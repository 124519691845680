// ids are contentful external life event ids
export const familyEvents = [
    {
        id: '45gRdA9ve4p9gIqorC7a6i',
        title: 'Getting Married',
        img: require('../../assets/images/lifeEvents/family/getting_married.svg'),
    },
    {
        // No external id -> LE is Coming Soon
        id: 'start-a-family',
        title: 'Start a Family',
        img: require('../../assets/images/lifeEvents/family/start_family.svg'),
        comingSoon: true,
    },
    {
        id: '5SZQU7RDR7R2Ts8SjJxKjZ',
        title: 'Getting Divorced',
        img: require('../../assets/images/lifeEvents/family/getting_divorced.svg'),
    },
    {
        id: '7bLtNggDKWOjw1ztuQ3uqq',
        title: 'Estate Plan',
        img: require('../../assets/images/lifeEvents/family/estate_plan.svg'),
    },
];

export const workEvents = [
    {
        id: '6ceIi2ikmezTJtxIDEuRsg',
        title: 'Career Goal',
        img: require('../../assets/images/lifeEvents/bigstuff/passion.svg')
    },
    {
        id: '3u6QYATZ4DlIMmfeHanzqD',
        title: 'New Job',
        img: require('../../assets/images/lifeEvents/work/new_job.svg'),
    },
    {
        id: '6auybRL2bWkIHRsWbSVMzb',
        title: 'Gig Worker',
        img: require('../../assets/images/lifeEvents/work/gig_worker.svg'),
    },
    {
        id: '71OxoP9ERDxSQQqJMjODL9',
        title: 'Side Hustle',
        img: require('../../assets/images/lifeEvents/work/side_hustle.svg'),
    },
    {
        // No external id -> LE is Coming Soon
        id: 'job-change',
        title: 'Job Change',
        img: require('../../assets/images/lifeEvents/work/job_change.svg'),
        comingSoon: true,
    },
    {
        // No external id -> LE is Coming Soon
        id: 'job-loss',
        title: 'Job Loss',
        img: require('../../assets/images/lifeEvents/work/job_loss.svg'),
        comingSoon: true,
    },
    {
        // No external id -> LE is Coming Soon
        id: 'benefits',
        title: 'Benefits',
        img: require('../../assets/images/lifeEvents/work/benefits.svg'),
        comingSoon: true,
    },
];

export const moneyEvents = [
    {
        id: '1zTG17lpX4S66cXfr62Rde',
        title: 'Manage Debt',
        img: require('../../assets/images/lifeEvents/money/manage_debt.svg'),
    },
    {
        id: 'prMyQcowYYyVC8N5XNQHn',
        title: 'Student Debt',
        img: require('../../assets/images/lifeEvents/money/student_debt.svg'),
    },
    {
        // No external id -> LE is Coming Soon
        id: 'O7v6vw1nqpANUiFn3SK7n',
        title: 'Savings Goal',
        img: require('../../assets/images/lifeEvents/money/savings.svg'),
    },
    {
        id: '44R8Vy6mKWirpfc3AOUsBf',
        title: 'Emergency Fund',
        img: require('../../assets/images/lifeEvents/money/emergency_fund.svg'),
    },
    {
        id: '15cY0o7S7hwiHdzTNLxNJL',
        title: 'Affordability',
        img: require('../../assets/images/lifeEvents/money/affordability.svg')
    }
];

export const protectionEvents = [
    {
        id: '5qothgTh3jmeIWcLULRxq0',
        title: 'Life Insurance',
        img: require('../../assets/images/lifeEvents/protection/life_insurance.svg'),
    },
    {
        // No external id -> LE is Coming Soon
        id: 'property-insurance',
        title: 'Property Insurance',
        img: require('../../assets/images/lifeEvents/protection/property_insurance.svg'),
        comingSoon: true,
    },
    {
        // No external id -> LE is Coming Soon  
        id: 'auto-insurance',
        title: 'Auto Insurance',
        img: require('../../assets/images/lifeEvents/protection/auto_insurance.svg'),
        comingSoon: true,
    },
    {
        // No external id -> LE is Coming Soon
        id: 'other-insurance',
        title: 'Other Insurance',
        img: require('../../assets/images/lifeEvents/protection/other_insurance.svg'),
        comingSoon: true,
    },
];

export const bigStuffEvents = [
    {
        id: '4cG7ftwrWfEeW2Bqj1ThiA',
        title: 'Buy a House',
        img: require('../../assets/images/lifeEvents/bigstuff/buy_home.svg'),
    },
    {
        id: '1GzC0nENxgH3MN2bwhwEOr',
        title: 'Buy a Car',
        img: require('../../assets/images/lifeEvents/bigstuff/buy_car.svg'),
    },
    {
        id: '5lwJJdsnVdI0HycjtVhRXH',
        title: 'College Plan',
        img: require('../../assets/images/lifeEvents/bigstuff/college.svg'),
    },
    {
        id: '3s7O7PtEaKgjdAML8huqsO',
        title: 'Retire / Reboot',
        img: require('../../assets/images/lifeEvents/bigstuff/retire.svg'),
    }
];

export const futureEvents = [
    {
        // No external id -> LE is Coming Soon
        id: 'travel',
        title: 'Travel',
        img: require('../../assets/images/lifeEvents/future/travel.svg'),
        comingSoon: true,
    },
    {
        // No external id -> LE is Coming Soon
        id: 'space-travel',
        title: 'Space Travel',
        img: require('../../assets/images/lifeEvents/future/space_travel.svg'),
        comingSoon: true,
    },
    {
        // No external id -> LE is Coming Soon
        id: 'robotic-healthcare',
        title: 'Robotic Healthcare',
        img: require('../../assets/images/lifeEvents/future/robotic_healthcare.svg'),
        comingSoon: true,
    },
    {
        // No external id -> LE is Coming Soon
        id: 'shooting-star',
        title: 'Shooting Star',
        img: require('../../assets/images/lifeEvents/future/shooting_star.svg'),
        comingSoon: true,
    },
];