import { useState } from "react";
import { useQuery } from "react-apollo";
import { GET_CATEGORIES } from "../Apollo/queries";
import { useDebtData } from "./useDebtData";
import { useExpensesData } from "./useExpensesData";
import { useIncomeData } from "./useIncomeData";
import { useInvestmentsData } from "./useInvestmentsData";
import { useNetCashData } from "./useNetCashData";
import { useNetWorthData } from "./useNetWorthData";
import { useRetirementData } from "./useRetirementData";
import { useSavingsData } from "./useSavingsData";

export const useScoop = (
  transactionsData: any,
  accBalancesData: any,
  dbPreviews: any
) => {
  const [transactionCategories, setTransactionCategories] = useState([]);
  const [incomeCategories, setIncomeCategories] = useState([]);
  const [skipCat, setSkip] = useState(false);

  useQuery(GET_CATEGORIES, {
    onCompleted: (data) => {
      setIncomeCategories([data?.customCategoriesV2[0]]);
      const cats = [...data?.customCategoriesV2];
      cats.shift();
      setTransactionCategories(cats);
      setSkip(true);
    },
    skip: skipCat,
  });

  const {
    fullMonths: monthsE,
    amountsTotalsMonthly: amtsE,
    baseData: dataE,
  } = useExpensesData(
    transactionCategories,
    transactionsData,
    false,
    11,
    "All Expenses"
  );
  const {
    fullMonths: monthsI,
    amountsTotalsMonthly: amtsI,
    baseData: dataI,
  } = useIncomeData(incomeCategories, transactionsData, false);
  const {
    fullMonths: monthsNW,
    monthlyTotals: amtsNW,
    baseData: dataNW,
  } = useNetWorthData(accBalancesData, false);
  const {
    fullMonths: monthsD,
    monthlyTotals: amtsD,
    baseData: dataD,
  } = useDebtData(accBalancesData, false);
  const {
    fullMonths: monthsS,
    monthlySums: amtsS,
    sortedData: dataS,
  } = useSavingsData(accBalancesData, transactionsData, false);
  const {
    fullMonths: monthsNC,
    totalMonthlyNetCash: amtsNC,
    baseData: dataNC,
  } = useNetCashData(transactionsData, false, 11);
  const {
    fullMonths: monthsIn,
    monthlySums: amtsIn,
    sortedData: dataIn,
  } = useInvestmentsData(accBalancesData, false);
  const {
    fullMonths: monthsR,
    monthlySums: amtsR,
    sortedData: dataR,
  } = useRetirementData(accBalancesData, false);

  const valueE = amtsE() && amtsE()[11];
  const valueI = amtsI && amtsI[11];
  const valueD = amtsD && amtsD[11];
  const valueR = amtsR && amtsR[11];
  const valueS = amtsS && amtsS[11];
  const valueNW = amtsNW && amtsNW[11];
  const valueNC = amtsNC && amtsNC;
  const valueIn = amtsIn && amtsIn[11];

  const dashboardsData = [
    {
      id: "expense",
      title: "Expenses",
      subtitle: monthsE[11],
      value: valueE,
      img: require("../../assets/images/scoop/expenses.svg"),
      color: "#F7C918",
      checked: dbPreviews ? dbPreviews.find((e) => e === "expense") : true,
    },
    {
      id: "income",
      title: "Income",
      subtitle: monthsI[11],
      value: valueI,
      color: "#8FDAF9",
      img: require("../../assets/images/scoop/incomes.svg"),
      checked: dbPreviews ? dbPreviews.find((e) => e === "income") : false,
    },
    {
      id: "networth",
      title: "Net Worth",
      subtitle: monthsNW[11],
      value: valueNW,
      img: require("../../assets/images/scoop/networth.svg"),
      color: "#42B05C",
      checked: dbPreviews ? dbPreviews.find((e) => e === "networth") : true,
    },
    {
      id: "debt",
      title: "Debt Dashboard",
      subtitle: monthsD[11],
      value: valueD,
      img: require("../../assets/images/scoop/debt.svg"),
      color: "#F9A200",
      checked: dbPreviews ? dbPreviews.find((e) => e === "debt") : false,
    },
    {
      id: "saving",
      title: "Savings",
      subtitle: monthsS[11],
      value: valueS,
      img: require("../../assets/images/scoop/savings.svg"),
      color: "#2698E0",
      checked: dbPreviews ? dbPreviews.find((e) => e === "saving") : true,
    },
    {
      id: "cashflow",
      title: "Net Cash",
      subtitle: monthsNC[11],
      value: valueNC,
      img: require("../../assets/images/scoop/cashflow.svg"),
      color: "#58E023",
      checked: dbPreviews ? dbPreviews.find((e) => e === "cashflow") : true,
    },
    {
      id: "retirement",
      title: "Retirement",
      subtitle: monthsR[11],
      value: valueR,
      img: require("../../assets/images/scoop/retirement.svg"),
      color: "#6FC9F0",
      checked: dbPreviews ? dbPreviews.find((e) => e === "retirement") : false,
    },
    {
      id: "investment",
      title: "Investments",
      subtitle: monthsIn[11],
      value: valueIn,
      img: require("../../assets/images/scoop/investments.svg"),
      color: "#49CBA7",
      checked: dbPreviews ? dbPreviews.find((e) => e === "investment") : false,
    },
  ];

  return {
    transactionCategories,
    incomeCategories,
    dataE,
    valueE,
    dataI,
    valueI,
    dataD,
    valueD,
    dataR,
    valueR,
    dataS,
    valueS,
    dataNW,
    valueNW,
    dataNC,
    valueNC,
    dataIn,
    valueIn,
    dashboardsData,
  };
};
