import React from "react";
import { Stack } from "office-ui-fabric-react";
import EFLabel from "../Atomic/EFLabel/EFLabel";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import maskValue from "../../utils/Masking";
import icon from  "../../assets/icons/bullet.svg";

interface IProps {
  style?: object;
  titleStyle?: object;
  sectionGap?: number;
  title: string;
  value?: number;
  color?: string;
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  thousandsSeparatorSymbol?: string;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: number;
  allowNegative?: boolean;
  allowLeadingZeroes?: boolean;
  isBulleted?: boolean;
  valueStyle?: object;
  valueFontSize?: number | string;
  includeIcon?: string;
  isHorizontal?: boolean;
  pairWidth?: string | number;
  notAmt?: boolean;
}

const MaskedLabelPair: React.FC<IProps> = (props) => {
  const numberMask = createNumberMask({
    ...MaskedLabelPair.defaultProps,
    ...props,
  });

  const titleStyle = {
    fontSize: '1.6rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: props.pairWidth,
    padding: '.5rem 0'
  }
  return (
    <Stack style={props.style} horizontal={props.isHorizontal} horizontalAlign={props.isHorizontal ? 'space-between' : 'center'}>
      {props.title ? (
        <Stack horizontal>
          {props.isBulleted ? (
            <div
              style={{
                  mask: `url(${icon}) no-repeat center`, 
                  WebkitMask: `url(${icon}) no-repeat center`, 
                  backgroundColor: props.color,
                  maskSize: 'contain',
                  width: '1.5rem',
                  height: '1.5rem',
              }} 
            />
          ) : null}
          <EFLabel style={{ ...titleStyle, ...props.titleStyle }}>{props.title}</EFLabel>
        </Stack>
      ) : null}
      <Stack horizontal verticalAlign='center' style={{ minWidth: "25%"}} tokens={{childrenGap: 2}}>
        {props.includeIcon ? <img style={{height: '.8rem', width: '.8rem'}} alt='icon' src={props.includeIcon} /> : null}
        <EFLabel
          style={{
            fontWeight: "normal" as "normal",
            fontSize: props.valueFontSize,
            textAlign: "left" as "left",
            color: props.color,
            ...props.valueStyle
          }}
        >
          {props.value ? maskValue(Math.round(props.value), numberMask) : props.value === 0 ? props.notAmt ? 0 : '$0' : ''}
        </EFLabel>
      </Stack>
    </Stack>
  );
};



MaskedLabelPair.defaultProps = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: false,
  decimalSymbol: ".",
  decimalLimit: 2,
  allowNegative: true,
  allowLeadingZeroes: false,
  isBulleted: true,
  valueFontSize: '1.6rem',
  isHorizontal: true,
};

export default MaskedLabelPair;
