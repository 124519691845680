import React from 'react';

import classes from './PlaylistWidget.module.css';
import moreActionsPlaylist from '../../../assets/images/planning/playlist/moreActionsPlaylist.svg';
import retakeIcon from '../../../assets/images/planning/playlist/retakeIcon.svg';
import makeItMyOwnIcon from '../../../assets/images/planning/playlist/makeItMyOwnIcon.svg';
import deletePlaylistIcon from '../../../assets/images/planning/playlist/deletePlaylistIcon.svg';
import copiedPlaylistIcon from '../../../assets/images/planning/playlist/copiedPlaylistIcon.svg';
import { isCanonical } from '../../../utils/global';
import PlanCard from '../PlanCard';
import { getLifeEventNameById } from '../../../utils/LifeEventTools/Mappers';
import { getPlanNameById } from '../MyPlanCards';
import { useSelector } from 'react-redux';

interface Props {
    user: any;
    onCardClick: (event: any) => void;
    onAddLifeEventButtonClick?: () => void;
    planToRender?: any;
    showMoreActions?: boolean;
    customStyle?: any;
    showPlusIcon?: boolean;
    onPlusClick?: () => void;
    showRetakeIcon?: boolean;
    onRetakeClick?: () => void;
    showDeleteIcon?: boolean;
    onDeleteClick?: () => void;
    showCopiedIcon?: boolean;
}

const PlaylistWidget: React.FC<Props> = props => {
    const realUser = useSelector((state: any) => state.data.realUser);

    const cardCustomStyle = {
        minWidth: 'unset',
        margin: 'unset',
        borderRadius: '12.2797px',
        boxShadow: '0px 1.63729px 12.2797px rgba(0, 0, 0, 0.109512)',
        padding: 'unset',
        width: '90%',
        marginBottom: '1rem'
    };

    let amountCompleted = 0;

    if (!isCanonical(props.user.name)) {
        if (props.planToRender?.lifeEvents.length > 0) {
            props.planToRender?.lifeEvents.forEach(le => {
                if (le.status !== "NEW") {
                    amountCompleted++;
                }
            })
        }
    }

    return (
        <div id="playlist-widget" className={`${classes.container} ${classes.disableScrollbars}`} style={{ ...props.customStyle }}>
            <div>
                <div className={classes.titleContainer}>
                    <p className={classes.title}>{isCanonical(props.user.name) ? props.user.plans[0].name :
                        props.planToRender.name === 'MY PLAYLIST' ? props.user.name.split(' ')[0] + "'s Playlist" : props.planToRender.name} </p>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {!isCanonical(props.user.name) && props.showRetakeIcon && <img src={retakeIcon} alt="Retake"
                            onClick={props.onRetakeClick} />}

                        {!isCanonical(props.user.name) && props.showMoreActions && <img src={moreActionsPlaylist} alt="more"
                            onClick={() => alert("Coming soon!")} />}

                        {!isCanonical(props.user.name) && props.showDeleteIcon && <img src={deletePlaylistIcon} alt="more"
                            onClick={props.onDeleteClick} />}

                        {/* User should only be able to add a playlist if they are authorized for now (since we never made the logic to handle unauth case) */}
                        {isCanonical(props.user.name) && props.showPlusIcon && realUser && Object.keys(realUser).length > 0 &&
                            <img src={makeItMyOwnIcon} alt="Make it my own" onClick={props.onPlusClick} />}
                    </div>
                </div>

                {!isCanonical(props.user.name) && props.planToRender?.lifeEvents.length > 0 && <p className={classes.status}>{amountCompleted +
                    '/' +
                    props.planToRender?.lifeEvents.length +
                    ' events completed'}</p>}

                {isCanonical(props.user.name) && <p className={classes.status}>{props.user.lifeEvents.length +
                    '/' +
                    props.user.lifeEvents.length +
                    ' events completed'}</p>}

                {!isCanonical(props.user.name) ?
                    props.planToRender?.lifeEvents.map(le =>
                        <PlanCard key={le?.id} id={le?.lifeEventId} title={le.name}
                            customStyle={cardCustomStyle}
                            customBadgeStyle={{ width: '3rem' }}
                            customContentStyle={{ paddingLeft: '1rem' }}
                            customImgStyle={{ width: '40px' }}
                            customLabelStyle={{ fontSize: '1.4rem' }}
                            owner={props.user.name}
                            isCompleted={le?.status === "NEW" ? false : true}
                            onCardClickHandler={(event, info) => {
                                event.stopPropagation();
                                event.preventDefault();
                                props.onCardClick(info)
                            }}
                            avatar={le?.eventIcon[0].url}
                            statusString={le?.overview.description}
                            le={le} />)
                    :
                    props.user.lifeEvents.map((le, index) =>
                        <PlanCard key={index}
                            customStyle={cardCustomStyle}
                            customBadgeStyle={{ width: '3rem' }}
                            customContentStyle={{ paddingLeft: '1rem' }}
                            customImgStyle={{ width: '40px' }}
                            customLabelStyle={{ fontSize: '1.4rem' }}
                            id={le.id} title={le.name}
                            owner={props.user.name}
                            isCompleted
                            onCardClickHandler={(event, info) => {
                                event.stopPropagation();
                                event.preventDefault();
                                props.onCardClick(info)
                            }}
                            avatar={le.icon[0].url}
                            statusString={le?.overview.description}
                            le={le} />)
                }
            </div>
        </div>

    )
};

export default PlaylistWidget;