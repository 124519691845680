/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { IDropdownOption } from 'office-ui-fabric-react';
import moment from 'moment';
import EFDropdown from '../Atomic/EFDropdown/EFDropdown';
import Modal from 'react-modal';
import { useScroll } from '../../hooks/useScroll';
import { appendHeaders } from '../../utils/FetchHeaders';
import Pagination from '../Atomic/Pagination/Pagination';

import add from '../../assets/images/kakeibo/add.svg';
import close from '../../assets/images/protect/close.svg';

import EFButtonOrange from "../CareerPlanning/EfButtonOrange";
import EfButtonWhite from "../CareerPlanning/EfButtonWhite";
import TransactionStrip from "./TransactionStrip";

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../utils/Masking';

import classes from './Kakeibo.module.css';
import CurrencyInput from "../Atomic/CurrencyInput/CurrencyInput";
import EFButton from "../Atomic/EFButton/EFButton";
import EFLabel from "../Atomic/EFLabel/EFLabel";
import FabricCalendar from "../Atomic/Calendar/FabricCalendar/FabricCalendar";

interface Props {
    currentView: string,
    token: string,
    realUser: boolean
}

const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowNegative: true,
    allowLeadingZeroes: false,
});

const getWeekRange = (weekStr: string) => {
    let weekArr = weekStr.split(' ');
    const currYr = moment().year();
    const minDate = moment(weekArr[2] + '/' + currYr).valueOf();
    const maxDate = moment(weekArr[4] + '/' + currYr).valueOf();

    return { minDate: minDate, maxDate: maxDate }
}

const getCurrWeekRng = (weekSet: any) => {
    const currDate = moment().valueOf();
    return weekSet.find(e => ( currDate >= getWeekRange(e.text).minDate && currDate <= getWeekRange(e.text).maxDate));
}

const handleStrToNum = (str: string) => {
    let curr_str = str;
    curr_str = curr_str.replace(/,/g, '');
    curr_str = curr_str.replace('$', '');
    let numVal = parseFloat(curr_str);
    if (isNaN(numVal) || numVal < 0) {
        numVal = 0;
    } else if (numVal > 100000) {
        numVal = 100000;
    };
    return numVal;
};


const Kakeibo: React.FC<Props> = props => {
    const [transactions, setTransactions] = useState([]);
    const [incomes, setIncomes] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [incomesSum, setIncomesSum] = useState(0);
    const [expensesSum, setExpesesSum] = useState(0);
    const [total, setTotal] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [addTitle, setAddTitle] = useState('');
    const [week, setWeek] = useState([]);
    const [weeks, setWeeks] = useState([]);
    const [selectedWeekRng, setSelectedWeekRng] = useState('');
    const [sourceName, setSourceName] = useState('');
    const [date, setDate] = useState(moment().valueOf());
    const [amount, setAmount] = useState(0);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
    const [entryForEditDelete, setEntryForEditDelete] = useState(Object());
    const [edit, setEdit] = useState(false);
    const [dateSelected, setDateSelected] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(moment(new Date()).format("M/DD").split("/")[0]);
    const [currentIncomePage, setCurrentIncomePage] = useState(1);
    const [currentExpensePage, setCurrentExpensePage] = useState(1);
    const [validator, setValidator] = useState(false);

    const [postsPerPage] = useState(10);

    const indexOfLastExpensePost = currentExpensePage * postsPerPage;
    const indexOfFirstExpensePost = indexOfLastExpensePost - postsPerPage;

    const indexOfLastIncomePost = currentIncomePage * postsPerPage;
    const indexOfFirstIncomePost = indexOfLastIncomePost - postsPerPage;

    const paginateIncome = pageNumber => setCurrentIncomePage(pageNumber);
    const paginateExpense = pageNumber => setCurrentExpensePage(pageNumber);

    const kakeiboUrl = process.env.REACT_APP_KAKEIBO_ENDPOINT;

    useScroll(modalIsOpen || modalDeleteIsOpen);

    function closeModalDelete() {
        setModalDeleteIsOpen(false);
    }

    async function fetchData() {
        try {
            fetch(kakeiboUrl,
            {
                method: 'GET',
                headers: {...appendHeaders(), Authorization: 'Bearer ' + props.token}
            }
            )
            .then(res => res.json())
            .then((data) => {
                setTransactions(data.data);
                getData(data.data);
            })
          } catch (error) {
            console.error(error);
          }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const changeDatesOnUpdate = (selectedDate: any) => {
        setDate(moment(selectedDate).valueOf());
        let index = selectedDate.getMonth();
        if (index === -1) {
            index = 11;
        }
        const selectedDateMonth = months[index].key;
        setDateSelected(selectedDate);
        setSelectedMonth(selectedDateMonth);
        const dateEpoch = moment(selectedDate).valueOf();
        const currWeekRng = Object.keys(weeks).find(e => ( dateEpoch >= getWeekRange(weeks[e].text).minDate
            && dateEpoch <= getWeekRange(weeks[e].text).maxDate));
        setSelectedWeekRng(weeks[currWeekRng].text);
    }

    const addKakeibo = () => {

        const entry = {
            sourceName: sourceName,
            date: date,
            type: addTitle,
            amount: amount
        }

        if (!entry.sourceName || !entry.amount) {
            setValidator(true);
            return
        }
        closeModal();
        try {
            fetch(kakeiboUrl,
            {
                method: 'POST',
                headers: {...appendHeaders(), Authorization: 'Bearer ' + props.token},
                body: JSON.stringify(entry),
            }
        )
            .then(res => res.json())
            .then((payload) => {
                transactions.push(payload.data);
                setTransactions(transactions);
                getData(transactions);
                changeDatesOnUpdate(new Date(date))
            })
          } catch (error) {
            console.error(error);
          }
    }

    const editKakeibo = () => {

        const entry = {
            sourceName: sourceName,
            date: date,
            type: addTitle,
            amount: amount
        }

        if (!entry.sourceName || !entry.amount) {
            setValidator(true);
            return
        }
        try {
            fetch(kakeiboUrl + '/' + entryForEditDelete.id,
            {
                method: 'PUT',
                headers: {...appendHeaders(), Authorization: 'Bearer ' + props.token},
                body: JSON.stringify(entry)
            }
        )
            .then(res => res.json())
            .then((data) => {
                //fetchData();
                transactions.forEach((t) => {
                    if (t.id === entryForEditDelete.id) {
                        t.sourceName = sourceName;
                        t.date = date;
                        t.type = addTitle;
                        t.amount = amount;
                    }
                });
                setTransactions(transactions);
                getData(transactions);
                changeDatesOnUpdate(new Date(date))
                closeModal();
            })
          } catch (error) {
            console.error(error);
        }
    }

    const deleteKakeibo = () => {
        try {
            fetch(kakeiboUrl + '/' + entryForEditDelete.id,
            {
                method: 'DELETE',
                headers: {...appendHeaders(), Authorization: 'Bearer ' + props.token}
            }
        )
            .then(res => res.json())
            .then((data) => {
                //fetchData();
                const newTransactions = transactions.filter((t) => t.id !== entryForEditDelete.id);
                setTransactions(newTransactions);
                getData(newTransactions);
                closeModalDelete();
            })
          } catch (error) {
            console.error(error);
        }
    }

    const setWeeksByYear = () => {
        const auxArr = [];
        var enddate, startyear, endyear, i;
        startyear = 2020;
        endyear = 2020;
        enddate = new Date(endyear, 11, 31);
        enddate.setDate(enddate.getDate() + 6);
        for (i = new Date(startyear, 0, 1); i <= enddate; i.setDate(i.getDate() + 6)) {
            auxArr.push(new Date(i));
        }
        setWeek(auxArr);
        const arrFinal = []
        arrFinal.push(new Date(enddate));
    }

    if (week.length === 0) {
        setWeeksByYear();
    }

    function openModal() {
        setModalIsOpen(true);
    }

    function closeModal() {
        setModalIsOpen(false);
    }

    const months = [
        { key: '1', text: 'January' },
        { key: '2', text: 'February' },
        { key: '3', text: 'March' },
        { key: '4', text: 'April' },
        { key: '5', text: 'May' },
        { key: '6', text: 'June' },
        { key: '7', text: 'July' },
        { key: '8', text: 'August' },
        { key: '9', text: 'September' },
        { key: '10', text: 'October' },
        { key: '11', text: 'November' },
        { key: '12', text: 'December' },
    ];

    useEffect(() => {
        const auxArr = [];

        for (const [i, w] of week.entries()) {
            var month1 = (1 + w.getMonth()).toString();
            month1 = month1.length > 1 ? month1 : '0' + month1;

            var day1 = w.getDate().toString();
            day1 = day1.length > 1 ? day1 : '0' + day1;

            let month2 = '';
            let day2 = '';

            if (i + 1 < week.length) {
                month2 = (1 + w.getMonth()).toString();
                month2 = month2.length > 1 ? month2 : '0' + month2;

                day2 = week[i + 1].getDate().toString();
                day2 = day2.length > 1 ? day2 : '0' + day2;

                if (parseInt(day1) > parseInt(day2)) {
                    const getNewMonth = (parseInt(month2) + 1) % 12;
                    month2 = getNewMonth.toString();
                }
                             
                month2 = month2 === "0" ? "12" : month2;
                
                auxArr.push({ key: i, text: 'Week from ' + month1 + '/' + day1 + ' to ' +  month2 + '/' + day2 });
            }
        }
        setWeeks(auxArr);
        setSelectedWeekRng(getCurrWeekRng(auxArr)?.text);
    }, [week])

    const getData = (transactions: any) => {
        if (transactions.length > 0) {
            const arrIncome = [];
            const arrExpense = [];
            let exSum = 0;
            let incSum = 0;

            if (props.currentView === 'Monthly') {
                for (const [, transaction] of transactions.entries()) {
                    const date = formateDate(transaction.date);
                    const month = date.split('/')[0];
                    if (transaction.type === 'Income' && month === selectedMonth) {
                        arrIncome.push(transaction);
                        incSum = incSum + transaction.amount;
                    } else if (transaction.type === 'Expense' && month === selectedMonth) {
                        arrExpense.push(transaction);
                        exSum = exSum + transaction.amount;
                    }
                }
            } else if (props.currentView === 'Weekly') {
                const { minDate, maxDate } = getWeekRange(selectedWeekRng);
                for (const [, transaction] of transactions.entries()) {
                    if (transaction.date < minDate || transaction.date > maxDate) {
                        continue;
                    }
                    if (transaction.type === 'Income') {
                        arrIncome.push(transaction);
                        incSum = incSum + transaction.amount;
                    } else if (transaction.type === 'Expense') {
                        arrExpense.push(transaction);
                        exSum = exSum + transaction.amount;
                    }
                }
            }

            const incTot = incSum.toFixed(2);
            const exTot = exSum.toFixed(2);
            const tot = parseFloat(incTot) - parseFloat(exTot);
            const parsedTot = tot.toFixed(2);
            setIncomes(arrIncome);
            setExpenses(arrExpense);
            setIncomesSum(parseFloat(incTot));
            setExpesesSum(parseFloat(exTot));
            setTotal(parseFloat(parsedTot));
        }
        else {
            setIncomes([]);
            setExpenses([]);
        }
    }

    const currentExpenses = expenses.slice(indexOfFirstExpensePost, indexOfLastExpensePost);
    const currentIncomes = incomes.slice(indexOfFirstIncomePost, indexOfLastIncomePost);

    const formateDate = (date) => {
        return moment(date).format("M/DD");
    }

    const deleteEntry = (item) => {
        setEntryForEditDelete(item);
        setModalDeleteIsOpen(true);
    }

    const editEntry = (item) => {
        setEntryForEditDelete(item);
        setEdit(true);
        setModalIsOpen(true);
        setSourceName(item.sourceName);
        setAmount(item.amount);
        setDate(item.date);
    }

    useEffect(() => {
        getData(transactions);
    }, [dateSelected, selectedMonth, selectedWeekRng, props.currentView]);

    useEffect(() => {
        setValidator(false)
    }, [amount, sourceName, modalIsOpen]);

    useEffect(() => {
        if (props.currentView === "Monthly") {
            const d = new Date();
            d.setMonth(parseFloat(selectedMonth));
            const selectedDate = new Date(d);
            setDate(moment(selectedDate).subtract(1, 'months').valueOf());
        } else {
            setDate(getWeekRange(selectedWeekRng).minDate);
        }
    }, [props.currentView]);

    return (
        <div className={classes.container}>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={classes.modalStyle}
                style={{
                    overlay: {
                        overflowY: "auto" as "auto",
                        background: 'rgba(248, 250, 255, 0.50)',
                        backdropFilter: 'blur(40px)',
                        WebkitBackdropFilter: 'blur(40px)',
                        zIndex: 10
                    }
                }}
            >
                <img src={close} alt="close" className={classes.rightButton} onClick={closeModal} />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                    {!edit ? <span className={classes.modalTitle}>Add {addTitle}</span> : <span className={classes.modalTitle}>Edit {entryForEditDelete.sourceName}</span>}
                </div>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', marginLeft: '3rem', marginTop: '3.5rem', marginBottom: '1rem' }}>
                    <span className={classes.greyLabelsLeft}>Date</span>
                </div>
                <div style={{ width: '80%', display: 'flex', marginLeft: '3rem', borderBottom: '1px solid #D5D4CF' }}>
                    <FabricCalendar
                        strFormat="MM/DD/YYYY"
                        selectedDate={date}
                        onSelectDate={d => setDate(moment(d).valueOf())}
                        isDayPickerVisible
                        isMonthPickerVisible={false}
                        labelStyle={{fontWeight: 600, fontSize: '2rem', color: '#293861'}}
                        minDate={moment().subtract(1, "years").toDate()}
                        maxDate={moment().add(1, "years").toDate()}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', marginLeft: '3rem', marginBottom: '1rem', marginTop: '3rem' }}>
                    <span className={classes.greyLabelsLeft}>Source</span>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <input value={sourceName} onChange={(e) => setSourceName(e.target.value)} style={{ outline: 0, fontWeight: 600, backgroundColor: '#FFFFFF', borderWidth: '0 0 1px', borderColor: '#d5d4cf', width: '80%', fontFamily: 'brandon-grotesque, sans-serif', fontSize: '20px', lineHeight: '23px', color: '#293861' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', marginLeft: '3rem', marginBottom: '1rem', marginTop: '3rem' }}>
                    <span className={classes.greyLabelsLeft}>Add Amount</span>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>    
                    <CurrencyInput value={amount} onChange={(e) => setAmount(handleStrToNum(e.target.value))} style={{fontWeight: 600, backgroundColor: '#FFF', borderWidth: '0 0 1px', borderColor: '#D5D4CF', width: '80%', fontSize: '2rem', color: '#293861'}} />
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '3rem' }}>
                    <EFButton 
                        style={{width: '40%'}} 
                        text={'DONE'} 
                        onClick={() => edit ? editKakeibo() : addKakeibo()}
                        isDisabled={validator}
                    />
                    {validator && <EFLabel style={{marginTop: '1rem', textAlign: 'center', color: '#c4233e', fontWeight: 'normal'}}>Please provide valid input</EFLabel>}
                </div>
            </Modal>
            <Modal
                isOpen={modalDeleteIsOpen}
                onRequestClose={closeModalDelete}
                className={classes.modalDeleteStyle}
                style={{
                    overlay: {
                        overflowY: "auto" as "auto",
                        background: 'rgba(248, 250, 255, 0.50)',
                        backdropFilter: 'blur(40px)',
                        WebkitBackdropFilter: 'blur(40px)',
                        zIndex: 10
                    }
                }}
            >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4rem' }}>
                    <span className={classes.deleteEntry}>Delete entry?</span>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '3rem' }}>
                    <span className={classes.fileName}>{entryForEditDelete.sourceName}</span>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '6rem' }}>
                    <EfButtonWhite width={'40%'} text={'cancel'} click={closeModalDelete}></EfButtonWhite>

                    <EFButtonOrange width={'40%'} text={'delete'} click={() => {
                        deleteKakeibo();
                    }}></EFButtonOrange>
                </div>
            </Modal>
            <div style={{ marginBottom: 30 }}>
                <span className={classes.title}>Money Available</span>
            </div>
            <div style={{ width: '100%' }}>
                <div className={classes.entryContainer}>
                    <div className={classes.centerDiv}>
                        <div style={{display: 'flex'}}>
                            <div className={classes.smallCenterDivs}>
                                <div className={classes.smallCenterDivs}>
                                    <span className={classes.greyLabels}>Income</span>
                                </div>
                                <div className={classes.smallCenterDivs}>
                                    <span className={classes.blueNormal}>{maskValue(Math.round(incomesSum), numberMask)}</span>
                                </div>
                            </div>
                            <div className={classes.smallCenterDivs}>
                                <div className={classes.smallCenterDivs}>
                                    <span className={classes.greyLabels}>Expenses</span>
                                </div>
                                <div className={classes.smallCenterDivs}>
                                    <span className={classes.blueNormal}>{maskValue(Math.round(expensesSum), numberMask)}</span>
                                </div>
                            </div>
                            <div className={classes.smallCenterDivs}>
                                <div className={classes.smallCenterDivs}>
                                    <span className={classes.greyLabels}>Balance</span>
                                </div>
                                <div className={classes.smallCenterDivs}>
                                    <span className={classes.blueNormal}>{maskValue(Math.round(total), numberMask)}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{width: '40%', margin: '1.5rem 1rem 0 2rem'}}>
                            <div style={{textAlign: 'unset'}} className={classes.greySmall}>Looking at</div>
                            {props.currentView === "Monthly" && (
                                <EFDropdown
                                    selectedKey={selectedMonth}
                                    style={{ width: '100%'}}
                                    options={months}
                                    onChange={(e: any, item?: IDropdownOption) => {
                                        const selected = (item?.key);
                                        if (typeof selected == 'string') {
                                            const d = new Date();
                                            d.setMonth(parseFloat(selected));
                                            const selectedDate = new Date(d);
                                            setDate(moment(selectedDate).subtract(1, 'months').valueOf());
                                            let index = selectedDate.getMonth() - 1;
                                            if (index === -1) {
                                                index = 11;
                                            }
                                            const selectedDateMonth = months[index].key;
                                            setDateSelected(selectedDate);
                                            setSelectedMonth(selectedDateMonth);
                                        }
                                    }}
                                />
                            )}
                            {props.currentView === "Weekly" && (
                                <EFDropdown
                                    placeholder={selectedWeekRng}
                                    style={{ width: '100%'}}
                                    options={weeks}
                                    onChange={(e: any, item?: IDropdownOption) => {
                                        const getDateValue = getWeekRange(item?.text).minDate;
                                        setSelectedWeekRng(item?.text);
                                        setDate(getDateValue);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className={classes.leftDiv}>
                        <div style={{ marginBottom: '3rem', marginTop: '4rem' }}>
                            <span className={classes.title}>Income</span>
                        </div>
                        <div className={classes.leftBox}>
                            <div className={classes.centerAll}>
                                <div style={{ width: '25%', marginRight: '2.5rem' }}>
                                    <span className={classes.greyLabels}>Date</span>
                                </div>
                                <div style={{ width: '20%', marginRight: '2.5rem' }}>
                                    <span className={classes.greyLabels}>Source</span>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <span className={classes.greyLabels}>Amount</span>
                                </div>
                            </div>
                            {currentIncomes.map(item => (
                                <div key={item.id}>
                                    <TransactionStrip
                                        date={formateDate(item.date)}
                                        sourceName={item.sourceName}
                                        value={maskValue(Math.round(item.amount), numberMask)}
                                        deleteEntry={() => deleteEntry(item)}
                                        editEntry={() => editEntry(item)}
                                    />
                                </div>
                            ))}
                            {props.realUser && <div className={classes.centerAll}>
                                <img src={add} alt="incomes" className={classes.addImgStyle} onClick={() => {
                                    setEdit(false);
                                    openModal();
                                    setAddTitle('Income');
                                    setSourceName('');
                                    setAmount(0);
                                }} ></img>
                            </div>}
                            <div className={classes.centerPagination}>
                                <Pagination
                                    currentPage={currentIncomePage}
                                    postsPerPage={postsPerPage}
                                    totalPosts={incomes.length}
                                    paginate={paginateIncome}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.rightDiv}>
                        <div style={{ marginBottom: '3rem', marginTop: '4rem' }}>
                            <span className={classes.title}>Expenses</span>
                        </div>
                        <div className={classes.rightBox}>
                            <div className={classes.centerAll}>
                                <div style={{ width: '25%', marginRight: '2.5rem' }}>
                                    <span className={classes.greyLabels}>Date</span>
                                </div>
                                <div style={{ width: '20%', marginRight: '2.5rem' }}>
                                    <span className={classes.greyLabels}>Source</span>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <span className={classes.greyLabels}>Amount</span>
                                </div>
                            </div>
                            {currentExpenses.map(item => (
                                <div key={item.id}>
                                    <TransactionStrip
                                        date={formateDate(item.date)}
                                        sourceName={item.sourceName}
                                        value={maskValue(Math.round(item.amount), numberMask)}
                                        deleteEntry={() => deleteEntry(item)}
                                        editEntry={() => editEntry(item)}
                                    />
                                </div>
                            ))}
                            {props.realUser && <div className={classes.centerAll}>
                                <img alt="expenses" src={add} className={classes.addImgStyle} onClick={() => {
                                    setEdit(false);
                                    openModal();
                                    setAddTitle('Expense');
                                    setSourceName('');
                                    setAmount(0);
                                }}></img>
                            </div>}
                            <div className={classes.centerPagination}>
                                <Pagination
                                    currentPage={currentExpensePage}
                                    postsPerPage={postsPerPage}
                                    totalPosts={expenses.length}
                                    paginate={paginateExpense}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kakeibo;
