/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as calculatorActions from '../../store/actions/Calculator';
import { getCustomData } from '../../utils/LifeEventTools/customDataGenerator';
const { LOAN_AFFORDABILITY } = require('ef-calculators');

interface ILoanAffordability {
  amount: number;
  years: number;
  interest: number;
  monthlyPayment: number;
  monthlyIncome:number;
  annualIncome:number;
  monthlyRatio: number;
  affordabilityZone: number;
  affordabilityCriteria: string;
  totalToPay: number;
  recommended: {
    years:number;
    monthlyPayment: number;
    totalToPay: number;
    monthlyRatio: number;
    affordabilityZone: number
    affordabilityCriteria: number
  }
}

export const useAffordability = (uniqueEventID?: string) => {
    const dispatch = useDispatch();

    const loggedUserLifeEventData = useSelector((state: any) => {
        if (state.session.user) {
            return state.session.user.lifeEvents.find((le: any) => le.id === uniqueEventID)
        }
        else {
            return null;
        }
      }
    );

    const defaultData = getCustomData('Affordability');
    

    const [loanAmount, setLoanAmount] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.studentDebtBalance : defaultData.studentDebtBalance);
    const [annualIncome, setAnnualIncome] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.monthlyFutureIncome * 12 : defaultData.monthlyFutureIncome * 12);
    const [interest, setInterest] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.interestRate : defaultData.interestRate);
    const [termsInYears, setTermsInYears] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.payOffTime / 12 : defaultData.payOffTime / 12);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [affordabilityMinLimit, setAffordabilityMinLimit] = useState(10);
    const [affordabilityMaxLimit, setAffordabilityMaxLimit] = useState(15);

    const [affordabilityLoanAmount, setAffordabilityLoanAmount] = useState(0);
    const [affordabilityYears, setAffordabilityYears] = useState(0);
    const [affordabilityInterests, setAffordabilityIntersts] = useState(0);
    const [affordabilityMonthlyPayment, setAffordabilityMonthlyPayment] = useState(0);
    const [affordabilityMonthlyIncome, setAffordabilityMonthlyIncome] = useState(0);
    const [affordabiliryAnnualIncome, setAffordabilityAnnualIncome] = useState(0);
    const [affordabilityRatio, setAffordabilityRatio] = useState(0);
    const [affordabilityZone, setAffordabilityZone] = useState(0);
    const [affordabilityCriteria, setAffordabilityCriteria] = useState('');
    const [affordabilityTotalToPay, setAffordabilityTotalToPay] = useState(0);

    const [affordabilityRecommendedSimu, setAffordabilityRecommendedSimu] = useState({
      years: 0,
      monthlyPayment: 0,
      totalToPay: 0,
      monthlyRatio: 0,
      affordabilityZone: 1,
      affordabilityCriteria: 'Ok'
    });

    useEffect(() => {
      const loanAffordability = () => {
          const c: ILoanAffordability | any = LOAN_AFFORDABILITY(
            loanAmount,
            annualIncome,
            interest,
            termsInYears,
            monthlyPayment,
            affordabilityMinLimit,
            affordabilityMaxLimit
          );
          if (typeof c != 'undefined') {
            if (
              typeof c.monthlyIncome == 'number' &&
              typeof c.years == 'number'
            ) {
              setAffordabilityAnnualIncome(c.annualIncome);
              setAffordabilityCriteria(c.affordabilityCriteria);
              setAffordabilityIntersts(c.interest);
              setAffordabilityMonthlyIncome(c.monthlyIncome);
              setAffordabilityMonthlyPayment(c.monthlyPayment);
              setAffordabilityTotalToPay(c.totalToPay);
              setAffordabilityYears(c.years);
              setAffordabilityZone(c.affordabilityZone);
              setAffordabilityLoanAmount(c.amount);
              setAffordabilityRecommendedSimu(c.recommended);
              setAffordabilityRatio(c.monthlyRatio);
            }
          }
        };
        loanAffordability();
        dispatch(calculatorActions.affordabilityChangeState({
          payOffTime: termsInYears * 12,
          interestRate: interest,
          studentDebtBalance: loanAmount,
          monthlyFutureIncome: annualIncome / 12,
        }));
  }, [
    dispatch,
    loanAmount,
    annualIncome,
    interest,
    termsInYears,
    monthlyPayment,
    affordabilityMinLimit,
    affordabilityMaxLimit
  ]);

    return {
        loanAmount,setLoanAmount, 
        annualIncome, setAnnualIncome, 
        interest, setInterest, 
        termsInYears, setTermsInYears, 
        monthlyPayment, setMonthlyPayment, 
        affordabilityMinLimit, setAffordabilityMinLimit, 
        affordabilityMaxLimit, setAffordabilityMaxLimit,

        affordabilityLoanAmount,
        affordabilityYears, 
        affordabilityInterests, 
        affordabilityMonthlyPayment, 
        affordabilityMonthlyIncome, 
        affordabiliryAnnualIncome,
        affordabilityRatio,
        affordabilityZone, 
        affordabilityCriteria, 
        affordabilityTotalToPay,
        affordabilityRecommendedSimu,
    };
};
