import React, { useState, useEffect } from "react";
import classes from "../Dashboards.module.css";
import { Stack } from "office-ui-fabric-react";
import EFLabel from "../../../Atomic/EFLabel/EFLabel";
import BarChart from "../../../Charts/BarChart/BarChart";
import DoughnutChart from "../../../Charts/DoughnutChart";
import MaskedLabelPair from "../../../UI/MaskedLabelPair";
import ScoopAccordion from "../../ScoopAccordion/ScoopAccordion";
import Category from "../../CategoryList/Category";
import EFDropdown from "../../../Atomic/EFDropdown/EFDropdown";
import { addAlpha } from "../../../../utils/colorGenerators";
import DashboardHeader from "../../DashboardHeader/DashboardHeader";
import TimeButton from "../../TimeButton/TimeButton";
import {
  allCategoryData,
  useExpensesData,
} from "../../../../hooks/ScoopDashboards/useExpensesData";
import EmptyTransactions from "../../EmptyTransactions/EmptyTransactions";
import moment from "moment";
import kebab from "../../../../assets/images/kebab.svg";
import SubCategoryModal from "./SubCategoryModal";
import { useResponsive } from "../../../../hooks/useResponsive";

interface IProps {
  categories: any;
  transactionsData: any;
  focusCategory?: string;
}

const Expenses: React.FC<IProps> = (props) => {
  const { screenIsAtMost } = useResponsive();
  const isMobile = screenIsAtMost("mobile");
  const [settings, setSettings] = useState<any>(null);
  const [toYearly, setToYearly] = useState<boolean>(false);
  const [monthByIdx, setMonthByIdx] = useState<number>(11);
  const idxInitCategory = props.categories?.findIndex(
    (e: any) => e.title === props.focusCategory
  );
  const initialCategory =
    idxInitCategory !== -1
      ? props.categories?.[idxInitCategory]
      : allCategoryData;
  const [category, toggleCategory] = useState<string>(
    props.focusCategory ? initialCategory?.title : allCategoryData?.name
  );
  const {
    fullMonths,
    months,
    monthSet,
    amountsSummedByType,
    transactionData,
    getMonthlyAmountsForIndex,
    amountsTotalsMonthly,
    yrToDateByType,
    yrToDateDoughtnutData,
    totalYrToDate,
  } = useExpensesData(
    props.categories,
    props.transactionsData,
    toYearly,
    monthByIdx,
    category
  );

  const barColor =
    category === allCategoryData.name
      ? allCategoryData.color
      : getMonthlyAmountsForIndex(monthByIdx).colors[0];
  const barOffSelectColor = addAlpha(barColor, 0.3);
  const barSelectColor = barColor;
  const barColors = monthSet.map((_, i) =>
    i !== monthByIdx ? barOffSelectColor : barSelectColor
  );

  useEffect(() => {
    toYearly ? setMonthByIdx(0) : setMonthByIdx(11);
    // toggleCategory(allCategoryData.title);
    // ^ Triple check if disabling this creates any side effects
  }, [toYearly]);

  const baseCategoryData = props.categories?.map((e) => {
    return {
      key: e.name,
      text: e.name,
      color: e.color,
      assets: e.assets,
      focused: category === e.name,
      value:
        amountsSummedByType[
          props.categories?.findIndex((x: any) => x.name === e.name)
        ] &&
        amountsSummedByType[
          props.categories?.findIndex((x: any) => x.name === e.name)
        ][monthByIdx],
      subs: e.subCategories,
    };
  });

  const baseCategoryDataToYearly = props.categories?.map((e) => {
    return {
      key: e.name,
      text: e.name,
      color: e.color,
      assets: e.assets,
      focused: category === e.name,
      value:
        yrToDateByType[
          props.categories?.findIndex((x: any) => x.name === e.name)
        ] &&
        yrToDateByType[
          props.categories?.findIndex((x: any) => x.name === e.name)
        ],
      subs: e.subCategories,
    };
  });

  let categoryData = [
    {
      key: allCategoryData.name,
      text: allCategoryData.name,
      color: allCategoryData.color,
      assets: allCategoryData.assets,
      focused: category === allCategoryData.name,
      value: undefined,
    },
    ...(toYearly ? baseCategoryDataToYearly : baseCategoryData),
  ];

  const categories = categoryData.map((e) => (
    <Stack key={e.key}>
      <Category
        id={e.key}
        title={e.text}
        color={addAlpha(e.color, 0.3)}
        icon={e.assets}
        focused={e.focused}
        onClick={() => handleCategoryChange(e.text, e.color)}
        value={e.value}
      />
    </Stack>
  ));

  // Handles filtering data for particular category + switches category view
  const handleCategoryChange = (name: string, color: string) => {
    toggleCategory(name);
  };

  // Handles transaction list relative to category
  const transactionList = (name: string) => {
    const labelStyle = {
      fontSize: "2rem",
      fontWeight: "normal",
      textAlign: "center",
      margin: "1.5rem 0",
    };
    const idxType = props.categories?.findIndex((e: any) => e.name === name);
    const getCurrentCategory = baseCategoryData.find(
      (e: any) => name === e.text
    );
    const allCategoriesIsEmpty = baseCategoryData.every(
      (category: any) => !category.value
    );

    return (
      <>
        {isMobile ? <></> : <EFLabel style={labelStyle}>{name}</EFLabel>}
        {getCurrentCategory && !getCurrentCategory.value ? (
          <EmptyTransactions
            icon={getCurrentCategory.assets}
            color={getCurrentCategory.color}
            text={`We haven't found any transactions for ${name} in ${fullMonths[monthByIdx]}.`}
          />
        ) : (
          <>
            {name !== allCategoryData.name ? (
              transactionData[idxType] &&
              (transactionData[idxType][monthByIdx] || []).map(
                (category: any, i: number) => (
                  <Stack key={`${category.category}_${i}`}>
                    <ScoopAccordion
                      id={`${category.category}_${i}`}
                      title={category.category}
                      color={props.categories?.[idxType].color}
                      value={category.total}
                    >
                      {category.data &&
                        category.data.map((e: any, j: number) => (
                          <Stack
                            className={
                              category.data.length - 1 !== j
                                ? classes.transaction
                                : ""
                            }
                            key={`${e.label}_${e.date}_${i}_${j}`}
                            horizontalAlign="center"
                          >
                            <Stack
                              tokens={{ childrenGap: "2rem" }}
                              className={classes.transactionInner}
                            >
                              <Stack
                                horizontal
                                horizontalAlign="space-between"
                                verticalAlign="center"
                              >
                                <EFLabel
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "1.6rem",
                                    width: "30rem",
                                  }}
                                >
                                  {e.label}
                                </EFLabel>
                                <Stack
                                  style={{
                                    minWidth: isMobile ? "10rem" : "unset",
                                  }}
                                  tokens={{ childrenGap: "2rem" }}
                                  horizontal
                                  horizontalAlign="space-between"
                                >
                                  <EFLabel
                                    style={{
                                      fontWeight: "normal",
                                      fontSize: "1.6rem",
                                    }}
                                  >
                                    {moment(e.date).format("MMM DD")}
                                  </EFLabel>
                                  <MaskedLabelPair
                                    isBulleted={false}
                                    includeThousandsSeparator
                                    prefix="$"
                                    suffix=""
                                    title={""}
                                    titleStyle={{ fontWeight: "normal" }}
                                    value={e.amount}
                                    color="#9EA4B3"
                                    valueFontSize={"1.6rem"}
                                  />
                                  <img
                                    onClick={() =>
                                      setSettings({
                                        txnData: e,
                                        catData: category.data,
                                      })
                                    }
                                    style={{
                                      height: "1.6rem",
                                      width: "1.6rem",
                                      cursor: "pointer",
                                    }}
                                    src={kebab}
                                    alt="settings"
                                  />
                                </Stack>
                              </Stack>
                            </Stack>
                          </Stack>
                        ))}
                    </ScoopAccordion>
                  </Stack>
                )
              )
            ) : allCategoriesIsEmpty ? (
              <EmptyTransactions
                icon={allCategoryData.assets}
                color={allCategoryData.color}
                text={`We haven't found any transactions in ${fullMonths[monthByIdx]}.`}
              />
            ) : (
              baseCategoryData.map((efCategory: any, i: any) =>
                efCategory.value ? (
                  <Stack key={efCategory.text}>
                    <ScoopAccordion
                      id={efCategory.text}
                      title={efCategory.text}
                      color={efCategory.color}
                      value={0}
                    >
                      {transactionData[i] &&
                        (transactionData[i][monthByIdx] || []).map(
                          (category: any, j: number) => (
                            <Stack
                              className={
                                (transactionData[i][monthByIdx] || []).length -
                                  1 !==
                                j
                                  ? classes.transaction
                                  : ""
                              }
                              key={Math.random()}
                              horizontalAlign="end"
                            >
                              <Stack
                                tokens={{ childrenGap: "2rem" }}
                                className={classes.transactionInner}
                              >
                                <MaskedLabelPair
                                  isBulleted={false}
                                  includeThousandsSeparator
                                  prefix="$"
                                  suffix=""
                                  title={category.category}
                                  titleStyle={{ fontWeight: "normal" }}
                                  value={category.total}
                                  color="#9EA4B3"
                                  valueFontSize={"1.6rem"}
                                />
                              </Stack>
                            </Stack>
                          )
                        )}
                    </ScoopAccordion>
                  </Stack>
                ) : (
                  <Stack key={Math.random()}></Stack>
                )
              )
            )}
          </>
        )}
      </>
    );
  };

  const mobileCategories = (
    <Stack tokens={{ childrenGap: "2rem" }} className={classes.modalCategories}>
      {categoryData.map((currCategory) => (
        <Stack
          style={{ width: "68vw" }}
          horizontalAlign="center"
          verticalAlign="center"
        >
          <Category
            id={currCategory.key}
            title={currCategory.text}
            color={addAlpha(currCategory.color, 0.3)}
            icon={currCategory.assets}
            focused={currCategory.focused}
            onClick={() =>
              handleCategoryChange(currCategory.text, currCategory.color)
            }
            value={currCategory.value}
            disableAnimation
            style={{ width: "90%", height: "5.2rem", borderRadius: "2.9rem" }}
            imgStyle={{ width: "3.5rem", height: "3.5rem", margin: "0 2rem" }}
          />
          {currCategory.text === category ? (
            <Stack
              style={{
                margin: "1.5rem 3rem",
                border: "1px solid #F0F0F0",
                borderRadius: "1.4rem",
                width: "90%",
              }}
            >
              {transactionList(category)}
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      ))}
    </Stack>
  );

  function updateChart(this: any, e: any) {
    const element = this.getElementAtEvent(e);

    if (element[0] && element.length) {
      const chartIdx = element[0]._index;
      const chartProps = element[0]._chart.config.data.datasets[0];
      setMonthByIdx(chartIdx);

      chartProps.data.forEach((_: any, i: number) => {
        element[0]._chart.config.data.datasets[0].backgroundColor[i] =
          barOffSelectColor;
        if (i === chartIdx) {
          element[0]._chart.config.data.datasets[0].backgroundColor[i] =
            barSelectColor;
        }
      });
      this.update();
    }
  }

  return (
    <Stack
      horizontalAlign={isMobile ? "center" : ("" as any)}
      className={
        isMobile ? classes.outer_container_mobile : classes.outer_container
      }
    >
      <DashboardHeader
        title={toYearly ? "Year To Date" : fullMonths[monthByIdx]}
        value={
          toYearly
            ? totalYrToDate
            : amountsTotalsMonthly() && amountsTotalsMonthly()[monthByIdx]
        }
        prevVal={toYearly ? 0 : amountsTotalsMonthly()[monthByIdx - 1]}
        valueColor={barColor}
      />
      <Stack
        className={classes.inner_container}
        tokens={{ childrenGap: "2rem" }}
      >
        <Stack
          horizontalAlign={isMobile ? "center" : "space-between"}
          wrap={isMobile}
          horizontal
          className={isMobile ? classes.charts_mobile : classes.charts}
          verticalAlign="center"
        >
          <Stack
            className={isMobile ? classes.bar_view_mobile : classes.bar_view}
            tokens={{ childrenGap: 10 }}
          >
            <Stack horizontal horizontalAlign="space-evenly">
              <TimeButton
                text="YEAR TO DATE"
                isFocused={toYearly}
                onClick={() => {
                  setToYearly(true);
                  setMonthByIdx(11);
                }}
              />
              <TimeButton
                text="MONTHLY"
                isFocused={!toYearly}
                onClick={() => {
                  setToYearly(false);
                  setMonthByIdx(11);
                }}
              />
            </Stack>
            <BarChart
              fontSize={isMobile ? 8 : undefined}
              barPercentage={isMobile ? 0.65 : 0.4}
              datasets={{
                labels: months,
                datasets: [
                  {
                    label: "Expenses",
                    data:
                      amountsTotalsMonthly() &&
                      amountsTotalsMonthly().map(
                        (e: any) => Math.round(e * 1e2) / 1e2
                      ),
                    backgroundColor: barColors,
                    borderColor: "transparent",
                  },
                ],
              }}
              onClick={updateChart}
            />
          </Stack>
          <Stack
            className={
              isMobile ? classes.doughnut_view_mobile : classes.doughnut_view
            }
            verticalAlign="center"
            horizontalAlign="center"
            tokens={{ childrenGap: 10 }}
          >
            {!toYearly ? (
              <EFDropdown
                defaultSelectedKey={category}
                style={{ width: "17rem" }}
                options={categoryData}
                onChange={(_: any, item: any) =>
                  handleCategoryChange(item.text, item.color)
                }
              />
            ) : (
              <></>
            )}
            <DoughnutChart
              datasets={{
                labels: getMonthlyAmountsForIndex(monthByIdx).labels,
                datasets: [
                  {
                    label: "Expenses For Month",
                    data: toYearly
                      ? yrToDateDoughtnutData.map(
                          (e: any) => Math.round(e * 1e2) / 1e2
                        )
                      : getMonthlyAmountsForIndex(monthByIdx).values.map(
                          (e: any) => Math.round(e * 1e2) / 1e2
                        ),
                    backgroundColor: toYearly
                      ? props.categories?.map((e: any) => e.color)
                      : getMonthlyAmountsForIndex(monthByIdx).colors,
                    borderColor: "transparent",
                  },
                ],
              }}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          horizontalAlign="center"
          tokens={{ childrenGap: "6rem" }}
        >
          {isMobile ? (
            mobileCategories
          ) : (
            <>
              <Stack
                className={classes.categories}
                tokens={{ childrenGap: "1.2rem" }}
              >
                {categories}
              </Stack>
              <Stack className={classes.breakdown_container}>
                {transactionList(category)}
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      {settings && (
        <SubCategoryModal
          txnData={settings.txnData}
          catData={settings.catData}
          settingsCategories={baseCategoryData}
          isOpen={settings}
          onClickExit={() => setSettings(null)}
        />
      )}
    </Stack>
  );
};

export default Expenses;
