import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Card } from "@uifabric/react-cards";
import EFHeader from "../../components/UI/EFHeader/EFHeader";
import EFButton from "../../components/Atomic/EFButton/EFButton";

import profilePreview from "../../assets/images/profilePreview.png";
import signPreview from "../../assets/images/signPreview.svg";

import animations from "../../utils/animations.module.css";
import classes from "./OnboardingPage.module.css";
import * as screenActions from "../../store/actions/Screens";
import PreviewSlideShow from "../../components/UI/PreviewSlideShow/PreviewSlideShow";
import { useResponsive } from "../../hooks/useResponsive";
import TagManager from 'react-gtm-module';

const OnboardingPage: React.FC<{}> = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const showCarousel = useSelector(
    (state: any) => state.screens.onboarding_showCarousel
  );
  const { screenIsAtMost } = useResponsive();
  const realUser = useSelector((state: any) => state.data.realUser);

  // Google analytics set up for page
  const tagManagerArgs = {
    dataLayer: {
      userId: realUser?.trackingId,  
      page: 'Splash'
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  useEffect(() => {
    history.listen(() => {
      if (history.action === "POP") {
        if (!showCarousel) {
          dispatch(screenActions.onboardingChangeState(true));
        }
      }
    });
  }, [dispatch, history, showCarousel]);

  const renderToLogin = () => (
    <p className={classes.hasAccount}>
        Already have an account?{" "}
        <strong
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push("/signin");
          }}
        >
          Sign In
        </strong>
      </p>
  )

  return (
    <div
      className={`${classes.container} ${
        screenIsAtMost("tabPort") ? classes.containerMobile : null
      }`}
    >
      <EFHeader customStyle={{ display: 'block'}}/>
      <div className={classes.form}>
        {showCarousel && (
          <>
            <PreviewSlideShow />
            <div
              style={{
                margin: "4.5rem 0",
                display: "flex",
                width: "31.8rem",
                justifyContent: "space-around",
              }}
            >
              <EFButton
                id="getstarted"
                style={{ backgroundColor: "transparent", color: "#feb81e" }}
                text="GET STARTED"
                onClick={() => {
                  dispatch(screenActions.onboardingChangeState(false));
                }}
              />
              <EFButton
                id="login"
                text="SIGN IN"
                onClick={() => {
                  history.push("/signin");
                }}
              />
            </div>
          </>
        )}
        {!showCarousel && (
          <>
            <p className={classes.title}>Help us help you!</p>
            <Card horizontal className={classes.signAndProfileCard}>
              <div className={classes.cardSection}>
                <p
                  className={classes.headerStyle}
                  style={{ marginTop: "6rem" }}
                >
                  Get Connected
                </p>
                <p className={classes.headerStyle}>Get Organized</p>
                <p className={classes.headerStyle}>Get Learning</p>
                <p
                  className={classes.headerStyle}
                  style={{
                    marginTop: "6rem",
                    fontWeight: "bold",
                    marginBottom: "4.6rem",
                  }}
                >
                  Let's get started
                </p>
                <div
                  className={classes.cardContainers}
                  style={
                    screenIsAtMost("tabPort")
                      ? { flexDirection: "column" }
                      : null
                  }
                >
                  <Card
                    horizontal
                    className={`${classes.smallCard} ${animations.cardShadowExpand}`}
                    onClick={() => {
                      history.push("/signup");
                    }}
                  >
                    <img
                      style={{ height: "20rem", marginBottom: "-.5rem" }}
                      src={signPreview}
                      alt="SignUp"
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p className={classes.smallCardTitle}>Sign-up</p>
                      <p className={classes.smallCardContent}>
                        Make my own plan
                      </p>
                    </div>
                  </Card>
                </div>
              </div>
            </Card>
            {renderToLogin()}
          </>
        )}
      </div>
      <div className={classes.rightColumn}></div>
    </div>
  );
};

export default OnboardingPage;
