import React from 'react';

import addToPlaylistIcon from '../../../assets/images/planning/playlist/addToPlaylistIcon.svg';
import classes from './NewPlaylistButton.module.css';

interface Props {
    onClick: () => void;
    customStyle?: any
}

const NewPlaylistButton: React.FC<Props> = props => {

    return (
        <div id="create-playlist" className={classes.container} onClick={props.onClick} style={{...props.customStyle}}>
            <img src={addToPlaylistIcon} alt="create" />
            <p className={classes.text}>CREATE NEW PLAYLIST</p>
        </div>
    );
};

export default NewPlaylistButton;