/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Stack } from "office-ui-fabric-react";
import ChartCard from "../ChartCard/ChartCard";
import ScoopCarousel from "../ScoopCarousel/ScoopCarousel";
import Expenses from "../Dashboards/Expenses/Expenses";
import NetWorth from "../Dashboards/NetWorth/NetWorth";
import Investments from "../Dashboards/Investments/Investments";
import Retirement from "../Dashboards/Retirement/Retirement";
import Income from "../Dashboards/Income/Income";
import Debt from "../Dashboards/Debt/Debt";
import NetCash from "../Dashboards/NetCash/NetCash";
import Savings from "../Dashboards/Savings/Savings";
import ScoopDashboardPreview from "./ScoopDashboardPreview";
import { useSelector } from "react-redux";
import AddMoreAccsView from "../../UI/AddMoreAccsView/AddMoreAccsView";
import { useHistory } from "react-router-dom";
import { useScoop } from "../../../hooks/ScoopDashboards/useScoop";

interface IProps {
  isPreview?: boolean;
  transactionsData: any;
  accBalancesData: any;
  hasAccounts?: boolean;
  focusCategory?: string;
  onChange?: (db: string) => void;
  onAddAccsClick?: () => void;
}

const ScoopDashboardsView: React.FC<IProps> = (props) => {
  let history = useHistory<any>();
  const navDb = history?.location?.state?.selectedDashboard;
  const dbPreviews = useSelector(
    (state: any) => state.session.user?.scoop?.hiddenDashboards
  );
  const [dashboard, toggleDashboard] = useState(navDb || "expense");
  const {
    transactionCategories,
    incomeCategories,
    dataE,
    valueE,
    dataI,
    valueI,
    dataD,
    valueD,
    dataR,
    valueR,
    dataS,
    valueS,
    dataNW,
    valueNW,
    dataNC,
    valueNC,
    dataIn,
    valueIn,
    dashboardsData,
  } = useScoop(props.transactionsData, props.accBalancesData, dbPreviews);

  const [dbSet, setDbSet] = useState(dashboardsData);
  const focusDb = (db: string) => {
    const focusedDbIdx = dashboardsData.findIndex(({ id }) => id === db);
    if (focusedDbIdx === -1) {
      return;
    }
    const focusedDb = dashboardsData[focusedDbIdx];
    const dBCopyFilteringOutFocused = dashboardsData.filter((e) => e.id !== db);
    const newDbSet = [focusedDb, ...dBCopyFilteringOutFocused];
    setDbSet(newDbSet);
  };

  useEffect(() => {
    focusDb(dashboard);
  }, [valueE, valueI, valueD, valueR, valueS, valueNW, valueNC, valueIn]);

  const chartCards = dbSet.map((e: any) => (
    <div key={e.id}>
      <ChartCard
        key={e.title}
        title={e.title.toUpperCase()}
        subtitle={e.subtitle}
        value={props.hasAccounts ? e.value : 0}
        valueColor={e.color}
        img={e.img}
        isClicked={dashboard === e.id}
        onClick={() => toggleDashboard(e.id)}
      />
    </div>
  ));

  const aggr = (
    <AddMoreAccsView
      db={dashboardsData.find((e) => e.id === dashboard)?.title}
    />
  );

  return (
    <>
      {props.isPreview ? (
        // WE NEED A MOBILE VERSION FOR THIS
        <ScoopDashboardPreview
          hasAccounts={props.hasAccounts}
          dashboardData={dashboardsData}
          onAddAccsClick={props.onAddAccsClick}
          onChange={props.onChange}
        />
      ) : (
        <Stack style={{ alignSelf: "center", width: "100%" }}>
          <ScoopCarousel>{chartCards}</ScoopCarousel>
          {dashboard === "expense" &&
            (dataE.length ? (
              <Expenses
                categories={transactionCategories}
                transactionsData={props.transactionsData}
                focusCategory={props.focusCategory}
              />
            ) : (
              aggr
            ))}
          {dashboard === "income" &&
            (dataI.length ? (
              <Income
                categories={incomeCategories}
                transactionsData={props.transactionsData}
              />
            ) : (
              aggr
            ))}
          {dashboard === "debt" &&
            (dataD.length ? (
              <Debt accBalancesData={props.accBalancesData} />
            ) : (
              aggr
            ))}
          {dashboard === "retirement" &&
            (dataR.length ? (
              <Retirement accBalancesData={props.accBalancesData} />
            ) : (
              aggr
            ))}
          {dashboard === "saving" &&
            (dataS.length ? (
              <Savings
                accBalancesData={props.accBalancesData}
                transactionsData={props.transactionsData}
              />
            ) : (
              aggr
            ))}
          {dashboard === "networth" &&
            (dataNW.length ? (
              <NetWorth accBalancesData={props.accBalancesData} />
            ) : (
              aggr
            ))}
          {dashboard === "cashflow" &&
            (dataNC.length ? (
              <NetCash
                transactionsData={props.transactionsData}
                onClickIncomes={() => {
                  toggleDashboard("income");
                  focusDb("income");
                }}
                onClickExpenses={() => {
                  toggleDashboard("expense");
                  focusDb("expense");
                }}
              />
            ) : (
              aggr
            ))}
          {dashboard === "investment" &&
            (dataIn.length ? (
              <Investments accBalancesData={props.accBalancesData} />
            ) : (
              aggr
            ))}
        </Stack>
      )}
    </>
  );
};

export default ScoopDashboardsView;
