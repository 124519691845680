import React from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import changeProfileLabel from '../../../assets/images/changeProfileLabel.svg';
import createProfileLabel from '../../../assets/images/createProfileLabel.svg';
import { clearCanonicalProfile } from '../../../store/actions/Users';

import classes from './SwitchLabel.module.css';

interface Props {
    name: string;
    img: string;
    showSubtext: boolean;
    goBackToProfile: () => void;
    chooseOtherProfile: () => void;
}

const SwitchLabel: React.FC<Props> = props => {

    const dispatch = useDispatch();
    let history = useHistory();

    return (
        <div className={classes.container} onClick={() => {
            if (!props.showSubtext) {
                dispatch(clearCanonicalProfile(history));
                //EF-1764: Temporary solution until we define what to do in this cases
                const url = window.location.href;
                if (url.includes("connectthedots") || url.includes("lowdown")
                    || url.includes("nittygritty") || url.includes("careerplanning")) {
                    props.goBackToProfile();
                    history.push("/connect");
                }
            }
            else {
                props.chooseOtherProfile();
            }
        }}>
            <img alt="back" src={changeProfileLabel} style={{
                position: 'relative',
                left: '-3.3rem'
            }} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={props.img} alt="avatar" style={{ width: '5rem', marginLeft: '-2rem' }} />
                <div style={{ paddingLeft: '2rem' }}>
                    <p className={classes.text}>{props.showSubtext ? 'Click here to choose another profile' : 'Click here to go to my profile'}</p>
                    <p className={classes.subtext}>LOOKING AT {props.name.toUpperCase()}</p>
                </div>
            </div>
        </div>
    );
};

export default SwitchLabel;