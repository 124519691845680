import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Stack, INavLink } from 'office-ui-fabric-react/lib/';
import Menu from './Menu/Menu';
import ConnectAccounts from './ConnectAccounts/ConnectAccounts';
import classes from './NavigationView.module.css';
import animations from '../../utils/animations.module.css';
import SelectBubble from './SelectBubble';
import { useSelector } from 'react-redux';
import { eUserSettings } from '../UserSettings/UserSettings';
import efLogoMobile from '../../assets/images/efLogoMobile.svg';
import logo from '../../assets/images/logo.svg';
import { useResponsive } from '../../hooks/useResponsive';
import SignOutButton from './SignOutButton/SignOutButton';
import { logOut } from '../../store/actions/Users';
import { isCanonical } from '../../utils/global';

interface IProps {
    menuStyle?: string,
    style?: object,
    connectedKey?: string,
    onConnectButtonClick?: () => void,
    onConnectedLinkClick?: (e?: React.MouseEvent<HTMLElement>, item?: INavLink) => void,
    protectKey?: string,
    onProtectButtonClick?: () => void,
    onProtectLinkClick?: (e?: React.MouseEvent<HTMLElement>, item?: INavLink) => void,
    growKey?: string,
    onGrowButtonClick?: () => void,
    onGrowLinkClick?: (e?: React.MouseEvent<HTMLElement>, item?: INavLink) => void,
    connectedAccounts: number;
    profile: any;
    onConnectAccountClick: () => void;
    currentDashboard?: string;
    selectCardContent?: any;
    onSelectBubleClickHandler?: () => void;
};

const NavigationView: React.FC<IProps> = props => {
    let history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.session.user);
    const realUser = useSelector((state: any) => state.data.realUser);

    const { screenIsAtMost } = useResponsive();

    return (
        <div className={`${classes.menu} ${props.menuStyle ? props.menuStyle : null}
            ${props.menuStyle ? animations.animateMenu : null}`}>
            <Stack style={{ marginBottom: 50 }}>
                <img onClick={() => {
                    history.push('/')
                }} alt='ef-logo' src={screenIsAtMost("tabLand") ? efLogoMobile : logo} style={{ marginBottom: 57, width: 80, cursor: 'pointer' }} />
                <Menu
                    onConnectButtonClick={props.onConnectButtonClick}
                    onConnectedLinkClick={props.onConnectedLinkClick}
                    connectedKey={props.connectedKey}
                    protectKey={props.protectKey}
                    onProtectButtonClick={props.onProtectButtonClick}
                    onProtectLinkClick={props.onProtectLinkClick}
                    growKey={props.growKey}
                    onGrowButtonClick={props.onGrowButtonClick}
                    onGrowLinkClick={props.onGrowLinkClick}
                    currentDashboard={props.currentDashboard}
                />
            </Stack>
            <Stack
                horizontal
                horizontalAlign="center"
                wrap
                style={{ height: 80 }}
                tokens={{ childrenGap: 10 }}
            >
                <SelectBubble
                    id="profileCard"
                    currentUser={user.name}
                    img={props.selectCardContent?.img}
                    onClick={props.onSelectBubleClickHandler}
                    showDifferentLabel={realUser && Object.keys(realUser).length > 0}
                />
            </Stack>
            <ConnectAccounts profile={props.profile} connected={props.connectedAccounts} showConnectedAccounts={props.onConnectAccountClick} />
            {!isCanonical(user.name) && <SignOutButton onClick={() => {
                dispatch(logOut(history));
            }} />}
        </div>
    )
};

export default NavigationView;
