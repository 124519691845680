import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import { sessionService } from "redux-react-session";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { appendHeaders } from "../utils/FetchHeaders";
import { isCanonical } from '../utils/global';
import { getUpdatedUserFullProfile } from '../store/actions/Users';

import OnboardingPage from "../pages/OnboardingPage/OnboardingPage";
import ProfileSelectorPage from "../pages/ProfileSelectorPage/ProfileSelectorPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import SignUpPage from "../pages/SignUpPage/SignUpPage";
import MainMenuPage from "../pages/MainMenuPage/MainMenuPage";
import ConnectDashboardPage from "../pages/ConnectDashboardPage/ConnectDashboardPage";
import ProtectDashboardPage from "../pages/ProtectDashboardPage/ProtectDashboardPage";
import GrowDashboardPage from "../pages/GrowDashboardPage/GrowDashboardPage";
import CompleteProfilePage from "../pages/CompleteProfilePage/CompleteProfilePage";
import SmartCashflowSetup2 from "../pages/SmartCashflowSetup/SmartCashflowSetup2";
import AggregationPage from '../pages/AgreggationPage/AggregationPage';
import MyPlaylistPage from "../pages/MyPlaylistPage/MyPlaylistPage";
import GetOrganizedPage from "../pages/GetOrganizedPage/GetOrganizedPage";
import ScoopDashboardsPage from "../pages/ScoopDashboardsPage/ScoopDashboardsPage";
import KakeiboPage from "../pages/KakeiboPage/KakeiboPage";
import NudgetPage from "../pages/NudgetPage/NudgetPage";
import MyEqualFuturePage from "../pages/MyEqualFuturePage/MyEqualFuturePage";
import LifeEventPage from "../pages/LifeEventPage/LifeEventPage";
import SmartCashflowPage from "../pages/SmartCashflowPage/SmartCashflowPage";
import SolutionsPage from "../pages/SolutionsPage/SolutionsPage";
import InsurancePage from "../pages/InsurancePage/InsurancePage";
import LowDownPage from "../pages/LowDownPage/LowDownPage";

/* import { updateSessionStatus } from "../store/actions/Screens"; */

const GET_SESSION_STATUS_ENDPOINT = process.env.REACT_APP_GET_SESSION_STATUS;
const REFRESH_TOKEN_ENDPOINT = process.env.REACT_APP_REFRESH_TOKEN_ENDPOINT;

const AppNavigator: React.FC = (props) => {

  const authenticated = useSelector(
    (state: any) => state.session.authenticated,
    shallowEqual
  );
  const userData = useSelector(
    (state: any) => state.session.user,
    shallowEqual
  );

  const dispatch = useDispatch();
  let history = useHistory();

  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (
      userData &&
      !(Object.keys(userData).length === 0 && userData.constructor === Object)
    ) {
      const token = userData.access_token;
      fetch(GET_SESSION_STATUS_ENDPOINT, {
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
      }).then((response) => {
        if (response.ok) {
          setLoading(false);
          setRedirect(true);
        } else {
          setLoading(true);
          fetch(REFRESH_TOKEN_ENDPOINT, {
            method: "POST",
            headers: { ...appendHeaders(), Authorization: "Bearer " + token },
            body: JSON.stringify({
              refreshToken: userData.refresh_token,
            }),
          }).then((response) => {
            if (response.ok) {
              response.json().then((payload) => {
                if (!isCanonical(userData.name)) {
                  sessionService.saveSession(payload.data);
                  //localStorage.setItem('access_token', payload.data.access_token);
                  //localStorage.setItem('refresh_token', payload.data.refresh_token);
                }
                sessionService
                  .loadUser()
                  .then((user) => {
                    user.access_token = payload.data.access_token;
                    user.refresh_token = payload.data.refresh_token;
                    sessionService.saveUser(user).then((response) => { });
                  })
                  .catch((error) => console.error(error));
                // EF-1618 -> Needs to be tested since this is based on time expiry
                if (userData.length) {
                  setRedirect(true);
                }
              });
            } else {
              setLoading(false);
              setRedirect(false);
              sessionService
                .deleteSession()
                .then((response) => {
                  sessionService.deleteUser();
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          });
        }
      });
    } else {
      sessionService
        .loadUser()
        .then((user) => {
          sessionService.saveUser(user);
          //We get user's access and refresh token and check if session is still valid
          //const access_token = localStorage.getItem('access_token');
          //const refresh_token = localStorage.getItem('refresh_token');
          sessionService.loadSession()
            .then(session => {
              fetch(GET_SESSION_STATUS_ENDPOINT, {
                headers: { ...appendHeaders(), Authorization: "Bearer " + session.access_token },
              })
                .then(sessionStatus => {
                  if (sessionStatus.ok) {
                    //session still valid, let's get the user's info
                    dispatch(getUpdatedUserFullProfile(history, session.access_token, session.refresh_token,
                      !isCanonical(user.name)));
                    setLoading(false);
                    setRedirect(true);
                  }
                  else {
                    //session no longer valid, let's get new tokens
                    setLoading(true);
                    fetch(REFRESH_TOKEN_ENDPOINT, {
                      method: "POST",
                      headers: { ...appendHeaders(), Authorization: "Bearer " + session.refresh_token },
                      body: JSON.stringify({
                        refreshToken: session.refresh_token,
                      }),
                    })
                      .then(response => {
                        if (response.ok) {
                          response.json()
                            .then(auth => {
                              sessionService.saveSession(auth.data);
                              //localStorage.setItem('access_token', auth.data.access_token);
                              //localStorage.setItem('refresh_token', auth.data.refresh_token);
                              dispatch(getUpdatedUserFullProfile(history, auth.access_token, auth.refresh_token,
                                !isCanonical(user.name)));
                              setRedirect(true);
                            })
                        }
                        else {
                          setLoading(false);
                          setRedirect(false);
                          sessionService
                            .deleteSession()
                            .then((response) => {
                              sessionService.deleteUser();
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                        }
                      })

                  }
                })
            });
        })
        .catch((error) => {
          console.error(error);
          setRedirect(false)
          setLoading(false)
        });
    }
  }, [loading, userData]);

  // Improvement: Add spinner here.
  return loading ? null : (
    <Router>
      <PrivateRoute exact path="/protect/securevault" isSessionValid={userData}>
        <ProtectDashboardPage />
      </PrivateRoute>
      <PrivateRoute exact path="/protect/insurance" isSessionValid={userData}>
        <InsurancePage />
      </PrivateRoute>
      <PrivateRoute exact={false} path="/connect/lifeevent" isSessionValid={userData}>
        <LifeEventPage />
      </PrivateRoute>
      <PrivateRoute exact path="/connect/myplaylist" isSessionValid={userData}>
        <MyPlaylistPage />
      </PrivateRoute>
      <PrivateRoute exact path="/connect/getorganized/scoopdashboards" isSessionValid={userData}>
        <ScoopDashboardsPage />
      </PrivateRoute>
      <PrivateRoute exact path="/connect/getorganized/nudget" isSessionValid={userData}>
        <NudgetPage />
      </PrivateRoute>
      <PrivateRoute exact path="/connect/getorganized/kakeibo" isSessionValid={userData}>
        <KakeiboPage />
      </PrivateRoute>
      <PrivateRoute exact path="/connect/getorganized" isSessionValid={userData}>
        <GetOrganizedPage />
      </PrivateRoute>
      <PrivateRoute exact path="/connect/yourprogress" isSessionValid={userData}>
        <MyEqualFuturePage />
      </PrivateRoute>
      <PrivateRoute exact path="/connect/smartcashflow" isSessionValid={userData}>
        <SmartCashflowPage />
      </PrivateRoute>
      <PrivateRoute exact path="/connect" isSessionValid={userData}>
        <ConnectDashboardPage />
      </PrivateRoute>
      <PrivateRoute exact path="/aggregate" isSessionValid={userData}>
        <AggregationPage />
      </PrivateRoute>
      <PrivateRoute exact path="/grow/overview" isSessionValid={userData}>
        <GrowDashboardPage />
      </PrivateRoute>
      <PrivateRoute exact path="/grow/solutions" isSessionValid={userData}>
        <SolutionsPage />
      </PrivateRoute>
      <PrivateRoute exact path="/grow/lowdown" isSessionValid={userData}>
        <LowDownPage />
      </PrivateRoute>
      {/* Dead route */}
      {/* <Route exact path="/smartcashflowsetup" component={SmartCashflowSetup2} /> */}
      <Route exact path="/myplansetup" component={CompleteProfilePage} />
      <Route exact path="/expired">
        <LoginPage expired={true} />
      </Route>
      <Route exact path="/signup" component={SignUpPage} />
      <Route exact path="/signin" component={LoginPage} />
      <Route exact path="/health-check" />
      <Route exact path="/profiles" component={ProfileSelectorPage} />
      <Route
        exact
        path="/"
        component={(redirect && authenticated) ? MainMenuPage : OnboardingPage}
      />
    </Router>
  );
};

export default AppNavigator;
