import React, { useState, useEffect } from 'react';
import Tour, { Navigation, Dot, Controls, Arrow, Close, Badge } from 'reactour';
import Step, { styles } from '../TourUI/Step/Step';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import EFCheckbox from '../../Atomic/EFCheckbox/EFCheckbox';
import { useResponsive } from '../../../hooks/useResponsive';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_TOUR_GUIDE } from '../../../hooks/Apollo/mutations';
import * as actions from '../../../store/actions/Users';

import '../TourUI/CustomHelper/TourGuide.css';

const ScoopTour: React.FC<{}> = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector((state: any) => state.session.user);
    const [updateTourGuide] = useMutation(UPDATE_TOUR_GUIDE);

    const [checkbox, setCheckbox] = useState(false);
    const [showTour, setShowTour] = useState(!user.tourGuide?.scoop && user.actingAs === "ME");
    const [scoopTab, setScoopTab] = useState({key: '', title: '', description: ''});
    const [scoopDBs, setScoopDBs] = useState({key: '', title: '', description: ''});
    const [nudget, setNudget] = useState({key: '', title: '', description: ''});
    const [kakeibo, setKakeibo] = useState({key: '', title: '', description: ''});
    // const [lifeEvents, setLifeEvents] = useState({key: '', title: '', description: ''});

    useEffect(() => {
        const BASE_URL = 'https://cdn.contentful.com';
        const SPACE_ID = 'up7kjdcvffhd';
        const API_KEY =  'shmWB5u1iYWryX13wK2T97mzEoVcnH6F3Bp4lnBCLuw';

        const loadData = async () => {
            const resp = await fetch(`${BASE_URL}/spaces/${SPACE_ID}/entries?access_token=${API_KEY}&content_type=guideInfo`);
            const data = await resp.json();
            const entries = data.items.map(e => e.fields);

            setScoopTab(entries.find(e => e.key === "SCOOP_TAB"));
            setScoopDBs(entries.find(e => e.key === "SCOOP_DBS_WEB"));
            setNudget(entries.find(e => e.key === "NUDGET"));
            setKakeibo(entries.find(e => e.key === "KAKEIBO"));
            // setLifeEvents(entries.find(e => e.key === "LIFE_EVENTS_LIBRARY"));
        }
        if (showTour) {
            loadData();   
        }
    }, []);

    const tourConfigConnect = [
        {
            id: 'SCOOP_TAB', // Used as GET ORGANIZED temporarily
            selector: '[data-tour="get-organized"]',
            content: () => <Step title={scoopTab?.title} desc={scoopTab?.description} />,
            position: 'bottom',
            disableInteraction: true,
            style: styles.tour,
        },
        {
            id: 'SCOOP_DBS_WEB',
            selector: '[data-tour="scoop-dbs"]',
            content: () => <Step title={scoopDBs?.title} desc={scoopDBs?.description} />,
            position: 'left',
            disableInteraction: true,
            style: styles.tour,
        },
        {
            id: 'NUDGET',
            selector: '[data-tour="nudget"]',
            content: () => <Step title={nudget?.title} desc={nudget?.description} />,
            position: 'left',
            disableInteraction: true,
            style: styles.tour,
        },
        {
            id: 'KAKEIBO',
            selector: '[data-tour="kakeibo"]',
            content: () => <Step title={kakeibo?.title} desc={kakeibo?.description} />,
            position: 'left',
            disableInteraction: true,
            style: styles.tour,
        },
        // {
        //     id: 'LIFE_EVENTS_LIBRARY',
        //     selector: '[data-tour="my-life-events"]',
        //     content: () => <Step title={lifeEvents?.title} desc={lifeEvents?.description} />,
        //     position: 'left',
        //     disableInteraction: true,
        //     style: styles.tour,
        // },
    ]

    const onRequestClose = async () => {
        setShowTour(false);
        if (checkbox) {
            await updateTourGuide({
                variables: {
                    tourGuide: {
                        //...user.tourGuide,
                        scoop: true,
                        protect: true,
                        grow: true,
                    }
                }
            }).catch(error => console.error(error));
            dispatch(actions.getUpdatedUserFullProfile(history));
        }
    }

    function CustomHelper({ current, content, totalSteps, gotoStep, close }) {
        const { screenIsAtLeast } = useResponsive();
    
        return (
          <main className="CustomHelper__wrapper">
            <Badge className="CustomHelper_badge">{current + 1}</Badge>
            <div className="CustomHelper__content">
              {content}
              <Controls
                data-tour-elem="controls"
                className="CustomHelper__controls"
              >
                <div style={{display:'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: '1rem'}}>
                  <Arrow
                    onClick={() => gotoStep(current - 1)}
                    disabled={current === 0}
                    className="CustomHelper__navArrow"
                    style={{color: 'white', backgroundColor: 'red'}}
                  />
                  {screenIsAtLeast('desktop') && (
                      <Navigation data-tour-elem="navigation">
                          {Array.from(Array(totalSteps).keys()).map((li, i) => (
                              <Dot
                              key={li}
                              className="CustomHelper__dot"
                              onClick={() => current !== i && gotoStep(i)}
                              current={current}
                              index={i}
                              disabled={current === i}
                              showNumber={true}
                              data-tour-elem="dot"
                              />
                          ))}
                      </Navigation>
                  )}
                  <Arrow
                    onClick={() => gotoStep(current + 1)}
                    disabled={current === totalSteps - 1}
                    className="CustomHelper__navArrow"
                    inverted
                  />
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <EFCheckbox 
                        id="showtour" 
                        checked={checkbox} 
                        onChange={() => setCheckbox(!checkbox)}
                    />
                    <EFLabel style={{color: '#293861', fontSize: '1.3rem', marginLeft: '.5rem', fontWeight: '400'}}>
                        Do not show this again
                    </EFLabel>
                </div>
              </Controls>
            </div>
            <Close
                className="CustomHelper__exit"
                onClick={close}
            />
          </main>
        )
    }

    return (
        <Tour
            CustomHelper={CustomHelper}
            onRequestClose={onRequestClose}
            steps={tourConfigConnect}
            isOpen={showTour}
            rounded={5}
            accentColor="#293861"
            maskClassName="mask"
            disableInteraction
            onAfterOpen={disableBodyScroll}
            onBeforeClose={enableBodyScroll}
        />
    )
};

export default ScoopTour;
