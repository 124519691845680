import React from 'react';
import { Toggle, IToggleStyleProps, IToggleStyles } from 'office-ui-fabric-react/lib/';

interface IProps {
    style?: object,
    isChecked: boolean,
    onClick: () => void,
};

const EFToggle: React.FC<IProps> = props => {
    const toggleStyles = (props: IToggleStyleProps): IToggleStyles => {
        const { checked } = props;
        return {
            root: {},
            label: {},
            container: {},
            pill: {
                width: 35,
                height: 22,
                borderColor: !checked ? '#E5E5E5' : '#feb81e',
                backgroundColor: !checked ? '#E5E5E5' : '#feb81e',
                selectors: {
                    '&:hover': {
                        borderColor: !checked ? '#E5E5E5' : '#feb81e',
                        backgroundColor: !checked ? '#E5E5E5' : '#feb81e',
                    },
                    '&:hover .ms-Toggle-thumb': {
                        backgroundColor: '#fff'
                    }
                },
            },
            thumb: {
                height: 18,
                width: 18,
                backgroundColor: '#fff',
                border: '0.7 solid red',
                selectors: {
                    '&:hover .ms-Toggle-thumb': {
                        backgroundColor: '#fff'
                    }
                }
            },
            text: {},
        }
    };

        

    return (
        <Toggle 
            onClick={props.onClick}
            checked={props.isChecked} 
            styles={toggleStyles} 
            style={props.style}    
        />
    )
};

export default EFToggle;
