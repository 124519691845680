/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-array-constructor */
import { currMonth, elevenMonthsAgo, currJan, getMonths } from '../../utils/dateTools';
import { useState, useEffect } from 'react';
import moment from 'moment';

// Generate month intervals based on "monthly"
const { fullMonths: fullMonthsM,chartMonths, dates } = getMonths(elevenMonthsAgo, currMonth);
// Generate month intervals based on "yr to date"
const { fullMonths: fullMonthsY, chartMonths: chartMonthsYrly } = getMonths(currJan, currMonth);
const containerTypes = ["bank", "investment", "retirement"];

export const useSavingsData = (accBalancesData: any, transactionsData: any, toYearly: boolean) => {
    const fullMonths = toYearly ? fullMonthsY : fullMonthsM;

    const data = accBalancesData.filter((e: any) => e.account.dashboards.find((db: any) => db === 'saving'));

    const amountsBySortedMonths = data && data.map((e: any) => {
        return {
            account: e.account,
            historicalBalances: e.historicalBalances.map((month: any) => {
                return {date: month.date, amount: month.balance.amount}
            }).sort((a: any, b: any) => b.date.localeCompare(a.date))
        }
    });

    const x = amountsBySortedMonths && amountsBySortedMonths.map((acc: any) =>
        {
            return {
                account: acc.account,
                historicalBalances: dates.map((e: any) => {
                    const currM = parseInt(e.split(('-'))[1]) - 1;
                    const targetObj = acc.historicalBalances && acc.historicalBalances.find((x: any) => x && parseInt(x.date.split(('-'))[1]) - 1 === currM);
                    if (targetObj) {
                        return {date: targetObj.date, amount: targetObj.amount};
                    } else {
                        return {date: e, amount: 0};
                    }
                })
            }
        } 
    );

    const y = x.map((acc: any) => {
        return {
            account: acc.account,
            historicalBalances: acc.historicalBalances.filter((e: any) => e.date >= currJan.format('YYYY-MM-01') && e.date < (moment().set('date', 1).add(1, 'months')).format('YYYY-MM-01'))
        }
    });

    const sortedData = toYearly ? y : x;

    const [test, setTest] = useState(Array());
    useEffect(() => {
        setTest(sortedData);
    }, [toYearly, accBalancesData]);

    // Grab balances per account
    const balances = test && test.map((e: any) => e.historicalBalances.map((x: any) => x.amount));

    // Handles passing data needed for bar chart
    const monthlySums = (toYearly ? chartMonthsYrly  : chartMonths).map((_, i) => balances && balances.reduce((a: any, c: any) => {
        const val = c[i] ? c[i] : 0;
        return a + val;
    }, 0));

    // Handles passing data needed for doughnut chart
    const getAccountBalancesByMonth = (idx: number) => {
        return balances && balances.map((e: any) => e.filter((_: any, i: any) => i === idx));
    };

    // Data monthly by category
    const dataByCategory = containerTypes.map((container: any) => sortedData && sortedData.filter((acc: any) => {
        if (container === 'retirement') {
            return acc.account?.dashboards.find(db => db === 'retirement')
        } else {
            return container === acc.account.CONTAINER
        }
    }));

    const balancesByCategory = sortedData && dataByCategory.map((category: any) => category.map((e: any) => e.historicalBalances.map((x: any) => x.amount)));
    const monthlySumsByCategory = sortedData && balancesByCategory.map((bal: any) => (toYearly ? chartMonthsYrly  : chartMonths).map((_, i) => bal && bal.reduce((a: any, c: any) => {
        const val = c[i] ? c[i] : 0;
        return a + val;
    }, 0)));
    const getCategoryTotalsByMonth = (idx: number) => {
        return sortedData && monthlySumsByCategory.map((e: any) => e.filter((_: any, i: any) => i === idx)).flat();
    };

    // Data "Year to Date" by category
    const yearToDateByCategory = sortedData && monthlySumsByCategory.map((category: any) => category.reduce((a: number, c: number) => a += c, 0));
    const totalYearToDate = sortedData && yearToDateByCategory.reduce((a: number, c: number) => a += c, 0);

    // EMERGENCY FUND CALCULATIONS
    const dataT = transactionsData.filter((e: any) => e.dashboards.find((db: any) => db === 'income'));

    // Handles data needed for emergency fund: Grab monthly salary
    const getSalaryAmount = () => {
        if (dataT && dataT.length > 0) {
            const x = dataT && dataT.filter((e: any) => e.category === 'wages');
            return x && x[0]?.amount ? x[0].amount?.amount : 0;
        } else {
            return 0
        }
    };

    // Get total monthly income from bank container accounts
    const getTotalMonthlyIncome = () => x && x.map((e: any) => {
        if (e.account.CONTAINER === containerTypes[0]) {
            const l = e.historicalBalances.length - 1;
            return e.historicalBalances[l] && e.historicalBalances[l].amount
        } else {
            return 0
        }
    }).reduce((a: any, c: any) => a += c, 0);

    // Get percentage of funds based on 3 months of salary / total income of this month (check if salary is 0 to prevent infinity %)
    const funds =  
        !getSalaryAmount() ? 0 : Math.round(getTotalMonthlyIncome() / (getSalaryAmount() * 3) * 100);
    const percentFunds = funds > 100 ? 100 : funds;


    return {
        fullMonths,
        chartMonths,
        chartMonthsYrly,
        containerTypes,
        sortedData,
        test,
        setTest,
        getCategoryTotalsByMonth,
        percentFunds,
        monthlySums,
        getAccountBalancesByMonth,
        monthlySumsByCategory,
        yearToDateByCategory,
        totalYearToDate
    };
};
