import React from 'react';

import classes from './Footer.module.css';

const Footer: React.FC<{}> = props => {
    return (
        <div className={classes.footer}>
            <div className={classes.bold}>© EqualFuture Corp. All rights reserved.</div>
            <div>info@equalfuture.com</div>
        </div>
    )
};

export default Footer;
