import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useResponsive } from '../../hooks/useResponsive';
import Fastlink from '../../components/UI/Fastlink/Fastlink';

import classes from './AggregationPage.module.css';
import EFHeader from '../../components/UI/EFHeader/EFHeader';
import TagManager from 'react-gtm-module';

const AggregationPage: React.FC<{}> = () => {

    localStorage.setItem("fastlinkEnd", 'false');
    const { screenIsAtMost } = useResponsive();
    const realUser = useSelector((state: any) => state.data.realUser);

    // Google analytics set up for page
    const tagManagerArgs = {
        dataLayer: {
            userId: realUser?.trackingId,
            page: 'Aggregate'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    return (
        <div className={`${classes.container} ${screenIsAtMost('tabPort') ? classes.containerMobile : null}`}>
            <EFHeader onNavToMain customStyle={{ display: 'block'}}/>
            <div className={classes.form}>
                <Fastlink />
            </div>
            <div style={{ flex: 1 }}></div>
        </div>
    )
};

export default AggregationPage;