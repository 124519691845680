import React, { useState, useEffect } from 'react';
import { Card } from '@uifabric/react-cards';
import classes from './PreviewSlideShow.module.css';
import { Animated } from 'react-animated-css';
import { useResponsive } from '../../../hooks/useResponsive';

import imageCarousel1 from '../../../assets/images/onboardingCarousel1.svg';
import imageCarousel2 from '../../../assets/images/onboardingCarousel2.svg';
import imageCarousel3 from '../../../assets/images/onboardingCarousel3.svg';

interface IProps {
    stackClassNameHeroImg?: string;
    stackClassNameCard?: string;
    stackClassNameContent?: string;
};

const carouselContent = [
    {
        title: "Make a Plan",
        p1: 'Plan your life',
        p2: 'Understand today and the impact of your decisions in the future',
        p3: 'Insights, unique tools and simulations using real life events',
        hero: imageCarousel1
    },
    {
        title: 'Get Organized',
        p1: 'Simple snapshots of your financial life',
        p2: 'Your security. Our priority',
        p3: 'Protected by SSL and 256 bit encryption',
        hero: imageCarousel2
    },
    {
        title: 'Learn from Others',
        p1: 'Access simulated profiles of people that are tackling different life events using game like interactive tools',
        p2: 'This makes learning simple, fun and relevant',
        hero: imageCarousel3
    }
]

const PreviewSlideShow: React.FC<IProps> = props => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { screenIsAtMost } = useResponsive();

    useEffect(() => {
        const repeatEvent = setInterval(() => {
            setCurrentSlide(val => (val + 1) % 3)
        }, 5000);
        return () => {
            clearInterval(repeatEvent)
        }
    }, []);

    return (
        <>
            <p className={classes.title}>{carouselContent[currentSlide].title}</p>
            <Card horizontal className={`${classes.card} ${props.stackClassNameCard} ${screenIsAtMost('mobile') ? classes.cardMobile : ''}`}>
                <Card.Section 
                    className={`${props.stackClassNameContent}`}>
                    {currentSlide === 0 && <Animated className={classes.centeredContent} animationIn="fadeIn" animationOut="fadeOut"
                        animationInDelay={500} animationInDuration={2000}
                        animationOutDuration={2000}
                        animationOutDelay={500} isVisible={currentSlide === 0}>
                        <img className={classes.heroImage} style={{ paddingTop: '3rem' }} src={carouselContent[currentSlide].hero} alt="hero" />
                        <div className={classes.contentTextContainer}>
                            <p className={classes.contentText}>{carouselContent[currentSlide].p1}</p>
                            <p className={classes.contentText}>{carouselContent[currentSlide].p2}</p>
                            <p className={classes.contentText}>{carouselContent[currentSlide].p3}</p>
                        </div>
                    </Animated>}
                    {currentSlide === 1 && <Animated className={classes.centeredContent} animationIn="fadeIn" animationOut="fadeOut"
                        animationInDelay={500} animationInDuration={2000}
                        animationOutDuration={2000}
                        animationOutDelay={500} style={{ height: 'inherit' }} isVisible={currentSlide === 1}>
                        <img className={classes.heroImage} style={{ paddingTop: '3rem' }} src={carouselContent[currentSlide].hero} alt="hero" />
                        <div className={classes.contentTextContainer}>
                            <p className={classes.contentText}>{carouselContent[currentSlide].p1}</p>
                            <p className={classes.contentText}>{carouselContent[currentSlide].p2}</p>
                            <p className={classes.contentText} style={{ marginTop: '2rem' }}>{carouselContent[currentSlide].p3}</p>
                        </div>
                    </Animated>}
                    {currentSlide === 2 && <Animated className={classes.centeredContent} animationIn="fadeIn" animationOut="fadeOut"
                        animationInDelay={500} animationInDuration={2000}
                        animationOutDuration={2000}
                        animationOutDelay={500} style={{ height: 'inherit' }} isVisible={currentSlide === 2}>
                        <img className={classes.heroImage} style={{ paddingRight: '4rem', paddingTop: '3rem' }} src={carouselContent[currentSlide].hero} alt="hero" />
                        <div className={classes.contentTextContainer}>
                            <p className={classes.contentText}>{carouselContent[currentSlide].p1}</p>
                            <p className={classes.contentText} style={{ marginTop: '2rem' }}>{carouselContent[currentSlide].p2}</p>
                        </div>
                    </Animated>}
                </Card.Section>
            </Card>
        </>
    )
};

export default PreviewSlideShow;
