import { useState } from 'react';


export const useConfigButtons = () => {
    const [setup, toggleSetup] = useState<boolean>(true);
    const [simu, toggleSimu] = useState<boolean>(false);
    const [lvl, toggleLvl] = useState<boolean>(false);
    
    const handleAllConfigs = () => {
        toggleSetup(false); 
        toggleSimu(false); 
        toggleLvl(false);
    };
    const onSetup = () => {
        handleAllConfigs();
        toggleSetup(true);
    };
    const onSimu = () => {
        handleAllConfigs();
        toggleSimu(true);
    };
    const onLvl = () => {
        handleAllConfigs();
        toggleLvl(true);
    };

    return {
        setup, onSetup,
        simu, onSimu,
        lvl, onLvl
    };
}