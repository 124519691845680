import React from "react";
import AppNavigator from "./navigation/AppNavigator";
import setUpIcons from "./utils/SetUpIcons";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { sessionReducer, sessionService } from "redux-react-session";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";

import UserReducer from "./store/reducers/Users";
import DataReducer from "./store/reducers/Data";
import OnboardingReducer from "./store/reducers/Onboarding";
import CareerPlanningReducer from "./store/reducers/CareerPlanning";
import AggregationReducer from "./store/reducers/Aggregation";
import ScreensReducer from "./store/reducers/Screens";
import CalculatorReducer from "./store/reducers/Calculator";
import Apollo from "./components/Apollo/Apollo";
import TagManager from "react-gtm-module";

const rootReducer = combineReducers({
  user: UserReducer,
  data: DataReducer,
  onboarding: OnboardingReducer,
  calculator: CalculatorReducer,
  career: CareerPlanningReducer,
  aggregation: AggregationReducer,
  screens: ScreensReducer,
  session: sessionReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(ReduxThunk))
);

sessionService.initSessionService(store, {
  driver: "LOCALSTORAGE",
});

const tagManagerArgs = {
  gtmId: "GTM-M6D6VS2",
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREV,
  dataLayerName: "PageDataLayer",
};

function App() {
  if (process.env.NODE_ENV !== "development") {
    TagManager.initialize(tagManagerArgs);
  }
  setUpIcons();

  return (
    <Provider store={store}>
      <Apollo>
        <AppNavigator />
      </Apollo>
    </Provider>
  );
}

export default App;
