/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../EFLabel/EFLabel';
import aggregationIcon from '../../../assets/icons/aggregationIcon.svg';
import aggregationOffIcon from '../../../assets/icons/aggregationOffIcon.svg';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../utils/Masking';

import classes from './CurrencyInput.module.css';
import useDebounce from '../../../hooks/useDebounce';

interface IProps {
    value: number | string;
    value2?: number | string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
    placeholder?: string;
    name?: string;
    stackClassName?: string;
    style?: object;
    onClickX?: () => void;
    smartCashflow?: boolean;
    isAggr?: (isAggr: boolean) => void;
};

const CurrencyInput: React.FC<IProps> = props => {
    const [color, setColor] = useState(
        props.value2 && props.value === props.value2 ? '#2695C7' : '#979797'
    );

    const onConfirmButtonClickHandler = () => {
            if (color === '#979797') {
                setColor('#2695C7');
                props.onChange(props.value2);
            } else {
                setColor('#979797');
                props.onChange(0);
            }
    }

    useEffect(() => {
        // Adding a timeout to wait for capsule animation to finish
        setTimeout(() => {
            props.isAggr && props.isAggr(!!props.value2)
        }, 1500)
    }, [])

    useEffect(() => {
        props.value2 === props.value ? setColor('#2695C7') : setColor('#979797');
    }, [props.value])

    return (
        <>
            <Stack
                horizontal
                horizontalAlign='center'
                // onMouseEnter={() => { }}
                // onMouseLeave={() => { }}
            >
                <Stack
                    verticalAlign='center'
                    style={{ position: 'absolute', left: color === '#2695C7' ? '-3px' : '-2px' }}
                >
                    {props.value2 ? 
                        <img
                            id="aggr-val"
                            data-tour={color === '#2695C7' && 'aggr-icon'}
                            alt="apply-aggregagtion"
                            src={color === '#2695C7' ? aggregationIcon : aggregationOffIcon}
                            className={classes.x}
                            onClick={onConfirmButtonClickHandler}
                            style={{margin: '0.7rem 0 0 1rem'}}
                        /> : <></>}
                </Stack>
                <Stack
                    className={classes.container}
                >
                    <EFLabel
                        style={{ marginLeft: '1rem', fontSize: '2rem', color: color }}
                    >
                        {props.placeholder}
                    </EFLabel>
                    <Stack horizontal>
                        <MaskedInput
                            style={{...props.style, color: color}}
                            name={props.name}
                            mask={numberMask}
                            placeholder={props.value !== null ? props.value.toString() : '$0'}
                            value={props.value}
                            onChange={(event) => props.onChange(event)}
                            className={`${classes.maskedInput} 
                                ${props.stackClassName}`}
                        />
                    </Stack>
                </Stack>
            </Stack>
            {props.value2 && props.value !== props.value2 ? 
                <EFLabel style={{ color: '#2695C7', fontWeight: 'unset', textAlign: 'center' }}>
                    {`Your aggregated amount is ${maskValue((props.value2 as number), numberMask)}`}
                </EFLabel> : <></>
            }
        </>
    )
};

// Number mask used for USD currency
// Can move these as props for more customization
const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowLeadingZeroes: false,
    allowNegative: false,
});

export default CurrencyInput;
