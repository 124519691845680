import React from 'react';
import classes from './Step.module.css';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';

interface IProps {
    title?: string;
    desc?: string;
};

const Step: React.FC<IProps> = props => {

    return (
        <div className={classes.container}>
            <EFLabel style={styles.title}>{props.title}</EFLabel>
            <EFLabel style={styles.description}>{props.desc}</EFLabel>
        </div>
    )
};

export const styles = {
    tour: {
        backgroundColor: 'rgba(254, 184, 30, 0.8)',
        fontSize: '1.5rem',
        fontFamily: 'brandon-grotesque',
        fontWeight: 'bold',
        borderRadius: '.5rem',
    },
    title: {
        fontSize: '1.8rem', 
        color: '#FFF', 
        marginBottom: '1rem'
    },
    description: {
        fontSize: '1.6rem', 
        color: '#FFF', 
        fontWeight: 'normal',
        paddingBottom: '3rem',
    }
}


export default Step;
