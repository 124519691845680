import React, { useState, useEffect } from 'react';
import classes from './CFSetupCapsule.module.css';
import { Stack } from 'office-ui-fabric-react';
import MaskedLabelPair from '../../../components/UI/MaskedLabelPair';

interface IProps {
    style?: object,
    title?: string,
    value?: number,
    color?: string,
    icon?: string,
    initOpen?: boolean,
    onToggle?: () => void,
    disableOpenAnim?: boolean,
};

const CFSetupCapsule: React.FC<IProps> = props => {
    const [opened, setOpened] = useState<boolean>(true);
    
    useEffect(() => {
        const timer = props.onToggle && setTimeout(() => {
            setOpened(props.initOpen);
        }, 500);
        return () => clearTimeout(timer);
    }, [props.initOpen, props.onToggle])

    const onClick = () => {
        setOpened(!opened);
        if (props.onToggle) {
            props.onToggle();
        }
    }

    return (
        <>
         <div className={`${classes.accordion} ${opened && !props.disableOpenAnim && classes.accordionopened}`}>
                <div 
                    onClick={onClick} 
                    className={classes.line}
                    style={{
                        backgroundImage: 
                            `-webkit-linear-gradient(left, ${props.color}, ${props.color} 20%,
                            transparent 20%, transparent 100%)`
                    }}
                >
                    <div className={classes.iconTransContainer}>
                        <img className={classes.iconTrans} src={props.icon} alt='icon' />
                    </div>
                    <div className={classes.label}>
                        <MaskedLabelPair
                            title={props.title}
                            value={props.value}
                            valueStyle={{ color: '#9398A5', fontSize: opened && !props.disableOpenAnim ? '2.4rem' : '2rem', fontWeight: 'bold' }}
                            titleStyle={{ fontSize: opened && !props.disableOpenAnim ? '2.2rem' : '1.8rem' }}
                        />
                    </div> 
                    <span className={`${classes.icon} ${opened && classes.iconopened}`} />
                </div>
            </div>
            <div 
                className={`${classes.inner} ${opened && classes.inneropened}`}
            >
                <Stack 
                    verticalAlign='center' 
                    tokens={{childrenGap: '1.3rem'}} 
                    className={`${classes.content} ${opened && classes.contentopened}`}
                >
                    {props.children}
                </Stack>
            </div>
        </>
    )
};

export default CFSetupCapsule;
