import createNumberMask from "text-mask-addons/dist/createNumberMask";

// Applies a masking to a number using regex function
const maskValue = (value: number, numberMask: (str: string) => []) => {
    const valStr = value ? value.toString() : '0';
    const mask = numberMask(valStr);
    const valArr = Array.from(valStr);

    return mask.map((char: any) => {
        return (char instanceof RegExp) ? valArr.shift() : char;
    }).join('');
};

// us currency masking
export const numberMaskUS = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowLeadingZeroes: false,
    allowNegative: false,
});

export default maskValue;
