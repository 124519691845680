import React, { useState } from 'react';
import classes from './EFAccordion.module.css';
import EFLabel from '../EFLabel/EFLabel';
import { Stack } from 'office-ui-fabric-react';

interface IProps {
    style?: object,
    title?: string,
};

const EFAccordion: React.FC<IProps> = props => {
    const [opened, setOpened] = useState<boolean>(true);
    
    return (
        <div className={`${classes.accordion} ${opened && classes.accordionopened}`}>
            <div onClick={() => setOpened(!opened)} className={`${classes.line} ${opened && classes.lineopened}`}>
                <EFLabel style={{fontWeight: 'normal' as 'normal', fontSize: 17}}>{props.title}</EFLabel>
                <span 
                    className={`${classes.icon} ${opened && classes.iconopened}`}
                />
            </div>
            <div className={`${classes.inner} ${opened && classes.inneropened}`}>
                <Stack 
                    verticalAlign='center' 
                    tokens={{childrenGap: 20}} 
                    className={`${classes.content} ${opened && classes.contentopened}`}
                >
                    {props.children}
                </Stack>
            </div>
        </div>
    )
};

export default EFAccordion;
