import React from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./LibDependency/components"; // example render components - source below

const sliderStyle = {
  position: "relative" as "relative",
  width: "100%" as '100%',
};

interface IProps {
  onChange: (values: readonly number[]) => void,
  value: number,
  min: number,
  max: number,
};

const EFScaledStepper: React.FC<IProps> = props => {

    return (
      <div style={{ height: 60, width: "100%" }}>
        <Slider
          mode={2}
          step={1}
          domain={[props.min, props.max]}
          rootStyle={sliderStyle}
          onChange={props.onChange}
          values={[props.value]}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={[props.min, props.max]}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={7}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <Tick key={tick.id} tick={tick} count={ticks.length} />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    );
}

export default EFScaledStepper;
