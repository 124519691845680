import React from "react";
import { TextField } from "office-ui-fabric-react/lib/TextField";

interface Props {
  id?: string;
  value: string;
  onChange: (event: any) => void;
  reference: any;
  onBackSpace?: () => void;
}

const EFCodeInput: React.FC<Props> = (props) => {
  return (
    <TextField
      onKeyUp={(e) => {
        if (e?.key === "Backspace") {
          props.onBackSpace && props.onBackSpace();
        }
      }}
      id={props.id}
      componentRef={props.reference}
      borderless
      styles={{
        root: {
          marginTop: "0px !important",
          width: "17%",
        },
        fieldGroup: {
          backgroundColor: "rgba(41, 56, 97, 0.1)",
          borderRadius: "4.8px",
          height: "3em",
        },
        field: {
          padding: 0,
          textAlign: "center",
          color: "#293861",
          fontSize: "large",
        },
      }}
      onChange={props.onChange}
      value={props.value}
    />
  );
};

export default EFCodeInput;
