import React, { useState } from 'react';
import TransferModal from './TransferModal';
import UnsignedModal from './UnsignedModal';

import classes from './Transfer.module.css';
import animations from '../../../utils/animations.module.css';
import transferIcon from '../../../assets/icons/transfer.svg';

interface Props {
    lifeEventName: string;
    actingAs: string;
    isLoggedUser?: boolean;
    onConfirm: () => void;
    onDismiss: () => void;
}

const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const LifeEventTransfer: React.FC<Props> = ( { lifeEventName, actingAs, isLoggedUser = false, onConfirm, onDismiss } ) => {
    const [transferModal, setTransferModal] = useState(false);
    const [unsignedModal, setUnsignedModal] = useState(false);

    const handleTransferModal = (open: boolean) => {
        if (open)
            window.scrollTo(0, 0);
        setTransferModal(open);
    }

    const handleUnsignedModal = (open: boolean) => {
        if (open)
            window.scrollTo(0, 0);
        setUnsignedModal(open);
    }

    const openNavigateModalFromTransfer = () => {
        onConfirm();
        handleTransferModal(false);
    }

    if (!lifeEventName)
        return null;

    return (
        <>
            <div onClick={() => isLoggedUser ? handleTransferModal(true) : handleUnsignedModal(true)}>
                <div className={ `${classes.card} ${animations.cardShadowExpand}` }>
                    <div className={classes.dismiss} onClick={onDismiss}>Dismiss</div>
                    <img alt='transfer-icon' src={transferIcon} className={classes.transferImg} />
                    <div className={classes.text}>
                        <div className={classes.subtext1}>{`Good job exploring ${capitalize(actingAs)}'s ${capitalize(lifeEventName)}!`}</div>
                        <div className={classes.subtext2}>LET'S MAKE IT YOUR OWN</div>
                    </div>
                </div>
            </div>
            {transferModal && <TransferModal isOpen={transferModal} handleModal={() => handleTransferModal(false)} onConfirm={openNavigateModalFromTransfer}/>}
            {unsignedModal && <UnsignedModal isOpen={unsignedModal} handleModal={() => handleUnsignedModal(false)}/>}
        </>
    )
}
