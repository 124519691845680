import React from 'react';
import { useSelector } from 'react-redux';
import { DeviceUUID } from 'device-uuid';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { HttpLink } from 'apollo-link-http';
import { cache } from './cache';

const Apollo: React.FC = props => {
    const user = useSelector((state: any) => state.session.user);
    const token = user?.access_token;
  
    const firstLink = new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
        }
    });
  
    const cashFlowLink = new HttpLink({
        // uri: 'process.env.REACT_APP_CASHFLOW_ENDPOINT',
        //cashflow 2:
        uri: process.env.REACT_APP_CASHFLOW_2_ENDPOINT,
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
            "x-equalfuture-device-id": new DeviceUUID().get(),
            'x-cashflow-version': 2.5,
        }
    });
  
    const client = new ApolloClient({
        link: ApolloLink.split(
            operation => operation.getContext().clientName === "cashFlow",
            cashFlowLink,
            firstLink
        ),
        cache: cache,
        connectToDevTools: process.env.NODE_ENV === 'development',
    });

    return (
        <ApolloProvider client={client}>
            {props.children}
        </ApolloProvider>
    );
};

export default Apollo;
