/* eslint-disable @typescript-eslint/no-array-constructor */
import { currMonth, elevenMonthsAgo, currJan, getMonths } from '../../utils/dateTools';
import { useState, useEffect } from 'react';
import moment from 'moment';

// Generate month intervals based on "monthly"
const { fullMonths: fullMonthsM,chartMonths, dates } = getMonths(elevenMonthsAgo, currMonth);
// Generate month intervals based on "yr to date"
const { fullMonths: fullMonthsY, chartMonths: chartMonthsYrly } = getMonths(currJan, currMonth);

/* const accountTypes = [
    "IRA", 
    "401K", 
    "403B", 
    "529_PLAN", 
    "ROTH", 
    "ROTH_CONVERSION", 
    "ROTH_IRA", 
    "SEP_IRA", 
    "ROTH_403B", 
    "SPOUSAL_IRA", 
    "SPOUSAL_ROTH_IRA", 
    "SARSEP_IRA",
    "INDIVIDUAL"
]; */

export const useRetirementData = (accBalancesData: any, toYearly: boolean) => {
    const fullMonths = toYearly ? fullMonthsY : fullMonthsM;
    const data = accBalancesData.filter((e: any) => e.account.dashboards.find((db: any) => db === 'retirement'));

    const amountsBySortedMonths = data && data.map((e: any) => {
        return {
            account: e.account,
            historicalBalances: e.historicalBalances.map((month: any) => {
                return {date: month.date, amount: month.balance.amount}
            }).sort((a: any, b: any) => b.date.localeCompare(a.date))
        }
    });

    const x = amountsBySortedMonths && amountsBySortedMonths.map((acc: any) =>
        {
            return {
                account: acc.account,
                historicalBalances: dates.map((e: any) => {
                    const currM = parseInt(e.split(('-'))[1]) - 1;
                    const targetObj = acc.historicalBalances && acc.historicalBalances.find((x: any) => x && parseInt(x.date.split(('-'))[1]) - 1 === currM);
                    if (targetObj) {
                        return {date: targetObj.date, amount: targetObj.amount};
                    } else {
                        return {date: e, amount: 0};
                    }
                })
            }
        } 
    );

    const y = x.map((acc: any) => {
        return {
            account: acc.account,
            historicalBalances: acc.historicalBalances.filter((e: any) => e.date >= currJan.format('YYYY-MM-01') && e.date < (moment().set('date', 1).add(1, 'months')).format('YYYY-MM-01'))
        }
    });

    const sortedData = toYearly ? y : x;

    const [filteredAccsData, setFilteredAccsData] = useState(Array());

    useEffect(() => {
        setFilteredAccsData(sortedData)
    }, [toYearly, accBalancesData]);

    // Grab balances per account
    const balances = filteredAccsData && filteredAccsData.map((e: any) => e.historicalBalances.map((x: any) => x.amount));

    // Handles passing data needed for bar chart
    const monthlySums = (toYearly ? chartMonthsYrly  : chartMonths).map((_, i) => balances && balances.reduce((a: any, c: any) => {
        const val = c[i] ? c[i] : 0;
        return a + val;
    }, 0));

    // Handles passing data needed for doughnut chart
    const getBalancesByMonth = (idx: number) => {
        return balances && balances.map((e: any) => e.filter((_: any, i: any) => i === idx));
    };

    // Data by "Year to Date"
    const yearToDateByAccount = sortedData && sortedData.map((acc: any) => acc.historicalBalances
        .map((hist: any) => hist.amount)).map((amt: any) => amt.reduce((a: number, c: number) => a += c, 0));
    const totalYearToDate = sortedData && yearToDateByAccount.reduce((a: number, c: number) => a += c, 0);

    return {
        fullMonths,
        chartMonths,
        chartMonthsYrly,
        sortedData,
        filteredAccsData,
        setFilteredAccsData,
        balances,
        monthlySums,
        getBalancesByMonth,
        yearToDateByAccount,
        totalYearToDate,
    };
};
