import React from 'react';
import { Nav, INavStyles, INavStyleProps, INavLink } from 'office-ui-fabric-react';
import './EFNav.scss'

interface IProps {
    name: string,
    buttonColor?: string,
    selectedKey?: string,
    links: INavLink[],
    onClick?: (event: any) => void,
    onLinkClick?: (e?: React.MouseEvent<HTMLElement>, item?: INavLink) => void,
    dataTourId?: string,
};

const EFNav: React.FC<IProps> = props => {
    const { buttonColor } = props;
    
    const navStyles = (props: INavStyleProps): INavStyles => {
        const { isSelected } = props;

        return {
            root: [],
            group: [],
            compositeLink: [],
            navItem: [],
            navItems: [
                {
                    paddingTop: 10,
                }
            ],
            chevronButton: [
                {
                    borderBottom: '0 solid',
                    borderRadius: '.5rem',
                    width: '13.1rem',
                    height: '5.4rem',
                    margin: 0,
                    padding: '1.5rem 1.5rem 0 1rem',
                    color: '#fff',
                    fontFamily: 'brandon-grotesque',
                    fontWeight: '900' as '900',
                    backgroundColor: buttonColor,
                    fontSize: '1.8rem',
                }
            ],
            groupContent: [
                {
                    margin: 0,
                }
            ],
            link: [
                {
                    padding: 0,
                    marginBottom: 15,
                    height: '2rem',
                    fontFamily: 'brandon-grotesque',
                    color: '#293861',
                    fontSize: '1.4rem',
                    fontWeight: '600' as '600',
                    width: '13.1rem',
                    selectors: {
                        '&:hover': {
                            color: buttonColor,
                        },
                        '&:active': {
                            color: buttonColor,
                        },
                        '.ms-Nav-compositeLink:hover &': {
                            backgroundColor: 'transparent',
                        },
                        '&:after': {
                            borderLeft: `3px solid ${buttonColor}`,
                        },
                        '.ms-Nav-compositeLink:after &': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
                isSelected && {
                    color: buttonColor,
                    backgroundColor: 'transparent',
                }
            ],
            linkText: [
                {
                    marginLeft: 8,
                },
            ],
            chevronIcon: [
                {
                    color: 'transparent',
                },
            ],
        }
    };

    return (
        <div data-tour={props.dataTourId}>
            <Nav
                onLinkClick={props.onLinkClick}
                selectedKey={props.selectedKey}
                styles={navStyles}
                groups={[
                    {
                        name: props.name,
                        links: props.links,
                        onHeaderClick: props.onClick
                    },
                ]}
            />
        </div>
    )
};

EFNav.defaultProps = {
    buttonColor: '#feb81e',
};

export default EFNav;
