import React from 'react';
import Backdrop from '../../Atomic/Backdrop/Backdrop';
import EFButton from '../../Atomic/EFButton/EFButton';
import { useHistory } from "react-router-dom";
import * as actions from '../../../store/actions/Users';
import { useDispatch } from 'react-redux';

import connect from '../../../assets/images/mobileConnectDashboard.svg';
import protect from '../../../assets/images/mobileProtectDashboard.svg';

import classes from './Transfer.module.css';

interface IProps {
    isOpen: boolean;
    handleModal: () => void;
};

const UnsignedModal: React.FC<IProps> = ({ isOpen, handleModal }) => {
    const dispatch = useDispatch();
    let history = useHistory();

    return (
        <Backdrop onClick={handleModal}>
            <div className={classes.modalContainer}>
                <div className={classes.modalUnsignedCard}onClick={(e) => e.stopPropagation()}>
                    <img className={classes.modalExit} alt='x' src={require('../../../assets/images/close.svg')} onClick={handleModal}/>
                    <div className={classes.modalImgContainer}>
                        <img alt="protect" src={protect} className={classes.modalProtect} />
                        <img alt="connect" src={connect} className={classes.modalConnect} />
                    </div>
                    <div className={classes.modalUnsignedRight}> 
                        <div className={classes.modalUnsignedHeader}>Creat an Account or Sign In</div>
                        <div className={classes.modalUnsignedText}>In order to import this data you need to create an account or sign in first.</div>
                        <div className={classes.modalCta}>
                            <EFButton onClick={() => { dispatch(actions.setRedirect(false)); history.push('/signup') }} text='Create an Account' style={{backgroundColor: '#FFF', color: '#FEB81E', width: '100%', marginRight: '1rem'}} />
                            <EFButton onClick={() => { dispatch(actions.setRedirect(false)); history.push('/signin') }}text='Sign In' style={{width: '100%', marginLeft: '1rem'}} /> 
                        </div>
                    </div>
                </div>
            </div>
        </Backdrop>
    )
};

export default UnsignedModal;
