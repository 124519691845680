import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import classes from './SCFStepper.module.css'

interface Props {
    value: number,
    onChange?: (v: any) => void;
    color?: string;
    label?: string,
    prefix?: string,
    suffix?: string,
    includeThousandsSeparator?: boolean,
    thousandsSeparatorSymbol?: string,
    allowDecimal?: boolean,
    decimalSymbol?: string,
    decimalLimit?: number,
    allowNegative?: boolean,
    allowLeadingZeroes?: boolean,
    maxVal?: number,
    minVal?: number,
    dynamicStepEnabled?: boolean,
    numStepper?: number,
    displayErrStyle?: boolean,
};

const StepperInput: React.FC<Props> = props => {
    const id = 'input_' + props.label?.replace(/\s|&/g, '')
    const handleStrToNum = (str: string) => {
        let curr_str = str;
        curr_str = curr_str.replace(/,/g, '');
        curr_str = curr_str.replace('$', '');
        let numVal = parseFloat(curr_str);
        if (isNaN(numVal) || numVal < props.minVal) {
            numVal =  props.minVal;
        } else if (numVal > props.maxVal) {
            numVal = props.maxVal;
        };
        return numVal;
    };

    const value = (typeof(props.value) !== 'number') ? 0 : props.value;
    let initVal = parseFloat(value.toFixed(props.decimalLimit));
    initVal = !props.allowDecimal ? ~~initVal : initVal;

    const decrementValue = () => {
        const newVal = (initVal <= props.minVal) ? props.minVal : initVal - getStep();
        props.onChange(newVal as number);
    }

    const incrementValue = () => {
        const newVal = (initVal >= props.maxVal) ? props.maxVal : initVal + getStep();
        props.onChange(newVal as number);
    }

    const getStep = () => {
        const checkNumRange = (min: number, max: number) => {
            return (initVal - min ^ initVal - max) < 0;
        };
        
        if (props.dynamicStepEnabled) {
            switch (!props.allowDecimal) {
                case checkNumRange(0, 50): return 5;
                case checkNumRange(50, 100): return 10;
                case checkNumRange(100, 1000): return 50;
                case checkNumRange(1000, 10000): return 100;
                case checkNumRange(10000, 25000): return 250;
                case checkNumRange(25000, 50000): return 500;
                case checkNumRange(50000, 100000): return 1000;
                case undefined: return 1;
                default: return 1;
            };
        } else {
            return (props.numStepper || 1);
        }
    };

    return (
        <Stack>
            <Stack tokens={{ childrenGap: '1rem' }} verticalAlign="center" horizontal style={{height: '4.1rem'}}>
                {/* <span style={{ backgroundColor: props.color && props.color }} className={`${classes.btn} ${classes.minus}`} onClick={decrementValue}>-</span> */}
                <style>
                {
                    `
                        #${id} {
                            border: 1px solid #FEB81E;
                            box-shadow: 2px 2px 11px rgb(0 0 0 / 11%);
                            border-radius: 2.7rem;
                            outline: 0;
                            padding: 0;
                            width: 13rem;
                            height: 3.7rem;
                            background: #ffffff;
                            color: #FEB81E;
                            font-size: 2rem;
                            font-family: 'brandon-grotesque';
                            font-weight: bold;
                            text-align: center;
                            -webkit-touch-callout:none; /* iOS Safari */
                            -webkit-user-select:none;   /* Chrome/Safari/Opera */
                            -khtml-user-select:none;    /* Konqueror */
                            -moz-user-select:none;      /* Firefox */
                            -ms-user-select:none;       /* Internet Explorer/Edge */
                            user-select:none;           /* Non-prefixed version */
                        }
                        #${id}::placeholder {
                            color: ${props.color || '#FEB81E'};
                        }
                    `
                }  
                </style>
                <MaskedInput
                    disabled
                    id={id}
                    mask={numberMask}
                    value={props.value}
                    onChange={(v) => {
                        let value = v.target?.value ? 
                            handleStrToNum(v.target.value) : typeof v === 'number' ? v : 0;
                        props.onChange(value)
                    }}
                    placeholder={props.value ? props.value.toString() : '$0'}
                    style={{ borderColor: props.color && props.color, color: props.color && props.color }}
                />
                {/* <span className={classes.btn} onClick={incrementValue}>+</span> */}
            </Stack>
        </Stack>
    );
};

const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowLeadingZeroes: false,
    allowNegative: false,
});

export default StepperInput;
