import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import ScoopDashboardWidget from '../ScoopDashboardWidget/ScoopDashboardWidget';
import FavSelectorModal from '../FavSelectorModal/FavSelectorModal';
import BlurAddMeContainer from '../../UI/BlurAddMeContainer/BlurAddMeContainer';
import { useBoolean } from '@uifabric/react-hooks';
import { useSelector } from 'react-redux';

interface IProps {
    hasAccounts: boolean,
    dashboardData: any,
    onAddAccsClick: () => void,
    onChange: (db: string) => void,
};

const ScoopDashboardPreview: React.FC<IProps> = props => {
    const isRealUser = !(useSelector((state: any) => state.session.user.anonymized));
    const [showEditFav, { toggle: toggleEditFav }] = useBoolean(false);
    // NEED TO HANDLE CP CASE WHERE WE HIDE EDIT ICON
    return (
        <>
            {showEditFav && 
                <div onClick={(e) => e.stopPropagation()}>
                    <FavSelectorModal 
                        dashboardData={props.dashboardData} 
                        showEditFav={showEditFav} 
                        toggleEditFav={toggleEditFav} 
                    />
                </div>}
            {props.hasAccounts ? (
                <>
                    <Stack style={{width: '100%', display: 'flex', alignItems: 'flex-end', marginRight: '3rem'}}>
                        {isRealUser && 
                            <img 
                                style={{cursor: 'pointer'}} 
                                src={require('../../../assets/images/editor.svg')} 
                                alt='edit' 
                                onClick={(e) => {toggleEditFav(); e.stopPropagation();}} 
                            />}
                    </Stack>
                    <Stack 
                        style={{ height: '25.4rem', marginBottom: '4rem'}} 
                        wrap 
                        horizontal 
                        horizontalAlign='center' 
                        verticalAlign='center' 
                        tokens={{ childrenGap: 15 }}
                    >
                        {props.dashboardData.filter((e: any) => e.checked).map((db: any) => (
                            <Stack key={db.id}>
                                <ScoopDashboardWidget dashboardData={db} onClick={(e) => { e.stopPropagation(); props.onChange(db.id as string) }} />
                            </Stack>
                        ))}
                    </Stack>
                </>
            ) : <BlurAddMeContainer 
                    onClick={props.onAddAccsClick} 
                    backgroundImg={require('../../../assets/images/pre_scoop_widget.png')} 
                    label='Link your accounts' 
                />}
        </>
    )
};

export default ScoopDashboardPreview;
