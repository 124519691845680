import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import PreviewSlideShow from '../../PreviewSlideShow/PreviewSlideShow';
import classes from './AddAccountsModal.module.css';
import * as actions from '../../../../store/actions/Users';
import * as aggregationActions from '../../../../store/actions/Aggregation';
import EFButton from '../../../Atomic/EFButton/EFButton';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

interface IProps {
    onClickExit: () => void;
};

const AddAccountsModal: React.FC<IProps> = props => {
    const dispatch = useDispatch();
    let history = useHistory();

    const handleAddAccs = () => {
        dispatch(aggregationActions.getFastlinkCredentials(history, false));
        dispatch(actions.setRedirect(false));
        history.push('/aggregate');
    };

    return (
        <Stack className={classes.container}>
            <Stack className={classes.exitContainer} onClick={props.onClickExit} horizontalAlign='end'>
                <img className={classes.exit} alt='x' src={require('../../../../assets/images/xLeft.png')} />
                <img className={classes.exit} alt='x' src={require('../../../../assets/images/xRight.png')} />
            </Stack>
            <Stack className={classes.slideshow} horizontalAlign='center'> 
                <PreviewSlideShow
                    stackClassNameHeroImg={classes.heroImg}
                    stackClassNameCard={classes.innerContainer} 
                    stackClassNameContent={classes.disableBlur}
                />
                <EFButton onClick={handleAddAccs} text='CONNECT MY ACCOUNTS' />
            </Stack>
        </Stack>
    )
};

export default AddAccountsModal;
