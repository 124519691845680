import React from 'react';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/';

interface IProps {
    style?: object,
    title?: string,
    selectedKey?: string,
    onChange?: (e: any, option?: IChoiceGroupOption) => void,
    options: IChoiceGroupOption[],
    selectorColor?: string,
    displayErrStyle?: boolean,
    useFlex?: boolean,
    customWidth?: string
};

const EFChoiceGroup: React.FC<IProps> = props => {
    const styledOptions: any = [];
    const choiceGroupStyles = {
        root: {
            color: '#343f6e',
            fontFamily: 'brandon-grotesque' as 'brandon-grotesque',
            selectors: {
                '.ms-ChoiceFieldLabel': {
                    marginTop: 2
                },
                '#ChoiceGroupLabel26-Buy': {
                    marginTop: 2,
                    position: 'absolute',
                    bottom: '3rem',
                    right: '-2.1rem'
                },
                '#ChoiceGroupLabel26-Rent': {
                    marginTop: 2,
                    position: 'absolute',
                    bottom: '3rem',
                    right: '-2.4rem'
                },
                '#ChoiceGroupLabel31-Now': {
                    marginTop: 2,
                    position: 'absolute',
                    bottom: '3rem',
                    right: '-2.4rem'
                },
                '#ChoiceGroupLabel31-Later': {
                    marginTop: 2,
                    position: 'absolute',
                    bottom: '3rem',
                    right: '-2.5rem'
                },
                '#ChoiceGroupLabel36-Buy': {
                    marginTop: 2,
                    position: 'absolute',
                    bottom: '3rem',
                    right: '-2rem'
                },
                '#ChoiceGroupLabel36-Lease': {
                    marginTop: 2,
                    position: 'absolute',
                    bottom: '3rem',
                    right: '-2.5rem'
                }
            },
        },
        field: {
            borderColor: 'rgba(0, 0, 0, 0.15)',
            selectors: {
                '&::before': {
                    borderColor: 'rgba(0, 0, 0, 0.15)',
                },
                '&::after': {
                    borderColor: props.selectorColor,
                    backgroundColor: 'transparent' as 'transparent',
                },
                '&:hover::before': {
                    borderColor: 'rgba(0, 0, 0, 0.11)',
                },
                '&:hover.ms-ChoiceField-field::after': {
                    borderColor: props.selectorColor,
                    backgroundColor: 'transparent' as 'transparent',
                },
                '&:hover .ms-ChoiceFieldLabel': {
                    color: '#343f6e',
                },
            },
        },
    };

    const styleOptions = () => {
        props.options.forEach((option) => {
            const obj = { ...option, styles: choiceGroupStyles }
            styledOptions.push(obj);
        });
        return styledOptions;
    };

    return (
        <ChoiceGroup
            styles={{
                label: { color: !props.displayErrStyle ? '#343f6e' : '#c4233e', fontFamily: 'brandon-grotesque', fontWeight: 'normal' as 'normal' },
                flexContainer: {
                    display: props.useFlex ? 'flex' : '',
                    justifyContent: props.useFlex ? 'space-around' : ''
                },
                applicationRole: {
                    width: props.customWidth ? props.customWidth + '%' : ''
                }
            }}
            selectedKey={props.selectedKey}
            onChange={props.onChange}
            options={styleOptions()}
            label={props.title}
        />
    )
};

EFChoiceGroup.defaultProps = {
    selectorColor: '#feb81e',
};

export default EFChoiceGroup;
