import React from 'react';
import { Stack, Text, FontIcon } from 'office-ui-fabric-react/lib/';
import classes from './EFValidationCheck.module.css';

interface IProps {
    style?: object,
    text: string,
    isValid: boolean,
};

const EFValidationCheck: React.FC<IProps> = props => {
    const currIcon = !props.isValid ? 'UnCheckedMarker' : 'CheckedMarker';
    const validated = !props.isValid ? classes.isntvalid : classes.isvalid;

    return (
        <Stack horizontal tokens={{childrenGap: 5}}>
            <FontIcon iconName={currIcon} />
            <Text 
                className={`${classes.text} ${validated}`} 
                style={props.style}
            >
                {props.text}
            </Text>
        </Stack>
    )
};

EFValidationCheck.defaultProps = {
    isValid: false,
};

export default EFValidationCheck;
