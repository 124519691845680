import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import classes from './DashboardContainer.module.css'
import { useResponsive } from '../../../hooks/useResponsive';

interface IProps {
    style?: object,
    childrenGap?: number,
};

const DashboardContainer: React.FC<IProps> = props => {
    const { screenIsAtLeast } = useResponsive();

    return (
        <div className={`${classes.border} ${screenIsAtLeast("desktop") ? classes.borderLand : classes.borderPort}`}
            style={screenIsAtLeast("desktop") ? { marginLeft: '1.8rem' } : null} >
            <Stack
                tokens={{ childrenGap: props.childrenGap }}
                styles={{
                    inner: {
                        justifyContent: 'flex-start',
                        flexDirection: 'column'
                    }
                }}
                className={classes.main_container}
            >
                {props.children}
            </Stack>
        </div>
    )
};

export default DashboardContainer;
