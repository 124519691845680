import React, { Component } from "react";

  interface Props {
    click?: () => void;
    bgcolor:string;
    text:any;
    height:any;
    width:any;
    fontSize:any;
    textTwo:string;
    cursor:string;
  }
  
  class RoundBudget extends Component<Props> {
    render() {
      return (
          <div style={{
            height: this.props.height,
            width: this.props.width,
            borderRadius: '50%',
            backgroundColor: this.props.bgcolor,
            cursor: this.props.cursor
          }} 
            onClick={this.props.click}
          >
            <div style={{width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:12}}>
              <span style={{
                color: 'white',
                textAlign: 'center' as 'center',
                display: 'table-cell' as 'table-cell',
                verticalAlign: 'middle' as 'middle',
                fontSize: this.props.fontSize,
                fontFamily: 'brandon-grotesque, sans-serif',
                fontStyle: 'normal',
                fontWeight: 700}}
                >
                  {`${this.props.text}`}
                </span>
            </div>
                {this.props.textTwo != '' ? <div style={{width:'100%', margin:'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <span style={{
                  color: 'white',
                  textAlign: 'center' as 'center',
                  display: 'table-cell' as 'table-cell',
                  verticalAlign: 'middle' as 'middle',
                  fontSize: '8px',
                  lineHeight: '10px',
                  fontFamily: 'brandon-grotesque, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: 700}}
                  >
                    {this.props.textTwo}
                  </span>
                </div> : <div></div>}
          </div>
        );
    }
  };
  
  export default RoundBudget;