import React from "react";
import RetirementCalculator from "../../components/Calculators/Retirement/Retirement";
import StudentDebtCalculator from "../../components/Calculators/StudentDebt/StudentDebt";
import CollegePlaningCalculator from "../../components/Calculators/CollegePlan/CollegePlan";
import ManageDebtCalculator from "../../components/Calculators/ManageDebt/ManageDebt";
import CarBuyLeaseCalculator from "../../components/Calculators/CarBuyLease/CarBuyLease";
import HouseBuyRentCalculator from "../../components/Calculators/HouseBuyRent/HouseBuyRent";
import StaticContent from "../../components/Calculators/StaticContent/StaticContent";
import Affordability from "../../components/Calculators/Affordability/Affordability";
import CareerAssesmentSelectedJob from "../../components/CareerPlanning/Jobs/Jobs";
import SavingsGoalCalculator from "../../components/Calculators/SavingGoal/SavingGoal";

//Now we use Id, hopefully in the future we could use type or somenthing similar...
export const getCalculator = (
  lifeEventDetails: any,
  handleModal: { showModal: boolean; exitModal?: () => void },
  currentView: string,
  assessmentInfo?: {
    jobList: any;
    interests: any;
  }
) => {
  switch (lifeEventDetails.externalId) {
    // Retire / Reboot
    case "3s7O7PtEaKgjdAML8huqsO": {
      return (
        <RetirementCalculator
          lifeEventDetails={lifeEventDetails}
          handleModal={handleModal}
          currentView={currentView}
        />
      );
    }

    // Student Debt
    case "prMyQcowYYyVC8N5XNQHn": {
      return (
        <StudentDebtCalculator
          lifeEventDetails={lifeEventDetails}
          handleModal={handleModal}
          currentView={currentView}
        />
      );
    }

    // College Plan
    case "5lwJJdsnVdI0HycjtVhRXH": {
      return (
        <CollegePlaningCalculator
          lifeEventDetails={lifeEventDetails}
          handleModal={handleModal}
          currentView={currentView}
        />
      );
    }

    // Manage Debt
    case "1zTG17lpX4S66cXfr62Rde": {
      return (
        <ManageDebtCalculator
          lifeEventDetails={lifeEventDetails}
          handleModal={handleModal}
          currentView={currentView}
        />
      );
    }

    case "O7v6vw1nqpANUiFn3SK7n": {
      return (
        <SavingsGoalCalculator
          lifeEventDetails={lifeEventDetails}
          handleModal={handleModal}
          currentView={currentView}
        />
      );
    }

    // Buy a Car
    case "1GzC0nENxgH3MN2bwhwEOr": {
      return (
        <CarBuyLeaseCalculator
          lifeEventDetails={lifeEventDetails}
          handleModal={handleModal}
          currentView={currentView}
        />
      );
    }

    // Buy a House
    case "4cG7ftwrWfEeW2Bqj1ThiA": {
      return (
        <HouseBuyRentCalculator
          lifeEventDetails={lifeEventDetails}
          handleModal={handleModal}
          currentView={currentView}
        />
      );
    }

    // Affordability
    case "15cY0o7S7hwiHdzTNLxNJL": {
      return (
        <Affordability
          lifeEventDetails={lifeEventDetails}
          handleModal={handleModal}
          currentView={currentView}
        />
      );
    }

    // Career Goal
    case "6ceIi2ikmezTJtxIDEuRsg": {
      return (
        <CareerAssesmentSelectedJob
          lifeEventDetails={lifeEventDetails}
          jobList={assessmentInfo.jobList}
          interests={assessmentInfo.interests}
        />
      );
    }

    // New Job
    case "3u6QYATZ4DlIMmfeHanzqD": {
      return (
        <StaticContent
          currentView={currentView}
          lifeEventDetails={lifeEventDetails}
          url="https://content.equalfuture.com/blog/New%20Job"
        />
      );
    }

    // Gig Worker
    case "6auybRL2bWkIHRsWbSVMzb": {
      return (
        <StaticContent
          currentView={currentView}
          lifeEventDetails={lifeEventDetails}
          url="https://content.equalfuture.com/blog/Gig%20Worker"
        />
      );
    }

    // Side Hustle
    case "71OxoP9ERDxSQQqJMjODL9": {
      return (
        <StaticContent
          currentView={currentView}
          lifeEventDetails={lifeEventDetails}
          url="https://content.equalfuture.com/blog/Side%20Hustle"
        />
      );
    }

    // Emergency Fund
    case "44R8Vy6mKWirpfc3AOUsBf": {
      return (
        <StaticContent
          currentView={currentView}
          lifeEventDetails={lifeEventDetails}
          url="https://content.equalfuture.com/blog/Emergency%20Fund"
        />
      );
    }

    // Life Insurance
    case "5qothgTh3jmeIWcLULRxq0": {
      return (
        <StaticContent
          currentView={currentView}
          lifeEventDetails={lifeEventDetails}
          url="https://content.equalfuture.com/blog/Life%20Insurance"
        />
      );
    }

    // Estate Plan
    case "7bLtNggDKWOjw1ztuQ3uqq": {
      return (
        <StaticContent
          currentView={currentView}
          lifeEventDetails={lifeEventDetails}
          url="https://content.equalfuture.com/blog/Estate%20Plan"
        />
      );
    }

    // Getting Married
    case "45gRdA9ve4p9gIqorC7a6i": {
      return (
        <StaticContent
          currentView={currentView}
          lifeEventDetails={lifeEventDetails}
          url="https://content.equalfuture.com/blog/Getting%20Married"
        />
      );
    }

    // Getting Divorced
    case "5SZQU7RDR7R2Ts8SjJxKjZ": {
      return (
        <StaticContent
          currentView={currentView}
          lifeEventDetails={lifeEventDetails}
          url="https://content.equalfuture.com/blog/Getting%20Divorced"
        />
      );
    }

    default: {
      return alert("Coming soon!");
    }
  }
};
