import moment from 'moment';

export const fullOrganizedMonthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
];

export const chartOrganizedMonthNames = [
    "JAN", "FEB", "MAR",
    "APR", "MAY", "JUN", "JUL",
    "AUG", "SEP", "OCT",
    "NOV", "DEC"
];

export const currMonth = moment();
export const elevenMonthsAgo = moment().set('date', 1).subtract(11, 'months');
export const elevenMonthsForward = moment().set('date', 1).add(11, 'months');
export const currJan = moment().startOf('year').set('date', 1);

// Reorganizes month arrays with respect to start and end date
export const getMonths = (fromDate: moment.Moment, toDate: moment.Moment) => {
    const fullMonths = [];
    const chartMonths = [];
    const dates = [];
    const format = 'YYYY-MM-07';
    const start = moment(fromDate, format);
    const end = moment(toDate, format);

    while(start.isBefore(end)) {
        fullMonths.push(fullOrganizedMonthNames[start.startOf('month').month()]);
        chartMonths.push(chartOrganizedMonthNames[start.startOf('month').month()]);
        dates.push(start.format(format));
        start.add(1, 'month');
    }
    return {
        fullMonths,
        chartMonths,
        dates
    };
};

export const localeDateConversion = (date: any) => {
    return moment(date).format('h:mm A • MMM D, YYYY');
}; 