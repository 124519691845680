import React, { useState, useEffect } from 'react';
import { Stack } from 'office-ui-fabric-react';
import EFStepperInput from '../../UI/EFStepperInput/EFStepperInput';
import BreakdownList from '../Common/BreakdownList';
import ResultReport from '../Common/ResultReport';
import LineChart from '../../Charts/LineChart/LineChart';
import StaticContainer from '../../UI/StaticContainer';
import ConfigButton from '../Common/ConfigButton';
import DividerContainer from '../../UI/Divider/DividerContainer';
import Divider from '../../UI/Divider/Divider';
import { useConfigButtons } from '../../../hooks/useConfigButtons';
import { useRetirement } from '../../../hooks/Calculators/useRetirement';
import MaskedLabelPair from '../../UI/MaskedLabelPair';
import EFAccordion from '../../Atomic/EFAccordion/EFAccordion';
import RetirementOnboarding from './RetirementOnboarding';

import classes from './Retirement.module.css';
import RetireInterstitial from '../../LifeEvents/Interstitials/RetireInterstitial/RetireInterstitial';
import SaveChangesModal from '../../LifeEvents/SaveChangesModal/SaveChangesModal';
import { retireTT } from '../../../assets/content/TempCalcToolTips';

interface Props {
    handleModal: { showModal: boolean, exitModal?: () => void },
    currentView: string,
    lifeEventDetails?: any,
}

const Retirement: React.FC<Props> = props => {
    const {
        age, setAge, 
        retirementAge, setretirementAge,
        startOfDistributionsAge, setstartOfDistributionsAge,
        lifeExpectancy, setlifeExpectancy,
        startingBalance, setstartingBalance,
        cashSavingsAndInvestments, setcashSavingsAndInvestments,
        monthlySideHustle, setmonthlySideHustle,
        annualSalary, setannualSalary,
        employerMatch, setemployerMatch,
        employerUpToMatch, setemployerUpToMatch,
        contribution401k, setcontribution401k,
        contributionIRA, setcontributionIRA,
        returnRate, setreturnRate,
        investmentsFees, setinvestmentsFees,
        taxRateInRetirement, settaxRateInRetirement,
        estimatedAnnualSSBenefit, setestimatedAnnualSSBenefit,
        incomeNeeded, setincomeNeeded,
        pctChangeInMonthlyDistributions, setpctChangeInMonthlyDistributions,
        annualInflation, setannualInflation,
        retirementOutput,
    } = useRetirement(props.lifeEventDetails.id);

    // Handles Configuration Buttons Logic
    const {setup, onSetup, simu, onSimu, lvl, onLvl} = useConfigButtons();

    // Current x val using annotation
    const initX = (retirementAge - age) < 0 ? 0 : retirementAge - age;
    const [currX, setCurrX] = useState<number>(initX);
    useEffect(() => {
        setCurrX(initX);
    }, [age, retirementAge])

    // Chart data
    const indexesOfData = retirementOutput.map((r, i) => i);
    const savingsData = retirementOutput.map((r, i) => retirementOutput[i][1]);
    const targetSavingsData = retirementOutput.map((r, i) => retirementOutput[i][2]);

    const valMonthToYr = (valMonth: number) => {
        return valMonth * 12;
    };

    const valYrToMonth = (valYr: number) => {
        return Math.round(valYr / 12);
    };


    // setup content
    const setupContent1 = (
        <>
            <EFStepperInput
                label={`Age You Begin Saving`}
                onChange={(val: number) => {
                    setAge(val);
                    if (retirementAge <= val) {
                        setretirementAge(val + 1);
                    }
                }}
                value={age}
                minVal={1} 
                maxVal={90}
                tooltip={retireTT.ageBeginSaving}
            />
            <Stack tokens={{childrenGap: 5}}>
                <EFStepperInput
                    label={`Monthly Income Needed in Retirement`}
                    onChange={(val: number) => setincomeNeeded(valMonthToYr(val))}
                    value={valYrToMonth(incomeNeeded)}
                    minVal={0} 
                    maxVal={25000}
                    prefix='$'
                    includeThousandsSeparator
                    dynamicStepEnabled
                    tooltip={retireTT.monthlyIncomeNeeded}
                />
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='$'
                    suffix=' a year'
                    title=''
                    value={incomeNeeded}
                    color='#293861'
                    valueFontSize={10}
                />
            </Stack>
            <EFStepperInput
                label={`Additional Savings (Cash & Investment Savings)`}
                onChange={(val: number) => setcashSavingsAndInvestments(val)}
                value={cashSavingsAndInvestments}
                minVal={0} 
                maxVal={1000000}
                prefix='$'
                includeThousandsSeparator
                dynamicStepEnabled
                tooltip={retireTT.addRetireSavings}
            />
        </>
    );
    const setupContent2 = (
        <>
            <Stack tokens={{childrenGap: 5}}>
                <EFStepperInput
                    label={`Monthly Salary`}
                    onChange={(val: number) => setannualSalary(valMonthToYr(val))}
                    value={valYrToMonth(annualSalary)}
                    minVal={0} 
                    maxVal={25000}
                    prefix='$'
                    includeThousandsSeparator
                    dynamicStepEnabled
                    tooltip={retireTT.monthlySalary}
                />
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='$'
                    suffix=' a year'
                    title=''
                    value={annualSalary}
                    color='#293861'
                    valueFontSize={10}
                />
            </Stack>
            <EFStepperInput
                label={`Current Retirement Savings Balance`}
                onChange={(val: number) => setstartingBalance(val)}
                value={startingBalance}
                minVal={0} 
                maxVal={1000000}
                prefix='$'
                includeThousandsSeparator
                dynamicStepEnabled
                tooltip={retireTT.currentRetirementSavingsBalance}
            />
            <Stack tokens={{childrenGap: 5}}>
                <EFStepperInput
                    label={`Monthly Estimated Social Security Benefit`}
                    onChange={(val: number) => setestimatedAnnualSSBenefit(valMonthToYr(val))}
                    value={valYrToMonth(estimatedAnnualSSBenefit)}
                    minVal={0} 
                    maxVal={3770}
                    prefix='$'
                    includeThousandsSeparator
                    dynamicStepEnabled
                    tooltip={retireTT.monthlyEstimatedSSBenefit}
                />
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='$'
                    suffix=' a year'
                    title=''
                    value={estimatedAnnualSSBenefit}
                    color='#293861'
                    valueFontSize={10}
                />
            </Stack>
        </>
    );

    // simu content
    const simuContent1 = (
        <>
            <EFStepperInput
                label={`Retirement Age`}
                onChange={(val: number) => setretirementAge(val)}
                value={retirementAge}
                minVal={age + 1} 
                maxVal={90}
                tooltip={retireTT.retireAge}
            />
            <Stack tokens={{childrenGap: 5}}>
                <EFStepperInput
                    label={`Monthly Side Hustle in Retirement`}
                    onChange={(val: number) => setmonthlySideHustle(val)}
                    value={monthlySideHustle}
                    minVal={0} 
                    maxVal={100000}
                    prefix='$'
                    includeThousandsSeparator
                    dynamicStepEnabled
                    tooltip={retireTT.monthlySideHustle}
                />
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='$'
                    suffix=' a year'
                    title=''
                    value={monthlySideHustle * 12}
                    color='#293861'
                    valueFontSize={10}
                />
            </Stack>
            <Stack tokens={{childrenGap: 5}}>
                <EFStepperInput
                    label={`IRA & Other Qualified Plans (Annual)`}
                    onChange={(val: number) => setcontributionIRA(val)}
                    value={contributionIRA}
                    minVal={0} 
                    maxVal={56000}
                    prefix='$'
                    includeThousandsSeparator
                    dynamicStepEnabled
                    tooltip={retireTT.qualifiedPlansIRA}
                />
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='$'
                    suffix=' a month'
                    title=''
                    value={valYrToMonth(contributionIRA)}
                    color='#293861'
                    valueFontSize={10}
                />
            </Stack>
        </>
    );
    const simuContent2 = (
        <>
            <Stack tokens={{childrenGap: 5}}>
                <EFStepperInput
                    label={`Monthly Contribution (401k)`}
                    onChange={(val: number) => setcontribution401k(val)}
                    value={contribution401k}
                    minVal={0} 
                    maxVal={1583}
                    prefix='$'
                    includeThousandsSeparator
                    dynamicStepEnabled
                    tooltip={retireTT.monthlyContributions401k}
                />
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix=''
                    suffix='% of your monthly salary'
                    title=''
                    value={contribution401k * 100 / valYrToMonth(annualSalary)}
                    color='#293861'
                    valueFontSize={10}
                />
            </Stack>
            <EFStepperInput
                label={`Employer Match (401k)`}
                onChange={(val: number) => setemployerMatch(val)}
                value={employerMatch}
                minVal={0} 
                maxVal={100}
                suffix='%'
                allowDecimal
                tooltip={retireTT.employerMatch}
            />
            <EFStepperInput
                label={`Employer Match Up To (401k)`}
                onChange={(val: number) => setemployerUpToMatch(val)}
                value={employerUpToMatch}
                minVal={0} 
                maxVal={6}
                suffix='%'
                allowDecimal
                tooltip={retireTT.employerMatchUpTo}
            />
        </>
    );

    // lvlup content
    const lvlEconomicsContent = (
        <>
            <EFStepperInput
                label={`Annual Return Rate`}
                onChange={(val: number) => setreturnRate(val)}
                value={returnRate}
                minVal={0} 
                maxVal={12}
                suffix='%'
                numStepper={.25}
                allowDecimal
                tooltip={retireTT.annualReturnRate}
            />
            <EFStepperInput
                label={`Inflation Rate`}
                onChange={(val: number) => setannualInflation(val)}
                value={annualInflation}
                minVal={0} 
                maxVal={2}
                suffix='%'
                numStepper={.25}
                allowDecimal
                tooltip={retireTT.inflationRate}
            />
            <EFStepperInput
                label={`Investment Fees`}
                onChange={(val: number) => setinvestmentsFees(val)}
                value={investmentsFees}
                minVal={0} 
                maxVal={2}
                suffix='%'
                numStepper={.25}
                allowDecimal
                tooltip={retireTT.investmentFees}
            />
            <EFStepperInput
                label={`Tax Rate at Retirement`}
                onChange={(val: number) => settaxRateInRetirement(val)}
                value={taxRateInRetirement}
                minVal={0} 
                maxVal={30}
                suffix='%'
                allowDecimal
                tooltip={retireTT.taxRateRetirement}
            />
        </>
    );
    const lvlIncomeContent = (
        <>
            <EFStepperInput
                label={`Starting Age of Distributions`}
                onChange={(val: number) => setstartOfDistributionsAge(val)}
                value={startOfDistributionsAge}
                minVal={45}
                maxVal={71}
            />
            <EFStepperInput
                label={`Change in Monthly Distributions`}
                onChange={(val: number) => setpctChangeInMonthlyDistributions(val)}
                value={pctChangeInMonthlyDistributions}
                minVal={0}
                maxVal={50}
                suffix='%'
                allowDecimal
                tooltip={retireTT.changeInMonthlyDistribution}
            />
        </>
    );
    const lvlAgeContent = (
        <>
            <EFStepperInput
                label={`Life Expectancy`}
                onChange={(val: number) => setlifeExpectancy(val)}
                value={lifeExpectancy}
                minVal={70}
                maxVal={120}
            />
        </>
    );

    return (
        <>
            <RetirementOnboarding
                handleModal={props.handleModal}
                setAge={setAge}
                setincomeNeeded={setincomeNeeded}
                setcashSavingsAndInvestments={setcashSavingsAndInvestments}
                setannualSalary={setannualSalary}
                setstartingBalance={setstartingBalance}
                setestimatedAnnualSSBenefit={setestimatedAnnualSSBenefit}
            />
            <SaveChangesModal
                openLifeEventSaveModal={props.lifeEventDetails.openSaveModal}
                onClose={props.lifeEventDetails.onOpenSaveModal}
            >
                <RetireInterstitial 
                    lifeEventDetails={props.lifeEventDetails}
                    retireAge={retirementAge}
                    startAge={age}
                    // onChangeStartAge={(val: number) => {
                    //     setAge(val);
                    //     if (retirementAge <= val) {
                    //         setretirementAge(val + 1);
                    //     }
                    // }}
                    onClick={props.lifeEventDetails.onSaveLifeEvent}
                    onCloseClick={props.lifeEventDetails.onOpenSaveModal}
                />
            </SaveChangesModal>
            {props.currentView === 'Connect the dots' && <Stack horizontalAlign='center' tokens={{childrenGap: 20}}>
                <Stack>
                    <Stack horizontal horizontalAlign='center' className={classes.dividercontainer} tokens={{childrenGap: 30}}>
                        <Divider
                            isOpen
                            title='Retirement Balance'
                            icon={require('../../../assets/icons/retire.svg')}
                        />
                    </Stack>
                    <Stack horizontalAlign='center'>
                            <DividerContainer>
                                <Stack wrap horizontal horizontalAlign='center' tokens={{childrenGap: 30}}>
                                    <Stack className={classes.chart}>
                                        <LineChart
                                            initAnn={currX}
                                            toolTip={currX + age}
                                            maxXTicksLimit={10}
                                            onDrag={(event: any) => setCurrX(event.subject.config.value)}
                                            xTickFunction={(value: any, _: number) => {
                                                return value + age
                                            }}
                                            datasets={{
                                                labels: indexesOfData,
                                                datasets: [
                                                    {
                                                        label: 'Projected Savings',
                                                        data: savingsData,
                                                        backgroundColor: 'rgba(190, 225, 250, 0.2)',
                                                        borderColor: '#BEE1FA',
                                                        fill: true,
                                                    },
                                                    {
                                                        label: 'Recommended Savings',
                                                        data: targetSavingsData,
                                                        backgroundColor: 'rgba(205, 209, 236, 0.2)',
                                                        borderColor: '#CDD1EC',
                                                        fill: true,
                                                    },
                                                ]
                                            }}
                                        />
                                    </Stack>
                                    <Stack horizontalAlign='center' className={classes.report}>
                                        <ResultReport
                                            isGreen={savingsData[currX] >= targetSavingsData[currX]}
                                            onChange={() => null}  
                                            reportGreenLabel='Your retirement looks good'
                                            reportRedLabel='Your retirement needs work'
                                        />
                                        <MaskedLabelPair
                                            isBulleted={false}
                                            title={(savingsData[currX] >= targetSavingsData[currX]) ? 'Surplus: ' : 'Shortfall: '} 
                                            value={Math.abs(savingsData[currX] - targetSavingsData[currX])}
                                            color={(savingsData[currX] >= targetSavingsData[currX]) ? '#2CCD79' : '#F79796'}
                                            titleStyle={{marginRight: '1rem'}}
                                        />
                                        <BreakdownList 
                                            style={{width: '30rem', marginTop: '5rem'}} 
                                            data={[
                                                { key: 0, title: 'Recommended Savings', value: targetSavingsData[currX], color: '#ACB4EA'},
                                                { key: 1, title: 'Projected Savings', value: savingsData[currX], color: '#7DC9FF'},
                                            ]}
                                        />
                                    </Stack>
                                </Stack>
                            </DividerContainer>
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign='center' tokens={{childrenGap: 40}}>
                    <ConfigButton type='setup' toggleFocus={setup} onClick={onSetup} />
                    <ConfigButton type='simu' toggleFocus={simu} onClick={onSimu} />
                    <ConfigButton type='levelup' toggleFocus={lvl} onClick={onLvl} />
                </Stack>
                {setup && (
                    <Stack wrap horizontal horizontalAlign='center' tokens={{childrenGap: 10}}>
                        <EFAccordion title=''>
                            {setupContent1}
                        </EFAccordion>
                        <EFAccordion title=''>
                            {setupContent2}
                        </EFAccordion>
                    </Stack>
                )}
                {simu && (
                    <Stack wrap horizontal horizontalAlign='center' tokens={{childrenGap: 10}}>
                        <EFAccordion title=''>
                            {simuContent1}
                        </EFAccordion>
                        <EFAccordion title=''>
                            {simuContent2}
                        </EFAccordion>
                    </Stack>
                )}
                {lvl && (
                    <Stack wrap horizontal horizontalAlign='center' tokens={{childrenGap: 10}}>
                        <EFAccordion title='Economics'>
                            {lvlEconomicsContent}
                        </EFAccordion>
                        <EFAccordion title='Income Distribution'>
                            {lvlIncomeContent}
                        </EFAccordion>
                        <EFAccordion title='Age'>
                            {lvlAgeContent}
                        </EFAccordion>
                    </Stack>
                )}
            </Stack>}
            {props.currentView === 'Low Down' && <StaticContainer url="https://content.equalfuture.com/blog/Retire%20/%20Reboot/lowdown" />}
            {props.currentView === 'Nitty Gritty' && <StaticContainer url="https://content.equalfuture.com/blog/Retire%20/%20Reboot/nittygritty" />}
        </>
    )
}

export default Retirement;
