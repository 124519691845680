import React, { useState } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import CalculatorSetup from '../../UI/CustomModalCard/CustomModalCard';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import Modal from 'react-modal';
import Carousel from '@brainhubeu/react-carousel';
import EFButton from '../../Atomic/EFButton/EFButton';
import EFSlider from '../../UI/EFSlider/EFSlider';
import CompletedOnboardingCard from '../Common/CompletedOnboardingCard/CompletedOnboardingCard';
import { useResponsive } from '../../../hooks/useResponsive';

import '@brainhubeu/react-carousel/lib/style.css';
import carbuyicon from '../../../assets/images/lifeEvents/bigstuff/buy_car.svg';

interface IProps {
    handleModal?: any,
    onChangeSimulation?: (val: number) => void,
};

const CarOnboarding: React.FC<IProps> = props => {
    const { screenIsAtLeast } = useResponsive();
    const title = 'CAR BUY VS LEASE';
    const defaultSimu = 6;

    const [modalIsOpen, setModalIsOpen] = useState(props.handleModal.showModal);
    const [simulation, setSimulation] = useState(0);
    const [simuErr, setSimuErr] = useState(false);
    const [finished, setFinished] = useState(false);
    
    const onCompleteCard1 = () => {
        let isFinished = true;
        if (simulation === 0) {
            setSimuErr(true);
            isFinished = false;
        }
        setFinished(isFinished);
    }

    const onSkipCard1 = () => {
        setSimulation(defaultSimu);
        setFinished(true);
    }

    const onCompleteSetup = () => {
        props.onChangeSimulation(simulation);
        setModalIsOpen(false);
    }

    const ErrLabel = (msg: string) => (
        <EFLabel style={{fontSize: '1.3rem', color: '#c4233e', fontWeight: 'normal'}}>{msg}</EFLabel>
    ); 

    return (
        <Modal 
            isOpen={modalIsOpen}
            onRequestClose={props.handleModal.exitModal}
            style={modalCardStyle}
        >
            {!finished && (
                <Carousel 
                draggable={false}
                centered
                slidesPerPage={screenIsAtLeast('tabPort') ? 1.8 : 1}
                >
                    <div>
                        <CalculatorSetup 
                            title={title} 
                            eventIcon={carbuyicon} 
                            onClickExit={props.handleModal.exitModal}
                        >
                            <Stack horizontalAlign='center' tokens={{childrenGap: '2rem'}}>
                                <Stack tokens={{childrenGap: 5}}>
                                    <EFSlider 
                                        title='How long you plan to have the car? (Years)'
                                        onChange={(val: number) => { setSimulation(val); setSimuErr(false) }}
                                        value={simulation}
                                        min={1}
                                        max={20}
                                        displayErrStyle={simuErr}
                                    />
                                    {simuErr && ErrLabel('Please choose a valid amount')}
                                </Stack>                                
                                <Stack style={{marginTop: '20rem'}} horizontalAlign='center' tokens={{childrenGap: '1.5rem'}}>
                                    <EFButton style={{width: '16rem'}} text={`DONE!`} onClick={onCompleteCard1} />
                                    <EFLabel onClick={onSkipCard1} style={{fontWeight: 'normal', textDecoration: 'underline', cursor: 'pointer'}}>
                                        Figure this out later
                                    </EFLabel>
                                </Stack>
                            </Stack>
                        </CalculatorSetup>
                    </div>
                </Carousel>
            )}
            {finished && <CompletedOnboardingCard title={title} eventIcon={carbuyicon} onClick={onCompleteSetup} onClickExit={props.handleModal.exitModal} />}
        </Modal>
    )
};

const modalCardStyle = {
    content: {
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: '70rem',
        width: '100%',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
        marginTop: '2rem',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)'
    }
}

export default CarOnboarding;
