
import React from 'react';
import chevronRight from '../../../../assets/images/chevronRight.svg';
import chevronRightWhite from '../../../../assets/images/chevronRightWhite.svg';

import classes from './SettingsOptions.module.css';
import animations from '../../../../utils/animations.module.css';


interface Props {
    title: string;
    selected: boolean;
    handleOption: (title: string) => void;
    imgSelected: string;
    imgUnselected: string;
}

const SettingsOptions: React.FC<Props> = props => {
    return (
        <div className={`${classes.settingsOption} ${animations.cardShadowExpand}`} style={{ backgroundColor: props.selected ? '#2CCD79' : 'white' }}
            onClick={(event) => {
                props.handleOption(props.title)
                event.stopPropagation()
            }}>
            <div className={classes.settingsOptionsCardLeft}>
                <img src={props.selected ? props.imgSelected : props.imgUnselected} alt={props.title} />
                <p className={classes.settingsTitleOption} style={{ color: props.selected ? 'white' : '#2CCD79' }}>{props.title}</p>
            </div>
            <img className={classes.settingsOptionsCardRight} alt="View More" src={props.selected ? chevronRightWhite : chevronRight} />
        </div>
    )
};

export default SettingsOptions;
