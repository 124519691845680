import React, { Children } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../Atomic/EFLabel/EFLabel';
import CollapsableMenu from './CollapsableMenu/CollapsableMenu';
import { useResponsive } from '../../hooks/useResponsive';

interface IProps {
    style?: object,
    dataTourId?: string,
    parentTitle: string,
    childrenTitles: Array<string>,
    onParentClick: () => void;
    //CollapsableMenu Props
    connectedAccounts?: number,
    connectedKey?: string,
    onConnectedLinkClick?: (e: any, item: any) => void,
    onConnectButtonClick?: () => void,
    onProtectButtonClick?: () => void,
    onGrowButtonClick?: () => void,
    growKey?: string,
    onGrowLinkClick?: () => void,
    protectKey?: string,
    onProtectLinkClick?: () => void,
    profile: boolean,
    onConnectAccountClick: () => void,
    onSelectBubleClickHandler?: () => void;
};

const EFBreadcrumb: React.FC<IProps> = props => {
    const { screenIsAtMost } = useResponsive();

    return (
        <Stack data-tour={props.dataTourId} horizontal={!screenIsAtMost('mobile')} horizontalAlign={screenIsAtMost('mobile') ? 'start' : 'baseline'} verticalAlign='end' style={screenIsAtMost("tabLand") ? { ...props.style } : { ...props.style }} tokens={{ childrenGap: 20 }}>
            {screenIsAtMost("tabLand") &&
                <CollapsableMenu
                    connectedAccounts={props.connectedAccounts}
                    connectedKey={props.connectedKey}
                    onConnectedLinkClick={props.onConnectedLinkClick}
                    onConnectButtonClick={props.onConnectButtonClick}
                    onProtectButtonClick={props.onProtectButtonClick}
                    onGrowButtonClick={props.onGrowButtonClick}
                    growKey={props.growKey}
                    onGrowLinkClick={props.onGrowLinkClick}
                    protectKey={props.protectKey}
                    onProtectLinkClick={props.onProtectLinkClick}
                    profile={props.profile}
                    onConnectAccountClick={props.onConnectAccountClick}
                    onSelectBubleClickHandler={props.onSelectBubleClickHandler}
                />}
            <Stack verticalAlign='center' tokens={{ childrenGap: 18 }} 
            style={{ marginLeft: 0, alignItems: screenIsAtMost('mobile') ? '' : 'flex-start', marginTop: screenIsAtMost("mobile") ? '' : '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <EFLabel onClick={props.onParentClick} style={{ fontWeight: 'normal' as 'normal', fontSize: 14, color: '#979797', cursor: 'pointer' }}>{props.parentTitle}</EFLabel>
                    <img style={{ height: 13, width: 7, marginLeft: '0.8rem' }} alt='breadcrumb-chevron' src={require('../../assets/images/breadcrumbChevron.svg')} />
                </div>
                {props.childrenTitles.map((children: string) => {
                    return (
                        <React.Fragment key={children}>
                            <EFLabel style={{ fontSize: screenIsAtMost('mobile') ? 14 : 26, marginTop: '0.5rem' }}>{children}</EFLabel>
                        </React.Fragment>
                    )
                })}
            </Stack>
        </Stack>
    )
};

export default EFBreadcrumb;
