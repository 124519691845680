import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Backdrop from '../../../Atomic/Backdrop/Backdrop';
import { useDispatch } from 'react-redux';
import { Label, IDropdownOption } from 'office-ui-fabric-react';
import EFDropdown from '../../../Atomic/EFDropdown/EFDropdown';
import EFButton from '../../../../components/Atomic/EFButton/EFButton';
import EFTextField from '../../../Atomic/EFTextField/EFTextField';

import { addDependent, updateDependent } from '../../../../store/actions/Users';

import classes from './FamilyModal.module.css';

interface Props {
    handleModal: () => void;
    title: string;
    dependent?: any;
}

const options = [
    { key: 0, text: 'Spouse' },
    { key: 1, text: 'Partner' },
    { key: 2, text: 'Daughter' },
    { key: 3, text: 'Son' },
    { key: 4, text: 'Mother' },
    { key: 5, text: 'Father' },
    { key: 6, text: 'Sibiling' },
    { key: 7, text: 'Other Relative' }
]

const FamilyModal: React.FC<Props> = props => {

    const dispatch = useDispatch();
    let history = useHistory();
    const [name, setName] = useState(props.dependent ? props.dependent.name : '');
    const [nameError, setNameError] = useState('');
    const [age, setAge] = useState(props.dependent ? props.dependent.age : '');
    const [ageError, setAgeError] = useState('');
    const [relationship, setRelationship] = useState(props.dependent ? props.dependent.relationship : options[0].text);

    const validateName = (event: any) => {
        const name = (event.target as HTMLTextAreaElement).value;
        if (name === '') {
            setNameError('Your name cannot be empty')
            setName(name);
        } else {
            setName(name);
            setNameError('');
        }
    }

    const validateAge = (event: any) => {
        const value = (event.target as HTMLTextAreaElement).value;
        if (/^[0-9]*$/.test(value) === false) {
            setAgeError('Age must be numeric only');
        } else {
            setAge(value);
            setAgeError('')
        }
    }

    const validateAgeLength = (event: any) => {
        const value = (event.target as HTMLTextAreaElement).value;
        if (Number(value) >= 120 || Number(value) < 0 || value === '') {
            setAgeError('Age must be valid');
        } else {
            setAge(value);
            setAgeError('')
        }
    }

    return (
        <Backdrop onClick={props.handleModal}>
            <div onClick={(e) => e.stopPropagation()} className={classes.card}>
                <div className={classes.container}>
                    <div className={classes.title}>
                        {props.title}
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                        <Label className={classes.fieldLabel}>Name</Label>
                        <EFTextField
                            width='45rem'
                            type='text'
                            value={name}
                            onChange={(e) => { validateName(e) }}
                            onBlur={e => { validateName(e) }}
                            errorMessage={nameError}
                        />
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                        <Label className={classes.fieldLabel}>Age</Label>
                        <EFTextField
                            width='45rem'
                            type='text'
                            value={age}
                            onChange={(e) => { validateAge(e) }}
                            onBlur={e => { validateAgeLength(e) }}
                            errorMessage={ageError}
                        />
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                        <Label className={classes.fieldLabel}>Relationship</Label>
                        <EFDropdown
                            placeholder={relationship}
                            options={options}
                            style={{ width: '100%' }}
                            onChange={(e, item?: IDropdownOption) => {
                                setRelationship(item.text);
                            }}
                        />
                    </div>
                    <div className={classes.btnContainer}>
                        <EFButton style={{ width: '30rem', marginTop: '3rem' }}
                            isDisabled={nameError !== "" || ageError !== "" || 
                            name === "" || age === ""} text="SAVE"
                            onClick={(event) => {
                                event.preventDefault();
                                props.dependent ?
                                    dispatch(updateDependent(props.dependent._id, name, age, relationship, history)) :
                                    dispatch(addDependent(name, age, relationship, history));
                                props.handleModal();
                            }} />
                    </div>
                </div>
            </div>
        </Backdrop>
    )
};

export default FamilyModal;
