import React from "react";
import { Stack } from "office-ui-fabric-react/lib/";
import EFCard from "../Atomic/EFCard/EFCard";
import { useHistory } from "react-router-dom";
import { useResponsive } from "../../hooks/useResponsive";
import { useSelector } from "react-redux";
import ScoopDashboardWidget from "../Scoop/ScoopDashboardWidget/ScoopDashboardWidget";
import { useScoop } from "../../hooks/ScoopDashboards/useScoop";

import animations from "../../utils/animations.module.css";
import getOrganizedNudget from "../../assets/images/getOrganizedNudget.svg";
import getOrganizedKakeibo from "../../assets/images/getOrganizedKakeibo.svg";
import Nudget from "../UI/Nudget/Nudget";
interface IProps {
  transactionsData: any;
  transactionCategoriesData: any;
  balancesData: any;
}

const GetOrganizedCard: React.FC<IProps> = (props) => {
  let history = useHistory();
  const { screenIsAtMost } = useResponsive();
  const dbPreviews = useSelector(
    (state: any) => state.session.user?.scoop?.hiddenDashboards
  );
  const { dashboardsData } = useScoop(
    props.transactionsData,
    props.balancesData,
    dbPreviews
  );

  const ScoopDashboardPreview = () => (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ childrenGap: 15 }}
    >
      {dashboardsData
        .filter((e: any) => e.checked)
        .filter((_, i: number) => i < 2)
        .map((db: any) => (
          <Stack key={db.id}>
            <ScoopDashboardWidget
              dashboardData={db}
              onClick={(e) => {
                e.stopPropagation();
                history.push("/connect/getorganized/scoopdashboards", {
                  selectedDashboard: db.id,
                });
              }}
            />
          </Stack>
        ))}
    </Stack>
  );

  return (
    <EFCard
      style={{
        cursor: "pointer",
        padding: screenIsAtMost("tabLand") ? "2rem" : "",
      }}
      stackClassName={animations.cardShadowExpand}
      title="GET ORGANIZED"
      onClick={() => {
        history.push("/connect/getorganized");
      }}
    >
      <Stack
        tokens={{ childrenGap: "2rem" }}
        verticalAlign="center"
        horizontalAlign="center"
      >
        <ScoopDashboardPreview />
        <div
          onClick={(e) => {
            e.stopPropagation();
            history.push("/connect/getorganized/nudget");
          }}
        >
          <Nudget
            categoryData={props.transactionCategoriesData}
            currentView="Connect"
            flag={false}
          />
        </div>
        <img
          onClick={(e) => {
            e.stopPropagation();
            history.push("/connect/getorganized/kakeibo");
          }}
          style={{ width: "95%" }}
          src={getOrganizedKakeibo}
          alt="Get Organized Kakeibo"
        />
      </Stack>
    </EFCard>
  );
};

export default GetOrganizedCard;
