import React, { Component } from 'react';

interface Props {
    click?: () => void;
    text: string;
    width: any;
    size?: string;
    isDisabled?: boolean;
}

interface State {
}

const EfButtonOrange: React.FC<Props> = props => {
    let textSize = 'calc(0.1em + 0.7vw)'
    if (props.size){
        textSize = props.size;
    }
    const buttonOrange = {
        color: '#FFFFFF',
        background:'#F6BE00',
        borderRadius: '22px',
        height: 30,
        width: props.width,
        border: 'none' as 'none',
        fontSize: textSize,
        lineHeight: '16px',
        textAlign: 'center' as 'center',
        fontFamily: 'brandon-grotesque, sans-serif',
        fontStyle: 'normal',
        textTransform:'uppercase' as 'uppercase',
        fontWeight: 600,
        cursor:'pointer'
    }

    const buttonDisabled = {
        color: '#FFFFFF',
        background:'grey',
        borderRadius: '22px',
        height: 30,
        width: props.width,
        border: 'none' as 'none',
        fontSize: textSize,
        lineHeight: '16px',
        textAlign: 'center' as 'center',
        fontFamily: 'brandon-grotesque, sans-serif',
        fontStyle: 'normal',
        textTransform:'uppercase' as 'uppercase',
        fontWeight: 600,
    }

    return (
            <button style={props.isDisabled ? buttonDisabled : buttonOrange} onClick={props.click}>{props.text}</button>
        )
}

export default EfButtonOrange;

