import React from 'react';
import { Checkbox, ICheckboxStyleProps, ICheckboxStyles } from 'office-ui-fabric-react/lib/';
import './EFCheckbox.css';

interface IProps {
    id?: string;
    stackClassName?: string;
    label?: string;
    checked: boolean;
    disabled?: boolean;
    onChange?: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
};

const EFCheckbox: React.FC<IProps> = props => {
    const fnStyles = (props: ICheckboxStyleProps): ICheckboxStyles => {
        const { checked, disabled } = props;

        return {
            root: {
                selectors: {
                    '&:hover .ms-Checkbox-checkbox': {
                        backgroundColor: checked ? 'transparent' : 'transparent',
                    },
                    '.ms-Checkbox-text': {
                        color: disabled ? '#BABABA' : '#293861',
                    }
                },
            },
        }
    };

    return (
        <Checkbox
            id={props.id}
            className={props.stackClassName}
            styles={fnStyles}
            label={props.label}
            checked={props.checked} 
            disabled={props.disabled} 
            onChange={props.onChange} 
        />
    )
};

export default EFCheckbox;
