import React from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/';
import classes from './EFButton.module.css';

interface IProps {
    id?: string,
    style?: object,
    text: string,
    isDisabled?: boolean,
    onClick: (event?: any) => void,
    stackClassName?: string,
    dataTourId?: string,
};

const EFButton: React.FC<IProps> = props => {

    return (
        <PrimaryButton
            id={props.id}
            data-tour={props.dataTourId} 
            disabled={props.isDisabled} 
            style={{...props.style}} 
            className={`${classes.button} ${props.stackClassName}`}  
            text={props.text} 
            onClick={props.onClick} 
        />
    )
};

export default EFButton;
