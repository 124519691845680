/* eslint-disable @typescript-eslint/no-array-constructor */
import { chartOrganizedMonthNames, currMonth, elevenMonthsAgo, currJan, getMonths } from '../../utils/dateTools';
import moment from 'moment';

const { fullMonths: fullMonthsM, chartMonths, dates } = getMonths(elevenMonthsAgo, currMonth);
const { fullMonths: fullMonthsY, chartMonths: chartMonthsYrly } = getMonths(currJan, currMonth);

export const useDebtData = (accBalancesData: any, toYearly: boolean) => {
    const fullMonths = toYearly ? fullMonthsY : fullMonthsM;

    const containerTypes = ["creditCard", "loan"];

    const baseData = accBalancesData.filter((e: any) => e.account.dashboards.find((db: any) => db === 'debt'));

    const months = toYearly ? chartOrganizedMonthNames : chartMonths;
    const monthSet = toYearly ? chartMonthsYrly : months;
    
    const dataByType = containerTypes.map((container: any) => baseData && baseData.filter((e: any) => container === e.account.CONTAINER));
    const amountsBySortedMonths = dataByType && dataByType.map((container: any) => container && container.map((e: any) => e.historicalBalances.map((month: any) => {
        return {date: month.date, amount: month.balance.amount, account: e.account}
    }).sort((a: any, b: any) => b.date.localeCompare(a.date))));

    const x = amountsBySortedMonths && amountsBySortedMonths.map((acc: any) => acc && acc.map((type: any) => 
        dates.map((e: any) => {
            const currM = parseInt(e.split(('-'))[1]) - 1;
            const targetObj = type && type.find((x: any) => x && parseInt(x.date.split(('-'))[1]) - 1 === currM);
            if (targetObj) {
                return {date: targetObj.date, amount: targetObj.amount, account: targetObj.account};
            } else {
                return {date: e, amount: 0};
            }
    })));

    const y = x.map((type: any) => type.map((acc: any) => acc.filter((e: any) => e.date >= currJan.format('YYYY-MM-01') && e.date < (moment().set('date', 1).add(1, 'months')).format('YYYY-MM-01'))));

    const sortedData = toYearly ? y : x;

    const balancesByType = sortedData && sortedData.map((container: any) => container && container.map((e: any) => e.map((x: any) => x.amount)));

    const monthlySumsByType = sortedData && sortedData.map((_, i) => monthSet.map((_, j) => balancesByType[i] && balancesByType[i].reduce((a: any, c: any) => {
        const val = c[j] ? c[j] : 0;
        return a + val;
    }, 0)));

    const monthlyTotals = monthSet.map((_, i) => monthlySumsByType && monthlySumsByType.reduce((a: any, c: any) => {
        const val = c[i] ? c[i] : 0;
        return a + val;
    }, 0));
    
    const yearToDateByType = monthlySumsByType && monthlySumsByType.map((container: any) => container && container.reduce((a: number, c: number) => a += c));
    const yearToDateTotal = yearToDateByType && yearToDateByType.reduce((a: number, c: number) => a += c);

    return {
        chartMonths,
        chartMonthsYrly,
        containerTypes,
        baseData,
        months,
        monthSet,
        fullMonths,
        sortedData,
        balancesByType,
        monthlySumsByType,
        monthlyTotals,
        yearToDateByType,
        yearToDateTotal
    };
};