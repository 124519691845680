import React, { useState } from "react";
import { Stack } from "office-ui-fabric-react";
import EFSlider from "../../UI/EFSlider/EFSlider";
import EFStepperInput from "../../UI/EFStepperInput/EFStepperInput";
import BreakdownList from "../Common/BreakdownList";
import ResultReport from "../Common/ResultReport";
import StackedChart from "../../Charts/StackedChart";
import EFLabel from "../../Atomic/EFLabel/EFLabel";
import ConfigButton from "../Common/ConfigButton";
import DividerContainer from "../../UI/Divider/DividerContainer";
import Divider from "../../UI/Divider/Divider";
import StaticContainer from "../../UI/StaticContainer";
import LineChart from "../../Charts/LineChart/LineChart";
import { useCarBuyLease } from "../../../hooks/Calculators/useCarBuyLease";
import { useConfigButtons } from "../../../hooks/useConfigButtons";
import MaskedLabelPair from "../../UI/MaskedLabelPair";
import EFScaledStepper from "../../UI/EFScaledStepper/EFScaledStepper";
import EFAccordion from "../../Atomic/EFAccordion/EFAccordion";

import classes from "./CarBuyLease.module.css";
import CarOnboarding from "./CarOnboarding";
import BuyLeaseInterstitial from "../../LifeEvents/Interstitials/BuyLeaseInterstitial/BuyLeaseInterstitial";
import SaveChangesModal from "../../LifeEvents/SaveChangesModal/SaveChangesModal";
import { carTT } from "../../../assets/content/TempCalcToolTips";

interface Props {
  handleModal: { showModal: boolean; exitModal?: () => void };
  currentView: string;
  lifeEventDetails?: any;
}

const CarBuyLease: React.FC<Props> = (props) => {
  const {
    //INPUTS
    selectedOption,
    setSelectedOption,
    //setup
    simulationLength,
    setsimulationLength,
    //simu
    vehiclePrice,
    setvehiclePrice,
    downPymntBuy,
    setdownPymntBuy,
    loanTerm,
    setloanTerm,
    loanInterestRate,
    setloanInterestRate,
    downPymntLease,
    setdownPymntLease,
    advertisedMthlyLeaseExpense,
    setadvertisedMthlyLeaseExpense,
    //lvlup
    salesTax,
    setsalesTax,
    registrationFees,
    setregistrationFees,
    monthlyMaintenance,
    setmonthlyMaintenance,
    monthlyInsurance,
    setmonthlyInsurance,
    investmentInterestRate,
    setinvestmentInterestRate,
    //OUPUTS
    paymentsOverTime,
    investmentSimulation,
    firstYearBuyDownPayment,
    firstYearBuySalesTax,
    firstYearBuyLoanPayments,
    firstYearLeaseDownPayment,
    firstYearLeaseSalesTax,
    firstYearLeasePayments,
    firstYearRegistrationFees,
    firstYearMaintenanceAmount,
  } = useCarBuyLease(props.lifeEventDetails.id);
  /* #region Define Divider States: TO BE REFACTORED INTO CUSTOM HOOK */
  const [expensesDivider, setExpensesDivider] = useState<boolean>(true);
  const [totalCostDivider, setTotalCostDivider] = useState<boolean>(false);
  const [breakdownDivider, setBreakdownDivider] = useState<boolean>(false);
  const handleDividers = () => {
    setExpensesDivider(false);
    setTotalCostDivider(false);
    setBreakdownDivider(false);
  };
  /* #endregion */

  const { setup, onSetup, simu, onSimu, lvl, onLvl } = useConfigButtons();

  const paymentsMonth = paymentsOverTime.map((r, i) => paymentsOverTime[i][0]);
  const paymentsBuyPayment = paymentsOverTime.map(
    (r, i) => paymentsOverTime[i][1]
  );
  const paymentsRentPayment = paymentsOverTime.map(
    (r, i) => paymentsOverTime[i][2]
  );
  const paymentsMonthlyBuyCost = paymentsOverTime.map(
    (r, i) => paymentsOverTime[i][3]
  );
  const paymentsMonthlyRentCost = paymentsOverTime.map(
    (r, i) => paymentsOverTime[i][4]
  );
  const [currMonth, setCurrMonth] = useState<number>(0);

  const leaseTotalDataSet = investmentSimulation.map(
    (r, i) => investmentSimulation[i][1]
  );
  const buyTotalDataSet = investmentSimulation.map(
    (r, i) => investmentSimulation[i][3]
  );
  const assetValueDataSet = investmentSimulation.map(
    (r, i) => investmentSimulation[i][5]
  );
  const interestLostBuyDataSet = investmentSimulation.map(
    (r, i) => investmentSimulation[i][4]
  );
  const interestLostLeaseDataSet = investmentSimulation.map(
    (r, i) => investmentSimulation[i][2]
  );

  // Loan Amount found under simu down payments
  const loanAmount = vehiclePrice - downPymntBuy;
  const [lengthYearsInput, setLengthYearsInput] = useState<number>(
    simulationLength / 12
  );
  const [lengthYearsObservable, setLengthYearsObservable] = useState<number>(
    simulationLength / 12
  );
  //setup input that manipulates input recieved by calc, the self input and the observable input from cost over time
  const onChangeSimulation = (val: number) => {
    setsimulationLength(val * 12);
    setLengthYearsInput(val);
    setLengthYearsObservable(val);
  };
  //cost over time input that changes self input and input recieved by calc
  const onChangeYearsToLiveObservable = (values: readonly number[]) => {
    setLengthYearsObservable(values[0]);
    setsimulationLength(values[0] * 12);
  };

  // Lease Gross Cost
  const leaseGrossCost = leaseTotalDataSet[simulationLength / 12 - 1];
  // Loss Interest on Lease
  const leaseLostInterest = interestLostLeaseDataSet[simulationLength / 12 - 1];
  // Leasing Net Cost
  const leaseNetCost = leaseGrossCost + leaseLostInterest;

  // Buy Gross Cost
  const buyGrossCost = buyTotalDataSet[simulationLength / 12 - 1];
  // Car Market Value
  const carMarketValue = assetValueDataSet[simulationLength / 12 - 1];
  // Lost Interests on Buy
  const buyLostInterest = interestLostBuyDataSet[simulationLength / 12 - 1];
  // Buying Net Cost
  const buyNetCost = buyGrossCost + buyLostInterest - carMarketValue;

  // Toggle for best option
  const bestOption =
    buyNetCost <= leaseNetCost
      ? { text: "Buying", color: "#5BBEEB" }
      : { text: "Renting", color: "#ECB361" };

  // setup content
  const setupContent = (
    <>
      <EFSlider
        title="How long you plan to have the car? (Years)"
        onChange={onChangeSimulation}
        value={simulationLength / 12}
        min={1}
        max={20}
      />
    </>
  );
  // simu content
  const simuContent1 = (
    <>
      <EFStepperInput
        label="Vehicle Price"
        onChange={(val: number) => setvehiclePrice(val)}
        value={vehiclePrice}
        prefix="$"
        minVal={0}
        maxVal={1000000}
        dynamicStepEnabled
        includeThousandsSeparator
        tooltip={carTT.vehiclePurchasePrice}
      />
    </>
  );
  const getMonths = loanTerm % 12;
  const getYrs = (loanTerm / 12) | 0;
  const simuContent2 = (
    <>
      <Stack tokens={{ childrenGap: 5 }}>
        <EFSlider
          title="Down Payment to Buy"
          onChange={(val: number) => setdownPymntBuy(val)}
          value={downPymntBuy}
          min={0}
          max={vehiclePrice}
          includeThousandsSeparator
          prefix="$"
          tooltip={carTT.downPaymentBuy}
        />
        <MaskedLabelPair
          isBulleted={false}
          includeThousandsSeparator
          prefix="Loan Amount: $"
          title=""
          value={loanAmount}
          color="#293861"
          valueFontSize={10}
        />
      </Stack>
      <Stack tokens={{ childrenGap: 5 }}>
        <EFStepperInput
          label="Loan Term (Months)"
          onChange={(val: number) => setloanTerm(val)}
          value={loanTerm}
          minVal={0}
          maxVal={100}
          tooltip={carTT.loanTerm}
        />
        <MaskedLabelPair
          isBulleted={false}
          includeThousandsSeparator
          suffix={getMonths ? ` Months` : " Years"}
          prefix={getMonths ? `${getYrs} Years ` : ""}
          title=""
          value={getMonths ? getMonths : getYrs}
          color="#293861"
          valueFontSize={10}
        />
      </Stack>
      <EFStepperInput
        label="Interest Rate"
        onChange={(val: number) => setloanInterestRate(val)}
        value={loanInterestRate}
        suffix="%"
        minVal={0}
        maxVal={100}
        numStepper={0.25}
        allowDecimal
        tooltip={carTT.interestRate}
      />
    </>
  );
  const simuContent3 = (
    <>
      <EFStepperInput
        label="Down Payment required by the dealer to Lease"
        onChange={(val: number) => setdownPymntLease(val)}
        value={downPymntLease}
        prefix="$"
        minVal={0}
        maxVal={1000000}
        dynamicStepEnabled
        includeThousandsSeparator
        tooltip={carTT.downPaymentLease}
      />
      <EFStepperInput
        label="Monthly Payment to Lease"
        onChange={(val: number) => setadvertisedMthlyLeaseExpense(val)}
        value={advertisedMthlyLeaseExpense}
        prefix="$"
        minVal={0}
        maxVal={1000000}
        dynamicStepEnabled
        includeThousandsSeparator
        tooltip={carTT.monthlyPaymentLease}
      />
    </>
  );

  // lvlup content
  const upfrontCostsContent = (
    <>
      <EFStepperInput
        label="Sale Taxes"
        onChange={(val: number) => setsalesTax(val)}
        value={salesTax}
        suffix="%"
        minVal={0}
        maxVal={20}
        numStepper={0.25}
        allowDecimal
        tooltip={carTT.salesTaxes}
      />
      <EFStepperInput
        label={`Registration and Other Fees`}
        onChange={(val: number) => setregistrationFees(val)}
        value={registrationFees}
        prefix="$"
        minVal={0}
        maxVal={1000000}
        dynamicStepEnabled
        includeThousandsSeparator
        tooltip={carTT.registrationAndOtherFees}
      />
    </>
  );
  const onGoingCostsContent = (
    <>
      <EFStepperInput
        label={`Monthly Maintenance`}
        onChange={(val: number) => setmonthlyMaintenance(val)}
        value={monthlyMaintenance}
        prefix="$"
        minVal={0}
        maxVal={1000000}
        dynamicStepEnabled
        includeThousandsSeparator
        tooltip={carTT.monthlyMaintenance}
      />
      <EFStepperInput
        label={`Monthly Insurance`}
        onChange={(val: number) => setmonthlyInsurance(val)}
        value={monthlyInsurance}
        prefix="$"
        minVal={0}
        maxVal={1000000}
        dynamicStepEnabled
        includeThousandsSeparator
      />
    </>
  );
  const oppCostsContent = (
    <>
      <EFStepperInput
        label="Investment Rate"
        onChange={(val: number) => setinvestmentInterestRate(val)}
        value={investmentInterestRate}
        suffix="%"
        minVal={0}
        maxVal={20}
        numStepper={0.25}
        allowDecimal
        tooltip={carTT.investmentRate}
      />
    </>
  );

  return (
    <>
      <CarOnboarding
        handleModal={props.handleModal}
        onChangeSimulation={onChangeSimulation}
      />
      <SaveChangesModal
        openLifeEventSaveModal={props.lifeEventDetails.openSaveModal}
        onClose={props.lifeEventDetails.onOpenSaveModal}
      >
        <BuyLeaseInterstitial
          lifeEventDetails={props.lifeEventDetails}
          onClick={props.lifeEventDetails.onSaveLifeEvent}
          onCloseClick={props.lifeEventDetails.onOpenSaveModal}
          simulationLength={simulationLength}
          currentOption={selectedOption}
          onSelectOption={(option) => setSelectedOption(option)}
        />
      </SaveChangesModal>
      {props.currentView === "Connect the dots" && (
        <Stack horizontalAlign="center" tokens={{ childrenGap: "2rem" }}>
          <Stack>
            <Stack
              horizontal
              horizontalAlign="center"
              className={classes.dividercontainer}
              tokens={{ childrenGap: "3rem" }}
            >
              <Divider
                title="Buy vs Lease:"
                subtitle="Cumulative costs over time"
                icon={require("../../../assets/icons/carannual.svg")}
                isOpen={expensesDivider}
                onClick={() => {
                  handleDividers();
                  setExpensesDivider(true);
                }}
              />
              <Divider
                title="Buy vs Lease:"
                subtitle="Total cost"
                icon={require("../../../assets/icons/cartotal.svg")}
                isOpen={totalCostDivider}
                onClick={() => {
                  handleDividers();
                  setTotalCostDivider(true);
                }}
              />
              <Divider
                title="Budget to Buy"
                subtitle="Breakdown of first year cost"
                icon={require("../../../assets/icons/carbd.svg")}
                isOpen={breakdownDivider}
                onClick={() => {
                  handleDividers();
                  setBreakdownDivider(true);
                }}
              />
            </Stack>
            <Stack horizontalAlign="center">
              <DividerContainer>
                {expensesDivider && (
                  <Stack
                    wrap
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: "3rem" }}
                  >
                    <Stack className={classes.chart}>
                      <LineChart
                        enableBreakEven
                        breakEvenImg={require("../../../assets/images/bep_car.svg")}
                        bepTextMargin={"5px 0"}
                        maxXTicksLimit={8}
                        onDrag={(event: any) =>
                          setCurrMonth(event.subject.config.value)
                        }
                        datasets={{
                          labels: paymentsMonth,
                          datasets: [
                            {
                              label: "Lease",
                              data: paymentsRentPayment,
                              backgroundColor: "#ECB361",
                              borderColor: "#ECB361",
                              fill: false,
                            },
                            {
                              label: "BUY",
                              data: paymentsBuyPayment,
                              backgroundColor: "#5BBEEB",
                              borderColor: "#5BBEEB",
                              fill: false,
                            },
                          ],
                        }}
                      />
                    </Stack>
                    <Stack
                      horizontalAlign="center"
                      tokens={{ childrenGap: "2rem" }}
                      className={classes.report}
                    >
                      <ResultReport
                        isGreen={buyNetCost <= leaseNetCost}
                        onChange={() => null}
                        reportGreenLabel="You should consider Buying"
                        greenAlt="#5BBEEB"
                        reportRedLabel="You should consider Leasing"
                        redAlt="#ECB361"
                      />
                      {/* buyNetCost <= leaseNetCost ? {text: 'Buying', color: '#5BBEEB'} : {text: 'Renting', color: '#ECB361'} */}
                      <Stack
                        horizontal
                        style={{ marginLeft: "3.4rem" }}
                        tokens={{ childrenGap: 0 }}
                      >
                        <EFLabel>Monthly</EFLabel>
                        <EFLabel style={{ marginLeft: "3rem" }}>Total</EFLabel>
                      </Stack>
                      <Stack horizontal>
                        <BreakdownList
                          style={{ width: "15rem" }}
                          data={[
                            {
                              key: 3,
                              title: "Buying",
                              value: paymentsMonthlyBuyCost[currMonth],
                              color: "#5BBEEB",
                            },
                            {
                              key: 1,
                              title: "Leasing",
                              value: paymentsMonthlyRentCost[currMonth],
                              color: "#ECB361",
                            },
                          ]}
                        />
                        <BreakdownList
                          style={{ width: "10rem", marginLeft: "4rem" }}
                          data={[
                            {
                              key: 1,
                              title: "",
                              value: paymentsBuyPayment[currMonth],
                              color: "",
                            },
                            {
                              key: 0,
                              title: "",
                              value: paymentsRentPayment[currMonth],
                              color: "",
                            },
                          ]}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
                {totalCostDivider && (
                  <Stack
                    wrap
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: "3rem" }}
                  >
                    <Stack horizontalAlign="center">
                      <Stack
                        horizontalAlign="center"
                        className={classes.observable}
                        tokens={{ childrenGap: "3rem" }}
                      >
                        <Stack
                          horizontal
                          verticalAlign="center"
                          tokens={{ childrenGap: "2.5rem" }}
                        >
                          <p
                            className={classes.observabletext}
                            style={{ color: bestOption.color }}
                          >
                            {`Having the car for `}
                            <strong>{lengthYearsObservable}</strong>
                            {` years, the best option is `}
                            <strong>{bestOption.text}</strong>
                          </p>
                        </Stack>
                        <EFScaledStepper
                          onChange={onChangeYearsToLiveObservable}
                          value={lengthYearsObservable}
                          min={1}
                          max={lengthYearsInput}
                        />
                      </Stack>
                      <Stack
                        horizontalAlign="center"
                        className={classes.chartalt}
                      >
                        <StackedChart
                          datasets={{
                            labels: ["", ""],
                            datasets: [
                              {
                                type: "bar",
                                label: "Lost Interest",
                                data: [
                                  -1 * leaseLostInterest,
                                  -1 * buyLostInterest,
                                ],
                                backgroundColor: "#67DEB9",
                                borderColor: "#67DEB9",
                                datalabels: {
                                  display: false,
                                },
                              },
                              {
                                type: "bar",
                                label: "Lease Cost",
                                data: [-1 * leaseGrossCost, 0],
                                backgroundColor: "#ECB361",
                                borderColor: "#ECB361",
                                datalabels: {
                                  display: false,
                                },
                              },
                              {
                                type: "bar",
                                label: "Asset Value",
                                data: [0, carMarketValue],
                                backgroundColor: "#81A3DE",
                                borderColor: "#81A3DE",
                                datalabels: {
                                  display: false,
                                },
                              },

                              {
                                type: "bar",
                                label: "Buy Cost",
                                data: [0, -1 * buyGrossCost],
                                backgroundColor: "#5BBEEB",
                                borderColor: "#5BBEEB",
                                datalabels: {
                                  display: false,
                                },
                              },
                            ],
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      horizontalAlign="center"
                      tokens={{ childrenGap: "2rem" }}
                      className={classes.report}
                    >
                      <ResultReport
                        isGreen={buyNetCost <= leaseNetCost}
                        onChange={() => null}
                        reportGreenLabel="You should consider Buying"
                        greenAlt="#5BBEEB"
                        reportRedLabel="You should consider Leasing"
                        redAlt="#ECB361"
                      />
                      <Stack style={{ width: "30rem", marginTop: "5rem" }}>
                        <MaskedLabelPair
                          color=""
                          isBulleted={false}
                          title="Leasing Net Cost: "
                          titleStyle={{
                            fontWeight: "bold",
                            fontSize: "1.8rem",
                          }}
                          value={leaseNetCost}
                          valueFontSize={"1.6rem"}
                        />
                      </Stack>
                      <BreakdownList
                        style={{ width: "30rem" }}
                        data={[
                          {
                            key: 0,
                            title: "Lease Gross Cost",
                            value: leaseGrossCost,
                            color: "#ECB361",
                          },
                          {
                            key: 1,
                            title: "Lost Interests on Lease",
                            value: leaseLostInterest,
                            color: "#67DEB9",
                          },
                        ]}
                      />
                      <Stack style={{ width: "30rem" }}>
                        <MaskedLabelPair
                          color=""
                          isBulleted={false}
                          title="Buying Net Cost: "
                          titleStyle={{
                            fontWeight: "bold",
                            fontSize: "1.8rem",
                          }}
                          value={buyNetCost}
                          valueFontSize={"1.6rem"}
                        />
                      </Stack>
                      <BreakdownList
                        style={{ width: "30rem" }}
                        data={[
                          {
                            key: 2,
                            title: "Buy Gross Cost",
                            value: buyGrossCost,
                            color: "#5BBEEB",
                          },
                          {
                            key: 3,
                            title: "Car Market Value",
                            value: carMarketValue,
                            color: "#81A3DE",
                          },
                          {
                            key: 4,
                            title: "Lost Interests On Buy",
                            value: buyLostInterest,
                            color: "#67DEB9",
                          },
                        ]}
                      />
                    </Stack>
                  </Stack>
                )}
                {breakdownDivider && (
                  <Stack
                    wrap
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: "3rem" }}
                  >
                    <Stack className={classes.chart}>
                      <StackedChart
                        barPercentage={0.6}
                        datasets={{
                          labels: [""],
                          datasets: [
                            {
                              type: "bar",
                              label: "Insurance",
                              data: [monthlyInsurance * 12],
                              backgroundColor: "#ECB361",
                              borderColor: "#ECB361",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Registration Fees",
                              data: [firstYearRegistrationFees],
                              backgroundColor: "#97CF67",
                              borderColor: "#97CF67",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Sales Taxes",
                              data: [firstYearBuySalesTax],
                              backgroundColor: "#67DEB9",
                              borderColor: "#67DEB9",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Down Payment",
                              data: [firstYearBuyDownPayment],
                              backgroundColor: "#5BBEEB",
                              borderColor: "#5BBEEB",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Loan Payments",
                              data: [firstYearBuyLoanPayments],
                              backgroundColor: "#8789C0",
                              borderColor: "#8789C0",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Maintenance",
                              data: [firstYearMaintenanceAmount],
                              backgroundColor: "#DCEA41",
                              borderColor: "#DCEA41",
                              datalabels: {
                                display: false,
                              },
                            },
                          ],
                        }}
                      />
                    </Stack>
                    <Stack
                      horizontalAlign="center"
                      tokens={{ childrenGap: "2rem" }}
                      className={classes.report}
                    >
                      <ResultReport
                        isGreen={buyNetCost <= leaseNetCost}
                        onChange={() => null}
                        reportGreenLabel="You should consider Buying"
                        greenAlt="#5BBEEB"
                        reportRedLabel="You should consider Leasing"
                        redAlt="#ECB361"
                      />
                      <BreakdownList
                        style={{ width: "30rem", marginTop: 0 }}
                        data={[
                          {
                            key: 0,
                            title: "Maintenance",
                            value: firstYearMaintenanceAmount,
                            color: "#DCEA41",
                          },
                          {
                            key: 1,
                            title: "Loan Payments",
                            value: firstYearBuyLoanPayments,
                            color: "#8789C0",
                          },
                          {
                            key: 2,
                            title: "Down Payment",
                            value: firstYearBuyDownPayment,
                            color: "#5BBEEB",
                          },
                          {
                            key: 3,
                            title: "Sales Taxes",
                            value: firstYearBuySalesTax,
                            color: "#67DEB9",
                          },
                          {
                            key: 4,
                            title: "Registration Fees",
                            value: firstYearRegistrationFees,
                            color: "#97CF67",
                          },
                          {
                            key: 5,
                            title: "Insurance",
                            value: monthlyInsurance * 12,
                            color: "#ECB361",
                          },
                        ]}
                      />
                    </Stack>
                  </Stack>
                )}
              </DividerContainer>
            </Stack>
          </Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: "4rem" }}
          >
            <ConfigButton type="setup" toggleFocus={setup} onClick={onSetup} />
            <ConfigButton type="simu" toggleFocus={simu} onClick={onSimu} />
            <ConfigButton type="levelup" toggleFocus={lvl} onClick={onLvl} />
          </Stack>
          {setup && (
            <Stack
              wrap
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: "1rem" }}
            >
              <EFAccordion title="">{setupContent}</EFAccordion>
            </Stack>
          )}
          {simu && (
            <Stack
              wrap
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: "1rem" }}
            >
              <EFAccordion title="Vehicle Price">{simuContent1}</EFAccordion>
              <EFAccordion title="Buy Options">{simuContent2}</EFAccordion>
              <EFAccordion title="Lease Options">{simuContent3}</EFAccordion>
            </Stack>
          )}
          {lvl && (
            <Stack
              wrap
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: "1rem" }}
            >
              <EFAccordion title="Upfront Costs">
                {upfrontCostsContent}
              </EFAccordion>
              <EFAccordion title="On-going Costs">
                {onGoingCostsContent}
              </EFAccordion>
              <EFAccordion title="Opportunity Costs">
                {oppCostsContent}
              </EFAccordion>
            </Stack>
          )}
        </Stack>
      )}
      {props.currentView === "Low Down" && (
        <StaticContainer url="https://content.equalfuture.com/blog/Car/lowdown" />
      )}
      {props.currentView === "Nitty Gritty" && (
        <StaticContainer url="https://content.equalfuture.com/blog/Car/nittygritty" />
      )}
    </>
  );
};

export default CarBuyLease;
