const modalCardStyle = (content?: any, overlay?: any) => ({
    content: {
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: "none" as "none",
        overflow: "visible",
        height: "fit-content",
        background: "none" as "none",
        left: "auto",
        right: "auto",
        padding: 0,
        marginTop: "2rem",
        ...content,
    },
    overlay: {
        display: "flex" as "flex",
        justifyContent: "center" as "center",
        overflowY: "auto" as "auto",
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)',
        zIndex: 10,
        ...overlay,
    },
});

export default modalCardStyle