import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import classes from './Divider.module.css';
import { useResponsive } from '../../../hooks/useResponsive';

interface IProps {
    title?: string,
    subtitle?: string,
    icon?: string,
    isOpen?: boolean,
    onClick?: () => void,
};

const Divider: React.FC<IProps> = props => {
    const labelStyle = { fontSize: '1.6rem', cursor: 'pointer' as 'pointer' };
    const styles = props.isOpen ? classes.divider : `${classes.divider} ${classes.notopen}`;
    const { screenIsAtLeast } = useResponsive();

    return (
        <Stack 
            horizontal 
            horizontalAlign='start' 
            verticalAlign='center' 
            tokens={{childrenGap: '1.5rem'}} 
            onClick={props.onClick} 
            className={styles}
        >
            {screenIsAtLeast('tabPort') ? <img alt='icon' src={props.icon}/> : <></>}
            <Stack>
                <EFLabel style={labelStyle}>{props.title}</EFLabel>
                <EFLabel style={labelStyle}>{props.subtitle}</EFLabel>
            </Stack>
        </Stack>
    )
};

export default Divider;
