import React from 'react';
import { initializeIcons } from '@uifabric/icons';
import { registerIcons } from '@uifabric/styling';
import { Text } from 'office-ui-fabric-react/lib/';

export default function setUpIcons() {
    const iconStyles = {
        root: {
          fontFamily: 'brandon-grotesque',
          fontWeight: '700' as '700',
          color: '#feb81e',
          fontSize: 24,
        },
    };

    // initializes fabric icons
    initializeIcons();

    // initializes custom icons
    registerIcons({
        icons: {
            Minus: <Text styles={iconStyles}>-</Text>,
            Plus: <Text styles={iconStyles}>+</Text>,
            PwdEye: <img alt='pwd-eye' src={require('../assets/icons/eye1.5x.svg')} />,
            CheckedMarker: <img alt='checked-marker' src={require('../assets/icons/check.svg')} />,
            UnCheckedMarker: <img alt='unchecked-marker' src={require('../assets/icons/oval.svg')} />,
            GreyTrash: <img alt='trash' src={require('../assets/icons/trash.svg')} />,
            EFCalendar: <img alt='calendar' src={require('../assets/icons/calendar.svg')} />,
        },
    });
};