/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import aggregationIcon from '../../../assets/icons/aggregationIcon.svg';
import aggregationOffIcon from '../../../assets/icons/aggregationOffIcon.svg';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../utils/Masking';
import useDebounce from '../../../hooks/useDebounce';

import classes from './CurrencyInput.module.css';

interface IProps {
    id?: string;
    color?: string;
    value: number | string;
    // fixedValue?: number | string;
    value2?: number | string;
    onChange?: (v: any) => void;
    placeholder?: string;
    name?: string;
    stackClassName?: string;
    style?: object;
    onClickX?: () => void;
    smartCashflow?: boolean;
    isAggr?: (isAggr: boolean) => void;
    maxVal?: number,
    minVal?: number,
    leSource?: any,
};

const CurrencyInput: React.FC<IProps> = props => {
    const [focus, setFocus] = useState(false);
    const [color, setColor] = useState(
        props.value2 && props.value === props.value2 ? '#2695C7' : '#979797'
    );
    let value = (typeof (props.value) !== 'number') ? 0 : props.value;
    // const debouncedValue = useDebounce(value, 500);

    // useEffect(() => {
    //     if (props.value !== props.fixedValue) {
    //         props.saveEntry && props.saveEntry();
    //     }
    // }, [debouncedValue])

    const handleStrToNum = (str: string) => {
        let curr_str = str;
        curr_str = curr_str.replace(/,/g, '');
        curr_str = curr_str.replace('$', '');
        let numVal = parseFloat(curr_str);
        if (isNaN(numVal) || numVal < props.minVal) {
            numVal = props.minVal;
        } else if (numVal > props.maxVal) {
            numVal = props.maxVal;
        };
        return numVal;
    };

    const onConfirmButtonClickHandler = () => {
        if (color === '#979797') {
            setColor('#2695C7');
            props.onChange(props.value2);
        } else {
            setColor('#979797');
            props.onChange(0);
        }
    }

    useEffect(() => {
        // Adding a timeout to wait for capsule animation to finish
        setTimeout(() => {
            props.isAggr && props.isAggr(!!props.value2)
        }, 1500)
    }, [])

    useEffect(() => {
        props.value2 === props.value ? setColor('#2695C7') : setColor('#979797');
    }, [props.value])

    const decrementValue = () => {
        const newVal = (value <= 0) ? 0 : value - getStep();
        props.onChange && props.onChange(newVal as number);
    }

    const incrementValue = () => {
        const newVal = (value >= 100000) ? 100000 : value + getStep();
        props.onChange && props.onChange(newVal as number);
    }

    const getStep = () => {
        const checkNumRange = (min: number, max: number) => {
            return (value - min ^ value - max) < 0;
        };

        switch (true) {
            case checkNumRange(0, 50): return 5;
            case checkNumRange(50, 100): return 10;
            case checkNumRange(100, 1000): return 50;
            case checkNumRange(1000, 10000): return 100;
            case checkNumRange(10000, 25000): return 250;
            case checkNumRange(25000, 50000): return 500;
            case checkNumRange(50000, 100000): return 1000;
            case undefined: return 1;
            default: return 1;
        };
    };

    return (
        <div id={props.id}>
            <Stack
                horizontal
                verticalAlign='center'
                horizontalAlign='center'
                onMouseEnter={() => setFocus(true)}
                onMouseLeave={() => setFocus(false)}
            >
                <Stack
                    verticalAlign='center'
                    style={{ position: 'absolute', left: color === '#2695C7' ? '-3px' : '-2px' }}
                >
                    {props.leSource ? (
                        <img
                            id="le"
                            className={classes.le}
                            src={props.leSource}
                            alt="le-source"
                        />
                    ) :
                        props.value2 ? (
                            <img
                                id="aggr-val"
                                data-tour={color === '#2695C7' && 'aggr-icon'}
                                alt="apply-aggregagtion"
                                src={color === '#2695C7' ? aggregationIcon : aggregationOffIcon}
                                className={classes.x}
                                onClick={onConfirmButtonClickHandler}
                                style={{ margin: '0.7rem 0 0 0.5rem' }}
                            />
                        ) :
                            <></>
                    }
                </Stack>
                <Stack
                    style={{ background: focus && '#ffffff', border: focus && `1px solid ${props.color || '#FEB81E'}` }}
                    className={classes.container}
                >
                    <EFLabel
                        style={{ color: color, maxWidth: '9rem', marginLeft: '1rem', fontSize: '1.4rem' }}
                    >
                        {props.placeholder}
                    </EFLabel>
                    <Stack horizontal>
                        <MaskedInput
                            style={{ ...props.style, color: color }}
                            name={props.name}
                            mask={numberMask}
                            placeholder={props.value !== null ? props.value.toString() : '$0'}
                            value={props.value}
                            onChange={(v) => {
                                let value = v.target?.value ?
                                    handleStrToNum(v.target.value) : typeof v === 'number' ? v : 0;
                                props.onChange && props.onChange(value)
                            }}
                            className={`${classes.maskedInput} 
                                ${props.stackClassName}`}
                        />
                    </Stack>
                </Stack>
                <span
                    style={{
                        //focus ? props.color || '#FEB81E' : 'transparent',
                        backgroundColor: !focus && 'transparent',
                        color: !focus && 'transparent',
                        margin: '0 0.2rem 0 0.4rem'
                    }}
                    className={classes.btn}
                    onClick={decrementValue}
                >
                    -
                </span>
                <span
                    style={{
                        backgroundColor: !focus && 'transparent',
                        color: !focus && 'transparent',
                    }}
                    className={classes.btn}
                    onClick={incrementValue}
                >
                    +
                </span>
            </Stack>
            {props.value2 && props.value !== props.value2 ?
                <EFLabel
                    style={{
                        fontSize: '1.4rem',
                        color: '#2695C7',
                        fontWeight: 'unset',
                        textAlign: 'center',
                        width: '16.5rem',
                        marginTop: '.5rem'
                    }}
                >
                    {`Your aggregated amount is ${maskValue((props.value2 as number), numberMask)}`}
                </EFLabel> : <></>
            }
        </div>
    )
};

// Number mask used for USD currency
// Can move these as props for more customization
const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowLeadingZeroes: false,
    allowNegative: false,
});

export default CurrencyInput;
