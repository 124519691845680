/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Modal from 'react-modal';

interface IProps {
    openLifeEventSaveModal: boolean;
    onClose: () => void;
};

const SaveChangesModal: React.FC<IProps> = props => {
    
    return (
        <Modal
            isOpen={props.openLifeEventSaveModal}
            onRequestClose={props.onClose}
            style={modalCardStyle}
        >
            {props.children}
        </Modal>
    )
};

const modalCardStyle = {
    content: {
      justifyContent: "center" as "center",
      alignItems: "middle" as "middle",
      borderRadius: 10,
      borderStyle: "none" as "none",
      overflow: "visible",
      height: "fit-content",
      background: "none" as "none",
      left: "auto",
      right: "auto",
      padding: 0,
      marginTop: "2rem",
    },
    overlay: {
      display: "flex" as "flex",
      justifyContent: "center" as "center",
      overflowY: "auto" as "auto",
      background: 'rgba(248, 250, 255, 0.50)',
      backdropFilter: 'blur(40px)',
      WebkitBackdropFilter: 'blur(40px)',
      zIndex: 20,
    },
};

export default SaveChangesModal;
