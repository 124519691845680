import retireIcon from '../../assets/images/lifeEvents/bigstuff/retire.svg';
import sDebtIcon from '../../assets/images/lifeEvents/money/student_debt.svg';
import mDebtIcon from '../../assets/images/lifeEvents/money/manage_debt.svg';
import houseIcon from '../../assets/images/lifeEvents/bigstuff/buy_home.svg';
import carIcon from '../../assets/images/lifeEvents/bigstuff/buy_car.svg';
import collegeIcon from '../../assets/images/lifeEvents/bigstuff/college.svg';
import moment from 'moment';

//Background Images for Plan Cards

import budget from '../../assets/images/budget_bg.svg'; //Double check this one

import gettingMarried from '../../assets/images/gettingMarriedBack.svg';
import startFamily from '../../assets/images/startFamilyBack.svg';
import gettingDivorced from '../../assets/images/gettingDivorcedBack.svg';
import estatePlan from '../../assets/images/estatePlanBack.svg';

import jobLoss from '../../assets/images/jobLoss.svg';
import careerGoal from '../../assets/images/mypassionbck.svg';
import benefits from '../../assets/images/benefits.svg';
import sideHustle from '../../assets/images/sideHustle.svg';
import gigWorker from '../../assets/images/gigWorker.svg';
import newJob from '../../assets/images/newJob.svg';
import jobChange from '../../assets/images/jobChange.svg';

import lifeInsurance from '../../assets/images/lifeInsurance.svg';

import affordability from '../../assets/images/planning/affordability_bg.png';
import manageDebt from '../../assets/images/manageDebt.svg';
import studentDebt from '../../assets/images/studentDebt.svg';
import emergencyFund from '../../assets/images/emergencyFund.svg';

import collegePlan from '../../assets/images/planning/college_bg.png';
import buyHouse from '../../assets/images/buyHouse.svg';
import buyCar from '../../assets/images/buyCar.svg';
import retireReboot from '../../assets/images/retireRebootBack.svg';


/**
 * Maps IDs to respective orginal life event names
 * @param eventId ID by lifeEventId (for personas) or externalId (via contentful) 
 */
export const getLifeEventNameById = (eventId: string) => {
    // IDs are either by "LE ID" or "External ID"
    switch (eventId) {
        case "5ebc058f75f0b3167e8447d6":
        case "15cY0o7S7hwiHdzTNLxNJL":
            return "Affordability"
        case "5ebc058f75f0b3c6ee8447d7":
        case "6ceIi2ikmezTJtxIDEuRsg":
            return "Career Goal"
        case "5c799fac4b6e9eaafb52939f":
        case "3s7O7PtEaKgjdAML8huqsO":
            return "Retire / Reboot"
        case "5c951cb9247ea6a8b0d2f5fd":
        case "5lwJJdsnVdI0HycjtVhRXH":
            return "College Plan"
        case "5d1b53ab05d54a7a9c8484dc":
        case "prMyQcowYYyVC8N5XNQHn":
            return "Student Debt"
        case "5c951d8a247ea6a8b0d2f601":
        case "1zTG17lpX4S66cXfr62Rde":
            return "Manage Debt"
        case "5c79a01807c935ab420c2e42":
        case "45gRdA9ve4p9gIqorC7a6i":
            return "Getting Married"
        case "5c951af7247ea6a8b0d2f5f7":
            return "Start a Family"
        case "5c951b50247ea6a8b0d2f5f9":
        case "7bLtNggDKWOjw1ztuQ3uqq":
            return "Estate Plan"
        case "5c951c2a247ea6a8b0d2f5fb":
        case "5SZQU7RDR7R2Ts8SjJxKjZ":
            return "Getting Divorced"
        case "5c951cdf247ea6a8b0d2f5fe":
        case "O7v6vw1nqpANUiFn3SK7n":
            return "Savings Goal"
        case "5c951d35247ea6a8b0d2f5ff":
        case "1GzC0nENxgH3MN2bwhwEOr":
            return "Buy a Car"
        case "5c951d58247ea6a8b0d2f600":
        case "4cG7ftwrWfEeW2Bqj1ThiA":
            return "Buy a House"
        case "5c951dcc247ea6a8b0d2f602":
            return "Travel"
        case "5c951e93247ea6a8b0d2f606":
        case "3u6QYATZ4DlIMmfeHanzqD":
            return "New Job"
        case "5c951eb3247ea6a8b0d2f607":
            return "Job Change"
        case "5d37680e3aada0d9ab99045c":
            return "Other Insurance"
        case "5d37680e3aada0175599045e":
        case "71OxoP9ERDxSQQqJMjODL9":
            return "Side Hustle"
        case "5d377c243aada078bd990464":
        case "6auybRL2bWkIHRsWbSVMzb":
            return "Gig Worker"
        case "5d377c243aada05dbf990465":
            return "Space Travel"
        case "5d377c243aada0a72f990463":
        case "5qothgTh3jmeIWcLULRxq0":
            return "Life Insurance"
        case "5d9519be323de31188120f9c":
        case "44R8Vy6mKWirpfc3AOUsBf":
            return "Emergency Fund"
        case "'5d951b4f323de34237120f9d'":
            return "Job Loss"
        case "5c951ece247ea6a8b0d2f608":
            return "Benefits"
        case "5d37680e3aada0cdf799045b":
            return "Property Insurance"
        case "5d951ecd323de3ac6f120f9e":
            return "Auto Insurance"
        case "5d7a932c4661859fd20cd8c6":
            return "Robotic Healthcare"
        case "5d7a932c46618582870cd8c5":
            return "Shooting Star"
        default:
            return "Other"
    }
}

/**
 * Grabs the current custom data relative to life event via the store
 * @param lifeEvent Desired life event by original name or external Id
 * @param dataSet Current data set of changeable life event custom data examined from redux store
 */
export const getCurrentLifeEventCustomData = (lifeEvent: string, dataSet: any) => {
    switch (lifeEvent) {
        case "Retire / Reboot":
        case "3s7O7PtEaKgjdAML8huqsO":
            return dataSet.retireReboot
        case "College Plan":
        case "5lwJJdsnVdI0HycjtVhRXH":
            return dataSet.collegePlan
        case "Buy a House":
        case "House":
        case "4cG7ftwrWfEeW2Bqj1ThiA":
            return dataSet.buyHouse
        case "Buy a Car":
        case "Car":
        case "1GzC0nENxgH3MN2bwhwEOr":
            return dataSet.buyCar
        case 'Student Debt':
        case "prMyQcowYYyVC8N5XNQHn":
            return dataSet.studentDebt
        case "Manage Debt":
        case "1zTG17lpX4S66cXfr62Rde":
            return dataSet.manageDebt
        case "Savings Goal":
        case "O7v6vw1nqpANUiFn3SK7n":
            return dataSet.savingGoal;
        case "Affordability":
        case "15cY0o7S7hwiHdzTNLxNJL":
            return dataSet.affordability
        case "Career Goal":
        case "CAREER PLANNING TOOL":
        case "6ceIi2ikmezTJtxIDEuRsg":
            return dataSet.careerGoal
        default:
            return {}
    }
}

export const getNavMenu = (name: string) => {
    let menu = [];
    switch (name) {
        case 'Affordability': {
            menu = ["Connect the dots"];
            break;
        }
        case 'Career Goal': {
            menu = ["Connect the dots"];
            break;
        }
        case 'Retire / Reboot': {
            menu = ["Connect the dots", "Low Down", "Nitty Gritty"];
            break;
        }
        case 'College Plan': {
            menu = ["Connect the dots", "Low Down", "Nitty Gritty"];
            break;
        }
        case 'Student Debt': {
            menu = ["Connect the dots", "Low Down", "Nitty Gritty"];
            break;
        }
        case 'Manage Debt': {
            menu = ["Connect the dots", "Low Down", "Nitty Gritty"];
            break;
        }
        case 'Getting Married': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Start a Family': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Estate Plan': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Getting Divorced': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Savings Goal': {
            menu = ["Connect the dots", "Low Down", "Nitty Gritty"];
            break;
        }
        case 'Buy a Car': {
            menu = ["Connect the dots", "Low Down", "Nitty Gritty"];
            break;
        }
        case 'House':
        case 'Buy a House': {
            menu = ["Connect the dots", "Low Down", "Nitty Gritty"];
            break;
        }
        case 'Travel': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'New Job': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Job Change': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Other Insurance': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Side Hustle': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Gig Worker': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Space Travel': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Life Insurance': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Emergency Fund': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Job Loss': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Benefits': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Property Insurance': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Auto Insurance': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Robotic Healthcare': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
        case 'Shooting Start': {
            menu = ["Low Down", "Nitty Gritty"];
            break;
        }
    }
    return menu;
}

export const getId = (type: string) => {
    switch (type) {
        case "New Job": {
            return "5c951e93247ea6a8b0d2f606"
        }
        case "Retire / Reboot": {
            return '5c799fac4b6e9eaafb52939f'
        }
        case "Getting Married": {
            return '5c79a01807c935ab420c2e42'
        }
        case 'Start a Family': {
            return '5c951af7247ea6a8b0d2f5f7'
        }
        case 'Estate Plan': {
            return '5c951b50247ea6a8b0d2f5f9'
        }
        case 'Getting Divorced': {
            return '5c951c2a247ea6a8b0d2f5fb'
        }
        case 'College Plan': {
            return '5c951cb9247ea6a8b0d2f5fd'
        }
        case 'Savings Goal': {
            return '5c951cdf247ea6a8b0d2f5fe'
        }
        case 'Buy a Car': {
            return '5c951d35247ea6a8b0d2f5ff'
        }
        case 'Buy a House': {
            return '5c951d58247ea6a8b0d2f600'
        }
        case 'Manage Debt': {
            return '5c951d8a247ea6a8b0d2f601'
        }
        case 'Travel': {
            return '5c951dcc247ea6a8b0d2f602'
        }
        case 'Job Change': {
            return '5c951eb3247ea6a8b0d2f607'
        }
        case 'Benefits': {
            return '5c951ece247ea6a8b0d2f608'
        }
        case 'Student Debt': {
            return '5d1b53ab05d54a7a9c8484dc'
        }
        case 'Other Insurance': {
            return '5d37680e3aada0d9ab99045c'
        }
        case 'Side Hustle': {
            return '5d37680e3aada0175599045e'
        }
        case 'Property Insurance': {
            return '5d37680e3aada0cdf799045b'
        }
        case 'Gig Worker': {
            return '5d377c243aada078bd990464'
        }
        case 'Space Travel': {
            return '5d377c243aada05dbf990465'
        }
        case 'Life Insurance': {
            return '5d377c243aada0a72f990463'
        }
        case 'Shooting Star': {
            return '5d7a932c46618582870cd8c5'
        }
        case 'Robotic Healthcare': {
            return '5d7a932c4661859fd20cd8c6'
        }
        case 'Emergency Fund': {
            return '5d9519be323de31188120f9c'
        }
        case 'Job Loss': {
            return '5d951b4f323de34237120f9d'
        }
        case 'Auto Insurance': {
            return '5d951ecd323de3ac6f120f9e'
        }
        case 'Affordability': {
            return '5ebc058f75f0b3167e8447d6'
        }
        case 'Career Goal':
        case 'CAREER PLANNING TOOL': {
            return '5ebc058f75f0b3c6ee8447d7'
        }
        default: {
            return '';
        }
    }
}

export const mapLEToDetails = (lifeEvent: any, cashflowDates: any) => {
    switch (lifeEvent.externalId) {
        // RETIRE / REBOOT
        case "3s7O7PtEaKgjdAML8huqsO":
            return {
                id: lifeEvent.id,
                icon: retireIcon,
                name: lifeEvent.name,
                expenseLabel: 'Monthly Contribution',
                expenseValue: lifeEvent && lifeEvent.data.monthlyContribution,
                expSuffix: '/Month',
                incomeLabel: lifeEvent && lifeEvent.data.shortfall ? 'Shortfall' : 'Surplus',
                incomeValue: lifeEvent && lifeEvent.data.shortfall ? lifeEvent && lifeEvent.data.shortfall : lifeEvent && lifeEvent.data.surplus,
                incomeColor: lifeEvent && lifeEvent.data.shortfall ? '#F3798D' : '#30DB20',
                incSuffix: '',
                startDate: lifeEvent && lifeEvent.date,
                endDate: cashflowDates[cashflowDates.length - 1], //indef
                highlightColor: '#FACCD3',
                iconColor: '#F3798D',
                iconContainerColor: '#FACCD3',
                min: 0,
                max: 100000,
            }
        // STUDENT DEBT
        case "prMyQcowYYyVC8N5XNQHn":
            return {
                id: lifeEvent.id,
                icon: sDebtIcon,
                name: lifeEvent.name,
                expenseLabel: 'Monthly Payment',
                expenseValue: lifeEvent.data && lifeEvent.data.monthlyPayment,
                expSuffix: '/Month',
                incomeLabel: 'Paid off by',
                incomeValue: lifeEvent.data && lifeEvent.data.paidOfByYear.toString(),
                incSuffix: '',
                startDate: lifeEvent && lifeEvent.date,
                endDate: lifeEvent && moment(lifeEvent.date).add(lifeEvent.data && lifeEvent.data.paidOfByYear - moment(lifeEvent.date).year(), 'years'),
                highlightColor: '#E8F4CE',
                iconColor: '#8FBE33',
                iconContainerColor: '#E8F4CE',
                min: 0,
                max: 100000,
            }
        // MANAGE DEBT
        case "1zTG17lpX4S66cXfr62Rde":
            return {
                id: lifeEvent.id,
                icon: mDebtIcon,
                name: lifeEvent.name,
                expenseLabel: 'Monthly Payment',
                expenseValue: lifeEvent && lifeEvent.data.monthlyPayment,
                expSuffix: '/Month',
                incomeLabel: 'Paid off by',
                incomeValue: lifeEvent && lifeEvent.data.paidOfByYear.toString(),
                incSuffix: '',
                startDate: lifeEvent && lifeEvent.date,
                endDate: lifeEvent && moment(lifeEvent.date).add(lifeEvent.data.paidOfByYear - moment(lifeEvent.date).year(), 'years'),
                highlightColor: '#E8F4CE',
                iconColor: '#8FBE33',
                iconContainerColor: '#E8F4CE',
                min: 0,
                max: 100000,
            }
        case "4cG7ftwrWfEeW2Bqj1ThiA":
            // HOUSE
            return {
                id: lifeEvent.id,
                icon: houseIcon,
                name: lifeEvent.name,
                expenseLabel: 'Monthly Payment',
                expenseValue: lifeEvent && lifeEvent.data.monthlyPayment,
                expSuffix: '/Month',
                incomeLabel: 'Interest Rate',
                incomeValue: lifeEvent && lifeEvent.data.interestsRate.toString(),
                incSuffix: '%',
                startDate: lifeEvent && lifeEvent.date,
                endDate: lifeEvent && moment(lifeEvent.date).add(lifeEvent.data.term, 'years'),
                highlightColor: '#FACCD3',
                iconColor: '#F3798D',
                iconContainerColor: '#FACCD3',
                min: 0,
                max: 100000,
            }
        case "1GzC0nENxgH3MN2bwhwEOr":
            // CAR
            return {
                id: lifeEvent.id,
                icon: carIcon,
                name: lifeEvent.name,
                expenseLabel: 'Lease - Loan Term',
                expenseValue: lifeEvent && lifeEvent.data.monthlyPayment,
                expSuffix: '/Month',
                incomeLabel: 'Interest Rate',
                incomeValue: lifeEvent && lifeEvent.data.interestsRate.toString(),
                incSuffix: '%',
                startDate: lifeEvent && lifeEvent.date,
                endDate: lifeEvent && moment(lifeEvent.date).add(lifeEvent.data.term, 'years'),
                highlightColor: '#FACCD3',
                iconColor: '#F3798D',
                iconContainerColor: '#FACCD3',
                min: 0,
                max: 100000,
            }
        case "5lwJJdsnVdI0HycjtVhRXH":
            // COLLEGE PLAN
            return {
                id: lifeEvent.id,
                icon: collegeIcon,
                name: lifeEvent.name,
                expenseLabel: 'Monthly Contribution',
                expenseValue: lifeEvent && lifeEvent.data.monthlyContribution,
                expSuffix: '/Month',
                incomeLabel: lifeEvent && lifeEvent.data.shortfall ? 'Shortfall' : 'Surplus',
                incomeValue: lifeEvent && lifeEvent.data.shortfall ? lifeEvent && lifeEvent.data.shortfall : lifeEvent && lifeEvent.data.surplus,
                incomeColor: lifeEvent && lifeEvent.data.shortfall ? '#F3798D' : '#30DB20',
                incSuffix: '',
                startDate: lifeEvent && lifeEvent.date,
                endDate: lifeEvent && moment(lifeEvent.date).add(lifeEvent.data.yearsToEnrollment, 'years'), //accurate ?
                highlightColor: '#FACCD3',
                iconColor: '#F3798D',
                iconContainerColor: '#FACCD3',
                min: 0,
                max: 100000,
            }
    }
}

export const mapLEToDetailsV2 = (lifeEvent: any) => {
    switch (lifeEvent?.externalId) {
        // SAVINGS GOALS
        case "O7v6vw1nqpANUiFn3SK7n":
            return {
                id: lifeEvent.id,
                icon: lifeEvent?.icon[0]?.url,
                name: lifeEvent.name,
                lifeEventId: "5c951cdf247ea6a8b0d2f5fe",
                externalId: "O7v6vw1nqpANUiFn3SK7n",
                iconColor: '#8FBE33',
                iconContainerColor: '#E8F4CE',
                iconBG: getTextColorAndBckgImgFromName(getLifeEventNameById(lifeEvent?.externalId))?.image,
                overview: lifeEvent.overview
            }
        // RETIRE / REBOOT
        case "3s7O7PtEaKgjdAML8huqsO":
            return {
                id: lifeEvent.id,
                icon: lifeEvent?.icon[0]?.url,
                name: lifeEvent.name,
                fixedValue: Math.round(lifeEvent.customData.monthlyContribution401k),
                customLabel: "monthlyContribution401k",
                lifeEventId: "5c799fac4b6e9eaafb52939f",
                externalId: "3s7O7PtEaKgjdAML8huqsO",
                iconColor: '#F3798D',
                iconContainerColor: '#FACCD3',
                min: 0,
                max: 1583, // 401k -> 1583 | 56000 <- IRA
                iconBG: getTextColorAndBckgImgFromName(getLifeEventNameById(lifeEvent?.externalId))?.image,
                cashCard: 10018569,
                overview: lifeEvent.overview
            }
        // STUDENT DEBT
        case "prMyQcowYYyVC8N5XNQHn":
            return {
                id: lifeEvent.id,
                name: lifeEvent.name,
                icon: lifeEvent?.icon[0]?.url,
                fixedValue: lifeEvent.customData.currentPayment,
                customLabel: "currentPayment",
                lifeEventId: "5d1b53ab05d54a7a9c8484dc",
                externalId: "prMyQcowYYyVC8N5XNQHn",
                iconColor: '#8FBE33',
                iconContainerColor: '#E8F4CE',
                min: 0,
                max: 100000,
                iconBG: getTextColorAndBckgImgFromName(getLifeEventNameById(lifeEvent?.externalId))?.image,
                cashCard: 10017614,
                overview: lifeEvent.overview
            }
        // MANAGE DEBT
        case "1zTG17lpX4S66cXfr62Rde":
            return {
                id: lifeEvent.id,
                name: lifeEvent.name,
                icon: lifeEvent?.icon[0]?.url,
                customData: lifeEvent.customData,
                fixedValue: lifeEvent.customData.currentPayment,
                customLabel: "currentPayment",
                lifeEventId: "5c951d8a247ea6a8b0d2f601",
                externalId: "1zTG17lpX4S66cXfr62Rde",
                iconColor: '#8FBE33',
                iconContainerColor: '#E8F4CE',
                min: 0,
                max: 100000,
                iconBG: getTextColorAndBckgImgFromName(getLifeEventNameById(lifeEvent?.externalId))?.image,
                cashCard: 10017614,
                overview: lifeEvent.overview
            }
        // HOUSE
        case "4cG7ftwrWfEeW2Bqj1ThiA":
            return {
                id: lifeEvent.id,
                name: lifeEvent.name,
                icon: lifeEvent?.icon[0]?.url,
                fixedValue: lifeEvent.customData.monthlyRent,
                customLabel: "monthlyRent",
                lifeEventId: "5c951d58247ea6a8b0d2f600",
                externalId: "4cG7ftwrWfEeW2Bqj1ThiA",
                iconColor: '#F3798D',
                iconContainerColor: '#FACCD3',
                min: 0,
                max: 100000,
                iconBG: getTextColorAndBckgImgFromName(getLifeEventNameById(lifeEvent?.externalId))?.image,
                cashCard: 10006171,
                overview: lifeEvent.overview
            }
        // CAR
        case "1GzC0nENxgH3MN2bwhwEOr":
            return {
                id: lifeEvent.id,
                name: lifeEvent.name,
                icon: lifeEvent?.icon[0]?.url,
                fixedValue: lifeEvent.customData.monthlyPayment,
                customLabel: "monthlyPayment",
                lifeEventId: "5c951d35247ea6a8b0d2f5ff",
                externalId: "1GzC0nENxgH3MN2bwhwEOr",
                iconColor: '#F3798D',
                iconContainerColor: '#FACCD3',
                min: 0,
                max: 100000,
                iconBG: getTextColorAndBckgImgFromName(getLifeEventNameById(lifeEvent?.externalId))?.image,
                cashCard: 10019141,
                overview: lifeEvent.overview
            }
        // COLLEGE PLAN
        case "5lwJJdsnVdI0HycjtVhRXH":
            return {
                id: lifeEvent.id,
                name: lifeEvent.name,
                icon: lifeEvent?.icon[0]?.url,
                fixedValue: Math.trunc(lifeEvent.customData.monthlyContribution),
                customLabel: "monthlyContribution",
                lifeEventId: "5c951cb9247ea6a8b0d2f5fd",
                externalId: "5lwJJdsnVdI0HycjtVhRXH",
                iconColor: '#F3798D',
                iconContainerColor: '#FACCD3',
                min: 0,
                max: 1000000,
                iconBG: getTextColorAndBckgImgFromName(getLifeEventNameById(lifeEvent?.externalId))?.image,
                cashCard: 10016314,
                overview: lifeEvent.overview
            }
    }
}

export const getTextColorAndBckgImgFromName = (lifeEventName: string) => {
    let result = {
        color: '',
        image: null
    };
    switch (lifeEventName) {
        case "Getting Married": {
            result = {
                color: '#B19FDD',
                image: gettingMarried
            }
            break;
        }
        case "Getting Divorced": {
            result = {
                color: '#B19FDD',
                image: gettingDivorced
            }
            break;
        }
        case "Estate Plan": {
            result = {
                color: '#B19FDD',
                image: estatePlan
            }
            break;
        }
        case "Career Goal": {
            result = {
                color: '#2695C7',
                image: careerGoal
            }
            break;
        }
        case "New Job": {
            result = {
                color: '#2695C7',
                image: newJob
            }
            break;
        }
        case "Gig Worker": {
            result = {
                color: '#2695C7',
                image: gigWorker
            }
            break;
        }
        case "Side Hustle": {
            result = {
                color: '#2695C7',
                image: sideHustle
            }
            break;
        }
        case "Manage Debt": {
            result = {
                color: '#8FBE33',
                image: manageDebt
            }
            break;
        }
        case "Student Debt": {
            result = {
                color: '#8FBE33',
                image: studentDebt
            }
            break;
        }
        case "Emergency Fund": {
            result = {
                color: '#8FBE33',
                image: emergencyFund
            }
            break;
        }
        case "Affordability": {
            result = {
                color: '#8FBE33',
                image: affordability
            }
            break;
        }
        case "Life Insurance": {
            result = {
                color: '#FEB81E',
                image: lifeInsurance
            }
            break;
        }
        case "Buy a House": {
            result = {
                color: '#F3798D',
                image: buyHouse
            }
            break;
        }
        case "Buy a Car": {
            result = {
                color: '#F3798D',
                image: buyCar
            }
            break;
        }
        case "College Plan": {
            result = {
                color: '#F3798D',
                image: collegePlan
            }
            break;
        }
        case "Retire / Reboot": {
            result = {
                color: '#F3798D',
                image: retireReboot
            }
            break;
        }
        case "Savings Goal": {
            result = {
                color: '#8FBE33',
                image: affordability
            }
            break;
        }
        default: {
            break;
        }
    }
    return result;
}

export const duplicationAllowed = (externalId: string) => {
    // Curr Life Events that allow duplication
    switch (externalId) {
        // College Plan
        case "5lwJJdsnVdI0HycjtVhRXH":
            return true
        // Buy a House
        case "4cG7ftwrWfEeW2Bqj1ThiA":
            return true
        //Buy a Car
        case "1GzC0nENxgH3MN2bwhwEOr":
            return true
        // Student Debt
        case "prMyQcowYYyVC8N5XNQHn":
            return true
        // Manage Debt
        case "1zTG17lpX4S66cXfr62Rde":
            return true
        //Savings Goal
        case "O7v6vw1nqpANUiFn3SK7n":
            return true;
        default:
            return false
    }
}