import { GET_QUESTIONS, GET_JOB_INFO, REMOVE_JOB_INFO } from '../actions/CareerPlanning';

const initialState = {
    data: null,
    jobInfo: null
};

const careerPlanningReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_QUESTIONS:
            return {
                ...state,
                data: action.data
            };

        case GET_JOB_INFO:
            return {
                ...state,
                jobInfo: action.info
            }
        
        case REMOVE_JOB_INFO:
            return {
                data: action.data,
                jobInfo: action.info
            }

        default:
            return state;
    }
};

export default careerPlanningReducer;