import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import { useHistory } from "react-router-dom";

import staceyAvatar from "../../assets/images/staceyAvatar.png";
import rosaAvatar from "../../assets/images/rosaAvatar.png";
import darylAvatar from "../../assets/images/darylAvatar.png";
import martinAvatar from "../../assets/images/martinAvatar.svg";
import eveAvatar from "../../assets/images/eveAvatar.svg";
import createProfileLabel from '../../assets/images/createProfileLabel.svg';
import { isCanonical } from '../../utils/global';

interface Props {
    id?: string,
    currentUser?: string,
    style?: object,
    img: string,
    onClick?: () => void,
    showDifferentLabel: boolean
};

const SelectBubble: React.FC<Props> = props => {

    let history = useHistory();

    const getAvatar = (name: string) => {

        let avatar;

        switch (name) {
            case 'Stacey': {
                avatar = rosaAvatar;
                break;
            }
            case 'Rosa': {
                avatar = darylAvatar;
                break;
            }
            case 'Daryl': {
                avatar = martinAvatar;
                break;
            }
            case 'Martin': {
                avatar = eveAvatar;
                break;
            }
            case 'Eve': {
                avatar = staceyAvatar;
                break;
            }
            default: {
                avatar = rosaAvatar;
                break;
            }
        }

        return avatar;
    }

    const getSecondAvatar = (name: string) => {
        let avatar;

        if (!props.showDifferentLabel) {
            avatar = staceyAvatar;
        }
        else {
            switch (name) {
                case 'Stacey': {
                    avatar = darylAvatar;
                    break;
                }
                case 'Rosa': {
                    avatar = martinAvatar;
                    break;
                }
                case 'Daryl': {
                    avatar = eveAvatar;
                    break;
                }
                case 'Martin': {
                    avatar = staceyAvatar;
                    break;
                }
                case 'Eve': {
                    avatar = rosaAvatar;
                    break;
                }
                default: {
                    avatar = darylAvatar;
                    break;
                }
            }
        }

        return avatar;
    }

    return (
        <Stack
            id={props.id}
            data-tour="profile-switcher"
            tokens={{ childrenGap: 5 }}
            horizontalAlign='center'
            verticalAlign='center'
            style={{ ...props.style }}
            onClick={() => {
                if (!props.showDifferentLabel) {
                    history.push("/signup")
                }
                else {
                    props.onClick && props.onClick();
                }
            }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                marginRight: !isCanonical(props.currentUser) ? '2rem' : ''
            }}>
                <img alt='avatar' src={!props.showDifferentLabel ? createProfileLabel : !isCanonical(props.currentUser) ?
                    rosaAvatar : getAvatar(props.currentUser)} style={{ width: '6rem', height: '6rem', cursor: 'pointer', zIndex: 1 }} />
                <img alt='avatar2' src={getSecondAvatar(props.currentUser)} style={{ width: '4.5rem', height: '4.5rem', cursor: 'pointer', position: 'absolute', paddingLeft: '6rem' }} />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#FFFFFF',
                borderRadius: '15px',
                marginTop: '1rem'
            }}>
                <p style={{
                    fontFamily: 'brandon-grotesque',
                    fontSize: '1.2rem',
                    lineHeight: '1.9rem',
                    letterSpacing: '0.0571429rem',
                    color: '#293861',
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    cursor: 'pointer'
                }}>{!props.showDifferentLabel ? 'Create your profile' : isCanonical(props.currentUser) ? 'Click here to choose another profile' : 'Click here to choose a profile and play'}</p>
            </div>
        </Stack>
    );
};

export default SelectBubble;