/* eslint-disable @typescript-eslint/no-array-constructor */
import { generateColorShadeSet } from "../../utils/colorGenerators";
import {
  chartOrganizedMonthNames,
  currMonth,
  elevenMonthsAgo,
  currJan,
  getMonths,
} from "../../utils/dateTools";
import moment from "moment";

export const allCategoryData = {
  name: "All Expenses",
  color: "#EFA400",
  assets: require("../../assets/images/scoop/scoop_all.svg"),
};
// export const baseCategories = [
//     {
//         id: 'EF7',
//         title: 'Housing',
//         color: '#4ADEDE',
//         icon: require('../../assets/images/scoop/scoop_house.svg'),
//     },
//     {
//         id: 'EF19',
//         title: 'Travel & Entertainment',
//         color: '#FAA7B7',
//         icon: require('../../assets/images/scoop/scoop_travel.svg'),
//     },
//     {
//         id: 'EF6',
//         title: 'Groceries',
//         color: '#5C6EEE',
//         icon: require('../../assets/images/scoop/scoop_groceries.svg'),
//     },
//     {
//         id: 'EF15',
//         title: 'Education',
//         color: '#7754C6',
//         icon: require('../../assets/images/scoop/scoop_edu.svg'),
//     },
//     {
//         id: 'EF4',
//         title: 'Utilities',
//         color: '#FFC192',
//         icon: require('../../assets/images/scoop/scoop_utils.svg'),
//     },
//     {
//         id: 'EF3',
//         title: 'Healthcare',
//         color: '#56C1EF',
//         icon: require('../../assets/images/scoop/scoop_health.svg'),
//     },
//     {
//         id: 'EF17',
//         title: 'Retirement',
//         color: '#FAB612',
//         icon: require('../../assets/images/scoop/scoop_retire.svg'),
//     },
//     {
//         id: 'EF10',
//         title: 'Insurance',
//         color: '#0BA879',
//         icon: require('../../assets/images/scoop/scoop_insurance.svg'),
//     },
//     {
//         id: 'EF5',
//         title: 'Shopping',
//         color: '#F7813B',
//         icon: require('../../assets/images/scoop/scoop_shop.svg'),
//     },
//     {
//         id: 'EF11',
//         title: 'Office',
//         color: '#9DD449',
//         icon: require('../../assets/images/scoop/scoop_office.svg'),
//     },
//     {
//         id: 'EF14',
//         title: 'Subscriptions',
//         color: '#F9372C',
//         icon: require('../../assets/images/scoop/scoop_subs.svg'),
//     },
//     {
//         id: 'EF9',
//         title: 'Other',
//         color: '#EDF017',
//         icon: require('../../assets/images/scoop/scoop_other.svg'),
//     },
// ];

// function transpose(a: any) {

//     // Calculate the width and height of the Array
//     var w = a.length || 0;
//     var h = a[0] instanceof Array ? a[0].length : 0;

//     // In case it is a zero matrix, no transpose routine needed.
//     if(h === 0 || w === 0) { return []; }

//     /**
//      * @var {Number} i Counter
//      * @var {Number} j Counter
//      * @var {Array} t Transposed data is stored in this array.
//      */
//     var i, j, t = Array();

//     // Loop through every item in the outer array (height)
//     for(i=0; i<h; i++) {

//       // Insert a new row (array)
//       t[i] = [];

//       // Loop through every item per item in outer array (width)
//       for(j=0; j<w; j++) {

//         // Save transposed data.
//         t[i][j] = a[j][i];
//       }
//     }

//     return t;
// }

const {
  fullMonths: fullMonthsM,
  chartMonths,
  dates,
} = getMonths(elevenMonthsAgo, currMonth);
const { fullMonths: fullMonthsY, chartMonths: chartMonthsYrly } = getMonths(
  currJan,
  currMonth
);

// V1
// const efCategoryIds = categories.map((e: any) => e.id);
// console.log(efCategoryIds)
// const baseData = transactionsData.filter((e: any) => e.dashboards.find((db: any) => db === 'expense') && e.categoryType === "EXPENSE");
// console.log(baseData)
// const dataByEFId = efCategoryIds.map((id: any) => baseData && baseData.filter((e: any) => id === e.customCategory.categoryId));
// console.log(dataByEFId);

export const useExpensesData = (
  categories: any,
  transactionsData: any,
  toYearly: boolean,
  monthByIdx: number,
  category: string
) => {
  const fullMonths = toYearly ? fullMonthsY : fullMonthsM;
  const months = toYearly ? chartOrganizedMonthNames : chartMonths;
  // Used to map the bar colors in barColors and updateChart
  const monthSet = toYearly ? chartMonthsYrly : months;

  //V2
  const baseData = transactionsData.filter(
    (e: any) =>
      e.dashboards.find((db: any) => db === "expense") &&
      e.categoryType === "EXPENSE"
  );
  const transDataByCategory = categories?.map(
    (x: any) =>
      baseData && baseData.filter((e: any) => x.id === e.customCategory.id)
  );

  // //V1
  // const efCategoryIds = baseCategories.map((e: any) => e.id);
  // const baseData = transactionsData.filter((e: any) => e.dashboards.find((db: any) => db === 'expense'));
  // const dataByEFId = efCategoryIds.map((id: any) => baseData && baseData.filter((e: any) => id === e.efCategory.categoryId));
  // console.log("V1")
  // console.log(dataByEFId)

  const filterDataPerType =
    transDataByCategory &&
    transDataByCategory.map(
      (id: any) =>
        id &&
        id.map((x: any) => {
          return {
            id: x.id,
            date: x.date,
            amount: x.amount.amount,
            category: x.category,
            label: x.description.original,
            subCatId: x.customCategory?.subCategories?.[0]?.id,
          };
        })
    );
  const amountsBySortedMonths =
    filterDataPerType &&
    filterDataPerType.map(
      (type: any) =>
        type &&
        type
          .reduce((a: any, c: any) => {
            const m = parseInt(c.date.split("-")[1]) - 1;
            a[m] ? a[m].data.push(c) : (a[m] = { date: c.date, data: [c] });
            return a;
          }, [])
          .sort((a: any, b: any) => b.date.localeCompare(a.date))
    );

  const x =
    amountsBySortedMonths &&
    amountsBySortedMonths.map((type: any) =>
      dates.map((e: any) => {
        const currM = parseInt(e.split("-")[1]) - 1;
        const targetObj =
          type &&
          type.find(
            (x: any) => x && parseInt(x.date.split("-")[1]) - 1 === currM
          );
        if (targetObj) {
          return { date: targetObj.date, data: targetObj.data };
        } else {
          return { date: e, data: [{ amount: 0 }] };
        }
      })
    );

  const y =
    x &&
    x.map((type: any) =>
      type.filter(
        (e: any) =>
          e.date >= currJan.format("YYYY-MM-01") &&
          e.date < moment().set("date", 1).add(1, "months").format("YYYY-MM-01")
      )
    );

  const sortedData = toYearly ? y : x;

  // Amount totals for 12 months per EFId type
  const amountsSummedByType =
    sortedData &&
    sortedData.map((type: any) => {
      return (
        type &&
        type.map((e: any) =>
          e.data.map((e: any) => e.amount).reduce((a: any, c: any) => (a += c))
        )
      );
    });

  // Map transactions by EF ID and contained in month sets
  const transactionsByEFId =
    sortedData &&
    sortedData.map(
      (category: any) =>
        category &&
        category.map((e: any) =>
          e.data.reduce((a: any, c: any) => {
            const found = a.find((e: any) => e.category === c.category);
            const value = {
              id: c.id,
              label: c.label,
              amount: c.amount,
              date: c.date,
              subCatId: c.subCatId,
            };
            if (!found) {
              a.push({ category: c.category, data: [value] });
            } else {
              found.data.push(value);
            }
            return a;
          }, [])
        )
    );

  // Find totals for different yodlee categories and map to category data sets
  const transactionData =
    transactionsByEFId &&
    transactionsByEFId.map(
      (category: any) =>
        category &&
        category.map(
          (month: any) =>
            month &&
            month.map((e: any) => {
              const total = e.data.reduce(
                (a: any, c: any) => (a += c.amount),
                0
              );
              return {
                id: e.id,
                category: e.category,
                total: total,
                data: e.data,
              };
            })
        )
    );

  // Generate Doughnut Data
  const getMonthlyAmountsForIndex = (idx: number) => {
    const idxType = categories?.findIndex((e: any) => e.name === category);
    if (category === allCategoryData.name || toYearly) {
      return {
        values:
          amountsSummedByType &&
          amountsSummedByType
            .map(
              (type: any) => type && type.filter((_: any, i: any) => idx === i)
            )
            .flat(),
        colors: categories?.map((e: any) => e.color),
        labels: categories?.map((e: any) => e.name),
      };
    } else {
      const values = transactionData[idxType][monthByIdx]
        ? transactionData[idxType][monthByIdx].map((e: any) => e.total)
        : [];
      return {
        values: values,
        colors: generateColorShadeSet(
          values.length,
          categories?.[idxType].color
        ),
        labels: transactionData[idxType][monthByIdx]
          ? transactionData[idxType][monthByIdx].map((e: any) => e.category)
          : [],
      };
    }
  };

  // Generate Bar Data & Colors
  const amountsTotalsMonthly = () => {
    if (category === allCategoryData.name) {
      return (
        amountsSummedByType &&
        amountsSummedByType.reduce((r: any, a: any) => {
          a &&
            a.forEach((b: any, i: any) => {
              r[i] = (r[i] || 0) + b;
            });
          return r;
        }, [])
      );
    } else {
      const idxType = categories?.findIndex((e: any) => e.name === category);
      return amountsSummedByType && amountsSummedByType[idxType];
    }
  };

  const yrToDateByType =
    amountsSummedByType &&
    amountsSummedByType.map(
      (type: any) => type && type.reduce((a: any, c: any) => (a += c), 0)
    );
  // const idxType = baseCategories.findIndex((e: any) => e.title === category);
  // const getEFTypeDataByMonths = transactionData[idxType] && transactionData[idxType].map((month: any) => month.map((e: any) => e.total));
  // const x = getEFTypeDataByMonths && getEFTypeDataByMonths.map((e: any) => e);
  // console.log(getEFTypeDataByMonths)
  // console.log(Array.from(getEFTypeDataByMonths, item => typeof item === 'undefined' ? 0 : item))
  // const yrToDateByEFType = transpose(x || []).map((e: any) => e.reduce((a: any, c: any) => a += (c ? c : 0), 0))
  // console.log(yrToDateByEFType);
  const yrToDateDoughtnutData =
    category === allCategoryData.name ? yrToDateByType : yrToDateByType; // Change to yrToDateByEFType after completed testing
  const totalYrToDate =
    amountsTotalsMonthly() &&
    amountsTotalsMonthly().reduce((a: any, c: any) => (a += c), 0);

  return {
    fullMonths,
    chartMonths,
    chartMonthsYrly,
    categories,
    baseData,
    months,
    monthSet,
    transDataByCategory,
    filterDataPerType,
    amountsBySortedMonths,
    amountsSummedByType,
    transactionsByEFId,
    transactionData,
    getMonthlyAmountsForIndex,
    amountsTotalsMonthly,
    yrToDateByType,
    yrToDateDoughtnutData,
    totalYrToDate,
  };
};
