import React from 'react';
import Lock from '../../assets/icons/lock.png';

const SecurityFooter: React.FC<{}> = props => {

    return (
        <footer style={footerStyle}>
            <img src={Lock} alt="lock" style={imgStyle} /> 
            <div style={textStyle}>
                <p>
                    Your security is our priority. We always have your security in mind.
                </p>
                <p>You are protected by SSL and 256 bit encryption.
                    <span style={yellowText}>Learn more</span>
                </p>
                
            </div>
        </footer>
    );
};

export default SecurityFooter;

const footerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.6rem',
    fontWeight: 300,
    textAlign: 'center' as 'center',
}

const imgStyle = {
    color: '#000'
}

const textStyle = {
    color: '#9EA4B3',
    marginLeft: '1rem'
}

const yellowText = {
    color: '#FEB81E',
    fontWeight: 500
}
