import React from 'react';
import { Stack, FontIcon,  } from 'office-ui-fabric-react/lib/';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import classes from './DeleteLifeEventButton.module.css';

interface IProps {
    showButton: boolean,
    onClick: () => void,
};

const DeleteLifeEventButton: React.FC<IProps> = props => {

    return (
        <>
            {props.showButton && (
                <Stack className={classes.container} verticalAlign='center' horizontalAlign='center'>
                    <Stack
                        className={classes.innerContainer}
                        horizontalAlign='center'
                        verticalAlign='center'
                        onClick={props.onClick} 
                    >
                        <Stack horizontal horizontalAlign='center' verticalAlign='center' tokens={{childrenGap: '1.3rem'}}>
                            <FontIcon
                                iconName='GreyTrash'
                                title='Remove event from My Life'
                            />
                            <EFLabel style={{color: '#9EA4B3', fontSize: '1.4rem', cursor: 'pointer'}}>
                                Remove event from my playlist
                            </EFLabel>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    )
};

export default DeleteLifeEventButton;
