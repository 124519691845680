import LifeEvent from "./LifeEvent";

class User {

    private name: string;
    private email: string;
    private trackingId: string;
    private phone: string;
    private gender: string;
    private anonymized: boolean;
    private actingAs: string;
    private access_token?: string;
    private refresh_token?: string;
    private age?: number;
    private status?: string;
    private address?: string;
    private zipCode?: string;
    private grossAnnualSalary?: number
    private nudgetSavingGoalsInfo?: Object;
    private connectedAccounts?: Array<Object>;
    private lifeEvents?: Array<LifeEvent>;
    private plans?: Array <Object>;
    private dependents?: Array<Object>
    private financialProfile?: Object;
    private masterSwitch?: Object;
    private masterSettings?: Object;
    private tourGuide?: Object;
    private hints?: Object;
    private profileCompletionStatus?: number;
    private scoop?: Object;
    private kakeibo?: Array<Object>;
    private avatar?: string;
    private picture?: Object;
    private protect?: Object;

    constructor (name: string, email: string, trackingId: string, phone: string, gender: string, 
        anonymized: boolean, actingAs: string, access_token?: string, 
        refresh_token?: string, age?: number, status?: string, 
        address?: string, zipCode?: string, grossAnnualSalary?: number, 
        nudgetSavingGoals?: Object, connectedAccounts?: Array<Object>, 
        lifeEvents?: Array<LifeEvent>, plans?: Array <Object>, dependents?: Array<Object>, 
        financialProfile?: Object, masterSwitch?: Object, 
        masterSettings?: Object, tourGuide?: Object, hints?: Object,
        profileCompletionStatus?: number, scoop?: Object, 
        kakeibo?: Array<Object>, avatar?: string, picture?: Object,
        protect?: Object) {

        this.name = name;
        this.email = email;
        this.trackingId = trackingId;
        this.phone = phone;
        this.gender = gender;
        this.anonymized = anonymized;
        this.actingAs = actingAs;
        this.access_token = access_token;
        this.refresh_token = refresh_token;
        this.age = age;
        this.status = status;
        this.address = address;
        this.zipCode = zipCode;
        this.grossAnnualSalary = grossAnnualSalary;
        this.nudgetSavingGoalsInfo = nudgetSavingGoals;
        this.connectedAccounts = connectedAccounts;
        this.lifeEvents = lifeEvents;
        this.plans = plans;
        this.dependents = dependents;
        this.financialProfile = financialProfile;
        this.masterSwitch = masterSwitch;
        this.masterSettings = masterSettings;
        this.tourGuide = tourGuide;
        this.hints = hints;
        this.profileCompletionStatus = profileCompletionStatus;
        this.scoop = scoop;
        this.kakeibo = kakeibo;
        this.avatar = avatar;
        this.picture = picture;
        this.protect = protect;
    }

    public setLifeEvents (lifeEvents: Array<LifeEvent>) {
        this.lifeEvents = lifeEvents
    }

    public setConnectedAccounts (connectedAccounts: Array<Object>) {
        this.connectedAccounts = connectedAccounts;
    }

};

export default User;