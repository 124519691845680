export const categoryMapping = [
    {
        "categoryId": "EF1",
        "source": "EQUALFUTURE",
        "categoryName": "Auto & Commuting",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 2,
                "name": "Automotive Expenses"
            },
            {
                "id": 8,
                "name": "Gasoline/Fuel"
            }
        ]
    },
    {
        "categoryId": "EF2",
        "source": "EQUALFUTURE",
        "categoryName": "Giving",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 3,
                "name": "Charitable Giving"
            },
            {
                "id": 9,
                "name": "Gifts"
            }
        ]
    },
    {
        "categoryId": "EF3",
        "source": "EQUALFUTURE",
        "categoryName": "Healthcare",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 11,
                "name": "Healthcare/Medical"
            }
        ]
    },
    {
        "categoryId": "EF4",
        "source": "EQUALFUTURE",
        "categoryName": "Utilities",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 39,
                "name": "Utilities"
            },
            {
                "id": 15,
                "name": "Cable/Satellite Services"
            }
        ]
    },
    {
        "categoryId": "EF5",
        "source": "EQUALFUTURE",
        "categoryName": "Shopping",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 43,
                "name": "Electronics"
            },
            {
                "id": 44,
                "name": "General Merchandise"
            },
            {
                "id": 5,
                "name": "Clothing/Shoes"
            }
        ]
    },
    {
        "categoryId": "EF6",
        "source": "EQUALFUTURE",
        "categoryName": "Groceries",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 10,
                "name": "Groceries"
            }
        ]
    },
    {
        "categoryId": "EF7",
        "source": "EQUALFUTURE",
        "categoryName": "Housing",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 13,
                "name": "Home Improvement"
            },
            {
                "id": 12,
                "name": "Home Maintenance"
            },
            {
                "id": 18,
                "name": "Mortgages"
            },
            {
                "id": 21,
                "name": "Rent"
            },
            {
                "id": 38,
                "name": "Telephone Services"
            }
        ]
    },
    {
        "categoryId": "EF8",
        "source": "EQUALFUTURE",
        "categoryName": "Loans",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 17,
                "name": "Loans"
            }
        ]
    },
    {
        "categoryId": "EF9",
        "source": "EQUALFUTURE",
        "categoryName": "Other",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 42,
                "name": "Pets/Pet Care"
            },
            {
                "id": 33,
                "name": "Checks"
            },
            {
                "id": 35,
                "name": "Other Bills"
            },
            {
                "id": 19,
                "name": "Other Expenses"
            },
            {
                "id": 112,
                "name": "Wages Paid"
            },
            {
                "id": 24,
                "name": "Service Charges & Fees"
            }
        ]
    },
    {
        "categoryId": "EF10",
        "source": "EQUALFUTURE",
        "categoryName": "Insurance",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 14,
                "name": "Insurance"
            }
        ]
    },
    {
        "categoryId": "EF11",
        "source": "EQUALFUTURE",
        "categoryName": "Office",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 100,
                "name": "Advertising"
            },
            {
                "id": 102,
                "name": "Business Miscellaneous"
            },
            {
                "id": 110,
                "name": "Office Maintenance"
            },
            {
                "id": 45,
                "name": "Office Supplies"
            },
            {
                "id": 16,
                "name": "Online Services"
            },
            {
                "id": 104,
                "name": "Postage and Shipping"
            },
            {
                "id": 106,
                "name": "Printing"
            }
        ]
    },
    {
        "categoryId": "EF12",
        "source": "EQUALFUTURE",
        "categoryName": "ATM",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 25,
                "name": "ATM/Cash Withdrawals"
            }
        ]
    },
    {
        "categoryId": "EF13",
        "source": "EQUALFUTURE",
        "categoryName": "Childcare",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 4,
                "name": "Child/Dependent Expenses"
            }
        ]
    },
    {
        "categoryId": "EF14",
        "source": "EQUALFUTURE",
        "categoryName": "Subscriptions",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 108,
                "name": "Dues and Subscriptions"
            }
        ]
    },
    {
        "categoryId": "EF15",
        "source": "EQUALFUTURE",
        "categoryName": "Education",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 6,
                "name": "Education"
            }
        ]
    },
    {
        "categoryId": "EF16",
        "source": "EQUALFUTURE",
        "categoryName": "Personal Care",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 20,
                "name": "Personal Care"
            }
        ]
    },
    {
        "categoryId": "EF17",
        "source": "EQUALFUTURE",
        "categoryName": "Retirement",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 41,
                "name": "Retirement Contributions"
            }
        ]
    },
    {
        "categoryId": "EF18",
        "source": "EQUALFUTURE",
        "categoryName": "Taxes",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 37,
                "name": "Taxes"
            }
        ]
    },
    {
        "categoryId": "EF19",
        "source": "EQUALFUTURE",
        "categoryName": "Travel & Entertainment",
        "type": "EXPENSE",
        "detailCategory": [
            {
                "id": 7,
                "name": "Entertainment"
            },
            {
                "id": 34,
                "name": "Hobbies"
            },
            {
                "id": 22,
                "name": "Restaurants/Dining"
            },
            {
                "id": 23,
                "name": "Travel"
            }
        ]
    },
    {
          "categoryId": "EF20",
          "source": "EQUALFUTURE",
          "categoryName": "Salary",
          "type": "INCOME",
          "detailCategory": [
              {
                  "id": 29,
                  "name": "Paychecks/Salary"
              }
          ]
      }
]