import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import classes from './LimitButtons.module.css';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';

interface IProps {
    currLimit: number,
    affordabilityMinLimit: number,
    affordabilityMaxLimit: number,
};

const LimitButtons: React.FC<IProps> = props => {
    const buttonDetails = [
        {
            id: 0,
            color: '#CAFFB7',
            focusColor: '#ACFF8D',
            limitLabel: `< ${props.affordabilityMinLimit}%`,
            title: 'Easy',
            isCurr: props.currLimit === 0,
        },
        {
            id: 1,
            color: '#FFFAB7',
            focusColor: '#FFF88D',
            limitLabel: `${props.affordabilityMinLimit}% - ${props.affordabilityMaxLimit}%`,
            title: 'OK',
            isCurr: props.currLimit === 1,
        },
        {
            id: 2,
            color: '#FFE3D1',
            focusColor: '#FFB98D',
            limitLabel: `> ${props.affordabilityMaxLimit}%`,
            title: 'Tight',
            isCurr: props.currLimit === 2,
        }
    ];

    const limitButtons = buttonDetails.map((btn: any) => (
        <Stack key={btn.id} horizontalAlign='center' tokens={{childrenGap: '1rem'}}>
            <Stack 
                verticalAlign='center' 
                horizontalAlign='center' 
                style={{backgroundColor: btn.isCurr ? btn.focusColor : btn.color}}
                className={`${classes.btn} ${btn.isCurr && classes.btnFocus}`}
            >
                <EFLabel style={{fontSize: '1.2rem'}}>{btn.limitLabel}</EFLabel>
            </Stack>
            <EFLabel style={{fontSize: '1.6rem', fontWeight: btn.isCurr ? 'bold' : 'normal'}}>{btn.title}</EFLabel>
        </Stack>
    ));

    return (
        <Stack 
            className={classes.container} 
            horizontal 
            horizontalAlign='center' 
            tokens={{childrenGap: '1rem'}}
        >
            {limitButtons}
        </Stack>
    )
};

export default LimitButtons;
