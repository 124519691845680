import React from 'react';
import { Stack, FontIcon } from 'office-ui-fabric-react';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../utils/Masking';
import icon from  "../../../assets/icons/bullet.svg";

interface IProps {
    style?: object,
    sectionGap?: number,
    data: { key: number; title: string; value: number; color: string; }[],
    prefix?: string,
    suffix?: string,
    includeThousandsSeparator?: boolean,
    thousandsSeparatorSymbol?: string,
    allowDecimal?: boolean,
    decimalSymbol?: string,
    decimalLimit?: number,
    allowNegative?: boolean,
    allowLeadingZeroes?: boolean,
    isBulleted?: boolean,
};

const BreakdownList: React.FC<IProps> = props => {
    const numberMask = createNumberMask({
        ...BreakdownList.defaultProps,
        ...props,
    });

    const list = props.data.map(
        (section: { key: number, title: string; value: number; color: string; }) =>
            <Stack key={section.key} horizontal horizontalAlign='space-between'>
                {section.title ? (
                    <Stack horizontal verticalAlign='center' horizontalAlign='center'>
                        {props.isBulleted ? (
                            <div
                                style={{
                                    mask: `url(${icon}) no-repeat center`, 
                                    WebkitMask: `url(${icon}) no-repeat center`, 
                                    backgroundColor: section.color,
                                    maskSize: 'contain',
                                    width: '1.5rem',
                                    height: '1.5rem',
                                    margin: '0 .2rem .2rem ',
                                }} 
                            />
                        ) : null}
                        <EFLabel style={{color: section.color, fontSize: '1.6rem'}}>{section.title}</EFLabel>
                    </Stack>
                ) : null}
                <div style={{minWidth: '20%'}}>
                <EFLabel style={{fontWeight: 'normal' as 'normal', fontSize: '1.6rem', textAlign: 'left' as 'left'}}>
                    {maskValue(Math.round(section.value), numberMask)}
                </EFLabel>
                </div>
            </Stack>
    );

    return (
        <Stack style={props.style} tokens={{childrenGap: props.sectionGap || 15}}>{list}</Stack>
    )
};

BreakdownList.defaultProps = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 2,
    allowNegative: true,
    allowLeadingZeroes: false,
    isBulleted: true,
};

export default BreakdownList;
