import React from 'react';

import classes from './CompleteProfilePage.module.css';

interface SuggestedLifeEventProps {
    lifeEvent: any;
}

interface Props {
    lifeEvents: any;
}

const Separator: React.FC<{}> = () => {
    return (
        <div className={classes.lifeEventSeparator}>
            <div>.</div>
        </div>
    )
}

const SuggestedLifeEvent: React.FC<SuggestedLifeEventProps> = ({ lifeEvent }) => {
    return (
        <div className={classes.lifeEvent}>
            <img src={lifeEvent.eventIcon[0].url} alt="lifeEventLogo" className={classes.lifeEventImg} />
            <div className={classes.lifeEventInfo}>
                <div className={classes.lifeEventTitle}>{lifeEvent.name.toUpperCase()}</div>
                <div className={classes.lifeEventSubtitle}>{lifeEvent.subtitle}</div>
                {lifeEvent.detail && <div className={classes.lifeEventSubtitle}>{lifeEvent.detail}</div>}
            </div>
        </div>
    )
}

const OnboardingLifeEvents: React.FC<Props> = ({ lifeEvents }) => {
    return (
        <div className={classes.lifeEventContainer}>
            <div className={classes.lifeEventLabel}>your game plan</div>
            <div className={classes.lifeEventCard}>
                {lifeEvents.map((lifeEvent: any, i: number) => {
                    if (i < lifeEvents.length - 1) {
                        return (
                            <React.Fragment key={i}>
                                <SuggestedLifeEvent lifeEvent={lifeEvent} />
                                <Separator />
                            </React.Fragment>
                        )
                    } else {
                        return <SuggestedLifeEvent key={i} lifeEvent={lifeEvent} />
                    }
                })}
            </div>
        </div>
    )
}

export default OnboardingLifeEvents;
