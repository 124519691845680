/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as calculatorActions from "../../store/actions/Calculator";
import { getCustomData } from "../../utils/LifeEventTools/customDataGenerator";
const { LOAN_SIMPLE, LOAN_COMPARE, LOAN_BALANCE } = require("ef-calculators");

interface loanCompare {
  originalDebtAmount: any;
  monthlyPaymentBase: number;
  monthlyPaymentSimulation: any;
  totalMonthsBase: number;
  totalMonthsSimulation: number;
  totalPaidBase: number;
  totalPaidSimulation: number;
  totalInterestsPaidBase: number;
  totalInterestsPaidSimulation: number;
  interestsSaved: number;
  schedule: {
    month: number;
    currentProjection: number;
    reducedProjection: number;
  }[];
}

export interface ILoanBalanceProps {
  LOAN_SIMPLE: any;
  simpleMonthly: number;
  originalDebtAmount: number;
  setoriginalDebtAmount: any;
  payOffTermInMonths: number;
  setpayOffTermInMonths: any;
  annualInterest: number;
  setannualInterest: any;
  desiredMonthlyPayment: number;
  setdesiredMonthlyPayment: any;
  desiredInterestRate: number;
  setdesiredInterestRate: any;
  startSimulationInPeriod: number;
  setstartSimulationInPeriod: any;
  groupMonthsCompare: number;
  setgroupMonthsCompare: any;
  startPrepaymentInPeriod: number;
  setStartPrepaymentInPeriod: any;
  prepayment: number;
  setPrePayment: any;
  apr: number;
  setApr: any;
  groupMonths: number;
  setgroupMonths: any;

  totalMonthsNoPrepayment: number;
  settotalMonthsNoPrepayment: any;
  totalMonthsWithPrepayment: number;
  settotalMonthsWithPrepayment: any;
  totalPaidNoPrepayment: number;
  settotalPaidNoPrepayment: any;
  totalPaidWithPrepayment: number;
  settotalPaidWithPrepayment: any;
  totalInterestsPaidNoPrepayment: number;
  settotalInterestsPaidNoPrepayment: any;
  totalInterestsPaidWithPrepayment: number;
  settotalInterestsPaidWithPrepayment: any;
  interestsSaved: number;
  setinterestsSaved: any;
  amortizationScheduleLoan: any[];
  setamortizationScheduleLoan: any;
  monthlyPaymentBase: number;
  totalPaidBase: number;
  totalPaidSimulation: number;
  totalMonthsSimulation: number;
  totalMonthsBase: number;
  monthlyPaymentSimulation: number;
  totalInterestsPaidBase: number;
  totalInterestsPaidSimulation: number;
  interestsSavedLoan: number;
  amortizationSchedule: any[];

  loanAmount: number;
  setLoanAmount: any;
  annualIncome: number;
  setAnnualIncome: any;
  interest: number;
  setInterest: any;
  termsInYears: number;
  setTermsInYears: any;
  monthlyPayment: number;
  setMonthlyPayment: any;
  affordabilityMinLimit: number;
  setAffordabilityMinLimit: any;
  affordabilityMaxLimit: number;
  setAffordabilityMaxLimit: any;

  affordabilityLoanAmount: number;
  affordabilityYears: number;
  affordabilityInterests: number;
  affordabilityMonthlyPayment: number;
  affordabilityMonthlyIncome: number;
  affordabiliryAnnualIncome: number;
  affordabilityRatio: number;
  affordabilityZone: number;
  affordabilityCriteria: string;
  affordabilityTotalToPay: number;
  affordabilityRecommendedSimu: {
    years: number;
    monthlyPayment: number;
    totalToPay: number;
    monthlyRatio: number;
    affordabilityZone: number;
    affordabilityCriteria: string;
  };
}

export const useStudentLoanBalance = (uniqueEventID?: string) => {
  const dispatch = useDispatch();
  const loggedUserLifeEventData = useSelector((state: any) => {
    if (state.session.user) {
      return state.session.user.lifeEvents.find(
        (le: any) => le.id === uniqueEventID
      );
    } else {
      return null;
    }
  });

  const defaultData = getCustomData("Student Debt");
  
  const [skip, setSkip] = useState(true);

  //simple loan inputs
  // const [simpleAmount, setSimpleAmount] = useState(50000);
  // const [term, setTerm] = useState(5);
  // const [simpleInterest, setSimpleInterest] = useState(5);
  //simple loan outputs
  const [simpleMonthly, setsimpleMonthly] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.currentPayment
      : defaultData.currentPayment
  );

  /* #region State Inputs */
  const [originalDebtAmount, setoriginalDebtAmount] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.studentDebtBalance
      : defaultData.studentDebtBalance
  );
  const [payOffTermInMonths, setpayOffTermInMonths] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.payOffTime
      : defaultData.payOffTime
  ); //changed from 60 months to 5 years
  const [annualInterest, setannualInterest] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.interestRate
      : defaultData.interestRate
  );
  const [desiredMonthlyPayment, setdesiredMonthlyPayment] = useState(
    simpleMonthly
  );
  const [desiredInterestRate, setdesiredInterestRate] = useState(
    annualInterest
  );
  const [startSimulationInPeriod, setstartSimulationInPeriod] = useState(0);
  const [groupMonthsCompare, setgroupMonthsCompare] = useState(0);
  /* #endregion */
  /* #region State Outputs */
  const [monthlyPaymentBase, setmonthlyPaymentBase] = useState(0);
  const [monthlyPaymentSimulation, setmonthlyPaymentSimulation] = useState(0);
  const [totalMonthsSimulation, settotalMonthsSimulation] = useState(0);
  const [totalMonthsBase, settotalMonthsBase] = useState(0);
  const [totalPaidBase, settotalPaidBase] = useState(0);
  const [totalPaidSimulation, settotalPaidSimulation] = useState(0);
  const [totalInterestsPaidBase, settotalInterestsPaidBase] = useState(0);
  const [
    totalInterestsPaidSimulation,
    settotalInterestsPaidSimulation,
  ] = useState(0);
  const [interestsSavedLoan, setinterestsSavedLoan] = useState(0);
  const [amortizationSchedule, setamortizationSchedule] = useState(Array());
  /* #endregion */

  const [startPrepaymentInPeriod, setStartPrepaymentInPeriod] = useState(0);
  const [prepayment, setPrePayment] = useState(0);
  const [apr, setApr] = useState(0);
  const [groupMonths, setgroupMonths] = useState(0);

  const [totalMonthsNoPrepayment, settotalMonthsNoPrepayment] = useState(0);
  const [totalMonthsWithPrepayment, settotalMonthsWithPrepayment] = useState(0);
  const [totalPaidNoPrepayment, settotalPaidNoPrepayment] = useState(0);
  const [totalPaidWithPrepayment, settotalPaidWithPrepayment] = useState(0);
  const [
    totalInterestsPaidNoPrepayment,
    settotalInterestsPaidNoPrepayment,
  ] = useState(0);
  const [
    totalInterestsPaidWithPrepayment,
    settotalInterestsPaidWithPrepayment,
  ] = useState(0);
  const [interestsSaved, setinterestsSaved] = useState(0);
  const [amortizationScheduleLoan, setamortizationScheduleLoan] = useState(
    Array()
  );

  useEffect(() => {
    const loanSimpleCalculate = () => {
      const s = LOAN_SIMPLE(
        originalDebtAmount,
        payOffTermInMonths / 12,
        annualInterest
      );
      setsimpleMonthly(Math.round(s.monthlyPayment));
      if (
        !loggedUserLifeEventData ||
        Object.keys(loggedUserLifeEventData.customData).length === 0
      ) {
        setdesiredMonthlyPayment(Math.round(s.monthlyPayment));
      }
    };
    loanSimpleCalculate();
  }, []);

  useEffect(() => {
    const loanSimpleCalculate = () => {
      const s = LOAN_SIMPLE(
        originalDebtAmount,
        payOffTermInMonths / 12,
        annualInterest
      );
      console.log("B");
      setsimpleMonthly(Math.round(s.monthlyPayment));
      setdesiredMonthlyPayment(Math.round(s.monthlyPayment));
    };
    if (skip) {
      setSkip(false);
    }
    else {
      loanSimpleCalculate();
    }
  }, [originalDebtAmount, payOffTermInMonths, annualInterest]);

  useEffect(() => {
    dispatch(
      calculatorActions.studentDebtChangeState({
        interestRate: annualInterest,
        payOffTime: payOffTermInMonths,
        currentPayment: desiredMonthlyPayment,
        studentDebtBalance: originalDebtAmount,
      })
    );
  }, [
    annualInterest,
    payOffTermInMonths,
    originalDebtAmount,
    desiredMonthlyPayment,
    desiredInterestRate,
  ]);

  useEffect(() => {
    const loanCompareCalculate = () => {
      const c: loanCompare | any = LOAN_COMPARE(
        originalDebtAmount,
        payOffTermInMonths,
        annualInterest,
        desiredMonthlyPayment,
        desiredInterestRate,
        startSimulationInPeriod,
        groupMonthsCompare
      );
      if (typeof c != "undefined") {
        if (
          typeof c.originalDebtAmount == "number" &&
          typeof c.monthlyPaymentSimulation == "number"
        ) {
          setoriginalDebtAmount(c.originalDebtAmount);
          setmonthlyPaymentBase(c.monthlyPaymentBase);
          setmonthlyPaymentSimulation(c.monthlyPaymentSimulation);
          settotalMonthsSimulation(c.totalMonthsSimulation);
          settotalMonthsBase(c.totalMonthsBase);
          settotalPaidBase(c.totalPaidBase);
          settotalPaidSimulation(c.totalPaidSimulation);
          settotalInterestsPaidBase(c.totalInterestsPaidBase);
          settotalInterestsPaidSimulation(c.totalInterestsPaidSimulation);
          setinterestsSavedLoan(c.interestsSaved);
          setamortizationSchedule(c.amortizationSchedule);
        }
      }
    };
    loanCompareCalculate();
  }, [
    annualInterest,
    desiredInterestRate,
    desiredMonthlyPayment,
    groupMonthsCompare,
    originalDebtAmount,
    payOffTermInMonths,
    startSimulationInPeriod,
  ]);

  useEffect(() => {
    const loanBalanceCalculate = () => {
      const b = LOAN_BALANCE(
        originalDebtAmount,
        desiredMonthlyPayment,
        apr,
        prepayment,
        startPrepaymentInPeriod,
        groupMonths
      );

      settotalMonthsNoPrepayment(b.totalMonthsNoPrepayment);
      settotalMonthsWithPrepayment(b.totalMonthsWithPrepayment);
      settotalPaidNoPrepayment(b.totalPaidNoPrepayment);
      settotalPaidWithPrepayment(b.totalPaidWithPrepayment);
      settotalInterestsPaidNoPrepayment(b.totalInterestsPaidNoPrepayment);
      settotalInterestsPaidWithPrepayment(b.totalInterestsPaidWithPrepayment);
      setinterestsSaved(b.interestsSaved);
      setamortizationScheduleLoan(b.amortizationSchedule);
    };
    loanBalanceCalculate();
  }, [
    originalDebtAmount,
    desiredMonthlyPayment,
    apr,
    prepayment,
    startPrepaymentInPeriod,
    groupMonths,
  ]);

  return {
    LOAN_SIMPLE,
    simpleMonthly,
    originalDebtAmount,
    setoriginalDebtAmount,
    payOffTermInMonths,
    setpayOffTermInMonths,
    annualInterest,
    setannualInterest,
    desiredMonthlyPayment,
    setdesiredMonthlyPayment,
    desiredInterestRate,
    setdesiredInterestRate,
    startSimulationInPeriod,
    setstartSimulationInPeriod,
    groupMonthsCompare,
    setgroupMonthsCompare,
    startPrepaymentInPeriod,
    setStartPrepaymentInPeriod,
    prepayment,
    setPrePayment,
    apr,
    setApr,
    groupMonths,
    setgroupMonths,

    totalMonthsNoPrepayment,
    settotalMonthsNoPrepayment,
    totalMonthsWithPrepayment,
    settotalMonthsWithPrepayment,
    totalPaidNoPrepayment,
    settotalPaidNoPrepayment,
    totalPaidWithPrepayment,
    settotalPaidWithPrepayment,
    totalInterestsPaidNoPrepayment,
    settotalInterestsPaidNoPrepayment,
    totalInterestsPaidWithPrepayment,
    settotalInterestsPaidWithPrepayment,
    interestsSaved,
    setinterestsSaved,
    amortizationScheduleLoan,
    setamortizationScheduleLoan,

    monthlyPaymentBase,
    totalMonthsSimulation,
    totalMonthsBase,
    totalPaidBase,
    totalPaidSimulation,
    monthlyPaymentSimulation,
    totalInterestsPaidBase,
    totalInterestsPaidSimulation,
    interestsSavedLoan,
    amortizationSchedule,
  };
};
