/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as calculatorActions from '../../store/actions/Calculator';
import { getCustomData } from '../../utils/LifeEventTools/customDataGenerator';
const { CAR_BUY_LEASE} = require('ef-calculators');


export const useCarBuyLease = (uniqueEventID?: string) => {
    const dispatch = useDispatch();
    const loggedUserLifeEvent = useSelector((state: any) => {
            if (state.session.user) {
                return state.session.user.lifeEvents.find((le: any) => le.id === uniqueEventID)
            }
            else {
                return null;
            }
        }
    );
    const defaultData = getCustomData('Buy a Car');


    /* #region Inputs */
    const [vehiclePrice, setvehiclePrice] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.vehiclePrice : defaultData.vehiclePrice);
    const [downPymntBuy, setdownPymntBuy] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.buyDownPayment : defaultData.buyDownPayment);
    const [downPymntLease, setdownPymntLease] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.leaseDownPayment : defaultData.leaseDownPayment);
    const [salesTax, setsalesTax] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.saleTaxes : defaultData.saleTaxes);
    const [registrationFees, setregistrationFees] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.registrationFeesTaxes : defaultData.registrationFeesTaxes);
    const [monthlyMaintenance, setmonthlyMaintenance] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.maintenance : defaultData.maintenance);
    const [monthlyInsurance, setmonthlyInsurance] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.insurance : defaultData.insurance);
    const [advertisedMthlyLeaseExpense, setadvertisedMthlyLeaseExpense] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.monthlyPayment : defaultData.monthlyPayment);
    const [simulationLength, setsimulationLength] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.simulationLength : defaultData.simulationLength);
    const [loanTerm, setloanTerm] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.payOffTime : defaultData.payOffTime);
    const [loanInterestRate, setloanInterestRate] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.interestRate : defaultData.interestRate);
    const [investmentInterestRate, setinvestmentInterestRate] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.investmentRate : defaultData.investmentRate);
    const [selectedOption, setSelectedOption] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0 ? loggedUserLifeEvent.customData.selectedOption : 'BUY');
    /* #endregion */

    /* #region Outputs */
    const [vehiclePriceOpt, setvehiclePriceOpt] = useState('');
    const [simulationLengthOpt, setdsimulationLengthOpt] = useState('');
    const [loanTermOpt, setloanTermOpt] = useState('');
    const [totalCostBuy, settotalCostBuy] = useState(0);
    const [totalCostLease, settotalCostLease] = useState(0);
    const [residualValue, setresidualValue] = useState(0);
    const [averageMthlyLoanPmt, setaverageMthlyLoanPmt] = useState(0);
    const [firstYearBuyDownPayment, setfirstYearBuyDownPayment] = useState(0);
    const [firstYearBuySalesTax, setfirstYearBuySalesTax] = useState(0);
    const [firstYearBuyLoanPayments, setfirstYearBuyLoanPayments] = useState(0);
    const [firstYearLeaseDownPayment, setfirstYearLeaseDownPayment] = useState(0);
    const [firstYearLeaseSalesTax, setfirstYearLeaseSalesTax] = useState(0);
    const [firstYearLeasePayments, setfirstYearLeasePayments] = useState(0);
    const [firstYearRegistrationFees, setfirstYearRegistrationFees] = useState(0);
    const [firstYearMaintenanceAmount, setfirstYearMaintenanceAmount] = useState(0);
    const [firstYearInsuranceAmount, setfirstYearInsuranceAmount] = useState(0);
    const [investmentSimulation, setinvestmentSimulation] = useState(Array());
    const [paymentsOverTime, setpaymentsOverTime] = useState(Array());
    /* #endregion */

    useEffect(() => {
        const carbuy = () => {
            const b = CAR_BUY_LEASE(
              vehiclePrice,
              downPymntBuy,
              downPymntLease,
              salesTax,
              registrationFees,
              monthlyMaintenance,
              monthlyInsurance,
              advertisedMthlyLeaseExpense,
              simulationLength,
              loanTerm,
              loanInterestRate,
              investmentInterestRate
            );
        
            setvehiclePriceOpt(b.vehiclePrice);
            setdsimulationLengthOpt(b.simulationLength);
            setloanTermOpt(b.loanTerm);
            settotalCostBuy(b.totalCostBuy);
            settotalCostLease(b.totalCostLease);
            setresidualValue(b.residualValue);
            setaverageMthlyLoanPmt(b.averageMthlyLoanPmt);
            setfirstYearBuyDownPayment(b.firstYearBuyDownPayment);
            setfirstYearBuySalesTax(b.firstYearBuySalesTax);
            setfirstYearBuyLoanPayments(b.firstYearBuyLoanPayments);
            setfirstYearLeaseDownPayment(b.firstYearLeaseDownPayment);
            setfirstYearLeaseSalesTax(b.firstYearLeaseSalesTax);
            setfirstYearLeasePayments(b.firstYearLeasePayments);
            setfirstYearRegistrationFees(b.firstYearRegistrationFees);
            setfirstYearMaintenanceAmount(b.firstYearMaintenanceAmount);
            setfirstYearInsuranceAmount(b.firstYearInsuranceAmount);
            setpaymentsOverTime(b.paymentsOverTime);
            setinvestmentSimulation(b.investmentSimulation);
          };
          carbuy();
          dispatch(calculatorActions.buyCarChangeState({
            payOffTime: loanTerm,
            investmentRate: investmentInterestRate,
            buyDownPayment: downPymntBuy,
            saleTaxes: salesTax,
            tradeInValue: 0,
            simulationLength: simulationLength,
            leaseDownPayment: downPymntLease,
            interestRate: loanInterestRate,
            monthlyPayment: advertisedMthlyLeaseExpense,
            registrationFeesTaxes: registrationFees,
            maintenance: monthlyMaintenance,
            insurance: monthlyInsurance,
            selectedOption: selectedOption,
            vehiclePrice: vehiclePrice,
          }));
    }, [
        /* #region Dependencies */
        dispatch,
        advertisedMthlyLeaseExpense, 
        downPymntBuy, 
        downPymntLease, 
        investmentInterestRate, 
        loanInterestRate, 
        loanTerm, 
        monthlyInsurance, 
        monthlyMaintenance, 
        registrationFees, 
        salesTax, 
        simulationLength, 
        vehiclePrice,
        selectedOption,
        /* #endregion */
    ]);

    return {
        selectedOption, setSelectedOption,
        vehiclePrice, setvehiclePrice,
        downPymntBuy, setdownPymntBuy,
        downPymntLease, setdownPymntLease,
        salesTax, setsalesTax,
        registrationFees, setregistrationFees,
        monthlyMaintenance, setmonthlyMaintenance,
        monthlyInsurance, setmonthlyInsurance,
        advertisedMthlyLeaseExpense, setadvertisedMthlyLeaseExpense,
        simulationLength, setsimulationLength,
        loanTerm, setloanTerm,
        loanInterestRate, setloanInterestRate,
        investmentInterestRate, setinvestmentInterestRate,
        vehiclePriceOpt,
        simulationLengthOpt,
        loanTermOpt,
        totalCostBuy,
        totalCostLease,
        residualValue,
        averageMthlyLoanPmt,
        firstYearBuyDownPayment,
        firstYearBuySalesTax,
        firstYearBuyLoanPayments,
        firstYearLeaseDownPayment,
        firstYearLeaseSalesTax,
        firstYearLeasePayments,
        firstYearRegistrationFees,
        firstYearMaintenanceAmount,
        firstYearInsuranceAmount,
        investmentSimulation,
        paymentsOverTime
    };
};
