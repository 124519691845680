import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import EFButton from '../../Atomic/EFButton/EFButton';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import classes from './AddItem.module.css';

interface IProps {
    text?: string,
    onClick: () => void,
};

const AddItem: React.FC<IProps> = props => {
    return (
        <Stack 
            className={classes.container} 
            horizontalAlign='center' 
            tokens={{childrenGap: 5}}
        >
            <EFButton text='+' style={button} onClick={props.onClick} />
            <EFLabel style={text}>{props.text}</EFLabel>
        </Stack>
    );
};

const button = {
    fontSize: '3rem', 
    minWidth: '4.6rem', 
    height: '4.6rem', 
    width: '4.6rem', 
    borderRadius: '4.6rem'
};

const text = {
    color: '#FEB81E', 
    fontSize: 8
};

export default AddItem;
