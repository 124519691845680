import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../Atomic/EFLabel/EFLabel';

import comingSoonBanner from '../../assets/images/lifeEvents/comingSoon.svg';
import animations from '../../utils/animations.module.css';

interface IProps {
    id: string,
    title?: string,
    img: string,
    imgStyle?: object,
    txtStyle?: object,
    onClick: (le: any) => void,
    disableAnimation?: boolean,
    isComingSoon?: boolean,
};

const LifeEvent: React.FC<IProps> = props => {

    return (
        <Stack 
            className={props.disableAnimation ? '' : animations.lifeEventExpand}
            onClick={!props.isComingSoon ? props.onClick : null} 
            id={props.id} 
            horizontalAlign='center' 
            verticalAlign='center' 
            tokens={{childrenGap: 10}}
            style={{cursor: 'pointer' as 'pointer'}}
        >
            <div style={{ backgroundImage: `url(${props.img})`, backgroundSize: 'cover', width: '9rem', height: '9rem', ...props.imgStyle }}>
                {props.isComingSoon && <img alt='coming-soon' src={comingSoonBanner} style={{ objectPosition: '-.2rem 0',width: '7.9rem', height: '3.6rem', marginTop: '.5rem' }} />}
            </div>
            <EFLabel style={{width: '8rem', textAlign: 'center' as 'center', ...props.txtStyle}}>{props.title}</EFLabel>
        </Stack>
    )
};

LifeEvent.defaultProps = {
    disableAnimation: false,
};

export default LifeEvent;
