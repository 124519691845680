import { getJobInfo } from "./CareerPlanning";

export const SAVE_USER = "SAVE_USER";
export const DELETE_USER = "DELETE_USER";
export const SAVE_PROFILES = "SAVE_PROFILES";

export const saveUser = (user: any) => {
    return async (dispatch: any, getState: any) => {
        try {
            dispatch({
                type: SAVE_USER,
                user: user
            })
            const careerGoalLE = user.lifeEvents.find(
                (le: any) => le.externalId === "6ceIi2ikmezTJtxIDEuRsg" && le.status !== "NEW"
              );
              if (careerGoalLE) {
                const getOnetCode = careerGoalLE.customData.selectedCareerOnetCode;
                dispatch(getJobInfo(getOnetCode));
              }
        }
        catch (error) {
            console.error(error);
        }
    }
};

export const deleteUser = () => {
    return async (dispatch: any, getState: any) => {
        try {
            dispatch({
                type: DELETE_USER
            })
        }
        catch (error) {
            console.error(error)
        }
    }
};

export const saveProfiles = (profiles: any) => {
    return async (dispatch: any, getState: any) => {
        try {
            dispatch({
                type: SAVE_PROFILES,
                profiles: profiles
            })
        }
        catch (error) {
            console.error(error);
        }
    }
}