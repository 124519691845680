import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../../Atomic/EFLabel/EFLabel';

interface IProps {
    toggleFocus: boolean,
    onClick: () => void,
    type: 'setup' | 'simu' | 'levelup',
};

const ConfigButton: React.FC<IProps> = props => {
    const offFocusImg = `on_${props.type}.svg`;
    const onFocusImg = `off_${props.type}.svg`;
    const iconCentering = props.type !== 'simu' ? 'center' as 'center' : undefined;
    const currImg = require(`../../../assets/images/${!props.toggleFocus ? onFocusImg : offFocusImg}`);
    const btnStyle = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer' as 'pointer', 
        height: '5.5rem', 
        width: '5.5rem', 
        borderRadius: '2.8rem',
        backgroundColor: props.toggleFocus ? '#feb81e' : 'transparent', 
        border: props.toggleFocus ? '1px solid #feb81e' : '1px solid #9ea4b3', 
        boxShadow: props.toggleFocus ? '2px 2px 10px rgba(0, 0, 0, 0.25)' : 'none',
    };
    const textStyle = {
        fontSize: '1.2rem',
        fontWeight: 'bold' as 'bold',
        color: props.toggleFocus ? '#293861' : '#9EA4B3',
    };
    const getLabel = () => {
        const type = props.type;
        switch (type) {
            case 'setup': return 'Setup'; // These need to be refactored so we can call them using contentful's microcopies
            case 'simu': return 'Simu';
            case 'levelup': return 'Level Up';
        };
    };


    return (
        <Stack horizontalAlign='center' tokens={{childrenGap: 5}}>
            <Stack verticalAlign={iconCentering} style={btnStyle} onClick={props.onClick}>
                <img style={{height: '3.5rem', width: '3.5rem'}} alt='config-icon' src={currImg} />
            </Stack>
        <EFLabel style={textStyle}>{getLabel()}</EFLabel>
        </Stack>
        
    )
};

export default ConfigButton;
