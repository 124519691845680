export const GET_QUESTIONS = "GET QUESTIONS";
export const SEND_ANSWERS = "SEND_ANSWERS";
export const GET_JOB_INFO = "GET_JOB_INFO";
export const REMOVE_JOB_INFO = "REMOVE_JOB_INFO";

const CARRER_PLANNING_ENDPOINT = process.env.REACT_APP_CARRER_PLANNING_ENDPOINT;

export const getQuestions = () => {
    return async (dispatch: any, getState: any) => {
        try {
            const response = await fetch(CARRER_PLANNING_ENDPOINT);
            if (response.ok) {
                const data = await response.json();
                dispatch({
                    type: GET_QUESTIONS,
                    data: data
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
};

export const sendAnswers = () => {
    return null;
};

export const getJobInfo = (jobCode: number) => {
    return async (dispatch: any, getState: any) => {
        try {
            let endpointURL = '';

            if (process.env.NODE_ENV === 'development') {
                endpointURL = 'https://career-assessment-api-dev.equalfuture.com/occupations/' + jobCode + '/US/?training=true&videos=true&wages=true&projectedEmployment=true&skills=true&knowledge=true&trainingPrograms=true';
            }

            if (process.env.NODE_ENV === 'production') {
                endpointURL = 'https://career-assessment-api-dev.equalfuture.com/occupations/' + jobCode + '/US/?training=true&videos=true&wages=true&projectedEmployment=true&skills=true&knowledge=true&trainingPrograms=true';
            }

            const response = await fetch(endpointURL);

            if (response.ok) {
                const payload = await response.json();

                let minWage = null;
                let midWage = null;
                let maxWage = null;
                let isBright = false;

                if (payload.OccupationDetail[0].Wages.NationalWagesList[1].RateType === "Annual") {
                    minWage = payload.OccupationDetail[0].Wages.NationalWagesList[1].Pct10;
                    midWage = payload.OccupationDetail[0].Wages.NationalWagesList[1].Median;
                    maxWage = payload.OccupationDetail[0].Wages.NationalWagesList[1].Pct90;
                } else {
                    minWage = payload.OccupationDetail[0].Wages.NationalWagesList[0].Pct10;
                    midWage = payload.OccupationDetail[0].Wages.NationalWagesList[0].Median;
                    maxWage = payload.OccupationDetail[0].Wages.NationalWagesList[0].Pct90;
                }

                if (payload.OccupationDetail[0].BrightOutlook === "Bright") {
                    isBright = true;
                }

                dispatch({
                    type: GET_JOB_INFO,
                    info: {
                        name: payload.OccupationDetail[0].OnetTitle,
                        percentChange: payload.OccupationDetail[0].Projections.Projections[0].PerCentChange,
                        anualProjected: payload.OccupationDetail[0].Projections.Projections[0].ProjectedAnnualJobOpening,
                        minWage: minWage,
                        midWage: midWage,
                        maxWage: maxWage,
                        isBright: isBright,
                        payload: payload
                    }
                })
            }
        }
        catch (error) {
            console.error(error)
        }
    }
}

export const removeCurrentJob = () => {
    return async (dispatch: any, getState: any) => {
        try {
           dispatch({
               type: REMOVE_JOB_INFO,
               data: null,
               info: null,
           }) 
        }
        catch (error) {
            console.error(error)
        }
    }
}