import React from 'react';
import EFToggle from '../../../../components/Atomic/EFToggle';

import classesParent from '../SettingsOptions/SettingsOptions.module.css';
import classes from './PrivacyOptions.module.css';

const PrivacyOptions: React.FC<{}> = props => {
    return (
        <>
            <div className={classes.card}>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>Use Passcode</div>
                    <EFToggle isChecked={false} onClick={() => {
                        alert("Coming soon!");
                    }} />
                </div>
                <div className={classes.divider} />
                <div className={classes.changeBtn} onClick={() => {
                    alert("Coming soon!");
                }}>Change Passcode</div>
            </div>
        </>
    )
};

export default PrivacyOptions;
