import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EFTextField from "../../components/Atomic/EFTextField/EFTextField";
import EFButton from "../../components/Atomic/EFButton/EFButton";
import EFHeader from "../../components/UI/EFHeader/EFHeader";
import { Label } from "office-ui-fabric-react";
import { Card } from "@uifabric/react-cards";
import { useResponsive } from "../../hooks/useResponsive";
import { sessionService } from "redux-react-session";
import VerificationInput from "../../components/UI/VerificationInput/VerificationInput";
import * as actions from "../../store/actions/Users";
import * as screenActions from "../../store/actions/Screens";

import classes from "./LoginPage.module.css";
import TagManager from 'react-gtm-module';
interface Props {
  expired?: boolean;
}

const LoginPage: React.FC<Props> = ({ expired = false }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { screenIsAtMost } = useResponsive();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [confirmedPasswordError, setConfirmedPasswordError] = useState("");
  const invalidCredentials = useSelector(
    (state: any) => state.screens.login_invalidCredentials
  );

  const [forgotPasswordFlow, setForgotPasswordFlow] = useState(false);

  const [emailErr, setEmailErr] = useState("");
  const [pwdErr, setPwdErr] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const realUser = useSelector((state: any) => state.data.realUser);

  // Google analytics set up for page
  let tagManagerArgs;
  if (!expired) {
    tagManagerArgs = {
      dataLayer: {
        userId: realUser?.trackingId,   
        page: 'Login'
      },
      dataLayerName: 'PageDataLayer'
    }
  } else {
    tagManagerArgs = {
      dataLayer: {
        userId: realUser?.trackingId,  
        page: 'Expired Login'
      },
      dataLayerName: 'PageDataLayer'
    }
  }
  TagManager.dataLayer(tagManagerArgs)

  const signUpErrorState = useSelector(
    (state: any) => state.screens.signUp_signUpError
  );
  const loggedUserfromSignupEmail = useSelector((state: any) =>
    state.session.user ? state.session.user.email : ""
  );
  const showCode = useSelector(
    (state: any) => state.screens.login_showAuthCodePage
  );
  const showResetCode = useSelector(
    (state: any) => state.user.showResetPasswordCode
  );
  const redirect = useSelector(
    (state: any) => state.screens.main_redirectToDashboard
  );
  const resetPasswordToken = useSelector(
    (state: any) => state.user.resetPasswordToken
  );

  const validatePwd = (regex: string, pwd: string) => {
    return pwd.match(regex) ? true : false;
  };

  const validateCompletePassword = (event: any) => {
    if (invalidCredentials) {
      dispatch(screenActions.loginCrendentialStatus(false));
    }
    setPwdErr("");
    const password = (event.target as HTMLTextAreaElement).value;
    const isValid =
      validatePwd("[a-z]", password) &&
      validatePwd("[A-Z]", password) &&
      validatePwd('[!@#$%^&*(),.?":{}|<>-]', password) &&
      validatePwd("[0-9]", password);
    if (!isValid) {
      setPwdErr("Your password format is not valid");
    }
    setPwd(password);
  };

  const validateEmail = (event: any) => {
    if (invalidCredentials) {
      dispatch(screenActions.loginCrendentialStatus(false));
    }
    setEmailErr("");
    setEmail((event.target as HTMLTextAreaElement).value);
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(
      (event.target as HTMLTextAreaElement).value.toLowerCase()
    );
    if (!isValid) {
      setEmailErr("Your email format is invalid");
    }
  };

  const onSubmitHandler = () => {
    if (forgotPasswordFlow) {
      if (resetPasswordToken) {
        setForgotPasswordFlow(false);
        dispatch(
          actions.setNewPassword(email, resetPasswordToken, pwd, history)
        );
      } else {
        dispatch(actions.resetPassword(email, history));
      }
    } else {
      dispatch(actions.login(email, pwd, history));
    }
  };

  const validateConfirmedPassword = (event: any) => {
    setConfirmedPasswordError("");
    const confirmed = (event.target as HTMLTextAreaElement).value;
    if (confirmed !== pwd) {
      setConfirmedPasswordError("The passwords don't match");
    }
    setConfirmedPassword(confirmed);
  };

  useEffect(() => {
    expired &&
      sessionService
        .deleteSession()
        .then((response) => {
          sessionService
            .deleteUser()
            .then((response) => {})
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
  }, [expired, history]);

  useEffect(() => {
    let enabled = false;
    if (!forgotPasswordFlow) {
      enabled =
        emailErr === "" &&
        pwdErr === "" &&
        email !== "" &&
        pwd !== "" &&
        !invalidCredentials;
    } else {
      if (resetPasswordToken) {
        enabled =
          pwd !== "" &&
          confirmedPassword !== "" &&
          pwdErr === "" &&
          confirmedPasswordError === "";
      } else {
        enabled = emailErr === "" && email !== "";
      }
    }
    setIsButtonEnabled(enabled);
  }, [
    emailErr,
    pwdErr,
    email,
    pwd,
    confirmedPassword,
    confirmedPasswordError,
    invalidCredentials,
    forgotPasswordFlow,
    resetPasswordToken,
  ]);

  useEffect(() => {
    if (redirect) {
      history.push("/");
    }
  }, [redirect, history]);

  return (
    <div
      className={`${classes.container} ${
        screenIsAtMost("tabPort") ? classes.containerMobile : null
      }`}
    >
      <EFHeader onNavToMain customStyle={{ display: "block" }} />
      <div className={classes.form}>
        <p className={classes.title}>
          {forgotPasswordFlow ? "Reset Your Password" : "Sign In"}
        </p>
        <p className={classes.subtitle}>Protected by bank-level security</p>
        {expired && (
          <Card horizontal className={classes.signAndProfileCard}>
            <div className={classes.cardSection}>
              <div className={classes.smallCardTitle}>
                Your session has expired.
              </div>
              <div
                className={classes.smallCardTitle}
                style={{ marginTop: "0rem", marginBottom: "2rem" }}
              >
                Please sign in again to continue.
              </div>
            </div>
          </Card>
        )}

        {!showCode && !showResetCode && (
          <Card horizontal className={classes.card}>
            <Card.Section
              styles={{
                root: {
                  alignContent: "center",
                  width: "100%",
                  paddingLeft: "15%",
                },
              }}
            >
              {!resetPasswordToken && (
                <Card.Section>
                  <Label className={classes.fieldLabel}>Email Address</Label>
                  <EFTextField
                    id="emailaddress"
                    type="text"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => validateEmail(e)}
                    errorMessage={emailErr}
                  />
                </Card.Section>
              )}

              {(!forgotPasswordFlow || resetPasswordToken) && (
                <Card.Section>
                  <Label className={classes.fieldLabel}>
                    {forgotPasswordFlow ? "New Password" : "Password"}
                  </Label>
                  <EFTextField
                    id="password"
                    type="password"
                    placeholder={
                      forgotPasswordFlow ? "New Password" : "Password"
                    }
                    value={pwd}
                    onChange={(e) => validateCompletePassword(e)}
                    errorMessage={pwdErr}
                  />
                </Card.Section>
              )}

              {invalidCredentials && (
                <p
                  className={classes.errorMessage}
                  style={{ marginTop: "2rem" }}
                >
                  Invalid Credentials
                </p>
              )}

              {forgotPasswordFlow && resetPasswordToken && (
                <Card.Section>
                  <Label className={classes.fieldLabel}>
                    Confirm New Password
                  </Label>
                  <EFTextField
                    type="password"
                    placeholder="Confirm New Password"
                    value={confirmedPassword}
                    onChange={(e) => validateConfirmedPassword(e)}
                    errorMessage={confirmedPasswordError}
                  />
                </Card.Section>
              )}
            </Card.Section>
          </Card>
        )}

        {showCode || showResetCode ? (
          <VerificationInput
            showButton
            showVerificationInput={showCode || showResetCode}
            signUpError={screenActions.signUpError}
            signUpErrorState={signUpErrorState}
            forgotPasswordFlow={forgotPasswordFlow}
            verifyResetPasswordCode={actions.verifyResetPasswordCode}
            verifyAuthCode={actions.verifyAuthCode}
            email={email}
            loggedUserfromSignupEmail={loggedUserfromSignupEmail}
            isButtonEnabled={isButtonEnabled}
            onSubmitHandler={onSubmitHandler}
          />
        ) : (
          <>
            <br />
            <EFButton
              id="login-reset"
              isDisabled={isButtonEnabled ? false : true}
              text={forgotPasswordFlow ? "RESET PASSWORD" : "SIGN IN"}
              onClick={onSubmitHandler}
            />
          </>
        )}

        {!forgotPasswordFlow && !showCode && (
          <strong
            style={{ cursor: "pointer" }}
            className={classes.forgot}
            onClick={() => {
              setForgotPasswordFlow(true);
            }}
          >
            Forgot your Password?
          </strong>
        )}

        {((forgotPasswordFlow && showResetCode) || showCode) && (
          <div style={{ margin: "2rem 0", fontSize: "1.6rem" }}>
            <p>
              Didn't get the code?{" "}
              <strong
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showCode
                    ? dispatch(actions.resend2FA(email))
                    : dispatch(actions.resetPassword(email, history));
                }}
              >
                Resend code
              </strong>
            </p>
          </div>
        )}

        <p className={classes.noAccount}>
          Don't have an account?{" "}
          <strong
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push("/signup");
            }}
          >
            Create an Account
          </strong>
        </p>
      </div>
      <div className={classes.rightColumn}></div>
    </div>
  );
};

export default LoginPage;
