import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { clearCanonicalProfile } from '../../../store/actions/Users';
import Modal from 'react-modal';
import WhiteButton from '../../CareerPlanning/EfButtonWhite';
import OrangeButton from '../../CareerPlanning/EfButtonOrange';
import close from '../../../assets/images/close.svg';

import classes from './Transfer.module.css';
import confirmImg from '../../../assets/images/confirmImg.svg';

interface Props {
    isOpen: boolean;
    handleModal: () => void;
    navigateScoop: () => void;
}

const NavigateModal: React.FC<Props> = ({ isOpen, handleModal, navigateScoop }) => {

    const dispatch = useDispatch();
    let history = useHistory();

    const switchToMainProfile = () => {
        dispatch(clearCanonicalProfile(history))
        history.replace('/connect');
        navigateScoop();
        handleModal();
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleModal}
            style={{
            content: { ...modalCardStyle.content, width: "100%" },
            overlay: modalCardStyle.overlay,
            }}
        >
            <div className={classes.modalContainer}>
                <div className={classes.modalCard} onClick={(e) => e.stopPropagation()}>
                    <img alt="close" src={close} className={classes.modalExit} onClick={handleModal} />
                    <img alt="transferIcon" src={confirmImg} className={classes.modalImg} />
                    <div className={classes.modalText}>Do you wish to continue on this profile or switch to your own?</div>
                    <div className={classes.modalCta}>
                        <WhiteButton click={handleModal} text="STAY HERE" width="15rem" size="1.3rem" />
                        <OrangeButton click={switchToMainProfile} text="GO TO MY PROFILE" width="15rem" size="1.3rem" />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const modalCardStyle = {
    content: {
      justifyContent: "center" as "center",
      alignItems: "middle" as "middle",
      borderRadius: 10,
      borderStyle: "none" as "none",
      overflow: "visible",
      height: "fit-content",
      background: "none" as "none",
      left: "auto",
      right: "auto",
      padding: 0,
      marginTop: "2rem",
    },
    overlay: {
      display: "flex" as "flex",
      justifyContent: "center" as "center",
      overflowY: "auto" as "auto",
      background: 'rgba(248, 250, 255, 0.50)',
      backdropFilter: 'blur(40px)',
      WebkitBackdropFilter: 'blur(40px)',
      zIndex: 10
    },
};

export default NavigateModal;
