/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import NavButton from '../../UI/NavButton';
import CashflowCarousel from '../../SmartCashflow2/CashflowCarousel/CashflowCarousel';
import moment from 'moment';
import CashflowChart from '../../Charts/CashflowChart2/CashflowChart';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import { mapLEToDetailsV2 } from '../../../utils/LifeEventTools/Mappers';
import { useResponsive } from '../../../hooks/useResponsive';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { getUpdatedUserFullProfile } from '../../../store/actions/Users';

import classes from './SmartCashflowView.module.css';
import rightArrow from '../../../assets/images/cashArrowR.svg';
import leftArrow from '../../../assets/images/cashArrowL.svg';
import EFSpinner from '../../Atomic/EFSpinner/EFSpinner';
import interstitialImage from '../../../assets/images/interstitcashflow3.svg';
import red from '../../../assets/images/red_toggle.svg';
import green from '../../../assets/images/green_toggle.svg';
import Insight from '../Insight/Insight';

import socketIOClient from 'socket.io-client';
import { useLazyQuery, useMutation } from 'react-apollo';
import { RESET_CASHFLOW_2_5, SAVE_CASHFLOW_2_5, UPDATE_CASHFLOW_2_5 } from '../../../hooks/Apollo/mutations';
import { GET_CATEGORIES } from '../../../hooks/Apollo/queries';
import CFAccordion from '../../SmartCashflow3/CFAccordian/CFAccordion';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import CFSetupCapsule from '../CFSetupCapsule/CFSetupCapsule';
import LEInsights from '../LEInsights/LEInsights';

const SIMU_CHANNEL = 'cashflow.simulation';
const SETUP_CHANNEL = 'cashflow.setup';
const SETUP_SAVED_CHANNEL = 'cashflow.setup.saved';
const CASHFLOW_SOCKET_URL = process.env.REACT_APP_CASHFLOW_SOCKET_URL;

const CashCardList = ({ cats, items, lifeEvents, onChangeSubCategory, onClickCCFocus, currCat, onSaveSpikes, saveEntry, onEnable, carouselCardCount }) =>
    <CashflowCarousel style={{ margin: '5rem 0' }} slidesCount={carouselCardCount}>
        {items && items?.map((event) => (
            <div>
                <CFAccordion
                    key={event.id}
                    name={event.name}
                    value={Math.round(event.subCategories.map(s => s.amount).reduce((a, b) => a + b, 0))}
                    fixedValue={event.fixedValue}
                    saveEntry={saveEntry.bind({}, event)}
                    asset={event.assets}
                    onClickFocus={() => onClickCCFocus(event)}
                    focused={event.id.toString() === currCat.id}
                    spikes={event.spikes}
                    onSaveSpikes={onSaveSpikes.bind({}, event)}
                    lifeEvents={lifeEvents}
                    enabled={event.enabled}
                    onEnable={onEnable.bind({}, event)}
                    onboarded={event.onboarded}
                >
                    <div className={!event.onboarded && classes.subs}>
                        {event?.lifeEventMapping?.length ? (
                            <LEInsights
                                cashCard={event}
                                lifeEvents={lifeEvents.filter(le => event?.lifeEventMapping?.map(e => e.lifeEventId).includes(le.id))}
                                onClick={(subId, val, valObj?) => onChangeSubCategory(event, event.subCategories.find(s => s.id === subId), val, valObj)}
                            />
                        ) : <></>}
                        <div>
                            {[...new Set(event.subCategories.map(s => s.parent.name))].map((p: any) => {
                                const assetInfo = cats?.find(cat => cat.name === p)
                                return (
                                    <CFSetupCapsule
                                        key={p} 
                                        initOpen
                                        value={event.subCategories.filter(x => x.parent.name === p).map(s => s.amount).reduce((a, b) => a + b, 0)}
                                        title={p}
                                        icon={assetInfo?.assets}
                                        color={assetInfo?.backColor}
                                        disableOpenAnim
                                    >
                                        {event.subCategories.filter(x => x.parent.name === p).map((s, i) => (
                                            <>
                                                <CurrencyInput
                                                    key={s.id}
                                                    id={s.id}
                                                    placeholder={s.category}
                                                    color={assetInfo?.color}
                                                    value={s.amount === 0 ? null : Math.round(s.amount)}
                                                    value2={Math.round(s.aggregatedAmount)}
                                                    onChange={onChangeSubCategory.bind({}, event, s)}
                                                    onClickX={onChangeSubCategory.bind({}, event, s)}
                                                    leSource={s.source !== "LIFE_EVENT" ? undefined :
                                                        lifeEvents.find(le => le.id === s.values?.find(v => v?.type === "LIFE_EVENT")?.source)?.icon
                                                    }
                                                />
                                                {event.subCategories.filter(x => x.parent.name === p).length -1 === i && <div style={{ marginBottom: '0.5rem' }} />}
                                            </>
                                        ))}
                                    </CFSetupCapsule>
                                )
                            })}
                        </div>
                    </div>
                </CFAccordion>
            </div>
        ))}
    </CashflowCarousel>


const SmartCashflowView: React.FC<IProps> = props => {
    const [enable, setEnable] = useState('Balance Automatically');
    useEffect(() => {
        setTimeout(() => {
            setEnable('Balance Automatically');
        }, 1000);
    }, [enable]);

    const loadedProfileMS = useSelector((state: any) => state?.session?.user);
    const [currCat, setCurrCat] = useState({ id: '', color: '', name: ''});

    const [seriesMthly, setSeriesMthly] = useState<any>([]);
    const [seriesYrly, setSeriesYrly] = useState<any>([]);
    const [series, setSeries] = useState<any>([]);

    const [responseMthly, setResponseMthly] = useState<any>([]);
    const [responseYrly, setResponseYrly] = useState<any>([]);
    const [response, setResponse] = useState<any>([]);

    const [inputData, setInputData] = useState<any>([]);
    // Skip input data once socket io is mounted and data consumed once
    const [skipInput, setSkipInput] = useState(false);

    const socketRef = useRef(null);
    const { screenIsAtLeast, screenIsAtMost, howManyCards } = useResponsive();
    const timeKeys = [
        screenIsAtLeast('tabPort') ? 'MONTHLY' : 'M', 
        screenIsAtLeast('tabPort') ? 'YEARLY' : 'Y', 
        screenIsAtLeast('tabPort') ? 'BIG PICTURE' : 'BP',
    ];
    const [currentView, setCurrentView] = useState(timeKeys[2]);
    const [resetCashflow] = useMutation(RESET_CASHFLOW_2_5, { context: { clientName: "cashFlow" }, onError(error) { console.error(error) } });

    const [updateEntry2] = useMutation(UPDATE_CASHFLOW_2_5, { context: { clientName: "cashFlow" }, onError(error) { console.error(error) } });
    const [saveEntry2] = useMutation(SAVE_CASHFLOW_2_5, { context: { clientName: "cashFlow" }, onError(error) { console.error(error) } });

    const [cats, setCats] = useState([])
    const [tmpCat] = useLazyQuery(GET_CATEGORIES , {
        onCompleted: data => {
            setCats(data?.customCategoriesV2)
        },
        fetchPolicy: "no-cache",
        onError(error) { console.error(error) }
    })

    useEffect(() => {
        tmpCat();
        resetCashflow();
        setSkipInput(false);
        setCurrCat({ id: '', color: '', name: ''});
        setSeries([]);
        changeViewHandler(timeKeys[2]);
    }, [loadedProfileMS.access_token])

    // Socket Handler
    useEffect(() => {
        socketRef.current = socketIOClient(CASHFLOW_SOCKET_URL, {
            query: { 
                token: loadedProfileMS.access_token, 
                'x-equalfuture-device-id': 'Postman',
                'version': 2.5, 
            },
            transports: ['websocket'],
            forceNew: true,
            reconnection: true,
        });

        socketRef.current.on(SETUP_CHANNEL, (data) => {
            const resp = JSON.parse(data);
            if (skipInput === false) {
                let setup = resp.setup;
                if (!Array.isArray(setup)) {
                    setup = resp.setup.setup
                }
                setup.map(g => g.fixedValue = g.subCategories.map(s => s.amount).reduce((a, b) => a + b, 0));
                setInputData(setup);
                setSkipInput(true);
            }
        })

        // socketRef.current.on(SETUP_SAVED_CHANNEL, (data) => {
        //     const resp = JSON.parse(data);
        //     console.log("SAVED CHANNEL", resp);
        //     dispatch(getUpdatedUserFullProfile(history));
        // })

        socketRef.current.on(SIMU_CHANNEL, (data) => {
            if (data) {
                const resp = JSON.parse(data);
                const global = set(resp.global);
                setResponseMthly(global);
                setResponseYrly(dataByTimeSeries(global));
                currentView === timeKeys[0] ? setResponse(global) : setResponse(dataByTimeSeries(global));
            } else {
                console.error(data)
            }
        });

        socketRef.current.on('connect_error', err => console.error(err));
        socketRef.current.on('connect_failed', err => console.error(err));
        socketRef.current.on('disconnect', err => console.error(err));
        
        return () => {
            socketRef.current.disconnect();
        }
    }, [skipInput, currentView]);

    let history = useHistory();
    const dispatch = useDispatch();

    const [unit, setUnit] = useState('month');
    const [unitStepSize, setUnitStepSize] = useState(1);
    const [currDate, setCurrDate] = useState((moment().valueOf()));

    const dates = response.map(e => moment.unix(e[0])["_i"]);
    
    // OLD VALUES 
    const balance = response.map((e: any) => e[1][2]);
    const incomeBalance = response.map((e: any) => e[1][0]);
    const expensesBalance = response.map((e: any) => e[1][1]);

    // SIMU | NEW VALUES
    const income = response.map((e: any) => e[2][0]);
    const expenses = response.map((e: any) => e[2][1]);
    const simulatedBalance = response.map((e: any) => e[2][2]);
    const currIdx = dates.findIndex(e => timeKeys[0] === currentView ? 
        moment(e).month() === moment(currDate).month() :
        moment(e).year() === moment(currDate).year());

    const [axisScaleX, setAxisScaleX] = useState({ min: undefined, max: undefined });
    const [axisScaleY, setAxisScaleY] = useState({ min: undefined, max: undefined });

    const onPanBackward = () => {
        let stepperByView = (currentView === timeKeys[0]) ? 1 : 8;

        if (axisScaleX.min < dates[0]) {
            return
        }
        let min = moment(axisScaleX.min).subtract(stepperByView, 'years').valueOf()
        if (moment(min).format("YYYY") === moment().format("YYYY")) {
            stepperByView = (currentView === timeKeys[0]) ? 0.10 : 0.30;
            min = moment(min).subtract(stepperByView, 'years').valueOf()
        }
        setAxisScaleX({min: min, max: axisScaleX.min });
        setCurrDate(min)
    };

    const onPanForward = () => {
        const stepperByView = (currentView === timeKeys[0]) ? 1 : 8;

        if (axisScaleX.max > dates[dates.length - 1]) {
            return
        }
        setAxisScaleX({ min: axisScaleX.max, max: moment(axisScaleX.max).add(stepperByView, 'years').valueOf() });
        setCurrDate(axisScaleX.max)
    };

    const changeViewHandler = (newView: string) => {
        switch (newView) {
            // MONTHLY
            case timeKeys[0]: {
                setCurrentView(newView);
                setAxisScaleX({ min: moment().subtract(1, 'month').valueOf(), max: moment().set('date', 20).add(1, 'years').valueOf() });
                setUnit('month');
                setUnitStepSize(1);
                setCurrDate(moment().valueOf());
                break;
            }
            // YEARLY
            case timeKeys[1]: {
                setCurrentView(newView);
                setAxisScaleX({ min: moment().subtract(.5, 'year').valueOf(), max: moment().set('date', 20).add(8, 'years').valueOf() });
                setUnit('year');
                setUnitStepSize(1);
                setCurrDate(moment().valueOf());
                break;
            }
            // BIG PICTURE
            case timeKeys[2]: {
                setCurrentView(newView);
                // moment.unix(dates[dates.length - 1])["_i"]
                setAxisScaleX({ min: moment().subtract(1.6, 'year').valueOf(), max: dates[dates.length - 1] });
                setUnit('year');
                setUnitStepSize(10);
                setCurrDate(moment().valueOf());
                break;
            }
        }
        setCurrentView(newView);
    }

    const [lifeEventDetails, setLifeEventDetails] = useState<any>([]);
    const currAge = loadedProfileMS?.age;
    const retireLE = loadedProfileMS?.lifeEvents
        ?.filter(e => "3s7O7PtEaKgjdAML8huqsO" === e?.externalId)
        ?.filter(e => e.status === 'CONFIRMED');
    const retireAge = retireLE?.length && retireLE[0]?.customData?.retirementAge;

    useEffect(() => {
        const lifeEventConfirmed = (loadedProfileMS?.lifeEvents || []).filter(e => e.status === 'CONFIRMED');
        const details = lifeEventConfirmed?.filter(e => mapLEToDetailsV2(e)).map((e: any) => mapLEToDetailsV2(e));
        setLifeEventDetails(details)
    }, [loadedProfileMS.access_token])

    const onChangeSubCategory = (curr, sub, value, valueLEObj?) => {
        onCCOffFocus();
        let subs = curr.subCategories;
        let currValue = valueLEObj ? valueLEObj : { amount: value, type: "USER_INPUT" }
        subs = subs.map(d => {
            if (d === sub) {
              return { 
                  ...d, 
                  amount: currValue?.amount, 
                  simuAmount: currValue?.amount,
                  values: [currValue],
                  source: currValue?.type, 
            };
            }
            return d;
        });
        const totalAmt = subs.reduce((a, b) => a + b.amount, 0);
        let updateCategorySet = inputData.map(d => {
            if (d === curr) {
                return { ...d, amount: totalAmt, subCategories: subs }
            }
            return d
        });
        setInputData(updateCategorySet);
    }

    const onSaveEntry = async (curr) => {
        let updateCategorySet = inputData.map(d => {
            if (d === curr) {
                return { ...curr, onboarded: true, fixedValue: curr.amount }
            }
            return d
        });
        setInputData(updateCategorySet)
        const groupId = curr.id.toString()
        const mutate = {
            id: groupId,
            subCategories: curr.subCategories,
            onboarded: true,
        }
        await updateEntry2({ variables: mutate })
        saveEntry2({variables: { id: groupId }})
    }

    const onCCOffFocus = () => {
        setCurrCat({ id: '', color: '', name: '' } );
        setSeries([]);
    }

    const onClickCCFocus = async (event) => {
        const id = event.id.toString();
        let name = event.name;
        if (name === 'Income') {
            name = 'All Income'
        }
        setCurrCat(currCat.id === id ? { id: '', color: '', name: '' } : { id: id, color: '#FEB81E', name: name });
        if (currCat.id !== id) {
            try {
                const x = await updateEntry2({ variables: { id: id } })
                const seriesInit = set(x.data.updateEntryNextGen.simulation);
                const seriesYrly = dataByTimeSeries(seriesInit);
                setSeriesMthly(seriesInit);
                setSeriesYrly(seriesYrly);
                currentView === timeKeys[0] ? setSeries(seriesInit) : setSeries(seriesYrly);
            } catch {
                console.error('Unable to fetch the timeseries...');
            }
        } else {
            setSeries([]);
        }
    }

    const onSaveCCSpikes = (d: any, spikes: any) => {
        onCCOffFocus();
        const updateGroup = { ...d, spikes: spikes.lumpy };
        const updateCategorySet = inputData.map(g => {
            if (g === d) {
                return updateGroup
            }
            return g
        });
        setInputData(updateCategorySet);
        const mutate = {
            id: d.id.toString(),
            spikes: spikes.lumpy,
        }
        saveEntry2({ variables: mutate });
    }

    const onEnable = (d: any) => {
        onCCOffFocus();
        const isEnabled = !d.enabled
        const updateGroup = { ...d, enabled: isEnabled };
        const updateCategorySet = inputData.map(g => {
            if (g === d) {
                return updateGroup
            }
            return g
        });
        setInputData(updateCategorySet);
        const mutate = {
            id: d.id.toString(),
            enabled: isEnabled,
        }
        saveEntry2({ variables: mutate });
    }

    useEffect(() => {
        if (currentView === timeKeys[0]) {
            setResponse(responseMthly)
            if (currCat.id) {
                setSeries(seriesMthly)
            }
        } else {
            setResponse(responseYrly)
            if (currCat.id) {
                setSeries(seriesYrly)
            }
        }
    }, [currentView])

    const [gradient, setGradient] = useState<CanvasGradient>();
    const [chartData, setChartData] = useState<any>({});
    const chart = document.getElementsByClassName('chartjs-render-monitor')[0] as HTMLCanvasElement;
    const ctx = chart?.getContext('2d');
    useEffect(() => {
        if (ctx && currCat.color) {
            const bottom = chartData?.scales?.['y-axis-0']?.bottom
            const top = chartData?.scales?.['y-axis-0']?.top

            const gradient = ctx.createLinearGradient(0, bottom, 0, top);
            const ratio = Math.abs(Math.min((0 - top) / (bottom - top), 1));
            gradient.addColorStop(1, 'white');
            gradient.addColorStop(0, currCat.color);
            setGradient(gradient);
            // let initGradient = ctx.createLinearGradient(0, 0, 0, 400);
            // initGradient.addColorStop(0.30, 'rgba(255, 255, 255, 0)');   
            // initGradient.addColorStop(.80, currCat.color);
            // setGradient(initGradient)
        }
    }, [ctx, currCat.id]);

    // find the min / max of expenses + income for y scale
    useEffect(() => {
        if (response?.length) {
            const initMonthlyDates = currentView === timeKeys[2] ? response :
                response && response.filter((d: any) => axisScaleX.min <= moment.unix(d[0])["_i"] && axisScaleX.max >= moment.unix(d[0])["_i"]);
            const multiplier = 1.2;
            // Generate minimum avg of expense and net cash, compare the two and grab the smaller one
            const expMinY = -1 * Math.max(...initMonthlyDates.map((e: any) => e[2][1]));
            const simuMinY = Math.max(...initMonthlyDates.map((e: any) => e[2][2]));
            // Generate maximum avg of income and net cash, compare the two and grab the bigger one
            const incMaxY = Math.max(...initMonthlyDates.map((e: any) => e[2][0]));
            const simuMaxY = Math.max(...initMonthlyDates.map((e: any) => e[2][2]));
    
            setAxisScaleY({ 
                min: (expMinY < simuMinY ? expMinY : simuMinY) * multiplier, 
                max: (incMaxY > simuMaxY ? incMaxY : simuMaxY) * multiplier
            });
        }
    }, [response, currentView, axisScaleX]);

    useEffect(() => {
        if (currentView === timeKeys[2]) {
            setAxisScaleX({ min: moment().subtract(1.6, 'year').valueOf(), max: dates[dates.length - 1] });
        }
    }, [response])

    const carouselCardCount = () => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const count = howManyCards(width);
        return count;
    }

    return (
        !skipInput ? <EFSpinner style={{ marginTop: '2rem' }} /> : (
            <div style={{ width: '100%' }} data-tour="smart-cashflow">
                <Stack horizontalAlign='center'>
                    <Stack style={{justifyContent: 'space-between', width: '94%'}}>
                        <EFLabel style={{ fontSize: '2.2rem', fontWeight: 'normal', marginBottom: '1.6rem' }}>
                            SMART CASHFLOW
                        </EFLabel>
                        <Stack className={classes.container} horizontalAlign='center'>
                            {!inputData.filter(e => e.onboarded === true).length || !response.length ? (
                                <img style={{ height: '100%', width: '100%' }} src={interstitialImage} alt="interstitial" />
                            ) : (
                                <>
                                    <Stack style={{ width: '100%', padding: '0 4rem' }} horizontal horizontalAlign="space-between" verticalAlign="center">
                                        <div style={{ width: '1rem', height: '1rem' }} />
                                        <Stack style={{ marginLeft: screenIsAtLeast('tabPort') && '12rem' }} className={classes.inputNavX} horizontal verticalAlign='center' horizontalAlign='center'>
                                            {timeKeys.map((nav: any) =>
                                                <NavButton
                                                    focusStyle={{backgroundColor: '#FFF', color: '#FEB81E', border: 'unset'}}
                                                    offFocusStyle={{border: 0, backgroundColor: 'transparent'}} 
                                                    key={nav} 
                                                    text={nav} 
                                                    onClick={() => changeViewHandler(nav)}
                                                    isFocused={currentView === nav ? true : false}
                                                    style={{minWidth: !screenIsAtLeast('tabPort') && 0, height: '2.5rem', borderRadius: '1.5rem', fontSize: '1.2rem', margin: '.35rem'}}
                                                />
                                            )}
                                        </Stack>
                                        {screenIsAtLeast('tabPort') ? (
                                            <Stack horizontal verticalAlign="center">
                                                <img 
                                                    className={classes.toggle}
                                                    src={enable === 'Balance Automatically' ? green : red} 
                                                    alt="toggle-on-off" 
                                                    onClick={() => setEnable('Coming Soon!')} 
                                                />
                                                <EFLabel style={{ marginLeft: '0.5rem', textAlign: 'start', maxWidth: '10rem', fontWeight: 'unset' }}>
                                                    {enable}
                                                </EFLabel>
                                            </Stack>
                                        ) : <></>}
                                    </Stack>
                                    <Insight
                                        isMonthly={timeKeys[0] === currentView} 
                                        currIncome={income[currIdx]} 
                                        currExpenses={expenses[currIdx]}
                                        currNet={simulatedBalance[currIdx]} 
                                        currSimuNet={balance[currIdx]}
                                        presentAge={!screenIsAtLeast('tabPort') ? undefined : parseInt(currAge)}
                                        currAge={parseInt(currAge) + (parseInt(moment(currDate).format("YYYY")) - parseInt(moment().format("YYYY")))}
                                    />
                                    <Stack horizontal horizontalAlign='center' className={classes.carouselContainer}>
                                    <Stack className={classes.chartContainer} tokens={{childrenGap: '1.5rem'}}>
                                        <Stack horizontalAlign='center' className={classes.chart} horizontal>
                                            <CashflowChart
                                                onChartData={(e: any) => setChartData(e)}
                                                retireYr={getRetirementYr(retireAge, currAge)}
                                                showRetire={currentView !== timeKeys[0]}
                                                initAnn={currDate}
                                                maxTicksXLimit={12}
                                                unit={unit}
                                                unitStepSize={unitStepSize}
                                                minX={axisScaleX.min}
                                                maxX={axisScaleX.max}
                                                minY={axisScaleY.min}
                                                maxY={axisScaleY.max}
                                                datasets={{
                                                    labels: dates,
                                                    datasets: [
                                                        {
                                                            label: `Cashflow ${currCat.name.split(" ").join("")}`,
                                                            data: series.map((e: any) => e[2][2]),
                                                            fill: true,
                                                            backgroundColor: gradient || 'transparent',
                                                            borderColor: series.length ? currCat.color : 'transparent',
                                                            pointBorderWidth: 10,
                                                            pointHitRadius: 10,
                                                            pointBorderColor: currCat.color,
                                                            pointBackgroundColor: '#FFFFFF',
                                                        },
                                                        {
                                                            label: 'Cashflow Net',
                                                            fill: false,
                                                            data: simulatedBalance,
                                                            backgroundColor: !currCat.id ? 'rgba(254, 184, 30, 1)' : 'rgba(254, 184, 30, 0.30)',
                                                            borderColor: !currCat.id ? 'rgba(254, 184, 30, 1)' : 'rgba(254, 184, 30, 0.30)',
                                                            borderWidth: 7,
                                                            pointBorderWidth: 15,
                                                            pointHitRadius: 15,
                                                            pointBorderColor: !currCat.id ? 'rgba(254, 184, 30, 1)' : 'rgba(254, 184, 30, 0.30)',
                                                            pointBackgroundColor: !currCat.id ? 'rgba(254, 184, 30, 1)' : 'rgba(254, 184, 30, 0.30)',
                                                            pointShadowOffsetY: 4,
                                                            pointShadowBlur: 4,
                                                            pointShadowColor: 'rgba(109, 96, 25, 0.25)',
                                                            shadowOffsetX: 0,
                                                            shadowOffsetY: 0,
                                                            shadowBlur: 30,
                                                            shadowColor: 'rgba(254, 164, 30, 0.50)',
                                                        },
                                                        // {
                                                        //     label: 'Cashflow PreviousNet',
                                                        //     fill: false,
                                                        //     data: balance,
                                                        //     backgroundColor: '#D8D8D8',
                                                        //     borderColor: '#D8D8D8',
                                                        //     borderWidth: 5,
                                                        //     pointBorderWidth: 10,
                                                        //     pointHitRadius: 10,
                                                        //     pointBorderColor: '#D8D8D8',
                                                        //     pointBackgroundColor: '#D8D8D8',
                                                        //     pointShadowOffsetY: 4,
                                                        //     pointShadowBlur: 4,
                                                        //     shadowOffsetX: 0,
                                                        //     shadowOffsetY: 0,
                                                        //     shadowBlur: 30,
                                                        //     borderDash: [20,10]
                                                        // },
                                                        {
                                                            label: 'Cashflow Income',
                                                            data: income && income,
                                                            fill: false,
                                                            backgroundColor: !currCat.id ? 'rgba(48, 219, 32, 1)' : 'rgba(48, 219, 32, 0.30)',
                                                            borderColor: !currCat.id ? 'rgba(48, 219, 32, 1)' : 'rgba(48, 219, 32, 0.30)',
                                                            pointBorderWidth: 2,
                                                            pointHitRadius: 6,
                                                            pointBorderColor: !currCat.id ? 'rgba(48, 219, 32, 1)' : 'rgba(48, 219, 32, 0.30)',
                                                            pointBackgroundColor: !currCat.id ? 'rgba(48, 219, 32, 1)' : 'rgba(48, 219, 32, 0.30)',
                                                            pointShadowOffsetY: 4,
                                                            pointShadowBlur: 4,
                                                            pointShadowColor: !currCat.id ? 'rgba(109, 96, 25, 0.25)' : 'transparent',
                                                            shadowOffsetX: 0,
                                                            shadowOffsetY: 0,
                                                            shadowBlur: 30,
                                                            shadowColor: !currCat.id ? 'rgba(48, 219, 32, 0.50)' : 'transparent',
                                                        },
                                                        // {
                                                        //     label: 'Cashflow PreviousIncome',
                                                        //     data: incomeBalance && incomeBalance,
                                                        //     fill: false,
                                                        //     backgroundColor: 'rgba(48, 219, 32, 0.30)',
                                                        //     borderColor: 'rgba(48, 219, 32, 0.30)',
                                                        //     pointBorderWidth: 2,
                                                        //     pointHitRadius: 6,
                                                        //     pointBorderColor: 'rgba(48, 219, 32, 0.30)',
                                                        //     pointBackgroundColor: 'rgba(48, 219, 32, 0.30)',
                                                        //     pointShadowOffsetY: 4,
                                                        //     pointShadowBlur: 4,
                                                        //     pointShadowColor: 'transparent',
                                                        //     shadowOffsetX: 0,
                                                        //     shadowOffsetY: 0,
                                                        //     shadowBlur: 30,
                                                        //     shadowColor: 'transparent',
                                                        //     borderDash: [20,10],
                                                        // },
                                                        {
                                                            label: 'Cashflow Expenses',
                                                            data: expenses && expenses.map(e => e * -1),
                                                            fill: false,
                                                            backgroundColor: !currCat.id ? 'rgba(243, 121, 141, 1)' : 'rgba(243, 121, 141, 0.30)',
                                                            borderColor: !currCat.id ? 'rgba(243, 121, 141, 1)' : 'rgba(243, 121, 141, 0.30)',
                                                            pointBorderWidth: 2,
                                                            pointHitRadius: 6,
                                                            pointBorderColor: !currCat.id ? 'rgba(243, 121, 141, 1)' : 'rgba(243, 121, 141, 0.30)',
                                                            pointBackgroundColor: !currCat.id ? 'rgba(243, 121, 141, 1)' : 'rgba(243, 121, 141, 0.30)',
                                                            pointShadowOffsetY: 4,
                                                            pointShadowBlur: 4,
                                                            pointShadowColor: !currCat.id ? 'rgba(109, 96, 25, 0.25)' : 'transparent',
                                                            shadowOffsetX: 0,
                                                            shadowOffsetY: 0,
                                                            shadowBlur: 30,
                                                            shadowColor: !currCat.id ? 'rgba(243, 121, 141, 0.50)' : 'transparent',
                                                        },
                                                        // {
                                                        //     label: 'Cashflow PreviousExpenses',
                                                        //     data: expensesBalance && expensesBalance.map(e => e * -1),
                                                        //     fill: false,
                                                        //     backgroundColor: 'rgba(243, 121, 141, 0.30)',
                                                        //     borderColor: 'rgba(243, 121, 141, 0.30)',
                                                        //     pointBorderWidth: 2,
                                                        //     pointHitRadius: 6,
                                                        //     pointBorderColor: 'rgba(243, 121, 141, 0.30)',
                                                        //     pointBackgroundColor: 'rgba(243, 121, 141, 0.30)',
                                                        //     pointShadowOffsetY: 4,
                                                        //     pointShadowBlur: 4,
                                                        //     pointShadowColor: 'transparent',
                                                        //     shadowOffsetX: 0,
                                                        //     shadowOffsetY: 0,
                                                        //     shadowBlur: 30,
                                                        //     shadowColor: 'transparent',
                                                        //     borderDash: [20,10],
                                                        // },
                                                    ]
                                                }}
                                                onClick={(element: any) => element[0] && setCurrDate(element[0]._chart.tooltip._data.labels[element[0]._index])}
                                                onDrag={(event: any) => setCurrDate(event.subject.config.value)}
                                                isMonthlyView={currentView === timeKeys[0]}
                                            />
                                        </Stack>
                                        {(currentView === timeKeys[0] || currentView === timeKeys[1]) && (
                                            <Stack horizontal verticalAlign='center' horizontalAlign='center'>
                                                {moment(axisScaleX.min).format("YYYY") !== moment(dates[0]).format("YYYY") && 
                                                    <img onClick={onPanBackward} className={classes.arrow} alt='leftArrow' src={leftArrow} />}
                                                <div className={classes.timeBar}>
                                                    {currentView === timeKeys[0] ? (
                                                        <EFLabel style={{ fontSize: '1.4rem', color: '#9EA4B3'}}>
                                                            {moment(axisScaleX.min).format("YYYY") + ' - ' + moment(axisScaleX.max).format("YYYY")}
                                                        </EFLabel>
                                                    ) : <EFLabel style={{ fontSize: '1.4rem', color: '#9EA4B3'}}>
                                                        {moment(axisScaleX.min).format("YYYY") + ' - ' + moment(axisScaleX.max).format("YYYY")}
                                                        </EFLabel>}
                                                </div>
                                                {moment(axisScaleX.max).format("YYYY") !== moment(dates[dates.length - 1]).format("YYYY") && 
                                                    <img onClick={onPanForward} className={classes.arrow}  alt='rightArrow' src={rightArrow} />}
                                            </Stack>
                                        )}
                                    </Stack>
                                </Stack>
                                </>
                            )}
                        </Stack>
                    </Stack>
                    <CashCardList
                        cats={cats}
                        lifeEvents={lifeEventDetails}
                        items={inputData}
                        currCat={currCat}
                        onChangeSubCategory={onChangeSubCategory}
                        onClickCCFocus={onClickCCFocus}
                        onSaveSpikes={onSaveCCSpikes}
                        saveEntry={onSaveEntry}
                        onEnable={onEnable}
                        carouselCardCount={carouselCardCount()}
                    />
                </Stack>
            </div>
        )
    )
};

const getRetirementYr = (retireAge: number, currAge: number) => {
    if (retireAge && currAge) {
        return moment().endOf('year').add(retireAge - currAge, 'years').subtract(1, 'month').valueOf()
    } else {
        return undefined
    }
}

// Brute Forced TMP YEARLY / BP MAPPING
const dataByTimeSeries = (set: any) => {
    let newSet = [];
    let dateCounter = moment().valueOf();
    let count = 0;
    let net = [0, 0, 0];
    let simu = [0, 0, 0];

    for (let i = 0; i < set.length; i++) {
        if (moment.unix(set[i][0]).valueOf() < dateCounter) {
            net = [net[0] + set[i][1][0], net[1] + set[i][1][1], net[2] + set[i][1][2]];
            simu = [simu[0] + set[i][2][0], simu[1] + set[i][2][1], simu[2] + set[i][2][2]];
            count = count + 1;
        } else {
            newSet.push([moment(dateCounter).unix(), 
                net.map(e => 12 * e / count), simu.map(e => 12 * e / count)]);
            count = 0;
            net = [0, 0, 0];
            simu = [0, 0, 0];
            dateCounter = moment(dateCounter).add(1, 'year').endOf('year').valueOf();
        }
    }
    return newSet
}

const set = (initData: any) =>
    initData.map(e => {
        return [ 
            e.date, 
            [ 
                Math.trunc(e.defaultValue.income), 
                Math.trunc(e.defaultValue.expense), 
                Math.trunc(e.defaultValue.netCashflow) 
            ],
            [
                Math.trunc(e.updatedValue.income), 
                Math.trunc(e.updatedValue.expense), 
                Math.trunc(e.updatedValue.netCashflow)
            ]
        ]
    }
)

interface IProps {
    style?: object,
};

export default SmartCashflowView;
