import { RETIREMENT, COLLEGE_PLAN, BUY_HOUSE, BUY_CAR,
    STUDENT_DEBT, MANAGE_DEBT, AFFORDABILITY, CAREER_GOAL, SAVING_GOAL } from '../actions/Calculator';
import { getCustomData } from '../../utils/LifeEventTools/customDataGenerator';

const initialState = {
    retireReboot: getCustomData('Retire / Reboot'),
    collegePlan: getCustomData('College Plan'),
    buyHouse: getCustomData('Buy a House'),
    buyCar: getCustomData('Buy a Car'),
    studentDebt: getCustomData('Student Debt'),
    manageDebt: getCustomData('Manage Debt'),
    affordability: getCustomData('Affordability'),
    savingGoal: getCustomData('Saving Goal')
}

const calculatorReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case RETIREMENT: {
            return {
                ...state,
                retireReboot: action.retireReboot
            }
        }
        case COLLEGE_PLAN: {
            return {
                ...state,
                collegePlan: action.collegePlan
            }
        }
        case BUY_HOUSE: {
            return {
                ...state,
                buyHouse: action.buyHouse
            }
        }
        case BUY_CAR: {
            return {
                ...state,
                buyCar: action.buyCar
            }
        }
        case STUDENT_DEBT: {
            return {
                ...state,
                studentDebt: action.studentDebt
            }
        }
        case MANAGE_DEBT: {
            return {
                ...state,
                manageDebt: action.manageDebt
            }
        }
        case AFFORDABILITY: {
            return {
                ...state,
                affordability: action.affordability
            }
        }
        case CAREER_GOAL: {
            return {
                ...state,
                careerGoal: action.careerGoal
            }
        }
        case SAVING_GOAL: {
            return {
                ...state,
                savingGoal: action.savingGoal
            }
        }
        default:
            return state;
    }
}

export default calculatorReducer;
