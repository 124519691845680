import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import FamilyMember from './FamilyMember/FamilyMember';
import FamilyModal from './FamilyModal/FamilyModal';
import plusIcon from '../../../assets/icons/orangePlus.svg';
import classes from './Family.module.css';



const Family: React.FC<{}> = () => {
    const loggedUser = useSelector((state: any) => state.session.user);
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [dependent, setDependent] = useState(null);

    const editFamilyMember = (dependent) => {
        setOpenModalEdit(true);
        setDependent(dependent)
    }

    return (
        <div className={classes.familyMenu}>
            {loggedUser.dependents.map(dependent => {
                return (
                    <FamilyMember key={dependent.name + dependent.age + dependent.relationship} 
                        handleClick={() => editFamilyMember(dependent)}
                        dependent={dependent} />)
            })}
            <div className={classes.familyAdd} onClick={() => setOpenModal(true)}>
                <img alt="plusIcon" src={plusIcon} />
                <div> Add Family Member</div>
            </div>
            {openModal && <FamilyModal handleModal={() => setOpenModal(false)}
                                        title="Add Family Member"/>}
            {openModalEdit && <FamilyModal handleModal={() => setOpenModalEdit(false)} 
                                            title="Edit Family Member"
                                            dependent={dependent}/>}
        </div>
    )
}

export default Family;
