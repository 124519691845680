import React from 'react';
import EFButton from '../../../Atomic/EFButton/EFButton';
import FabricCalendar from '../../../Atomic/Calendar/FabricCalendar/FabricCalendar';
import classes from '../Interstitial.module.css';

import close from '../../../../assets/images/close.svg';
import pole from '../../../../assets/images/pole.svg';
import moment from 'moment';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';

interface Props {
    lifeEventDetails?: any;
    loanTerm?: any;
    onClick?: (formData) => void;
    onCloseClick?: () => void;
};

const AffordabilityTemp: React.FC<Props> = props => {
    const initDate = moment().valueOf();
    const endDate = (d) => moment(d).add(props.loanTerm / 12, "years").valueOf();
    const [formData, setFormData] = React.useState(
        { 
            nickName: props.lifeEventDetails.name, 
            startDate: props.lifeEventDetails?.startDate || initDate, 
            date: props.lifeEventDetails?.date || initDate 
        }
    );

    let content = (
        <>
            <p className={classes.header}>
                Let's check your timeline
            </p>
            <div className={classes.labelSaveContent}>
                <p className={classes.content} style={{ fontSize: '1.8rem', width: '10rem', textAlign: 'center' }}>
                    Start loan payment in
                </p>
                <p className={classes.content} style={{ fontSize: '1.8rem', width: '10rem', textAlign: 'center'  }}>
                    Pay off time <b>{props.loanTerm}</b> month{props.loanTerm === 1 ? '' : 's'}
                </p>
            </div>
            <div className={classes.poleContent}>
                <img className={classes.pole} src={pole} alt="pole" />
            </div>
            <div className={classes.inputSaveContent}>
                <div className={classes.labelContainer}>
                    <FabricCalendar
                        selectedDate={formData.startDate} 
                        strFormat="MM/YYYY" 
                        isDayPickerVisible={false}
                        onSelectDate={d => 
                            setFormData({ ...formData, "startDate": moment(d).valueOf(), "date": moment(d).valueOf() })
                        }
                        labelStyle={{ color: '#293861', fontSize: '1.6rem' }}
                    />
                </div>
                <p className={classes.content} style={{ fontSize: '2rem', padding: '0 2rem' }}>to</p>
                <div className={classes.labelContainer}>
                    <EFLabel style={{ fontSize: '1.6rem'}}>
                        {moment(endDate(formData.startDate)).format("MM/YYYY")}
                    </EFLabel>
                </div>
            </div>
        </>
    )

    let button = (
        <EFButton 
            text="SAVE" 
            onClick={async () => {
                props.onClick(formData);
                props.onCloseClick();
            }}
            style={{ minWidth: '16rem' }} 
        />
    );

    return (
        <div className={classes.container}>
            <div className={classes.closeContainer}>
                <img 
                    src={close}
                    alt="close" 
                    onClick={props.onCloseClick}
                    className={classes.close}
                />
            </div>
            <div className={classes.generalContainer}>
                <div className={classes.titleContainer}>
                    <img src={props.lifeEventDetails.icon[0].url} alt="le" style={{ width: '15%' }} />
                    <p className={classes.title}>{props.lifeEventDetails.name}</p>
                </div>
                <div className={classes.contentContainer}>
                    {content}
                </div>
                <div className={classes.buttonContainer}>
                    {button}
                </div>
            </div>
        </div >
    );
};

export default AffordabilityTemp;
