import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import { appendHeaders } from "../../utils/FetchHeaders";

import { useScroll } from "../../hooks/useScroll";
import { useResponsive } from "../../hooks/useResponsive";
import { IDropdownOption } from "office-ui-fabric-react";
import EFDropdown from "../Atomic/EFDropdown/EFDropdown";
import EFButtonOrange from "../CareerPlanning/EfButtonOrange";
import EfButtonWhite from "../CareerPlanning/EfButtonWhite";
import newDoc from "../../assets/images/protect/newDoc.svg";
import docWhite from "../../assets/images/protect/doc_white.svg";
import newFolder from "../../assets/images/protect/newFolder.svg";
import newFolderWhite from "../../assets/images/protect/newFolder_white.svg";
import close from "../../assets/images/protect/close.svg";
import { stateList } from "../../assets/content/StateList";
import EFLabel from "../Atomic/EFLabel/EFLabel";
import calendarAsset from "../../assets/images/kakeibo/calendar.svg";
import EFChoiceGroup from "../UI/EFChoiceGroup/EFChoiceGroup";
import { IChoiceGroupOption } from "office-ui-fabric-react";
import EFCollapsible from "../Atomic/EFCollapsible/EFCollapsible";
import classes from "./Protect.module.css";

interface presignedPostData {
  fields: any;
  url: string;
}

interface Props {
  token: string;
  onEnd: (data: any) => void;
  showAdd: boolean;
  openUploadModal?: boolean;
  onCloseUploadModal?: () => void;
}

interface Folder {
  name: string;
  icon: string;
  id: string;
  color: string;
}

let fileForSave;

const NAMES = [
  { key: 1, text: "Identification", color: "#B19FDD" },
  { key: 2, text: "Memberships", color: "#8FBE33" },
  { key: 3, text: "Family", color: "#F3798D" },
  { key: 4, text: "Job", color: "#B19FDD" },
  { key: 5, text: "House", color: "#F6BE00" },
  { key: 6, text: "Car", color: "#2695C7" },
  { key: 7, text: "Insurance", color: "#F7813B" },
  { key: 8, text: "Health", color: "#8FBE33" },
  { key: 9, text: "Tax", color: "#F6BE00" },
  { key: 10, text: "Financial", color: "#F6BE00" },
  { key: 11, text: "Receipts", color: "#F3798D" },
  { key: 12, text: "Transcripts", color: "#2695C7" },
  { key: 13, text: "Estate", color: "#F7813B" },
  { key: 14, text: "Vaccination Records", color: '#2695C7'}
];

const FOLDERS: Folder[] = NAMES.map((option) => ({
  id: option.key.toString(),
  name: option.text,
  icon: option.text + ".svg",
  color: option.color,
}));

const Protect: React.FC<Props> = (props) => {
  const [addType, setAddType] = useState(0);
  const [error, setError] = useState("");
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalHealthIsOpen, setModalHealthIsOpen] = React.useState(false);
  const [modalPolicyIsOpen, setModalPolicyIsOpen] = React.useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [name, setName] = useState("");
  const [fileTypes, setFileTypes] = useState([]);
  const [path, setPath] = useState(NAMES[0].text);
  const [allFiles, setAllFiles] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [keyPressed, setKeypressed] = useState(0);
  const [health, setHealth] = useState(0);
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [smoker, setSmoker] = useState(false);
  const [state, setState] = useState("");
  const [age, setAge] = useState(0);
  const [policies, setPolicies] = useState([]);

  useScroll(modalIsOpen);
  const { screenIsAtMost } = useResponsive();

  const handleDrop = useCallback((acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name));
    setFileTypes(acceptedFiles.map((file) => file.type));
    setAddType(0);
    fileForSave = acceptedFiles[0];
  }, []);

  const fetchData = useCallback(() => {
    fetch("https://api2-beta.equalfuture.com/v1.2/users", {
      method: "GET",
      headers: { ...appendHeaders(), Authorization: "Bearer " + props.token },
    })
      .then((res) => res.json())
      .then((data) => {
        setAllFiles(data.data.protect.files);
        setAllFolders(data.data.protect.folders);
      })
      .catch(console.log);
  }, [props.token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (policies.length > 0) {
      closeModalHealth();
      openModalPolicy();
    }
  }, [policies]);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
    setError("");
    props.onCloseUploadModal();
  }

  function openModalHealth() {
    setModalHealthIsOpen(true);
  }

  function closeModalHealth() {
    setModalHealthIsOpen(false);
  }

  function openModalPolicy() {
    setModalPolicyIsOpen(true);
  }

  function closeModalPolicy() {
    setModalPolicyIsOpen(false);
  }

  const uploadFileToS3 = (presignedPostData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });
      formData.append("file", file);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", presignedPostData.url, true);
      xhr.send(formData);
      xhr.onload = function () {
        this.status === 204 ? resolve() : reject(this.responseText);
        if (this.status === 204) {
          closeModal();
          fetchData();
        }
      };
    });
  };

  const upload = () => {
    if (name === "") {
      setError("Name cannot be empty");
      return;
    }

    if (addType === 1) {
      fetch(process.env.REACT_APP_PROTECT_FOLDERS_ENDPOINT, {
        method: "POST",
        headers: { ...appendHeaders(), Authorization: "Bearer " + props.token },
        body:
          `{
                          "name": "` +
          name +
          `",
                          "path": "` +
          name +
          `/` +
          name +
          `"
                      }`,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata && data.metadata.statusCode === 400) {
            setError(data.metadata.message);
          } else {
            closeModal();
            fetchData();
          }
        })
        .catch(console.log);
    } else {
      fetch(process.env.REACT_APP_PROTECT_FILES_ENDPOINT, {
        method: "POST",
        headers: { ...appendHeaders(), Authorization: "Bearer " + props.token },
        body:
          `{
                          "filename": "` +
          name +
          "." +
          fileForSave.type.split("/")[1] +
          `",
                          "type": "` +
          fileTypes[0] +
          `",
                          "path": "` +
          path +
          `"
                      }`,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata && data.metadata.statusCode === 400) {
            setError(data.metadata.message);
          } else {
            const presinged: presignedPostData = Object();
            presinged.fields = data.data.url.fields;
            presinged.url = data.data.url.url;
            uploadFileToS3(presinged, fileForSave);
          }
        })
        .catch(console.log);
    }
  };

  const ethos = () => {
    fetch(
      process.env.REACT_APP_PROTECT_INSURANCE_AGE_ENDPOINT +
      age +
      "&health=" +
      health +
      "&birthDate=" +
      birthDate +
      "&gender=" +
      gender +
      "&smoker=" +
      smoker +
      "&state=" +
      state,
      {
        method: "GET",
        headers: { ...appendHeaders(), Authorization: "Bearer " + props.token },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const arr = [];
        for (const [, policy] of data.data.policies.entries()) {
          arr.push(policy);
        }
        setPolicies(arr);
      })
      .catch(console.log);
  };

  const sendFiles = (folder: Folder) => {
    const files = [];
    for (const [, file] of allFiles.entries()) {
      if (file.path === folder.name) {
        files.push(file);
      }
    }
    props.onEnd({ category: folder.name, icon: folder.icon, files: files });
  };

  const countFiles = (path) => {
    let count = 0;
    allFiles.map((file) => {
      if (path === file.path) count++;
    });
    return count;
  };

  const renderFolder = (folder: Folder) => {
    const count = countFiles(folder.name);
    const color = folder.color || "#945B79";
    return (
      <div key={folder.id} className={classes.folderItem}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            sendFiles(folder);
          }}
        >
          <div
            className={classes.centerDivs}
            style={{ position: "relative", width: "10rem" }}
          >
            {count !== 0 && (
              <span
                className={classes.folderBubble}
                style={{
                  color,
                  border: `2px solid ${color}`,
                  left: screenIsAtMost("tabPort") ? "0" : ".6rem",
                }}
              >
                {count}
              </span>
            )}
            <img
              alt="folder"
              style={{ width: 60, height: 48 }}
              src={folder.icon}
            />
          </div>

          <div className={classes.centerDivs}>
            <span className={classes.labels}>{folder.name}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderFolderOptions = () => {
    const allFoldersToOptions = allFolders.map((folder) => ({
      key: folder.id,
      text: folder.name,
      color: "#945B79",
    }));
    return NAMES.concat(allFoldersToOptions);
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.centerDivs}>
        <Modal
          isOpen={modalIsOpen || props.openUploadModal}
          onRequestClose={closeModal}
          style={modalStyle}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={close}
              className={classes.rightButton}
              onClick={closeModal}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "6rem",
                marginBottom: "4rem",
              }}
            >
              <div onClick={() => setAddType(0)}>
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      style={{
                        outline: "none",
                        padding: "2rem",
                        width: "8rem",
                        margin: "0 1.5rem",
                        backgroundColor: addType === 0 ? "#84CDED" : "white",
                        boxShadow: "0px 2px 11px rgba(0, 0, 0, 0.109512)",
                        borderRadius: "13px",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      <div
                        style={{ marginBottom: "1rem" }}
                        className={classes.centerDivs}
                      >
                        <span
                          className={
                            addType === 0
                              ? classes.labelWhite
                              : classes.labelBlue
                          }
                        >
                          Document
                        </span>
                      </div>
                      <div className={classes.centerDivs}>
                        <img
                          style={{ width: "4rem" }}
                          src={addType === 0 ? docWhite : newDoc}
                        ></img>
                      </div>
                      <div className={classes.centerDivs}>
                        {fileNames.map((fileName) => (
                          <div
                            style={{
                              width: "100%",
                              wordBreak: "break-all",
                              fontSize: ".8rem",
                              marginTop: "1rem",
                            }}
                            className={
                              addType === 0
                                ? classes.labelWhite
                                : classes.labelBlue
                            }
                            key={fileName}
                          >
                            {fileName}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div
                onClick={() => {
                  setFileNames([]);
                  setAddType(1);
                }}
                style={{
                  width: "8rem",
                  margin: "0 1.5rem",
                  padding: "2rem",
                  boxShadow: "0px 2px 11px rgba(0, 0, 0, 0.109512)",
                  backgroundColor: addType === 1 ? "#84CDED" : "white",
                  borderRadius: "13px",
                  cursor: "pointer",
                  transform: "5",
                }}
              >
                <div
                  style={{ marginBottom: "1rem" }}
                  className={classes.centerDivs}
                >
                  <span
                    className={
                      addType === 1 ? classes.labelWhite : classes.labelBlue
                    }
                  >
                    Folder
                  </span>
                </div>
                <div className={classes.centerDivs}>
                  <img
                    style={{ width: "4.5rem" }}
                    src={addType === 1 ? newFolderWhite : newFolder}
                  ></img>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                margin: "auto" as "auto",
              }}
            >
              <span className={classes.labelGrey}>Give it a name</span>
            </div>
            <div
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "left",
                margin: "auto" as "auto",
                marginBottom: "2rem",
              }}
            >
              <input
                type="text"
                className={classes.inputStyle}
                placeholder={"Type a name"}
                onChange={(e) => setName(e.target.value)}
              ></input>
              {error && (
                <div
                  style={{
                    color: "red",
                    marginTop: "1.5rem",
                    fontSize: "1.3rem",
                  }}
                >
                  {error}
                </div>
              )}
            </div>
            {addType === 0 && (
              <>
                <div className={classes.leftDivs}>
                  <span className={classes.labelGrey}>Choose folder</span>
                </div>
                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "left",
                    marginBottom: 20,
                  }}
                >
                  <EFDropdown
                    placeholder={NAMES[0].text}
                    style={{ width: "21rem" }}
                    options={renderFolderOptions()}
                    onChange={(e: any, item?: IDropdownOption) => {
                      setPath(item?.text);
                    }}
                  />
                </div>
              </>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                margin: "2rem 0",
              }}
            >
              <EfButtonWhite
                size={"1rem"}
                width={"33%"}
                text={"CANCEL"}
                click={closeModal}
              />
              <EFButtonOrange
                size={"1rem"}
                width={"33%"}
                text={"CREATE"}
                click={upload}
              />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={modalPolicyIsOpen}
          onRequestClose={closeModalPolicy}
          style={modalHealth}
        >
          <div
            style={{
              width: "100%",
              marginTop: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "28px",
            }}
          >
            <span className={classes.titleStyle}>SELECT A PLAN</span>
          </div>
          <hr className={classes.divider} />

          <img
            src={close}
            alt="close"
            className={classes.rightButtonClose}
            onClick={closeModalHealth}
          />

          {policies.map((item, idx) => (
            <div key={idx} style={{ marginTop: 30 }}>
              <EFCollapsible
                title={
                  "Plan " + (idx + 1) + " - " + " $" + item.monthly + " / MONTH"
                }
                image={idx.toString()}
                childComp={
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <EFLabel style={{ fontWeight: "normal" as "normal" }}>
                        {"Cover " + " $" + item.coverage}
                      </EFLabel>
                      <div style={{ marginLeft: 20 }}>
                        <EFLabel style={{ fontWeight: "normal" as "normal" }}>
                          {"Term " + " " + item.term + " years"}
                        </EFLabel>
                      </div>
                    </div>
                    <hr className={classes.divider} />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <EFLabel style={{ fontWeight: "normal" as "normal" }}>
                        {"Payment options"}
                      </EFLabel>
                    </div>
                    <hr className={classes.divider} />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <EFLabel
                        style={{
                          fontWeight: "normal" as "normal",
                          color: "grey",
                          textSize: "15rem",
                        }}
                      >
                        {"ANNUAL"}
                      </EFLabel>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <EFLabel style={{ fontWeight: "bold" as "bold" }}>
                        {"$" + item.annually}
                      </EFLabel>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <EFLabel
                        style={{
                          fontWeight: "normal" as "normal",
                          color: "grey",
                          textSize: "15rem",
                        }}
                      >
                        {"SEMIANNUAL"}
                      </EFLabel>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <EFLabel style={{ fontWeight: "bold" as "bold" }}>
                        {"$" + item.semiannually}
                      </EFLabel>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <EFLabel
                        style={{
                          fontWeight: "normal" as "normal",
                          color: "grey",
                          textSize: "15rem",
                        }}
                      >
                        {"MONTHLY"}
                      </EFLabel>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <EFLabel style={{ fontWeight: "bold" as "bold" }}>
                        {"$" + item.monthly}
                      </EFLabel>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <EFLabel
                        style={{
                          fontWeight: "normal" as "normal",
                          color: "grey",
                          textSize: "15rem",
                        }}
                      >
                        {"QUARTERLY"}
                      </EFLabel>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <EFLabel style={{ fontWeight: "bold" as "bold" }}>
                        {"$" + item.quarterly}
                      </EFLabel>
                    </div>
                    <hr className={classes.divider} />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                    >
                      <EFButtonOrange
                        text={"PICK THIS PLAN"}
                        width={"40%"}
                        size={"calc(0.1em + 0.5vw)"}
                      />
                    </div>
                  </>
                }
              ></EFCollapsible>
            </div>
          ))}
        </Modal>
        <Modal
          isOpen={modalHealthIsOpen}
          onRequestClose={closeModalHealth}
          style={modalDeleteStyle}
        >
          <div
            style={{
              width: "100%",
              marginTop: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "28px",
            }}
          >
            <span className={classes.titleStyle}>HEALTH INSURANCE</span>
          </div>
          <hr className={classes.divider} />
          <div style={{ padding: "0 8rem" }}>
            <img
              src={close}
              alt="close"
              className={classes.rightButtonClose}
              onClick={closeModalHealth}
            />
            <div className={classes.insuranceModalItem}>
              <EFLabel style={{ fontWeight: "normal" as "normal" }}>
                STATE
              </EFLabel>
            </div>
            <div className={classes.insuranceModalItem}>
              <EFDropdown
                placeholder={stateList[0].text}
                options={stateList}
                style={{ width: "18rem" }}
                onChange={(e: any, item?: IDropdownOption) => {
                  setState(item.key.toString());
                }}
              />
            </div>
            <div className={classes.insuranceModalItem}>
              <EFLabel style={{ fontWeight: "normal" as "normal" }}>
                DATE
              </EFLabel>
            </div>
            <div className={classes.insuranceModalItem}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    outline: 0,
                    fontWeight: 600,
                    backgroundColor: "#FFFFFF",
                    borderWidth: "0 0 1px",
                    borderColor: "#d5d4cf",
                    width: "18rem",
                    fontFamily: "brandon-grotesque, sans-serif",
                    fontSize: "20px",
                    lineHeight: "23px",
                    color: "#293861",
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 8) {
                      setKeypressed(1);
                    } else {
                      setKeypressed(0);
                    }
                  }}
                  onChange={(e) => {
                    if (keyPressed !== 1) {
                      if (e.target.value.length == 4) {
                        e.target.value = e.target.value + "-";
                      } else if (e.target.value.length == 7) {
                        e.target.value = e.target.value + "-";
                      }
                    }
                    setBirthDate(e.target.value);
                  }}
                  placeholder={"YYYY/MM/DD"}
                />
                <img
                  src={calendarAsset}
                  style={{ marginLeft: -20, cursor: "pointer" }}
                  onClick={() => {
                    setCalendarVisible(true);
                  }}
                ></img>
              </div>
            </div>
            <div className={classes.insuranceModalItem}>
              <EFLabel style={{ fontWeight: "normal" as "normal" }}>
                AGE
              </EFLabel>
            </div>
            <div className={classes.insuranceModalItem}>
              <input
                style={{
                  outline: 0,
                  fontWeight: 600,
                  backgroundColor: "#FFFFFF",
                  borderWidth: "0 0 1px",
                  borderColor: "#d5d4cf",
                  width: "5rem",
                  fontFamily: "brandon-grotesque, sans-serif",
                  fontSize: "20px",
                  lineHeight: "23px",
                  color: "#293861",
                }}
                onChange={(e) => {
                  setAge(parseFloat(e.target.value));
                }}
              />
            </div>
            <div className={classes.insuranceModalItem}>
              <EFLabel style={{ fontWeight: "normal" as "normal" }}>
                GENDER
              </EFLabel>
            </div>
            <div className={classes.insuranceModalItem}>
              <EFChoiceGroup
                selectorColor="#fab81e"
                options={[
                  { key: "female", text: "Female" },
                  { key: "male", text: "Male" },
                ]}
                onChange={(e: any, option?: IChoiceGroupOption) => {
                  setGender(option.text);
                }}
              />
            </div>
            <div className={classes.insuranceModalItem}>
              <EFLabel style={{ fontWeight: "normal" as "normal" }}>
                DO YOU SMOKE?
              </EFLabel>
            </div>
            <div className={classes.insuranceModalItem}>
              <EFChoiceGroup
                selectorColor="#fab81e"
                options={[
                  { key: "yes", text: "Yes " },
                  { key: "no", text: "No" },
                ]}
                onChange={(e: any, option?: IChoiceGroupOption) => {
                  if (option.key == "yes") {
                    setSmoker(true);
                  } else {
                    setSmoker(false);
                  }
                }}
              />
            </div>
            <div className={classes.insuranceModalItem}>
              <EFLabel style={{ fontWeight: "normal" as "normal" }}>
                YOUR HEALTH
              </EFLabel>
            </div>
            <div className={classes.insuranceModalItem}>
              <EFChoiceGroup
                selectorColor="#fab81e"
                options={[
                  { key: "1", text: "Healthy" },
                  { key: "2", text: "Great Health" },
                  { key: "3", text: "Could be better" },
                  { key: "4", text: "Weak" },
                ]}
                onChange={(e: any, option?: IChoiceGroupOption) => {
                  setHealth(parseFloat(option.key));
                }}
              />
            </div>
            <div className={classes.insuranceModalItem}>
              <EFButtonOrange
                size={"1.4rem"}
                width={"100%"}
                text={"GET QUOTE"}
                click={ethos}
              />
            </div>
          </div>
        </Modal>
        <div
          data-tour="vault"
          className={`${classes.container} ${screenIsAtMost("tabPort") ? classes.containerPort : ""
            }`}
        >
          {allFolders.map((folder: Folder) => renderFolder(folder))}
          {props.showAdd && (
            <div className={classes.folderItem}>
              <div style={{ cursor: "pointer" }}>
                <div className={classes.centerDivs}>
                  <img
                    style={{ width: 60, height: 48 }}
                    src={require("../../assets/images/protect/Add.svg")}
                    onClick={openModal}
                  />
                </div>
                <div className={classes.centerDivs}>
                  <span className={classes.labelYellow}>ADD</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Protect;

const modalStyle = {
  content: {
    justifyContent: "center" as "center",
    alignItems: "middle" as "middle",
    display: "block",
    margin: "auto",
    borderRadius: 10,
    width: "35rem",
    top: "10%",
    left: 0,
    right: 0,
    borderStyle: "none" as "none",
    backgroundColor: "#fff",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.11)",
    position: "relative" as "relative",
  },
  overlay: {
    overflowY: "auto" as "auto",
    background: 'rgba(248, 250, 255, 0.50)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
    zIndex: 10
  }
};

const modalDeleteStyle = {
  content: {
    justifyContent: "center" as "center",
    alignItems: "middle" as "middle",
    display: "block",
    margin: "auto",
    borderRadius: 10,
    height: "80%",
    width: "35rem",
    borderStyle: "none" as "none",
    backgroundColor: "#fff",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.11)",
  },
  overlay: {
    overflowY: "auto" as "auto",
    background: 'rgba(248, 250, 255, 0.50)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
  }
};

const modalHealth = {
  content: {
    justifyContent: "center" as "center",
    alignItems: "middle" as "middle",
    display: "block",
    margin: "auto",
    borderRadius: 10,
    height: "50%",
    width: "35rem",
    top: 20,
    borderStyle: "none" as "none",
    backgroundColor: "rgba(255, 255, 255, 0.2);",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.11)",
    position: "relative" as "relative",
  },
  overlay: {
    overflowY: "auto" as "auto",
    background: 'rgba(248, 250, 255, 0.50)',
    backdropFilter: 'blur(40px)',
    WebkitBackdropFilter: 'blur(40px)',
  }
};
