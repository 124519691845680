import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

interface Props {
  datasets?: object;
  barRadius?: number;
  barPercentage?: number;
}

const StackedBarChart: React.FC<Props> = (props) => {
  return (
    <Bar
      data={props.datasets}
      options={{
        maintainAspectRatio: false,
        cornerRadius: props.barRadius,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              barPercentage: props.barPercentage,
              stacked: true,
              gridLines: {
                display: true,
                drawOnChartArea: false,
                drawTicks: false,
                margin: 10,
              },
              ticks: {
                padding: 10,
                fontFamily: "brandon-grotesque",
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                fontFamily: "brandon-grotesque",
                beginAtZero: false,
                maxTicksLimit: 3,
                padding: 10,
                callback: (value: number) => {
                  return value < 1000000
                    ? value / 1000 + "k"
                    : value / 1000000 + "m";
                },
              },
            },
          ],
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      }}
    />
  );
};

StackedBarChart.defaultProps = {
  barRadius: 0,
  datasets: {
    labels: ["2020", "2021", "2022", "2023"],
    datasets: [
      {
        type: "bar",
        label: "Income1",
        data: [5000, 6000, 6000, 6500],
        backgroundColor: ["#98E6BD", "#98E6BD", "#98E6BD", "#98E6BD"],
        borderColor: ["#98E6BD", "#98E6BD", "#98E6BD", "#98E6BD"],
      },
      {
        type: "bar",
        label: "Income2",
        data: [6000, 6000, 6000, 0],
        backgroundColor: ["#FF9255", "#FF9255", "#FF9255", "#FF9255"],
        borderColor: ["#FF9255", "#FF9255", "#FF9255", "#FF9255"],
      },
      {
        type: "bar",
        label: "Income3",
        data: [23000, 25000, 17000, 0],
        backgroundColor: ["#02C4EA", "#02C4EA", "#02C4EA", "#02C4EA"],
        borderColor: ["#02C4EA", "#02C4EA", "#02C4EA", "#02C4EA"],
      },
      {
        type: "bar",
        label: "Income4",
        data: [0, 0, 10000, 33000],
        backgroundColor: ["#F3798D", "#F3798D", "#F3798D", "#F3798D"],
        borderColor: ["#F3798D", "#F3798D", "#F3798D", "#F3798D"],
      },
    ],
  },
};

export default StackedBarChart;
