import { SAVE_ANSWER, CREATE_ONBOARDED_USER, START_ONBOARDING, UPDATE_CURRENT_QUESTION, GO_BACK, SHOW_PLAN_SUMMARY, REMOVE_LE, ADD_LE, RETAKE } from '../actions/Onboarding';

const initialState = {
    age: 0,
    familyStatus: '',
    dependents: [],
    state: '',
    city: '',
    zipCode: '',
    rentHome: false,
    householdAnnualIncome: 0,
    monthlyHouseholdSpend: 0,
    suggestedLifeEvents: [],
    currentQuestion: {},
    currentLE: [],
    transitions: [],
    savedAnswers: {},
    setupCompleted: false,
};

const onboardingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SAVE_ANSWER: {
            const questionIndex = action.questionIndex;
            const answer = action.answer;
            switch (questionIndex) {
                case 0: {
                    return {
                        ...state,
                        age: answer,
                        savedAnswers: action.savedAnswers
                    }
                }
                case 1: {
                    return {
                        ...state,
                        familyStatus: answer,
                        savedAnswers: action.savedAnswers
                    }
                }
                case 2: {
                    return {
                        ...state,
                        savedAnswers: action.savedAnswers
                    }
                }
                case 3: {
                    return {
                        ...state,
                        savedAnswers: action.savedAnswers
                    }
                }
                case 4: {
                    return {
                        ...state,
                        dependents: answer,
                        savedAnswers: action.savedAnswers
                    }
                }
                case 5: {
                    return {
                        ...state,
                        savedAnswers: action.savedAnswers
                    }
                }
                case 6: {
                    return {
                        ...state,
                        savedAnswers: action.savedAnswers
                    }
                }
                case 7: {
                    return {
                        ...state,
                        savedAnswers: action.savedAnswers
                    }
                }
                case 8: {
                    return {
                        ...state,
                        suggestedLifeEvents: answer,
                        savedAnswers: action.savedAnswers
                    }
                }
            }
            break;
        }
        case CREATE_ONBOARDED_USER: {
            return initialState;
        }
        case START_ONBOARDING: {
            let newTransitions = [...state.transitions];
            newTransitions.push(action.transition);
            return {
                ...state,
                currentQuestion: action.currentQuestion,
                transitions: newTransitions
            }
        }
        case UPDATE_CURRENT_QUESTION: {
            let newTransitions = [...state.transitions];
            newTransitions.push(action.transition);
            let newLE = [...state.currentLE];
            if (action.le.length > 0) {
                const filteredLE = newLE.filter(le => le.name === action.le[0].name && le.subtitle === action.le[0].subtitle); //Needs to handle multiple suggested LE, future improvement
                if (filteredLE.length === 0) {
                    newLE = [...state.currentLE, ...action.le];
                }
            }
            return {
                ...state,
                savedAnswers: action.savedAnswers,
                currentQuestion: action.currentQuestion,
                transitions: newTransitions,
                currentLE: newLE
            }
        }
        case GO_BACK: {
            let newTransitions = [...state.transitions];
            newTransitions.pop();
            let newLE = [...state.currentLE];
            newLE = newLE.filter(le => le.transition !== action.oldTransition);
            return {
                ...state,
                currentQuestion: action.currentQuestion,
                transitions: newTransitions,
                currentLE: newLE
            }
        }
        case SHOW_PLAN_SUMMARY: {
            const newLE = [...state.currentLE, ...action.le];
            return {
                ...state,
                currentQuestion: action.currentQuestion,
                currentLE: newLE,
                transitions: action.transition,
                setupCompleted: true
            }
        }
        case ADD_LE: {
            return {
                ...state,
                currentLE: action.currentLE
            }
        }
        case REMOVE_LE: {
            return {
                ...state,
                currentLE: action.currentLE
            }
        }
        case RETAKE: {
            return {
                ...state,
                setupCompleted: false,
                currentLE: []
            }
        }
        default: {
            return state;
        }
    }
};

export default onboardingReducer;