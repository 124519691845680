import React from "react";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../utils/Masking';

const numberMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
});


const ChartLabel = (props:any) => {
    const { x, y, width, value } = props;
    const radius = 10;
    return (
      <text
         x={x + width / 2} 
         y={y - radius} 
         fill="#000" 
         textAnchor="middle" 
         dominantBaseline="middle" 
         style={{
          fontFamily: 'brandon-grotesque, sans-serif',
          fontSize: '1.4rem',
          textAlign: 'center' as 'center',
          color: '#293861',
        }}
      >
        {maskValue(value, numberMask)}
      </text>
    );
};

export default ChartLabel;
