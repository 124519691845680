import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import EFHeader from '../../components/UI/EFHeader/EFHeader';
import Footer from '../../components/Atomic/SecurityFooter';
import EFButton from '../../components/Atomic/EFButton/EFButton';
import CompletionBar from '../../components/Atomic/CompletionBar/CompletionBar';
import Question, { IQuestion } from './Question';
import OnboardingLifeEvents from './OnboardingLifeEvents';
import { saveAnswer, createOnboardedUser, startOnboarding, getNextQuestion, goBack, removeLE, addLE } from '../../store/actions/Onboarding';

import leftArrow from '../../assets/icons/chevron_left.svg';
import addLifeEventIcon from '../../assets/images/lifeEvents/addLifeEvent.svg';
import removeLifeEventIcon from '../../assets/images/removeIcon.svg';
import classes from './CompleteProfilePage.module.css';
import LifeEventPicker from '../../components/LifeEvents/LifeEventPicker/LifeEventPicker';
import TagManager from 'react-gtm-module'

const CompleteProfilePage: React.FC<{}> = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const currentQuestion = useSelector((state: any) => state.onboarding.currentQuestion);
    const lifeEvents = useSelector((state: any) => state.onboarding.currentLE);
    const completed = useSelector((state: any) => state.onboarding.setupCompleted);
    const transitions = useSelector((state: any) => state.onboarding.transitions);

    const [completition, setCompletition] = useState(0);

    const [answer, setAnswer] = useState('');
    const [answer2, setAnswer2] = useState('');
    //const [lifeEvents, setLifeEvents] = useState<LifeEvent[]>([]);
    //const [completed, setCompleted] = useState(false);
    const [showLifeEventPicker, setShowLifeEventPicker] = useState(false);

    const realUser = useSelector((state: any) => state.data.realUser);

    // Google analytics set up for page
    const tagManagerArgs = {
        dataLayer: {
            userId: realUser?.trackingId,
            page: 'My Plan Setup'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    useEffect(() => {
        dispatch(startOnboarding());
    }, []);


    const sendAnswer = () => {
        dispatch(getNextQuestion(currentQuestion.configuration.fields[0].index, answer, currentQuestion.configuration.fields[1]?.index, answer2));
        setAnswer('');
        setAnswer2('');
        setCompletition(completition + 12);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer(e.target.value);
    }

    const handleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer2(e.target.value)
    }

    const prevQuestion = () => {
        setCompletition(completition -12);
        dispatch(goBack());
    }

    const addSuggestedLifeEvent = (lifeEvent: any) => {
        dispatch(addLE({
            eventIcon: [{
                url: lifeEvent.img
            }],
            name: lifeEvent.title,
            subtitle: '',
            detail: ''
        }))
    }

    const removeSuggestedLifeEvent = (index: number) => {
        dispatch(removeLE(index));
    }

    const Title = ({ completed }) => {

        if (completed) {
            return (
                <div>
                    <p className={classes.title}>Based on what you've told us</p>
                    <p className={classes.title}>Here are some life events you can</p>
                    <p className={classes.title}>get started with:</p>
                    <CompletionBar completionPercentage={100} />
                </div>
            )
        }

        return (
            <div>
                <p className={classes.title}>Help us help you!</p>
                <p className={classes.title}>Let's complete your profile</p>
                <CompletionBar completionPercentage={completition} />
            </div>
        )
    }

    const PreviousQuestionBtn = () => {
        if (!(transitions[transitions.length - 1] === 'initial')) {
            return (
                <button onClick={prevQuestion} className={classes.previousBtn}>
                    <img src={leftArrow} alt="leftArrow" className={classes.leftArrow} />
                    <div>previous question</div>
                </button>
            )
        }

        return null;
    }

    let content = null;

    if (Object.keys(currentQuestion).length > 0 || completed) {
        content = (
            <div className={classes.page}>
                <EFHeader onNavToMain customStyle={{ display: 'block'}}/>
                <div className={classes.container}>
                    <Title completed={completed} />
                    {showLifeEventPicker &&
                        <LifeEventPicker
                            onBackdropClick={() => setShowLifeEventPicker(false)}
                            onExitClick={() => setShowLifeEventPicker(false)}
                            openModal={showLifeEventPicker}
                            onLifeEventClick={(le: any) => {
                                addSuggestedLifeEvent(le);
                            }} />
                    }
                    {!completed ?
                        <>
                            <div className={classes.content}>
                                <div className={classes.card}>
                                    <div className={classes.cardSection}>
                                        <Question question={currentQuestion.question}
                                            placeholder={currentQuestion.configuration.fields[0].attributes.placeholder}
                                            isDropdown={currentQuestion.configuration.fields[0].fieldType === 'select' ? true : false}
                                            options={currentQuestion.configuration.fields[0].attributes.values}
                                            subtext={currentQuestion.description}
                                            label1={currentQuestion.configuration.fields[0].label}
                                            label2={currentQuestion.configuration.fields[1]?.label}
                                            answer={answer}
                                            answer2={answer2}
                                            handleChange={handleChange}
                                            handleChange2={handleChange2}
                                            dropdownSetValue={(option: string) => {
                                                let value = option;
                                                setAnswer(option);
                                                setAnswer2(value)
                                            }} />
                                        {!currentQuestion.configuration.required &&
                                            <button onClick={() => {
                                                dispatch(getNextQuestion(currentQuestion.configuration.fields[0].index, '', currentQuestion.configuration.fields[1]?.index, '', true));
                                                //nextQuestion(1)
                                            }} className={classes.skipBtn}>Skip question</button>
                                        }
                                    </div>
                                </div>
                                <EFButton style={{ width: '20rem', marginTop: '3rem' }} isDisabled={!answer}
                                    text="NEXT" onClick={() => {
                                        sendAnswer()
                                    }} />
                                <PreviousQuestionBtn />
                            </div>
                            {lifeEvents.length > 0 && <OnboardingLifeEvents lifeEvents={lifeEvents.filter(le => le !== null).reverse()} />}
                        </>
                        :
                        <div className={classes.content}>
                            <div className={classes.completedContainer}>
                                {lifeEvents.map((lifeEvent: any, i: number) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <div className={classes.completedLifeEvent}>
                                                <img className={classes.removeIcon} src={removeLifeEventIcon} alt="remove"
                                                    onClick={() => {
                                                        removeSuggestedLifeEvent(i);
                                                    }} />
                                                <img className={classes.completedImg} src={lifeEvent.eventIcon[0].url} alt="lifeEventIcon" />
                                                <div className={classes.completedTitle}>{lifeEvent.name}</div>
                                            </div>
                                            <div className={classes.completedSeparator}>...</div>
                                        </React.Fragment>
                                    )
                                })}
                                <div className={classes.completedLifeEvent}>
                                    <img className={classes.completedImg} src={addLifeEventIcon} alt="addLifeEventIcon"
                                        onClick={() => setShowLifeEventPicker(true)} />
                                    <div className={classes.completedTitle}>Add another Life Event</div>
                                </div>
                            </div>
                            <EFButton style={{ width: '20rem', marginTop: '5rem' }}
                                text="GET STARTED" onClick={() => {
                                    dispatch(createOnboardedUser(history));
                                }} />
                        </div>}
                    <Footer />
                </div>
                <div className={classes.rightColumn}></div>
            </div>
        )
    }

    return content;
};

export default CompleteProfilePage;
