import React from 'react';
import { Stack, INavLink } from 'office-ui-fabric-react';
import classes from './Menu.module.css';
import EFNav from '../../Atomic/EFNav/EFNav';

interface IProps {
    connectedKey?: string,
    onConnectButtonClick?: () => void,
    onConnectedLinkClick?: (e?: React.MouseEvent<HTMLElement>, item?: INavLink) => void,
    protectKey?: string,
    onProtectButtonClick?: () => void,
    onProtectLinkClick?: (e?: React.MouseEvent<HTMLElement>, item?: INavLink) => void,
    growKey?: string,
    onGrowButtonClick?: () => void,
    onGrowLinkClick?: (e?: React.MouseEvent<HTMLElement>, item?: INavLink) => void,
    currentDashboard?: string
};

const Menu: React.FC<IProps> = props => {

    return (
        <Stack horizontalAlign='center' tokens={{ childrenGap: 10 }}>
            {props.currentDashboard === "CONNECT" ? (
                <EFNav
                    dataTourId="connect"
                    name='CONNECT'
                    onClick={() => {
                        props.onConnectButtonClick();
                    }}
                    onLinkClick={(event, item) => {
                        props.onConnectedLinkClick(event, item);
                    }}
                    buttonColor='#feb81e'
                    links={[
                        {
                            key: 'Get Organized',
                            name: 'Get Organized',
                            url: '',
                        },
                        {
                            key: 'My Playlist',
                            name: 'My Playlist',
                            url: '',
                        },
                        {
                            key: 'Your Progress',
                            name: 'Your Progress',
                            url: ''
                        },
                        {
                            key: 'Plan For Future',
                            name: 'Plan For Future',
                            url: '',
                        }
                    ]}
                    selectedKey={props.connectedKey}
                />) : (
                    <div onClick={() => {
                        props.onConnectButtonClick();
                    }} style={{ backgroundColor: '#feb81e' }} className={classes.button}>
                        <p className={classes.text}>
                            CONNECT
                    </p>
                    </div>
                )}
            {props.currentDashboard === "PROTECT" ? (
                <EFNav
                    dataTourId="protect"
                    name='PROTECT'
                    onLinkClick={props.onProtectLinkClick}
                    buttonColor='#84cded'
                    links={[
                        {
                            key: 'Secure Vault',
                            name: 'Secure Vault',
                            url: '',
                        },
                        {
                            key: 'Insurance',
                            name: 'Insurance',
                            url: '',
                        }
                    ]}
                    selectedKey={props.protectKey}
                />) : (
                    <div onClick={() => {
                        props.onProtectButtonClick();
                    }} style={{ backgroundColor: '#84cded' }} className={classes.button}>
                        <p className={classes.text}>
                            PROTECT
                    </p>
                    </div>
                )}
            {props.currentDashboard === "GROW"? (
                <EFNav
                    dataTourId="grow"
                    name='GROW'
                    onLinkClick={props.onGrowLinkClick}
                    buttonColor='#aad17e'
                    links={[
                        {
                            key: 'Overview',
                            name: 'Overview',
                            url: '',
                        },
                        {
                            key: 'Low Down',
                            name: 'Low Down',
                            url: '',
                        },
                        {
                            key: 'Solutions',
                            name: 'Solutions',
                            url: '',
                        },
                    ]}
                    selectedKey={props.growKey}
                />) : (
                    <div onClick={() => {
                        props.onGrowButtonClick();
                    }} style={{ backgroundColor: '#aad17e' }} className={classes.button}>
                        <p className={classes.text}>
                            GROW
                    </p>
                    </div>
                )}
        </Stack>
    )
};

export default Menu;
