import React from 'react';
import { Dropdown, IDropdownOption, IDropdown } from 'office-ui-fabric-react/lib/';

interface IProps {
    style?: object,
    titleStyle?: object,
    placeholder?: string,
    defaultSelectedKey?: string,
    selectedKey?: string,
    options: IDropdownOption[],
    onChange: (e: React.FormEvent<HTMLDivElement>,  option?: IDropdownOption) => void,
    displayErrStyle?: boolean,
};

const EFDropdown: React.FC<IProps> = props => {
    const dropDownRef = React.createRef<IDropdown>();
    //const [selected, setSelected] = React.useState('');
    const activeBorder = !props.displayErrStyle ? 'rgba(0, 0, 0, 0.11)' : '#E0B4B5';
    const fontColor = '#343f6e';
    const borderColor = !props.displayErrStyle ? 'rgba(0, 0, 0, 0.11)' : '#E0B4B5';;

    const dropdownStyles = {
        dropdown: {
            width: '35.5rem',
            margin: 0,
            color: fontColor,
            selectors: {
                '&:focus:after': {
                    borderColor: 'transparent'
                },
                '&:hover .ms-Dropdown-titleIsPlaceHolder': {
                    borderColor: borderColor,
                    color: fontColor,
                },
                '&:active .ms-Dropdown-titleIsPlaceHolder': {
                    borderColor: borderColor,
                    color: fontColor,
                },
                '&:focus .ms-Dropdown-titleIsPlaceHolder': {
                    borderColor: borderColor,
                    color: fontColor,
                },
                '&:focus .ms-Dropdown-title': {
                    borderColor: borderColor,
                    color: fontColor,
                },
                '&:hover .ms-Dropdown-title': {
                    borderColor: borderColor,
                    color: fontColor,
                },
                '&:active .ms-Dropdown-title': {
                    borderColor: borderColor,
                    color: fontColor,
                },
            },
            ...props.style,
        },
        title: {
            border: `1px solid ${activeBorder}`,
            borderTop: 0,
            borderRight: 0,
            borderLeft: 0,
            borderRadius: 0,
            color: fontColor,
            fontFamily: 'brandon-grotesque',
            fontSize: '1.8rem',
            padding: 0,
            ...props.titleStyle
        },
        dropdownItems: {
            color: '#343f6e',
        },
        dropdownOptionText: {
            color: '#343f6e',
        },
    };

    const onDropdownChange = (e: React.FormEvent<HTMLDivElement>,  option?: IDropdownOption) => {
        //const getDropdown = dropDownRef.current;
        //setSelected(getDropdown ? getDropdown.selectedOptions[0].text : '');
        props.onChange(e, option);
    };

    return (
        <Dropdown 
            options={props.options} 
            placeholder={props.placeholder}
            componentRef={dropDownRef}
            defaultSelectedKey={props.defaultSelectedKey}
            selectedKey={props.selectedKey}
            onChange={onDropdownChange}
            styles={dropdownStyles}
        />
    )
};

EFDropdown.defaultProps = {
    placeholder: '',
};

export default EFDropdown;
