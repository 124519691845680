import React from 'react';
import classes from './InvestmentsGrow.module.css';
import { Stack, Label } from 'office-ui-fabric-react';
import Category from '../../../Scoop/CategoryList/Category';
import { generateColorShadeSet, addAlpha } from '../../../../utils/colorGenerators';
import { useInvestmentsData } from '../../../../hooks/ScoopDashboards/useInvestmentsData';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';
import LineChart from '../../../Charts/LineChart/LineChart';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../../utils/Masking';
import BlurAddMeContainer from '../../../UI/BlurAddMeContainer/BlurAddMeContainer';


interface IProps {
    accBalancesData: any,
    hasAccounts?: boolean,
    onClick?: () => void,
    onClickAddAccs?: () => void,
};

const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowNegative: true,
    allowLeadingZeroes: false,
});


const InvestmentsGrow: React.FC<IProps> = props => {
    let year = new Date().getFullYear();
    const toYearly = false;
    const {
        sortedData,
        chartMonths,
        monthlySums,
        balances
    } = useInvestmentsData(props.accBalancesData, toYearly);

    const doughnutColors = generateColorShadeSet(sortedData && sortedData.length, '#297A63');
    const getBaseCategories = () => {
        if (sortedData) {
            return sortedData.map((acc: any, i: any) => ({
                key: acc.account.accountName,
                text: acc.account.accountName,
                color: doughnutColors[i],
                icon: require('../../../../assets/images/scoop/piggy.svg'),
                focused: false,
                value: acc.historicalBalances[11] ? acc.historicalBalances[11].amount : 0,
            }));
        } else {
            return [];
        };
    };
    let categoryData = [
        ...getBaseCategories()
    ];
    
    const lineChartDataSets = () => {
        if (sortedData && getBaseCategories()) {
            return getBaseCategories().map((acc: any, i: any) => ({
                key: acc.key,
                label: acc.text,
                data: balances[i],
                borderColor: doughnutColors[i],
                fill: false
            }));
        } else {
            return [];
        };
    };

    const categories = categoryData.map(e => (
        <Stack key={e.key}>
            <Category
                id={e.key}
                title={e.text}
                color={addAlpha(e.color, .3)}
                icon={e.icon}
                iconColor={e.color}
                focused={e.focused}
                onClick={() => null}
                value={e.value} 
            />
        </Stack>
    ));
    
    return (
        <>
            {(props.hasAccounts && sortedData.length) ? (
                    <Stack onClick={props.onClick} className={classes.container} horizontalAlign='center' tokens={{childrenGap: 0}}>
                        <Stack verticalAlign='center' horizontalAlign='center'>
                            <EFLabel style={{fontWeight: 'normal', fontSize: '1.8rem'}}>Total Investments</EFLabel>
                            <EFLabel style={{fontWeight: 'normal', fontSize: '1.8rem'}}>{maskValue(Math.round(monthlySums[11]), numberMask)}</EFLabel>
                        </Stack>
                        <Stack className={classes.line_view} verticalAlign='center' horizontalAlign='center'>
                                <LineChart
                                    maxXTicksLimit={2}
                                    maxYTicksLimit={8}
                                    drawOnChartAreaY
                                    xTickFunction={(_: any, index: number) => (index < chartMonths.length / 2) ? year - 1 : year}
                                    datasets={{
                                        labels: chartMonths,
                                        datasets: lineChartDataSets(),
                                    }}
                                />
                            </Stack>
                        <Stack horizontal horizontalAlign='center'>
                            <Stack horizontalAlign='center' tokens={{childrenGap: 6}}>
                                {categories.filter((_: any, i: any) => i < 3)}
                                {categories.length > 3 && <Label className={classes.show_all} onClick={() => null}>Show all</Label>}
                            </Stack>
                        </Stack>
                    </Stack>
            ) : 
            <BlurAddMeContainer 
                onClick={props.onClickAddAccs} 
                backgroundImg={require('../../../../assets/images/grow_invest_preview.png')} 
                label={props.hasAccounts ? 'Please add more accounts' : 'Link your accounts'}
            />}
        </>
    )
};

export default InvestmentsGrow;
