import React, { useState } from 'react';
import classes from './SettingsAccordion.module.css';
import { Stack } from 'office-ui-fabric-react';
import MaskedLabels from './MaskedLabels';

interface IProps {
    id?: any,
    title: string,
    value?: number,
    value2?: number,
    color?: string,
    isNotOpenable?: boolean,
    isHorizontal?: boolean,
};

const SettingsAccordion: React.FC<IProps> = props => {
    const [opened, setOpened] = useState<boolean>(false);
    
    return (
        <div key={props.id} className={`${classes.accordion} ${opened && classes.accordionopened}`}>
            <div onClick={() => props.isNotOpenable ? null : setOpened(!opened)} className={`${classes.line} ${opened && classes.lineopened}`}>
                <Stack style={{width: '100%'}}>
                    <MaskedLabels
                        isHorizontal={props.isHorizontal}
                        isBulleted={false}
                        color={props.color}
                        title={props.title}
                        value={props.value}
                        value2={props.value2}
                    />
                </Stack>
                {props.isNotOpenable ? null : (
                    <span className={`${classes.icon} ${opened && classes.iconopened}`} />
                )}
            </div>
            <div className={`${classes.inner} ${opened && classes.inneropened}`}>
                <Stack 
                    verticalAlign='center' 
                    tokens={{childrenGap: 20}} 
                    className={`${classes.content} ${opened && classes.contentopened}`}
                >
                    {props.children}
                </Stack>
            </div>
        </div>
    )
};

export default SettingsAccordion;
