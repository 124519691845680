import { Card } from "@uifabric/react-cards";
import { useConstCallback } from "@uifabric/react-hooks";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import EFButton from "../../Atomic/EFButton/EFButton";
import EFCodeInput from "../../Atomic/EFCodeInput";
import EFSpinner from "../../Atomic/EFSpinner/EFSpinner";

import classes from "./VerificationInput.module.css";

interface IProps {
  email: string;
  loggedUserfromSignupEmail: string;
  showButton: boolean;
  signUpErrorState: boolean;
  showVerificationInput: boolean;
  isButtonEnabled?: boolean;
  onSubmitHandler?: () => void;
  signUpError?: (show: boolean) => any;
  forgotPasswordFlow?: boolean;
  validateEmailFlow?: boolean;
  validateEmail?: (email: string, code: string, history: any) => any;
  verifyResetPasswordCode?: (email: string, code: string, history: any) => any;
  verifyAuthCode?: (email: string, code: string, history: any) => any;
}

const VerificationInput: React.FC<IProps> = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [loader, setLoader] = useState(false);

  const cc1Ref = useRef(null);
  const cc2Ref = useRef(null);
  const cc3Ref = useRef(null);
  const cc4Ref = useRef(null);

  // Leaving these callbacks to use
  // the useConstCallback hooks
  // as I found them to have, we
  // should look into refactoring them
  const onCodeChange1 = useConstCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue === "") {
        setCode1("");
        return;
      }
      if (!newValue || newValue.length <= 1) {
        setCode1(newValue || "");
        cc2Ref.current.focus();
      }
    }
  );

  const onCodeChange2 = useConstCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue === "") {
        setCode2("");
        return;
      }
      if (!newValue || newValue.length <= 1) {
        setCode2(newValue || "");
        cc3Ref.current.focus();
      }
    }
  );

  const onCodeChange3 = useConstCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      if (newValue === "") {
        setCode3("");
        return;
      }
      if (!newValue || newValue.length <= 1) {
        setCode3(newValue || "");
        cc4Ref.current.focus();
      }
    }
  );

  const validateCode = (
    email: string,
    loggedUserfromSignupEmail: string,
    signUpError?: (show: boolean) => any,
    forgotPasswordFlow?: boolean,
    verifyResetPasswordCode?: (
      email: string,
      code: string,
      history: any
    ) => any,
    verifyAuthCode?: (email: string, code: string, history: any) => any,
    validateEmailFlow?: boolean,
    validateEmail?: (email: string, code: string, history: any) => any
  ) => {
    signUpError && dispatch(signUpError(false));
    setLoader(true);
    cc4Ref.current.blur();
    if (forgotPasswordFlow && !validateEmailFlow) {
      dispatch(
        verifyResetPasswordCode(email, code1 + code2 + code3 + code4, history)
      );
    } else if (validateEmailFlow && validateEmail) {
      dispatch(
        validateEmail(
          email === "" ? loggedUserfromSignupEmail : email,
          code1 + code2 + code3 + code4,
          history
        )
      );
    } else {
      dispatch(
        verifyAuthCode(
          email === "" ? loggedUserfromSignupEmail : email,
          code1 + code2 + code3 + code4,
          history
        )
      );
    }
    setCode1("");
    setCode2("");
    setCode3("");
    setCode4("");
    cc1Ref.current.focus();
  };

  const submitCode = () => {
    if (code1 && code2 && code3 && code4) {
      validateCode(
        props.email,
        props.loggedUserfromSignupEmail,
        props.signUpError,
        props.forgotPasswordFlow,
        props.verifyResetPasswordCode,
        props.verifyAuthCode,
        props.validateEmailFlow,
        props.validateEmail
      );
    }
  };

  useEffect(() => {
    submitCode();
  }, [code4]);

  const onCodeSubmitHandler = () => {
    if (props.showVerificationInput) {
      submitCode();
      return;
    }
    props.onSubmitHandler && props.onSubmitHandler();
  };

  return (
    <>
      <Card className={classes.confirmationCodeCard}>
        <Card.Section
          styles={{
            root: { alignContent: "center", alignItems: "center" },
          }}
        >
          <p>
            <strong>
              {props.forgotPasswordFlow
                ? "Check your email"
                : "Check your device"}
            </strong>
          </p>
          <p className={classes.fieldLabel}>
            {props.forgotPasswordFlow
              ? "Please type the verification code sent to your email"
              : "Please type the verification code sent to your device"}
          </p>
          <Card.Section
            horizontal
            styles={{
              root: {
                alignContent: "center",
                justifyContent: "center",
                marginBottom: "10%",
              },
            }}
            tokens={{ childrenGap: 5 }}
          >
            <EFCodeInput
              id="cc1"
              reference={cc1Ref}
              value={code1}
              onChange={onCodeChange1}
            />
            <EFCodeInput
              id="cc2"
              reference={cc2Ref}
              value={code2}
              onChange={onCodeChange2}
              onBackSpace={() => {
                if (code2 === "") {
                  cc1Ref?.current?.select();
                }
              }}
            />
            <EFCodeInput
              id="cc3"
              reference={cc3Ref}
              value={code3}
              onChange={onCodeChange3}
              onBackSpace={() => {
                if (code3 === "") {
                  cc2Ref?.current?.select();
                }
              }}
            />
            <EFCodeInput
              id="cc4"
              reference={cc4Ref}
              value={code4}
              onChange={(event) => {
                setCode4(event.target.value);
              }}
              onBackSpace={() => {
                if (code4 === "") {
                  cc3Ref?.current?.select();
                }
              }}
            />
          </Card.Section>
          {loader && !props.signUpErrorState && (
            <EFSpinner style={{ margin: "unset" }} />
          )}
          {props.signUpErrorState && (
            <p style={{ margin: "unset" }} className={classes.errorMessage}>
              The validation code is wrong
            </p>
          )}
        </Card.Section>
      </Card>
      {props.showButton ? (
        <>
          <br />
          <EFButton
            id="login-reset"
            isDisabled={props.isButtonEnabled ? false : true}
            text={props.forgotPasswordFlow ? "RESET PASSWORD" : "SIGN IN"}
            onClick={onCodeSubmitHandler}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default VerificationInput;
