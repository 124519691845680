import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useHistory } from "react-router-dom";


import classes from './Summary.module.css';
import CardBadge from './CardBadge/CardBadge';

import summaryWrench from '../../assets/images/summary/summaryWrench.svg';
import summaryAirPods from '../../assets/images/summary/summaryAirPods.svg';
import summaryUser from '../../assets/images/summary/summaryUser.svg';
import progressCompleted from '../../assets/images/summary/progressCompleted.svg';

import estatePlan from '../../assets/images/lifeEvents/family/estate_plan.svg';
import manageDebt from '../../assets/images/lifeEvents/money/manage_debt.svg';
import studentDebt from '../../assets/images/lifeEvents/money/student_debt.svg';
import retire from '../../assets/images/lifeEvents/bigstuff/retire.svg';
import savings from '../../assets/images/lifeEvents/money/savings.svg';

import { useResponsive } from '../../hooks/useResponsive';



interface Props {
    currentUser: any;
    showProfile: () => void;
    cashflowData: any;
}

const getSectionByNumber = (n: number) => {
    return n === 0 ? 'Outer Section' : n === 1 ? 'Middle Section' : 'Inner Section'
}

const conditions = [
    [
        "Add a Kakeibo entry",
        "Add your Retire / Reboot Life Event to your Smart Cashflow",
        "Setup your Smart Cashflow",
        "Upload your Insurance, Wills & Trust documents to your Secure Vault",
        "Add a Student Debt Life Event to your Playlist",
        "Add a goal to your Smart Cashflow"
    ],
    [
        "Add a Nudget",
        "Save your Retire / Reboot Life Event",
        "Setup your Playlist",
        "Add an Insurance Plan",
        "Add a Manage Debt Life Event to your Playlist",
        "Add a savings goal"
    ],
    [
        "Aggregate an account",
        "Add a Retire / Reboot Life Event to your Playlist",
        "Complete your profile",
        "Add a Estate Plan Life Event to your playlist",
        "Aggregate an account",
        "Emergency Fund secure"
    ]
]

const Summary: React.FC<Props> = props => {

    let history = useHistory();

    const [currentBorderColor, setCurrentBorderColor] = useState('');

    const { screenIsAtMost } = useResponsive();

    const navigateToLE = (title: string, img: any, id: string, planId: string) => {
        const payload = {
            status: "NEW",
            name: title,
            icon: [{
                url: img
            }],
            id: "",
            externalId: id,
            relatedPlanId: planId
        }

        history.push({
            pathname: '/connect/lifeevent',
            state: payload
        });
    }

    const renderBudgetingCompletedIcon = () => {
        let completed = false;
        if (props.currentUser.kakeibo.length > 0 &&
            Object.keys(props.currentUser.nudgetSavingGoalsInfo).length > 0 &&
            props.currentUser.connectedAccounts.length > 0) {
            completed = true;
        }
        return completed;
    }

    const renderRetireCompletedIcon = () => {
        let completed = false;
        if (props.cashflowData.filter(info => info.id === 10018569)[0].subCategories[0].source === "LIFE_EVENT" &&
            props.currentUser.lifeEvents.filter(le => le.externalId === '3s7O7PtEaKgjdAML8huqsO' && le.status === "CONFIRMED").length > 0 &&
            props.currentUser.lifeEvents.filter(le => le.externalId === '3s7O7PtEaKgjdAML8huqsO').length > 0) {
            completed = true;
        }
        return completed;
    }

    const renderSetupCompletedIcon = () => {
        let completed = false;
        if (props.cashflowData.filter(info => info.onboarded === true).length > 0 &&
            props.currentUser.plans.filter(p => p.name === 'MY PLAYLIST').length > 0 &&
            props.currentUser.profileCompletionStatus === 100) {
            completed = true;
        }
        return completed;
    }

    const renderEstateCompletedIcon = () => {
        let completed = false;
        if ((props.currentUser.protect && props.currentUser.protect.files && props.currentUser.protect.files.length > 0) &&
            props.currentUser.lifeEvents.filter(le => le.externalId === '7bLtNggDKWOjw1ztuQ3uqq').length > 0) {
            completed = true;
        }
        return completed;
    }

    const renderDebtCompletedIcon = () => {
        let completed = false;
        if (props.currentUser.lifeEvents.filter(le => le.externalId === 'prMyQcowYYyVC8N5XNQHn').length > 0 &&
            props.currentUser.lifeEvents.filter(le => le.externalId === '1zTG17lpX4S66cXfr62Rde').length > 0 &&
            props.currentUser.connectedAccounts.length > 0) {
            completed = true;
        }
        return completed;
    }

    const renderSavingsCompletedIcon = () => {
        let completed = false;
        if (props.cashflowData.filter(info => info.id === 10010143)[0].subCategories
            .find(subCategory => subCategory.values[0].amount > 0 || subCategory.values[1].amount > 0) !== undefined) {
            completed = true;
        }
        return completed;
    }

    return (
        <div>
            <p className={classes.subtitle} style={{ paddingTop: '1rem' }}>A simple snapshot of how you are tracking against</p>
            <p className={classes.subtitle}>your goal, and some tips on what to do next</p>

            <div style={{
                display: 'flex', position: 'fixed', justifyContent: screenIsAtMost("mobile") ? 'space-between' : 'space-around',
                width: screenIsAtMost("mobile") ? '81%' : '82%', marginTop: screenIsAtMost("mobile") ? '9%' : '4%'
            }}>
                <div className={classes.savings} style={{ marginLeft: screenIsAtMost("mobile") ? '' : '16%' }}>
                    <p className={classes.label}>Savings</p>
                    {renderSavingsCompletedIcon() && <img className={classes.completedIcon} src={progressCompleted} alt="completed" />}
                </div>
                <div className={classes.budgeting}>
                    <p className={classes.label}>Budgeting</p>
                    {renderBudgetingCompletedIcon() && <img className={classes.completedIcon} src={progressCompleted} alt="completed" />}
                </div>
            </div>

            <div style={{
                display: 'flex', position: 'fixed', justifyContent: screenIsAtMost("mobile") ? 'space-between' : 'space-around',
                width: screenIsAtMost("mobile") ? '88%' : '94%', marginTop: screenIsAtMost("mobile") ? '41%' : '21%'
            }}>
                <div className={classes.debt} style={{ marginLeft: screenIsAtMost("mobile") ? '-13%' : '' }}>
                    <p className={classes.label}>Debt</p>
                    {renderDebtCompletedIcon() && <img className={classes.completedIcon} src={progressCompleted} alt="completed" />}
                </div>
                <div className={classes.retire}>
                    <p className={classes.label}>Retire</p>
                    {renderRetireCompletedIcon() && <img className={classes.completedIcon} src={progressCompleted} alt="completed" />}
                </div>
            </div>

            <div style={{
                display: 'flex', position: 'fixed', justifyContent: screenIsAtMost("mobile") ? 'space-between' : 'space-around',
                width: screenIsAtMost("mobile") ? '79%' : '82%', marginTop: screenIsAtMost("mobile") ? '76%' : '37%'
            }}>
                <div className={classes.estate} style={{ marginLeft: screenIsAtMost("mobile") ? '-17%' : '5%' }}>
                    <p className={classes.label}>Protect</p>
                    {renderEstateCompletedIcon() && <img className={classes.completedIcon} src={progressCompleted} alt="completed" />}
                </div>
                <div className={classes.setup}>
                    <p className={classes.label}>Setup</p>
                    {renderSetupCompletedIcon() && <img className={classes.completedIcon} src={progressCompleted} alt="completed" />}
                </div>
            </div>

            <div style={{ marginTop: screenIsAtMost("mobile") ? '' : '4rem', marginBottom: screenIsAtMost("mobile") ? '' : '10rem' }}>
                <Doughnut data={{
                    datasets: [{
                        data: [1, 1, 1, 1, 1, 1],
                        backgroundColor: [
                            props.currentUser.kakeibo.length > 0 ? '#E3DBE4' : '#F5F4F1', //User has a Kakeibo entry
                            props.cashflowData.filter(info => info.id === 10018569)[0].subCategories[0].source === "LIFE_EVENT" ? "#C2FCFA" : '#F5F4F1', //User added Retire LE to Cashflow
                            props.cashflowData.filter(info => info.onboarded === true).length > 0 ? "#BDE4FF" : '#F5F4F1', //User went through the Cashflow Setup?
                            props.currentUser.protect && props.currentUser.protect.files && props.currentUser.protect.files.length > 0 ? '#E3EFC8' : '#F5F4F1', //User loaded a estate plan document into our secure vault, in protect. We can only check if user uploaded something to the secure vault
                            props.currentUser.lifeEvents.filter(le => le.externalId === 'prMyQcowYYyVC8N5XNQHn').length > 0 ? '#FFE1A1' : '#F5F4F1', //User has a Student Debt LE
                            '#F5F4F1', //Coming soon
                        ],
                        borderWidth: 3,
                        borderColor: '#FFFFFF',
                        label: [
                            'Budgeting - Outer',
                            'Retire - Outer',
                            'Setup - Outer',
                            'Protect - Outer',
                            'Debt - Outer',
                            'Savings - Outer',
                        ]
                    },
                    {
                        data: [2, 2, 2, 2, 2, 2],
                        backgroundColor: [
                            Object.keys(props.currentUser.nudgetSavingGoalsInfo).length > 0 ? '#D9D0DE' : '#F1F0EC', //User has a nudget entry
                            props.currentUser.lifeEvents.filter(le => le.externalId === '3s7O7PtEaKgjdAML8huqsO' && le.status === "CONFIRMED").length > 0 ? '#95EBE8' : '#F1F0EC', //User has a CONFIRMED Retire LE
                            props.currentUser.plans.filter(p => p.name === 'MY PLAYLIST').length > 0 ? '#90CFFB' : '#F1F0EC', //User has gone through the My Playlist Setup, i.e.: user has a My Playlist entry
                            localStorage.getItem("ef_insuranceDisclaimerAccepted") !== null ? JSON.parse(localStorage.getItem("ef_insuranceDisclaimerAccepted")).filter(e => e.email === props.currentUser.email).length > 0 ? '#D9E8B3' : '#F1F0EC' : '#F1F0EC', //User has accepted Covr Disclaimer
                            props.currentUser.lifeEvents.filter(le => le.externalId === '1zTG17lpX4S66cXfr62Rde').length > 0 ? '#FECF6B' : '#F1F0EC', //User has Manage Debt LE
                            props.currentUser.lifeEvents.filter(le => le.externalId === 'O7v6vw1nqpANUiFn3SK7n' && le.status === "CONFIRMED").length > 0 ? '#FAC5CA' : '#F1F0EC', //User has Savings Goal LE
                        ],
                        borderWidth: 3,
                        borderColor: '#FFFFFF',
                        label: [
                            'Budgeting - Middle',
                            'Retire - Middle',
                            'Setup - Middle',
                            'Protect - Middle',
                            'Debt - Middle',
                            'Savings - Middle',
                        ]
                    }, {
                        data: [3, 3, 3, 3, 3, 3],
                        backgroundColor: [
                            props.currentUser.connectedAccounts.length > 0 ? '#AB9ABD' : '#E7E7E7', //User has connected accounts
                            props.currentUser.lifeEvents.filter(le => le.externalId === '3s7O7PtEaKgjdAML8huqsO').length > 0 ? '#16D3CC' : '#E7E7E7', //User has Retire LE
                            props.currentUser.profileCompletionStatus === 100 ? '#3BA5F0' : '#E7E7E7', //User has 100% profile completition
                            props.currentUser.lifeEvents.filter(le => le.externalId === '7bLtNggDKWOjw1ztuQ3uqq').length > 0 ? '#8FBE33' : '#E7E7E7', //Spreadsheet says Estate Plan Playlist, but for now I think we need to look if the user has the Estate Plan LE
                            props.currentUser.connectedAccounts.length > 0 ? '#FFAE00' : '#E7E7E7', //User has connected accounts
                            props.cashflowData.filter(info => info.id === 10010143)[0].subCategories
                                .find(subCategory => subCategory.values[0].amount > 0 || subCategory.values[1].amount > 0) !== undefined ? '#F0516A' : '#E7E7E7' //User has an identified income source
                        ],
                        borderWidth: 3,
                        borderColor: '#FFFFFF',
                        label: [
                            'Budgeting - Inner',
                            'Retire - Inner',
                            'Setup - Inner',
                            'Protect - Inner',
                            'Debt - Inner',
                            'Savings - Inner',
                        ]
                    }],
                }}
                    options={{
                        legend: {
                            display: false
                        },
                        plugins: {
                            datalabels: {
                                display: false
                            }
                        },
                        maintainAspectRatio: false,
                        cutoutPercentage: 25,
                        tooltips: {
                            displayColors: false,
                            backgroundColor: 'white',
                            caretSize: 0,
                            titleFontStyle: 'bold',
                            titleFontFamily: 'brandon-grotesque',
                            titleFontColor: '#293861',
                            bodyFontColor: '#293861',
                            bodyFontFamily: 'brandon-grotesque',
                            bodyFontStyle: 'bold',
                            bodyFontSize: 16,
                            footerFontSize: 16,
                            footerFontFamily: 'brandon-grotesque',
                            footerFontStyle: 'normal',
                            footerFontColor: '#293861',
                            borderColor: currentBorderColor,
                            borderWidth: 1,
                            callbacks: {
                                title: function (tooltipItem, data) {
                                    return
                                },
                                label: function (tooltipItem, data) {
                                    switch (data.datasets[0].label[tooltipItem.index].split('-')[0]) { //The fact that I can do this here is mindblowing...
                                        case "Budgeting ": {
                                            setCurrentBorderColor('rgb(177,159,221)');
                                            break;
                                        }
                                        case "Retire ": {
                                            setCurrentBorderColor('rgb(9,210,203)');
                                            break;
                                        }
                                        case "Setup ": {
                                            setCurrentBorderColor('rgb(55,156,240)');
                                            break;
                                        }
                                        case "Protection ": {
                                            setCurrentBorderColor('rgb(171,205,88)');
                                            break;
                                        }
                                        case "Insurance ": {
                                            setCurrentBorderColor('rgb(171,205,88)');
                                            break;
                                        }
                                        case "Debt ": {
                                            setCurrentBorderColor('rgb(251,175,9)');
                                            break;
                                        }
                                        case "Savings ": {
                                            setCurrentBorderColor('rgb(243,121,141)');
                                            break;
                                        }
                                    }
                                    return data.datasets[0].label[tooltipItem.index].split('-')[0];
                                },
                                footer: function (tooltipItem, data) {

                                    const chartLevel = tooltipItem[0].datasetIndex;
                                    const chartType = tooltipItem[0].index;

                                    return conditions[chartLevel][chartType];
                                }
                            }
                        }
                    }}
                    width={screenIsAtMost('mobile') ? 250 : 300}
                    height={screenIsAtMost('mobile') ? 300 : 450}
                    getElementAtEvent={e => {
                        if (e && e[0] && e[0]._datasetIndex >= 0 && e[0]._model.label) {
                            switch (e[0]._datasetIndex) {
                                case 0: { //Outer Chart
                                    switch (e[0]._model.label.split('-')[0]) {
                                        case "Budgeting ": {
                                            history.push('/connect/getorganized/kakeibo');
                                            break;
                                        }
                                        case "Retire ": {
                                            history.push('/connect/smartcashflow');
                                            break;
                                        }
                                        case "Setup ": {
                                            history.push('/connect/smartcashflow');
                                            break;
                                        }
                                        case "Protection ": {
                                            if (!(props.currentUser.protect && props.currentUser.protect.files.length > 0)) {

                                            }
                                            history.push('/protect/securevault');
                                            break;
                                        }
                                        case "Debt ": {
                                            const studentDebtArray = props.currentUser.lifeEvents.filter(le => le.externalId === "prMyQcowYYyVC8N5XNQHn");
                                            if (studentDebtArray.length === 0) {
                                                navigateToLE('Student Debt', studentDebt, 'prMyQcowYYyVC8N5XNQHn', props.currentUser.plans[0].planId);
                                            }
                                            break;
                                        }
                                        case "Savings ": {
                                            alert("Coming soon!");
                                            break;
                                        }
                                    }
                                    break;
                                }
                                case 1: { //Middle Chart
                                    switch (e[0]._model.label.split('-')[0]) {
                                        case "Budgeting ": {
                                            history.push('/connect/getorganized/nudget');
                                            break;
                                        }
                                        case "Retire ": {
                                            const retireLEArray = props.currentUser.lifeEvents.filter(le => le.externalId === '3s7O7PtEaKgjdAML8huqsO' && le.status === "CONFIRMED");
                                            const newRetireLEArray = props.currentUser.lifeEvents.filter(le => le.externalId === '3s7O7PtEaKgjdAML8huqsO');
                                            if (retireLEArray.length === 0) {
                                                if (newRetireLEArray.length === 0) {
                                                    navigateToLE('Retire / Reboot', retire, '3s7O7PtEaKgjdAML8huqsO', props.currentUser.plans[0].planId);
                                                }
                                                else {
                                                    history.push('/connect/lifeevent/' + newRetireLEArray[0].id);
                                                }
                                            }
                                            break;
                                        }
                                        case "Setup ": {
                                            props.currentUser.plans.filter(p => p.name === 'MY PLAYLIST').length > 0 ?
                                                history.push('/connect/myplaylist') : history.push('/myplansetup')
                                            break;
                                        }
                                        case "Protection ": {
                                            history.push('/protect/insurance');
                                            break;
                                        }
                                        case "Debt ": {
                                            const manageDebtArray = props.currentUser.lifeEvents.filter(le => le.externalId === '1zTG17lpX4S66cXfr62Rde');
                                            if (manageDebtArray.length === 0) {
                                                navigateToLE('Manage Debt', manageDebt, '1zTG17lpX4S66cXfr62Rde', props.currentUser.plans[0].planId);
                                            }
                                            break;
                                        }
                                        case "Savings ": {
                                            const savingsGoalArray = props.currentUser.lifeEvents.filter(le => le.externalId === 'O7v6vw1nqpANUiFn3SK7n' && le.status === "CONFIRMED");
                                            if (savingsGoalArray.length === 0) {
                                                navigateToLE('Saving Goal', savings, 'O7v6vw1nqpANUiFn3SK7n', props.currentUser.plans[0].planId);
                                            }
                                            break;
                                        }
                                    }
                                    break;
                                }
                                case 2: { //Inner Chart 
                                    switch (e[0]._model.label.split('-')[0]) {
                                        case "Budgeting ": {
                                            if (props.currentUser.connectedAccounts.length === 0) {
                                                history.push('/aggregate');
                                            }
                                            break;
                                        }
                                        case "Retire ": {
                                            const retireLEArray = props.currentUser.lifeEvents.filter(le => le.externalId === '3s7O7PtEaKgjdAML8huqsO');
                                            if (retireLEArray.length === 0) {
                                                navigateToLE('Retire / Reboot', retire, '3s7O7PtEaKgjdAML8huqsO', props.currentUser.plans[0].planId);
                                            }
                                            break;
                                        }
                                        case "Setup ": {
                                            props.showProfile();
                                            break;
                                        }
                                        case "Protection ": {
                                            const estatePlanArray = props.currentUser.lifeEvents.filter(le => le.externalId === '7bLtNggDKWOjw1ztuQ3uqq');
                                            if (estatePlanArray.length === 0) {
                                                navigateToLE('Estate Plan', estatePlan, '7bLtNggDKWOjw1ztuQ3uqq', props.currentUser.plans[0].planId);
                                            }
                                            break;
                                        }
                                        case "Debt ": {
                                            if (props.currentUser.connectedAccounts.length === 0) {
                                                history.push('/aggregate');
                                            }
                                            break;
                                        }
                                        case "Savings ": {
                                            if (props.cashflowData.filter(info => info.id === 10010143)[0].subCategories
                                                .find(subCategory => subCategory.values[0].amount > 0 || subCategory.values[1].amount > 0) === undefined) {
                                                history.push('/connect/getorganized/scoopdashboards');
                                            }
                                            break;
                                        }
                                    }
                                    break;
                                }
                            }

                        }
                    }}
                />
            </div>
            {false && <div className={screenIsAtMost('mobile') ? classes.badgesContainerMobile : classes.badgesContainer}>
                <CardBadge img={summaryWrench} title="Tools" onClick={() => alert("Coming soon!")} />
                <CardBadge img={summaryAirPods} title="Online Assistance" onClick={() => alert("Coming soon!")}
                    customStyle={{ marginTop: screenIsAtMost("mobile") ? '2rem' : '', marginBottom: screenIsAtMost("mobile") ? '2rem' : '' }} />
                <CardBadge img={summaryUser} title="Advisor on your time" onClick={() => alert("Coming soon!")} />
            </div>}
        </div>
    )
};

export default Summary;