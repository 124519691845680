import React from 'react';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import MaskedLabelPair from '../../UI/MaskedLabelPair';
import { Stack } from 'office-ui-fabric-react';
import Delta from '../Delta/Delta';
// import classes from 'DashboardHeader.module.css';

interface IProps {
    title?: string,
    value?: number,
    prevVal?: number,
    valueColor?: string,
};

const DashboardHeader: React.FC<IProps> = props => {
    return (
        <Stack>
            <EFLabel style={label}>
                {props.title}
            </EFLabel>
            <Stack horizontal verticalAlign='center' tokens={{childrenGap: '1.7rem'}}>
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='$'
                    suffix=''
                    title={''}
                    titleStyle={{color: props.valueColor, fontWeight: 'normal', fontSize: '2.4rem', padding: 'unset'}}
                    value={props.value}
                    color={props.valueColor}
                    valueFontSize={'2.4rem'}
                />
                {props.prevVal && <Delta newVal={props.value} prevVal={props.prevVal} />}
            </Stack>
        </Stack>
    );
};

const label = {
    fontSize: '2.8rem', 
    margin: '.5rem 0'
};

export default DashboardHeader;
