export const RETIREMENT = "RETIREMENT";
export const COLLEGE_PLAN = "COLLEGE_PLAN";
export const BUY_HOUSE = "BUY_HOUSE";
export const BUY_CAR = "BUY_CAR";
export const STUDENT_DEBT = "STUDENT_DEBT";
export const MANAGE_DEBT = "MANAGE_DEBT";
export const AFFORDABILITY = "AFFORDABILITY";
export const CAREER_GOAL = "CAREER_GOAL";
export const SAVING_GOAL = "SAVING_GOAL";

export const retirementChangeState = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: RETIREMENT,
                retireReboot: data
            })
        }
        catch (error) {
            console.error(error)
        }
    }
}

export const collegePlanChangeState = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: COLLEGE_PLAN,
                collegePlan: data
            })
        }
        catch (error) {
            console.error(error)
        }
    }
}

export const buyHouseChangeState = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: BUY_HOUSE,
                buyHouse: data
            })
        }
        catch (error) {
            console.error(error)
        }
    }
}

export const buyCarChangeState = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: BUY_CAR,
                buyCar: data
            })
        }
        catch (error) {
            console.error(error)
        }
    }
}

export const studentDebtChangeState = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: STUDENT_DEBT,
                studentDebt: data
            })
        }
        catch (error) {
            console.error(error)
        }
    }
}

export const manageDebtChangeState = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: MANAGE_DEBT,
                manageDebt: data
            })
        }
        catch (error) {
            console.error(error)
        }
    }
}

export const affordabilityChangeState = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: AFFORDABILITY,
                affordability: data
            })
        }
        catch (error) {
            console.error(error)
        }
    }
}

export const careerGoalChangeState = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: CAREER_GOAL,
                careerGoal: data
            })
        }
        catch (error) {
            console.error(error)
        }
    }
}

export const savingGoalChangeState = (data: any) => {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: SAVING_GOAL,
                savingGoal: data
            });
        }
        catch (error) {
            console.error(error);
        }
    }
}
