/* eslint-disable @typescript-eslint/no-array-constructor */
import { currMonth, elevenMonthsAgo, currJan, getMonths } from '../../utils/dateTools';
import moment from 'moment';
import { generateColorShadeSet } from '../../utils/colorGenerators';

// Generate month intervals based on "monthly" (as opposed to yr to date)
const { fullMonths: fullMonthsM, chartMonths, dates } = getMonths(elevenMonthsAgo, currMonth);
const { fullMonths: fullMonthsY, chartMonths: chartMonthsYrly } = getMonths(currJan, currMonth);

export const useIncomeData = (categories: any, transactionsData: any, toYearly: boolean, monthByIdx?: number) => {
    const fullMonths = toYearly ? fullMonthsY : fullMonthsM;
    const baseCategories = [
        {
            id: 'EF21',
            title: 'Income',
            color: '#0786C1',
            icon: require('../../assets/images/scoop/income_interests.svg'),
        },
        // {
        //     id: 'EF20',
        //     title: 'Salary',
        //     color: '#31A2FF',
        //     icon: require('../../assets/images/scoop/income_salary.svg'),
        // },
    ];

    //V2
    const baseData = transactionsData.filter((e: any) => e.dashboards.find((db: any) => db === 'income') && e.categoryType === "INCOME");
    const transDataByCategory = categories?.map((x: any) => baseData && baseData.filter((e: any) => x.id === e.customCategory.id));

    // // V1
    // const efCategoryIds = baseCategories.map((e: any) => e.id);
    // const baseData = transactionsData.filter((e: any) => e.dashboards.find((db: any) => db === 'income'));
    // const dataByEFId = efCategoryIds.map((id: any) => baseData && baseData.filter((e: any) => id === e.efCategory.categoryId));

    const filterDataPerType= transDataByCategory && transDataByCategory.map((id: any) => id && id.map((x: any) => { 
        return { date: x.date, amount: x.amount.amount, category: x.category, label: x.description.original } 
     }));

     // Use to populate transaction list
     const amountsBySortedMonths = filterDataPerType && filterDataPerType.map((type: any) => type && type.reduce((a: any, c: any) => {
        const m = parseInt(c.date.split(('-'))[1]) - 1;
        (a[m]) ? a[m].data.push(c) : a[m] = { date: c.date, data: [c] };
        return a;
    }, []).sort((a: any, b: any) => b.date.localeCompare(a.date)));

    const x = amountsBySortedMonths.map((type: any) => 
        dates.map((e: any) => {
            const currM = parseInt(e.split(('-'))[1]) - 1;
            const targetObj = type && type.find((x: any) => x && parseInt(x.date.split(('-'))[1]) - 1 === currM);
            if (targetObj) {
                return {date: targetObj.date, data: targetObj.data};
            } else {
                return {date: e, data: [{amount: 0}]};
            }
    }));

    const y = x.map((type: any) => type.filter((e: any) => e.date >= currJan.format('YYYY-MM-01') && e.date < (moment().set('date', 1).add(1, 'months')).format('YYYY-MM-01')));

    const sortedData = toYearly ? y : x;

    // Amount totals for 12 months per EFId type: Use to populate category buttons (for monthly)
    const amountsSummedByType = sortedData && sortedData.map((type: any) => {
        return type && type.map((e: any) => e.data.map((x: any) => x.amount).reduce((a: any, c: any) => a += c));
    });

    // Map transactions by EF ID and contained in month sets
    const transactionsByEFId = sortedData && sortedData.map((category: any) => category && category.map((e: any) => e.data.reduce((a: any, c: any) => {
        const found = a.find((e: any) => e.category === c.category);
        const value = { label: c.label, amount: c.amount, date: c.date };
        if (!found) {
            a.push({ category: c.category, data: [value] });
        } else {
            found.data.push(value);
        }
        return a;
    }, [])));

    // Find totals for different yodlee categories and map to category data sets
    const transactionData = transactionsByEFId && transactionsByEFId.map((category: any) => category && category.map((month: any) => month && month.map((e: any) => {
        const total = e.data.reduce((a: any, c: any) => a += c.amount, 0)
        return {
            category: e.category,
            total: total,
            data: e.data,
        }
    })));

    // Use to populate doughnut chart (for monthly)
    const getMonthlyAmountsForIndex = (_idx: number) => {
        const idxType = categories?.findIndex((e: any) => e.name === baseCategories[0].title);
        // when we have more categories use "category" like expenses db instead of baseCategories[0].title
        const values = transactionData[idxType] && transactionData[idxType][monthByIdx] ? transactionData[idxType][monthByIdx].map((e: any) => e.total) : [];
        return {
            values: values,
            colors: generateColorShadeSet(values.length, baseCategories[0].color),
            labels: transactionData[idxType] && transactionData[idxType][monthByIdx] ? transactionData[idxType][monthByIdx].map((e: any) => e.category) : [],
        }
    };

    // Use to populate bar chart & title label (for monthly)
    const amountsTotalsMonthly = amountsSummedByType[0] && amountsSummedByType[0].map((amt: any, i: number) => {
        const val = amountsSummedByType[1] && amountsSummedByType[1][i];
        const sum = amt + (val ? val : 0);
        return sum
    });

    // Use to populate category buttons + populates doughnut chart (for yr to date)
    const yrToDateByType = amountsSummedByType && amountsSummedByType.map((type: any) => type && type.reduce((a: any, c: any) => a += c, 0));
    
    // Use to set title label (for yr to date)
    const totalYrToDate = (yrToDateByType[0] && yrToDateByType[0]) + (yrToDateByType[1] && yrToDateByType[1]);

    return {
        fullMonths,
        chartMonths,
        chartMonthsYrly,
        baseCategories,
        baseData,
        transactionData,
        sortedData,
        amountsSummedByType,
        getMonthlyAmountsForIndex,
        amountsTotalsMonthly,
        yrToDateByType,
        totalYrToDate,
    };
}