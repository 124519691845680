import React from 'react';
import EFCard from '../Atomic/EFCard/EFCard';

import animations from "../../utils/animations.module.css";
import classes from './SummaryWidget.module.css';
import summaryWidget from '../../assets/images/summary/summaryWidget.svg';

interface Props {
    onClick?: () => void;
}

const SummaryWidget: React.FC<Props> = ( props ) => {
    return (
        <EFCard
            dataTourId="summary"
            stackClassName={animations.cardShadowExpand}
            title="YOUR PROGRESS"
            onClick={props.onClick}
            style={{ cursor: "pointer" }}
        >
            <div className={classes.container}>
                <img
                    alt="summary"
                    style={{ width: "100%" }}
                    src={summaryWidget}
                />
            </div>
        </EFCard>
    )
};

export default SummaryWidget;