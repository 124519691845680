import { useState, useEffect } from 'react'

export type Orientation = 'landscape' | 'portrait'

export interface Screen {
  size: string 
  screenIsAtLeast(breakpoint: keyof Breakpoint, andOrientation?: Orientation): boolean
  screenIsAtMost(breakpoint: keyof Breakpoint, andOrientation?: Orientation): boolean
  howManyCards(size: number): number,
  width: number
}

interface Breakpoint {
    mobile: number,
    tabPort: number,
    tabLand: number,
    desktop: number,
    bigDesktop: number
}

export const breakpoints: Breakpoint = { mobile: 0, tabPort: 600, tabLand: 900, desktop: 1200, bigDesktop: 1800 };
export const cashflowCardsBreakpoints = {oneCardMax: 450, oneCardThirdMax: 550, oneCardSixthMax: 650, twoCardsMax: 750, twoCardsThird: 850, twoCardsSixth: 950, threeCards: 1050}
export const useResponsive = (): Screen => {
  const sizes = Object.entries(breakpoints).sort(([, aSize], [, bSize]) => bSize - aSize)
  if (sizes[sizes.length - 1][1] !== 0) {
    console.warn('fixing', sizes[sizes.length - 1][0], 'size which should be 0')
    sizes[sizes.length - 1][1] = 0
  }

  const getScreen = (): Screen => {
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const size = sizes.find(([_, size]) => size <= width)[0]
    return {
      size,
      screenIsAtLeast(breakpoint) {
        return width >= breakpoints[breakpoint]
      },
      screenIsAtMost(breakpoint) {
          let topBreakpoint: number = breakpoints["mobile"];
          switch(breakpoint) {
              case "mobile": {
                  topBreakpoint = breakpoints["tabPort"];
                  break;
              }
              case "tabPort": {
                  topBreakpoint = breakpoints["tabLand"];
                  break;
              }
              case "tabLand": {
                  topBreakpoint = breakpoints["desktop"];
                  break;
              }
              case "desktop": {
                  topBreakpoint = breakpoints["bigDesktop"];
                  break;
              }
              case "bigDesktop": {
                  topBreakpoint = 99999;
              }
          }

        return width < topBreakpoint
      },
      howManyCards(size: number) {
        let cards = 1;
        if (450 < size && size <= 550) {
          cards = 1.3;
        }
        if (550 < size && size <= 650) {
          cards = 1.6;
        }
        if (650 < size && size <= 750) {
          cards = 2;
        }
        if (750 < size && size <= 850) {
          cards = 2.3;
        }
        if (850 < size && size <= 950) {
          cards = 2.6;
        }
        if (950 < size && size <= 1150) {
          cards = 2;
        }
        if (1150 < size && size <= 1350) {
          cards = 2.3;
        }
        if (1350 < size && size <= 1550) {
          cards = 2.6;
        }
        if (1550 < size && size <= 1750) {
          cards = 3;
        }
        if (1750 < size && size <= 14950) {
          cards = 3.3;
        }
        if (1950 < size && size <= 2150) {
          cards = 3.6;
        }
        if (2150 < size) {
          cards = 4;
        }
        return cards;
      },
      width
    }
  }

  const [screen, setScreen] = useState(getScreen())

  useEffect(
    () => {
      const onResize = () => {
        const current = getScreen()
        if (current.size !== screen.size || current.width !== screen.width) {
          setScreen(current)
        }
      }

      window.addEventListener('resize', onResize)

      return () => {
        window.removeEventListener('resize', onResize)
      }
    },
    [screen]
  )

  return screen
}
