import { SAVE_USER, DELETE_USER, SAVE_PROFILES } from '../actions/Data';
import { isCanonical } from '../../utils/global';

const initialState = {
    profiles: [],
    currentProfile: null,
    realUser: null
};

const dataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SAVE_USER: {
            if(isCanonical(action.user.name)) {
                return {
                    ...state,
                    currentProfile: action.user
                }
            }
            else {
                return {
                    ...state,
                    realUser: action.user
                }
            }
        }
        case DELETE_USER: {
            return {
                ...state,
                realUser: null,
                currentProfile: null
            }
        }
        case SAVE_PROFILES: {
            return {
                ...state,
                profiles: action.profiles
            }
        }
        default: {
            return state;
        }
    }
}

export default dataReducer;