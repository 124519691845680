import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Stack } from "office-ui-fabric-react";

import ProfileSwitcher from "../../components/UI/ProfileSwitcher/ProfileSwitcher";
import NavigationView from "../../components/UI/NavigationView";
import DashboardContainer from "../../components/UI/DashboardContainer/DashboardContainer";
import EFCard from "../../components/Atomic/EFCard/EFCard";
import Backdrop from "../../components/Atomic/Backdrop/Backdrop";
import { useResponsive } from "../../hooks/useResponsive";
import { handleSideNavigation, isCanonical } from '../../utils/global';

import maleAvatar from "../../assets/images/maleAvatar.svg";
import staceyAvatar from "../../assets/images/staceyAvatar.png";
import rosaAvatar from "../../assets/images/rosaAvatar.png";
import darylAvatar from "../../assets/images/darylAvatar.png";
import martinAvatar from "../../assets/images/martinAvatar.svg";
import eveAvatar from "../../assets/images/eveAvatar.svg";

import classes from "./GrowDashboardPage.module.css";

import InvestmentsGrow from "../../components/Grow/Dashboards/Investments/InvestmentsGrow";
import RetirementGrow from "../../components/Grow/Dashboards/Retirement/RetirementGrow";
import SavingsGrow from "../../components/Grow/Dashboards/Savings/SavingsGrow";
import Tweets from "../../components/Grow/Tweets/Tweets";
import AddAccountsModal from "../../components/UI/Modals/AddAccountsModal/AddAccountsModal";
import { usePersistentApolloState } from "../../hooks/Apollo/usePersistentApolloState";

import OverviewTour from "../../components/TourGuides/Grow/OverviewTour";
import { eUserSettings } from "../../components/UserSettings/UserSettings";
import { connectChangeState } from "../../store/actions/Screens";
import { getJobInfo, removeCurrentJob } from "../../store/actions/CareerPlanning";
import Greeting from "../../components/UI/Greeting";
import TagManager from 'react-gtm-module'

const GrowDashboardPage: React.FC<{}> = () => {
  const { screenIsAtLeast, screenIsAtMost } = useResponsive();
  const user = useSelector((state: any) => state.session.user);
  const realUser = useSelector((state: any) => state.data.realUser);
  const numConnectedAccounts =
    Object.keys(user).length > 0 ? user.connectedAccounts.length : 0;

  let history = useHistory();
  const dispatch = useDispatch();

  const { state: balancesData } = usePersistentApolloState("balancesState");

  const [openModal, setOpenModal] = useState(false);
  const [openAccsModal, setAccsModal] = useState(false);
  const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);
  const [currentView, setCurrentView] = useState('Overview');
  const [showCarousel, setShowCarousel] = useState(true);

  const [profileTab, setProfileTab] = useState(eUserSettings.PROFILE);

  // Google analytics set up for page
  const tagManagerArgs = {
    dataLayer: {
        userId: realUser?.trackingId,
        page: 'Grow'
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  const openConnectedAccounts = () => {
    if (isCanonical(user.name)) return;

    setProfileTab(eUserSettings.CONNECTED_ACCOUNTS);
    setShowProfileSwitcher(true);
    setShowCarousel(false);
  };

  const handleModal = () => {
    setOpenModal(!openModal);
    window.scrollTo(0, 0);
  };

  const handleAccsModal = () => {
    setAccsModal(!openAccsModal);
    window.scrollTo(0, 0);
  };

  let profiles = [
    {
      name: "Stacey",
      img: staceyAvatar,
      label: "Planning for College",
      profileNumber: 2,
    },
    {
      name: "Rosa",
      img: rosaAvatar,
      label: "Begin Carrer",
      profileNumber: 3,
    },
    {
      name: "Daryl",
      img: darylAvatar,
      label: "Tools for Parents",
      profileNumber: 4,
    },
    {
      name: "Martin",
      img: martinAvatar,
      label: "Buy a House",
      profileNumber: 5,
    },
    {
      name: "Eve",
      img: eveAvatar,
      label: "Planning for Retirement",
      profileNumber: 6,
    },
  ];

  let content = null;

  if (Object.keys(user).length > 0) {
    let selectCardContent = {
      name: user.name,
      img: !user.anonymized
        ? maleAvatar
        : user.name.length > 0
          ? profiles.filter(
            (profile) =>
              profile.name.toLowerCase() === user.name.toLowerCase()
          )[0].img
          : "",
      label: !user.anonymized
        ? ""
        : user.name.length > 0
          ? profiles.filter(
            (profile) =>
              profile.name.toLowerCase() === user.name.toLowerCase()
          )[0].label
          : "",
      profileNumber: !user.anonymized
        ? 2
        : profiles.filter(
          (profile) =>
            profile.name.toLowerCase() === user.name.toLowerCase()
        )[0].profileNumber - 1
    };

    content = (
      <div
        className={`${screenIsAtMost('tabLand') ? classes.containerMobile : [classes.container, classes.greenBackground].join(' ')}`}
      >

        <OverviewTour />

        {openModal && <Backdrop onClick={handleModal} />}

        {openAccsModal && (
          <Backdrop
            customStyle={{ overflowY: "scroll" }}
            onClick={handleAccsModal}
          >
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              style={{ width: "100%", margin: "10rem" }}
            >
              <AddAccountsModal onClickExit={handleAccsModal} />
            </Stack>
          </Backdrop>
        )}

        {showProfileSwitcher && (
          <Backdrop
            customStyle={{
              overflow: "auto",
            }}
            onClick={() => {
              setShowCarousel(true);
              setProfileTab(eUserSettings.PROFILE);
              setShowProfileSwitcher(false);
            }}
          >
            <ProfileSwitcher
              startingProfile={selectCardContent.profileNumber}
              onSelectedProfile={(name: string) => {
                if (name !== "" && name !== "STACEY") {
                  dispatch(removeCurrentJob());
                }
                else {
                  if (name === "STACEY") {
                    dispatch(getJobInfo(15 - 1299.08));
                  }
                  else {
                    const careerGoalLE = realUser.lifeEvents.find(
                      (le: any) => le.externalId === "6ceIi2ikmezTJtxIDEuRsg" && le.status !== "NEW"
                    );
                    if (careerGoalLE) {
                      const getOnetCode = careerGoalLE.customData.selectedCareerOnetCode;
                      dispatch(getJobInfo(getOnetCode));
                    } else {
                      dispatch(removeCurrentJob());
                    }
                  }
                }
                setProfileTab(eUserSettings.PROFILE);
                setShowProfileSwitcher(false);
              }}
              profileTab={profileTab}
              showCarousel={showCarousel}
              onClose={() => {
                setShowCarousel(true);
                setShowProfileSwitcher(false)}}
            />
          </Backdrop>
        )}

        <div
          style={{ flexDirection: screenIsAtMost('tabLand') ? 'column' : 'row', alignItems: screenIsAtMost('tabLand') ? 'center' : '' }}
          className={classes.content}
        >

          {screenIsAtLeast("desktop") && (
            <NavigationView
              profile={isCanonical(user.name)}
              connectedAccounts={user.connectedAccounts.length}
              growKey={"Overview"}
              onGrowLinkClick={(event, item) => {
                handleSideNavigation(item.name, history)
              }}
              onConnectButtonClick={() => history.push("/connect")}
              onProtectButtonClick={() => history.push("/protect/securevault")}
              onConnectAccountClick={openConnectedAccounts}
              currentDashboard="GROW"
              onSelectBubleClickHandler={() => {
                setProfileTab(eUserSettings.PROFILE);
                setShowProfileSwitcher(true);
              }}
            />
          )}

          {screenIsAtMost("tabLand") &&
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '85%', paddingTop: '3rem' }}>

              <Greeting
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={user.connectedAccounts.length}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                showOnlySalutation
                onSelectBubleClickHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
              />

              <Greeting
                hideMenuLogo
                hideSalutation
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={user.connectedAccounts.length}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
              />
            </div>

          }

          <DashboardContainer childrenGap={30}>
            <div
              style={{
                width: "100%", display: 'flex',
                justifyContent: 'space-between', alignItems: 'center'
              }}
            >
              {!screenIsAtMost("tabLand") && <Greeting
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={user.connectedAccounts.length}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                showOnlySalutation
              />}

              {!screenIsAtMost("tabLand") && <Greeting
                hideSalutation
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={user.connectedAccounts.length}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
              />}

            </div>
            <div className={classes.viewContainer}>
              <Stack horizontal={screenIsAtMost("tabLand") ? false : true}>
                <EFCard
                  style={{ height: "45rem" }}
                  stackClassName={`${classes.cardShadow} ${classes.scoopCard}`}
                  title="INVESTMENTS"
                >
                  <InvestmentsGrow
                    accBalancesData={balancesData}
                    hasAccounts={numConnectedAccounts !== 0}
                    onClick={() => {
                      dispatch(connectChangeState('scoop'));
                      history.push("/connect/getorganized/scoopdashboards", { selectedDashboard: "investment" })
                    }}
                    onClickAddAccs={handleAccsModal}
                  />
                </EFCard>
                <EFCard
                  style={{ height: "45rem" }}
                  stackClassName={`${classes.cardShadow} ${classes.scoopCard}`}
                  title="RETIREMENT"
                >
                  <RetirementGrow
                    accBalancesData={balancesData}
                    hasAccounts={numConnectedAccounts !== 0}
                    onClick={() => {
                      dispatch(connectChangeState('scoop'));
                      history.push("/connect/getorganized/scoopdashboards", { selectedDashboard: "retirement" })
                    }}
                    onClickAddAccs={handleAccsModal}
                  />
                </EFCard>
                <EFCard
                  style={{ height: "45rem" }}
                  stackClassName={`${classes.cardShadow} ${classes.scoopCard}`}
                  title="SAVINGS"
                >
                  <SavingsGrow
                    accBalancesData={balancesData}
                    transactionsData={[]}
                    hasAccounts={numConnectedAccounts !== 0}
                    onClick={() => {
                      dispatch(connectChangeState('scoop'));
                      history.push("/connect/getorganized/scoopdashboards", { selectedDashboard: "saving" })
                    }}
                    onClickAddAccs={handleAccsModal}
                  />
                </EFCard>
              </Stack>
              <Stack style={{ marginRight: screenIsAtMost("tabLand") ? '' : '20%', marginTop: '5rem' }} tokens={{ childrenGap: '3.5rem' }}>
                <Tweets />
              </Stack>
            </div>
          </DashboardContainer>
        </div>
      </div>
    );
  }

  return content;
};

export default GrowDashboardPage;
