import React, { useState } from "react";
import { Stack, IChoiceGroupOption } from "office-ui-fabric-react/lib/";
import EFStepperInput from "../../UI/EFStepperInput/EFStepperInput";
import CalculatorSetup from "../../UI/CustomModalCard/CustomModalCard";
import EFLabel from "../../Atomic/EFLabel/EFLabel";
import Modal from "react-modal";
import Carousel from "@brainhubeu/react-carousel";
import EFButton from "../../Atomic/EFButton/EFButton";
import CompletedOnboardingCard from "../Common/CompletedOnboardingCard/CompletedOnboardingCard";
import EFSlider from "../../UI/EFSlider/EFSlider";
import EFChoiceGroup from "../../UI/EFChoiceGroup/EFChoiceGroup";
import ToggleableEFStepperInput from "../../UI/ToggleableEFStepperInput/ToggleableEFStepperInput";
import { useResponsive } from "../../../hooks/useResponsive";

import "@brainhubeu/react-carousel/lib/style.css";

interface IProps {
  handleModal?: any;
  setyearsToEnrollment?: (val: number) => void;
  setyearsAttending?: (val: number) => void;
  handleCollegeType?: (e: any, option?: IChoiceGroupOption) => void;
  tuition: number;
  setTuition?: (val: number) => void;
  setToggleRoom?: any;
  room: number;
  setRoom?: (val: number) => void;
  setTransportation?: (val: number) => void;
  setEntertainment?: (val: number) => void;
  setMedical?: (val: number) => void;
  setOther?: (val: number) => void;
}

const CollegePlanOnboarding: React.FC<IProps> = (props) => {
  const { screenIsAtLeast } = useResponsive();
  const title = "COLLEGE PLAN";
  const defaultYearsToEnrollment = 18;
  const defaultYearsAttending = 4;
  const defaultToggleRoom = true;
  const defaultTransportation = 1000;
  const defaultEntertainment = 2500;
  const defaultMedical = 0;
  const defaultOther = 500;

  const [modalIsOpen, setModalIsOpen] = useState(props.handleModal.showModal);
  const [carouselValue, setCarouselValue] = useState(0);
  const [finished, setFinished] = useState(false);

  // Card 1
  const [yearsToEnrollment, setYearsToEnrollment] = useState(0);
  const [enrollmentErr, setEnrollmentErr] = useState(false);
  const [yearsAttending, setYearsAttending] = useState(0);
  const [yearsAttendingErr, setYearsAttendingErr] = useState(false);

  // Card 2
  const [schoolChosen, setSchoolChosen] = useState(false);
  const [schoolTypeErr, setSchoolTypeErr] = useState(false);
  const [roomToggle, setRoomToggle] = useState(false);

  // Card 3
  const [transportation, setTransportation] = useState(0);
  const [entertainment, setEntertainment] = useState(0);
  const [medical, setMedical] = useState(0);
  const [other, setOther] = useState(0);

  const onCompleteCard1 = () => {
    let nextCard = 1;
    if (yearsToEnrollment === 0) {
      setEnrollmentErr(true);
      nextCard = 0;
    }
    if (yearsAttending === 0) {
      setYearsAttendingErr(true);
      nextCard = 0;
    }
    setCarouselValue(nextCard);
  };

  const onSkipCard1 = () => {
    setYearsToEnrollment(defaultYearsToEnrollment);
    setEnrollmentErr(false);
    setYearsAttending(defaultYearsAttending);
    setYearsAttendingErr(false);
    setCarouselValue(1);
  };

  const onCompleteCard2 = () => {
    let nextCard = 2;
    if (schoolChosen === false) {
      setSchoolTypeErr(true);
      nextCard = 1;
    }
    if (roomToggle === false) {
      props.setRoom(0);
    }
    setCarouselValue(nextCard);
  };

  const onSkipCard2 = () => {
    setSchoolTypeErr(false);
    setRoomToggle(defaultToggleRoom);
    setCarouselValue(2);
  };

  const onCompleteCard3 = () => {
    setFinished(true);
  };

  const onSkipCard3 = () => {
    setTransportation(defaultTransportation);
    setEntertainment(defaultEntertainment);
    setMedical(defaultMedical);
    setOther(defaultOther);
    setFinished(true);
  };

  const onCompleteSetup = () => {
    props.setyearsToEnrollment(yearsToEnrollment);
    props.setyearsAttending(yearsAttending);
    props.setToggleRoom(roomToggle);
    props.setTransportation(transportation);
    props.setEntertainment(entertainment);
    props.setMedical(medical);
    props.setOther(other);
    setModalIsOpen(false);
  };

  const ErrLabel = (msg: string) => (
    <EFLabel
      style={{ fontSize: "1.3rem", color: "#c4233e", fontWeight: "normal" }}
    >
      {msg}
    </EFLabel>
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={props.handleModal.exitModal}
      style={modalCardStyle}
    >
      {!finished && (
        <Carousel
          value={carouselValue}
          draggable={false}
          centered
          slidesPerPage={screenIsAtLeast("tabPort") ? 1.8 : 1}
        >
          <div key={0}>
            <CalculatorSetup
              title={title}
              onClickExit={props.handleModal.exitModal}
            >
              <Stack horizontalAlign="center" tokens={{ childrenGap: "2rem" }}>
                <Stack style={{ margin: 10 }} tokens={{ childrenGap: 40 }}>
                  <EFLabel
                    style={{
                      marginTop: 26,
                      fontSize: 28,
                      fontWeight: "normal" as "normal",
                    }}
                  >
                    Let's talk about timing...
                  </EFLabel>
                  <Stack tokens={{ childrenGap: 5 }}>
                    <EFSlider
                      title="Years to Enrollment"
                      onChange={(val: number) => {
                        setYearsToEnrollment(val);
                        setEnrollmentErr(false);
                      }}
                      value={yearsToEnrollment}
                      min={1}
                      max={18}
                      displayErrStyle={enrollmentErr}
                    />
                    {enrollmentErr && ErrLabel("Please set an amount")}
                  </Stack>
                  <Stack tokens={{ childrenGap: 5 }}>
                    <EFSlider
                      title="Years Attending"
                      onChange={(val: number) => {
                        setYearsAttending(val);
                        setYearsAttendingErr(false);
                      }}
                      value={yearsAttending}
                      min={2}
                      max={6}
                      displayErrStyle={yearsAttendingErr}
                    />
                    {yearsAttendingErr && ErrLabel("Please set an amount")}
                  </Stack>
                </Stack>
                <Stack
                  style={{ marginTop: "2rem" }}
                  horizontalAlign="center"
                  tokens={{ childrenGap: "1.5rem" }}
                >
                  <EFButton
                    style={{ width: "16rem" }}
                    text={`NEXT`}
                    onClick={onCompleteCard1}
                  />
                  <EFLabel
                    onClick={onSkipCard1}
                    style={{
                      fontWeight: "normal",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Figure this out later
                  </EFLabel>
                </Stack>
              </Stack>
            </CalculatorSetup>
          </div>
          <div key={1}>
            <CalculatorSetup
              title={title}
              onClickExit={props.handleModal.exitModal}
            >
              <Stack tokens={{ childrenGap: 5 }}>
                <EFChoiceGroup
                  options={[
                    { key: "PUBLIC_4", text: "Public" },
                    { key: "PRIVATE", text: "Private" },
                    { key: "COMMUNITY", text: "Community College" },
                  ]}
                  title="School Type"
                  onChange={(e, option) => {
                    setSchoolChosen(true);
                    props.handleCollegeType(e, option);
                    setSchoolTypeErr(false);
                    if (option?.key === 'COMMUNITY') {
                        setRoomToggle(false);
                    } else {
                        setRoomToggle(true);
                    }
                  }}
                  displayErrStyle={schoolTypeErr}
                />
                {schoolTypeErr && ErrLabel("Please choose one")}
              </Stack>
              <Stack horizontalAlign="center" tokens={{ childrenGap: ".5rem" }}>
                <EFStepperInput
                  label={`Tuition, Fees & Books`}
                  onChange={(val: number) => props.setTuition(val)}
                  value={props.tuition}
                  prefix="$"
                  minVal={0}
                  maxVal={500000}
                  allowDecimal
                  dynamicStepEnabled
                  includeThousandsSeparator
                />
                <ToggleableEFStepperInput
                  label={`Room & Board`}
                  value={props.room}
                  isToggled={roomToggle}
                  onClickToggle={() => setRoomToggle(!roomToggle)}
                  onChangeInput={(val: number) => props.setRoom(val)}
                  minVal={0}
                  maxVal={500000}
                  dynamicStepEnabled
                  includeThousandsSeparator
                />
                <Stack
                  style={{ marginTop: "1rem" }}
                  horizontalAlign="center"
                  tokens={{ childrenGap: "1.5rem" }}
                >
                  <Stack horizontal tokens={{ childrenGap: "2.7rem" }}>
                    <EFButton
                      style={{
                        width: "16rem",
                        color: "#F7B500",
                        backgroundColor: "#FFF",
                      }}
                      text={`PREVIOUS`}
                      onClick={() => setCarouselValue(0)}
                    />
                    <EFButton
                      style={{ width: "16rem" }}
                      text={`NEXT`}
                      onClick={onCompleteCard2}
                    />
                  </Stack>
                  <EFLabel
                    onClick={onSkipCard2}
                    style={{
                      fontWeight: "normal",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Figure this out later
                  </EFLabel>
                </Stack>
              </Stack>
            </CalculatorSetup>
          </div>
          <div key={2}>
            <CalculatorSetup
              title={title}
              onClickExit={props.handleModal.exitModal}
            >
              <Stack horizontalAlign="center" tokens={{ childrenGap: "2rem" }}>
                <Stack
                  style={{ margin: 10, width: "20rem" }}
                  tokens={{ childrenGap: 30 }}
                >
                  <EFSlider
                    title="Transportation"
                    prefix="$"
                    includeThousandsSeparator
                    onChange={(val: number) => setTransportation(val)}
                    value={transportation}
                    min={0}
                    max={12000}
                  />
                  <EFSlider
                    title="Entertainment"
                    prefix="$"
                    includeThousandsSeparator
                    onChange={(val: number) => setEntertainment(val)}
                    value={entertainment}
                    min={0}
                    max={2500}
                  />
                  <EFSlider
                    title="Medical"
                    prefix="$"
                    includeThousandsSeparator
                    onChange={(val: number) => setMedical(val)}
                    value={medical}
                    min={0}
                    max={2500}
                  />
                  <EFSlider
                    title="Other living expenses"
                    prefix="$"
                    includeThousandsSeparator
                    onChange={(val: number) => setOther(val)}
                    value={other}
                    min={0}
                    max={12000}
                  />
                </Stack>
                <Stack
                  style={{ marginTop: "1rem" }}
                  horizontalAlign="center"
                  tokens={{ childrenGap: "1.5rem" }}
                >
                  <Stack horizontal tokens={{ childrenGap: "2.7rem" }}>
                    <EFButton
                      style={{
                        width: "16rem",
                        color: "#F7B500",
                        backgroundColor: "#FFF",
                      }}
                      text={`PREVIOUS`}
                      onClick={() => setCarouselValue(1)}
                    />
                    <EFButton
                      style={{ width: "16rem" }}
                      text={`FINISH!`}
                      onClick={onCompleteCard3}
                    />
                  </Stack>
                  <EFLabel
                    onClick={onSkipCard3}
                    style={{
                      fontWeight: "normal",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Figure this out later
                  </EFLabel>
                </Stack>
              </Stack>
            </CalculatorSetup>
          </div>
        </Carousel>
      )}
      {finished && (
        <CompletedOnboardingCard
          title={title}
          onClick={onCompleteSetup}
          onClickExit={props.handleModal.exitModal}
        />
      )}
    </Modal>
  );
};

const modalCardStyle = {
  content: {
    justifyContent: "center" as "center",
    alignItems: "middle" as "middle",
    borderRadius: 10,
    borderStyle: "none" as "none",
    overflow: "visible",
    height: "70rem",
    width: "100%",
    background: "none" as "none",
    left: "auto",
    right: "auto",
    padding: 0,
    marginTop: "2rem",
  },
  overlay: {
    display: "flex" as "flex",
    justifyContent: "center" as "center",
    zIndex: 20,
    overflowY: "auto" as "auto",
    background: "rgba(248, 250, 255, 0.50)",
    backdropFilter: "blur(40px)",
    WebkitBackdropFilter: "blur(40px)",
  },
};

export default CollegePlanOnboarding;
