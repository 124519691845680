class LifeEvent {

    id: string;
    name?: string;
    status?: string;
    date: number;
    startDate?: number;
    customData?: object;
    externalId?: string;
    icon: Array<any>;
    planId?: string;
    planName?: string;
    relatedPlanId?: string;
    overview?: object;

    constructor(id: string, date: number, icon: Array<any>, name?: string, status?: string,
        startDate?: number, customData?: object, externalId?: string,
        relatedPlanId?:string, overview?: object) {
        this.id = id;
        this.name = name;
        this.status = status;
        this.date = date;
        this.startDate = startDate;
        this.customData = customData;
        this.externalId = externalId;
        this.icon = icon;
        this.relatedPlanId = relatedPlanId;
        this.overview = overview;
    }
};

export default LifeEvent;