import { getUpdatedUserFullProfile } from './Users';
import { appendHeaders } from "../../utils/FetchHeaders";
import { getId } from '../../utils/LifeEventTools/Mappers';

export const SAVE_ANSWER = "SAVE_ANSWER";
export const CREATE_ONBOARDED_USER = "CREATE_ONBOARDED_USER";
export const START_ONBOARDING = "START_ONBOARDING";
export const UPDATE_CURRENT_QUESTION = "UPDATE_CURRENT_QUESTION";
export const GO_BACK = "GO_BACK";
export const SHOW_PLAN_SUMMARY = "SHOW_PLAN_SUMMARY";
export const REMOVE_LE = "REMOVE_LE";
export const ADD_LE = "ADD_LE";
export const RETAKE = "RETAKE";

const GET_USER_FULL_PROFILE_ENDPOINT =
  process.env.REACT_APP_GET_USER_FULL_PROFILE_ENDPOINT;

const USER_PLANS_ENDPOINT = process.env.REACT_APP_USER_PLANS;
const ONBOARDING_HANDLER_ENDPOINT = process.env.REACT_APP_ONBOARDING_HANDLER;

export const saveAnswer = (questionIndex: number, answer: any) => {
  return async (dispatch: any, getState: any) => {
    try {

      const user = getState().session.user;
      const token = user.access_token;

      const response = await fetch(ONBOARDING_HANDLER_ENDPOINT, {
        method: 'PUT',
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          data: {
            age: answer
          }
        })
      });

      if (response.ok) {
        const payload = await response.json();
        const savedAnswers = payload.data.setup['my-game-plan'];
        dispatch({
          type: SAVE_ANSWER,
          questionIndex: questionIndex,
          answer: answer,
          savedAnswers: savedAnswers
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getNextQuestion = (currentKey: string, currentValue: string, currentOptionalKey?: string, currentOptionalValue?: string, skipped?: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      const user = getState().session.user;
      const currentQuestion = getState().onboarding.currentQuestion;
      const token = user.access_token;

      let bodyRequest = {
        ...getState().onboarding.savedAnswers
      };

      if (skipped !== undefined && skipped) {
        bodyRequest = { ...bodyRequest, [currentKey]: '' }
      }
      else {
        if (currentQuestion.configuration.isArray) {  
            bodyRequest = { ...bodyRequest, [currentQuestion.configuration.arrayIndex]: [...(bodyRequest[currentQuestion.configuration.arrayIndex] || []), 
              { [currentKey]: currentValue, [currentOptionalKey]: currentOptionalValue }]}
        }
        else {
          bodyRequest = { ...bodyRequest, [currentKey]: currentValue }
        }
      }

      const response = await fetch(ONBOARDING_HANDLER_ENDPOINT, {
        method: 'PATCH',
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          data: bodyRequest
        })
      });

      if (response.ok) {
        const payload = await response.json();
        const suggestedLifeEvents = Object.keys(payload.data).length > 0 ? [...payload.data.results.lifeEvents] : []
        suggestedLifeEvents.forEach(le => {
          le.transition = payload.data.transition
        });

        if (payload.data.to === null) {

          const reply = await fetch(ONBOARDING_HANDLER_ENDPOINT, {
            method: 'PUT',
            headers: { ...appendHeaders(), Authorization: "Bearer " + token },
            body: JSON.stringify({
              data: bodyRequest
            })
          });

          if (reply.ok) {
            dispatch({
              type: SHOW_PLAN_SUMMARY,
              currentQuestion: {},
              transition: [],
              le: suggestedLifeEvents
            });
          }
        }
        else {
          dispatch({
            type: UPDATE_CURRENT_QUESTION,
            savedAnswers: payload.data.data,
            currentQuestion: payload.data.to.metadata,
            transition: payload.data.transition,
            le: suggestedLifeEvents
          });
        }
      }
    }
    catch (error) {
      console.error(error);
    }
  }
}

export const goBack = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const user = getState().session.user;
      const token = user.access_token;
      const transition = getState().onboarding.transitions[getState().onboarding.transitions.length - 2];

      const response = await fetch(ONBOARDING_HANDLER_ENDPOINT, {
        method: 'PATCH',
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          transitionId: transition
        })
      });

      if (response.ok) {
        const payload = await response.json();
        dispatch({
          type: GO_BACK,
          oldTransition: getState().onboarding.transitions[getState().onboarding.transitions.length - 1],
          currentQuestion: payload.data.to.metadata
        })
      }

    }
    catch (error) {
      console.error(error);
    }
  }
}

export const removeLE = (index: number) => {
  return async (dispatch: any, getState: any) => {
    const newLE = [...getState().onboarding.currentLE];
    newLE.splice(index, 1);
    dispatch({
      type: REMOVE_LE,
      currentLE: newLE
    });
  }
}

export const addLE = (lifeEventInfo: any) => {
  return async (dispatch: any, getState: any) => {
    const newLE = [...getState().onboarding.currentLE];
    newLE.push(lifeEventInfo);
    dispatch({
      type: ADD_LE,
      currentLE: newLE
    });
  }
}

export const createOnboardedUser = (history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const user = getState().session.user;
      const token = user.access_token;
      const savedAnswers = getState().onboarding.savedAnswers;

      //Income information
      //const householdAnnualIncome = getState().onboarding.householdAnnualIncome;
      //const monthlyHouseholdSpend = getState().onboarding.monthlyHouseholdSpend;

      if (user.plans.length > 0 && user.plans.filter(plan => plan.type === "main").length > 0) {
        const response = await fetch(USER_PLANS_ENDPOINT + '/' +
          user.plans.filter(plan => plan.type === "main")[0].planId, {
          method: 'DELETE',
          headers: { ...appendHeaders(), Authorization: "Bearer " + token }
        });
        if (response.ok) {
          if (getState().onboarding.currentLE.length > 0) {
            const payload = getState().onboarding.currentLE.map(le => {
              return {
                customData: {},
                status: 'NEW',
                lifeEventId: le._id || getId(le.name),
                name: le.name,
                date: Date.now()
              }
            })
            await addPlan("MY PLAYLIST", payload, history, token, 'main');
          }

          const response = await fetch(GET_USER_FULL_PROFILE_ENDPOINT, {
            method: "PATCH",
            headers: { ...appendHeaders(), Authorization: "Bearer " + token },
            body: JSON.stringify({
              age: parseInt(savedAnswers.age),
              dependents: savedAnswers.dependents
            }),
          });

          if (response.ok) {
            dispatch({
              type: RETAKE
            });
            dispatch(getUpdatedUserFullProfile(history, undefined, undefined,
              undefined, undefined, true));
          } else {
            if (response.status === 401) {
              history.replace("/expired");
            }
          }
        } else {
          if (response.status === 401) {
            history.replace("/expired");
          }
        }
      }
      else {
        if (getState().onboarding.currentLE.length > 0) {
          const payload = getState().onboarding.currentLE.map(le => {
            return {
              customData: {},
              status: 'NEW',
              lifeEventId: le._id || getId(le.name),
              name: le.name,
              date: Date.now()
            }
          })
          await addPlan("MY PLAYLIST", payload, history, token, 'main');
        }

        const response = await fetch(GET_USER_FULL_PROFILE_ENDPOINT, {
          method: "PATCH",
          headers: { ...appendHeaders(), Authorization: "Bearer " + token },
          body: JSON.stringify({
            age: parseInt(savedAnswers.age),
              dependents: savedAnswers.dependents
          }),
        });

        if (response.ok) {
          dispatch({
            type: RETAKE
          });
          dispatch(getUpdatedUserFullProfile(history, undefined, undefined,
            undefined, undefined, true));
        } else {
          if (response.status === 401) {
            history.replace("/expired");
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export async function addPlan(planName: string, lifeEventArray: any, history, token: string, type: string) {
  try {
    const response = await fetch(USER_PLANS_ENDPOINT, {
      method: 'POST',
      headers: { ...appendHeaders(), Authorization: "Bearer " + token },
      body: JSON.stringify({
        name: planName,
        lifeEvents: lifeEventArray,
        type: type
      })
    });

    if (response.ok) {
      const payload = await response.json();
      return payload.data;
    }
    else {
      if (response.status === 401) {
        history.replace("/expired");
      }
    }

  }
  catch (error) {
    console.error(error);
  }
}

export const startOnboarding = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const user = getState().session.user;
      const token = user.access_token;

      const result = await fetch(ONBOARDING_HANDLER_ENDPOINT, {
        method: 'POST',
        headers: { ...appendHeaders(), Authorization: "Bearer " + token }
      });

      if (result.ok) {
        const payload = await result.json();
        const info = payload.data.to.metadata;
        dispatch({
          type: START_ONBOARDING,
          currentQuestion: info,
          transition: payload.data.transition
        })
      }
    }
    catch (error) {
      console.error(error);
    }
  }
}
