import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useResponsive } from '../../../hooks/useResponsive';
import EFButton from '../../Atomic/EFButton/EFButton';
import UserSettings, { eUserSettings } from '../../UserSettings/UserSettings';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import maleAvatar from '../../../assets/images/maleAvatar.png';
import close from '../../../assets/images/close.svg';

import classes from './ProfileSwitcher.module.css';
import * as actions from '../../../store/actions/Users';
import { appendHeaders } from '../../../utils/FetchHeaders';
import { saveProfiles } from '../../../store/actions/Data';

interface Props {
    onSelectedProfile: (name: string) => void;
    startingProfile: number;
    hide?: (hide: boolean) => void;
    profileTab: eUserSettings;
    showCarousel: boolean;
    onClose: () => void;
}

interface LifeEvent {
    image: string,
    text: string
}

interface Profile {
    title: string,
    subtitle: string,
    content: string,
    profileNumber: number,
    avatar: string,
    image: string,
    text: string,
    lifeEvents: LifeEvent[]
}

export const CustomProfile = ({ selectedProfile, onSelectedProfile }) => {
    const realUser = useSelector((state: any) => state.data.realUser);
    const history = useHistory();
    const dispatch = useDispatch();

    const customProfileClickHandler = (event: React.SyntheticEvent) => {
        if (!realUser) {
            history.push('/signup');
            event.stopPropagation();
        }
        else {
            dispatch(actions.clearCanonicalProfile(history));
            onSelectedProfile();
        }
    }

    return (
        <div onClick={(event) => { customProfileClickHandler(event) }}
            className={`${classes.previewCard} ${classes.card}`}
            style={{
                opacity: selectedProfile.profileNumber !== 1 ? 0.35 : 1,
                border:
                    !selectedProfile
                        ? "2px solid #FEB81E"
                        : ""
            }}>
            <div style={{ alignItems: 'center', paddingTop: '15%' }}>
                <img style={{ width: '9.25rem', height: '9.25rem', marginBottom: '1rem' }} src={maleAvatar} alt="profile icon" />
            </div>
            <div style={{ alignItems: 'center' }}>
                {!realUser &&
                    <>
                        <div className={classes.title} style={{ marginTop: '.5rem' }}>Create</div>
                        <div className={classes.title} style={{ margin: '1rem 0' }}>an Account</div>
                    </>}
                {realUser && <div className={classes.title} style={{ marginTop: '.5rem', textAlign: 'center' }}>{realUser.name}</div>}

            </div>
        </div>
    )
}

const getValue = (name: string) => {
    let value = -1;

    switch (name) {
        case 'Stacey': {
            value = 4;
            break;
        }
        case 'Daryl': {
            value = 1;
            break;
        }
        case 'Rosa': {
            value = 0;
            break;
        }
        case 'Eve': {
            value = 2;
            break;
        }
        case 'Martin': {
            value = 3;
            break
        }
    }

    return value;
}

const ProfileSwitcher: React.FC<Props> = props => {
    // Attempt to make profile selection cyclic, apologies if I messed up the logic!
    const startingProfile = props.startingProfile === 1 ? 6 : props.startingProfile;
    const history = useHistory();
    const dispatch = useDispatch();
    const { screenIsAtMost } = useResponsive();
    const profiles = useSelector((state: any) => state.data.profiles);
    const currentProfile = useSelector((state: any) => state.data.currentProfile);
    const [carouselValue, setCarouselValue] = useState(currentProfile !== null && Object.keys(currentProfile).length > 0 ? getValue(currentProfile.name) : 0);

    useEffect(() => {
        if (profiles.length === 0) {
            fetch(process.env.REACT_APP_ALL_PERSONAS_ENDPOINT, {
                method: 'GET',
                headers: { ...appendHeaders() }
            })
                .then(response => {
                    response.json()
                        .then(payload => {
                            const profiles = payload.data.map(p => {
                                return {
                                    title: p.name,
                                    subtitle: p.shortDescription,
                                    content: p.goal,
                                    avatar: p.avatar,
                                    image: p.profilePicture,
                                    text: p.description,
                                    lifeEvents: p.lifeEvents.map(l => {
                                        return {
                                            image: l.eventIcon[0],
                                            text: l.name
                                        }
                                    })
                                }
                            });
                            dispatch(saveProfiles(profiles));
                        })
                })
                .catch(error => console.error(error))
        }
        if (currentProfile !== null && Object.keys(currentProfile).length > 0) {
            setSelectedProfile(profiles.filter(p => p.title === currentProfile.name)[0]);
        }
        else {
            setSelectedProfile(profiles.filter(p => p.title === "Rosa")[0]);
        }

    }, [profiles]);

    const startProfile = () => {
        const canonical = profiles.find(p => p.profileNumber === startingProfile);
        if (canonical) {
            return canonical;
        } else if (startingProfile === 1) {
            return { profileNumber: 1, lifeEvents: null, title: null, subtitle: null, content: null, avatar: null, image: null, text: null };
        } else {
            return null;
        }
    }

    const [selectedProfile, setSelectedProfile] = useState(currentProfile !== null && Object.keys(currentProfile).length > 0 ?
        profiles.filter(p => p.title === currentProfile.name)[0] :
        profiles.filter(p => p.title === "Rosa")[0]);
    const onProfileSelectHandler = (selectedProfile: Profile) => {
        setSelectedProfile(selectedProfile);
    }

    const CanonicalProfile = ({ profile }: { profile: any }) => {
        let content = null;

        if (selectedProfile !== undefined && selectedProfile.title !== '') {
            content = (
                <div
                    onClick={(event) => {
                        onProfileSelectHandler(profile);
                        event.stopPropagation()
                    }}
                    className={`${classes.previewCard} ${classes.card}`}
                    style={{
                        border:
                            selectedProfile.title === profile.title
                                ? "2px solid #FEB81E"
                                : "",
                        opacity: selectedProfile.title !== profile.title ? 0.35 : 1
                    }}
                >
                    <div style={{ alignItems: "center", paddingTop: "10%" }}>
                        <img style={{ width: '9.25rem', height: '9.25rem', marginBottom: '1rem' }} src={profile.avatar} alt="profile icon" />
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div className={classes.title} style={{ marginTop: "0px" }}>
                            {profile.title}
                        </div>
                        <div className={classes.subtitle} style={{ marginTop: "1rem", textAlign: 'center' }}>
                            {profile.subtitle}
                        </div>
                    </div>
                </div>
            );
        }

        return content;
    };

    const Separator = () => {
        return <div className={classes.separator}>...</div>;
    };

    const renderLifeEvent = (lifeEvent: LifeEvent) => {
        return (
            <div key={lifeEvent.text} className={classes.lifeEvent}>
                <img src={lifeEvent.image} alt="icon" />
                <p>{lifeEvent.text}</p>
            </div>
        );
    };

    const renderLifeEvents = () => {
        return (
            <div className={classes.lifeEventList}>
                {selectedProfile?.lifeEvents?.map(
                    (lifeEvent: LifeEvent, index: number) => {
                        if (index !== selectedProfile.lifeEvents.length - 1) {
                            return (
                                <React.Fragment key={lifeEvent.text}>
                                    {renderLifeEvent(lifeEvent)}
                                    <Separator />
                                </React.Fragment>
                            );
                        } else {
                            return renderLifeEvent(lifeEvent);
                        }
                    }
                )}
            </div>
        );
    };

    const renderSelectedProfile = (profile: any) => {
        let content = null;

        if (profile !== undefined && profile.title !== '') {
            content = (
                screenIsAtMost('mobile') ?
                    <div style={{ height: '62rem', width: '82vw', margin: '2rem' }}>
                        <div className={classes.cardTextSectionMobile}
                            style={{
                                background: `url(${profile.image})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                width: '100%'
                            }}>
                            <div style={{
                                height: '80%',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                background: 'linear-gradient(179.83deg, rgba(0, 0, 0, 0.0001) 19.48%, rgba(0, 0, 0, 0.350021) 28.84%, rgba(0, 0, 0, 0.8) 62.07%)'
                            }}>
                                <div
                                    className={classes.subtitle}
                                    style={{ fontWeight: 700, fontSize: "2rem", color: 'white', padding: '3rem' }}
                                >
                                    {profile.text}
                                </div>
                                {renderLifeEvents()}
                                <EFButton
                                    style={{ margin: '2rem 0', height: '3.25rem', width: '18rem', fontSize: '1.28rem', alignSelf: "center", marginTop: "1.86rem", color: 'white', fontWeight: '700' }}
                                    text={"CHOOSE " + profile.title?.toUpperCase()}
                                    onClick={() =>
                                        dispatch(actions.getProfile(profile.title, history, false))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <div className={`${classes.carousel} ${classes.cardSelectedProfile}`}>
                        <div className={classes.cardImgSection}>
                            <img
                                src={profile.image}
                                alt="Profile"
                                style={{ width: "30rem", marginRight: "7.72rem" }}
                            />
                        </div>
                        <div className={classes.cardTextSection}>
                            <div
                                className={classes.title}
                                style={{ fontSize: "3.3rem" }}
                            >
                                {profile.title}
                            </div>
                            <div
                                className={classes.subtitle}
                                style={{ fontWeight: 500, fontSize: "2.1rem", margin: "0" }}
                            >
                                {profile.subtitle}
                            </div>
                            <div
                                className={classes.content}
                                style={{ fontSize: "2.1rem", margin: "0" }}
                            >
                                {profile.content}
                            </div>
                            <div
                                className={classes.subtitle}
                                style={{ fontWeight: 500, fontSize: "2.2rem" }}
                            >
                                {profile.text}
                            </div>
                            {renderLifeEvents()}
                            <EFButton
                                id="choose-cp"
                                style={{ height: '3.25rem', width: '18rem', fontSize: '1.28rem', alignSelf: "center", marginTop: "1.86rem" }}
                                text={"CHOOSE " + profile.title?.toUpperCase()}
                                onClick={() => {
                                    dispatch(actions.getProfile(profile.title, history, false))
                                    props.onSelectedProfile(profile.title.toUpperCase())
                                }
                                }
                            />
                        </div>
                    </div>
            );
        }

        return content;
    };

    const backSelectedProfile = () => {
        let newIndex = -1;
        let newCarouselValue = carouselValue;
        const selectedProfileCurrentIndex = profiles.findIndex(p => p.title === selectedProfile.title);
        switch (selectedProfileCurrentIndex) {
            case 0: {
                newIndex = 1;
                break;
            }
            case 1: {
                newIndex = 4;
                break;
            }
            case 2: {
                newIndex = 3;
                break;
            }
            case 3: {
                newIndex = 0;
                break;
            }
            case 4: {
                newIndex = 2;
                break;
            }
        }
        setSelectedProfile(profiles[newIndex]);
        newCarouselValue--;
        setCarouselValue(newCarouselValue);
    }

    const forwardSelectedProfile = () => {
        let newIndex = -1;
        let newCarouselValue = carouselValue;
        const selectedProfileCurrentIndex = profiles.findIndex(p => p.title === selectedProfile.title);
        switch (selectedProfileCurrentIndex) {
            case 0: {
                newIndex = 3;
                break;
            }
            case 1: {
                newIndex = 0;
                break;
            }
            case 2: {
                newIndex = 4;
                break;
            }
            case 3: {
                newIndex = 2;
                break;
            }
            case 4: {
                newIndex = 1;
                break;
            }
        }
        setSelectedProfile(profiles[newIndex]);
        newCarouselValue++;
        setCarouselValue(newCarouselValue);
    }

    const olderUserCard = (
        <div>
            <CustomProfile
                selectedProfile={selectedProfile}
                onSelectedProfile={() => { props.onSelectedProfile("") }} />
        </div>
    );

    let content = null;

    if (profiles.length > 0) {
        content = (
            <div onClick={(e) => e.stopPropagation()}
                className={classes.mainContainer}>
                <div className={classes.outerCard}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                        <img alt='x' src={close} style={{ cursor: 'pointer', width: '1.5%', marginRight: '0.7rem', marginTop: '0.7rem' }} onClick={props.onClose}/>
                    </div>
                    <div>
                        <div className={classes.form}>
                            {props.showCarousel &&
                                <p className={classes.title} style={{ paddingBottom: '2rem' }}>Choose Profile and Play</p>
                            }
                            {props.showCarousel && <div style={{ justifyContent: 'space-evenly' }} className={classes.carousel}>
                                <div style={{ width: '100%' }}>
                                    <Carousel
                                        value={carouselValue}
                                        onChange={value => setCarouselValue(value)}
                                        clickToChange
                                        centered
                                        slidesPerPage={screenIsAtMost('mobile') ? 1.5 : 3}
                                        infinite
                                        addArrowClickHandler
                                        arrowLeft={<img onClick={(e) => { e.stopPropagation(); backSelectedProfile(); }} style={{ marginRight: '2rem', cursor: 'pointer' }} alt='chev-l' src={require('../../../assets/icons/chevron_left.svg')} />}
                                        arrowRight={<img onClick={(e) => { e.stopPropagation(); forwardSelectedProfile(); }} style={{ marginLeft: '2rem', cursor: 'pointer' }} alt='chev-l' src={require('../../../assets/icons/chevron_right.svg')} />}
                                    >
                                        <div><CanonicalProfile profile={profiles[2]} /></div>
                                        <div><CanonicalProfile profile={profiles[4]} /></div>
                                        <div><CanonicalProfile profile={profiles[1]} /></div>
                                        <div><CanonicalProfile profile={profiles[0]} /></div>
                                        <div><CanonicalProfile profile={profiles[3]} /></div>
                                    </Carousel>
                                </div>
                            </div>}
                            {!props.showCarousel && <UserSettings hide={props.hide} section={props.profileTab} />}
                            {props.showCarousel && renderSelectedProfile(selectedProfile)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return content;
};

export default ProfileSwitcher;
