import React from 'react';
import classes from './SavingsGrow.module.css';
import { Stack } from 'office-ui-fabric-react';
import DoughnutChart from '../../../Charts/DoughnutChart';
import Category from '../../../Scoop/CategoryList/Category';
import { generateColorShadeSet, addAlpha } from '../../../../utils/colorGenerators';
import { useSavingsData } from '../../../../hooks/ScoopDashboards/useSavingsData';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../../utils/Masking';
import BlurAddMeContainer from '../../../UI/BlurAddMeContainer/BlurAddMeContainer';

interface IProps {
    accBalancesData: any;
    transactionsData: any;
    hasAccounts?: boolean,
    onClick?: () => void,
    onClickAddAccs?: () => void,
};

const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowNegative: true,
    allowLeadingZeroes: false,
});

const Savings: React.FC<IProps> = props => {
    const toYearly = false;
    const {
        sortedData,
        getCategoryTotalsByMonth,
        monthlySumsByCategory,
    } = useSavingsData(props.accBalancesData, props.transactionsData, toYearly);

    const allCategoryColor = '#2698E0';
    const baseCategories = ['Banking', 'Investment', 'Retirement'];
    const doughnutColors = generateColorShadeSet(3, allCategoryColor);

    let categoryData = [
        { 
            key: baseCategories[0], 
            text: baseCategories[0], 
            color: doughnutColors[0], 
            icon: require('../../../../assets/images/scoop/all_bank_savings.svg'), 
            focused: false, 
            value: sortedData && monthlySumsByCategory[0][11],
        },
        { 
            key: baseCategories[1], 
            text: baseCategories[1], 
            color: doughnutColors[1], 
            icon: require('../../../../assets/images/scoop/all_invest_savings.svg'), 
            focused: false, 
            value: sortedData && monthlySumsByCategory[1][11],
        },
        { 
            key: baseCategories[2], 
            text: baseCategories[2], 
            color: doughnutColors[2], 
            icon: require('../../../../assets/images/scoop/all_retire_savings.svg'), 
            focused: false, 
            value: sortedData && monthlySumsByCategory[2][11],
        },
    ];

    const categories = categoryData.map(e => (
        <Stack key={e.key}>
            <Category
                id={e.key}
                title={e.text}
                color={addAlpha(e.color, .3)}
                icon={e.icon}
                focused={e.focused}
                onClick={() => null}
                value={e.value} 
            />
        </Stack>
    ));

    const savingsByCategory = getCategoryTotalsByMonth(11);
    const totalSavings = Math.round((savingsByCategory[0] + savingsByCategory[1]) * 1e2 / 1e2);
    
    return (
        <>
            {(props.hasAccounts && sortedData.length) ? (
                <Stack onClick={props.onClick} className={classes.container} horizontalAlign='center' tokens={{childrenGap: 20}}>
                    <Stack horizontalAlign='center'>
                        <EFLabel style={{fontWeight: 'normal', fontSize: '1.8rem'}}>Total Savings</EFLabel>
                        <EFLabel style={{fontWeight: 'normal', fontSize: '1.8rem'}}>{maskValue(Math.round(totalSavings), numberMask)}</EFLabel>
                        <Stack className={classes.doughnut_view} verticalAlign='center' horizontalAlign='center' tokens={{childrenGap: 10}}>
                            <DoughnutChart 
                                datasets={{
                                labels: [baseCategories[0], baseCategories[1]],
                                datasets: [
                                    {
                                        label: 'Savings',
                                        data: savingsByCategory.map((e: any) => Math.round(e * 1e2) / 1e2),
                                        backgroundColor: doughnutColors,
                                        borderColor: 'transparent',
                                    },
                                ]}}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal horizontalAlign='center'>
                        <Stack tokens={{childrenGap: 6}}>
                            {sortedData && categories}
                        </Stack>
                    </Stack>
                </Stack>
            ) : 
            <BlurAddMeContainer
                onClick={props.onClickAddAccs} 
                backgroundImg={require('../../../../assets/images/grow_save_preview.png')} 
                label={props.hasAccounts ? 'Please add more accounts' : 'Link your accounts'}
            />}
        </>
    )
};

export default Savings;
