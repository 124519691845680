import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import classes from './Category.module.css';
import MaskedLabelPair from '../../UI/MaskedLabelPair';
import animations from '../../../utils/animations.module.css';

interface IProps {
    id?: any,
    title: string,
    value?: number,
    color: string,
    icon: string,
    iconColor?: string,
    focused?: boolean,
    onClick?: () => void,
    disableAnimation?: boolean,
    style?: any,
    imgStyle?: object,
};

const Category: React.FC<IProps> = props => {
    const animate = props.disableAnimation ? '' : animations.cardShadowExpand;

    return (
        <Stack
            key={props.id}
            horizontal 
            verticalAlign='center' 
            horizontalAlign='space-between'
            className={`${classes.container} ${animate}`}
            style={{
                backgroundImage: 
                    `-webkit-linear-gradient(left, ${props.color}, ${props.color} 
                    ${props.focused ? '100%' : '20%'}, transparent 
                    ${props.focused ? '100%' : '20%'}, transparent 100%)`,
                ...props.style,
            }}
            onClick={props.onClick}
        >
            {props.iconColor ? (
                <div 
                    className={classes.img} 
                    style={{
                        mask: `url(${props.icon}) no-repeat center`, 
                        WebkitMask: `url(${props.icon}) no-repeat center`, 
                        backgroundColor: props.iconColor,
                        maskSize: 'contain',
                        ...props.imgStyle,
                    }} 
                />
            ) : <img style={props.imgStyle} className={classes.img} alt='category' src={props.icon} />}
            <Stack className={classes.label}>
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='$'
                    suffix=''
                    title={props.title}
                    value={props.value}
                    color='#9398A5'
                    valueFontSize={'1.4rem'}
                    pairWidth={'10rem'}
                    sectionGap={100}
                />
            </Stack>
        </Stack>
    )
};

export default Category;
