/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect } from 'react';
import StaticContainer from '../../UI/StaticContainer';

interface Props {
    currentView: string,
    lifeEventDetails?: any,
    url: string,
}

const StaticContent: React.FC<Props> = props => {
    const isAddLEClicked = props.lifeEventDetails?.openSaveModal || false;
    const initDate = moment().valueOf();
    const formData = { nickName: props.lifeEventDetails?.name || "", startDate: initDate, date: initDate };

    useEffect(() => {
        if (props.lifeEventDetails?.status === "NEW") {
            // Save by clicking add icon
            // OR (FOR FUTURE)
            // Auto save if user has gone through all tab views
            // by using -> || props.currentView === 'Nitty Gritty' 
            // (wont work yet cause router refreshes page when we save)
            if (isAddLEClicked) {
                props.lifeEventDetails?.onSaveLifeEvent(formData)
            }
        }
    }, [isAddLEClicked, props.currentView])

    return (
        <>
            {props.currentView === 'Low Down' && <StaticContainer url={props.url + "/lowdown"} />}
            {props.currentView === 'Nitty Gritty' && <StaticContainer url={props.url + "/nittygritty"} />}
        </>
    )
}

export default StaticContent;
