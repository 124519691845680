import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import addToMyPlans from '../../assets/images/arrowsOrange.svg';
import { isCanonical } from '../../utils/global';
import PlanDropDown from './PlanDropDown';
import PlanCard from './PlanCard';
import Interstitial from '../Atomic/Interstitial/Interstital';

import { getLifeEventNameById } from '../../utils/LifeEventTools/Mappers';

import classes from './MyPlanCards.module.css';
import interstitialImage from '../../assets/images/planInterstitial.svg';

interface Plan {
    id: number;
    title: string;
    avatar: string;
    bgAvatar: string;
    status: string;
}

interface IProps {
    owner: string,
    style?: object,
    title?: string,
    data: any,
    onCardClickHandler: (id: number, owner: string, avatar: any, externalId: string, _id: string) => void;
    onUserCardClickHandler: (lifeEventName: string, lifeEventStatus: string,
        lifeEventId: string, lifeEventAvatar: any, lifeEventExternalId: string) => void;
    onAddPlanClick: () => void;
};

export const getPlanIdByName = (name: string) => {
    switch (name) {
        case 'Stacey':
            return '15XMPjNJxIiObyKiZj5bqX';
        case 'Rosa':
            return '2rP4i7nprPFlOTJQUdDGpf';
        case 'Daryl':
            return '5d7zn2czRJU1qjrnOpYtTq';
        case 'Martin':
            return '6hOMYdzUptCQPQg644vRVd';
        case 'Eve':
            return '1uKWUhdMsHuc87hiz6Rhpg'
    }
}

export const getPlanNameById = (planId: string) => {
    switch (planId) {
        case "0":
        case '15XMPjNJxIiObyKiZj5bqX': {
            return 'Planning for College';
        }
        case "1":
        case '2rP4i7nprPFlOTJQUdDGpf': {
            return 'Begin Career';
        }
        case '2':
        case '5d7zn2czRJU1qjrnOpYtTq': {
            return 'Tools for Parents';
        }
        case "3":
        case '6hOMYdzUptCQPQg644vRVd': {
            return 'Buy a House';
        }
        case "4":
        case '1uKWUhdMsHuc87hiz6Rhpg': {
            return 'Planning for Retirement';
        }
        default:
            return "";
    }
}

export const planNames = [
    'Planning for College', 'Begin Career', 'Tools for Parents', 'Buy a House', 'Planning for Retirement'
]

const MyPlanCards: React.FC<IProps> = props => {

    let renderProfile = -1;
    const user = useSelector((state: any) => state.session.user);
    const realUser = useSelector((state: any) => state.data.realUser);
    const [selectedPlan, setSelectedPlan] = useState(0);

    switch (props.owner) {
        case "Stacey": {
            renderProfile = 0;
            break;
        }
        case "Rosa": {
            renderProfile = 1;
            break;
        }
        case "Daryl": {
            renderProfile = 2;
            break;
        }
        case "Martin": {
            renderProfile = 3;
            break;
        }
        case "Eve": {
            renderProfile = 4;
            break;
        }
    }

    //reference urls are currently being mapped via a plan object's id
    let cards;
    if (renderProfile !== -1) {
        cards = props.data[renderProfile].map(
            (plan: any, index: any) =>
                <PlanCard key={index}
                    id={plan.id} title={plan.title}
                    owner={props.owner}
                    isCompleted
                    onCardClickHandler={() =>
                        props.onCardClickHandler(plan.id, props.owner.toUpperCase(), plan.avatar, plan.externalId, plan._id)
                    }
                    avatar={plan.avatar}
                    statusString={plan.status} 
                    le={{}}/>
        );
    }

    return (
        <>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                {(user.plans.length > 0 || isCanonical(user.name)) ?
                    <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%' }}>
                            <PlanDropDown isCanonical={isCanonical(user.name)}
                                title={isCanonical(user.name) ?
                                    user.plans[0].name :
                                    user.plans.length > 0 ? user.plans[selectedPlan].name :
                                        null}
                                planId={isCanonical(user.name) ? getPlanIdByName(props.owner) : user.plans[selectedPlan].planId}
                                plans={user.plans}
                                onPlanClick={(index) => {
                                    setSelectedPlan(index)
                                }}
                                onPlanDeleted={() => setSelectedPlan(0)}>
                            </PlanDropDown>
                            {isCanonical(props.owner) && realUser
                                && realUser.plans.filter(plan => plan.name === "MY PLAYLIST").length > 0 && //TEMPORARY UNTIL WE HAVE A BETTER CONDITION
                                <div className={classes.container} onClick={props.onAddPlanClick}>
                                    <div className={classes.assetContainer}>
                                        <img src={addToMyPlans} alt="img" />
                                    </div>
                                    <div className={classes.textContainer}>
                                        <p className={classes.titleText}>MAKE IT MY OWN PLAN</p>
                                    </div>
                                </div>
                            }
                        </div>
                        <div style={{ display: 'flex', width: '80%', flexWrap: 'wrap', alignItems: 'center' }}>
                            {isCanonical(user.name) ?
                                cards :
                                user.plans.length > 0 ?
                                    user.plans[selectedPlan].lifeEvents.map(le =>
                                        <PlanCard key={le?.id} id={le?.lifeEventId} title={le.name}
                                            owner={props.owner}
                                            isCompleted={le?.status === "NEW" ? false : true}
                                            onCardClickHandler={() => props.onUserCardClickHandler(le?.name, le?.status, le?.id, le?.eventIcon[0].url, le?.externalId)}
                                            avatar={le?.eventIcon[0].url}
                                            statusString={le?.status === "NEW" ? "GET STARTED" : ''}
                                            le={le} />)
                                    : null
                            }
                        </div>
                    </>
                    :
                    <Interstitial
                        interstitialImg={interstitialImage}
                        btnTxt="Let's do it"
                        path="/myplansetup"
                    />
                }
            </div>
        </>
    )
};

export default MyPlanCards;