import React from 'react';
import classes from './Pagination.module.css';
import next from '../../../assets/images/pagination/paginationNext.svg';
import back from '../../../assets/images/pagination/paginationBack.svg';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {

  const totalPages = Math.ceil(totalPosts / postsPerPage);
  let gap = 3;
  let prevBreak = false;
  let nextBreak = false;

  if (currentPage + gap < totalPages) {
    nextBreak = true;
  }
  if (currentPage - gap > 1) {
    prevBreak = true;
  }

  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    if (!prevBreak && !nextBreak) {
      pageNumbers.push(i);
      continue;
    }
    if ( i === 1 || i === totalPages) {
      pageNumbers.push(i);
      continue;
    }
    if (i <= currentPage && i + gap >= currentPage) {
      pageNumbers.push(i);
      continue;
    }
    if (i >= currentPage && i - gap <= currentPage) {
      pageNumbers.push(i);
      continue;
    }
  }

  if (totalPages <= 1) {
    return null
  }

  return (
    <nav>
      <div className={classes.centerDiv}>
        <ul>
  <li className={classes.pagination}>{currentPage !== 1 && <img onClick={() => paginate(currentPage - 1)} className={classes.arrowImg} src={back} alt="back"/>}</li>
          {pageNumbers.map((number: number, i: number) => {
            return (
              <React.Fragment key={number}>
                {number !== 1 && pageNumbers[i - 1] + 1 !== number &&
                <li key={number + 'dots'} className={classes.pagination}>...</li>}
                <li key={number} className={classes.pagination}>
                  <span className={number === currentPage ? classes.selected : classes.pageNumber} onClick={() => paginate(number)}>
                    {number}
                  </span>
                </li>
              </React.Fragment>
            )})}
          <li className={classes.pagination}>{currentPage !== pageNumbers.length && <img onClick={() => paginate(currentPage + 1)} className={classes.arrowImg} src={next} alt="next"/>}</li>
        </ul>
      </div>
    </nav>
  );
};

export default Pagination;
