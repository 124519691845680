import { GET_FASTLINK_CREDENTIALS, SET_SHOW_MONETIZATION } from '../actions/Aggregation';

const initialState = {
    fastlinkURL: '',
    token: '',
    showMonetization: false
};

const aggregationReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case GET_FASTLINK_CREDENTIALS: {
            return {
                ...state,
                fastlinkURL: action.fastlinkURL,
                token: action.token
            };
        }

        case SET_SHOW_MONETIZATION: {
            return {
                ...state,
                showMonetization: action.showMonetization
            }
        }

        default: {
            return state;
        }
    }
}

export default aggregationReducer;