import React from 'react';
import { useSavings } from '../../../hooks/Calculators/useSavings';
import { useConfigButtons } from '../../../hooks/useConfigButtons';
import EFStepperInput from '../../UI/EFStepperInput/EFStepperInput';
import { Stack } from 'office-ui-fabric-react';
import MaskedLabelPair from '../../UI/MaskedLabelPair';
import SaveChangesModal from '../../LifeEvents/SaveChangesModal/SaveChangesModal';
import Divider from '../../UI/Divider/Divider';
import classes from './SavingGoal.module.css';
import DividerContainer from '../../UI/Divider/DividerContainer';
import LineChart from '../../Charts/LineChart/LineChart';
import ResultReport from '../Common/ResultReport';
import ConfigButton from '../Common/ConfigButton';
import EFAccordion from '../../Atomic/EFAccordion/EFAccordion';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import StaticContainer from '../../UI/StaticContainer';
import green_arrow from '../../../assets/icons/green_arrow.svg';
import green_arrow_up from '../../../assets/icons/green_arrow_up.svg';
import red_arrow from '../../../assets/icons/red_arrow.svg';
import red_arrow_down from '../../../assets/icons/red_arrow_down.svg';
import savingsImage from '../../../assets/images/savingsGoalImage.svg';
import SavingGoalOnboarding from './SavingGoalOnboarding';
import SavingGoalInterstitial from '../../LifeEvents/Interstitials/SavingGoalInterstitial/SavingGoalInterstitial';


interface Props {
    handleModal: { showModal: boolean, exitModal?: () => void };
    currentView: string;
    lifeEventDetails?: any;
}

const SavingGoal: React.FC<Props> = props => {

    const {
        SAVING_MONTHLY_CONTRIBUTION,
        SAVING_GOAL_COMPARE,
        monthlySaving,
        setMonthlySaving,
        savingGoal,
        setSavingGoal,
        annualReturnRate,
        setAnnualReturnRate,
        currentSavings,
        setCurrentSavings,
        monthsToGoal,
        setMonthsToGoal,
        desiredMonthlySaving,
        setDesiredMonthlySaving,
        desiredInterestRate,
        setDesiredInterestRate,
        savingProgress,
        setSavingProgress,
        summary,
        setSummary,
    } = useSavings(props.lifeEventDetails.id);

    // Handles saving goal with desired payments dependency on it
    const onChangeGoal = (val: number) => {
        const s = SAVING_MONTHLY_CONTRIBUTION(
            val,
            currentSavings,
            monthsToGoal,
            annualReturnRate,
        );
        setSavingGoal(val);
        setDesiredMonthlySaving(Math.round(s));
        setMonthlySaving(Math.round(s));
    };

    // Handles monthly term with desired savings dependency on it
    const onChangeTerm = (val: number) => {
        const s = SAVING_MONTHLY_CONTRIBUTION(
            savingGoal,
            currentSavings,
            val,
            annualReturnRate
        );
        setMonthsToGoal(val);
        setDesiredMonthlySaving(Math.round(s));
        setMonthlySaving(Math.round(s));
    };

    // Handles interest rate with desired savings + desired interest dependencies on it
    const onChangeInterest = (val: number) => {
        const s = SAVING_MONTHLY_CONTRIBUTION(
            savingGoal,
            currentSavings,
            monthsToGoal,
            val
        );
        setAnnualReturnRate(val);
        setDesiredInterestRate(val);
        setDesiredMonthlySaving(Math.round(s));
        setMonthlySaving(Math.round(s));
    };

    //Handles changes to Current Savings input
    const onChangeCurrentSavings = (val: number) => {
        const s = SAVING_MONTHLY_CONTRIBUTION(
            savingGoal,
            val,
            monthsToGoal,
            annualReturnRate
        );
        setCurrentSavings(val);
        setDesiredMonthlySaving(Math.round(s));
        setMonthlySaving(Math.round(s));
    };

    // Chart data
    const savingMonths = savingProgress.map(s => s.month);
    const currentProjection = savingProgress.filter(el => el.savingGoalAchieved !== true).map(s => s.savingGoalBalance);
    const desiredProjection = savingProgress.filter(el => el.desiredSavingGoalAchieved !== true).map(s => s.desiredSavingGoalBalance);

    // Handles simulated chart's various states (none, green, red)
    const handleSimulatedData = () => {
        let simulatedData = desiredProjection;
        let simulatedIsBetter = false;
        let stop = false;
        let i = 0;
        //let's find out which projection is "better"
        if (currentProjection.length === desiredProjection.length) {

            while (!stop && i < currentProjection.length) {
                if (Math.round(currentProjection[i]) > Math.round(desiredProjection[i])) {
                    console.log("A");
                    stop = true;
                }
                else {
                    if (Math.round(currentProjection[i]) < Math.round(desiredProjection[i])) {
                        console.log("B");
                        simulatedIsBetter = true;
                        stop = true;
                    }
                }
                i++;
            }

            if (!stop) {
                simulatedData = null;
            }
        }
        else {
            if (currentProjection.length > desiredProjection.length) {
                simulatedIsBetter = true;
            }
        }
        let simulatedDataColor = 'rgba(247, 175, 2, 0.13)';
        if (!simulatedIsBetter) {
            simulatedDataColor = 'rgba(243, 121, 141, 0.13)';
        } else {
            if (!stop) {
                simulatedDataColor = 'rgba(57, 200, 16, 0.13)';
            }        
        }
        return { simulatedData, simulatedDataColor, simulatedIsBetter }
    };

    // Handles pay off time different font color states
    const handlePayOffTimeLabelColors = () => {
        let color = '#293861';
        let iconColor = undefined;
        if (summary.desiredMonthsToGoal < monthsToGoal) {
            color = '#38C810';
            iconColor = green_arrow;
        } else if (summary.desiredMonthsToGoal > monthsToGoal) {
            color = '#CF2435';
            iconColor = red_arrow;
        };
        return { color, iconColor };
    };

    const handleInterestsEarnedLabelColors = () => {
        let color = '#293861';
        let iconColor = undefined;
        if (summary.interestsSaved > 0) {
            color = '#38C810';
            iconColor = green_arrow_up;
        }
        else {
            if (summary.interestsSaved < 0) {
                color = '#CF2435';
                iconColor = red_arrow_down;
            }
        };
        return { color, iconColor };
    }

    const handleTotalContributionLabelColor = () => {
        let color = '#293861';
        let iconColor = undefined;
        if (Math.round(summary.desiredTotalContribution) < Math.round(summary.totalContribution)) {
            color = '#38C810';
            iconColor = green_arrow;
        }
        else {
            if (Math.round(summary.desiredTotalContribution) > Math.round(summary.totalContribution)) {
                color = '#CF2435';
                iconColor = red_arrow;
            }
        }
        return { color, iconColor };
    }

    // Handles Configuration Buttons Logic
    const { setup, onSetup, simu, onSimu, lvl, onLvl } = useConfigButtons();

    const setUpContent = (
        <>
            <EFStepperInput
                label='Savings Goal'
                onChange={onChangeGoal}
                value={savingGoal}
                prefix='$'
                includeThousandsSeparator
                dynamicStepEnabled
                minVal={0}
                maxVal={100000}
            />
            <EFStepperInput
                label='Saving Term (Months)'
                onChange={onChangeTerm}
                value={monthsToGoal}
                minVal={1}
                maxVal={1000}
            />
            <EFStepperInput
                label='Interest Rate'
                onChange={onChangeInterest}
                value={annualReturnRate}
                allowDecimal
                numStepper={.25}
                suffix='%'
                minVal={0}
                maxVal={18}
                // was defined as 15 but we have data coming from backend at 18 ?
                tooltip={'Can be up to 18%'}
            />
            <EFStepperInput
                label='Current Savings'
                onChange={onChangeCurrentSavings}
                value={currentSavings}
                prefix='$'
                includeThousandsSeparator
                dynamicStepEnabled
                minVal={0}
                maxVal={100000}
            />
        </>
    );

    const simuContent = (
        <>
            <Stack tokens={{ childrenGap: 5 }}>
                <EFStepperInput
                    label='Desired Monthly Savings'
                    onChange={(val: number) => setDesiredMonthlySaving(val)}
                    value={Math.round(desiredMonthlySaving)}
                    prefix='$'
                    includeThousandsSeparator
                    dynamicStepEnabled
                    minVal={0}
                    maxVal={100000}
                />
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='Minimum savings: $'
                    title=''
                    value={Math.round(monthlySaving)}
                    color='#293861'
                    valueFontSize={10}
                />
            </Stack>
            <EFStepperInput
                label='Desired Interest Rate'
                onChange={(val: number) => setDesiredInterestRate(val)}
                value={desiredInterestRate}
                allowDecimal
                numStepper={.25}
                suffix='%'
                minVal={0}
                maxVal={18}
                // was defined as 15 but we have data coming from backend at 18 ?
                tooltip={'Can be up to 18%'}
            />
        </>
    );

    return (
        <>
            <SavingGoalOnboarding
                handleModal={props.handleModal}
                setSavingGoal={onChangeGoal}
                setMonthsToGoal={onChangeTerm}
                setInterest={onChangeInterest}
                setCurrentSavings={onChangeCurrentSavings}
            />
            <SaveChangesModal
                openLifeEventSaveModal={props.lifeEventDetails.openSaveModal}
                onClose={props.lifeEventDetails.onOpenSaveModal}
            >
                <SavingGoalInterstitial
                    lifeEventDetails={props.lifeEventDetails}
                    savingTerm={summary.desiredMonthsToGoal}
                    onClick={props.lifeEventDetails.onSaveLifeEvent}
                    onCloseClick={props.lifeEventDetails.onOpenSaveModal}
                />
            </SaveChangesModal>
            {props.currentView === 'Connect the dots' &&
                <Stack horizontalAlign='center' tokens={{ childrenGap: 20 }}>
                    <Stack>
                        <Stack horizontal horizontalAlign='center' className={classes.dividercontainer} tokens={{ childrenGap: 30 }}>
                            <Divider
                                isOpen
                                title='Savings Goal'
                                icon={require('../../../assets/icons/saving_icon.svg')}
                            />
                        </Stack>
                        <Stack horizontalAlign='center'>
                            <DividerContainer>
                                <Stack wrap horizontal horizontalAlign='center' tokens={{ childrenGap: 30 }}>
                                    <Stack className={classes.chart}>
                                        <LineChart
                                            maxXTicksLimit={10}
                                            datasets={{
                                                labels: savingMonths,
                                                datasets: [
                                                    {
                                                        label: 'Current',
                                                        data: currentProjection,
                                                        backgroundColor: 'rgba(247, 175, 2, 0.13)',
                                                        borderColor: 'rgba(247, 175, 2, 0.13)',
                                                        fill: true,
                                                    },
                                                    {
                                                        label: 'Desired',
                                                        data: handleSimulatedData().simulatedData,
                                                        backgroundColor: handleSimulatedData().simulatedDataColor,
                                                        borderColor: handleSimulatedData().simulatedDataColor,
                                                        fill: true,
                                                    },
                                                ]
                                            }}
                                        />
                                    </Stack>
                                    <Stack horizontalAlign='center' tokens={{ childrenGap: 20 }} className={classes.report}>
                                        <ResultReport
                                            isGreen={handleSimulatedData().simulatedIsBetter}
                                            isNeutral={handleSimulatedData().simulatedData === null}
                                            onChange={() => null}
                                            reportGreenImg={savingsImage}
                                            reportRedImg={savingsImage}
                                            reportGreenLabel='Congrats! You have reached your savings goal early!'
                                            reportNeutralLabel="You have reached your goal on time!"
                                            reportRedLabel='Wait! Check your savings rate and monthly saving amount so you can reach your goal on time!'
                                        />
                                        <Stack tokens={{ childrenGap: 15 }} style={{ width: '30rem', marginTop: '5rem' }}>
                                            <MaskedLabelPair
                                                isBulleted={false}
                                                includeThousandsSeparator
                                                title='Savings Balance'
                                                value={savingGoal}
                                                color='#293861'
                                            />
                                            <MaskedLabelPair
                                                isBulleted={false}
                                                includeThousandsSeparator={false}
                                                prefix=''
                                                suffix=' Months'
                                                title='Saving Term'
                                                value={summary.desiredMonthsToGoal}
                                                color={handlePayOffTimeLabelColors().color}
                                                includeIcon={handlePayOffTimeLabelColors().iconColor}
                                                notAmt
                                            />
                                            <MaskedLabelPair
                                                isBulleted={false}
                                                includeThousandsSeparator={true}
                                                prefix='$'
                                                suffix=' '
                                                title='Interest Earned'
                                                value={Math.round(summary.interestsEarned)}
                                                color={handleInterestsEarnedLabelColors().color}
                                                includeIcon={handleInterestsEarnedLabelColors().iconColor}
                                                notAmt
                                            />
                                            <MaskedLabelPair
                                                isBulleted={false}
                                                includeThousandsSeparator={true}
                                                prefix='$'
                                                suffix=' '
                                                title='Total Contribution'
                                                value={Math.round(summary.desiredTotalContribution)}
                                                color={handleTotalContributionLabelColor().color}
                                                includeIcon={handleTotalContributionLabelColor().iconColor}
                                                notAmt
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </DividerContainer>
                        </Stack>
                    </Stack>
                    <Stack horizontal horizontalAlign='center' tokens={{ childrenGap: 40 }}>
                        <ConfigButton type='setup' toggleFocus={setup} onClick={onSetup} />
                        <ConfigButton type='simu' toggleFocus={simu} onClick={onSimu} />
                        <ConfigButton type='levelup' toggleFocus={lvl} onClick={onLvl} />
                    </Stack>
                    {setup && (
                        <Stack horizontal horizontalAlign='center' tokens={{ childrenGap: 10 }}>
                            <EFAccordion title=''>
                                {setUpContent}
                            </EFAccordion>
                        </Stack>
                    )}
                    {simu && (
                        <Stack horizontal horizontalAlign='center' tokens={{ childrenGap: 10 }}>
                            <EFAccordion title=''>
                                {simuContent}
                            </EFAccordion>
                        </Stack>
                    )}
                    {lvl && (
                        <Stack horizontal horizontalAlign='center'>
                            <EFLabel style={{ color: '#9EA4B3', margin: 40 }}>No advanced options</EFLabel>
                        </Stack>
                    )}
                </Stack>}
            {props.currentView === 'Low Down' && <StaticContainer url="https://content.equalfuture.com/blog/Savings%20Goals/lowdown" />}
            {props.currentView === 'Nitty Gritty' && <StaticContainer url="https://content.equalfuture.com/blog/Savings%20Goals/nittygritty" />}
        </>
    )
};

export default SavingGoal;