import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, isSessionValid, exact, ...rest }) => {
  return (
    <Route
    exact={exact}
      {...rest}
      render={({ location }) =>
        isSessionValid &&
        // Checking if object is not empty
        !(
          Object.keys(isSessionValid).length === 0 &&
          isSessionValid.constructor === Object
        ) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/expired",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
