import React from 'react';

import { Label } from 'office-ui-fabric-react';
import EFTextField from '../../components/Atomic/EFTextField/EFTextField';
import Dropdown from '../../components/Atomic/Dropdown/Dropdown';

import classes from './CompleteProfilePage.module.css';


export interface IQuestion {
    question: string;
    label1?: string;
    label2?: string;
    getLifeEvent?: (answer: string, answer2?: string) => { icon: string, title: string, subtitle: string, detail?: string };
}

interface Props {
    question: string;
    placeholder: string;
    label1?: string;
    label2?: string;
    subtext?: string;
    isDropdown: boolean,
    options: any,
    answer: string;
    answer2?: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleChange2?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    dropdownSetValue?: (option: string) => void;
}

const Question: React.FC<Props> = ({ question, placeholder, label1, label2,
    isDropdown, options, subtext, answer, answer2, handleChange, handleChange2, dropdownSetValue }) => {

    if (label2) {
        return (
            <>
                <p className={classes.question}>{question}</p>
                <div className={classes.answer}>
                    <div className={classes.multipleQuestions}>
                        <div>
                            <Label className={classes.fieldLabel}>{label1}</Label>
                            <EFTextField
                                width='12rem'
                                type='text'
                                placeholder={placeholder}
                                placeholderColor='rgba(0, 0, 0, 0.31)'
                                value={answer}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <Label className={classes.fieldLabel}>{label2}</Label>
                            <EFTextField
                                width='12rem'
                                type='text'
                                placeholder={placeholder}
                                placeholderColor='rgba(0, 0, 0, 0.31)'
                                value={answer2}
                                onChange={handleChange2}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    } else {

        return (
            <>
                <p className={classes.question}>{question}</p>
                <div className={classes.answer}>
                    {isDropdown ?
                        <Dropdown options={options} value={answer}
                            selectedOptionHandler={dropdownSetValue} />
                        :
                        <EFTextField
                            width='100%'
                            type='text'
                            placeholder={placeholder}
                            placeholderColor='rgba(0, 0, 0, 0.31)'
                            value={answer}
                            onChange={handleChange}
                        />}
                    <p className={classes.subtext}>{subtext}</p>
                </div>
            </>
        )
    }

}

export default Question;
