import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../EFLabel/EFLabel';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import classes from './CurrencyInputAlt.module.css';
import x from '../../../../assets/icons/x.svg';

interface IProps {
    value: number | string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    name?: string;
    stackClassName?: string;
    style?: object;
    onClickX?: () => void;
};

const CurrencyInputAlt: React.FC<IProps> = props => {
    const [showX, setShowX] = useState(false);

    return (
        <Stack 
            horizontal 
            horizontalAlign='center'
            className={classes.container}
            onMouseEnter={() => props.onClickX && setShowX(true)}
            onMouseLeave={() => props.onClickX && setShowX(false)}
        >
            <MaskedInput
                style={props.style}
                name={props.name}
                mask={numberMask}
                placeholder={'$0'} 
                value={props.value}
                onChange={props.onChange}
                className={`${classes.maskedInput} 
                    ${props.stackClassName}`}
            />
            {props.onClickX &&
                <Stack 
                    verticalAlign='center' 
                    style={{ marginLeft: '1rem'}}
                >
                    <img
                        alt="clear-value"
                        src={x}
                        className={`${classes.x} ${showX && classes.showX}`}
                        onClick={props.onClickX}
                    />
                </Stack>
            }
        </Stack>
    )
};

// Number mask used for USD currency
// Can move these as props for more customization
const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowLeadingZeroes: false,
    allowNegative: false,
});

export default CurrencyInputAlt;
