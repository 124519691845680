import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import classes from './EmergencyButton.module.css';
import EFLabel from '../../../../Atomic/EFLabel/EFLabel';

interface IProps {
    title?: string,
    value: number,
    onClick: () => void,
};

const EmergencyButton: React.FC<IProps> = props => {

    return (
        <Stack 
            className={classes.container} 
            onClick={props.onClick} 
            horizontalAlign='center'
            tokens={{childrenGap: 30}}
        >
            <Stack horizontalAlign='center' verticalAlign='center'>
                <img 
                    className={classes.pig} 
                    alt='piggy' 
                    src={require('../../../../../assets/images/scoop/emergency_fund.svg')} 
                />
                <p className={classes.value}>{`${props.value}%`}</p>
            </Stack>
            <Stack className={classes.label} horizontal verticalAlign='center'>
                <EFLabel style={{fontWeight: 'normal'}}>
                    {props.title}
                </EFLabel>
                <span className={classes.icon} />
            </Stack>
        </Stack>
    )
};

EmergencyButton.defaultProps = {
    title: 'EMERGENCY FUND',
};

export default EmergencyButton;
