import React, { useState, useEffect } from 'react';
import classes from '../Dashboards.module.css';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';
import BarChart from '../../../Charts/BarChart/BarChart';
import DoughnutChart from '../../../Charts/DoughnutChart';
import ScoopAccordion from '../../ScoopAccordion/ScoopAccordion';
import Category from '../../CategoryList/Category';
import EFDropdown from '../../../Atomic/EFDropdown/EFDropdown';
import { generateColorShadeSet, addAlpha } from '../../../../utils/colorGenerators';
import { chartOrganizedMonthNames } from '../../../../utils/dateTools';
import { useInvestmentsData } from '../../../../hooks/ScoopDashboards/useInvestmentsData';
import DashboardHeader from '../../DashboardHeader/DashboardHeader';
import EmptyTransactions from '../../EmptyTransactions/EmptyTransactions';
import { useResponsive } from '../../../../hooks/useResponsive';

interface IProps {
    accBalancesData: any,
};

const Investments: React.FC<IProps> = props => {
    const { screenIsAtMost } = useResponsive();
    const isMobile = screenIsAtMost('mobile');
    const [toYearly] = useState<boolean>(false);
    const {
        fullMonths,
        chartMonths,
        chartMonthsYrly,
        sortedData,
        filteredAccsData,
        setFilteredAccsData,
        monthlySums,
        getBalancesByMonth,
        yearToDateByAccount,
        totalYearToDate,
    } = useInvestmentsData(props.accBalancesData, toYearly);
    const [monthByIdx, setMonthByIdx] = useState<number>(11);
    const allCategoryLabel = 'All Categories';
    const allCategoryColor = '#22CBA7';
    const [category, toggleCategory] = useState<string>(allCategoryLabel);
    const months = toYearly ? chartOrganizedMonthNames : chartMonths;

    const [barColor, setBarColor] = useState<string>(allCategoryColor);
    const barOffSelectColor = addAlpha(barColor, .3);
    const barSelectColor = barColor;
    // Used to map the bar colors in barColors and updateChart
    const monthSet = toYearly ? chartMonthsYrly : months;
    const barColors = monthSet.map((_, i) => (i !== monthByIdx) ? barOffSelectColor : barSelectColor);
    const doughnutColors = generateColorShadeSet(sortedData && sortedData.length, '#297A63');

    useEffect(() => {
        toYearly ? setMonthByIdx(0) : setMonthByIdx(11);
        toggleCategory(allCategoryLabel);
    }, [toYearly]);
    
    const getBaseCategories = () => {
        if (sortedData) {
            return sortedData.map((acc: any, i: any) => ({
                key: acc.account.accountName,
                text: acc.account.accountName,
                color: doughnutColors[i],
                icon: require('../../../../assets/images/scoop/piggy.svg'),
                focused: category === acc.account.accountName,
                value: acc.historicalBalances[monthByIdx] ? acc.historicalBalances[monthByIdx].amount : 0,
            }));
        } else {
            return [];
        };
    };

    const getBaseCategoriesYrly = () => {
        if (sortedData) {
            return sortedData.map((acc: any, i: any) => ({
                key: acc.account.accountName,
                text: acc.account.accountName,
                color: doughnutColors[i],
                icon: require('../../../../assets/images/scoop/piggy.svg'),
                focused: category === acc.account.accountName,
                value: yearToDateByAccount[i],
            }));
        } else {
            return [];
        };
    };


    let categoryData = [
        { 
            key: allCategoryLabel, 
            text: allCategoryLabel, 
            color: allCategoryColor,
            icon: require('../../../../assets/images/scoop/all_invest.svg'), 
            focused: category === allCategoryLabel, 
            value: undefined
        },
        ...toYearly ? getBaseCategoriesYrly() : getBaseCategories(),
    ];

    const categories = categoryData.map(e => (
        <Stack key={e.key}>
            <Category
                id={e.key}
                title={e.text}
                color={addAlpha(e.color, .3)}
                icon={e.icon}
                iconColor={e.text === allCategoryLabel ? '' : e.color}
                focused={e.focused}
                onClick={() => handleCategoryChange(e.text, e.color)}
                value={e.value} 
            />
        </Stack>
    ));

    // Handles filtering data for particular category + switches category view
    const handleCategoryChange = (title: string, color: string) => {
        toggleCategory(title);
        setBarColor(color);
        let filtered = sortedData && sortedData.filter((acc: any) => (title === acc.account.accountName));
        if (title === allCategoryLabel) {
            filtered = sortedData && sortedData;
        };
        setFilteredAccsData(filtered);
    };

    // Handles transaction list relative to category
    const transactionList = (title: string) => {
        const labelStyle = { fontSize: '2rem', fontWeight: 'normal', textAlign: 'center', margin: '1.5rem 0' };
        const getCurrentCategory = getBaseCategories().find((e: any) => title === e.text);
        const allCategoriesIsEmpty = getBaseCategories().every((category: any) => !category.value);
        
        return (
            <>
                {isMobile ? <></> : <EFLabel style={labelStyle}>{title}</EFLabel>}
                {(title === allCategoryLabel) && allCategoriesIsEmpty ? (
                    <EmptyTransactions
                        icon={categoryData[0].icon}
                        color={categoryData[0].color}
                        text={`We haven't found anything in ${fullMonths[monthByIdx]}.`}
                    /> 
                ) : getCurrentCategory && !getCurrentCategory.value ? (
                    <EmptyTransactions
                        hideAddAccs
                        icon={''}
                        color={getCurrentCategory.color}
                        text={`We haven't found anything for ${title} in ${fullMonths[monthByIdx]}.`}
                    />
                ) : (
                    filteredAccsData && filteredAccsData.map((acc: any, i: any) => (
                        <Stack key={Math.random()}>
                            <ScoopAccordion
                                id={acc.id}
                                title={acc.account.accountName} 
                                color={doughnutColors[i]} 
                                value={acc.historicalBalances[monthByIdx] ? filteredAccsData && filteredAccsData[i].historicalBalances[monthByIdx].amount : 0}
                                isNotOpenable
                                style={{ border: filteredAccsData.length - 1 === i && 'unset' }}
                            />
                        </Stack>
                    ))
                )}
            </>
        )
    };

    const mobileCategories = (
        <Stack tokens={{childrenGap: '2rem'}} className={classes.modalCategories}>
            {categoryData.map((currCategory) => (
                <Stack
                    style={{ width: '68vw' }}
                    horizontalAlign="center"
                    verticalAlign="center"
                >
                <Category
                    id={currCategory.key}
                    title={currCategory.text}
                    color={addAlpha(currCategory.color, 0.3)}
                    icon={currCategory.icon}
                    focused={currCategory.focused}
                    onClick={() => handleCategoryChange(currCategory.text, currCategory.color)}
                    value={currCategory.value}
                    disableAnimation
                    style={{ width: '90%', height: '5.2rem', borderRadius: '2.9rem' }}
                    imgStyle={{ width: '3.5rem', height: '3.5rem', margin: '0 2rem' }}
                />
                {currCategory.text === category ? 
                    <Stack 
                        style={{
                            margin: "1.5rem 3rem",
                            border: "1px solid #F0F0F0",
                            borderRadius: "1.4rem",
                            width: '90%',
                        }}
                    >
                        {transactionList(category)}
                    </Stack> : <></>}
                </Stack>
            ))}
        </Stack>
    )

    function updateChart (this: any, e: any) {
        const element = this.getElementAtEvent(e);
                    
        if (element[0] && element.length) {
            const chartIdx = element[0]._index;
            const chartProps = element[0]._chart.config.data.datasets[0];
            setMonthByIdx(chartIdx);
            
            chartProps.data.forEach((_: any, i: number) => {
                element[0]._chart.config.data.datasets[0].backgroundColor[i] = barOffSelectColor;
                if (i === chartIdx) {
                    element[0]._chart.config.data.datasets[0].backgroundColor[i] = barSelectColor;
                }
            });
            this.update();
        };
    }

    return (
        <Stack horizontalAlign={isMobile ? "center" : "" as any} className={isMobile ? classes.outer_container_mobile : classes.outer_container}>
            <DashboardHeader 
                title={toYearly ? 'Year To Date' : 'Total Portfolio Value'}
                value={toYearly ? totalYearToDate : monthlySums[monthByIdx]}
                valueColor='#49CBA7'
                prevVal={toYearly ? 0 : monthlySums[monthByIdx - 1]}
            />
             <Stack className={classes.inner_container} tokens={{childrenGap: '2rem'}}>
                <Stack horizontalAlign={isMobile ? "center" : "space-between"} wrap={isMobile} horizontal className={isMobile ? classes.charts_mobile : classes.charts} verticalAlign='center'>
                    <Stack className={isMobile ? classes.bar_view_mobile : classes.bar_view} tokens={{childrenGap: 10}}>
                        {/* <Stack horizontal horizontalAlign='space-evenly'>
                            <TimeButton text='YEAR TO DATE' isFocused={toYearly} onClick={() => { setToYearly(true); setMonthByIdx(11);}} />
                            <TimeButton text='MONTHLY' isFocused={!toYearly} onClick={() => { setToYearly(false); setMonthByIdx(11);}} />
                        </Stack> */}
                        <BarChart
                            fontSize={isMobile ? 8 : undefined}
                            barPercentage={isMobile ? 0.65 : 0.40} 
                            datasets={{
                                labels: months,
                                datasets: [
                                    {
                                        label: 'Total Investments',
                                        data: monthlySums.map((e: any) => Math.round(e * 1e2) / 1e2),
                                        backgroundColor: barColors,
                                        borderColor: 'transparent',
                                    },
                                ]
                                }}
                                onClick={updateChart}
                        />
                    </Stack>
                    <Stack className={isMobile ? classes.doughnut_view_mobile : classes.doughnut_view} verticalAlign='center' horizontalAlign='center' tokens={{childrenGap: 10}}>
                        <EFDropdown 
                            defaultSelectedKey={category} 
                            style={{width: '17rem'}} 
                            options={categoryData}
                            onChange={(e: any, item: any) => handleCategoryChange(item.text, item.color)} 
                        />
                        <DoughnutChart 
                            datasets={{
                            labels: filteredAccsData ? filteredAccsData.map((acc: any) => acc.account.accountName) : [],
                            datasets: [
                                {
                                    label: 'Investments For Month',
                                    data: toYearly ? yearToDateByAccount.map((e: any) => Math.round(e * 1e2) / 1e2) : getBalancesByMonth(monthByIdx).map((e: any) => Math.round(e * 1e2) / 1e2),
                                    backgroundColor: doughnutColors,
                                    borderColor: 'transparent',
                                },
                            ]
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign='center' tokens={{childrenGap: '6rem'}}>
                    {isMobile ? mobileCategories : (
                        <>
                            <Stack className={classes.categories} tokens={{childrenGap: '1.2rem'}}>
                                {categories}
                            </Stack>
                            <Stack className={classes.breakdown_container}>
                                {transactionList(category)}
                            </Stack>
                        </>
                    )}
                </Stack>
             </Stack>
        </Stack>
    )
};

export default Investments;
