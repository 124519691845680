import { Stack } from 'office-ui-fabric-react';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import EFCard from '../../components/Atomic/EFCard/EFCard';
import NewPlaylistButton from '../../components/Planning/Playlist/NewPlaylistButton';
import PlaylistWidget from '../../components/Planning/Playlist/PlaylistWidget';
import DashboardContainer from '../../components/UI/DashboardContainer/DashboardContainer';
import EFBreadcrumb from '../../components/UI/EFBreadcrumb';
import Greeting from '../../components/UI/Greeting';
import NavigationView from '../../components/UI/NavigationView';
import SelectBubble from '../../components/UI/SelectBubble';
import { useResponsive } from '../../hooks/useResponsive';

import classes from './MyPlaylistPage.module.css';
import animations from "../../utils/animations.module.css";
import { handleSideNavigation, isCanonical } from '../../utils/global';
import interstitialImage from '../../assets/images/planInterstitial.svg';
import Interstitial from '../../components/Atomic/Interstitial/Interstital';

import maleAvatar from "../../assets/images/maleAvatar.svg";
import staceyAvatar from "../../assets/images/staceyAvatar.png";
import rosaAvatar from "../../assets/images/rosaAvatar.png";
import darylAvatar from "../../assets/images/darylAvatar.png";
import martinAvatar from "../../assets/images/martinAvatar.svg";
import eveAvatar from "../../assets/images/eveAvatar.svg";
import addToPlaylistButtonWidget from '../../assets/images/planning/playlist/addToPlaylistWidgetButton.svg';

import { eUserSettings } from '../../components/UserSettings/UserSettings';
import Backdrop from '../../components/Atomic/Backdrop/Backdrop';
import ProfileSwitcher from '../../components/UI/ProfileSwitcher/ProfileSwitcher';
import * as careerActions from "../../store/actions/CareerPlanning";
import LifeEventPicker from '../../components/LifeEvents/LifeEventPicker/LifeEventPicker';

import close from '../../assets/images/close.svg';
import EFButton from '../../components/Atomic/EFButton/EFButton';
import { clearCanonicalProfile, createPlan, deletePlan } from '../../store/actions/Users';
import EFTextField from '../../components/Atomic/EFTextField/EFTextField';
import { getPlanNameById, planNames } from '../../components/Planning/MyPlanCards';
import PlanTransferPrompt from '../../components/Planning/PlanTransferPrompt/PlanTransferPrompt';
import { showTransferConfirmedPrompt } from '../../store/actions/Screens';
import { duplicationAllowed } from '../../utils/LifeEventTools/Mappers';
import OkCancelDialog from '../../components/UI/OkCancelDialog/OkCancelDialog';
import { workEvents } from '../../components/LifeEvents/LifeEventsData';
import TagManager from 'react-gtm-module'

const MyPlaylistPage: React.FC<{}> = () => {

    const dispatch = useDispatch();
    let history = useHistory();
    const { screenIsAtLeast, screenIsAtMost } = useResponsive();

    const user = useSelector((state: any) => state.session.user);
    const realUser = useSelector((state: any) => state.data.realUser);
    const selectedJob = useSelector((state: any) => state.career.jobInfo);

    const numConnectedAccounts =
        Object.keys(user) && Object.keys(user)?.length > 0
            ? user?.connectedAccounts?.length
            : 0;

    const [newPlaylistName, setNewPlaylistName] = useState("");
    const [planId, setPlanId] = useState(""); //Couldn't find another way...

    const [showCarousel, setShowCarousel] = useState(true);
    const [profileTab, setProfileTab] = useState(eUserSettings.PROFILE);
    const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);
    const [openLEModal, setLEModal] = useState(false);
    const [showNewPlaylistModal, setShowNewPlaylistModal] = useState(false);
    const [showPlanTransferModal, setShowPlanTransferModal] = useState(false);
    const [affordabilityRouteBlocker, setAffordabilityRouteBlocker] = useState(false);

    // Google analytics set up for page
    const tagManagerArgs = {
        dataLayer: {
            userId: realUser?.trackingId,
            page: 'My Playlist',
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    // Checks to see if user is allowed to enter affordability (user can't if they don't have career goal set up)
    const checkCareerGoalForAffordability = (externalId: string, job: any) => externalId === '15cY0o7S7hwiHdzTNLxNJL' && !job;

    const onProfileSwitch = (name: string) => {
        //we check if the profile that we are going to render has the Career Goal LE
        //we know that for CP, only Stacey has it
        if (name !== "" && name !== "STACEY") {
            dispatch(careerActions.removeCurrentJob());
        }
        else {
            if (name === "STACEY") {
                dispatch(careerActions.getJobInfo(15 - 1299.08));
            }
            else {
                const careerGoalLE = realUser.lifeEvents.find(
                    (le: any) => le.externalId === "6ceIi2ikmezTJtxIDEuRsg" && le.status !== "NEW"
                );
                if (careerGoalLE) {
                    const getOnetCode = careerGoalLE.customData.selectedCareerOnetCode;
                    dispatch(careerActions.getJobInfo(getOnetCode));
                } else {
                    dispatch(careerActions.removeCurrentJob());
                }
            }
        }

        setProfileTab(eUserSettings.PROFILE);
        setShowProfileSwitcher(false);
    };

    const cardWidgetClickHandler = (event: any) => {
        // Handles Affordability (when Career Goal has not been set up) case
        if (checkCareerGoalForAffordability(event.externalId, selectedJob)) {
            setAffordabilityRouteBlocker(true);
            return
        }
        history.push('/connect/lifeevent/' + event.id);
    };

    const lifeEventPickerClickHandler = (le: any) => {
        const isDuplicable = duplicationAllowed(le.id);
        const existingLE = user.lifeEvents?.find((e: any) => e.externalId === le.id);

        // Handles Affordability (when Career Goal has not been set up) case
        if (checkCareerGoalForAffordability(le.id, selectedJob)) {
            setAffordabilityRouteBlocker(true);
            return
        }

        if (!isDuplicable && existingLE) {
            history.push('/connect/lifeevent/' + existingLE.id);
        } else {
            const payload = {
                status: "NEW",
                name: le.title,
                icon: [{
                    url: le.img
                }],
                id: "",
                externalId: le.id,
                relatedPlanId: planId
            }

            if (planId !== "") {
                setPlanId("");
            }

            history.push({
                pathname: '/connect/lifeevent',
                state: payload
            })
        }
    };

    const handleLEModal = () => {
        setLEModal(!openLEModal);
        window.scrollTo(0, 0);

        if (planId !== "") {
            setPlanId("");
        }
    };

    const copyPlan = () => {
        let lifeEventPlans = [];
        user.lifeEvents.forEach((lifeEvent) => {
            if (lifeEvent.relatedPlanId) {
                let lifeEventData = {
                    name: lifeEvent.name,
                    customData: lifeEvent.customData,
                    lifeEventId: lifeEvent.id,
                    date: lifeEvent.date,
                    planName: getPlanNameById(lifeEvent.relatedPlanId),
                    startDate: Date.now(),
                    status: "NEW",
                };
                lifeEventPlans.push(lifeEventData);
            }
        });
        dispatch(createPlan(lifeEventPlans[0].planName, lifeEventPlans, history, true));
    };

    const openConnectedAccounts = () => {
        if (user.anonymized) return;
        setProfileTab(eUserSettings.CONNECTED_ACCOUNTS);
        setShowProfileSwitcher(true);
        setShowCarousel(false);
    };

    let content = null;

    if (Object.keys(user).length > 0) {

        let profiles = [
            {
                name: "Stacey",
                img: staceyAvatar,
                label: "Planning for College",
                profileNumber: 2,
            },
            {
                name: "Rosa",
                img: rosaAvatar,
                label: "Begin Career",
                profileNumber: 3,
            },
            {
                name: "Daryl",
                img: darylAvatar,
                label: "Tools for Parents",
                profileNumber: 4,
            },
            {
                name: "Martin",
                img: martinAvatar,
                label: "Buy a House",
                profileNumber: 5,
            },
            {
                name: "Eve",
                img: eveAvatar,
                label: "Planning for Retirement",
                profileNumber: 6,
            },
        ];

        let selectCardContent = {
            name: user.name,
            img: !user.anonymized
                ? maleAvatar
                : user.name.length > 0
                    ? profiles.filter(
                        (profile) =>
                            profile.name.toLowerCase() === user.name.toLowerCase()
                    )[0].img
                    : "",
            label: !user.anonymized
                ? ""
                : user.name.length > 0
                    ? profiles.filter(
                        (profile) =>
                            profile.name.toLowerCase() === user.name.toLowerCase()
                    )[0].label
                    : "",
            profileNumber: !user.anonymized
                ? 2
                : profiles.filter(
                    (profile) =>
                        profile.name.toLowerCase() === user.name.toLowerCase()
                )[0].profileNumber - 1
        };

        content = (
            <div
                className={`${screenIsAtMost('tabLand') ? classes.containerMobile : [classes.container, classes.treeBackground].join(' ')}`}
            >

                {affordabilityRouteBlocker && (
                    <OkCancelDialog
                        isOpen={affordabilityRouteBlocker}
                        onClose={() => setAffordabilityRouteBlocker(false)}
                        onConfirmClick={() => lifeEventPickerClickHandler(workEvents[0])}
                    />
                )}

                {openLEModal &&
                    <LifeEventPicker
                        onBackdropClick={handleLEModal} onExitClick={handleLEModal}
                        onLifeEventClick={lifeEventPickerClickHandler} openModal={openLEModal} />
                }

                {showPlanTransferModal && (
                    <Backdrop
                        customStyle={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => setShowPlanTransferModal(false)}
                    >
                        <PlanTransferPrompt
                            onConfirmClick={copyPlan}
                            onRedirectToProfileClick={() => {
                                setShowPlanTransferModal(false);
                                dispatch(clearCanonicalProfile(history));
                                dispatch(showTransferConfirmedPrompt(false));
                                history.replace("/connect/myplaylist");
                            }}
                            onClose={() => {
                                setShowPlanTransferModal(false)
                                dispatch(showTransferConfirmedPrompt(false));
                            }}
                        />
                    </Backdrop>
                )}

                {showProfileSwitcher && (
                    <Backdrop
                        customStyle={{
                            position: "fixed",
                            overflow: "auto",
                        }}
                        onClick={() => {
                            setShowCarousel(true);
                            setProfileTab(eUserSettings.PROFILE);
                            setShowProfileSwitcher(false);
                        }}
                    >
                        <ProfileSwitcher
                            hide={(hide: boolean) => { }}
                            startingProfile={selectCardContent.profileNumber}
                            onSelectedProfile={onProfileSwitch}
                            profileTab={profileTab}
                            showCarousel={showCarousel}
                            onClose={() => {
                                setShowCarousel(true);
                                setShowProfileSwitcher(false)}}
                        />
                    </Backdrop>
                )}

                {showNewPlaylistModal && <Backdrop
                    customStyle={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onClick={() => {
                        setShowNewPlaylistModal(false);
                        setNewPlaylistName("");
                    }}
                >
                    <div className={classes.newPlaylistContainer} onClick={(event) => event.stopPropagation()}>
                        <div className={classes.closeContainer}>
                            <img onClick={() => {
                                setShowNewPlaylistModal(false);
                                setNewPlaylistName("");
                            }} src={close} alt="close" style={{ width: '2.5rem', cursor: 'pointer' }} />
                        </div>
                        <div className={classes.textContainer}>
                            <p className={classes.newPlaylistTitle}>New Playlist</p>
                            <EFTextField
                                value={newPlaylistName}
                                placeholder='Name'
                                onChange={(e) => setNewPlaylistName((e.target as HTMLTextAreaElement).value)}
                                width="65%" />
                        </div>
                        <div className={classes.buttonsContainer} style={{ marginBottom: '4rem' }}>
                            <EFButton onClick={(event) => {
                                event.stopPropagation();
                                dispatch(createPlan(newPlaylistName, [], history));
                                setShowNewPlaylistModal(false);
                                setNewPlaylistName("");
                            }} text="CREATE" />
                        </div>
                    </div>
                </Backdrop>}

                <div
                    style={{ flexDirection: screenIsAtMost('tabLand') ? 'column' : 'row', alignItems: screenIsAtMost('tabLand') ? 'center' : '' }}
                    className={classes.content}
                >

                    {screenIsAtLeast("desktop") && (
                        <NavigationView
                            profile={user.anonymized}
                            connectedAccounts={numConnectedAccounts}
                            connectedKey="My Playlist"
                            onConnectedLinkClick={(event, item) => {
                                handleSideNavigation(item.name, history)
                            }}
                            onConnectButtonClick={() => history.push("/connect")}
                            onProtectButtonClick={() => history.push("/protect/securevault")}
                            onGrowButtonClick={() => history.push("/grow/overview")}
                            onConnectAccountClick={openConnectedAccounts}
                            currentDashboard="CONNECT"
                            selectCardContent={selectCardContent}
                            onSelectBubleClickHandler={() => {
                                setProfileTab(eUserSettings.PROFILE);
                                setShowProfileSwitcher(true);
                            }}
                        />
                    )}

                    {screenIsAtMost("tabLand") &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '85%', paddingTop: '3rem' }}>

                            <EFBreadcrumb
                                onParentClick={() => { history.push("/connect") }}
                                parentTitle="CONNECT"
                                profile={user.anonymized}
                                connectedAccounts={0}
                                connectedKey="CONNECT"
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect/securevault")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                childrenTitles={
                                    ["MY PLAYLIST"]
                                }
                                onConnectAccountClick={openConnectedAccounts}
                                style={{
                                    flexFlow: 'row',
                                    alignItems: 'center',
                                    width: '59%',
                                    justifyContent: 'space-between'
                                }}
                                onSelectBubleClickHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />

                            <Greeting
                                hideMenuLogo
                                hideSalutation
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={numConnectedAccounts}
                                connectedKey=""
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect/securevault")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                onConnectAccountClick={openConnectedAccounts}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />
                        </div>

                    }

                    <DashboardContainer childrenGap={10} style={{ alignItems: screenIsAtMost("tabLand") ? 'center' : '' }}>
                        <div className={classes.headerSection}>

                            {!screenIsAtMost("tabLand") &&
                                <EFBreadcrumb
                                    onParentClick={() => { history.push("/connect") }}
                                    parentTitle="CONNECT"
                                    profile={user.anonymized}
                                    connectedAccounts={0}
                                    connectedKey="CONNECT"
                                    onConnectedLinkClick={
                                        () => { }
                                    }
                                    onConnectButtonClick={() => { }}
                                    onProtectButtonClick={() => { }}
                                    onGrowButtonClick={() => { }}
                                    childrenTitles={
                                        ["MY PLAYLIST"]
                                    }
                                    onConnectAccountClick={() => { }}
                                />}

                            {!screenIsAtMost("tabLand") && <Greeting
                                hideSalutation
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={numConnectedAccounts}
                                connectedKey=""
                                onConnectedLinkClick={
                                    () => {

                                    }
                                }
                                onConnectButtonClick={() => {

                                }}
                                onProtectButtonClick={() => {

                                }}
                                onGrowButtonClick={() => {

                                }}
                                onConnectAccountClick={() => {

                                }}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />}

                        </div>

                        {!isCanonical(user.name) && <NewPlaylistButton onClick={() => {
                            setShowNewPlaylistModal(true);
                        }} />}


                        {(isCanonical(user.name) || user.plans?.length > 0) &&
                            <div className={classes.playlistSection} style={{ justifyContent: 'flex-start', marginTop: '0px', flexDirection: screenIsAtMost("tabLand") ? 'column' : 'row' }}>
                                {!isCanonical(user.name) && user.plans.map((plan, index) => {
                                    return (
                                        <>
                                            <EFCard
                                                style={{ justifyContent: 'flex-start' }}
                                                stackClassName={animations.cardShadowExpand}
                                                customStyle={{ marginTop: '0px' }}
                                                title=""
                                                onClick={() => { }}>
                                                <PlaylistWidget user={user} onCardClick={cardWidgetClickHandler}
                                                    planToRender={plan} showMoreActions showRetakeIcon={plan.type === 'main'} onRetakeClick={() => history.push('/myplansetup')}
                                                    showDeleteIcon={plan.type !== 'main'} onDeleteClick={() => dispatch(deletePlan(plan.planId, history))}
                                                    customStyle={{ cursor: 'pointer', paddingLeft: '2rem', overflowY: 'overlay', justifyContent: 'flex-start' }}
                                                    showCopiedIcon={planNames.includes(plan.name) ? true : false}
                                                />
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent', width: '100%', margin: '0px' }}>
                                                    <img src={addToPlaylistButtonWidget} alt="add" style={{
                                                        paddingRight: '2rem',
                                                        width: '15%',
                                                        alignSelf: 'center',
                                                        paddingTop: '3rem',
                                                        cursor: 'pointer',
                                                        marginTop: '-1rem',
                                                        marginBottom: '1rem'
                                                    }} onClick={event => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        setLEModal(true);
                                                        setPlanId(plan.planId);
                                                    }} />
                                                </div>
                                            </EFCard>
                                        </>

                                    );
                                })}
                                {isCanonical(user.name) &&
                                    <EFCard style={{ cursor: 'pointer', alignItems: 'flex-start', paddingLeft: '2rem', overflowY: 'overlay', justifyContent: 'flex-start' }}
                                        stackClassName={animations.cardShadowExpand}
                                        customStyle={{ marginTop: '0px' }}
                                        title=""
                                        onClick={() => { }}>
                                        <PlaylistWidget user={user} onCardClick={cardWidgetClickHandler} showPlusIcon onPlusClick={() => setShowPlanTransferModal(true)} />
                                    </EFCard>}
                            </div>}

                        {(!isCanonical(user.name) && user.plans?.length === 0) &&
                            <div className={classes.playlistSection}>
                                <Interstitial
                                    interstitialImg={interstitialImage}
                                    btnTxt="Let's do it"
                                    path="/myplansetup"
                                />
                            </div>}

                    </DashboardContainer>
                </div>
            </div>
        );
    }

    return content;
};

export default MyPlaylistPage;