export const getCustomData = (lifeEventName) => {
    let customData = {};

    switch (lifeEventName) {
        case 'Retire / Reboot': {
            customData = {
                annualContributionIRA: 0, 
                retirementSavingsBalance: 0,
                monthlyIncomeNeededAtRetire: 3500,
                startingAgeDistribution: 65,
                additionalSavingsCashInvestment: 5,
                estimatedSocialSecurityBenefit: 2393,
                employerContributionMatch401k: 100,
                monthlyContribution401k: 160,
                retirementAge: 65,
                employerUpToMatch401k: 6,
                monthlySalary: 4100,
                startAge: 32,
                annualReturnRate: 6,
                inflationRate: 3,
                lifeExpectancy: 92,
                investmentFees: 1,
                percentageChangeMonthlyContribution: 0,
                otherIncomes: 0,
                taxRateAtRetirement: 20,
            }
            return customData
        }
        case 'College Plan': {
            customData = {
                roomAndBoard: true,
                tuitionInflationRate: 3,
                roomAndBoardCost: 11140,
                yearsAttending: 4,
                otherCostInflationRate: 3,
                currentSavings: 10,
                schoolType: "PUBLIC_4",
                privateStudentLoanFunding: 0,
                studentLoanFunding: 3200,
                otherCost: 500,
                tuitionCost: 10230,
                entertainmentCost: 2500,
                transportationCost: 1000,
                annualReturnRate: 5,
                contributionGrowWithInflation: true,
                yearsToEnrollment: 18,
                monthlyContribution: 0,
                grantsScholarship: 22,
                sideHustleWork: 0,
            }
            return customData
        }
        case 'House':
        case 'Buy a House': {
            customData = {
                closingCost: 3,
                rentAppreciationRate: 3,
                city: "California",
                rateOfReturn: 3,
                monthlyRentInsurance: 20,
                monthlyHOAFees: 1,
                downPayment: 50000,
                monthlyRent: 1400,
                mortgageInterestRate: 3.75,
                homeownersInsuranceRate: 0.035000000000000003,
                incomeTaxRate: 3,
                loanTerm: 30,
                capitalGainsTaxRate: 3,
                housePrice: 250000,
                homeAppreciationRate: 3,
                inflationRate: 3,
                downPaymentRent: 2000,
                yearsToStayInHouse: 30,
                selectedOption: "BUY",
                homeMaintenance: 35,
                propertyTax: 0.73999999999999999
            }
            return customData
        }

        case 'Buy a Car': {
            customData = {
                payOffTime: 48,
                investmentRate: 3,
                buyDownPayment: 3500,
                saleTaxes: 5.75,
                tradeInValue: 0,
                simulationLength: 72,
                leaseDownPayment: 1000,
                interestRate: 4,
                monthlyPayment: 450,
                registrationFeesTaxes: 350,
                maintenance: 100,
                insurance: 150,
                selectedOption: "BUY",
                vehiclePrice: 30000
            }
            return customData
        }
        case 'Student Debt':
        case 'Manage Debt': {
            customData = {
                interestRate:18,
                payOffTime:19,
                currentPayment:205,
                studentDebtBalance:2231
            }
            return customData
        }
        case 'Affordability': {
            customData = {
                payOffTime: 120,
                interestRate: 5,
                studentDebtBalance: 50000,
                monthlyFutureIncome: 4166,
            }
            return customData
        }
        case 'Career Goal':
        case 'CAREER PLANNING TOOL': {
            customData = {
                selectedCareerTitle: '',
                selectedCareerOnetCode: '',
            }
            return customData
        }
        case 'Savings Goal': {
            customData = {
                savingGoal: 20000,
                annualReturnRate: 3,
                currentSavings: 2000,
                monthsToGoal: 60,
                desiredMonthlySaving: 0,
                monthlySaving: 0,
                desiredMonthsToGoal: 60,
                desiredAnualReturnRate: 3,
                interestsSaved: 0,
                desiredInterestsSaved: 0
            }
            return customData;
        }
        default: {
            return customData;
        }
    }
}