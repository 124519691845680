import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-doughnutlabel';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../utils/Masking';

interface Props {
    datasets?: object;
    valueLabel?: number;
    sumTotalLabel?: boolean;
    prefix?: string,
    suffix?: string,
    includeThousandsSeparator?: boolean,
    thousandsSeparatorSymbol?: string,
    allowDecimal?: boolean,
    decimalSymbol?: string,
    decimalLimit?: number,
    allowNegative?: boolean,
    allowLeadingZeroes?: boolean,
    labelFontSize?: string,
};

const DoughnutChart: React.FC<Props> = props => {
  return (
    <Doughnut
      data={props.datasets}
      options={{
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
            display: false,
        },
        elements: {
            arc: {
                borderWidth: 0
            },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          doughnutlabel: {
            labels: [
              {
                text: function(chart) {
                  let label = '';
                  if (props.valueLabel) {
                    label = maskValue(props.valueLabel, createNumberMask({ ...props }))
                  }
                  if (props.sumTotalLabel) {
                    const total = chart?.config?.data?.datasets[0]?.data.reduce((a, b) => a + b, 0)
                    label = maskValue(total, createNumberMask({ ...props }))
                  }
                  return label;
                },
                font: {
                  size: props.labelFontSize,
                  family: 'brandon-grotesque',
                  weight: 'bold',
                },
                color: '#323232'
              },
            ]
          }
        },
      }}
    />
  )
}
  
DoughnutChart.defaultProps = {
    datasets: {
    labels: ['1', '2'],
    datasets: [
            {
            label: 'SOME-CATEGORY',
            data: [20, 16, 40, 30],
            backgroundColor: ['#56C1EF', '#E15EAB', '#8498FF', '#84ED78'],
            },
        ]
    },
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 2,
    allowNegative: true,
    allowLeadingZeroes: false,
    labelFontSize: '40',
}

export default DoughnutChart
