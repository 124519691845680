import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import Modal from 'react-modal';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import { Stack } from 'office-ui-fabric-react';
import EFButton from '../../Atomic/EFButton/EFButton';
import { useResponsive } from '../../../hooks/useResponsive';

import classes from './RouteLeaveWarningGuard.module.css';
import saveIcon from '../../../assets/images/save.svg';

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  onConfirm?: () => void;
  onCloseDiscard?: () => void;
  fwdText?: string;
  bwdText?: string;
}

const RouteLeaveWarningGuard = ({ when, navigate, shouldBlockNavigation, onConfirm, onCloseDiscard, fwdText, bwdText }: Props) => {
  const { screenIsAtLeast } = useResponsive();

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    onConfirm();
  }; 

  const handleDiscardConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    onCloseDiscard();
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal
        isOpen={modalVisible}
        onRequestClose={handleDiscardConfirmNavigationClick}
        style={modalCardStyle}
      >
          <Stack verticalAlign='center' className={`${classes.container} ${!screenIsAtLeast('tabPort') && classes.containerMobile}`}>
            <Stack tokens={{childrenGap: '3.5rem'}} horizontalAlign='center'>
              <img className={classes.img} alt='save' src={saveIcon} />
              <EFLabel style={{fontSize: '2.4rem', fontWeight: 'normal'}}>Would you like to save your changes?</EFLabel>
              <Stack horizontal tokens={{childrenGap: '1.5rem'}}>
                <EFButton text={bwdText} onClick={handleDiscardConfirmNavigationClick} style={{ backgroundColor: '#FFF', color: '#FEB81E', width: '15.5rem'}} />
                <EFButton text={fwdText} onClick={handleConfirmNavigationClick} style={{ width: '15.5rem'}} />
              </Stack>
            </Stack>
          </Stack>
      </Modal>
    </>
  );
};

RouteLeaveWarningGuard.defaultProps = {
  fwdText: 'SAVE',
  bwdText: 'DISCARD',
}

const modalCardStyle = {
    content: {
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: 'fit-content',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
        marginTop: '2rem',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)',
        zIndex: 30000000000,
    }
}

export default RouteLeaveWarningGuard;
