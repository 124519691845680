import React, { useState } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFStepperInput from '../../UI/EFStepperInput/EFStepperInput';
import CalculatorSetup from '../../UI/CustomModalCard/CustomModalCard';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import Modal from 'react-modal';
import Carousel from '@brainhubeu/react-carousel';
import EFButton from '../../Atomic/EFButton/EFButton';
import CompletedOnboardingCard from '../Common/CompletedOnboardingCard/CompletedOnboardingCard';
import { useResponsive } from '../../../hooks/useResponsive';

import '@brainhubeu/react-carousel/lib/style.css';
import lifeEventRetirement from '../../../assets/images/lifeEvents/bigstuff/retire.svg';


interface IProps {
    handleModal?: any,
    setAge?: (val: number) => void,
    setincomeNeeded?: (val: number) => void,
    setcashSavingsAndInvestments?: (val: number) => void,
    setannualSalary?: (val: number) => void,
    setstartingBalance?: (val: number) => void,
    setestimatedAnnualSSBenefit?: (val: number) => void,
};

const RetirementOnboarding: React.FC<IProps> = props => {
    const { screenIsAtLeast } = useResponsive();
    const title = 'RETIREMENT'
    const defaultAge = 32;
    const defaultIncomeNeeded = 3500;
    const defaultCashAdd = 5;
    const defaultSalary = 4100;
    const defaultSavings = 0;
    const defaultSSBenefit = 2393;

    const [modalIsOpen, setModalIsOpen] = useState(props.handleModal.showModal);
    const [carouselValue, setCarouselValue] = useState(0);

    // Card 1
    const [age, setAge] = useState(0);
    const [ageErr, setAgeErr] = useState(false);
    const [incomeNeeded, setIncomeNeeded] = useState(0);
    const [incomeNeededErr, setIncomeNeededErr] = useState(false);
    const [cashAdd, setCashAdd] = useState(0);

    // Card 2
    const [salary, setSalary] = useState(0);
    const [salaryErr, setSalaryErr] = useState(false);
    const [savings, setSavings] = useState(0);
    const [sSBenefit, setSSBenefit] = useState(0);
    const [finished, setFinished] = useState(false);
    
    const onCompleteCard1 = () => {
        let nextCard = 1;
        if (age <= 0) {
            setAgeErr(true);
            nextCard = 0;
        }
        if (incomeNeeded <= 0) {
            setIncomeNeededErr(true);
            nextCard = 0;
        }
        setCarouselValue(nextCard);
    }

    const onSkipCard1 = () => {
        setAge(defaultAge);
        setAgeErr(false);
        setIncomeNeeded(defaultIncomeNeeded);
        setIncomeNeededErr(false);
        setCashAdd(defaultCashAdd);
        setCarouselValue(1);

    }

    const onCompleteCard2 = () => {
        let isFinished = true;
        if (salary <= 0) {
            setSalaryErr(true);
            isFinished = false;
        }
        setFinished(isFinished);
    }

    const onSkipCard2 = () => {
        setSalary(defaultSalary);
        setSavings(defaultSavings);
        setSSBenefit(defaultSSBenefit);
        setFinished(true);
    }

    const onCompleteSetup = () => {
        props.setAge(age);
        props.setincomeNeeded(incomeNeeded * 12);
        props.setcashSavingsAndInvestments(cashAdd);
        props.setannualSalary(salary * 12);
        props.setstartingBalance(savings);
        props.setestimatedAnnualSSBenefit(sSBenefit * 12);
        setModalIsOpen(false);
    }

    const ErrLabel = (msg: string) => (
        <EFLabel style={{fontSize: '1.3rem', color: '#c4233e', fontWeight: 'normal'}}>{msg}</EFLabel>
    ); 

    return (
        <Modal 
            isOpen={modalIsOpen}
            onRequestClose={props.handleModal.exitModal}
            style={modalCardStyle}
        >
            {!finished && (
                <Carousel
                    value={carouselValue} 
                    draggable={false}
                    centered
                    slidesPerPage={screenIsAtLeast('tabPort') ? 1.8 : 1}
                >
                    <div key={0}>
                        <CalculatorSetup 
                            title={title} 
                            eventIcon={lifeEventRetirement} 
                            onClickExit={props.handleModal.exitModal}
                        >
                            <Stack horizontalAlign='center' tokens={{childrenGap: '2rem'}}>
                                <Stack tokens={{childrenGap: 5}}>
                                    <EFStepperInput
                                        label={`Age You Begin Saving`}
                                        onChange={(val: number) => { setAge(val); setAgeErr(false); }}
                                        value={age}
                                        minVal={1} 
                                        maxVal={90}
                                        displayErrStyle={ageErr}
                                    />
                                    {ageErr && ErrLabel('Please enter a valid age')}
                                </Stack>
                                <Stack tokens={{childrenGap: 5}}>
                                    <EFStepperInput
                                        label={`Monthly Income Needed in Retirement`}
                                        onChange={(val: number) => { setIncomeNeeded(val); setIncomeNeededErr(false); }}
                                        value={incomeNeeded}
                                        minVal={0} 
                                        maxVal={25000}
                                        prefix='$'
                                        includeThousandsSeparator
                                        dynamicStepEnabled
                                        displayErrStyle={incomeNeededErr}
                                    />
                                    {incomeNeededErr && ErrLabel('Please enter an amount')}
                                </Stack>
                                <EFStepperInput
                                    label={`Additional Savings (Cash & Investment Savings)`}
                                    onChange={(val: number) => setCashAdd(val)}
                                    value={cashAdd}
                                    minVal={0} 
                                    maxVal={1000000}
                                    prefix='$'
                                    includeThousandsSeparator
                                    dynamicStepEnabled
                                />
                                <Stack style={{marginTop: '6rem'}} horizontalAlign='center' tokens={{childrenGap: '1.5rem'}}>
                                    <EFButton style={{width: '16rem'}} text={`NEXT`} onClick={onCompleteCard1} />
                                    <EFLabel onClick={onSkipCard1} style={{fontWeight: 'normal', textDecoration: 'underline', cursor: 'pointer'}}>
                                        Figure this out later
                                    </EFLabel>
                                </Stack>
                            </Stack>
                        </CalculatorSetup>
                    </div>
                    <div key={1}>
                        <CalculatorSetup 
                            title={title} 
                            eventIcon={lifeEventRetirement} 
                            onClickExit={props.handleModal.exitModal}
                        >
                            <Stack horizontalAlign='center' tokens={{childrenGap: '2rem'}}>
                                <Stack tokens={{childrenGap: 5}}>
                                    <EFStepperInput
                                        label={`Monthly Salary`}
                                        onChange={(val: number) => { setSalary(val); setSalaryErr(false); }}
                                        value={salary}
                                        minVal={0} 
                                        maxVal={25000}
                                        prefix='$'
                                        includeThousandsSeparator
                                        dynamicStepEnabled
                                        displayErrStyle={salaryErr}
                                    />
                                    {salaryErr && ErrLabel('Please enter a salary')}
                                </Stack>
                                <EFStepperInput
                                    label={`Current Retirement Savings Balance`}
                                    onChange={(val: number) => setSavings(val)}
                                    value={savings}
                                    minVal={0} 
                                    maxVal={1000000}
                                    prefix='$'
                                    includeThousandsSeparator
                                    dynamicStepEnabled
                                />
                                <EFStepperInput
                                    label={`Monthly Estimated Social Security Benefit`}
                                    onChange={(val: number) => setSSBenefit(val)}
                                    value={sSBenefit}
                                    minVal={0} 
                                    maxVal={3770}
                                    prefix='$'
                                    includeThousandsSeparator
                                    dynamicStepEnabled
                                />
                                <Stack style={{marginTop: '6rem'}} horizontalAlign='center' tokens={{childrenGap: '1.5rem'}}>
                                    <Stack horizontal tokens={{childrenGap: '2.7rem'}}>
                                        <EFButton style={{width: '16rem', color: '#F7B500', backgroundColor: '#FFF'}} text={`PREVIOUS`} onClick={() => setCarouselValue(0)} />
                                        <EFButton style={{width: '16rem'}} text={`FINISHED!`} onClick={onCompleteCard2} />
                                    </Stack>
                                    <EFLabel onClick={onSkipCard2} style={{fontWeight: 'normal', textDecoration: 'underline', cursor: 'pointer'}}>
                                        Figure this out later
                                    </EFLabel>
                                </Stack>
                            </Stack>
                        </CalculatorSetup>
                    </div>
                </Carousel>
            )}
            {finished && <CompletedOnboardingCard title={title} eventIcon={lifeEventRetirement} onClick={onCompleteSetup} onClickExit={props.handleModal.exitModal} />}
        </Modal>
    )
};

const modalCardStyle = {
    content: {
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: '70rem',
        width: '100%',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
        marginTop: '2rem',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)'
    }
}

export default RetirementOnboarding;
