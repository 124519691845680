import React from 'react';
import { useHistory } from "react-router-dom";
import classes from './EFHeader.module.css';
import Logo from '../../../assets/images/eflogo.svg';

import maleAvatar from "../..//../assets/images/maleAvatar.svg";

interface IProps {
    onNavToMain?: boolean;
    showSettings?: boolean;
    onAvatarClick?: () => void;
    userAvatar?: any;
    customStyle?: any;
}

const EFHeader: React.FC<IProps> = props => {
    let history = useHistory();

    return (
        <div className={classes.container} style={props.customStyle}>
            <img onClick={_ => props.onNavToMain && history.push("/")} className={classes.imgStyle} src={Logo} alt="EqualFuture" />
            {props.showSettings && <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                paddingRight: '4rem'
            }}>
                <img alt='avatar' src={props.userAvatar || maleAvatar} style={{ width: '6rem', height: '6rem', cursor: 'pointer' }} onClick={props.onAvatarClick} />
            </div>}
        </div>
    );
};

EFHeader.defaultProps = {
    onNavToMain: false,
}

export default EFHeader;