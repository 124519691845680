import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import CalculatorSetup from '../../../UI/CustomModalCard/CustomModalCard';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';
import EFButton from '../../../Atomic/EFButton/EFButton';

interface IProps {
    eventIcon?: string,
    title?: string,
    onClickExit: () => void,
    onClick: () => void,
}

const CompletedOnboardingCard: React.FC<IProps> = props => {
    return (
        <CalculatorSetup style={{margin: '5rem auto auto auto'}} title={props.title} eventIcon={props.eventIcon} onClickExit={props.onClickExit}>
            <Stack horizontalAlign='center' tokens={{childrenGap: 20}}>
                <img alt='setup-finish' src={require('../../../../assets/images/setup_finish.svg')} style={{height: '16.4rem', width: '16.4rem',}} />
                <EFLabel style={{fontSize: '2rem', fontWeight: 'normal', width: '17rem', textAlign: 'center', lineHeight: 1.5}}>
                    YOU ARE DONE SETTING UP THIS LIFE EVENT!
                </EFLabel>
            </Stack>
            <EFButton text={`LET'S CHECK IT OUT`} onClick={props.onClick} />
        </CalculatorSetup>
    )
}

export default CompletedOnboardingCard;
