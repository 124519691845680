import React from 'react';

import signOutIcon from '../../../assets/icons/signOutButton.svg';
import classes from './SignOutButton.module.css';

interface Props {
    onClick: () => void;
}

const SignOutButton: React.FC<Props> = props => {

    return (
        <div className={classes.container} onClick={props.onClick} >
            <img alt="Sign Out" src={signOutIcon} className={classes.img}/>
            <p className={classes.text}>Sign Out</p>
        </div>
    )

};

export default SignOutButton