/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from "react";
import { Stack } from "office-ui-fabric-react";
import EFSlider from "../../UI/EFSlider/EFSlider";
import EFChoiceGroup from "../../UI/EFChoiceGroup/EFChoiceGroup";
import EFStepperInput from "../../UI/EFStepperInput/EFStepperInput";
import BreakdownList from "../../../components/Calculators/Common/BreakdownList";
import ResultReport from "../../../components/Calculators/Common/ResultReport";
import StackedChart from "../../../components/Charts/StackedChart";
import EFLabel from "../../../components/Atomic/EFLabel/EFLabel";
import EFToggle from "../../../components/Atomic/EFToggle";
import StaticContainer from "../../UI/StaticContainer";
import ConfigButton from "../../../components/Calculators/Common/ConfigButton";
import DividerContainer from "../../../components/UI/Divider/DividerContainer";
import Divider from "../../../components/UI/Divider/Divider";
import DoughnutChart from "../../../components/Charts/DoughnutChart";
import EFAccordion from "../../Atomic/EFAccordion/EFAccordion";
import ToggleableEFStepperInput from "../../UI/ToggleableEFStepperInput/ToggleableEFStepperInput";
import { useCollegeSavings } from "../../../hooks/Calculators/useCollegeSavings";
import { useConfigButtons } from "../../../hooks/useConfigButtons";

import classes from "./CollegePlan.module.css";
import CollegePlanOnboarding from "./CollegePlanOnboarding";
import SaveChangesModal from "../../LifeEvents/SaveChangesModal/SaveChangesModal";
import CollegePlanningInterstitial from "../../LifeEvents/Interstitials/CollegePlanningInterstitial/CollegePlanningInterstitial";
import maskValue, { numberMaskUS } from "../../../utils/Masking";
import { collegeTT } from "../../../assets/content/TempCalcToolTips";
import EFTooltip from "../../Atomic/EFTooltip/EFTooltip";

interface Props {
  handleModal: { showModal: boolean; exitModal?: () => void };
  currentView?: string;
  lifeEventDetails: any;
}

const CollegePlan: React.FC<Props> = (props) => {
  const {
    //#region Inputs and Outputs
    surplus,
    schedule,
    tuition,
    setTuition,
    transportation,
    setTransportation,
    medical,
    setMedical,
    sideHustle,
    setsideHustle,
    room,
    setRoom,
    entertainment,
    setEntertainment,
    other,
    setOther,
    shortfall,
    privateLoan,
    setPrivateLoan, //PRIVATE LOAN VS. PRIVATE LOAN SPENT ?
    amountSaved,
    totalSideHustle,
    totalLoan,
    totalGrants,
    yearsToEnrollment,
    setyearsToEnrollment,
    yearsAttending,
    setyearsAttending,
    collegeType,
    handleCollegeType,
    savings,
    setsavings,
    monthlyContribution,
    setmonthlyContribution,
    grantScholarShipPct,
    setgrantScholarShipPct,
    federalLoanAmountLimit,
    setfederalLoanAmountLimit,
    annualReturnRate,
    setannualReturnRate,
    inflationRateTuition,
    setinflationRateTuition,
    inflationRateOther,
    setinflationRateOther,
    contributionsGrowWithInflation,
    setcontributionsGrowWithInflation,
    //#endregion
  } = useCollegeSavings(props.lifeEventDetails.id);

  // Handles Divider Pages UI Logic
  const [costsDivider, setCostsDivider] = useState<boolean>(true);
  const [fundingDivider, setFundingDivider] = useState<boolean>(false);
  const handleDividers = () => {
    setCostsDivider(false);
    setFundingDivider(false);
  };

  // Handles Room & Board Input
  const defaultRoomCost = 0;
  const [toggleRoom, setToggleRoom] = useState<boolean>(true);
  const onClickRoomToggle = () => {
    setToggleRoom(!toggleRoom);
    !toggleRoom ? setRoom(defaultRoomCost) : setRoom(0);
  };

  // Handles Configuration Buttons Logic
  const { setup, onSetup, simu, onSimu, lvl, onLvl } = useConfigButtons();

  // College Costs Doughnut Chart Data
  const collegeCostsChartData = [
    {
      key: 0,
      title: "Tuition, Fees & Books",
      value: tuition,
      color: "#b19fdd",
    },
    { key: 4, title: "Room & Board", value: room, color: "#2695c7" },
    {
      key: 1,
      title: "Transportation",
      value: transportation,
      color: "#8fbe33",
    },
    { key: 2, title: "Medical", value: medical, color: "#43c47e" },
    { key: 5, title: "Entertainment", value: entertainment, color: "#de7fb3" },
    { key: 6, title: "Other Costs", value: other, color: "#e79350" },
  ];

  // Toggles result report evaluation
  const isFunded = shortfall <= 0;

  // Toggles first breakdown row as shortfall or surplus
  const shortfallVsSurplus = !isFunded
    ? { key: 0, title: "Shortfall", value: shortfall, color: "#F3798D" }
    : { key: 0, title: "Surplus", value: surplus, color: "#2CCD79" };

  // Produces arrays for each stack set on the College Funding Stacked Chart
  const scheduleYears = schedule.map(
    (r: any, i: number) => `Year ${schedule[i].year}`
  );
  const scheduleGrants = schedule.map(
    (r: any, i: number) => schedule[i].grants
  );
  const scheduleStudentLoan = schedule.map(
    (r: any, i: number) => schedule[i].loan
  );
  const scheduleSideHustle = schedule.map(
    (r: any, i: number) => schedule[i].sideHustle
  );
  const scheduleSavings = schedule.map(
    (r: any, i: number) => schedule[i].spent
  );
  const schedulePrivateLoan = schedule.map(
    (r: any, i: number) => schedule[i].privateLoanSpent
  );
  const scheduleShortfall = schedule.map(
    (r: any, i: number) => schedule[i].unfunded
  );

  const fundingCost =
    shortfallVsSurplus.value +
    privateLoan +
    amountSaved +
    totalSideHustle +
    totalLoan +
    totalGrants;

  // setup content
  const timeFramesContent = (
    <>
      <EFSlider
        title="Years to Enrollment"
        onChange={(val: number) => setyearsToEnrollment(val)}
        value={yearsToEnrollment}
        min={1}
        max={18}
        tooltip={collegeTT.yrToEnrollment}
      />
      <EFSlider
        title="Years Attending"
        onChange={(val: number) => setyearsAttending(val)}
        value={yearsAttending}
        min={2}
        max={6}
        tooltip={collegeTT.yrAttending}
      />
    </>
  );
  // THIS IS NOT HANDLING THE ' ' KEY. BACKEND DEFAULTS TO PUBLIC RADIO BTN IF IT RECIEVES ANYTHING INVALID -> MUST RECREATE THAT BEHAVIOR HERE
  const collegeCostContent = (
    <>
      <EFChoiceGroup
        options={[
          { key: "PUBLIC_4", text: "Public" },
          { key: "PRIVATE", text: "Private" },
          { key: "COMMUNITY", text: "Community College" },
        ]}
        title="School Type"
        selectedKey={collegeType}
        onChange={(e, option) => {
          handleCollegeType(e, option);
          if (option?.key === "COMMUNITY") {
            setToggleRoom(false);
            setRoom(0);
          } else {
            setToggleRoom(true);
          }
        }}
      />
      <EFStepperInput
        label={`Tuition, Fees & Books`}
        onChange={(val: number) => setTuition(val)}
        value={tuition}
        prefix="$"
        minVal={0}
        maxVal={500000}
        allowDecimal
        dynamicStepEnabled
        includeThousandsSeparator
        tooltip={collegeTT.tuitionFees}
      />
      <ToggleableEFStepperInput
        label={`Room & Board`}
        isToggled={toggleRoom}
        onClickToggle={onClickRoomToggle}
        value={room}
        onChangeInput={(val: number) => setRoom(val)}
        minVal={0}
        maxVal={500000}
        dynamicStepEnabled
        includeThousandsSeparator
        tooltip={collegeTT.roomAndBoard}
      />
    </>
  );
  const otherCostsContent = (
    <>
      <EFSlider
        title="Transportation"
        prefix="$"
        includeThousandsSeparator
        onChange={(val: number) => setTransportation(val)}
        value={transportation}
        min={0}
        max={12000}
      />
      <EFSlider
        title="Entertainment"
        prefix="$"
        includeThousandsSeparator
        onChange={(val: number) => setEntertainment(val)}
        value={entertainment}
        min={0}
        max={2500}
      />
      <EFSlider
        title="Medical"
        prefix="$"
        includeThousandsSeparator
        onChange={(val: number) => setMedical(val)}
        value={medical}
        min={0}
        max={2500}
      />
      <EFSlider
        title="Other living expenses"
        prefix="$"
        includeThousandsSeparator
        onChange={(val: number) => setOther(val)}
        value={other}
        min={0}
        max={12000}
      />
    </>
  );

  // simu content
  const savingsContent = (
    <>
      <EFStepperInput
        label="College Savings so Far"
        prefix="$"
        includeThousandsSeparator
        dynamicStepEnabled
        minVal={0}
        maxVal={1000000}
        value={savings}
        onChange={(val: number) => setsavings(val)}
        tooltip={collegeTT.collegeSavings}
      />
      <EFStepperInput
        label="Desired Monthly Contribution"
        prefix="$"
        includeThousandsSeparator
        dynamicStepEnabled
        minVal={0}
        maxVal={1000000}
        value={monthlyContribution}
        onChange={(val: number) => setmonthlyContribution(val)}
        tooltip={collegeTT.desiredMonthlyContribution}
      />
      <EFStepperInput
        label="Side Hustle or Work Study"
        prefix="$"
        includeThousandsSeparator
        dynamicStepEnabled
        minVal={0}
        maxVal={1000000}
        value={sideHustle}
        onChange={(val: number) => setsideHustle(val)}
        tooltip={collegeTT.monthlySideHustle}
      />
    </>
  );
  const loansContent = (
    <>
      <EFSlider
        title={`Grants & Scholarship Financial AID`}
        suffix="%"
        onChange={(val: number) => setgrantScholarShipPct(val)}
        value={grantScholarShipPct}
        min={0}
        max={100}
        tooltip={collegeTT.grantsAndAID}
      />
      <EFSlider
        title="Federal Student Loan"
        prefix="$"
        includeThousandsSeparator
        onChange={(val: number) => setfederalLoanAmountLimit(val)}
        value={federalLoanAmountLimit}
        min={0}
        max={27000}
        tooltip={collegeTT.federalStudentLoan}
      />
      <EFSlider
        title="Private Student Loan"
        prefix="$"
        includeThousandsSeparator
        onChange={(val: number) => setPrivateLoan(val)}
        value={privateLoan}
        min={0}
        max={150000}
        tooltip={collegeTT.privateStudentLoan}
      />
    </>
  );

  // lvlup content
  const economicsContent = (
    <>
      <EFStepperInput
        label="Annual Return Rate"
        suffix="%"
        includeThousandsSeparator
        minVal={0}
        maxVal={12}
        value={annualReturnRate}
        onChange={(val: number) => setannualReturnRate(val)}
        allowDecimal
        tooltip={collegeTT.annualReturnRate}
      />
      <EFStepperInput
        label="Tuition Inflation Rate"
        suffix="%"
        includeThousandsSeparator
        minVal={0}
        maxVal={8}
        value={inflationRateTuition}
        onChange={(val: number) => setinflationRateTuition(val)}
        allowDecimal
        tooltip={collegeTT.tuitionInflationRate}
      />
      <EFStepperInput
        label="Other Costs Inflation Rate"
        suffix="%"
        includeThousandsSeparator
        minVal={0}
        maxVal={8}
        value={inflationRateOther}
        onChange={(val: number) => setinflationRateOther(val)}
        allowDecimal
        tooltip={collegeTT.otherCostsInflationRate}
      />
      <Stack verticalAlign="center" horizontal horizontalAlign="space-between">
        <Stack
          tokens={{ childrenGap: ".5rem" }}
          verticalAlign="center"
          horizontal
        >
          <EFLabel style={{ fontWeight: "normal" as "normal" }}>
            {"Contributions Grow with Inflation"}
          </EFLabel>
          <EFTooltip
            text={collegeTT.contributionsGrowWithInflation}
            title={"Contributions Grow with Inflation"}
          />
        </Stack>
        <EFToggle
          isChecked={contributionsGrowWithInflation}
          onClick={() =>
            setcontributionsGrowWithInflation(!contributionsGrowWithInflation)
          }
        />
      </Stack>
    </>
  );

  return (
    <>
      <CollegePlanOnboarding
        handleModal={props.handleModal}
        setyearsToEnrollment={setyearsToEnrollment}
        setyearsAttending={setyearsAttending}
        handleCollegeType={handleCollegeType}
        tuition={tuition}
        setTuition={setTuition}
        setToggleRoom={setToggleRoom}
        room={room}
        setRoom={setRoom}
        setTransportation={setTransportation}
        setEntertainment={setEntertainment}
        setMedical={setMedical}
        setOther={setOther}
      />
      <SaveChangesModal
        openLifeEventSaveModal={props.lifeEventDetails.openSaveModal}
        onClose={props.lifeEventDetails.onOpenSaveModal}
      >
        <CollegePlanningInterstitial
          lifeEventDetails={props.lifeEventDetails}
          yearsToEnroll={yearsToEnrollment}
          onClick={props.lifeEventDetails.onSaveLifeEvent}
          onCloseClick={props.lifeEventDetails.onOpenSaveModal}
        />
      </SaveChangesModal>
      {props.currentView === "Connect the dots" && (
        <Stack horizontalAlign="center" tokens={{ childrenGap: 20 }}>
          <Stack>
            <Stack
              horizontal
              horizontalAlign="center"
              className={classes.dividercontainer}
              tokens={{ childrenGap: 30 }}
            >
              <Divider
                title={`Annual College Costs - Today's Value`}
                icon={require("../../../assets/icons/costs_icon.svg")}
                isOpen={costsDivider}
                onClick={() => {
                  handleDividers();
                  setCostsDivider(true);
                }}
              />
              <Divider
                title="College Funding - Future Value"
                icon={require("../../../assets/icons/funding_icon.svg")}
                isOpen={fundingDivider}
                onClick={() => {
                  handleDividers();
                  setFundingDivider(true);
                }}
              />
            </Stack>
            <Stack horizontalAlign="center">
              <DividerContainer>
                {costsDivider && (
                  <Stack
                    wrap
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 30 }}
                  >
                    <Stack className={classes.chart}>
                      <DoughnutChart
                        sumTotalLabel
                        datasets={{
                          labels: collegeCostsChartData.map(
                            (section: { title: string }) => section.title
                          ),
                          datasets: [
                            {
                              label: `College Costs`,
                              data: collegeCostsChartData.map(
                                (section: { value: number }) => section.value
                              ),
                              backgroundColor: collegeCostsChartData.map(
                                (section: { color: string }) => section.color
                              ),
                            },
                          ],
                        }}
                      />
                    </Stack>
                    <Stack
                      horizontalAlign="center"
                      tokens={{ childrenGap: 20 }}
                      className={classes.report}
                    >
                      <ResultReport
                        isGreen={isFunded}
                        onChange={() => null}
                        reportGreenLabel="Your college plan is fully funded"
                        reportRedLabel="Your college plan is not fully funded, consider increasing your savings"
                        reportRedImg={require("../../../assets/icons/shield.svg")}
                        imgStyle={{ width: "16.7rem", height: "11rem" }}
                        reportStyle={{ width: "25rem" }}
                        containerStyle={{
                          marginBottom: "unset",
                          marginTop: "2rem",
                        }}
                      />
                      <EFLabel
                        style={{
                          color: !isFunded ? "#DC50A2" : "#2CCD79",
                          fontSize: "1.6rem",
                        }}
                      >
                        Total cost of college funding:{" "}
                        {maskValue(fundingCost, numberMaskUS)}
                      </EFLabel>
                      <BreakdownList
                        style={{ width: "30rem", marginTop: "5rem" }}
                        data={collegeCostsChartData}
                      />
                    </Stack>
                  </Stack>
                )}
                {fundingDivider && (
                  <Stack
                    wrap
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 30 }}
                  >
                    <Stack className={classes.chart}>
                      <StackedChart
                        datasets={{
                          labels: scheduleYears,
                          datasets: [
                            {
                              type: "bar",
                              label: `Grants & Scholarship`,
                              data: scheduleGrants,
                              backgroundColor: "#98E6BD",
                              borderColor: "#98E6BD",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: `Student Loan`,
                              data: scheduleStudentLoan,
                              backgroundColor: "#FF9255",
                              borderColor: "#FF9255",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: `Side Hustle & Work Study`,
                              data: scheduleSideHustle,
                              backgroundColor: "#F6BE00",
                              borderColor: "#F6BE00",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: `College Savings`,
                              data: scheduleSavings,
                              backgroundColor: "#02C4EA",
                              borderColor: "#02C4EA",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: `Private Loan`,
                              data: schedulePrivateLoan,
                              backgroundColor: "#B19FDD",
                              borderColor: "#B19FDD",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: `Shortfall`,
                              data: scheduleShortfall.map((e) => Math.round(e)),
                              backgroundColor: "#F3798D",
                              borderColor: "#F3798D",
                              datalabels: {
                                color: "#343F6E",
                                formatter: function (_value, context) {
                                  const sum =
                                    context.chart.data?.datasets.reduce(
                                      (a, b) => {
                                        return a + b.data[context.dataIndex];
                                      },
                                      0
                                    );
                                  return maskValue(sum, numberMaskUS);
                                },
                                align: "end",
                                anchor: "end",
                                offset: 5,
                                padding: -2,
                                clip: true,
                                font: {
                                  size: "16",
                                  weight: "bold",
                                },
                              },
                            },
                          ],
                        }}
                      />
                    </Stack>
                    <Stack
                      horizontalAlign="center"
                      tokens={{ childrenGap: 20 }}
                      className={classes.report}
                    >
                      <ResultReport
                        isGreen={isFunded}
                        onChange={() => null}
                        reportGreenLabel="Your college plan is fully funded"
                        reportRedLabel="Your college plan is not fully funded, consider increasing your savings"
                        reportRedImg={require("../../../assets/icons/shield.svg")}
                        imgStyle={{ width: "16.7rem", height: "11rem" }}
                        reportStyle={{ width: "25rem" }}
                        containerStyle={{
                          marginBottom: "unset",
                          marginTop: "2rem",
                        }}
                      />
                      <EFLabel
                        style={{
                          color: !isFunded ? "#DC50A2" : "#2CCD79",
                          fontSize: "1.6rem",
                        }}
                      >
                        Total cost of college funding:
                        {maskValue(fundingCost, numberMaskUS)}
                      </EFLabel>
                      <BreakdownList
                        style={{ width: "30rem", marginTop: "5rem" }}
                        data={[
                          shortfallVsSurplus,
                          {
                            key: 1,
                            title: "Private Loan",
                            value: privateLoan,
                            color: "#B19FDD",
                          },
                          {
                            key: 2,
                            title: "College Savings",
                            value: amountSaved,
                            color: "#02C4EA",
                          },
                          {
                            key: 3,
                            title: "Side Hustle or Work Study",
                            value: totalSideHustle,
                            color: "#F6BE00",
                          },
                          {
                            key: 4,
                            title: "Student Loan",
                            value: totalLoan,
                            color: "#FF9255",
                          },
                          {
                            key: 5,
                            title: "Grants & Scholarship",
                            value: totalGrants,
                            color: "#98E6BD",
                          },
                        ]}
                      />
                    </Stack>
                  </Stack>
                )}
              </DividerContainer>
            </Stack>
          </Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: "4rem" }}
          >
            <ConfigButton type="setup" toggleFocus={setup} onClick={onSetup} />
            <ConfigButton type="simu" toggleFocus={simu} onClick={onSimu} />
            <ConfigButton type="levelup" toggleFocus={lvl} onClick={onLvl} />
          </Stack>
          {setup && (
            <Stack
              wrap
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: "1rem" }}
            >
              <EFAccordion title="Time Frames">{timeFramesContent}</EFAccordion>
              <EFAccordion title="College Cost">
                {collegeCostContent}
              </EFAccordion>
              <EFAccordion title="Other Costs">{otherCostsContent}</EFAccordion>
            </Stack>
          )}
          {simu && (
            <Stack
              wrap
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: "1rem" }}
            >
              <EFAccordion title="Savings and Contributions">
                {savingsContent}
              </EFAccordion>
              <EFAccordion title="Loans and Financial AID">
                {loansContent}
              </EFAccordion>
            </Stack>
          )}
          {lvl && (
            <Stack
              wrap
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: "1rem" }}
            >
              <EFAccordion title="Economics">{economicsContent}</EFAccordion>
            </Stack>
          )}
        </Stack>
      )}
      {props.currentView === "Low Down" && (
        <StaticContainer url="https://content.equalfuture.com/blog/College%20Plan/lowdown" />
      )}
      {props.currentView === "Nitty Gritty" && (
        <StaticContainer url="https://content.equalfuture.com/blog/College%20Plan/nittygritty" />
      )}
    </>
  );
};

export default CollegePlan;
