export const stateList = [
    { key: 'AL', text: 'Alabama' },
    { key: 'AK', text: 'Alaska' },
    { key: 'AZ', text: 'Arizona' },
    { key: 'CA', text: 'California' },
    { key: 'CO', text: 'Colorado'},
    { key: 'CT', text: 'Connecticut'},
    { key: 'DE', text: 'Delaware'},
    { key: 'DC', text: 'District of Columbia'},
    { key: 'FL', text: 'Florida'},
    { key: 'GA', text: 'Georgia'},
    { key: 'HI', text: 'Hawaii'},
    { key: 'ID', text: 'Idaho'},
    { key: 'IL', text: 'Illinois'},
    { key: 'IN', text: 'Indiana'},
    { key: 'IA', text: 'Iowa'},
    { key: 'KS', text: 'Kansas'},
    { key: 'KY', text: 'Kentucky'},
    { key: 'LA', text: 'Louisiana'},
    { key: 'ME', text: 'Maine'},
    { key: 'MD', text: 'Maryland'},
    { key: 'MA', text: 'Massachusetts'},
    { key: 'MI', text: 'Michigan'},
    { key: 'MN', text: 'Minnesota'},
    { key: 'MS', text: 'Mississippi'},
    { key: 'MO', text: 'Missouri'},
    { key: 'MT', text: 'Montana'},
    { key: 'NE', text: 'Nebraska'},
    { key: 'NV', text: 'Nevada'},
    { key: 'NH', text: 'New Hampshire'},
    { key: 'NJ', text: 'New Jersey'},
    { key: 'NM', text: 'New Mexico'},
    { key: 'NY', text: 'New York'},
    { key: 'NC', text: 'North Carolina'},
    { key: 'ND', text: 'North Dakota'},
    { key: 'OH', text: 'Ohio'},
    { key: 'OK', text: 'Oklahoma'},
    { key: 'OR', text: 'Oregon'},
    { key: 'PA', text: 'Pennsylvania'},
    { key: 'RI', text: 'Rhode Island'},
    { key: 'SC', text: 'South Carolina'},
    { key: 'SD', text: 'South Dakota'},
    { key: 'TN', text: 'Tennessee'},
    { key: 'TX', text: 'Texas'},
    { key: 'UT', text: 'Utah'},
    { key: 'VT', text: 'Vermont'},
    { key: 'VA', text: 'Virginia'},
    { key: 'WA', text: 'Washington'},
    { key: 'WV', text: 'West Virginia'},
    { key: 'WI', text: 'Wisconsin'},
    { key: 'WY', text: 'Wyoming'},
];