import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useResponsive } from '../../hooks/useResponsive';
import { Card } from '@uifabric/react-cards';
import EFHeader from '../../components/UI/EFHeader/EFHeader';
import EFButton from '../../components/Atomic/EFButton/EFButton';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import staceyAvatar from '../../assets/images/staceyAvatar.png';
import rosaAvatar from '../../assets/images/rosaAvatar.png';
import darylAvatar from '../../assets/images/darylAvatar.png';
import martinAvatar from '../../assets/images/martinAvatar.svg';
import eveAvatar from '../../assets/images/eveAvatar.svg';
import bigStacey from '../../assets/images/bigStacey.png';
import rosa from '../../assets/images/rosa.png';
import daryl from '../../assets/images/daryl.png';
import martin from '../../assets/images/martin.svg';
import eve from '../../assets/images/eve.svg';

import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../store/actions/Users';

import classes from './ProfileSelectorPage.module.css';

import Passion from '../../assets/images/lifeEvents/bigstuff/passion.svg';
import CollegeCosts from '../../assets/images/lifeEvents/bigstuff/college.svg';
import Affordability from '../../assets/images/lifeEvents/money/affordability.svg';
import GetJob from '../../assets/images/lifeEvents/work/new_job.svg';
import StudentDebt from '../../assets/images/lifeEvents/money/student_debt2.svg';
import ManageDebt from '../../assets/images/lifeEvents/money/manage_debt.svg';
import Retirement from '../../assets/images/lifeEvents/protection/retirement.svg';
import House from '../../assets/images/lifeEvents/bigstuff/buy_home.svg';
import Reboot from '../../assets/images/retireReboot.svg';
import SideHustle from '../../assets/images/lifeEvents/work/side_hustle.svg';
import EstatePlan from '../../assets/images/lifeEvents/family/estate_plan.svg';
import { appendHeaders } from '../../utils/FetchHeaders';
import { saveProfiles } from '../../store/actions/Data';
import TagManager from 'react-gtm-module';
interface LifeEvent {
  image: string,
  text: string
}

interface Profile {
  title: string,
  subtitle: string,
  content: string,
  profileNumber: number,
  avatar: string,
  image: string,
  text: string,
  lifeEvents: LifeEvent[]
}

const ProfileSelectorPage: React.FC<{}> = props => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { screenIsAtMost } = useResponsive();
  const realUser = useSelector((state: any) => state.data.realUser);

  // Google analytics set up for page
  const tagManagerArgs = {
    dataLayer: {
      userId: realUser?.trackingId,  
      page: 'Anonymous Persona Profile Selector'
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  useEffect(() => {
    fetch(process.env.REACT_APP_ALL_PERSONAS_ENDPOINT, {
      method: 'GET',
      headers: { ...appendHeaders() }
    })
      .then(response => {
        response.json()
          .then(payload => {
            const profiles = payload.data.map(p => {
              return {
                title: p.name,
                subtitle: p.shortDescription,
                content: p.goal,
                avatar: p.avatar,
                image: p.profilePicture,
                text: p.description,
                lifeEvents: p.lifeEvents.map(l => {
                  return {
                    image: l.eventIcon[0],
                    text: l.name
                  }
                })
              }
            });
            setProfiles(profiles);
            setSelectedProfile(profiles.filter(p => p.title === "Rosa")[0]);
            dispatch(saveProfiles(profiles));
          })
      })
      .catch(error => console.error(error))

  }, []);

  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({
    title: '',
    subtitle: '',
    content: '',
    avatar: '',
    image: '',
    text: '',
    lifeEvents: []
  });

  const onProfileSelectHandler = (selectedProfile: Profile) => {
    setSelectedProfile(selectedProfile);
  }

  const renderProfile = (profile: Profile) => {
    return (
      <Card
        key={profile.title}
        onClick={() => {
          onProfileSelectHandler(profile);
        }}
        className={classes.card}
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          border: screenIsAtMost("mobile") ? '' :
            selectedProfile.title === profile.title
              ? "2px solid #FEB81E"
              : "",
          width: screenIsAtMost("mobile") ? selectedProfile.title === profile.title ? '11.7rem' : '7.7rem' : '16rem',
          height: screenIsAtMost("mobile") ? selectedProfile.title === profile.title ? '11.7rem' : '7.7rem' : '21rem',
          borderRadius: screenIsAtMost("mobile") ? selectedProfile.title === profile.title ? '6rem' : '4rem' : '',
          boxShadow: selectedProfile.title === profile.title ? '0px 4px 14px rgba(254, 184, 30, 0.47)' : ''
        }}
      >
        <Card.Section
          styles={{ root: { alignItems: "center", paddingTop: screenIsAtMost("mobile") ? "0%" : "10%" } }}
        >
          <img style={screenIsAtMost("mobile") ? selectedProfile.title === profile.title ?
            { width: '11rem', height: '11rem' } : { width: '7rem', height: '7rem' } : { width: '9.25rem', height: '9.25rem' }}
            src={profile.avatar} alt="profile icon" />
        </Card.Section>
        {!screenIsAtMost("mobile") && <Card.Section styles={{ root: { alignItems: "center" } }}>
          <div className={classes.title} style={{ marginTop: "0px" }}>
            {profile.title}
          </div>
          <div className={classes.subtitle} style={{ marginTop: "1rem" }}>
            {profile.subtitle}
          </div>
        </Card.Section>}
      </Card>
    );
  };

  const getTitleAndSubtitle = (profile: any) => {
    return (
      <React.Fragment>
        <div className={classes.titleMobile} style={{ marginTop: "0px" }}>
          {profile.title}
        </div>
        <div className={classes.subtitleMobile} style={{ marginTop: "1rem" }}>
          {profile.subtitle}
        </div>
      </React.Fragment>
    )
  }

  const Separator = () => {
    return <div style={screenIsAtMost('mobile') ? { color: 'white' } : {}} className={classes.separator}>...</div>;
  };

  const renderLifeEvent = (lifeEvent: LifeEvent) => {
    return (
      <div key={lifeEvent.text} className={`${classes.lifeEvent} ${screenIsAtMost('mobile') ? classes.lifeEventMobile : ''}`}>
        <img src={lifeEvent.image} alt="icon" className={`${screenIsAtMost('mobile') ? classes.lifeEventImgMobile : ''}`} />
        <p>{lifeEvent.text}</p>
      </div>
    );
  };

  const renderLifeEvents = () => {
    return (
      <div className={classes.lifeEventList}>
        {selectedProfile.lifeEvents.map(
          (lifeEvent: LifeEvent, index: number) => {
            if (index !== selectedProfile.lifeEvents.length - 1) {
              return (
                <React.Fragment key={lifeEvent.text}>
                  {renderLifeEvent(lifeEvent)}
                  <Separator />
                </React.Fragment>
              );
            } else {
              return renderLifeEvent(lifeEvent);
            }
          }
        )}
      </div>
    );
  };

  const renderSelectedProfile = (profile: any) => {
    return screenIsAtMost('mobile') ?
      <div style={{ height: '62rem', width: '82vw', margin: '2rem' }}>
        <div className={classes.cardTextSectionMobile}
          style={{
            background: `url(${profile.image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '100%'
          }}>
          <div style={{
            height: '80%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            background: 'linear-gradient(179.83deg, rgba(0, 0, 0, 0.0001) 19.48%, rgba(0, 0, 0, 0.350021) 28.84%, rgba(0, 0, 0, 0.8) 62.07%)',
            borderRadius: '1.4rem'
          }}>
            <div
              className={classes.subtitle}
              style={{ fontWeight: 700, fontSize: "2rem", color: 'white', padding: '3rem' }}
            >
              {profile.text}
            </div>
            {renderLifeEvents()}
            <EFButton
              style={{ margin: '2rem 0', height: '3.25rem', width: '18rem', fontSize: '1.28rem', alignSelf: "center", marginTop: "1.86rem", color: 'white', fontWeight: '700' }}
              text={"CHOOSE " + profile.title.toUpperCase()}
              onClick={() =>
                dispatch(actions.getProfile(profile.title, history, true))
              }
            />
          </div>
        </div>
      </div>
      :
      <div className={`${classes.carousel}`}>
        <Card
          horizontal
          className={`${classes.card} ${classes.cardSelectedProfile}`}
        >
          <Card.Section className={classes.cardImgSection}>
            <img
              src={profile.image}
              alt="Profile"
              style={{ width: "26rem", marginRight: "7.72rem" }}
            />
          </Card.Section>
          <Card.Section
            className={classes.cardTextSection}
            styles={{
              root: {
                alignItems: "flex-start",
                marginLeft: "0%",
              },
            }}
          >
            <div
              className={classes.title}
              style={{ fontSize: "3.3rem", margin: "0" }}
            >
              {profile.title}
            </div>
            <div
              className={classes.subtitle}
              style={{ fontWeight: 500, fontSize: "2.1rem", margin: "0" }}
            >
              {profile.subtitle}
            </div>
            <div
              className={classes.content}
              style={{ fontSize: "2.1rem", margin: "0" }}
            >
              {profile.content}
            </div>
            <div
              className={classes.subtitle}
              style={{ fontWeight: 400, fontSize: "1.8rem" }}
            >
              {profile.text}
            </div>
            {renderLifeEvents()}
            <EFButton
              id="chooseProfile"
              style={{ height: '3.25rem', width: '18rem', fontSize: '1.28rem', alignSelf: "center", marginTop: "1.86rem" }}
              text={"CHOOSE " + profile.title.toUpperCase()}
              onClick={() =>
                dispatch(actions.getProfile(profile.title, history, true))
              }
            />
          </Card.Section>
        </Card>
      </div>
  };

  const renderToLogin = () => (
    <p className={classes.hasAccount}>
      Already have an account?{" "}
      <strong
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push("/signin");
        }}
      >
        Sign In
        </strong>
    </p>
  )

  let content = null;

  if (profiles.length > 0) {
    content = (
      <div className={`${classes.container} ${screenIsAtMost('mobile') ? classes.containerMobile : ''}`}>
        <EFHeader onNavToMain customStyle={{ display: 'block' }} />
        <div className={classes.form} style={{ paddingTop: screenIsAtMost('mobile') ? '0px' : '4rem' }}>
          {!screenIsAtMost('mobile') && <p style={{ marginBottom: "2.36rem", fontSize: '2.8rem' }}
            className={classes.title}>Pick your experience</p>}
          <div className={`${classes.carousel} ${screenIsAtMost('mobile') ? classes.carouselMobile : ''}`}>
            <Carousel
              centered
              clickToChange
              slidesPerPage={3}
              infinite
              addArrowClickHandler
              arrowLeft={<img style={{ marginRight: screenIsAtMost('mobile') ? '1rem' : '2rem', cursor: 'pointer' }}
                alt='chev-l' src={require('../../assets/icons/chevron_left.svg')}
                onClick={() => {
                  let newIndex = -1;
                  const selectedProfileCurrentIndex = profiles.findIndex(p => p.title === selectedProfile.title);
                  switch (selectedProfileCurrentIndex) {
                    case 0: {
                      newIndex = 1;
                      break;
                    }
                    case 1: {
                      newIndex = 4;
                      break;
                    }
                    case 2: {
                      newIndex = 3;
                      break;
                    }
                    case 3: {
                      newIndex = 0;
                      break;
                    }
                    case 4: {
                      newIndex = 2;
                      break;
                    }
                  }
                  setSelectedProfile(profiles[newIndex]);
                }} />}
              arrowRight={<img style={{ marginLeft: screenIsAtMost('mobile') ? '0rem' : '2rem', cursor: 'pointer' }}
                alt='chev-l'
                src={require('../../assets/icons/chevron_right.svg')}
                onClick={() => {
                  let newIndex = -1;
                  const selectedProfileCurrentIndex = profiles.findIndex(p => p.title === selectedProfile.title);
                  switch (selectedProfileCurrentIndex) {
                    case 0: {
                      newIndex = 3;
                      break;
                    }
                    case 1: {
                      newIndex = 0;
                      break;
                    }
                    case 2: {
                      newIndex = 4;
                      break;
                    }
                    case 3: {
                      newIndex = 2;
                      break;
                    }
                    case 4: {
                      newIndex = 1;
                      break;
                    }
                  }
                  setSelectedProfile(profiles[newIndex]);
                }} />}
            >
              <div>{renderProfile(profiles[2])}</div>
              <div>{renderProfile(profiles[4])}</div>
              <div>{renderProfile(profiles[1])}</div>
              <div>{renderProfile(profiles[0])}</div>
              <div>{renderProfile(profiles[3])}</div>
            </Carousel>
          </div>
          {screenIsAtMost('mobile') && getTitleAndSubtitle(selectedProfile)}
          {renderSelectedProfile(selectedProfile)}
          {renderToLogin()}
        </div>
        <div className={classes.rightColumn}></div>
      </div>
    )
  }

  return content;
};

export default ProfileSelectorPage;
