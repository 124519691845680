import React, { useState } from "react";
import delImg from '../../assets/images/nudget/del.svg';
import editImg from '../../assets/images/nudget/editAlt.svg';
import { useResponsive } from '../../hooks/useResponsive';
import { isCanonical } from '../../utils/global';

const ProgressBar = (props) => {
  const { bgcolor, completed, width, color, total, monthCompletion, mainPage, edit, eliminate} = props;
  var height = 0
  const [value, setValue] = useState('50')
  const [hover, setHover] = useState(false)
  const { screenIsAtLeast } = useResponsive();
  const { screenIsAtMost } = useResponsive();

  if (screenIsAtLeast("bigDesktop")){
    if(mainPage){
      height = 28;
    }else{
      height = 27;
    }
  }

  if (screenIsAtMost('desktop')){
    if (mainPage){
      height = 25;
    }else{
      height = 20;
    }
  } 

  const containerStyles = {
    height: height,
    width: width,
    backgroundColor: bgcolor,
    borderRadius: 50,
    position: 'relative' as 'relative',
    marginTop:10,
    cursor:'pointer'
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: color,
    borderRadius: 'inherit' as 'inherit',
    textAlign: 'left' as 'left',
    display: 'table' as 'table',
    zIndex: 10
  }

  const barLabel = {
    color: 'white',
    textAlign: 'left' as 'left',
    verticalAlign: 'middle' as 'middle',
    fontFamily: 'brandon-grotesque, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    marginLeft:20,
    marginTop:5,
    position: 'absolute' as 'absolute',
    display:'block' as 'block',
    fontSize: 'calc(0.8em + 0.3vw)'
  }

  const labelStyles = {
    color: '#AFAFAF',
    textAlign: 'left' as 'left',
    verticalAlign: 'middle' as 'middle',
    fontFamily: 'brandon-grotesque, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '12px',
    fontSize: '8px',
    top: -15,
    marginLeft: -13,
    position: 'relative' as 'relative'

  }

  const verticalLine = {
    borderLeft: '1px solid #ADADAD',
    height: height,
    marginLeft:monthCompletion+'%',
    position:'absolute' as 'absolute',
  }

  const tooltipStyle = {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    display: hover ? 'flex' : 'none',
    boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.11)',
    borderRadius: 15,
    backgroundColor: '#fff',
    marginLeft: '5rem',
    width: '8rem',
    height: '5rem',
}

  const handleMouseIn= (e:any) => {
    if (mainPage){
      setHover(true);
    }
  }
  
const handleMouseOut= (e:any) => {
    if (mainPage){
      setHover(false);
    }
  }

  return (
        <div style={{textAlign:'left' as 'left'}}>
            <div style={containerStyles} onMouseOver={handleMouseIn} onMouseOut={handleMouseOut}>
              <div style={verticalLine}>
                <span style={labelStyles}>TODAY</span>
                {!isCanonical(props.userName) && <div style={tooltipStyle}>
                  <img alt="delete" src={delImg} onClick={eliminate} style={{height: '3.3rem'}}/>
                  <img alt="edit" src={editImg} onClick={edit} style={{height: '3.3rem'}}/>
                </div>}
              </div>
              <div style={fillerStyles}>
                  <span style={barLabel}>{`$${total}`}</span>
              </div>
            </div>
        </div>
  );
};

export default ProgressBar;