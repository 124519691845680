// Used mainly for scoop dashboards
const rgb2hex = require('rgb2hex');

export const generateColorShadeSet = ( size: number, hexColor: string, a?: number) => {
    var c: any;
    let r = 0;
    let g = 0;
    let b = 0;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexColor)){
        c = hexColor.substring(1).split('');
        if(c.length === 3){
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        r = (c >> 16) & 255;
        g = (c >> 8) & 255;
        b = c & 255;
    };

    const max = Math.max(r, Math.max(g, b));
    const step = 255 / (max * 10);

    const colors = new Array(size).fill(null).map((_: any, i: number) => {
        if (i === 0) {
            return `rgba(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)}, ${a ? a : 1})`
        } else {
            const newR = r * step * (i + 6);
            const newG = g * step * (i + 6);
            const newB = b * step * (i + 6);
            return `rgba(${Math.round(newR)}, ${Math.round(newG)}, ${Math.round(newB)}, ${a ? a : 1})`
        }
    });

    return colors;
};

export const addAlpha = (colorStr: string, opacity: number) => {
    let color = colorStr;
    if (color && (color.charAt(0) !== '#')) {
        const { hex } = rgb2hex(color);
        color = hex;
    };
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
};