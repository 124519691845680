export const isCanonical = (name: string) => {
    if (name !== "Stacey" && name !== "Rosa" && name !== "Daryl" &&
    name !== "Martin" && name !== "Eve") {
        return false;
    }
    else {
        return true;
    }
}

export const nthIndex = (input: string, pattern: string, n: number) => {
    let i = -1;
    while (n-- && i++ < input.length) {
        i = input.indexOf(pattern, i);
        if (i < 0) {
            break;
        }
    }
    return i;
}

export const handleSideNavigation = (key: string, history: any) => {
    let url = "";
    switch(key) {
        case "Get Organized": {
            url = '/connect/getorganized';
            break;
        }
        case "My Playlist": {
            url = '/connect/myplaylist';
            break;
        }
        case "Your Progress": {
            url = '/connect/yourprogress';
            break;
        }
        case "Plan For Future": {
            url = '/connect/smartcashflow';
            break;
        }
        case "Secure Vault": {
            url = '/protect/securevault';
            break;
        }
        case "Insurance": {
            url = '/protect/insurance';
            break;
        }
        case "Overview": {
            url = '/grow/overview';
            break;
        }
        case "Low Down": {
            url = '/grow/lowdown';
            break;
        }
        case "Solutions": {
            url = '/grow/solutions';
            break;
        }
    }

    history.push(url);
};