import React, { useState } from 'react';
import Modal from 'react-modal';
import NavigationView from '../NavigationView';
import classes from './CollapsableMenu.module.css';
import efLogoMobile from '../../../assets/images/efLogoMobile.svg';
import logo from '../../../assets/images/logo.svg';
import { useResponsive } from '../../../hooks/useResponsive';

interface Props {
    connectedAccounts: number;
    connectedKey: string;
    onConnectedLinkClick: (e: any, item: any) => void;
    onConnectButtonClick: () => void;
    onProtectButtonClick: () => void;
    onGrowButtonClick: () => void;
    growKey?: string;
    onGrowLinkClick?: () => void;
    protectKey?: string;
    onProtectLinkClick?: () => void;
    profile: boolean;
    onConnectAccountClick: () => void;
    hideLogo?: boolean;
    onSelectBubleClickHandler?: () => void;
}

Modal.setAppElement('#root');
const modalStyle = {
    overlay: {
        overflowY: "auto" as "auto",
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)',
    },
    content: {
        top: null,
        left: null,
        right: null,
        bottom: null,
        border: null,
        background: null,
        borderRadius: null,
        padding: null,
        position: null
    }
};

const CollapsableMenu: React.FC<Props> = props => {
    const [showMenu, setShowMenu] = useState(false);

    const { screenIsAtMost } = useResponsive();

    return (
        <>
            {!props.hideLogo && <img onClick={() => { setShowMenu(true) }} alt='ef-logo' src={screenIsAtMost("tabLand") ? efLogoMobile : logo} style={{ cursor: 'pointer' }} />}
            <Modal overlayClassName={classes.menu} style={modalStyle} shouldFocusAfterRender={false} isOpen={showMenu} onRequestClose={() => setShowMenu(false)}>
                <NavigationView
                    menuStyle={classes.menuTablet}
                    connectedAccounts={props.connectedAccounts}
                    connectedKey={props.connectedKey}
                    onConnectedLinkClick={(e, item) => { props.onConnectedLinkClick(e, item); setShowMenu(false) }}
                    onConnectButtonClick={() => { props.onConnectButtonClick(); setShowMenu(false) }}
                    onProtectButtonClick={() => { props.onProtectButtonClick(); setShowMenu(false) }}
                    onGrowButtonClick={() => { props.onGrowButtonClick(); setShowMenu(false) }}
                    growKey={props.growKey}
                    onGrowLinkClick={() => { props.onGrowLinkClick(); setShowMenu(false) }}
                    protectKey={props.protectKey}
                    onProtectLinkClick={() => { props.onProtectLinkClick(); setShowMenu(false) }}
                    profile={props.profile}
                    onConnectAccountClick={() => { props.onConnectAccountClick(); setShowMenu(false) }}
                    onSelectBubleClickHandler={() => { props.onSelectBubleClickHandler(); setShowMenu(false) }}
                />
            </Modal>
        </>
    )
};

export default CollapsableMenu;
