import React, { useState, useEffect } from 'react';
import classes from '../Dashboards.module.css';
import { Stack } from 'office-ui-fabric-react';
import BarChart from '../../../Charts/BarChart/BarChart';
import MaskedLabelPair from '../../../UI/MaskedLabelPair';
import Category from '../../CategoryList/Category';
import { addAlpha } from '../../../../utils/colorGenerators';
import DashboardHeader from '../../DashboardHeader/DashboardHeader';
import TimeButton from '../../TimeButton/TimeButton';
import { useNetCashData } from '../../../../hooks/ScoopDashboards/useNetCashData';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';
import { useResponsive } from '../../../../hooks/useResponsive';

interface IProps {
    transactionsData: any,
    onClickIncomes: () => void,
    onClickExpenses: () => void,
};
const NetCash: React.FC<IProps> = props => {
    const { screenIsAtMost } = useResponsive();
    const isMobile = screenIsAtMost('mobile');
    const [toYearly, setToYearly] = useState<boolean>(false);
    const [monthByIdx, setMonthByIdx] = useState<number>(11);
    const {
        fullMonths,
        months,
        monthSet,
        amountsSummed,
        totalMonthlyNetCash,
        monthlyNetCashTotals,
        yrToDateByType,
        totalYrToDate,
    } = useNetCashData(props.transactionsData, toYearly, monthByIdx);

    const baseCategories = ['Ins', 'Outs'];
    const baseColors = ['#30DB20', '#FF9BB2'];
    const baseIcons = [require('../../../../assets/images/scoop/all_income.svg'), require('../../../../assets/images/scoop/all_expense.svg')];

    useEffect(() => {
        toYearly ? setMonthByIdx(0) : setMonthByIdx(11);
    }, [toYearly]);

    const barColors1 = monthSet.map((_, i) => (i !== monthByIdx) ? addAlpha(baseColors[0], .3) : baseColors[0]);
    const barColors2 = monthSet.map((_, i) => (i !== monthByIdx) ? addAlpha(baseColors[1], .3) : baseColors[1]);

    let categoryData = [
        { 
            key: baseCategories[0], 
            text: baseCategories[0], 
            color: baseColors[0],
            icon: baseIcons[0], 
            focused: null, 
            value: toYearly ? yrToDateByType[0] : amountsSummed[0] && amountsSummed[0][monthByIdx],
        },
        { 
            key: baseCategories[1], 
            text: baseCategories[1], 
            color: baseColors[1], 
            icon: baseIcons[1],
            focused: null, 
            value: toYearly ? yrToDateByType[1] : amountsSummed[1] && amountsSummed[1][monthByIdx],
        },
    ];

    const categories = categoryData.map(e => (
        <Stack key={e.key}>
            <Category
                id={e.key}
                title={e.text}
                color={addAlpha(e.color, .3)}
                icon={e.icon}
                onClick={(e.text === 'Ins') ? 
                        props.onClickIncomes :
                        props.onClickExpenses
                }
                value={e.value} 
            />
        </Stack>
    ));

    const mobileCategories = (
        <Stack tokens={{childrenGap: '2rem'}} className={classes.modalCategories}>
            {categoryData.map((currCategory) => (
                <Stack
                    style={{ width: '68vw' }}
                    horizontalAlign="center"
                    verticalAlign="center"
                >
                <Category
                    id={currCategory.key}
                    title={currCategory.text}
                    color={addAlpha(currCategory.color, 0.3)}
                    icon={currCategory.icon}
                    focused={currCategory.focused}
                    onClick={() => null}
                    value={currCategory.value}
                    disableAnimation
                    style={{ width: '90%', height: '5.2rem', borderRadius: '2.9rem' }}
                    imgStyle={{ width: '3.5rem', height: '3.5rem', margin: '0 2rem' }}
                />
                </Stack>
            ))}
        </Stack>
    )

    function updateChart (this: any, e: any) {
        const element = this.getElementAtEvent(e);
                    
        if (element[0] && element.length) {
            const chartIdx = element[0]._index;
            const chartProps = element[0]._chart.config.data.datasets[0];
            setMonthByIdx(chartIdx);
            
            chartProps.data.forEach((_: any, i: number) => {
                element[0]._chart.config.data.datasets[0].backgroundColor[i] = addAlpha(baseColors[0], .3);
                element[0]._chart.config.data.datasets[1].backgroundColor[i] = addAlpha(baseColors[1], .3);
                if (i === chartIdx) {
                    element[0]._chart.config.data.datasets[0].backgroundColor[i] = baseColors[0];
                    element[0]._chart.config.data.datasets[1].backgroundColor[i] = baseColors[1];
                }
            });
            this.update();
        };
    };
    
    return (
        <Stack horizontalAlign={isMobile ? "center" : "" as any} className={isMobile ? classes.outer_container_mobile : classes.outer_container}>
            <DashboardHeader 
                title={toYearly ? 'Year To Date' : fullMonths[monthByIdx]} 
                value={toYearly ? totalYrToDate : totalMonthlyNetCash}
                valueColor='#58E023'
                prevVal={toYearly ? 0 : monthlyNetCashTotals[monthByIdx - 1]}
            />
             <Stack className={classes.inner_container} tokens={{childrenGap: '2rem'}}>
                 <Stack horizontalAlign={isMobile ? "center" : "space-between"} wrap={isMobile} horizontal className={isMobile ? classes.charts_mobile : classes.charts} verticalAlign='center'>
                    <Stack className={isMobile ? classes.bar_view_mobile : classes.bar_view} tokens={{childrenGap: 10}}>
                        {/* <Stack horizontal horizontalAlign='space-evenly'>
                            <TimeButton text='YEAR TO DATE' isFocused={toYearly} onClick={() => { setToYearly(true); setMonthByIdx(11);}} />
                            <TimeButton text='MONTHLY' isFocused={!toYearly} onClick={() => { setToYearly(false); setMonthByIdx(11);}} />
                        </Stack> */}
                        <BarChart
                            fontSize={isMobile ? 8 : undefined}
                            barPercentage={isMobile ? 0.65 : 0.40}  
                            datasets={{
                                labels: months,
                                datasets: [
                                    {
                                        label: baseCategories[0],
                                        data: amountsSummed[0] && amountsSummed[0].map((e: any) => Math.round(e * 1e2) / 1e2),
                                        backgroundColor: barColors1,
                                        borderColor: 'transparent',
                                    },
                                    {
                                        label: baseCategories[1],
                                        data: amountsSummed[1] && amountsSummed[1].map((e: any) => -1 * Math.round(e * 1e2) / 1e2),
                                        backgroundColor: barColors2,
                                        borderColor: 'transparent',
                                    },
                                    {
                                        label: 'Totals',
                                        type: 'line',
                                        fill: false,
                                        data: (monthlyNetCashTotals && monthlyNetCashTotals.map((e: any) => Math.round(e * 1e2) / 1e2)) || [],
                                        borderColor: '#FEB81E',
                                    },
                                ]
                                }}
                                onClick={updateChart}
                        />
                    </Stack>
                    <Stack style={{ margin: isMobile && '4rem 0' }} className={classes.alt_view} verticalAlign='center' horizontalAlign='center' tokens={{childrenGap: 10}}>
                        <Stack horizontal verticalAlign='center' style={{width: '25.8rem', height: '8.3rem', boxShadow: '0 2px 9px rgba(0, 0, 0, 0.11)', borderRadius: '1.2rem'}}>
                            <Stack horizontalAlign='center' style={{width: '50%'}}>
                                <EFLabel style={{fontWeight: 'normal', fontSize: '1.5rem'}}>Year-To-Date</EFLabel>
                                <MaskedLabelPair
                                    isHorizontal={false}
                                    isBulleted={false}
                                    includeThousandsSeparator
                                    prefix='$'
                                    suffix=''
                                    title={categoryData[0].text}
                                    titleStyle={{color: categoryData[0].color}}
                                    value={yrToDateByType[0]}
                                    color='#293861'
                                    valueFontSize={23}
                                />
                            </Stack>
                            <div style={{height: '100%', borderLeft: '0.5px solid rgba(0, 0, 0, 0.15)', borderRight: '0.5px solid rgba(0, 0, 0, 0.15)'}} />
                            <Stack horizontalAlign='center' style={{width: '50%'}}>
                                <EFLabel style={{fontWeight: 'normal', fontSize: '1.5rem'}}>Year-To-Date</EFLabel>
                                <MaskedLabelPair
                                    isHorizontal={false}
                                    isBulleted={false}
                                    includeThousandsSeparator
                                    prefix='$'
                                    suffix=''
                                    title={categoryData[1].text}
                                    titleStyle={{color: categoryData[1].color}}
                                    value={yrToDateByType[1]}
                                    color='#293861'
                                    valueFontSize={23}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign={!isMobile ? 'start' : 'center'} tokens={{childrenGap: '6rem'}}>
                    {isMobile ? mobileCategories : (
                        <Stack tokens={{childrenGap: 12}}>
                            {categories}
                        </Stack>
                    )}
                </Stack>
             </Stack>
        </Stack>
    )
};

export default NetCash;
