/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
// import 'chartjs-plugin-zoom';
import 'chartjs-plugin-draggable';
import moment from 'moment';
import { useResponsive } from '../../../hooks/useResponsive';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../utils/Masking';
import { useSelector } from 'react-redux';
import 'chartjs-plugin-style';

interface Props {
    onChartData?: (e: any) => void;
    initAnn?: any;
    datasets?: object;
    barRadius?: number;
    onClick?: (this: any, e: any) => void;
    maxTicksXLimit?: number;
    maxTicksYLimit?: number;
    minX?: string | number;
    maxX?: string | number;
    minY?: number;
    maxY?: string | number;
    unit?: string;
    unitStepSize?: number;
    isMonthlyView?: boolean;
    onDrag?: (e: any) => void;
    retireYr?: number | undefined;
    showRetire?: boolean;
    // highlightColor: string;
    // highlight: number;
};

const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowNegative: true,
    allowLeadingZeroes: false,
});

const CashflowChart: React.FC<Props> = props => {
  const chartRef = createRef<Line>(); 
  const { screenIsAtLeast } = useResponsive();
  const userAge = useSelector((state: any) => state.session.user?.age);

  useEffect(() => {
    const inst = chartRef.current.chartInstance;
    inst.annotation.elements['vline'].options.value = props.initAnn;
    inst.update();
    props.onChartData && props.onChartData(inst);
}, [props.initAnn]);

  return (
    <Line
      ref={chartRef}
      getElementAtEvent={props.onClick}
      data={props.datasets}
      options={{
        plugins: {
            datalabels: {
                display: false,
            }
        },
        hover: {
            intersect: false,
        },
        tooltips: {
            // filter: function (tooltipItem) {
            //     return tooltipItem.datasetIndex !== 0;
            // },
            // Disable the on-canvas tooltip
            enabled: false,
            custom: function(tooltipModel) {
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = '<table></table>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = '0';
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                // Set Text
                if (tooltipModel.body && tooltipModel.body[0]) {
                    const currTime = moment(tooltipModel.title[0]).add(5, 'days');
                    const ageNum = parseInt(userAge);
                    const ageInc = parseInt(currTime.format("YYYY")) - parseInt(moment().format("YYYY"));
                    const currAge = ageNum + ageInc;
                    const ageStr = userAge ? `Age: ${currAge} ` : '';
                    const t = props.isMonthlyView ? 
                        `Month: ${currTime.format("MMM")}` : 
                        `Year: ${currTime.format("YYYY")}`;

                    const title = tooltipModel.body[0].lines[0].split(' ');
                    const val = Math.round(title[2]);
                    const customName = () => {
                        if (title[1] === 'Net:') {
                            return "Net Cash Flow:"
                        } else if (title[1] === 'PreviousIncome:') {
                            return "Previous Income:"
                        } else if (title[1] === 'PreviousExpenses:') {
                            return "Previous Expenses:"
                        } else if (title[1] === 'PreviousNet:') {
                            return "Previous Net Cash Flow:"
                        }
                        return title[1]
                    }
                    const mthly = props.isMonthlyView ? 
                        `Monthly ${customName()} ${maskValue(val, numberMask)}` : `Monthly ${customName()} ${maskValue(Math.round(val / 12), numberMask)}`;
                    const yrly = props.isMonthlyView ? 
                        `Yearly ${customName()} ${maskValue(val * 12, numberMask)}` : 
                        `Yearly ${customName()} ${maskValue(val, numberMask)}`;

                    const style1 = '"display: flex; flex-direction: row; font-weight: 600; ';
                    const style2 = `justify-content: ${userAge ? 'space-between' : 'center'};"`;
                    const spacedDiv = '<div style=' + style1 + style2 + '>';
                    
                    const isNotDelta = title[1] === 'Expenses:' || title[1] === 'Income:' || title[1] === 'Net:';

                    // eslint-disable-next-line no-useless-concat
                    var innerHtml = spacedDiv + (isNotDelta ? `<div>${ageStr}</div><div>${t}</div>` : `<div/>`) + '</div>';
                    if (isNotDelta) {
                        innerHtml += '<div style="margin: 1rem 0 0 0">' + mthly + '</div>';
                        innerHtml += '<div style="margin: 1rem 0 0 0">' + yrly + '</div>';
                    } else {
                        const title = customName().replace(/([A-Z])/g, ' $1').trim()
                        innerHtml += `<div style="font-weight: normal"><strong>${title}</strong> ${maskValue(val, numberMask)}</div>`;
                    }

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.backgroundColor = 'white';
                tooltipEl.style.boxShadow = '4px 4px 14px rgba(0, 0, 0, 0.1)';
                tooltipEl.style.opacity = '1';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                tooltipEl.style.borderRadius = '5px';
                tooltipEl.style.color = '#293861';
                tooltipEl.style.fontFamily = 'brandon-grotesque';
                tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        },
        barRadius: props.barRadius,
        maintainAspectRatio: false,
        cornerRadius: props.barRadius,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: props.unit,
                    unitStepSize: props.unitStepSize,
                    displayFormats: {
                        'month': screenIsAtLeast('tabPort') ? `MMM` : `M`,
                        'year': screenIsAtLeast('tabPort') ? `YYYY` : `'YY`,
                    }
                },
                barPercentage: 0.4,
                gridLines: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: false,
                    borderDash: [3, 4],
                    drawBorder: false,
                    zeroLineWidth: 0
                },
                ticks: {
                    display: false,
                    maxTicksLimit: props.maxTicksXLimit,
                    beginAtZero: false,
                    padding: 10,
                    fontFamily: 'brandon-grotesque',
                    min: props.minX,
                    max: props.maxX,
                },
            }],
            yAxes: [{
                gridLines: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: false,
                    drawBorder: false,
                    zeroLineWidth: 0
                },
                ticks: {
                    beginAtZero: false,
                    maxTicksLimit: props.maxTicksYLimit || 6, //maxing at 1 causes showing 2 ticks so use .5 for 1
                    padding: 15,
                    fontFamily: 'brandon-grotesque',
                    callback: (value: number) => {
                        if (Math.abs(value) < 1000000 && Math.abs(value) > 999) {
                            return Math.round(value / 1000) + 'k';
                        } else if (Math.abs(value) > 1000000) {
                            return Math.round(value / 1000000) + 'm';
                        } else {
                            return Math.round(value);
                        }
                    },
                    min: props.minY,
                    max: props.maxY,
                }
            }]
        },
        elements: {
            point:{
                radius: 0,
            }
        },
        zoom: {
            enabled: false,
            drag: false,
            mode: 'xy',
        },
        pan: {
            enabled: false,
            mode: 'x',
            speed: 60,
        },
        annotation: {
            drawTime: 'afterDraw',
            events: ['click'],
            annotations: [
                {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: props.retireYr,
                    backgroundColor: '#9B9B9B',
                    borderColor: '#9B9B9B',
                    borderWidth: 3,
                    label: {
                        backgroundColor: '#9B9B9B',
                        position: 'top',
                        content: 'Retirement Age',
                        xPadding: 20,
                        yPadding: 5,
                        yAdjust: 10,
                        enabled: props.showRetire && props.retireYr,
                        font: {
                            family: "brandon-grotesque",
                            size: 20,
                            style: "bold",
                            color: "#FFFFFF",
                        },
                    },
                },
                {
                    id: 'vline',
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: props.initAnn,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    borderWidth: 1,
                    label: {
                        backgroundColor: 'rgba(254, 184, 30, .20)',
                        position: 'top',
                        content: ' ',
                        xPadding: 12,
                        yPadding: 231,
                        enabled: true
                    },
                    draggable: true,
                    onDrag: props.onDrag,
                },
                {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: props.initAnn,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    borderWidth: 3,
                    label: {
                        backgroundColor: '#FEB81E',
                        position: 'bottom',
                        content: props.unit === 'year' ? 
                            moment(props.initAnn).format("YYYY") : moment(props.initAnn).format("MMM"),
                        xPadding: 7,
                        yPadding: 5,
                        enabled: true,
                        font: {
                            family: "brandon-grotesque",
                            size: 25,
                            style: "bold",
                            color: "#FFFFFF",
                        },
                    },
                    draggable: true,
                    onDrag: props.onDrag,
                },
            ],
        },
      }}
    />
  )
}

export default CashflowChart;
