/* eslint-disable @typescript-eslint/no-array-constructor */
import { chartOrganizedMonthNames, currMonth, elevenMonthsAgo, currJan, getMonths } from '../../utils/dateTools';
import moment from 'moment';

const { fullMonths, chartMonths, dates } = getMonths(elevenMonthsAgo, currMonth);
const { chartMonths: chartMonthsYrly } = getMonths(currJan, currMonth);
const containerTypes = ["INCOME", "EXPENSE"];
const efCategoryIds = ["EF20", "EF7", "EF19", "EF6", "EF15", "EF5", "EF4", "EF3", "EF17", "EF9", "EF10", "EF11" ];

export const useNetCashData = (transactionsData: any, toYearly: boolean, monthByIdx: number) => {
    const months = toYearly ? chartOrganizedMonthNames : chartMonths;
    const monthSet = toYearly ? chartMonthsYrly : months;

    const baseData = transactionsData.filter((e: any) => e.dashboards.find((db: any) => db === 'cashflow'));

    const dataByType = containerTypes.map((container: any) => baseData && baseData.filter((e: any) => container === e.categoryType));

    const amountsByType = dataByType && dataByType.map((container: any) => container && container.map((x: any) => { 
        return { date: x.date, amount: x.amount.amount } 
     }));

    const amountsBySortedMonths = amountsByType && amountsByType.map((type: any) => type && type.reduce((a: any, c: any) => {
        const m = parseInt(c.date.split(('-'))[1]) - 1;
        (a[m]) ? a[m].data.push(c) : a[m] = { date: c.date, data: [c] };
        return a;
    }, []).sort((a: any, b: any) => b.date.localeCompare(a.date)));

    const x = amountsBySortedMonths.map((type: any) => 
        dates.map((e: any) => {
            const currM = parseInt(e.split(('-'))[1]) - 1;
            const targetObj = type && type.find((x: any) => x && parseInt(x.date.split(('-'))[1]) - 1 === currM);
            if (targetObj) {
                return {date: targetObj.date, data: targetObj.data};
            } else {
                return {date: e, data: [{amount: 0}]};
            }
    }));

    const y = x.map((type: any) => type.filter((e: any) => e.date >= currJan.format('YYYY-MM-01') && e.date < (moment().set('date', 1).add(1, 'months')).format('YYYY-MM-01')));

    const sortedData = toYearly ? y : x;

    const amountsSummed = sortedData && sortedData.map((type: any) => {
        return type && type.map((e: any) => e.data.map((e: any) => e.amount).reduce((a: any, c: any) => a += c));
    });
    
    const totalMonthlyNetCash = (amountsSummed[0] && amountsSummed[0][monthByIdx]) - (amountsSummed[1] && amountsSummed[1][monthByIdx]);
    const monthlyNetCashTotals = amountsSummed[0] && amountsSummed[0].map((amt: any, i: number) => { 
        const sum = amt - (amountsSummed[1] && amountsSummed[1][i]);
        return sum ? Math.round(sum) : 0;
    });

    const yrToDateByType = amountsSummed && amountsSummed.map((type: any) => type && type.reduce((a: any, c: any) => a +=c, 0));
    const totalYrToDate = (yrToDateByType[0] && yrToDateByType[0]) - (yrToDateByType[1] && yrToDateByType[1]);

    return {
        fullMonths,
        chartMonths,
        chartMonthsYrly,
        containerTypes,
        efCategoryIds,
        baseData,
        months,
        monthSet,
        dataByType,
        amountsByType,
        amountsBySortedMonths,
        amountsSummed,
        totalMonthlyNetCash,
        monthlyNetCashTotals,
        yrToDateByType,
        totalYrToDate,
    };
};