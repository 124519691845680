import React from 'react';
import { IconButton, Label, Stack } from 'office-ui-fabric-react/lib/';
import classes from './ConnectAccounts.module.css';
import * as aggregationActions from '../../../store/actions/Aggregation';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

interface IProps {
    style?: object,
    connected: number,
    onClick?: () => void,
    profile: any
    showConnectedAccounts: () => void;
};

const ConnectAccounts: React.FC<IProps> = props => {
    const dispatch = useDispatch();
    let history = useHistory();
    const accountNum = props.connected === 0 ? 'Connect an Account' : `${props.connected} Connected Accounts`;

    return (
        <Stack className={classes.container} style={{marginTop: '5rem'}}>
            <div className={classes.container_inner} onClick={props.showConnectedAccounts} >
                <IconButton className={classes.button} onClick={props.onClick} style={{cursor: !props.profile ? 'pointer' : 'default'}} >
                    <img alt='orange-rectangle' src={require('../../../assets/icons/bank.svg')} />
                </IconButton>
                <Label style={{cursor: !props.profile ? 'pointer' : 'default'}} className={classes.font_inner}>{accountNum}</Label>
            </div>
            {!props.profile && <Label className={classes.font_outer} style={{cursor: 'pointer'}} onClick={() => {
                dispatch(aggregationActions.getFastlinkCredentials(history, false));
                history.push('/aggregate');
            }}>Connect accounts</Label>}
        </Stack>
    )
};

export default ConnectAccounts;
