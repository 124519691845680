import React, { useEffect, useState } from "react";
import { SearchBox, Stack } from "office-ui-fabric-react";
import EditorModal from "../../EditorModal/EditorModal";
import classes from "../Dashboards.module.css";
import Category from "../../CategoryList/Category";
import { addAlpha } from "../../../../utils/colorGenerators";
import EFButton from "../../../Atomic/EFButton/EFButton";
import ScoopAccordion from "../../ScoopAccordion/ScoopAccordion";
import { useMutation } from "react-apollo";
import { RECATEGORIZE_TXN } from "../../../../hooks/Apollo/mutations";
import { GET_TRANSACTIONS } from "../../../../hooks/Apollo/queries";
import { currMonth, elevenMonthsAgo } from "../../../../utils/dateTools";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../../../store/actions/Users";
import EFLabel from "../../../Atomic/EFLabel/EFLabel";
import MaskedLabelPair from "../../../UI/MaskedLabelPair";
import moment from "moment";

interface IProps {
  txnData?: any;
  settingsCategories?: any;
  isOpen?: boolean;
  onClickExit?: () => void;
  catData?: any;
}

const dateYrAgo = elevenMonthsAgo.format("YYYY-MM-01");
const dateCurr = currMonth.format("YYYY-MM-DD");
const queryVariables = { fromDate: dateYrAgo, toDate: dateCurr };

const SubCategoryModal: React.FC<IProps> = (props) => {
  console.log(props.txnData);
  let history = useHistory();
  const dispatch = useDispatch();
  const initCurrSub = props.catData?.filter(
    (e) => e.id === props.txnData?.id
  )?.[0]?.subCatId;
  const [currSub, setCurrSub] = useState(initCurrSub);
  const [recategorize] = useMutation(RECATEGORIZE_TXN, {
    refetchQueries: [{ query: GET_TRANSACTIONS, variables: queryVariables }],
  });
  const [search, setSearch] = useState("");
  const [filteredCats, setFilteredCats] = useState(props.settingsCategories);

  useEffect(() => {
    const filterCats = props.settingsCategories
      ?.map((cat) => ({
        ...cat,
        subs: cat?.subs?.filter((sub) =>
          sub.name.toLowerCase().includes(search?.toLowerCase())
        ),
      }))
      ?.filter((cat) => cat?.subs?.length);
    setFilteredCats(filterCats);
  }, [props.settingsCategories, search]);

  let subCategories = (currCategory: any) => (
    <Stack
      style={{
        margin: "1.5rem 3rem",
        border: "1px solid #F0F0F0",
        borderRadius: "1.4rem",
      }}
    >
      {currCategory?.subs?.map((e, i) => (
        <ScoopAccordion
          key={e.id}
          id={e.id}
          title={e.name}
          color={currCategory.color}
          value={0}
          isNotOpenable
          style={{
            marginBottom: "unset",
            border: currCategory?.subs?.length - 1 === i && "unset",
          }}
          containerStyle={{
            minWidth: "unset",
            width: "23rem",
            cursor: "pointer",
          }}
          onClick={() => setCurrSub(e.id)}
          useCheckerIcon={e.id === currSub}
        />
      ))}
    </Stack>
  );

  let content = (
    <Stack className={classes.modalCategories}>
      {filteredCats.map((currCategory) => (
        <Stack
          // tokens={{ childrenGap: "3.4rem" }}
          horizontalAlign="center"
          verticalAlign="center"
        >
          <Category
            id={currCategory.key}
            title={currCategory.text}
            color={addAlpha(currCategory.color, 0.3)}
            icon={currCategory.assets}
            onClick={() => null}
            value={currCategory.value}
            disableAnimation
          />
          {subCategories(currCategory)}
        </Stack>
      ))}
    </Stack>
  );

  let button = (
    <Stack horizontal tokens={{ childrenGap: "2rem" }}>
      <EFButton
        text="SAVE"
        isDisabled={currSub === ""}
        onClick={async () => {
          await recategorize({
            variables: {
              transactionId: parseFloat(props.txnData?.id),
              categoryId: currSub,
            },
          }).catch((error) => console.error(error));
          dispatch(actions.getUpdatedConnectedAccounts(history));
          props.onClickExit();
        }}
        style={{ minWidth: "16rem", marginTop: "5rem" }}
      />
    </Stack>
  );

  return (
    <EditorModal
      noTitle
      openModal={props.isOpen}
      onClickExit={props.onClickExit}
    >
      <Stack className={classes.subCatTitle}>
        <EFLabel style={labelStyle}>Move transaction to another category</EFLabel>
        <EFLabel style={{ fontWeight: 500, fontSize: "2rem" }}>
          {props.txnData?.label}
        </EFLabel>
        <Stack
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
        >
          <Stack horizontal horizontalAlign="space-between">
            <MaskedLabelPair
              isBulleted={false}
              includeThousandsSeparator
              prefix="($"
              suffix=""
              title={""}
              titleStyle={{ fontWeight: "normal" }}
              value={props.txnData?.amount}
              valueFontSize={"1.6rem"}
            />
            <EFLabel style={{ fontWeight: "normal", fontSize: "1.6rem" }}>
              {" - " + moment(props.txnData?.date).format("MMM DD") + ")"}
            </EFLabel>
          </Stack>
        </Stack>
      </Stack>
      <Stack horizontalAlign="center" className={classes.container_list}>
        <SearchBox
          placeholder="Search subcategory"
          onChange={(_, val) => setSearch(val)}
          styles={searchBoxStyle}
        />
        {content}
        {button}
      </Stack>
    </EditorModal>
  );
};

export default SubCategoryModal;

const searchBoxStyle = {
  root: {
    width: "80%",
    background: "#F1F1F1",
    borderRadius: "2.3rem",
    border: "unset",
    height: "4.6rem",
    marginBottom: "1rem",
  },
  iconContainer: {
    color: "unset",
    fontSize: "2rem",
    transform: "rotate(-90deg)",
  },
  field: {
    fontSize: "1.6rem",
    fontFamily: "brandon-grotesque",
  },
};

const labelStyle = {
  fontSize: '2.2rem',
  fontWeight: 600, 
  textAlign: 'center'
};
