import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import NavigationView from "../../components/UI/NavigationView";
import DashboardContainer from "../../components/UI/DashboardContainer/DashboardContainer";
import Backdrop from "../../components/Atomic/Backdrop/Backdrop";
import ProfileSwitcher from "../../components/UI/ProfileSwitcher/ProfileSwitcher";
import { handleSideNavigation, isCanonical } from "../../utils/global";
import { useResponsive } from "../../hooks/useResponsive";
import classes from "./InsurancePage.module.css";
import close from '../../assets/images/close.svg';
import maleAvatar from "../../assets/images/maleAvatar.svg";
import staceyAvatar from "../../assets/images/staceyAvatar.png";
import rosaAvatar from "../../assets/images/rosaAvatar.png";
import darylAvatar from "../../assets/images/darylAvatar.png";
import martinAvatar from "../../assets/images/martinAvatar.svg";
import eveAvatar from "../../assets/images/eveAvatar.svg";
import Modal from "react-modal";
import modalCardStyle from '../../components/UI/Modals/CommonModalInlineStyle';
import { eUserSettings } from "../../components/UserSettings/UserSettings";
import { getJobInfo, removeCurrentJob } from "../../store/actions/CareerPlanning";
import Greeting from "../../components/UI/Greeting";
import EFButton from "../../components/Atomic/EFButton/EFButton";

import insuranceOverview from '../../assets/images/protect/lifeInsurance/insuranceOverview.svg';
import termLifeInsurance from '../../assets/images/protect/lifeInsurance/termLifeInsurance.svg';
import permanentLifeInsurance from '../../assets/images/protect/lifeInsurance/permanentLifeInsurance.svg';
import childrenLifeInsurance from '../../assets/images/protect/lifeInsurance/childrenLifeInsurance.svg';
import loanProtectionInsurance from '../../assets/images/protect/lifeInsurance/loanProtectionInsurance.svg';
import longTermCare from '../../assets/images/protect/lifeInsurance/longTermCare.svg';
import supplementalInsurance from '../../assets/images/protect/lifeInsurance/supplementalInsurance.svg';
import TagManager from 'react-gtm-module'

const InsurancePage: React.FC<{}> = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.session.user);
    const realUser = useSelector((state: any) => state.data.realUser);
    const [openModal, setOpenModal] = useState(false);
    const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);
    const [showCarousel, setShowCarousel] = useState(true);
    const [currentView, setCurrentView] = useState('Insurance');
    const [profileTab, setProfileTab] = useState(eUserSettings.PROFILE);
    const [tempUrl, setTempUrl] = useState("");

    // Google analytics set up for page
    const tagManagerArgs = {
        dataLayer: {
            userId: realUser?.trackingId,
            page: 'Protect Insurance'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    useEffect(() => {
        const insuranceDisclaimer = localStorage.getItem("ef_insuranceDisclaimerAccepted");
        if (insuranceDisclaimer === null) {
            localStorage.setItem("ef_insuranceDisclaimerAccepted", JSON.stringify([]));
        }
    }, []);

    const openConnectedAccounts = () => {
        if (isCanonical(user.name)) return;

        setProfileTab(eUserSettings.CONNECTED_ACCOUNTS);
        setShowProfileSwitcher(true);
        setShowCarousel(false);
    };

    const token = user.access_token;

    const { screenIsAtLeast, screenIsAtMost } = useResponsive();

    const insuranceCardsInfo = [
        {
            key: 1,
            name: 'TERM LIFE INSURANCE',
            img: termLifeInsurance,
            url: 'https://equalfuture.covrtech.com/landing/term'
        },
        {
            key: 2,
            name: 'PERMANENT LIFE INSURANCE',
            img: permanentLifeInsurance,
            url: 'https://equalfuture.covrtech.com/landing/permanent'
        },
        {
            key: 3,
            name: "CHILDREN'S LIFE INSURANCE",
            img: childrenLifeInsurance,
            url: 'https://equalfuture.covrtech.com/landing/children'
        },
        {
            key: 4,
            name: 'LOAN PROTECTION LIFE INSURANCE',
            img: loanProtectionInsurance,
            url: 'https://equalfuture.covrtech.com/landing/loan-protector'
        },
        {
            key: 5,
            name: 'LONG-TERM CARE',
            img: longTermCare,
            url: 'https://equalfuture.covrtech.com/landing/long-term-care'
        },
        {
            key: 6,
            name: 'SUPPLEMENTAL INSURANCE',
            img: supplementalInsurance,
            url: 'https://equalfuture.covrtech.com/landing/supplemental'
        }
    ]

    let profiles = [
        {
            name: "Stacey",
            img: staceyAvatar,
            label: "Planning for College",
            profileNumber: 2,
        },
        {
            name: "Rosa",
            img: rosaAvatar,
            label: "Begin Carrer",
            profileNumber: 3,
        },
        {
            name: "Daryl",
            img: darylAvatar,
            label: "Tools for Parents",
            profileNumber: 4,
        },
        {
            name: "Martin",
            img: martinAvatar,
            label: "Buy a House",
            profileNumber: 5,
        },
        {
            name: "Eve",
            img: eveAvatar,
            label: "Planning for Retirement",
            profileNumber: 6,
        },
    ];

    const handleInsuraceCardClick = (target: string) => {
        if (isCanonical(user.name)) {
            window.open(target, '_blank');
        }
        else {
            const insuranceDisclaimerAccepted = JSON.parse(localStorage.getItem("ef_insuranceDisclaimerAccepted"));
            if (insuranceDisclaimerAccepted.filter(e => e.email === user.email).length > 0) {
                window.open(target, '_blank');
            }
            else {
                setOpenModal(true);
                setTempUrl(target);
                window.scrollTo(0, 0);
            }
        }
    }

    let content = null;

    if (Object.keys(user).length > 0) {
        let selectCardContent = {
            name: user.name,
            img: !user.anonymized
                ? maleAvatar
                : user.name.length > 0
                    ? profiles.filter(
                        (profile) =>
                            profile.name.toLowerCase() === user.name.toLowerCase()
                    )[0].img
                    : "",
            label: !user.anonymized
                ? ""
                : user.name.length > 0
                    ? profiles.filter(
                        (profile) =>
                            profile.name.toLowerCase() === user.name.toLowerCase()
                    )[0].label
                    : "",
            profileNumber: !user.anonymized
                ? 2
                : profiles.filter(
                    (profile) =>
                        profile.name.toLowerCase() === user.name.toLowerCase()
                )[0].profileNumber - 1
        };

        content = (
            <div
                className={`${screenIsAtMost('tabLand') ? classes.containerMobile : [classes.container, classes.treeBackground].join(' ')}`}
            >

                {openModal && <Modal
                    isOpen={openModal}
                    onRequestClose={() => setOpenModal(false)}
                    style={{
                        content: { ...modalCardStyle().content, width: "100%" },
                        overlay: modalCardStyle().overlay,
                    }}
                >
                    <div className={classes.disclaimerContainer} onClick={(event) => event.stopPropagation()}>
                        <div className={classes.closeContainer}>
                            <img onClick={() => {
                                setOpenModal(false)
                            }} src={close} alt="close" style={{ width: '2rem', cursor: 'pointer' }} />
                        </div>
                        <div className={classes.textContainer}>
                            <p className={classes.disclaimerContent}>
                                Thank you for your interest in insurance protection.
                            </p>
                            <p className={classes.disclaimerContent}>
                                After clicking “I accept” below, you will be directed to away
                                from EqualFuture’s website and redirected to our
                                selected partner’s site to assist you with your insurance
                                needs.
                            </p>
                            <p className={classes.disclaimerContent}>
                                We recommend that you review our partners
                                privacy and information security policies, because they
                                may differ from EqualFuture’s policices.
                            </p>
                            <p className={classes.disclaimerContent}>
                                Please note that we do not have direct control over the partners’ website.
                                By clicking below, you agree to waive any claim against
                                EqualFuture that may arise due to your visit to the
                                website to which you may be redirected.
                            </p>
                            <p className={classes.disclaimerContent}>
                                Thank you.
                            </p>
                        </div>
                        <div className={classes.buttonsContainer} style={{ marginBottom: '4rem' }}>
                            <EFButton onClick={(event) => {
                                event.stopPropagation();
                                const insuranceDisclaimerAccepted = JSON.parse(localStorage.getItem("ef_insuranceDisclaimerAccepted"));
                                insuranceDisclaimerAccepted.push({
                                    email: user.email
                                });
                                localStorage.setItem("ef_insuranceDisclaimerAccepted", JSON.stringify(insuranceDisclaimerAccepted));
                                window.open(tempUrl, "_blank");
                                setTempUrl("");
                                setOpenModal(false);
                            }} text="I ACCEPT" />
                        </div>
                    </div>
                </Modal>}

                {
                    showProfileSwitcher && (
                        <Backdrop
                            customStyle={{
                                overflow: "auto",
                            }}
                            onClick={() => {
                                setShowCarousel(true);
                                setProfileTab(eUserSettings.PROFILE);
                                setShowProfileSwitcher(false);
                            }}
                        >
                            <ProfileSwitcher
                                showCarousel={showCarousel}
                                startingProfile={selectCardContent.profileNumber}
                                onSelectedProfile={(name: string) => {
                                    if (name !== "" && name !== "STACEY") {
                                        dispatch(removeCurrentJob());
                                    }
                                    else {
                                        if (name === "STACEY") {
                                            dispatch(getJobInfo(15 - 1299.08));
                                        }
                                        else {
                                            const careerGoalLE = realUser.lifeEvents.find(
                                                (le: any) => le.externalId === "6ceIi2ikmezTJtxIDEuRsg" && le.status !== "NEW"
                                            );
                                            if (careerGoalLE) {
                                                const getOnetCode = careerGoalLE.customData.selectedCareerOnetCode;
                                                dispatch(getJobInfo(getOnetCode));
                                            } else {
                                                dispatch(removeCurrentJob());
                                            }
                                        }
                                    }
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(false);
                                }}
                                profileTab={profileTab}
                                onClose={() => {
                                    setShowCarousel(true);
                                    setShowProfileSwitcher(false)}}
                            />
                        </Backdrop>
                    )
                }

                <div
                    style={{ flexDirection: screenIsAtMost('tabLand') ? 'column' : 'row', alignItems: screenIsAtMost('tabLand') ? 'center' : '' }}
                    className={classes.content}
                >

                    {screenIsAtLeast("desktop") && (
                        <NavigationView
                            profile={isCanonical(user.name)}
                            connectedAccounts={user.connectedAccounts.length}
                            protectKey={"Insurance"}
                            onProtectLinkClick={(event, item) => {
                                handleSideNavigation(item.name, history)
                            }}
                            onConnectButtonClick={() => history.push("/connect")}
                            onGrowButtonClick={() => history.push("/grow/overview")}
                            onConnectAccountClick={openConnectedAccounts}
                            onProtectButtonClick={() => history.push("/protect")}
                            currentDashboard="PROTECT"
                            onSelectBubleClickHandler={() => {
                                setProfileTab(eUserSettings.PROFILE);
                                setShowProfileSwitcher(true);
                            }}
                        />
                    )}

                    {screenIsAtMost("tabLand") &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '85%', paddingTop: '3rem' }}>

                            <Greeting
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={user.connectedAccounts.length}
                                connectedKey={currentView}
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                onConnectAccountClick={openConnectedAccounts}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                showOnlySalutation
                            />

                            <Greeting
                                hideMenuLogo
                                hideSalutation
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={user.connectedAccounts.length}
                                connectedKey={currentView}
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                onConnectAccountClick={openConnectedAccounts}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />
                        </div>

                    }


                    <DashboardContainer childrenGap={30}>
                        <div
                            style={{
                                width: "100%", display: 'flex',
                                justifyContent: 'space-between', alignItems: 'center'
                            }}
                        >

                            {!screenIsAtMost("tabLand") && <Greeting
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={user.connectedAccounts.length}
                                connectedKey={currentView}
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                onConnectAccountClick={openConnectedAccounts}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                showOnlySalutation
                            />}

                            {!screenIsAtMost("tabLand") && <Greeting
                                hideSalutation
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={user.connectedAccounts.length}
                                connectedKey={currentView}
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                onConnectAccountClick={openConnectedAccounts}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />}
                        </div>

                        <div className={classes.overviewContainer}>
                            <div className={classes.insuranceCardContainer} >
                                <img src={insuranceOverview} alt="Overview" onClick={() => {
                                    handleInsuraceCardClick("https://equalfuture.covrtech.com/")
                                }} />
                            </div>
                        </div>

                        <div data-tour="insurances" className={classes.cardsContainer}>
                            {insuranceCardsInfo.map((insurance) => {
                                return (
                                    <div className={classes.insuranceCardContainer} key={insurance.key}>
                                        <img src={insurance.img} alt={insurance.name} onClick={() => {
                                            handleInsuraceCardClick(insurance.url)
                                        }} />
                                    </div>
                                )
                            })}
                        </div>
                    </DashboardContainer>
                </div>
            </div >
        );
    }
    return content;
};

export default InsurancePage;
