
import React, {useState} from 'react';
import rightIcon from '../../../../assets/images/chevronRight.svg';
import SettingsModal from './SettingsModal';

import classesParent from '../SettingsOptions/SettingsOptions.module.css';
import classes from './PrivacyOptions.module.css'; 
import animations from '../../../../utils/animations.module.css';


interface Props {
    termsContent: JSX.Element;
    privacyContent: JSX.Element;
}

const LegalOptions: React.FC<Props> = props => {

    const [termsModal, setTermsModal] = useState(false);
    const [privacyModal, setPrivacyModal] = useState(false);

    return (
        <div>
            <div onClick={() => {setTermsModal(true);}}
                className={`${ classes.card } ${ animations.cardShadowExpand }`}>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>Terms of Use</div>
                    <img className={classes.rightImg} alt="rightImg" src={rightIcon}/>
                </div>
            </div>
            <div onClick={() => {setPrivacyModal(true);}}
                className={`${ classes.card } ${ animations.cardShadowExpand }`}>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>Privacy</div>
                    <img className={classes.rightImg} alt="rightImg" src={rightIcon}/>
                </div>
            </div>
            {termsModal &&
            <SettingsModal 
                Content={props.termsContent} 
                handleModal={() => setTermsModal(false)} />} 
            {privacyModal &&
            <SettingsModal 
                Content={props.privacyContent} 
                handleModal={() => setPrivacyModal(false)} />} 
        </div>
    )
};

export default LegalOptions;
