/* eslint-disable @typescript-eslint/no-array-constructor */
import { chartOrganizedMonthNames, currMonth, elevenMonthsAgo, currJan, getMonths } from '../../utils/dateTools';
import moment from 'moment';

const { fullMonths: fullMonthsM, chartMonths, dates } = getMonths(elevenMonthsAgo, currMonth);
const { fullMonths: fullMonthsY, chartMonths: chartMonthsYrly } = getMonths(currJan, currMonth);
const containerTypes = ["bank", "investment", "loan", "creditCard"];
const containerEFTypes = [["bank", "investment"], ["loan", "creditCard"]];

export const useNetWorthData = (accBalancesData: any, toYearly: boolean) => {
    const fullMonths = toYearly ? fullMonthsY : fullMonthsM;

    const baseData = accBalancesData.filter((e: any) => e.account.dashboards.find((db: any) => db === 'networth'));

    const months = toYearly ? chartOrganizedMonthNames : chartMonths;

    // Used to map the bar colors in barColors and updateChart
    const monthSet = toYearly ? chartMonthsYrly : months;

    const dataByType = containerEFTypes.map((container: any, i: any) => baseData && baseData
        .filter((e: any) => container[0] === e.account.CONTAINER || container[1] === e.account.CONTAINER));

    const amountsBySortedMonths = dataByType && dataByType.map((container: any) => container && container.map((e: any) => e.historicalBalances.map((month: any) => {
        return {date: month.date, amount: month.balance.amount, account: e.account}
    }).sort((a: any, b: any) => b.date.localeCompare(a.date))));

    const x = amountsBySortedMonths && amountsBySortedMonths.map((acc: any) => acc && acc.map((type: any) => 
        dates.map((e: any) => {
            const currM = parseInt(e.split(('-'))[1]) - 1;
            const targetObj = type && type.find((x: any) => x && parseInt(x.date.split(('-'))[1]) - 1 === currM);
            if (targetObj) {
                return {date: targetObj.date, amount: targetObj.amount, account: targetObj.account};
            } else {
                return {date: e, amount: 0, account: []};
            }
    })));

    const y = x.map((type: any) => type.map((acc: any) => acc.filter((e: any) => e.date >= currJan.format('YYYY-MM-01') && e.date < (moment().set('date', 1).add(1, 'months')).format('YYYY-MM-01'))));

    const sortedData = toYearly ? y : x;

    const balancesByType = sortedData && sortedData.map((container: any) => container && container.map((e: any) => e.map((x: any) => x.amount)));

    const monthlySumsByType = sortedData && sortedData.map((_: any, i: any) => monthSet.map((_, j) => balancesByType[i] && balancesByType[i].reduce((a: any, c: any) => {
        const val = c[j] ? c[j] : 0;
        return a + val;
    }, 0)));

    const monthlyTotals = monthlySumsByType[0] && monthlySumsByType[0].map((amt: any, i: number) => {
        const val = monthlySumsByType[1] && monthlySumsByType[1][i];
        const sum = amt + (val ? -1 * val : 0);
        return Math.round(sum)
    });
    
    const yearToDateByType = monthlySumsByType && monthlySumsByType.map((container: any) => container && container.reduce((a: number, c: number) => a += c));
    const yearToDateTotal = yearToDateByType && yearToDateByType.reduce((a: number, c: number) => a -= c);

    return {
        chartMonths,
        chartMonthsYrly,
        containerTypes,
        containerEFTypes,
        baseData,
        months,
        monthSet,
        fullMonths,
        sortedData,
        balancesByType,
        monthlySumsByType,
        monthlyTotals,
        yearToDateByType,
        yearToDateTotal,
    };
}