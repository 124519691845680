import React from 'react';
import { Slider, Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../utils/Masking';
import EFTooltip from '../../Atomic/EFTooltip/EFTooltip';

interface IProps {
    style?: object,
    title?: string,
    value?: number,
    thumbColor?: string,
    activeSectionColor?: string,
    min?: number,
    max?: number,
    prefix?: string;
    suffix?: string;
    includeThousandsSeparator?: boolean;
    thousandsSeparatorSymbol?: string;
    allowDecimal?: boolean,
    decimalSymbol?: string,
    decimalLimit?: number,
    allowNegative?: boolean,
    allowLeadingZeroes?: boolean,
    onChange?: (value: number, index?: number) => void,
    displayErrStyle?: boolean,
    componentRef?: any,
    tooltip?: string,
};

const EFSlider: React.FC<IProps> = props => {
    const maskedValue = maskValue(props.value, createNumberMask({ ...props }));
    const sliderStyles = {
        slideBox: {
            padding: 0,
            selectors: {
                '&:hover .ms-Slider-active': {
                    backgroundColor: props.activeSectionColor,
                },
                '&:active .ms-Slider-active': {
                    backgroundColor: props.activeSectionColor,
                },
                '&:hover .ms-Slider-inactive': {
                    backgroundColor: '#e4e4e6',
                },
                '&:active .ms-Slider-inactive': {
                    backgroundColor: '#e4e4e6',
                },
                '&:hover .ms-Slider-thumb': {
                    border: '1px solid #fff',
                },
                '&:active .ms-Slider-thumb': {
                    border: '1px solid #fff',
                },
            },
        },
        thumb: {
            backgroundColor: props.thumbColor,
            border: '1px solid #fff',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.10)',
        },
        activeSection: {
            backgroundColor: props.activeSectionColor,
        },
        inactiveSection: {
            backgroundColor: '#e4e4e6',
            height: 3,
        },
    };

    return (
        <Stack style={props.style}>
            <Stack horizontal horizontalAlign='space-between'>
                <Stack tokens={{ childrenGap: '.5rem' }} verticalAlign="center" horizontal style={{ maxWidth: '25rem', }}>
                    <EFLabel style={{fontWeight: 'normal' as 'normal', color: props.displayErrStyle && '#c4233e'}}>
                        {props.title}
                    </EFLabel>
                    {props.tooltip ? <EFTooltip text={props.tooltip} style={{ marginTop: '.2rem' }} title={props.title} /> : <></>}
                </Stack>
                <EFLabel style={{fontWeight: 'normal' as 'normal', color: props.displayErrStyle && '#c4233e'}}>
                    {maskedValue}
                </EFLabel>
            </Stack>
            <Slider
                value={props.value}
                onChange={props.onChange}
                min={props.min}
                max={props.max}
                showValue={false}
                styles={sliderStyles}
                componentRef={props.componentRef}
            />
        </Stack>
    )
};

EFSlider.defaultProps = {
    min: 0,
    max: 100,
    suffix: '',
    prefix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 2,
    allowNegative: true,
    allowLeadingZeroes: false,
    thumbColor: '#bee1fa',
    activeSectionColor: '#bee1fa',
};

export default EFSlider;
