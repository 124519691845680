import React from 'react';
import classes from './CompletionBar.module.css'

interface Props {
    completionPercentage: number
    showCompletionPercentage?: boolean;
    customStyle?: object;
}

const CompletionBar: React.FC<Props> = props => {
    return (
        <>
            <div className={classes.outerBar} style={{ ...props.customStyle }}>
                <div className={classes.innerBar}
                    style={{ width: props.completionPercentage + '%', ...props.customStyle }}></div>
            </div>
            {props.showCompletionPercentage &&
                <p className={classes.percentageNumber}>{props.completionPercentage} %</p>
            }
        </>
    )
};

export default CompletionBar;