import React from 'react';
import EFButton from '../Atomic/EFButton/EFButton';

interface IProps {
    id?: string,
    style?: object,
    focusStyle?: object,
    offFocusStyle?: object,
    isFocused: boolean,
    text: string,
    onClick: () => void,
    isDisabled?: boolean,
    dataTourId?: string,
};

const NavButton: React.FC<IProps> = props => {
    const navBtn = { 
        marginLeft: 0, 
        height: '3.2rem', 
        fontSize: '1.44rem',
        padding: '0 3rem',
        ...props.focusStyle
    };
    const navBtnOffFocus = { 
        marginLeft: 0, 
        height: '3.2rem', 
        fontSize: '1.44rem', 
        color: '#9ea4b3', 
        backgroundColor: 'white', 
        border: '0.8px solid rgb(222, 227, 241)',
        padding: '0 3rem',
        ...props.offFocusStyle,
    };
    const focusStyle = props.isFocused ? navBtn : navBtnOffFocus;

    return (
        <EFButton
            id={props.id}
            dataTourId={props.dataTourId}
            isDisabled={props.isDisabled} 
            style={{...focusStyle, ...props.style}}
            text={props.text} 
            onClick={props.onClick}
        />
    )
};

export default NavButton;
