/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import IframeResizer from 'iframe-resizer-react';
import EFSpinner from '../Atomic/EFSpinner/EFSpinner';

const StaticContainer = (props) => {
  const [extHTML, setExtHTML] = useState("");

  useEffect(() => {
    fetch(props.url)
      .then((response) => {
        response
          .text()
          .then((r) => {
            setExtHTML(r);
          })
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));
  }, []);

  const style = {
    width: "0px", 
    margin: '0px', 
    minWidth: "100%", 
    border: 'none', 
    overflow: 'hidden', 
    borderRadius: '15px', 
    ...props.customStyle
  }

  return ( extHTML ? <IframeResizer src={props.url} style={style} /> : <EFSpinner /> );
};

export default StaticContainer;
