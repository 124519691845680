import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../../Atomic/EFLabel/EFLabel'
import LifeEvent from '../LifeEvent';
import { familyEvents, workEvents, moneyEvents, protectionEvents, bigStuffEvents, futureEvents } from '../LifeEventsData';

interface IProps {
    openModal: boolean;
    onClickExit: () => void;
    onLifeEventClick: (le: any) => void;
};

const LifeEventsList: React.FC<IProps> = props => {
    const labelStyle = {
        fontSize: '2.2rem', 
        color: '#293861', 
        fontFamily: 'brandon-grotesque', 
        fontWeight: 600, 
        marginBottom: '2.3rem',
    };

    const eventSet = (events: {id: string, title: string, img: string, comingSoon?: boolean}[]) => events.map(
        (event: {id: string, title: string, img: string, comingSoon?: boolean}) =>
        <Stack key={event.id}>
            <LifeEvent
                id={event.id}
                title={event.title}
                img={event.img}
                onClick={() => {props.onLifeEventClick(event)}}
                disableAnimation={event.comingSoon}
                isComingSoon={event.comingSoon}
            />
        </Stack>
    );

    const eventSetContainer = ( eventSet: any, title: string) => {
        return (
            <Stack tokens={{childrenGap: 10}}>
                <EFLabel>{title}</EFLabel>
                <Stack wrap horizontal horizontalAlign='start' tokens={{childrenGap: 20}}>
                    {eventSet}
                </Stack>
            </Stack>
        )
    };

    return (
        <Stack style={{position: 'relative' as 'relative', backgroundColor: '#fff', zIndex: 101, borderRadius: 10, margin: 'auto' as 'auto'}}>
            {props.openModal && (
                <Stack>
                    <Stack onClick={props.onClickExit} style={{margin: 30}} horizontalAlign='end'>
                        <img style={{position: 'absolute', cursor: 'pointer' as 'pointer'}} alt='x' src={require('../../../assets/images/xLeft.png')} />
                        <img style={{position: 'absolute', cursor: 'pointer' as 'pointer'}} alt='x' src={require('../../../assets/images/xRight.png')} />
                    </Stack>
                    <Stack horizontalAlign='start' style={{width: '100%', padding: 60, paddingTop: 30}} tokens={{childrenGap: 40}}>
                        <EFLabel style={labelStyle}>ADD A LIFE EVENT</EFLabel>
                        {eventSetContainer(eventSet(familyEvents), 'FAMILY')}
                        {eventSetContainer(eventSet(workEvents), 'WORK')}
                        {eventSetContainer(eventSet(moneyEvents), 'MONEY')}
                        {eventSetContainer(eventSet(protectionEvents), 'PROTECTION')}
                        {eventSetContainer(eventSet(bigStuffEvents), 'BIG STUFF')}
                        {eventSetContainer(eventSet(futureEvents), 'FUTURE')}
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
};

export default LifeEventsList;
