import React, { useState, useCallback, useEffect } from "react";
import Modal from 'react-modal';
import { appendHeaders } from '../../utils/FetchHeaders';

import { useResponsive } from '../../hooks/useResponsive';
import { useScroll } from '../../hooks/useScroll';
import EFDropdown from '../Atomic/EFDropdown/EFDropdown';
import EFButtonOrange from '../CareerPlanning/EfButtonOrange';
import EfButtonWhite from "../CareerPlanning/EfButtonWhite";
import { IDropdownOption } from 'office-ui-fabric-react';
import Dropzone from "react-dropzone";
import doc from '../../assets/images/protect/doc.svg';
import uploadDocUp from '../../assets/images/protect/uploadDocUp.svg';
import uploadDocPlus from '../../assets/images/protect/uploadDocPlus.svg';
import uploadDocDone from '../../assets/images/protect/uploadDocDone.svg';
import deleteFileImg from '../../assets/images/protect/deleteFile.svg';
import close from '../../assets/images/close.svg';
import classes from './FilesByCategory.module.css';

const filesUrl = process.env.REACT_APP_PROTECT_FILES_ENDPOINT

interface Props {
    folder: { category: string, icon: string, files: any },
    token: string,
    allowUpload: boolean,
    onClose: () => void
}

interface presignedPostData {
    fields: any,
    url: string
}

let fileForSave;

const FilesByCategory: React.FC<Props> = props => {
    const options = [
        { key: 0, text: 'Name A-Z' },
        { key: 1, text: 'Name Z-A' },
        { key: 2, text: 'Most Recent' },
        { key: 3, text: 'Least Recent' },
    ]

    const [error, setError] = useState('');
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [modalAddIsOpen, setModalAddIsOpen] = React.useState(false);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = React.useState(false);
    const [selectedFile, setSelectedFile] = useState(Object());

    const [fileNames, setFileNames] = useState([]);
    const [name, setName] = useState('');
    const [fileTypes, setFileTypes] = useState([]);

    const [files, setFiles] = useState(props.folder.files);
    const [sortKey, setSortKey] = useState(0);

    const sortFiles = (files) => {
        function compareString(a, b) { return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1) }
        let sortedFiles = [];
        switch (sortKey) {
            case 0:
                sortedFiles = files.sort((a, b) => compareString(a, b));
                break;
            case 1:
                sortedFiles = files.sort((a, b) => compareString(b, a));
                break;
            case 2:
                sortedFiles = files.sort((a, b) => {
                    if (a.createdAt && b.createdAt) {
                        if (b.createdAt >= a.createdAt) {
                            return 1
                        } else {
                            return -1
                        }
                    } else {
                        compareString(a, b);
                    }
                });
                break;
            case 3:
                sortedFiles = files.sort((a, b) => {
                    if (a.createdAt && b.createdAt) {
                        if (a.createdAt >= b.createdAt) {
                            return 1
                        } else {
                            return -1
                        }
                    } else {
                        compareString(a, b);
                    }
                });
                break;
            default:
                sortedFiles = files.sort((a, b) => compareString(a, b));
                break;
        }
        return sortedFiles;
    }

    useEffect(() => {
        setFiles(sortFiles(files));
    }, [sortKey])

    async function fetchFiles() {
        console.log('fetch');
        fetch(filesUrl,
            {
                method: 'GET',
                headers: { ...appendHeaders(), Authorization: 'Bearer ' + props.token }
            }
        )
            .then(res => res.json())
            .then((data) => {
                setFiles(data.data.filter(file => file.path === props.folder.category));
                console.log(data);
            })
    }

    const { screenIsAtMost } = useResponsive();
    useScroll(modalIsOpen || modalAddIsOpen || modalDeleteIsOpen);

    function openModal() {
        setModalIsOpen(true);
    }

    function closeModal() {
        setModalIsOpen(false);
    }

    function openModalAdd() {
        setModalAddIsOpen(true);
    }

    function closeModalAdd() {
        setFileNames([]);
        setFileTypes([]);
        setError('');
        setModalAddIsOpen(false);
    }

    function closeModalDelete() {
        setModalDeleteIsOpen(false);
    }

    const handleDrop = useCallback((acceptedFiles) => {
        setFileNames(acceptedFiles.map(file => file.name));
        setFileTypes(acceptedFiles.map(file => file.type));
        fileForSave = acceptedFiles[0];
    }, [])


    const uploadFileToS3 = (presignedPostData, file) => {
        return new Promise<void>((resolve, reject) => {
            const formData = new FormData();
            Object.keys(presignedPostData.fields).forEach(key => {
                formData.append(key, presignedPostData.fields[key]);
            });
            formData.append("file", file);
            const xhr = new XMLHttpRequest();
            xhr.open("POST", presignedPostData.url, true);
            xhr.send(formData);
            xhr.onload = function () {
                this.status === 204 ? resolve() : reject(this.responseText);
                if (this.status === 204) {
                    closeModalAdd();
                } else {
                    setError('An error ocurred, please try again later.')
                }
            };
        });
    };

    const upload = () => {
        if (name === '') {
            setError('Name cannot be empty');
            return;
        }

        fetch(filesUrl,
            {
                method: 'POST',
                headers: { ...appendHeaders(), Authorization: 'Bearer ' + props.token },
                body: `{
                      "filename": "`+ name + '.' + fileForSave.type.split('/')[1] + `",
                      "type": "`+ fileTypes[0] + `",
                      "path": "`+ props.folder.category + `"
                  }`,
            }
        )
            .then(res => res.json())
            .then((data) => {
                if (data.metadata && data.metadata.statusCode === 400) {
                    setError(data.metadata.message);
                } else {
                    const presinged: presignedPostData = Object()
                    presinged.fields = data.data.url.fields;
                    presinged.url = data.data.url.url;
                    uploadFileToS3(presinged, fileForSave);
                    fetchFiles().then(response => {
                        console.log(response);
                    })
                }
            })
            .catch(console.log)
    }

    const deleteFile = (id: string) => {

        fetch(filesUrl + '/' + id, {
            method: 'DELETE',
            headers: { ...appendHeaders(), Authorization: 'Bearer ' + props.token }
        })
            .then(res => res.json())
            .then((data) => {
                if (data.metadata && data.metadata.statusCode === 400) {
                    setError(data.metadata.message);
                } else {
                    closeModalDelete();
                    closeModal();
                    fetchFiles();
                }
            })
            .catch(console.log)
    }

    return (
        <div style={container(screenIsAtMost('tabLand'))}>
            <div className={`${classes.header} ${screenIsAtMost('mobile') ? classes.headerPort : ''}`}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <img src={props.folder.icon}></img>
                    <div style={catTitle}>{props.folder.category}</div>
                </div>
                <div className={classes.headerRight}>
                    <div style={greyText}>Sort by</div>
                    <div>
                        <EFDropdown
                            placeholder={options[0].text}
                            style={{ width: '15rem' }}
                            options={options}
                            onChange={(e: any, item?: IDropdownOption) => {
                                setSortKey(Number(item.key));
                            }}
                        />
                    </div>
                </div>
                <div>
                    <img alt="close" src={close} style={{ cursor: 'pointer', width: '75%' }} onClick={props.onClose}/>
                </div>
            </div>
            <div className={`${classes.filesContainer} ${screenIsAtMost('tabPort') ? classes.filesContainerPort : ''}`}>
                {sortFiles(files).map((item) => (
                    <div key={item.id} onClick={() => {
                        setSelectedFile(item);
                        openModal();
                    }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '2.5rem' }}>
                        <div className={classes.blueContainer}>
                            <div style={fileStyle(doc)}>
                                <div style={fileTypeStyle}>{item.name.split('.').pop().toUpperCase()}</div>
                            </div>
                        </div>
                        <div style={{ ...labelFile, textAlign: 'center', marginTop: '1rem' }}>
                            {item.name}
                        </div>
                    </div>
                ))}
                {props.allowUpload && <div>
                    <div style={{ cursor: 'pointer' }}>
                        <div style={{ width: '100%' }}>
                            <img style={{ width: 60, height: 48 }} src={require('../../assets/images/protect/Add.svg')} onClick={openModalAdd} />
                        </div>
                        <div style={{ ...labelYellow, textAlign: 'center', marginTop: '1rem' }}>
                            ADD
                        </div>
                    </div>
                </div>}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={modalStyle(0)}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={download}>Download File</span>
                    </div>
                    <div style={fileStyle(doc)}>
                        <div style={fileTypeStyle}>{selectedFile.name && selectedFile.name.split('.').pop().toUpperCase()}</div>
                    </div>
                    <div style={fileName}>
                        {selectedFile.name}
                    </div>
                    {selectedFile.createdAt &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span style={createdAt}>{`Created At: ${unixToDate(selectedFile.createdAt)}`}</span>
                        </div>}
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '6rem' }}>
                        <EfButtonWhite size={'1.4rem'} width={'30%'} text={'cancel'} click={closeModal}></EfButtonWhite>

                        <EFButtonOrange size={'1.4rem'} width={'30%'} text={'download'} click={() => {
                            fetch(filesUrl + '/' + selectedFile.id,
                                {
                                    method: 'GET',
                                    headers: { ...appendHeaders(), Authorization: 'Bearer ' + props.token }
                                }
                            )
                                .then(res => res.json())
                                .then((data) => {
                                    window.open(data.data.url.url);
                                    closeModal();
                                })
                                .catch(console.log)
                        }}></EFButtonOrange>
                    </div>
                    <img onClick={() => setModalDeleteIsOpen(true)} src={deleteFileImg} alt='deleteFile' style={deleteFileStyle} />
                </div>
            </Modal>
            <Modal
                isOpen={modalDeleteIsOpen}
                onRequestClose={closeModalDelete}
                style={modalStyle(20)}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5rem', paddingBottom: '2rem' }}>
                    <div style={fileName}>
                        Are you sure you want to delete this file?
                    </div>
                    <div style={fileName}>
                        {selectedFile.name}
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '6rem' }}>
                        <EfButtonWhite size={'1.4rem'} width={'30%'} text={'cancel'} click={() => {
                            closeModal();
                            closeModalDelete();
                        }}></EfButtonWhite>

                        <EFButtonOrange size={'1.4rem'} width={'30%'} text={'delete'} click={() => { deleteFile(selectedFile.id) }}></EFButtonOrange>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={modalAddIsOpen}
                onRequestClose={closeModalAdd}
                style={modalStyle(20)}
            >
                <div>
                    <div style={{ textAlign: 'center', marginTop: '2.5rem', marginBottom: '4.5rem' }}>
                        {fileNames.length > 0 ? fileNames.map(fileName => (
                            <div style={download} key={fileName}>{fileName}</div>
                        ))
                            :
                            <div style={download}>SELECT A FILE</div>}
                    </div>
                    <Dropzone onDrop={handleDrop}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <div {...getRootProps({ className: "dropzone" })} style={{ outline: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <input {...getInputProps()} />
                                    <div style={{
                                        width: '11rem', height: '14rem', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2rem', paddingTop: '3.5rem',
                                        border: isDragActive ? 'solid 2px #84CDED' : (fileNames.length > 0 ? 'none' : 'dashed 2px #84CDED'), borderRadius: '12px',
                                        background: isDragActive ? 'rgba(132, 205, 237, 0.19)' : (fileNames.length > 0 ? '#84CDED' : 'white')
                                    }}>
                                        <img style={{ width: '6rem' }} src={isDragActive ? uploadDocPlus : (fileNames.length > 0 ? uploadDocDone : uploadDocUp)}></img>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1rem', textAlign: 'center', fontFamily: 'brandon-grotesque', color: '#84CDED', fontWeight: 700 }}>
                                            {fileNames.length > 0 ?
                                                <div style={{ color: 'white', marginTop: '3rem' }}>DONE!</div>
                                                :
                                                <>
                                                    <div style={{ marginTop: '2.5rem' }}>CHOOSE A FILE</div>
                                                    <div>OR DRAG IT HERE</div>
                                                </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    <div style={{ width: '80%', display: 'flex', justifyContent: 'left', alignItems: 'left', margin: 'auto' as 'auto', marginTop: 20 }}>
                        <span style={labelGrey}>Give it a name</span>
                    </div>
                    <div style={{ width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'left', margin: 'auto' as 'auto', marginBottom: 20 }}>
                        <input type='text' style={inputStyle} placeholder={'Type a name'} onChange={(e) => setName(e.target.value)}></input>
                        {error && <div style={{ color: 'red', marginTop: '1.5rem', fontSize: '1.3rem' }}>{error}</div>}
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '5rem' }}>
                        <EfButtonWhite size={'1.4rem'} width={'30%'} text={'cancel'} click={closeModalAdd}></EfButtonWhite>

                        <EFButtonOrange size={'1.4rem'} width={'30%'} text={'save'} click={upload}></EFButtonOrange>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default FilesByCategory;

const container = (isAtMostPort: boolean) => {
    return {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3rem',
        margin: isAtMostPort ? '2.5rem 0 0 0' : '2.5rem',
        boxShadow: '0px 2px 11px rgba(0, 0, 0, 0.109512)',
        borderRadius: '13px',
        height: '100%',
        backgroundColor: '#FFFFFF'
    }
}

const labelGrey = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '1.3rem',
    textAlign: 'left' as 'left',
    color: '#9EA4B3',
    fontWeight: 500
}

const inputStyle = {
    width: '100%',
    border: 0,
    outline: 0,
    borderBottom: '1px solid #9EA4B3',
    color: '#343f6e',
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '1.8rem',
}

const labelYellow = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '2rem',
    textAlign: 'center' as 'center',
    color: '#FEB81E',
    display: 'block',
    fontWeight: 600
}

const catTitle = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '3rem',
    letterSpacing: '0.4248px',
    color: '#293861',
    fontWeight: 600,
    marginLeft: '1rem'
}

const greyText = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '2rem',
    color: '#9EA4B3',
    marginRight: '1rem'
}

const labelFile = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '1.4rem',
    textAlign: 'center' as 'center',
    letterspacing: '0.4248px',
    color: '#293861'
}

const download = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '2.2rem',
    textTransform: 'uppercase' as 'uppercase',
    color: '#293861',
    fontWeight: 500,
    marginTop: '5rem',
    marginBottom: '3rem'
}

const fileName = {
    fontFamily: 'brandon-grotesque, sans-serif',
    textAlign: 'center' as 'center',
    fontSize: '1.8rem',
    color: '#293861',
    fontWeight: 700,
    marginTop: '2rem'
}

const createdAt = {
    fontFamily: 'brandon-grotesque, sans-serif',
    textAlign: 'center' as 'center',
    fontSize: '1.6rem',
    color: '#9EA4B3',
    marginTop: '2rem'
}

const deleteFileStyle = {
    width: '26rem',
    cursor: 'pointer',
    marginTop: '4rem'
}

const modalStyle = (paddingBottom: number) => {
    return {
        content: {
            top: '15%',
            right: 'auto',
            left: 'auto',
            bottom: 'auto',
            display: 'block',
            margin: 'auto',
            position: 'relative' as 'relative',
            borderRadius: 10,
            height: 'auto',
            width: '40rem',
            borderStyle: 'none' as 'none',
            backgroundColor: '#fff',
            boxShadow: '2px 2px 10px rgba(0,0,0,0.11)',
            paddingBottom: paddingBottom
        },
        overlay: {
            overflowY: "auto" as "auto",
            background: 'rgba(248, 250, 255, 0.50)',
            backdropFilter: 'blur(40px)',
            WebkitBackdropFilter: 'blur(40px)',
            zIndex: 10
        }
    }
}

const fileStyle = (url: string) => {
    return {
        background: `url(${url})`,
        width: '6rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '8rem',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        cursor: 'pointer'
    }
}

const fileTypeStyle = {
    color: 'white',
    fontSize: '1.4rem',
    fontWeight: 900,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '80%',
    textAlign: 'center' as 'center'
}

const unixToDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const sec = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    const hours = date.getHours();
    return `${month} ${day} ${year} - ${hours}:${min}:${sec}`;
}
