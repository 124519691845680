import React from 'react';
import classes from './AddIncomeModal.module.css';
import EditorModal from '../../../EditorModal/EditorModal';
import EFDropdown from '../../../../Atomic/EFDropdown/EFDropdown';
import EFTextField from '../../../../Atomic/EFTextField/EFTextField';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../../../Atomic/EFLabel/EFLabel';
import EFButton from '../../../../Atomic/EFButton/EFButton';

interface IProps {
    openModal: boolean,
    onClickExit: () => void,
};

const labelStyle = {
    fontWeight: 'normal'
};
const cancelButton = {
    width: '13.7rem', 
    border: '1px solid #7CD4FA', 
    backgroundColor: '#FFF', 
    color: '#7CD4FA'
};
const doneButton = {
    width: '13.7rem', 
    backgroundColor: '#7CD4FA', 
    border: 0
};

const frequencyOptions = [
    {key: '1', text: 'One-Time'},
];

const categoryOptions = [
    { key: '1', text: 'Art' },
    { key: '2', text: 'Boat' },
    { key: '3', text: 'Car' },
    { key: '4', text: 'Cash' },
    { key: '5', text: 'Collectable' },
    { key: '6', text: 'House' },
    { key: '7', text: 'Jewelry' },
    { key: '8', text: 'Motorcycle' },
    { key: '9', text: 'Primary Residence' },
    { key: '10', text: 'Secondary Residence' },
    { key: '11', text: 'Other' },
];

const AddIncomeModal: React.FC<IProps> = props => {

    return (
        <EditorModal
            title='ADD INCOME'
            color='#7CD4FA'
            openModal={props.openModal} 
            onClickExit={props.onClickExit}
        >
            <Stack horizontalAlign='start' tokens={{childrenGap: 20}}>
                <Stack horizontal>
                    <Stack className={classes.input_container} horizontalAlign='start'>
                        <EFLabel style={labelStyle}>Name</EFLabel>
                        <EFTextField
                            placeholder='' 
                            value={''} 
                            onChange={(e) => null}
                        />
                    </Stack>
                    <Stack className={classes.input_container} horizontalAlign='start'>
                        <EFLabel style={labelStyle}>Income Value</EFLabel>
                        <EFTextField
                            placeholder='' 
                            value={''} 
                            onChange={(e) => null}
                        />
                    </Stack>
                </Stack>
                <Stack horizontal>
                    <Stack className={classes.input_container} horizontalAlign='start'>
                        <EFLabel style={labelStyle}>Frequency</EFLabel>
                        <EFDropdown
                            style={{width: '21.5rem'}} 
                            options={frequencyOptions}
                            defaultSelectedKey={''}
                            onChange={() => null}
                        />
                    </Stack>
                    <Stack className={classes.input_container} horizontalAlign='start'>
                        <EFLabel style={labelStyle}>Category</EFLabel>
                        <EFDropdown
                            style={{width: '21.5rem'}} 
                            options={categoryOptions}
                            defaultSelectedKey={''}
                            onChange={() => null}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Stack 
                horizontal 
                horizontalAlign='center' 
                tokens={{childrenGap: 20}}
                style={{marginTop: '8rem'}}
            >
                <EFButton
                    text='CANCEL' 
                    onClick={props.onClickExit}
                    style={cancelButton}
                />
                <EFButton
                    text='DONE' 
                    onClick={() => null}
                    style={doneButton}
                />
            </Stack>
        </EditorModal>
    )
};

export default AddIncomeModal;
