import React from 'react';
import classes from './AddMoreAccsView.module.css';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import dashboardsSubscribe from '../../../assets/images/onboardingCarousel2.svg';
import EFButton from '../../Atomic/EFButton/EFButton';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as aggregationActions from '../../../store/actions/Aggregation';
import * as actions from '../../../store/actions/Users';

interface IProps {
    db?: string,
    onClick?: () => void,
};

const AddMoreAccsView: React.FC<IProps> = props => {
    const dispatch = useDispatch();
    let history = useHistory();

    return (
        <Stack tokens={{ childrenGap: '2rem' }} horizontalAlign='center' verticalAlign='center' className={classes.container}>
            <EFLabel style={{fontSize: '3rem', textAlign: 'center'}}>Get all of your finances in one place</EFLabel>
            <img alt='subscribe' src={dashboardsSubscribe} className={classes.img} />
            <EFLabel style={{fontSize: '2rem', fontWeight: 'normal', textAlign: 'center'}}>
                Oops, we don't have information on your {props.db}
            </EFLabel>
            <EFButton text={`Let's connect it!`} onClick={() => {
                dispatch(aggregationActions.getFastlinkCredentials(history, false));
                dispatch(actions.setRedirect(false));
                history.push('/aggregate');
            }} />
        </Stack>
    )
};

export default AddMoreAccsView;
