import React, { useState } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFStepperInput from '../../UI/EFStepperInput/EFStepperInput';
import CalculatorSetup from '../../UI/CustomModalCard/CustomModalCard';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import Modal from 'react-modal';
import Carousel from '@brainhubeu/react-carousel';
import EFButton from '../../Atomic/EFButton/EFButton';
import CompletedOnboardingCard from '../Common/CompletedOnboardingCard/CompletedOnboardingCard';
import { useResponsive } from '../../../hooks/useResponsive';

import '@brainhubeu/react-carousel/lib/style.css';
import savingsIcon from '../../../assets/images/lifeEvents/money/savings.svg';

interface IProps {
    handleModal?: any,
    setSavingGoal?: (val: number) => void,
    setMonthsToGoal?: (val: number) => void,
    setInterest?: (val: number) => void,
    setCurrentSavings?: (val: number) => void
};

const SavingGoalOnboarding: React.FC<IProps> = props => {
    const { screenIsAtLeast } = useResponsive();
    const title = 'SAVINGS GOAL'
    const defaultSavingsAmount = 2000;
    const defaultMonthsToGoal = 12;
    const defaultInterest = 5;
    const defaultCurrentSavings = 500;

    const [modalIsOpen, setModalIsOpen] = useState(props.handleModal.showModal);
    const [savingGoal, setSavingGoal] = useState(0);
    const [savingErr, setSavingErr] = useState(false);
    const [monthsToGoal, setMonthsToGoal] = useState(0);
    const [monthsToGoalErr, setMonthsToGoalErr] = useState(false);
    const [interest, setInterest] = useState(0);
    const [currentSavings, setCurrentSavings] = useState(0);
    const [currentSavingsErr, setCurrentSavingsErr] = useState(false);
    const [finished, setFinished] = useState(false);

    const onCompleteCard1 = () => {
        let isFinished = true;
        if (savingGoal <= 0) {
            setSavingErr(true);
            isFinished = false;
        }
        if (monthsToGoal <= 0) {
            setMonthsToGoalErr(true);
            isFinished = false;
        }
        setFinished(isFinished);
    }

    const onSkipCard1 = () => {
        setSavingGoal(defaultSavingsAmount);
        setMonthsToGoal(defaultMonthsToGoal);
        setInterest(defaultInterest);
        setCurrentSavings(defaultCurrentSavings);
        setFinished(true);
    }

    const onCompleteSetup = () => {
        props.setSavingGoal(savingGoal);
        props.setMonthsToGoal(monthsToGoal);
        props.setInterest(interest);
        props.setCurrentSavings(currentSavings);
        setModalIsOpen(false);
    }

    const ErrLabel = (msg: string) => (
        <EFLabel style={{ fontSize: '1.3rem', color: '#c4233e', fontWeight: 'normal' }}>{msg}</EFLabel>
    );

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={props.handleModal.exitModal}
            style={modalCardStyle}
        >
            {!finished && (
                <Carousel
                    draggable={false}
                    centered
                    slidesPerPage={screenIsAtLeast('tabPort') ? 1.8 : 1}
                >
                    <div>
                        <CalculatorSetup
                            title={title}
                            eventIcon={savingsIcon}
                            onClickExit={props.handleModal.exitModal}
                        >
                            <Stack horizontalAlign='center' tokens={{ childrenGap: '2rem' }}>
                                <Stack tokens={{ childrenGap: 5 }}>
                                    <EFStepperInput
                                        label='Savings Goal'
                                        onChange={(val: number) => { setSavingGoal(val); setSavingErr(false); }}
                                        value={savingGoal}
                                        prefix='$'
                                        includeThousandsSeparator
                                        dynamicStepEnabled
                                        minVal={0}
                                        maxVal={1000000}
                                        displayErrStyle={savingErr}
                                    />
                                    {savingErr && ErrLabel('Please enter an amount')}
                                </Stack>
                                <Stack tokens={{ childrenGap: 5 }}>
                                    <EFStepperInput
                                        label='Saving Term (Months)'
                                        onChange={(val: number) => { setMonthsToGoal(val); setMonthsToGoalErr(false); }}
                                        value={monthsToGoal}
                                        minVal={1}
                                        maxVal={1000}
                                        displayErrStyle={monthsToGoalErr}
                                    />
                                    {monthsToGoalErr && ErrLabel('Please enter an amount')}
                                </Stack>
                                <EFStepperInput
                                    label='Interest Rate'
                                    onChange={(val: number) => setInterest(val)}
                                    value={interest}
                                    allowDecimal
                                    numStepper={.25}
                                    suffix='%'
                                    minVal={0}
                                    maxVal={100}
                                />
                                <EFStepperInput
                                    label='Current Savings'
                                    onChange={(val: number) => setCurrentSavings(val)}
                                    value={currentSavings}
                                    includeThousandsSeparator
                                    dynamicStepEnabled
                                    prefix='$'
                                    minVal={0}
                                    maxVal={100000}
                                />
                                <Stack style={{ marginTop: '4rem' }} horizontalAlign='center' tokens={{ childrenGap: '1.5rem' }}>
                                    <EFButton style={{ width: '16rem' }} text={`DONE!`} onClick={onCompleteCard1} />
                                    <EFLabel onClick={onSkipCard1} style={{ fontWeight: 'normal', textDecoration: 'underline', cursor: 'pointer' }}>
                                        Figure this out later
                                    </EFLabel>
                                </Stack>
                            </Stack>
                        </CalculatorSetup>
                    </div>
                </Carousel>
            )}
            {finished && <CompletedOnboardingCard title={title} eventIcon={savingsIcon} onClick={onCompleteSetup} onClickExit={props.handleModal.exitModal} />}
        </Modal>
    )
};

const modalCardStyle = {
    content: {
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: '70rem',
        width: '100%',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
        marginTop: '2rem',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)'
    }
}

export default SavingGoalOnboarding;
