/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, createRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-draggable';
import { Stack } from 'office-ui-fabric-react';
import classes from './LineChart.module.css';
import EFLabel from '../../Atomic/EFLabel/EFLabel';

interface Props {
    datasets?: any;
    onDrag?: (e: any) => void,
    drawOnChartAreaY?: boolean,
    maxXTicksLimit?: number,
    maxYTicksLimit?: number,
    xTickFunction?: (value: any, index: number) => any,
    enableBreakEven?: boolean,
    toolTip?: number,
    breakEvenImg?: string,
    bepTextMargin?: number | string,
    initAnn?: number,
};

const LineChart: React.FC<Props> = props => {
  // Sets pixel coordinate of break even point
  const [bepCoord, setBepCoord] = useState<number>(0);
  const [tipCoord, setTipCoord] = useState<number>(0);
  let year = new Date().getFullYear();
  
  // Set up to handle getting break even point
  // (might need to refactor in the futue to use more than 2 data sets!)
  const getBreakEvenPoint = () => {
    if (props.enableBreakEven) {
        const labels = props.datasets.labels;
        const set1 = props.datasets.datasets[0].data;
        const set2 = props.datasets.datasets[1].data;
        
        const zip = labels.map((r: any, i: number) => {
            return [labels[i], set1[i], set2[i]];
        });
        
        const breakEvenSet = zip.filter((r: any, i: number) => {
            let buyToRentDiff = zip[i][1] - zip[i][2];
            let rentToBuyDiff = zip[i][2] - zip[i][1];
            return (zip[i][0] > 0) && ((buyToRentDiff > 0 && buyToRentDiff < 250) || (rentToBuyDiff > 0 && rentToBuyDiff < 250))
        });

        return breakEvenSet[0] ? breakEvenSet[0][0] : null;
    }
    return null;
  };

  // Convert break point to year
  const toYr = Math.ceil(getBreakEvenPoint() / 12);
  const yrStr = toYr === 1 ? ' yr' : ' yr';
  const chartRef = createRef<Line>();

  useEffect(() => {
    if (props.enableBreakEven || props.toolTip) {
        const elements = chartRef.current?.chartInstance.annotation.elements;
        const annotations = Object.keys(elements).map(function (key) { 
            return elements[key]; 
        });
        const getTip = annotations[0]._model.line?.b;
        const getBEP = annotations[1]._model.line?.b;
        if (getBEP) {
            setBepCoord(getBEP);
        };
        if (getTip) {
            setTipCoord(getTip);
        };
    };
  }, [chartRef, props.enableBreakEven, props.toolTip]);

  const bepYes = {
    width: bepCoord + 10,
    marginBottom: 40,
  };
  const bepNo = {
    display: 'none',
  };

  useEffect(() => {
    if (props.toolTip) {
        chartRef.current.chartInstance.annotation.elements['vline'].options.value = props.initAnn;
        chartRef.current.chartInstance.update();
    }
}, [props.initAnn]);

  return (
    <Stack className={classes.container}>
        {props.enableBreakEven && props.breakEvenImg && getBreakEvenPoint() !== null ? (
            <Stack horizontalAlign='end' style={bepCoord ? bepYes : bepNo}>
                <p 
                    className={classes.bep_text} 
                    style={{margin: props.bepTextMargin}}
                >
                    {toYr + yrStr}
                </p>
                <Stack className={classes.bep_img}>
                    <img alt='bep' src={props.breakEvenImg} />
                    <p style={{color: '#9EA4B3', fontSize: 10}}>BREAK EVEN POINT</p>
                </Stack>
            </Stack>
            ) : null
        }
        {props.toolTip ? (
             <Stack 
                horizontalAlign='end' 
                style={{width: tipCoord + 50, marginBottom: 5}}
             >
                 <Stack
                    verticalAlign='center'
                    horizontalAlign='center'
                    style={{width: '10rem', height: '3rem', boxShadow: '0 1px 4px rgba(0, 0, 0, 0.124)', borderRadius: 6}}>
                    <Stack horizontal tokens={{childrenGap: 5}}>
                        <EFLabel>{`Savings at`}</EFLabel>
                        <EFLabel style={{fontWeight: 'normal'}}>{props.toolTip}</EFLabel>
                    </Stack>
                 </Stack>
            </Stack>
            ) : null
        }
        <Line
            ref={chartRef}
            getElementAtEvent={(e: any) => {}}
            data={props.datasets}
            options={{
                plugins: {
                    datalabels: {
                        display: false,
                    }
                },
                layout: {
                    padding: 20,
                },
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                        barPercentage: 0.6,
                        stacked: false,
                        gridLines: {
                            display: true,
                            drawOnChartArea: false,
                            drawTicks: false,
                            margin: 10,
                        },
                        ticks: {
                            fontFamily: 'brandon-grotesque',
                            callback: !props.xTickFunction ? 
                            (_: any, index: number) => {
                                return Math.round(year + index / 12)
                            } : props.xTickFunction,
                            padding: 10,
                            maxTicksLimit: props.maxXTicksLimit,
                            autoSkip: true,
                        },
                    }],
                    yAxes: [{
                        afterDataLimits: function(axis: any) {
                            axis.max += 1; // add 1px to top
                        },
                        fontFamily: 'brandon-grotesque',
                        stacked: false,
                        gridLines: {
                            display: true,
                            drawOnChartArea: props.drawOnChartAreaY || false,
                            drawTicks: false,
                        },
                        ticks: {
                            maxTicksLimit: props.maxYTicksLimit,
                            beginAtZero: false,
                            padding: 10,
                            callback: (value: number) => { //replace with masking f(x)
                                let x = Math.abs(value);
                                if (x < 1000) {
                                    return '$' + value;
                                } else if (x < 1000000) {
                                    return '$' + value / 1000 + 'k';
                                } else {
                                    return '$' + value / 1000000 + 'm';
                                }
                            }
                        }
                    }]
                },
                elements: {
                    point:{
                        radius: 0,
                    }
                },
                annotation: {
                    drawTime: 'afterDraw',
                    events: ['click'],
                    annotations: [
                        {
                            id: 'vline',
                            type: props.onDrag ? 'line' : '',
                            mode: 'vertical',
                            scaleID: 'x-axis-0',
                            value: props.initAnn,
                            backgroundColor: '#FBB81E',
                            borderColor: '#FBB81E',
                            borderWidth: 1,
                            label: {
                                backgroundColor: '#FBB81E',
                                position: 'top',
                                content: ' ',
                                xPadding: 4.8,
                                yPadding: 1,
                                enabled: true
                            },
                            draggable: true,
                            onDrag: props.onDrag,
                        },
                        {
                            type: 'line',
                            mode: 'vertical',
                            scaleID: 'x-axis-0',
                            value: getBreakEvenPoint(),
                            backgroundColor: '#9EA4B3',
                            borderColor: "#9EA4B3",
                            borderWidth: 1,
                            borderDash: [2, 2],
                        },
                    ],
                }
            }}
        />
    </Stack>
  )
}
  
LineChart.defaultProps = {
    initAnn: 0,
    datasets: {
        maxXTicksLimit: 8,
        labels: [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026],
        datasets: [
            {
                label: 'RENT',
                data: [4, 20, 40, 20],
                backgroundColor: '#FF9255',
                borderColor: '#FF9255',
                fill: false,
            },
            {
                label: 'BUY',
                data: [5, 15, 30, 30],
                backgroundColor: '#BAA0FF',
                borderColor: '#BAA0FF',
                fill: false,
            },
        ]
    }
}

export default LineChart;
