import React, { useState } from 'react';

import davidAvatar from '../../../../assets/images/davidAvatar.svg';
import helenAvatar from '../../../../assets/images/helenAvatar.svg';
import owlyAvatar from '../../../../assets/images/owlyAvatar.svg';
import octoAvatar from '../../../../assets/images/octoAvatar.svg';
import maleAvatar from "../../../../assets/images/maleAvatar.svg";

import { useDispatch } from 'react-redux';
import { updateProfile } from '../../../../store/actions/Users';
import { useHistory } from 'react-router-dom';

interface Props {
    avatar: any
}

const AvatarOptions: React.FC<Props> = props => {

    const dispatch = useDispatch();
    let history = useHistory();

    const [selectedAvatar, setSelectedAvatar] = useState(props.avatar);

    const avatars = [{
        id: "3Mxv3XhNNVaQupOzfYBWEN",
        img: davidAvatar,
        name: "David"
    }, {
        id: "4xYF66mqSnuVym8EDvHMRu",
        img: helenAvatar,
        name: "Helen"
    }, {
        id: "6F15h0gu6bK03QdpOaelFo",
        img: owlyAvatar,
        name: 'Owly'
    }, {
        id: "6KYAQizPddYd9XlDrrCmsE",
        img: octoAvatar,
        name: 'Octo'
    }];

    const pickedAvatarStyle = {
        borderColor: '#feb81e',
        borderWidth: '3px',
        borderStyle: 'solid',
        borderRadius: '60px'
    };

    const defaultAvatar = (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            cursor: 'pointer',
            width: '40%',
            marginTop: '1rem',
            marginLeft: '1rem'
        }} onClick={() => {
            setSelectedAvatar({ id: "aaaa", img: maleAvatar, name: "Default" });
            dispatch(updateProfile({ avatar: "aaaa" }, history))
        }}>
            <img src={maleAvatar} alt="default" style={selectedAvatar && Object.keys(selectedAvatar).length > 0 && selectedAvatar?.name === 'Default' ?
                { ...pickedAvatarStyle, width: '100%' } : { width: '100%' }} />
            <p style={{
                fontFamily: "brandon-grotesque",
                fontWeight: 'bold',
                fontSize: '1.5rem',
                lineHeight: '1.8rem',
                textAlign: 'center',
                color: '#293861',
                paddingTop: '1rem'
            }}>Default</p>
        </div>
    );

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '75%', marginLeft: '5rem' }}>
            {avatars.map((avatar) => {
                return (
                    <div key={avatar.id} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        cursor: 'pointer'
                    }} onClick={() => {
                        setSelectedAvatar(avatar);
                        dispatch(updateProfile({ avatar: avatar.id }, history))
                    }}>
                        <img src={avatar?.img} alt={avatar?.name} style={selectedAvatar && Object.keys(selectedAvatar).length > 0 && selectedAvatar?.name === avatar?.name ?
                            { ...pickedAvatarStyle } : null} />
                        <p style={{
                            fontFamily: "brandon-grotesque",
                            fontWeight: 'bold',
                            fontSize: '1.5rem',
                            lineHeight: '1.8rem',
                            textAlign: 'center',
                            color: '#293861',
                            paddingTop: '1rem'
                        }}>{avatar.name}</p>
                    </div>
                );
            })}
        </div>
    )
};

export default AvatarOptions;