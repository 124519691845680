// Temp using tool tip content from here instead of contentful 
// due to last minute request for beta test

export const collegeTT = {
    yrToEnrollment: `Using the student's current age, how many years ahead until the student plans to enroll?`,
    yrAttending: 'Are you attending a 2 year or 4 year college program? You can also plan for 6 years.',
    schoolType: 'You can estimate the future costs of college based on attending a particular school type.',
    tuitionFees: 'Here you will see the estimate for tuition costs today.',
    roomAndBoard: 'Costs here are the average room and board costs for the type of school you picked.',
    collegeSavings: 'Enter any savings you have set aside for college.',
    desiredMonthlyContribution: 'This is the amount you plan to set aside each month for college.',
    grantsAndAID: 'Paying for college can come from many sources. There are many opportunities available to help offset tuition and fees.  We use a national average of current financial aid being used by students today.',
    federalStudentLoan: 'Many people take advantage of Federal Student Loans programs offered by the Government. Choose a total amount of student loan funding you plan to take over during your college years.',
    privateStudentLoan: 'These are loans available through banks and financial institutions that can provide additional funding beyond what Federal Student Loans can cover.',
    monthlySideHustle: 'Many students plan to work during college years to help defray the costs. Fill out the FASFA to see if you qualify for work study.  Enter your estimated monthly earnings here.',
    annualReturnRate: 'We use an annual rate of return on your college savings, set it at what you think you will earn on your accounts. Can be up to 12%.',
    tuitionInflationRate: 'College costs have  been growing higher than other costs, set a rate here to see how inflation can affect future costs. Can be up to 8%.',
    otherCostsInflationRate: 'Can be up to 8%.',
    contributionsGrowWithInflation: 'We will increase your monthly contributions by the annual inflation rate.'
}

export const carTT = {
    vehiclePurchasePrice: 'With either buying or leasing a car, it all starts with the price, enter the purchase price of the vehicle you are considering.',
    downPaymentBuy: 'Using some of your savings as a downpayment for buying a car will lower your monthly payment and help you to qualify easier for a auto loan. If you have a used car now that you can use as a trade-in value, this amount will also reduce your payments.',
    loanTerm: 'What is your desired time period to pay off your loan.',
    interestRate: 'This is an average interest rate being charged on auto loans. You can enter your own rate if you like.',
    downPaymentLease: 'Most car dealers require a small downpayment for the lease; e.g. a month or two worth of lease payments.',
    monthlyPaymentLease: 'Enter here the monthly lease payment advertised by the car dealer. This amount will be used to compare versus buying a car.',
    salesTaxes: 'Buying or leasing a car will generate a state sales tax. Use the tax percentage here or enter you specific sales tax rate you know of. Can be up to 20%.',
    registrationAndOtherFees: 'Each state will charge you fees to register your vehical regardless of buying or leasing.',
    monthlyMaintenance: 'Consider if you need a monthly amount of maintenance costs.',
    investmentRate: 'Can be up to 20%.'
}

export const houseTT = {
    planToLive: 'Have an idea of where you might live? Enter the state and we will customize a few variables for you in the calculator.',
    housePurchasePrice: 'This is the total price for the home before any down payments.',
    loanTerm: 'Slide over to the number of years your mortgage will be. Most standard mortgages run 15 or 30 years but you can enter any timeframe.',
    mortgageRate: 'Enter the rate you anticipate getting on a mortgage. Can be up to 10%.',
    monthlyRent: 'How much monthly rent do you want to pay.',
    rentSecurityDeposit: 'How much monthly rent do you want to pay.',
    propertyTaxes: 'When owning a home, any local or other taxes are paid by you either directly or via your monthly mortgage." We are using a national average for this calculation but feel free to change it if you have your own tax data.',
    incomeTaxRate: 'Even with the recent tax law changes, some of your monthly mortage and taxes are still decuctible.  We use your tax rate here to apply to those deductible items and reflec that savings in your monthly cost calculation vs renting. Please note that recent tax law has limited tax deductibility to certain amounts used here.',
    capitalGainsTaxRate: 'When we compare your long term value of renting vs buying, we will assume you will finally sell your home someday.  Profits from your house sale will be treated a capital gain using the rate here.  Please adjust if you have specific data on your tax situation.',
    homeownerInsurance: 'You will need to have a certain amount of insurance on your new home as part of your mortgage agreement. we have used a national average of $35 for every $100,000 of home value. Or enter a specific amount here.',
    rentersInsurance: `You should have some insurance for your valuable, furnitures etc if you are renting. We use a national average for monthly cost of a typical renter's policy.`,
    homeRepairs: 'When you own a home you should anticipate spending money throughout the year on maintenence and repairs.  Experts use the 1% rule for home maintenance; so we multiple the home value above time 1% and show it here as a monthly cost.',
    homeValueAppreciation: 'A valuable benefit to owning your home is that the real estate can appreciate over time and increase the value of your home as an asset" We use a moderate rate here but feel free to use your own rate based on local history but keep in mind the future may not reflect the same.',
    rentAppreciation: 'All costs go up and so do rents. We use a standard increase but feel free to simulate other situations wherer rents my go or down in the future. Can be up to 10%.',
    generalInflation: 'Taxes, maintenance and other costs will rise with inflation over time and you should anticipate this if you own a home. You may use your own inflation assumption here. Can be up to 10%.',
    rateOfReturn: 'We use this savings rate in the calculation to figure out how much any extra cash will grow if you rent and invest the difference in buying costs per month.',
}

export const retireTT = {
    ageBeginSaving: `It's never too early nor too late to start savings. Enter the age you plan to being saving for retirement.`,
    monthlySalary: 'Here is your monthly salary from your profile, you can always change it here if you have other income.',
    monthlyIncomeNeeded: `We'll use this to figure out how much income you'll need to generate from your retirement savings (we'll take care of inflation so tell us based on today's dollars how much you think you'll need to support your lifestyle). Experts suggest using 80% of your current monthly income for retirement planning.`,
    currentRetirementSavingsBalance: 'Current value of your 401K, IRA, and other Retirement Savings Accounts.',
    addRetireSavings: 'Current value of your other cash and investment accounts you plan to use for retirement.',
    monthlyEstimatedSSBenefit: 'If you have been making contributions via your paycheck to Social Security known as FICA on your paystub, you are eligible to receive montlhy benefits at retirement age. This monthly amount will vary with your: earnings, years of contribution and at what year you begin receiving benefits.  Your actual benefit can be found with the Social Security Administration.  WE USE A MONTHLY AVERAGE OF US WORKERS TODAY IN YOUR RETIREMENT PROJECTION.  Use our estimate bere or simply change your benefit amount based on your own estimate. https://www.ssa.gov/oact/quickcalc/index.html.',
    retireAge: 'The age that you might plan to "re-boot" your life and start to live off your nest egg of savings and investments.',
    monthlyContributions401k: 'The amount you are contributing to your 401k plan at work. See you paystub or 4o1k retirement statement for the amount. Simulate a larger deposit each month to see the impact on your retirement savings. You can contribute up to $1,583 monthly.',
    employerMatch: 'The amount your employer contributes depends on your participation and your monthly contribution. Take advantage of this benefit from your employer.',
    employerMatchUpTo: 'Can match up to 6%.',
    qualifiedPlansIRA: 'The amount you are contributing to IRAs, SEP IRA, or small buisness qualified plans. You can contribute up to $56,000 annually.',
    monthlySideHustle: 'Any monthly amount you plan on earning during your retirement years.',
    annualReturnRate: 'This is the annual return (interest rate or investment return) we assume your money grows. Can be up to 12%.',
    inflationRate: 'This is the rate that your living costs will rise over time. Can be up to 2%.',
    investmentFees: 'Your investment accounts can have annual fees - we assume an average amount here but feel free to raise or lower based on your accounts.',
    taxRateRetirement: 'This is the tax rate we use to tax your earnings during retirement. Can be up to 30%.',
    changeInMonthlyDistribution: 'Can be up to 50%.',
}