import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  GET_TRANSACTIONS,
  GET_ACC_BALANCES,
  GET_TRANSACTIONS_CATEGORIES,
  GET_CASHFLOW_2,
  GET_TWEETS,
  GET_CASHFLOW_1,
} from "./queries";
import { currMonth, elevenMonthsAgo } from "../../utils/dateTools";
import { useSelector } from "react-redux";

const dateYrAgo = elevenMonthsAgo.format("YYYY-MM-01");
const dateCurr = currMonth.format("YYYY-MM-DD");

export function usePersistentApolloState(
  bucket: string,
  variables: any = { fromDate: dateYrAgo, toDate: dateCurr }
) {
  const user = useSelector((state: any) => state.session.user);
  const query = queryMap[bucket];
  const [state, setState] = useState([]);

  const cashFlowContext = { clientName: "cashFlow" };

  const [getQuery, { refetch, loading }] = useLazyQuery(query, {
    context: (bucket === "cashFlowState" || bucket === "cashFlow2State") && cashFlowContext,
    variables: variables,
    onCompleted: (data) => {
      switch (bucket) {
        case "transactionsState":
          setState(data.transactions);
          break;
        case "balancesState":
          setState(data.balances);
          break;
        case "transactionsCategoriesState":
          let temp = [];
          data.transactionCategories.forEach((cat) => {
            let entry = {
              id: cat.id,
              key: cat.category,
              text: cat.category,
              type: cat.type,
            };
            temp.push(entry);
          });
          setState(temp);
          break;
        case "cashFlowState":
          if (Object.keys(variables).find((e) => e === "frequency")) {
            setState(data.cashflowDetail.cashflow);
          } else {
            setState(data.cashflowDetail.lifeEvents);
          }
          break;
        case "cashFlow2State":
            break;
        case "tweetsState":
          setState(data.searchTweets);
          break;
        default:
          break;
      }
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (user.access_token) {
      getQuery();
    }
  }, [getQuery, user.access_token, user.actingAs]);

  return { state, refetch, loading };
}

const queryMap = {
  transactionsState: GET_TRANSACTIONS,
  balancesState: GET_ACC_BALANCES,
  transactionsCategoriesState: GET_TRANSACTIONS_CATEGORIES,
  cashFlowState: GET_CASHFLOW_1,
  cashFlow2State: GET_CASHFLOW_2,
  tweetsState: GET_TWEETS,
};
