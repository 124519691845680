import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import classes from './Tweets.module.css';
import { localeDateConversion } from '../../../utils/dateTools';
import FadeIn from 'react-lazyload-fadein';
import ShareTooltip from '../../UI/ShareTooltip/ShareTooltip';
import { useResponsive } from '../../../hooks/useResponsive';
import { usePersistentApolloState } from '../../../hooks/Apollo/usePersistentApolloState';

interface IProps {
};

const Tweets: React.FC<IProps> = props => {
    const { screenIsAtLeast } = useResponsive();
    const tweetsVars = { query: 'from:YahooFinance+OR+from:CNBC+OR+from:Marketwatch+OR+from:WSJMarkets+OR+from:markets', limit: 100 };
    const { state: tweetsData } = usePersistentApolloState("tweetsState", tweetsVars);

    const tweets = tweetsData.map((tweet: any) => (
        <Stack key={Math.random()} style={screenIsAtLeast('tabPort') ? { paddingLeft: '3.1rem' } : {}} horizontalAlign='start' tokens={{ childrenGap: 20 }}>
            <FadeIn 
                key={tweet.id_str} 
                throttle={200} 
                height={300}
                render={(onload: ((event: React.SyntheticEvent<HTMLElement, Event>) => void) | undefined) => (
                    <Stack 
                        onLoad={onload} 
                        key={tweet.id}
                        style={{ width: screenIsAtLeast('tabPort') && '67rem' }} 
                        className={classes.container} 
                        tokens={{childrenGap: 10}} 
                        onClick={() => {
                            const win = window.open(`https://twitter.com/anyuser/status/${tweet.id_str}`, '_blank');
                            win.focus();
                        }}>
                        <Stack horizontal horizontalAlign='start' verticalAlign='center' tokens={{childrenGap: 10}}>
                            <img className={classes.usrDP} alt='usr-dp' src={tweet.user.profile_image_url} />
                            <Stack tokens={{childrenGap: 2}}>
                                <EFLabel style={{fontSize:'1.8rem'}}>{tweet.user.name}</EFLabel>
                                <EFLabel style={{fontWeight: 'normal', fontSize:'1.8rem', color: '#9EA4B3'}}>{`@${tweet.user.screen_name}`}</EFLabel>
                            </Stack>
                        </Stack>
                        <Stack className={classes.pContainer}>
                            <p className={classes.p}>{tweet.text}</p>
                            {/* {tweet.entities.media && (
                                <img className={classes.media} alt='tweet-img' src={tweet.entities.media.media_url_https} />
                            )} */}
                        </Stack>
                        <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
                            <EFLabel style={{fontWeight: 'normal', fontSize:'1.4rem', color: '#9EA4B3'}}>{localeDateConversion(tweet.created_at)}</EFLabel>
                            <ShareTooltip shareUrl={`https://twitter.com/anyuser/status/${tweet.id_str}`}>
                                <img className={classes.shareIcon} alt='share' src={require('../../../assets/icons/ion_share-outline.svg')} />
                            </ShareTooltip>
                        </Stack>
                    </Stack>
                )}
            />
        </Stack>
    ));

    return tweetsData ? (
        <>
            <EFLabel style={{ fontSize: '2.2rem', fontWeight: 'normal', paddingLeft: '3.1rem' }}>NEWS</EFLabel>
            {tweets}
        </>) : null;
};

export default Tweets;
