import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Label } from 'office-ui-fabric-react';
import CompletionBar from '../Atomic/CompletionBar/CompletionBar';
import Family from './Family/Family';
import EFTextField from '../../components/Atomic/EFTextField/EFTextField';
import EFButton from '../../components/Atomic/EFButton/EFButton';
import maleAvatar from '../../assets/images/maleAvatar.svg';
import settingsFamily from '../../assets/images/settingsFamily.png';
import settings from '../../assets/images/settings.png';
import settingsConnectedAccounts from '../../assets/images/settingsConnectedAccounts.png';
import davidAvatar from '../../assets/images/davidAvatar.svg';
import helenAvatar from '../../assets/images/helenAvatar.svg';
import owlyAvatar from '../../assets/images/owlyAvatar.svg';
import octoAvatar from '../../assets/images/octoAvatar.svg';
import Settings from './Settings/Settings';
import ConnectedAccounts from './ConnectedAccounts/ConnectedAccounts';

import classes from './UserSettings.module.css';

import * as actions from '../../store/actions/Users';

export enum eUserSettings {
    PROFILE,
    FAMILY,
    SETTINGS,
    CONNECTED_ACCOUNTS
}

interface Props {
    hide?: (hide: boolean) => void;
    section?: eUserSettings;
}

const UserSettings: React.FC<Props> = props => {

    const avatarsR = [{
        id: "3Mxv3XhNNVaQupOzfYBWEN",
        image: davidAvatar,
        name: "David"
    }, {
        id: "4xYF66mqSnuVym8EDvHMRu",
        image: helenAvatar,
        name: "Helen"
    }, {
        id: "6F15h0gu6bK03QdpOaelFo",
        image: owlyAvatar,
        name: 'Owly'
    }, {
        id: "6KYAQizPddYd9XlDrrCmsE",
        image: octoAvatar,
        name: 'Octo'
    }];

    const dispatch = useDispatch();
    let history = useHistory();

    const loggedUser = useSelector((state: any) => state.session.user);

    const [currentSection, setCurrentSection] = useState(props.section);
    const [changesToSave, setChangesToSave] = useState(false);
    const [newName, setNewName] = useState(loggedUser.name);
    const [newNameError, setNewNameError] = useState('');
    const [newPhone, setNewPhone] = useState(loggedUser.phone);
    const [newPhoneError, setNewPhoneError] = useState('');
    const [newAge, setNewAge] = useState(loggedUser.age || '');
    const [newAgeError, setNewAgeError] = useState('');
    const [newZipCode, setNewZipCode] = useState(loggedUser.zipCode || '');
    const [newZipCodeError, setNewZipCodeError] = useState('');

    const validateName = (event: any) => {
        setNewNameError('')
        const name = (event.target as HTMLTextAreaElement).value;
        if (name === '') {
            setNewNameError('Your name cannot be empty')
        }
        setNewName(name);
    }

    const validatePhone = (event: any) => {
        setNewPhoneError('');
        const phone = (event.target as HTMLTextAreaElement).value;
        const regex = /\d{11,}/;
        const isValid = regex.test(phone);
        if (!isValid) {
            setNewPhoneError('Your phone number must have at least 11 numbers');
        }
        setNewPhone(phone);
    }

    const validateAge = (event: any) => {
        const value = (event.target as HTMLTextAreaElement).value;
        if (/^[0-9]*$/.test(value) === false) {
            setNewAgeError('Age must be numeric only');
        } else {
            setNewAge(value);
            setChangesToSave(true);
        }
    }

    const validateZipCode = (event: any) => {
        const value = (event.target as HTMLTextAreaElement).value;
        if (/^[0-9]*$/.test(value) === false) {
            setNewZipCodeError('Zip code must be numeric only');
        }
        else {
            setNewZipCode(value);
            if (validateZipCodeLength(event)) {
                setNewZipCodeError('');
            }
        }
    }

    const validateZipCodeLength = (event: any) => {
        const value = (event.target as HTMLTextAreaElement).value;
        if (value.length < 4 || value.length > 5) {
            setNewZipCodeError('Zip code must be valid')
            setChangesToSave(false);
        }
        else {
            setNewZipCode(value);
            setNewZipCodeError('');
            setChangesToSave(true);
        }
        return (value.length === 4 || value.length === 5);
    }

    const onSectionSelectHandler = (selectedSection: eUserSettings) => {
        setCurrentSection(selectedSection);
    };

    /* useEffect(() => {
        const enabled = newName && newPhone && newAge && newZipCode
            && newNameError === '' && newPhoneError === ''
            && newAgeError === '' && newZipCodeError === '' &&
            changesToSave;
        setEnableSave(enabled);
    }, [newName, newPhone, newAge, newZipCode, newNameError,
        newPhoneError, newAgeError, newZipCodeError, changesToSave]) */

    return (
        <>
            <div className={classes.carousel}>
                <div onClick={(event) => {
                    onSectionSelectHandler(eUserSettings.PROFILE);
                    event.stopPropagation()
                }}
                    className={`${classes.carouselCard} ${currentSection === eUserSettings.PROFILE ? classes.selectedCard : null}`}
                    style={{
                        border: currentSection === eUserSettings.PROFILE ? '2px solid #FEB81E' : ''
                    }}>
                    <img className={`${classes.settingsImg} ${currentSection === eUserSettings.PROFILE ? classes.selectedCardImg : null}`} alt="avatar" src={loggedUser.picture ? loggedUser.picture.image : maleAvatar} />
                    <div className={`${classes.title} ${currentSection === eUserSettings.PROFILE ? classes.selectedCardTitle : null}`}>My Info</div>
                </div>
                <div onClick={(event) => {
                    onSectionSelectHandler(eUserSettings.FAMILY)
                    event.stopPropagation()
                }}
                    className={`${classes.carouselCard} ${currentSection === eUserSettings.FAMILY ? classes.selectedCard : null}`}
                    style={{
                        border: currentSection === eUserSettings.FAMILY ? '2px solid #F987BD' : ''
                    }}>
                    <img className={`${classes.settingsImg} ${currentSection === eUserSettings.FAMILY ? classes.selectedCardImg : null}`} alt="settingsFamily" src={settingsFamily} />
                    <div className={`${classes.title} ${currentSection === eUserSettings.FAMILY ? classes.selectedCardTitle : null}`}>
                        Family
                    </div>
                </div>
                <div onClick={(event) => {
                    onSectionSelectHandler(eUserSettings.SETTINGS)
                    event.stopPropagation()
                }}
                    className={`${classes.carouselCard} ${currentSection === eUserSettings.SETTINGS ? classes.selectedCard : null}`}
                    style={{
                        border: currentSection === eUserSettings.SETTINGS ? '2px solid #2CCD79' : ''
                    }}>
                    <img className={`${classes.settingsImg} ${currentSection === eUserSettings.SETTINGS ? classes.selectedCardImg : null}`} alt="settings" src={settings} />
                    <div className={`${classes.title} ${currentSection === eUserSettings.SETTINGS ? classes.selectedCardTitle : null}`}>
                        Settings
                    </div>
                </div>
                <div onClick={(event) => {
                    onSectionSelectHandler(eUserSettings.CONNECTED_ACCOUNTS)
                    event.stopPropagation()
                }}
                    className={`${classes.carouselCard} ${currentSection === eUserSettings.CONNECTED_ACCOUNTS ? classes.selectedCard : null}`}
                    style={{
                        border: currentSection === eUserSettings.CONNECTED_ACCOUNTS ? '2px solid #2695C7' : ''
                    }}>
                    <img className={`${classes.settingsImg} ${currentSection === eUserSettings.CONNECTED_ACCOUNTS ? classes.selectedCardImg : null}`} alt="ConnetedAccounts" src={settingsConnectedAccounts} />
                    <div>
                        <div className={`${classes.title} ${currentSection === eUserSettings.CONNECTED_ACCOUNTS ? classes.selectedCardTitle : null}`}>
                            Connected
                        </div>
                        <div className={`${classes.title} ${currentSection === eUserSettings.CONNECTED_ACCOUNTS ? classes.selectedCardTitle : null}`}>
                            Accounts
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.card}>
                <div>
                    <p className={classes.cardTitle} style={{
                        color: currentSection === eUserSettings.PROFILE ? '#FEB81E' :
                            currentSection === eUserSettings.FAMILY ? '#F987BD' : currentSection === eUserSettings.SETTINGS ? '#2CCD79' : '#2695C7'
                    }}>{currentSection === eUserSettings.PROFILE ? 'My Info' :
                        currentSection === eUserSettings.FAMILY ? 'Family' : currentSection === eUserSettings.SETTINGS ? 'Settings' : 'Connected Accounts'}</p>
                </div>
                {currentSection === eUserSettings.PROFILE &&
                    <div className={classes.profileCard}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}>
                            <CompletionBar
                                completionPercentage={loggedUser.profileCompletionStatus}
                                showCompletionPercentage
                                customStyle={{
                                    height: '1rem',
                                    marginTop: '0rem'
                                }}
                            />
                        </div>
                        <div className={classes.profileEmail}>
                            <div className={classes.profileEmailLabel}>Email</div>
                            <div className={classes.profileEmailText}>{loggedUser.email}</div>
                        </div>
                        <div style={{ marginBottom: '2rem' }} onClick={(event) => {
                            event.stopPropagation();
                        }}>
                            <Label className={classes.fieldLabel}>Name</Label>
                            <EFTextField
                                width='45rem'
                                type='text'
                                placeholder='Your Name'
                                value={newName}
                                onChange={(e) => {
                                    validateName(e)
                                    setChangesToSave(true)
                                }}
                                errorMessage={newNameError}
                            />
                        </div>
                        <div style={{ marginBottom: '2rem' }} onClick={(event) => {
                            event.stopPropagation();
                        }}>
                            <Label className={classes.fieldLabel}>Age</Label>
                            <EFTextField
                                width='45rem'
                                type='text'
                                placeholder='Your age'
                                value={newAge}
                                onChange={(e) => { validateAge(e) }}
                                errorMessage={newAgeError}
                            />
                        </div>
                        <div style={{ marginBottom: '2rem' }} onClick={(event) => {
                            event.stopPropagation();
                        }}>
                            <Label className={classes.fieldLabel}>Phone Number</Label>
                            <EFTextField
                                width='45rem'
                                type='text'
                                placeholder='Phone Number'
                                value={newPhone}
                                onChange={(e) => {
                                    //validatePhone(e)
                                    //setChangesToSave(true)
                                }}
                                errorMessage={newPhoneError}
                            />
                        </div>
                        <div style={{ marginBottom: '2rem' }} onClick={(event) => {
                            event.stopPropagation();
                        }}>
                            <Label className={classes.fieldLabel}>Zip Code</Label>
                            <EFTextField
                                width='45rem'
                                type='text'
                                placeholder='10001'
                                value={newZipCode}
                                onChange={(e) => {
                                    validateZipCode(e)
                                }}
                                errorMessage={newZipCodeError}
                            />
                        </div>
                        <EFButton style={{ width: '30rem', marginTop: '3rem' }} isDisabled={!changesToSave} text="SAVE" onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            dispatch(actions.updateProfile({ name: newName, age: parseInt(newAge), zipCode: newZipCode }, history));
                            setChangesToSave(false);
                        }} />
                    </div>}
                {currentSection === eUserSettings.FAMILY &&
                    <Family />}
                {currentSection === eUserSettings.SETTINGS &&
                    <Settings />}

                {currentSection === eUserSettings.CONNECTED_ACCOUNTS &&
                    <ConnectedAccounts hide={props.hide} />}
            </div>
        </>
    );
};

UserSettings.defaultProps = {
    section: eUserSettings.PROFILE
}

export default UserSettings;
