import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import PreviewSlideShow from '../../PreviewSlideShow/PreviewSlideShow';
import classes from './SignUpModal.module.css';
import EFButton from '../../../Atomic/EFButton/EFButton';
import { useHistory } from "react-router-dom";
import * as actions from '../../../../store/actions/Users';
import { useDispatch } from 'react-redux';

interface IProps {
    onClickExit: () => void;
    onClickAsLoggedUser: () => void;
    isLoggedUser: boolean;
};

const SignUpModal: React.FC<IProps> = props => {
    const dispatch = useDispatch();
    let history = useHistory();

    return (
        <Stack className={classes.container}>
            <Stack className={classes.exitContainer} onClick={props.onClickExit} horizontalAlign='end'>
                <img className={classes.exit} alt='x' src={require('../../../../assets/images/xLeft.png')} />
                <img className={classes.exit} alt='x' src={require('../../../../assets/images/xRight.png')} />
            </Stack>
            <Stack className={classes.slideshow} horizontalAlign='center'> 
                <PreviewSlideShow
                    stackClassNameHeroImg={classes.heroImg}
                    stackClassNameCard={classes.innerContainer} 
                    stackClassNameContent={classes.disableBlur}
                />
                {props.isLoggedUser ? (
                    <EFButton onClick={props.onClickAsLoggedUser} text={`BACK TO CONNECT`} style={{backgroundColor: '#FFF', color: '#FEB81E'}} />
                ) : (
                    <Stack horizontal tokens={{childrenGap: '2rem'}}>
                        <EFButton onClick={() => { dispatch(actions.setRedirect(false)); history.push('/signup') }} text='GET STARTED' style={{backgroundColor: '#FFF', color: '#FEB81E'}} />
                        <EFButton onClick={() => { dispatch(actions.setRedirect(false)); history.push('/signin') }}text='SIGN IN' /> 
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
};

export default SignUpModal;