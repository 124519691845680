import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import maskValue from "../../../utils/Masking";

import incArrow from '../../../assets/images/scoop/deltaArrow.svg';
import decArrow from '../../../assets/images/scoop/deltaDArrow.svg';
import classes from './Delta.module.css';
import EFLabel from '../../Atomic/EFLabel/EFLabel';

interface IProps {
 newVal: number;
 prevVal: number;
};

const Delta: React.FC<IProps> = props => {

    const delta = roundToTwo(props.newVal - props.prevVal);
    const prcntChange = roundToTwo((delta / props.prevVal) * 100);
    const isPositive = props.newVal > props.prevVal;

    return (
        <Stack tokens={{childrenGap: '0.7rem'}} className={classes.container} horizontal>
            <div 
                className={classes.arrow} 
                style={{
                    mask: `url(${isPositive ? incArrow : decArrow}) no-repeat center`, 
                    WebkitMask: `url(${isPositive ? incArrow : decArrow}) no-repeat center`, 
                    backgroundColor: isPositive ? '#30DB20' : '#FF9BB2',
                    maskSize: 'contain'
                }} 
            />
            <EFLabel style={labelStyle}>
                {`${maskValue(Math.abs(Math.round(delta)), dollarMask)}${getDec(delta)}`}
            </EFLabel>   
            <EFLabel style={labelStyle}>
                {`${isPositive ? '+' : '-'}${maskValue(Math.abs(Math.round(prcntChange)), prcntMask)}${getDec(prcntChange)}%`}
            </EFLabel>   
        </Stack>
    )
};

const roundToTwo = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;
const getDec = (num: number) => {
    const decStr = num.toString().split('.')[1];
    return decStr ? `.${decStr}` : '';
};
const labelStyle = { fontSize: '1.4rem', color: '#979797' };
const dollarMask = createNumberMask({
    prefix: "$",
    suffix: "",
});
const prcntMask = createNumberMask({
    prefix: "",
    suffix: "",
});

export default Delta;
