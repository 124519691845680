import { gql } from "apollo-boost";

export const ADD_ACC_SETTINGS = gql`
  mutation addAggregatedAccountsSettings(
    $accountContainer: String!
    $accountType: String!
    $providerId: String!
    $dashboards: [String!]!
  ) {
    addAggregatedAccountsSettings(
      accountContainer: $accountContainer
      accountType: $accountType
      providerId: $providerId
      dashboards: $dashboards
    )
  }
`;

export const UPDATE_USER = gql`
  mutation userUpdate($scoop: ScoopSetupInputDTO) {
    userUpdate(scoop: $scoop)
  }
`;

export const UPDATE_TOUR_GUIDE = gql`
  mutation updateTourGuide($tourGuide: TourGuideInputDTO!) {
    updateTourGuide(tourGuide: $tourGuide)
  }
`;

export const UPDATE_CASHFLOW = gql`
  mutation updateEntry(
    $income: CashflowCategorySetupInputArgs
    $expense: CashflowCategorySetupInputArgs
    $lifeEvent: CashflowLifeEventSetupInputArgs
  ) {
    updateEntry(income: $income, expense: $expense, lifeEvent: $lifeEvent) {
      simulation {
        date
        defaultValue {
          income
          expense
          netCashflow
        }
        updatedValue {
          income
          expense
          netCashflow
        }
      }
    }
  }
`;

export const UPDATE_CASHFLOW_2_5 = gql`
  mutation updateEntryNextGen(
    $id: String!
    $enabled: Boolean
    $onboarded: Boolean
    $spikes: [CashflowSpikeSetupDTO!]
    $subCategories: [SubCategoriesSetupDTO!]
  ) {
    updateEntryNextGen(
      id: $id
      enabled: $enabled
      onboarded: $onboarded
      spikes: $spikes
      subCategories: $subCategories
    ) {
      id
      name
      type
      simulation {
        date
        dateAsString
        defaultValue {
          income
          expense
          netCashflow
        }
        updatedValue {
          income
          expense
          netCashflow
        }
      }
    }
  }
`;

export const SAVE_CASHFLOW = gql`
  mutation saveEntry(
    $income: CashflowCategorySetupInputArgs
    $expense: CashflowCategorySetupInputArgs
    $lifeEvent: CashflowLifeEventSetupInputArgs
  ) {
    saveEntry(income: $income, expense: $expense, lifeEvent: $lifeEvent) {
      simulation {
        date
        defaultValue {
          income
          expense
          netCashflow
        }
        updatedValue {
          income
          expense
          netCashflow
        }
      }
    }
  }
`;

export const SAVE_CASHFLOW_2_5 = gql`
  mutation saveEntryNextGen(
    $id: String!
    $enabled: Boolean
    $onboarded: Boolean
    $spikes: [CashflowSpikeSetupDTO!]
    $subCategories: [SubCategoriesSetupDTO!]
  ) {
    saveEntryNextGen(
      id: $id
      enabled: $enabled
      onboarded: $onboarded
      spikes: $spikes
      subCategories: $subCategories
    ) {
      id
      name
      type
      simulation {
        date
        dateAsString
        defaultValue {
          income
          expense
          netCashflow
        }
        updatedValue {
          income
          expense
          netCashflow
        }
      }
    }
  }
`;

export const RESET_CASHFLOW = gql`
  mutation {
    resetCashflow
  }
`;

export const RESET_CASHFLOW_2_5 = gql`
  mutation {
    resetCashflowNextGen
  }
`;

export const RECATEGORIZE_TXN = gql`
  mutation recategorizeTransaction(
    $transactionId: Float!
    $categoryId: String!
  ) {
    recategorizeTransaction(
      transactionId: $transactionId
      categoryId: $categoryId
    )
  }
`;
