import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ProgressBar from "../../Nudget/EFProgressBar";
import RoundBudget from "../../Nudget/EFRoundBudget";
import Modal from "react-modal";
import { IDropdownOption } from "office-ui-fabric-react";

import Slider from "../EFScaledStepper/CustomFeaturedSlider/CustomFeaturedSlider";
import close from "../../../assets/images/nudget/close.svg";
import addIncome from "../../../assets/images/nudget/addIncome.svg";
import addBudget from "../../../assets/images/nudget/addBudget.svg";
import empty from "../../../assets/images/nudget/emptynudget.svg"
import EFDropdown from "../../Atomic/EFDropdown/EFDropdown";
import EFButtonOrange from "../../CareerPlanning/EfButtonOrange";
import EfButtonWhite from "../../CareerPlanning/EfButtonWhite";
import noBudget from "../../../assets/images/nudget/noBudget.svg";
import { useResponsive } from "../../../hooks/useResponsive";
import { useScroll } from "../../../hooks/useScroll";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../store/actions/Users";
import * as screenActions from "../../../store/actions/Screens";
import { categoryMapping } from "../../../utils/TransactionCategoryMapper";

import classes from "./Nudget.module.css";

interface nudgetSavingGoals {
  categoryLimit: number; //69.44,
  categoryType: string; //"EXPENSE",
  categoryTitle: string; //"Rent",
  categoryId: number; //19
}

interface nudgetArr {
  nudgetSavingGoals: nudgetSavingGoals[];
}

interface Props {
  categoryData: any;
  flag: boolean;
  currentView: string;
  setFocusCategory?: (value: React.SetStateAction<string>) => void;
}

let rootObj: nudgetArr = Object();
rootObj.nudgetSavingGoals = [];
let dateSelected = new Date();

const Nudget: React.FC<Props> = (props) => {
  let history = useHistory();
  const [onCreateError, setOnCreateError] = useState(true);

  const categories = props.categoryData.sort((a, b) => {
    return a.text.localeCompare(b.text);
  });

  const allNudgets = useSelector((state: any) =>
    Object.keys(state.session.user).length > 0
      ? Object.keys(state.session.user.nudgetSavingGoalsInfo).length > 0
        ? state.session.user.nudgetSavingGoalsInfo.nudgetSavingGoals
        : []
      : []
  );

  const expenses = useSelector((state: any) =>
    Object.keys(state.session.user).length > 0
      ? Object.keys(state.session.user.nudgetSavingGoalsInfo).length > 0
        ? state.session.user.nudgetSavingGoalsInfo.nudgetSavingGoals.filter(
            (nudget) => nudget.categoryType === "EXPENSE"
          )
        : []
      : []
  );

  const incomes = useSelector((state: any) =>
    Object.keys(state.session.user).length > 0
      ? Object.keys(state.session.user.nudgetSavingGoalsInfo).length > 0
        ? state.session.user.nudgetSavingGoalsInfo.nudgetSavingGoals.filter(
            (nudget) => nudget.categoryType === "INCOME"
          )
        : []
      : []
  );

  const total = useSelector((state: any) =>
    Object.keys(state.session.user).length > 0
      ? Object.keys(state.session.user.nudgetSavingGoalsInfo).length > 0
        ? state.session.user.nudgetSavingGoalsInfo.budget
        : 0
      : 0
  );

  const currentUser = useSelector((state: any) => state.session.user);

  const setupBar = (
    outOff: number,
    value: number,
    title: string,
    i: number,
    nudgetId: string,
    typeIndex: number,
    income?: boolean
  ) => {
    var out = Math.round(outOff);
    var val = Math.round(value);
    var completed = 0;
    var color = "#00D250";
    var bgColor = "";
    var label = Math.abs(out - val);
    var ovOrLef = "";
    var roundColor = "#00D250";
    if (val >= out) {
      val = out - val;
      bgColor = "#ff5a5a";
      if (income) {
        color = "#7CD4FA";
        roundColor = "#7CD4FA";
        bgColor = "#7CD4FA";
        ovOrLef = "OVER";
      } else {
        color = "#ff8888";
        roundColor = bgColor;
        ovOrLef = "OVER";
      }
      completed = (val * 100) / (out * -1);
      if (val > out) {
        if (income) {
          color = "#7CD4FA";
          roundColor = "#7CD4FA";
          bgColor = "#F1F1F1";
        } else {
          bgColor = "#C80049";
          color = "#C80049";
        }
        completed = 100;
        roundColor = color;
      }
    } else {
      if (income) {
        ovOrLef = "BELOW";
        color = "#ff8888";
        bgColor = "#ff5a5a";
        roundColor = bgColor;
      } else {
        ovOrLef = "LEFT";
      }
      completed = (val * 100) / out;
      if (completed >= 100) {
        if (income) {
          color = "#7CD4FA";
          roundColor = "#7CD4FA";
          bgColor = "#F1F1F1";
        }
      }
      if (completed > 50 && completed < 75) {
        if (income) {
          //color = '#7CD4FA';
          //roundColor = '#7CD4FA';
          //bgColor = '#F1F1F1';
        } else {
          color = "#feb81e";
          roundColor = color;
        }
      } else if (completed >= 75) {
        if (income) {
          //color = '#7CD4FA';
          //roundColor = '#7CD4FA';
          //bgColor = '#F1F1F1';
        } else {
          color = "#ff8888";
          roundColor = color;
        }
      }
      if (income) {
        //color = '#7CD4FA';
        //roundColor = '#7CD4FA';
        //bgColor = '#F1F1F1';
      } else {
        bgColor = "#F1F1F1";
      }
    }
    var date = new Date();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var monthLenght = daysInMonth(month, year);
    var monthCompletion = 0;
    switch (monthLenght) {
      case 30: {
        monthCompletion = Math.round(date.getDate() * 3.3333333);
        break;
      }
      case 31: {
        monthCompletion = Math.round(date.getDate() * 3.2258064516);
        break;
      }
      case 28: {
        monthCompletion = Math.round(date.getDate() * 3.5714285714);
        break;
      }
      case 29: {
        monthCompletion = Math.round(date.getDate() * 3.4482758621);
      }
      // eslint-disable-next-line no-fallthrough
      default: {
        monthCompletion = Math.round(date.getDate() * 3.3333333);
      }
    }
    const stringMonthComp = String(monthCompletion);
    return {
      index: i,
      nudgetId: nudgetId,
      typeIndex: typeIndex,
      total: Math.round(value),
      text: title,
      limit: Math.round(outOff),
      color: color,
      bgColor: bgColor,
      label: label,
      completed: completed,
      ovOrLef: ovOrLef,
      roundColor: roundColor,
      monthCompletion: stringMonthComp,
      isIncome: income,
    };
  };

  const createIncomeNudgets = (incomes: any) => {
    let tempIncomeNudgets = [];
    incomes.forEach((income, i) => {
      const idx = allNudgets.findIndex(
        (nudget) =>
          nudget.categoryLimit === income.categoryLimit &&
          nudget.categoryCurrent === income.categoryCurrent &&
          nudget.categoryTitle === income.categoryTitle
      );

      tempIncomeNudgets.push(
        setupBar(
          income.categoryLimit,
          income.categoryCurrent,
          income.categoryTitle,
          idx,
          income.id,
          i,
          true
        )
      );
    });
    return tempIncomeNudgets;
  };

  const createExpenseNudgets = (expenses: any) => {
    let tempExpenseNudgets = [];
    expenses.forEach((expense, i) => {
      const idx = allNudgets.findIndex(
        (nudget) =>
          nudget.categoryLimit === expense.categoryLimit &&
          nudget.categoryCurrent === expense.categoryCurrent &&
          nudget.categoryTitle === expense.categoryTitle
      );

      tempExpenseNudgets.push(
        setupBar(
          expense.categoryLimit,
          expense.categoryCurrent,
          expense.categoryTitle,
          idx,
          expense.id,
          i,
          false
        )
      );
    });
    return tempExpenseNudgets;
  };

  let expensesNudgets = createExpenseNudgets(expenses);
  let incomeNudgets = createIncomeNudgets(incomes);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [catTitle, setCatTitle] = useState("");
  const [newNudgetVal, setNewNudgetVal] = useState(0);
  const [nudgetForDelete, setNudgetForDelete] = useState(Object());
  const [nudgetForEdit, setNudgetForEdit] = useState(Object());

  const { screenIsAtLeast } = useResponsive();
  const { screenIsAtMost } = useResponsive();

  const user = useSelector((state: any) => state.session.user);

  useScroll(modalIsOpen || modalEditIsOpen || modalDeleteIsOpen);

  const dispatch = useDispatch();

  const months = [
    { key: "01", text: "January" },
    { key: "02", text: "February" },
    { key: "03", text: "March" },
    { key: "04", text: "April" },
    { key: "05", text: "May" },
    { key: "06", text: "June" },
    { key: "07", text: "July" },
    { key: "08", text: "August" },
    { key: "09", text: "September" },
    { key: "10", text: "October" },
    { key: "11", text: "November" },
    { key: "12", text: "December" },
  ];

  const handleOnChange = (val: any) => {
    setNewNudgetVal(parseInt(val));
  };

  const handleOnEdit = (val: any) => {
    const newEditedNudget = { ...nudgetForEdit };
    newEditedNudget.limit = parseInt(val);
    newEditedNudget.label = parseInt(val);
    setNudgetForEdit(newEditedNudget);
  };

  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  const handleModal = (value: boolean) => {
    setModalIsOpen(value);
  };

  const handleDeleteModal = (value: boolean) => {
    setModalDeleteIsOpen(value);
  };

  const handleEditModal = (value: boolean) => {
    setModalEditIsOpen(value);
  };

  const onCUDNudget = (mode: string) => {
    switch (mode) {
      case "CREATE": {
        const id = categories.filter((cat) => cat.text === catTitle)[0].id;
        let nudgetInfo = {
          categoryLimit: newNudgetVal,
          categoryId: parseInt(id),
        };
        dispatch(actions.addNudgetSavingGoal(nudgetInfo, history));
        if (props.currentView === "INCOME") {
          incomeNudgets = createIncomeNudgets(incomes);
        } else {
          expensesNudgets = createExpenseNudgets(expenses);
        }
        setOnCreateError(true);
        setNewNudgetVal(0);
        setCatTitle("");
        handleModal(false);
        break;
      }
      case "UPDATE": {
        let nudgetInfo = {
          categoryLimit: nudgetForEdit.limit,
        };
        dispatch(
          actions.updateNudgetSavingGoal(
            nudgetInfo,
            nudgetForEdit.nudgetId,
            history
          )
        );
        if (nudgetForEdit.isIncome) {
          const nudget = { ...incomeNudgets[nudgetForEdit.typeIndex] };
          nudget.limit = nudgetForEdit.limit;
          nudget.label = nudgetForEdit.label;
          const updatedNudgets = [...incomeNudgets];
          updatedNudgets[nudgetForEdit.typeIndex] = nudget;
          incomeNudgets = createIncomeNudgets(updatedNudgets);
        } else {
          const nudget = { ...expensesNudgets[nudgetForEdit.typeIndex] };
          nudget.limit = nudgetForEdit.limit;
          nudget.label = nudgetForEdit.label;
          const updatedNudgets = [...expensesNudgets];
          updatedNudgets[nudgetForEdit.typeIndex] = nudget;
          expensesNudgets = createExpenseNudgets(updatedNudgets);
        }
        handleEditModal(false);
        break;
      }
      case "DELETE": {
        dispatch(
          actions.deleteNudgetSavingGoal(nudgetForDelete.nudgetId, history)
        );
        if (nudgetForDelete.isIncome) {
          const updatedNudgets = [...incomeNudgets];
          updatedNudgets.splice(nudgetForDelete.typeIndex, 1);
          incomeNudgets = createIncomeNudgets(updatedNudgets);
          expensesNudgets = createExpenseNudgets(updatedNudgets);
        } else {
          const updatedNudgets = [...expensesNudgets];
          updatedNudgets.splice(nudgetForDelete.typeIndex, 1);
        }
        handleDeleteModal(false);
        break;
      }
      default: {
        break;
      }
    }
  };

  var background;
  var progressDiv;
  var leftDiv;
  var container;
  var progressWidth;
  var progressHeight;
  var roundSize;
  var progressRoundContainer;
  var roundText;
  var divRound;
  var viewAll;
  var progressConainer;
  let dropdownContainer;
  let availableMoneyContainer;
  let selectedMonthContainer;

  const modalStyle = {
    content: {
      justifyContent: "center" as "center",
      alignItems: "middle" as "middle",
      display: "block",
      margin: "auto",
      borderRadius: 10,
      width: screenIsAtMost("tabLand") ? "80%" : "55%",
      padding: screenIsAtMost("tabLand") ? 0 : "auto",
      borderStyle: "none" as "none",
      backgroundColor: "#fff",
      boxShadow: "2px 2px 10px rgba(0,0,0,0.11)",
    },
    overlay: {
      overflowY: "auto" as "auto",
      background: "rgba(248, 250, 255, 0.50)",
      backdropFilter: "blur(40px)",
      WebkitBackdropFilter: "blur(40px)",
      zIndex: 10,
    },
  };

  const modalDeleteStyle = {
    content: {
      justifyContent: "center" as "center",
      alignItems: "middle" as "middle",
      display: "block",
      borderRadius: 10,
      width: screenIsAtMost("tabLand") ? "80%" : "55%",
      padding: screenIsAtMost("tabLand") ? 0 : "auto",
      borderStyle: "none" as "none",
      backgroundColor: "#fff",
      boxShadow: "2px 2px 10px rgba(0,0,0,0.11)",
      inset: "auto",
      marginTop: screenIsAtMost("tabLand") ? "50%" : "15%",
      marginLeft: screenIsAtMost("tabLand") ? "10%" : "23%",
    },
    overlay: {
      overflowY: "auto" as "auto",
      background: "rgba(248, 250, 255, 0.50)",
      backdropFilter: "blur(40px)",
      WebkitBackdropFilter: "blur(40px)",
      zIndex: 10,
    },
  };

  const getBackground = () => {
    if (props.flag) {
      background = {
        padding: 80,
        width: "80%",
        marginRight: "0 auto",
        background: "#FFFFFF",
        boxShadow: "0px 2px 11px rgba(0, 0, 0, 0.109512)",
        borderRadius: "13px",
        float: "left" as "left",
        marginLeft: 60,
      };
      leftDiv = {
        float: "left",
        width: "36%",
        height: 25,
        marginLeft: 40,
        marginRight: 40,
        marginBottom: 40,
        marginTop: 20,
      };
      container = {
        width: "90%",
      };
      progressDiv = {
        marginTop: 5,
      };
      progressRoundContainer = {
        width: "70%",
        float: "left",
        marginRight: 20,
      };
      divRound = {};
      progressWidth = "100%";
      progressHeight = 28;
      roundSize = 50;
      roundText = "13px";
      dropdownContainer = {
        padding: 58,
        width: "80%",
        marginRight: "0 auto",
      };
      availableMoneyContainer = {
        float: "left",
        width: "50%",
      };
      selectedMonthContainer = {
        float: "left",
        width: "30%",
        marginRight: 10,
      };
      if (screenIsAtLeast("bigDesktop")) {
        progressConainer = {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 30,
        };
      }

      if (screenIsAtMost("desktop")) {
        progressConainer = {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        };
      }
      if (screenIsAtMost("tabLand")) {
        container = {
          width: "90%",
          display: "flex",
          flexDirection: "column",
        };
        background = {
          width: "100%",
          marginRight: "0 auto",
          background: "#FFFFFF",
          boxShadow: "0px 2px 11px rgba(0, 0, 0, 0.109512)",
          borderRadius: "13px",
          float: "left" as "left",
        };
        dropdownContainer = {
          display: "flex",
          flexDirection: "column",
          width: "95%",
        };
        leftDiv = {
          float: "left",
          width: "80%",
          height: 25,
          marginLeft: 40,
          marginRight: 40,
          marginBottom: 40,
          marginTop: 20,
        };
        availableMoneyContainer = {
          float: "left",
          width: "50%",
          paddingTop: "4rem",
        };
        roundSize = 40;
        selectedMonthContainer = {
          float: "left",
          width: "100%",
          marginRight: 10,
        };
      }
    } else {
      progressWidth = "90%";
      progressHeight = 25;
      roundSize = 40;
      roundText = "8px";
      background = { width: "100%", marginTop: 20 };
      leftDiv = { width: "100%" };
      container = {
        width: "100%",
        heihgt: "100%",
      };
      progressDiv = {
        margin: "10px 10px 10px 0",
      };
      progressRoundContainer = {
        width: "100%",
        marginLeft: 20,
        marginRight: 5,
      };
      divRound = {
        marginRight: 20,
      };
      if (screenIsAtLeast("bigDesktop")) {
        viewAll = {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "5%",
          marginTop: 10,
        };
        progressConainer = {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 30,
        };
      }

      if (screenIsAtMost("desktop")) {
        viewAll = {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 5,
        };
        progressConainer = {
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        };
      }
    }
  };

  const edit = (item, index) => {
    setNudgetForEdit(item);
    handleEditModal(true);
  };

  const eliminate = (item, index) => {
    setNudgetForDelete(item);
    handleDeleteModal(true);
  };

  const navigateToExpenseDashboard = (type: string) => {
    const categoryEF = categoryMapping.filter((cat: any) =>
      cat.detailCategory.find((detail: any) => detail.name === type)
    );
    const categoryEFName = categoryEF ? categoryEF[0].categoryName : "";
    if (!categoryEFName) {
      return;
    }
    props.setFocusCategory(categoryEFName);
    dispatch(screenActions.connectShowScoop(true));
    dispatch(screenActions.connectShowNudget(false));
    history.push("/connect/getorganized/scoopdashboards");
  };

  getBackground();

  // If the current view is on connect page
  // we show one expense budget else placeholder image
  const ConnectView = () =>
    expenses.length < 1 ? (
      <img style={{ width: '100%' }} src={empty} alt="no-budgets" />
    ) : (
      <div style={leftDiv}>
        <div style={progressConainer}>
          <div style={progressRoundContainer}>
            <div className={classes.labelPair}>
              <span className={classes.labelDescription}>{expensesNudgets[0].text}</span>
              <span
                className={`${classes.labelDescription} ${classes.labelDescriptionBold}`}
              >
                {"$" + expensesNudgets[0].limit}
              </span>
            </div>
            <div style={progressDiv}>
              <ProgressBar
                userName={currentUser.name}
                color={expensesNudgets[0].color}
                total={expensesNudgets[0].total}
                bgcolor={expensesNudgets[0].bgColor}
                completed={expensesNudgets[0].completed > 100 ? 100 : expensesNudgets[0].completed}
                text={expensesNudgets[0].text}
                width={progressWidth}
                monthCompletion={expensesNudgets[0].monthCompletion}
                mainPage={props.flag}
                edit={() => edit(expensesNudgets[0], expensesNudgets[0].index)}
                eliminate={() => eliminate(expensesNudgets[0], expensesNudgets[0].index)}
              />
            </div>
          </div>
          <div style={divRound}>
            <RoundBudget
              cursor={"auto"}
              bgcolor={expensesNudgets[0].roundColor}
              textTwo={expensesNudgets[0].ovOrLef}
              text={"$" + expensesNudgets[0].label}
              height={roundSize}
              width={roundSize}
              fontSize={roundText}
            />
          </div>
        </div>
      </div>
    );

  const GeneralViews = () => (
    <div style={container}>
      {props.flag ? (
        <div style={dropdownContainer}>
          <div style={selectedMonthContainer}>
            <span className={classes.labelDescription}>Selected Month</span>
            <div style={{ width: 125 }}>
              <EFDropdown
                placeholder={months[new Date().getMonth().toString()].text}
                options={months}
                style={{ width: 120 }}
                titleStyle={{ backgroundColor: "transparent" }}
                onChange={(e: any, item?: IDropdownOption) => {
                  const selected = item?.key;
                  if (typeof selected == "string") {
                    let date = new Date();
                    date.setMonth(parseFloat(selected) - 1);
                    dateSelected = date;
                    const range =
                      "" +
                      date.getFullYear() +
                      "-" +
                      months[date.getMonth()].key;
                    dispatch(actions.getAllNudgetSavingGoals(range, history));
                  }
                }}
              />
            </div>
          </div>
          {/* Can this block be removed if forever false? - Sudeep */}
          {false && (
            <div style={availableMoneyContainer}>
              <span className={classes.labelDescription}>Available money</span>
              <div style={{ width: 125 }}>
                <input
                  type="text"
                  style={{
                    outline: 0,
                    fontWeight: 600,
                    backgroundColor: "transparent",
                    borderWidth: "0 0 1px",
                    borderColor: "#d5d4cf",
                    width: 118,
                    fontFamily: "brandon-grotesque, sans-serif",
                    fontSize: "20px",
                    lineHeight: "23px",
                    color: total >= 0 ? "green" : "red",
                  }}
                  value={"$" + Math.round(total)}
                  onChange={() => {}}
                />
              </div>
            </div>
          )}
          <div
            style={{
              float: "left",
              width: "100%",
              marginTop: 50,
              marginBottom: 5,
            }}
          >
            <span className={classes.viewTitle}>
              {props.currentView} TO TRACK
            </span>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {expensesNudgets.length > 0 || incomeNudgets.length > 0 ? (
        <div style={background}>
          {props.currentView === "INCOME" && (
            <>
              {incomeNudgets.map((item, idx) => (
                <div key={idx} style={leftDiv}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 30,
                      marginTop: 20,
                    }}
                  >
                    <div style={progressRoundContainer}>
                      <div className={classes.labelPair}>
                        <span className={classes.labelDescription}>
                          {item.text}
                        </span>
                        <span
                          className={`${classes.labelDescription} ${classes.labelDescriptionBold}`}
                        >
                          {"$" + item.limit}
                        </span>
                      </div>
                      <div style={progressDiv}>
                        <ProgressBar
                          userName={currentUser.name}
                          key={idx}
                          color={item.color}
                          total={item.total}
                          bgcolor={item.bgColor}
                          completed={
                            item.completed > 100 ? 100 : item.completed
                          }
                          text={item.text}
                          height={progressHeight}
                          width={progressWidth}
                          monthCompletion={item.monthCompletion}
                          mainPage={props.flag}
                          edit={() => edit(item, item.index)}
                          eliminate={() => eliminate(item, item.index)}
                        />
                      </div>
                    </div>
                    <div style={divRound}>
                      <RoundBudget
                        cursor={"auto"}
                        bgcolor={item.roundColor}
                        textTwo={item.ovOrLef}
                        text={"$" + item.label}
                        height={roundSize}
                        width={roundSize}
                        fontSize={roundText}
                      />
                    </div>
                  </div>
                  {props.flag ?? <hr className={classes.divider} />}
                </div>
              ))}
            </>
          )}
          {props.currentView === "EXPENSES" && (
            <>
              {expensesNudgets.map((item, idx) => (
                <div key={idx} style={leftDiv}>
                  <div style={progressConainer}>
                    <div style={progressRoundContainer}>
                      <div
                        className={classes.labelPair}
                        onClick={() => navigateToExpenseDashboard(item.text)}
                      >
                        <span
                          className={`${classes.labelDescription} ${classes.hyperlink}`}
                        >
                          {item.text}
                        </span>
                        <span
                          className={`${classes.labelDescription} ${classes.labelDescriptionBold}`}
                        >
                          {"$" + item.limit}
                        </span>
                      </div>
                      <div style={progressDiv}>
                        <ProgressBar
                          userName={currentUser.name}
                          key={idx}
                          color={item.color}
                          total={item.total}
                          bgcolor={item.bgColor}
                          completed={
                            item.completed > 100 ? 100 : item.completed
                          }
                          text={item.text}
                          width={progressWidth}
                          monthCompletion={item.monthCompletion}
                          mainPage={props.flag}
                          edit={() => edit(item, item.index)}
                          eliminate={() => eliminate(item, item.index)}
                        />
                      </div>
                    </div>
                    <div style={divRound}>
                      <RoundBudget
                        cursor={"auto"}
                        bgcolor={item.roundColor}
                        textTwo={item.ovOrLef}
                        text={"$" + item.label}
                        height={roundSize}
                        width={roundSize}
                        fontSize={roundText}
                      />
                    </div>
                  </div>
                  {props.flag ?? <hr className={classes.divider} />}
                </div>
              ))}
            </>
          )}
          {props.currentView === "Get Organized" ? (
            expenses.length === 0 ? (
              <div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "100%",
                      marginBottom: 20,
                      marginTop: 50,
                    }}
                  >
                    <img src={noBudget} alt="noBudget" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "100%",
                      marginBottom: 20,
                    }}
                  >
                    <span
                      className={`${classes.labelStyles} ${classes.labelStylesBold}`}
                    >
                      No budgets yet
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "95%",
                      marginBottom: 20,
                    }}
                  >
                    <span className={classes.labelStyles}>
                      Create your budget and track your monthly expenses
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: screenIsAtMost("mobile") ? "auto" : "auto",
                      marginRight: screenIsAtMost("mobile") ? "auto" : "auto",
                      width: screenIsAtMost("mobile") ? "100%" : "40%",
                      marginBottom: screenIsAtMost("mobile") ? 0 : 20,
                      marginTop: screenIsAtMost("mobile") ? 0 : 70,
                    }}
                  >
                    <EFButtonOrange
                      text={"START"}
                      click={() => handleModal(true)}
                      width={"70%"}
                    ></EFButtonOrange>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {expensesNudgets
                  .slice(0, expenses.length >= 5 ? 4 : expenses.length)
                  .map((item, idx) => (
                    <div key={idx} style={leftDiv}>
                      <div style={progressConainer}>
                        <div style={progressRoundContainer}>
                          <div className={classes.labelPair}>
                            <span className={classes.labelDescription}>
                              {item.text}
                            </span>
                            <span
                              className={`${classes.labelDescription} ${classes.labelDescriptionBold}`}
                            >
                              {"$" + item.limit}
                            </span>
                          </div>
                          <div style={progressDiv}>
                            <ProgressBar
                              userName={currentUser.name}
                              key={idx}
                              color={item.color}
                              total={item.total}
                              bgcolor={item.bgColor}
                              completed={
                                item.completed > 100 ? 100 : item.completed
                              }
                              text={item.text}
                              width={progressWidth}
                              monthCompletion={item.monthCompletion}
                              mainPage={props.flag}
                              edit={() => edit(item, item.index)}
                              eliminate={() => eliminate(item, item.index)}
                            />
                          </div>
                        </div>
                        <div style={divRound}>
                          <RoundBudget
                            cursor={"auto"}
                            bgcolor={item.roundColor}
                            textTwo={item.ovOrLef}
                            text={"$" + item.label}
                            height={roundSize}
                            width={roundSize}
                            fontSize={roundText}
                          />
                        </div>
                      </div>
                      {props.flag ?? <hr className={classes.divider} />}
                    </div>
                  ))}
                <div>
                  {!props.flag ? (
                    <div style={viewAll}>
                      <p
                        style={{
                          textAlign: "center",
                          fontFamily: "brandon-grotesque, sans-serif",
                          fontSize: "calc(0.5em + 0.5vw)",
                          lineHeight: "8px",
                          color: "#293861",
                          fontWeight: 500,
                        }}
                      >
                        View All
                      </p>
                    </div>
                  ) : null}
                </div>
              </>
            )
          ) : null}
          <div
            style={{
              width: "100%",
              marginTop: "70px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            {user.actingAs === "ME" && props.flag ? (
              <img
                src={props.currentView === "EXPENSES" ? addBudget : addIncome}
                alt="modal"
                style={{ cursor: "pointer", marginTop: 40 }}
                onClick={() => handleModal(true)}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                marginBottom: 20,
                marginTop: 50,
              }}
            >
              <img src={noBudget} alt="noBudget" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                marginBottom: 20,
              }}
            >
              <span
                className={`${classes.labelStyles} ${classes.labelStylesBold}`}
              >
                No budgets yet
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                width: "95%",
                marginBottom: 20,
              }}
            >
              <span className={classes.labelStyles}>
                Create your budget and track your monthly expenses
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                width: "40%",
                marginBottom: 20,
                marginTop: 70,
              }}
            >
              <EFButtonOrange
                text={"START"}
                click={() => handleModal(true)}
                width={"70%"}
              ></EFButtonOrange>
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => handleModal(false)}
        style={modalStyle}
      >
        <div
          style={{
            width: "100%",
            margin: "4rem 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "28px",
          }}
        >
          <span className={classes.titleStyle}>
            ADD {props.currentView === "EXPENSES" ? "BUDGET" : "INCOME"}
          </span>
        </div>
        <hr className={classes.divider} />

        <img
          src={close}
          alt="close"
          className={classes.rightButton}
          onClick={() => handleModal(false)}
        />
        <div
          style={{
            width: "100%",
            marginTop: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <span className={classes.normalText}>
            Choose Category <span className={classes.errorText}>*</span>
          </span>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <EFDropdown
            placeholder="Pick a Category"
            options={categories.filter((category) => {
              return (
                category.type === props.currentView ||
                category.type === props.currentView.slice(0, -1)
              );
            })}
            onChange={(e: any, item?: IDropdownOption) => {
              const text = item?.text;
              if (typeof text == "string") {
                if (text !== "Pick a Category") {
                  setOnCreateError(false);
                  setCatTitle(text);
                } else {
                  setOnCreateError(true);
                }
              }
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            margin: "5rem 0 10rem 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <span className={classes.normalText}>Budget Limit</span>
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Slider
            tooltip
            style={{ width: "55%", marginBottom: "2rem" }}
            railStyle={{ height: "1.8rem", borderRadius: "1rem" }}
            handleStyle={{ width: "4rem", height: "4rem" }}
            handleInnerStyle={{ width: "2rem", height: "2rem" }}
            handleColor="#FEB81E"
            value={newNudgetVal}
            onChange={handleOnChange}
            min={0}
            max={10000}
          />
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "3rem",
            display: "flex",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <EFButtonOrange
            text={"ADD"}
            click={() => (catTitle ? onCUDNudget("CREATE") : null)}
            width={"30%"}
            isDisabled={onCreateError ? true : false}
          />
        </div>
      </Modal>
      <Modal
        isOpen={modalDeleteIsOpen}
        onRequestClose={() => handleDeleteModal(false)}
        style={modalDeleteStyle}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <span className={classes.editLabel}>Delete Nudget?</span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <span className={classes.toEdit}>{nudgetForDelete.text}</span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: "4rem",
            marginBottom: "4rem",
          }}
        >
          <EfButtonWhite
            width={"40%"}
            text={"cancel"}
            click={() => handleDeleteModal(false)}
          ></EfButtonWhite>

          <EFButtonOrange
            width={"40%"}
            text={"delete"}
            click={() => onCUDNudget("DELETE")}
          ></EFButtonOrange>
        </div>
      </Modal>
      <Modal
        isOpen={modalEditIsOpen}
        onRequestClose={() => handleEditModal(false)}
        style={modalDeleteStyle}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <span className={classes.editLabel}>Edit Nudget</span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 30,
          }}
        >
          <span className={classes.toEdit}>{nudgetForEdit.text}</span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 80,
          }}
        >
          <Slider
            tooltip
            style={{ width: "55%", marginBottom: "2rem" }}
            railStyle={{ height: "1.8rem", borderRadius: "1rem" }}
            handleStyle={{ width: "4rem", height: "4rem" }}
            handleInnerStyle={{ width: "2rem", height: "2rem" }}
            handleColor="#FEB81E"
            value={nudgetForEdit.limit}
            onChange={handleOnEdit}
            min={0}
            max={10000}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: "4rem",
            marginBottom: "4rem",
          }}
        >
          <EfButtonWhite
            width={"40%"}
            text={"cancel"}
            click={() => handleEditModal(false)}
          ></EfButtonWhite>

          <EFButtonOrange
            width={"40%"}
            text={"save"}
            click={() => onCUDNudget("UPDATE")}
          ></EFButtonOrange>
        </div>
      </Modal>
    </div>
  );

  return props.currentView === "Connect" ? <ConnectView /> : <GeneralViews />;
};

export default Nudget;
