import React from 'react';
import { useScroll } from '../../../hooks/useScroll';

import classes from './Backdrop.module.css';

interface IProps {
    onClick?: () => void,
    customClassName?: string,
    customStyle?: object
};

const Backdrop: React.FC<IProps> = props => {

    useScroll();

    return (<div className={`${props.customClassName} ${classes.backdrop}`} onClick={props.onClick} style={{...props.customStyle}}>
        {props.children}
    </div>);
};

export default Backdrop;
