import React, { useState } from 'react';
import { Stack, IDropdownOption } from 'office-ui-fabric-react/lib/';
import CalculatorSetup from '../../UI/CustomModalCard/CustomModalCard';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import Modal from 'react-modal';
import Carousel from '@brainhubeu/react-carousel';
import EFButton from '../../Atomic/EFButton/EFButton';
import EFSlider from '../../UI/EFSlider/EFSlider';
import EFDropdown from '../../Atomic/EFDropdown/EFDropdown';
import CompletedOnboardingCard from '../Common/CompletedOnboardingCard/CompletedOnboardingCard';
import { useResponsive } from '../../../hooks/useResponsive';

import { stateList } from '../../../assets/content/StateList';
import '@brainhubeu/react-carousel/lib/style.css';
import housebuyicon from '../../../assets/images/lifeEvents/bigstuff/buy_home.svg';

interface IProps {
    handleModal?: any,
    setPropertyState?: (val: string) => void,
    onChangeYearsToLive?: (val: number) => void,
};

const HouseOnboarding: React.FC<IProps> = props => {
    const { screenIsAtLeast } = useResponsive();
    const title = 'HOUSE BUY VS RENT';
    const defaultState = "California";
    const defaultLengthYears = 30;

    const [modalIsOpen, setModalIsOpen] = useState(props.handleModal.showModal);
    const [state, setState] = useState(undefined);
    const [stateErr, setStateErr] = useState(false);
    const [lengthYears, setLengthYears] = useState(0);
    const [yearsErr, setYearsErr] = useState(false);
    const [finished, setFinished] = useState(false);
    
    const onCompleteCard1 = () => {
        let isFinished = true;
        if (!state) {
            setStateErr(true);
            isFinished = false;
        }
        if (lengthYears === 0) {
            setYearsErr(true);
            isFinished = false;
        }
        setFinished(isFinished);
    }

    const onSkipCard1 = () => {
        setState(defaultState);
        setLengthYears(defaultLengthYears);
        setFinished(true);
    }

    const onCompleteSetup = () => {
        props.setPropertyState(state);
        props.onChangeYearsToLive(lengthYears);
        setModalIsOpen(false);
    }

    const ErrLabel = (msg: string) => (
        <EFLabel style={{fontSize: '1.3rem', color: '#c4233e', fontWeight: 'normal'}}>{msg}</EFLabel>
    ); 

    return (
        <Modal 
            isOpen={modalIsOpen}
            onRequestClose={props.handleModal.exitModal}
            style={modalCardStyle}
        >
            {!finished && (
                <Carousel 
                    draggable={false}
                    centered
                    slidesPerPage={screenIsAtLeast('tabPort') ? 1.8 : 1}
                >
                    <div>
                        <CalculatorSetup 
                            title={title} 
                            eventIcon={housebuyicon} 
                            onClickExit={props.handleModal.exitModal}
                        >
                            <Stack horizontalAlign='center' tokens={{childrenGap: '2rem'}}>
                                <Stack tokens={{childrenGap: 5}}>
                                    <Stack>
                                        <EFLabel style={{fontWeight: 'normal' as 'normal', color: stateErr && '#c4233e'}}>
                                            Where do you plan to live?
                                        </EFLabel>
                                        <EFDropdown
                                            placeholder=''
                                            options={stateList} 
                                            onChange={(e: any, item?: IDropdownOption) => { 
                                                const x = item?.key.toString() || '';
                                                setState(stateList.find((s: any) => s.key === x).text);
                                                setStateErr(false);
                                            }}
                                            style={{width: '30rem'}}
                                            displayErrStyle={stateErr}
                                        />
                                    </Stack>
                                    {stateErr && ErrLabel('Please choose a state')}
                                </Stack>
                                <Stack tokens={{childrenGap: 5}}>
                                    <EFSlider 
                                        title='How long do you plan to live there? (Years) '
                                        onChange={(val: number) => { setLengthYears(val); setYearsErr(false) }}
                                        value={lengthYears}
                                        min={1}
                                        max={30}
                                        displayErrStyle={yearsErr}
                                        style={{width: '29rem'}}
                                    />
                                    {yearsErr && ErrLabel('Please choose a valid amount')}
                                </Stack>                                 
                                <Stack style={{marginTop: '17rem'}} horizontalAlign='center' tokens={{childrenGap: '1.5rem'}}>
                                    <EFButton style={{width: '16rem'}} text={`DONE!`} onClick={onCompleteCard1} />
                                    <EFLabel onClick={onSkipCard1} style={{fontWeight: 'normal', textDecoration: 'underline', cursor: 'pointer'}}>
                                        Figure this out later
                                    </EFLabel>
                                </Stack>
                            </Stack>
                        </CalculatorSetup>
                    </div>
                </Carousel>
            )}
            {finished && <CompletedOnboardingCard title={title} eventIcon={housebuyicon} onClick={onCompleteSetup} onClickExit={props.handleModal.exitModal} />}
        </Modal>
    )
};

const modalCardStyle = {
    content: {
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: '70rem',
        width: '100%',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
        marginTop: '2rem',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)'
    }
}

export default HouseOnboarding;
