import { gql } from 'apollo-boost';

export const GET_ACC_BALANCES = gql`
    query getAccountBalances(
        $fromDate: String, 
        $toDate: String
    ) {
        balances(
            fromDate: $fromDate, 
            toDate: $toDate
        ) {
            account {
                id
                dashboards
                CONTAINER
                accountName
                providerName
                accountType
            }
            historicalBalances {
                date
                balance {
                    amount
                }
            }
        }
    }
`;

export const GET_TRANSACTIONS = gql`
    query getTransactions(
        $fromDate: String,
        $toDate: String
    ){
        transactions(
            fromDate: $fromDate,
            toDate: $toDate,
        ){
            id
            dashboards
            categoryType
            category
            date
            description {
                original
            }
            efCategory {
                categoryId
            }
            amount {
                amount
            }
            customCategory {
                id
                name
                subCategories {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_TRANSACTIONS_CATEGORIES = gql`
    {
        transactionCategories {
            id
            category
            type
            highLevelCategoryName
            highLevelCategoryId
        }
    }
`;

export const GET_TWEETS = gql`
    query searchTweets(
        $query: String!, 
        $limit: Float, 
        $lastId: Float
    ) {
        searchTweets(
            query: $query, 
            limit: $limit, 
            lastId: $lastId
        ) {
            id_str
            user {
                profile_image_url
                name
                screen_name
            }
            text
            entities {
                media {
                    media_url
                }
            }
            created_at
            retweeted
        }
    }
`;

export const GET_CASHFLOW_1 = gql`
    query cashflowDetail(
        $frequency: String,
    ) {
        cashflowDetail(
            frequency: $frequency,
        ) {
            cashflow {
                date
                balance
                income
                expenses
                lifeEvents {
                  userLifeEventId
                  name
                  date
                  startDate
                  income
                  expenses
                }
             }
             lifeEvents {
              id
              externalId
              date
              startDate
              name
              data {
                term
                monthlyContribution
                monthlyCost
                monthlyPayment
                shortfall
                surplus
                interestsRate
                paidOfByYear
                yearsToEnrollment
              }
            }
        }
    }
`;

export const GET_CASHFLOW_2 = gql`
    query cashflow(
        $income: [CashflowCategorySetupInputArgs!]!,
        $expenses: [CashflowCategorySetupInputArgs!]!,
        $lifeEvents: [CashflowLifeEventSetupInputArgs!]!,
    ) {
        cashflow(
            income: $income,
            expenses: $expenses,
            lifeEvents: $lifeEvents,
        ) {
            global {
                date
                defaultValue {
                    income
                    expense
                    netCashflow
                }
                updatedValue {
                    income
                    expense
                    netCashflow
                }
            }
        }
    }
`;

export const GET_TIMESERIES = gql`
    query timeserie(
        $income: CashflowCategorySetupInputArgs,
        $expense: CashflowCategorySetupInputArgs,
        $lifeEvent: CashflowLifeEventSetupInputArgs,
    ) {
        timeserie(
            income: $income,
            expense: $expense,
            lifeEvent: $lifeEvent,
        ) {
            id
        }
    }
`

export const GET_CF_SETUP_2_5 = gql`
    {
        userCashflowNextGenSetup {
            id
            name
            type
            enabled
            assets
            color
            onboarded
            spikes {
                amount
                startDate
                endDate
                description
                }
            subCategories {
            id
            category
            amount
            savedDelta
            simuAmount
            aggregatedAmount
            categoryId
            detailCategoryId
            highLevelCategoryId
            enabled
            parent {
                highLevelCategoryId
                name
                assets
            }
            values {
                amount
                source
                type
                name
                startDate
                endDate
            }
            source
            }
            aggregatedAmount
            aggregatedAveAmount
            income
        }
    }
`

export const GET_CATEGORIES = gql`
    {
        customCategoriesV2 {
            id
            name
            assets
            color
            backColor
            subCategories {
                id
                name
            }
        }
    }
`
