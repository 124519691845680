import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import classes from './Insight.module.css';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../utils/Masking';

interface IProps {
    currIncome: number,
    currExpenses: number,
    currNet: number,
    currSimuNet?: number,
    isMonthly?: boolean,
    presentAge?: number,
    currAge?: number,
};

const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowLeadingZeroes: false,
    decimalLimit: 2,
    allowNegative: false,
});

const Insight: React.FC<IProps> = props => {
    const timeTitle = props.isMonthly ? 'Monthly ' : 'Yearly ';
    
    return (
        <Stack horizontal horizontalAlign='start' verticalAlign='end' className={classes.totalView} tokens={{childrenGap: '2.7rem'}}>
            <Stack tokens={{childrenGap: '.5rem'}}>
                <EFLabel style={{color: '#FEB81E', fontSize: '2.4rem', textAlign: 'left'}}>
                    {(Math.round(props.currNet) >= 0 || !Math.round(props.currNet) ? '' : '-') + maskValue(Math.round(Math.abs(props.currNet)), numberMask)}
                </EFLabel>
                <Stack verticalAlign='center' horizontalAlign='start' horizontal tokens={{childrenGap: '.5rem'}}>
                    <img style={{height: '.6rem', width: '.6rem'}} alt='bullet' src={require('../../../assets/images/orgDot.svg')} />
                    <EFLabel style={{fontSize: '1.1rem', fontWeight: 'normal', textAlign: 'left'}}>{timeTitle + 'Net Cash Flow'}</EFLabel>
                </Stack>
            </Stack>
            <Stack tokens={{childrenGap: '.5rem'}}>
                <EFLabel style={{color: '#F3798D', fontSize: '1.6rem', textAlign: 'left'}}>{maskValue(Math.round(props.currExpenses), numberMask)}</EFLabel>
                <Stack verticalAlign='center' horizontalAlign='start' horizontal tokens={{childrenGap: '.5rem'}}>
                    <img style={{height: '.6rem', width: '.6rem'}} alt='bullet' src={require('../../../assets/images/redDot.svg')} />
                    <EFLabel style={{fontSize: '1.1rem', fontWeight: 'normal', textAlign: 'left'}}>{timeTitle + 'Expenses'}</EFLabel>
                </Stack>
            </Stack>
            <Stack tokens={{childrenGap: '.5rem'}}>
                <EFLabel style={{color: '#30DB20', fontSize: '1.6rem', textAlign: 'left'}}>{maskValue(Math.round(props.currIncome), numberMask)}</EFLabel>
                <Stack verticalAlign='center' horizontalAlign='start' horizontal tokens={{childrenGap: '.5rem'}}>
                    <img style={{height: '.6rem', width: '.6rem'}} alt='bullet' src={require('../../../assets/images/greenDot.svg')} />
                    <EFLabel style={{fontSize: '1.1rem', fontWeight: 'normal', textAlign: 'left'}}>{timeTitle + 'Income'}</EFLabel>
                </Stack>
            </Stack>
            {/* {props.currSimuNet && props.currSimuNet !== props.currNet && 
                <Stack tokens={{childrenGap: '.5rem'}}>
                    <EFLabel style={{color: '#9EA4B3', fontSize: '1.6rem', textAlign: 'left'}}>
                        {(Math.round(props.currSimuNet) >= 0 ? '' : '-') + maskValue(Math.round(Math.abs(props.currSimuNet)), numberMask)}
                    </EFLabel>
                    <Stack verticalAlign='center' horizontalAlign='start' horizontal tokens={{childrenGap: '.5rem'}}>
                        <img style={{height: '.6rem', width: '.6rem'}} alt='bullet' src={require('../../../assets/images/grayDot.svg')} />
                        <EFLabel style={{fontSize: '1.1rem', fontWeight: 'normal', textAlign: 'left'}}>{timeTitle + 'Net Cash Flow Before'}</EFLabel>
                    </Stack>
                </Stack>} */}
            {props.presentAge && (
                <Stack tokens={{childrenGap: '.5rem'}}>
                    <EFLabel style={{color: '#293861', fontSize: '1.6rem', textAlign: 'left'}}>{props.currAge}</EFLabel>
                    <Stack verticalAlign='center' horizontalAlign='start' horizontal tokens={{childrenGap: '.5rem'}}>
                        <EFLabel style={{fontSize: '1.1rem', fontWeight: 'normal', textAlign: 'left'}}>{'Age'}</EFLabel>
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
};

export default Insight;
