import React from 'react';
import adultFamily from '../../../../assets/images/adultFamily.svg';
import childFamily from '../../../../assets/images/childFamily.svg';
import chevronRight from '../../../../assets/images/chevronRight.svg';

import animations from '../../../../utils/animations.module.css';
import classes from './FamilyMember.module.css';

type Relationship = 'Child' | 'Partner';

interface Props {
    dependent: any;
    handleClick: () => void;
}

const renderMemberImg = (relationship: Relationship) => {
    switch(relationship) {
        case 'Partner':
            return adultFamily;
        case 'Child':
            return childFamily;
        default:
            return adultFamily;
    }
}

const FamilyMember: React.FC<Props> = props => {
    return (
        <div onClick={props.handleClick} className={`${classes.familyCard} ${animations.cardShadowExpand}`}>
            <img className={classes.familyCardIcon} src={renderMemberImg(props.dependent.relationship)} alt="Icon" />
            <div className={classes.familyCardText}>
                <div className={classes.familyCardName}>{props.dependent.name}</div>
                <div className={classes.familyCardAttributes}>
                    <div>RELATIONSHIP</div>
                    <div>AGE</div>
                </div>
                <div className={classes.familyCardAttributesValues}>
                    <div>{props.dependent.relationship}</div>
                    <div>{props.dependent.age}</div>
                </div>
            </div>
            <img className={classes.familyCardView} src={chevronRight} alt="View" />
        </div>
    )
};

export default FamilyMember;
