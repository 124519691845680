import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../utils/Masking';

import classes from './ScoopDashboardWidget.module.css';
import animations from '../../../utils/animations.module.css';

interface IProps {
    onClick?: (e?) => void,
    dashboardData:  { id: string, title: string, value: number, img: string, checked: boolean },
    isSelectView?: boolean,
};

const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol:',',
    allowDecimal: false,
    decimalSymbol: '.',
    decimalLimit: 2,
    allowNegative: true,
    allowLeadingZeroes: false,
});

const ScoopDashboardWidget: React.FC<IProps> = props => {
    return (
        <Stack 
            key={props.dashboardData.id} 
            onClick={props.onClick} 
            className={`${classes.container} ${!props.isSelectView && animations.widgetExpand}`}
        >
            <Stack horizontalAlign='center' verticalAlign='center' className={classes.img}>
                {props.isSelectView && <Stack horizontalAlign='end' className={classes.marker}>
                    <div className={`${classes.imgCircle} ${props.dashboardData.checked && classes.checked}`}>
                        {props.dashboardData.checked ? (
                            <img className={classes.checker} alt='checker' src={require('../../../assets/images/scoop/checkmark.svg')} />
                        ) : null}
                    </div>
                </Stack>}
                <img className={classes.icon} alt='dashboard' src={props.dashboardData.img} />
            </Stack>
            <Stack horizontalAlign='center' verticalAlign='center' className={classes.labels}>
                <EFLabel style={{fontWeight: 'normal', fontSize: '1.2rem'}}>{props.dashboardData.title}</EFLabel>
                <EFLabel style={{color: '#9398A5', fontSize: '1.1rem'}}>
                    {!props.dashboardData.value ? '$0' : maskValue(Math.round(props.dashboardData.value), numberMask)}
                </EFLabel>
            </Stack>
        </Stack>
    )
};

export default ScoopDashboardWidget;
