import React from 'react';
import EFToggle from '../../../../components/Atomic/EFToggle';

import classesParent from '../SettingsOptions/SettingsOptions.module.css';
import classes from './PrivacyOptions.module.css'; 

const NotificationsOptions: React.FC<{}> = props => {
    return (
        <div>
            <div className={classes.card}>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>Push Notifications</div>
                    <EFToggle isChecked={false} onClick={() => {
                        alert("Coming Soon!");
                    }} />
                </div>
            </div>
            <div className={classes.card}>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>Emails</div>
                    <EFToggle isChecked={false} onClick={() => {
                        alert("Coming Soon!");
                    }} />
                </div>
            </div>
            <div className={classes.card}>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>SMS</div>
                    <EFToggle isChecked={false} onClick={() => {
                        alert("Coming Soon!");
                    }} />
                </div>
            </div>
        </div>
    )
};

export default NotificationsOptions;
