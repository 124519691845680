import React, {useState, FunctionComponent, Children} from "react";
import classes from './EFCollapsible.module.css';

interface IProps {
    title?: string;
    childComp?: React.ReactNode;
    image?: string;
};
const Collapsible: FunctionComponent<IProps> = props => {
    const [open, setOpen] = useState(false);
    const togglePanel = (e) =>{
        setOpen(!open)
    }
    return (<div>
    <div onClick={(e)=>togglePanel(e)} className={classes.header}>
        <div className={classes.headerContent}>
            {props.image ? <div className={classes.imgContainer}>
                <img src={require('../../../assets/images/protect/lifeInsurance/' + props.image +'.png')}></img>
            </div>:<></>}
            {props.title}
        </div>
    </div>
    {open ? (
    <div className={classes.content}>
        {props.childComp}
    </div>
    ) : null}
    </div>);
    }
    
    
export default Collapsible