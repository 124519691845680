import React from 'react'
import classes from './LEInsights.module.css'
import LECard from './LECard';
import { Stack } from 'office-ui-fabric-react';

interface IProps {
    cashCard?: any,
    lifeEvents: any[],
    onClick?: (subId: string, value: number, valObj?: any) => void,
}

const LEInsights: React.FC<IProps> = props => {
    return (
        <Stack tokens={{ childrenGap: '1rem' }} className={classes.lehints}>
            {props.lifeEvents?.map((le, i) => (
                <div key={i}>
                    <LECard
                        key={le.id} 
                        cashCard={props.cashCard} 
                        le={le} 
                        onClick={props.onClick}
                    />
                </div>
            ))}
        </Stack>
    )
}

export default LEInsights;
