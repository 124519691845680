import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Rating from 'react-rating';
import Icon from '../../assets/images/career/svg/icon.svg';
import StarFull from '../../assets/images/career/svg/starFull.svg';
import StarEmpty from '../../assets/images/career/svg/starEmpty.svg';
import EfButtonWhite from './EfButtonWhite';
import EfButtonOrange from './EfButtonOrange';
import Done from '../../assets/images/career/done.png';
import classes from './InterestsCarousel.module.css';
import stars from '../../assets/images/career/Stars.svg';
import close from '../../assets/images/close.svg';
import { Line } from 'rc-progress';
import { appendHeaders } from '../../utils/FetchHeaders';

import * as actions from '../../store/actions/CareerPlanning';
import { useScroll } from "../../hooks/useScroll";

interface QuestionAnswer {
  QuestionId: string;
  Rate: number
}

interface Props {
  onEnd: (data: any) => void;
  closeModal?: () => void;
}
let rootObj: QuestionAnswer[] = [];

const InterestsCarousel: React.FC<Props> = props => {
  useScroll();
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);

  const data = useSelector((state: any) => state.career.data);
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState(Array(31));
  const [labelText, setLabelText] = useState("Let's help you find your interests");
  const [rateValue, setRateValue] = useState([]);
  const [carouselValue, setCarouselValue] = useState(0);
  var rated = false;


  const finishQuestions = (rate: number, questions: any[], i: number, isMobile: boolean) => {
    const r: QuestionAnswer = Object();
    r.QuestionId = questions[i].Id;
    switch (rate) {
      case 1: {
        r.Rate = questions[i].Values[0].Value;
        rateValue[i] = r.Rate;
        break;
      }
      case 2: {
        r.Rate = questions[i].Values[1].Value;
        rateValue[i] = r.Rate;
        break;
      }
      case 3: {
        r.Rate = questions[i].Values[2].Value;
        rateValue[i] = r.Rate;
        break;
      }
      case 4: {
        r.Rate = questions[i].Values[3].Value;
        rateValue[i] = r.Rate;
        break;
      }
      case 5: {
        r.Rate = questions[i].Values[4].Value;
        rateValue[i] = r.Rate;
        break;
      }
      default: {
        break;
      }

    }

    rootObj[i] = r;
    rated = true;

    if (isMobile) {
      setTimeout(() => {
        if (carouselValue < 29) {
          setCarouselValue(carouselValue + 1);
        }
      }, 200);

    }
  }

  useEffect(() => {
    dispatch(actions.getQuestions());
  }, []);

  useEffect(() => {
    if (data !== null && data.length > 0) {
      setQuestions(data);
      rootObj = new Array(data.length);
    }
  }, [data])

  const next = (i) => {
    if (rated || rateValue[i]) {
      if (carouselValue < 30) {
        setCarouselValue(carouselValue + 1);
      }
      if (i === 29) {
        setFinished(true);
      }
    }
  }

  const prev = () => {
    if (carouselValue > 0) {
      setCarouselValue(carouselValue - 1);
      rated = false;
    }
  }

  const requestData = (answers) => ({
    method: 'POST',
    headers: appendHeaders(),
    body: answers
  })

  const fetchInterests = (answers, occupations) => {
    fetch(process.env.REACT_APP_CARRER_PLANNING_RESULTS_ENDPOINT, requestData(answers))
    .then(res => res.json())
    .then(data => {
      let interests = {};
      data.Result.forEach(category => {
        const maxValue = 32;
        interests[category.Area] = {score: (category.Score / maxValue) * 100, description: category.Description}
      })
      const result = {occupations, interests};
      props.onEnd(result);
    })
    .catch(error => {
      console.error(error);
      props.onEnd({occupations})
    })
  }

  const sendData = () => {
    const answers = JSON.stringify(rootObj);

    fetch(process.env.REACT_APP_CARRER_PLANNING_ENDPOINT, requestData(answers))
    .then(res => res.json())
    .then((data) => {
      window.postMessage('{"AssessmentId":' + '"' + data.AssessmentId + '"' + ',"AssessmentType":' + '"' + data.AssessmentType + '"' + '}', '*');

      if (window.opener) {
        window.opener.postMessage('{"AssessmentId":' + '"' + data.AssessmentId + '"' + ',"AssessmentType":' + '"' + data.AssessmentType + '"' + '}', '*');
      }
      let occupations = Array();
      data.Occupations.map((row: any) => {
        return occupations.push(row);
      });
      fetchInterests(answers, occupations);
    })
    .catch(error => console.error(error));
  }

  return (
    <div className={classes.toolContainer}>
      {!started &&
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '45rem' }} className={classes.card}>
          <div className={classes.exitContainer}>
            <img alt="close" src={close} className={classes.exit} onClick={props.closeModal} />
          </div>
          <div className={classes.cardInner}>
            <img src={Icon} className={classes.cardImg} alt="cardImg" />
            <p className={classes.title}>CAREER ASSESSMENT</p>
            <p className={classes.subtitle}>{labelText}</p>
            <div style={{ marginTop: '7rem' }} className={classes.subtitle}>
              Please indicate your level of passion for each activity
            </div>
            <div className={classes.rating}>
              <img style={{ width: '24rem' }} src={stars} alt="starsRating" />
            </div>
            <div className={classes.cta}>
              <EfButtonOrange click={() => setStarted(true)} text={"START"} width={'12rem'} size={'1.4rem'} />
            </div>
          </div>
        </div>
      </div>}
      {started && !finished &&
        <Carousel slidesPerPage={2} value={carouselValue} onChange={next} centered>
          {questions.map((row, i: number) =>
          <div key={i} className={classes.card}>
            <div className={classes.exitContainer}>
              <img alt="close" src={close} className={classes.exit} onClick={props.closeModal} />
            </div>
            <div className={classes.cardInner}>
              <img className={classes.iconImg} src={require('../../assets/images/career/' + i + '.png')} />
              <div style={{ height: '6rem' }} className={classes.subtitle}>
                {row.Question}
              </div>
              <div style={{ marginTop: '1rem' }}>
                <Rating
                  onChange={(rate) => { finishQuestions(rate, questions, i, true) }}
                  emptySymbol={<img alt="empty" src={StarEmpty} style={{ width: '80%', height: 'auto' }} />}
                  fullSymbol={<img alt="full" src={StarFull} style={{ width: '80%', height: 'auto' }} />}
                  initialRating={rateValue[i] != null ? rateValue[i] : 0}
                />
              </div>
              <div className={classes.cta}>
                {i !== 0 && <EfButtonWhite width={'12rem'} click={prev} text="PREVIOUS" size={'1.4rem'} />}
                {rootObj[i] && i < questions.length - 1 && <EfButtonOrange click={() => next(i)} text="NEXT" width={'12rem'} size={'1.4rem'} />}
                {i === questions.length - 1 && <EfButtonOrange click={() => next(i)} text="DONE" width={'12rem'} size={'1.4rem'} />}
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '18rem', marginTop: '2.5rem'}}>
                <Line percent={(i + 1) * 100 / questions.length} strokeWidth={4} strokeColor="#F7B500" trailWidth={4} />
                <div style={{marginTop: '.5rem', textAlign: 'center', fontSize: '1.5rem', color: '#293861', fontFamily: 'brandon-grotesque'}}>
                  {`${i + 1}/${questions.length}`}
                </div>
              </div>
            </div>
          </div>)}
        </Carousel>}
      {started && finished &&
        <div className={classes.card}>
          <div className={classes.exitContainer}>
            <img alt="close" src={close} className={classes.exit} onClick={props.closeModal} />
          </div>
          <div className={classes.cardInner}>
            <p className={classes.title}>You are done!</p>
            <img src={Done} className={classes.doneImg} />
            <div className={classes.potentialCareers}>
              HERE ARE YOUR
            </div>
            <div className={classes.potentialCareers}>
              POTENTIAL CAREERS
            </div>
            <div className={classes.checkoutBtn}>
              <EfButtonOrange click={sendData} text={"LET'S CHECK THEM OUT"} width={'24rem'} size={'1.4rem'} />
            </div>
          </div>
        </div>}
    </div>
  );
}

export default InterestsCarousel;
