/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as calculatorActions from '../../store/actions/Calculator';
import { getCustomData } from '../../utils/LifeEventTools/customDataGenerator';
import { stateList } from '../../assets/content/StateList';
const { PROPERTY_TAX_BY_STATE, BUY_VS_RENT } = require('ef-calculators');

interface houseCalculator {
    yearsToStayInHouse: number; //8
    totalEstimatedCostOfRenting: number; //8151684.72
    monthlyMortgagePayment: number; //8926.23
    totalOfInterestPayments: number; //8 55254.78
    totalClosingCosts: number; //87500
    totalPropertyTaxCosts: number; //8 20268.32
    totalMaintenanceCosts: number; //8 24321.99
    totalHomeownersInsuranceCosts: number; //812667.7
    homeAppreciation: number; //876193.3
    totalPMICosts: number; //80
    interestEarnedOnInvestedFunds: number; //815574.94
    totalAssociationDues: number; //82432.2
    taxSavings: number; //82685.69
    cashInvestmentOnRent: number; //8 0
    costOfSellingHome: number; //80
    totalCostsOfRenting: number; //8151684.72
    totalBenefitsOfRenting: number; //815574.94
    totalCostsOfBuying: number; //8122444.99
    totalBenefitsOfBuying: number; //8 78878.99
    netCostOfRenting: number; //8136109.79
    netCostOfBuying: number; //843566
    buyingBreakeven: number; //81
    firstYearBuyDownPayment: number; //850000
    firstYearBuyMortgagePayments: number; //811114.76
    firstYearBuyHomeownerInsurance: number; //81253.13
    firstYearBuyHOAfees: number; //8240.6
    firstYearBuyPropertyTaxes: number; //82005
    firstYearBuyMaintenanceCosts: number; //8 2406
    firstYearBuyClosingCosts: number; //87500
    firstYearRentPayments: number; //821281.74
    firstYearRentInsuranceCosts: number; //8240
    firstYearRentDownPayment: number; //82800
    investmentSimulation: any[];
    paymentsOverTime: any[];
    amortizationSchedule: {
        summary: {
        amount: number;
        months: number;
        monthlyPayment: number;
        totalPaid: number;
        interestRate: number;
        };
        monthlyAmortization: {
        month: number;
        balance: number;
        interest: number;
        payment: number;
        capital: number;
        }[];
    };
    summary: string;
};

export const useHouseBuyRent = (uniqueEventID?: string) => {
    const dispatch = useDispatch();
    const loggedUserLifeEventData = useSelector((state: any) => {
        if (state.session.user) {
            return state.session.user.lifeEvents.find((le: any) => le.id === uniqueEventID)
        }
        else {
            return null;
        }
      }
    );
    
    const defaultData = getCustomData('Buy a House');

    /* #region Inputs */
    const [monthlyRent, setmonthlyRent] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.monthlyRent : defaultData.monthlyRent);
    const [monthlyRentInsurance, setmonthlyRentInsurance] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.monthlyRentInsurance : defaultData.monthlyRentInsurance);
    const [inflationRate, setinflationRate] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.inflationRate : defaultData.inflationRate);
    const [housePrice, setshousePrice] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.housePrice : defaultData.housePrice);
    const [downPayment, setdownPayment] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.downPayment : defaultData.downPayment);
    const [downPaymentRent, setdownPaymentRent] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.downPaymentRent : defaultData.downPaymentRent);
    const [mortgageTerm, setmortgageTerm] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.loanTerm : defaultData.loanTerm);
    const [mortgageInterestRate, setmortgageInterestRate] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.mortgageInterestRate : defaultData.mortgageInterestRate);
    const [homeBuyDiscountPoints, sethomeBuyDiscountPoints] = useState(0);
    const [closingCostRate, setclosingCostRate] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.closingCost : defaultData.closingCost);
    const [mortgageCosts, setmortgageCosts] = useState(0);
    const [mortgageInsuranceRate, setmortgageInsuranceRate] = useState(0);
    const [homeownersInsuranceRate, sethomeownersInsuranceRate] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.homeownersInsuranceRate : defaultData.homeownersInsuranceRate);
    const [homeownersAssociationDues, sethomeownersAssociationDues] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.monthlyHOAFees : defaultData.monthlyHOAFees);
    const [homeMonthlyMaintenance, sethomeMonthlyMaintenance] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.homeMaintenance : defaultData.homeMaintenance);
    const [homeAnnualTax, sethomeAnnualTax] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? housePrice * (loggedUserLifeEventData.customData.propertyTax / 100) : housePrice * (defaultData.propertyTax / 100));
    const [incomeTax, setincomeTax] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.incomeTaxRate : defaultData.incomeTaxRate);
    const [rateOfReturn, setrateOfReturn] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.rateOfReturn : defaultData.rateOfReturn);
    const [homeAppreciationRate, sethomeAppreciationRate] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.homeAppreciationRate : defaultData.homeAppreciationRate);
    const [yearsToStayInHouse, setyearsToStayInHouse] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.yearsToStayInHouse : defaultData.yearsToStayInHouse);
    const [homeBuyRealtorCommissionRate, sethomeBuyRealtorCommissionRate] = useState(0);
    const [rentAppreciation, setrentAppreciation] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.rentAppreciationRate : defaultData.rentAppreciationRate);
    const [capitalGainsTaxRate, setcapitalGainsTaxRate] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.capitalGainsTaxRate : defaultData.capitalGainsTaxRate);

    const [propertyState, setPropertyState] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.city : defaultData.city);

    const [selectedOption, setSelectedOption] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.selectedOption : 'BUY');
    /* #endregion */

    /* #region Outputs */
    const [yearsToStayInHouseopt, setyearsToStayInHouseopt] = useState(0);
    const [amortizationSchedule, setamortizationSchedule] = useState(Object());
    const [totalEstimatedCostOfRenting, settotalEstimatedCostOfRenting] = useState(0);
    const [monthlyMortgagePayment, setmonthlyMortgagePayment] = useState(0);
    const [totalOfInterestPayments, settotalOfInterestPayments] = useState(0);
    const [totalClosingCosts, settotalClosingCosts] = useState(0);
    const [totalPropertyTaxCosts, settotalPropertyTaxCosts] = useState(0);
    const [totalMaintenanceCosts, settotalMaintenanceCosts] = useState(0);
    const [totalHomeownersInsuranceCosts, settotalHomeownersInsuranceCosts] = useState(0);
    const [homeAppreciation, sethomeAppreciation] = useState(0);
    const [totalPMICosts, settotalPMICosts] = useState(0);
    const [interestEarnedOnInvestedFunds, setinterestEarnedOnInvestedFunds] = useState(0);
    const [totalAssociationDues, settotalAssociationDues] = useState(0);
    const [taxSavings, settaxSavings] = useState(0);
    const [cashInvestmentOnRent, setcashInvestmentOnRent] = useState(0);
    const [costOfSellingHome, setcostOfSellingHome] = useState(0);
    const [totalCostsOfRenting, settotalCostsOfRenting] = useState(0);
    const [totalBenefitsOfRenting, settotalBenefitsOfRenting] = useState(0);
    const [totalCostsOfBuying, settotalCostsOfBuying] = useState(0);
    const [totalBenefitsOfBuying, settotalBenefitsOfBuying] = useState(0);
    const [netCostOfRenting, setnetCostOfRenting] = useState(0);
    const [netCostOfBuying, setnetCostOfBuying] = useState(0);
    const [buyingBreakeven, setbuyingBreakeven] = useState(0);
    const [firstYearBuyDownPaymentopt, setfirstYearBuyDownPaymentopt] = useState(0);
    const [firstYearBuyMortgagePayments, setfirstYearBuyMortgagePayments] = useState(0);
    const [firstYearBuyHomeownerInsurance, setfirstYearBuyHomeownerInsurance] = useState(0);
    const [firstYearBuyHOAfees, setfirstYearBuyHOAfees] = useState(0);
    const [firstYearBuyPropertyTaxes, setfirstYearBuyPropertyTaxes] = useState(0);
    const [firstYearBuyMaintenanceCosts, setfirstYearBuyMaintenanceCosts] = useState(0);
    const [firstYearBuyClosingCosts, setfirstYearBuyClosingCosts] = useState(0);
    const [firstYearRentPayments, setfirstYearRentPayments] = useState(0);
    const [firstYearRentInsuranceCosts, setfirstYearRentInsuranceCosts] = useState(0);
    const [firstYearRentDownPayment, setfirstYearRentDownPayment] = useState(0);
    const [investmentSimulationHouse, setinvestmentSimulationHouse] = useState(Array());
    const [paymentsOverTimeHouse, setpaymentsOverTimeHouse] = useState(Array());
    const [amortizationSummary, setAmortizationSummary] = useState(false);
    const [monthlyAmortization, setmonthlyAmortization] = useState(Array());
    const [stringSummary, setstringSummary] = useState('');

    const [taxByState, setTaxByState] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0 ? loggedUserLifeEventData.customData.propertyTax : defaultData.propertyTax);
    /* #endregion */

    function priceFormat(value: number | string) {
    return Math.floor(Number(value) * 100) / 100;
    }

    useEffect(() => {
        const propertytax = () => {
            const stateKey = stateList.find((s: any) => s.text === propertyState).key;
            const p = PROPERTY_TAX_BY_STATE(stateKey);
            if (typeof p != "string") {
                setTaxByState(p);
            }
        };
        propertytax();
        //set annual property tax
        sethomeAnnualTax(housePrice * (taxByState / 100));
    }, [housePrice, propertyState, taxByState])

    useEffect(() => {
        const housebuy = () => {
            const h: houseCalculator | string = BUY_VS_RENT(
                monthlyRent,
                monthlyRentInsurance,
                inflationRate,
                housePrice,
                downPayment,
                downPaymentRent,
                mortgageTerm,
                mortgageInterestRate,
                homeBuyDiscountPoints,
                closingCostRate,
                mortgageCosts,
                mortgageInsuranceRate,
                homeownersInsuranceRate,
                homeownersAssociationDues,
                homeMonthlyMaintenance,
                homeAnnualTax,
                incomeTax,
                rateOfReturn,
                homeAppreciationRate,
                yearsToStayInHouse,
                homeBuyRealtorCommissionRate,
                rentAppreciation,
                capitalGainsTaxRate
            );
            if (typeof h != "string") {
                setyearsToStayInHouseopt(priceFormat(h.yearsToStayInHouse));
                setamortizationSchedule(h.amortizationSchedule);
                settotalEstimatedCostOfRenting(
                priceFormat(h.totalEstimatedCostOfRenting)
                );
                setmonthlyMortgagePayment(priceFormat(h.monthlyMortgagePayment));
                settotalOfInterestPayments(priceFormat(h.totalOfInterestPayments));
                settotalClosingCosts(priceFormat(h.totalClosingCosts));
                settotalPropertyTaxCosts(priceFormat(h.totalPropertyTaxCosts));
                settotalMaintenanceCosts(priceFormat(h.totalMaintenanceCosts));
                settotalHomeownersInsuranceCosts(
                priceFormat(h.totalHomeownersInsuranceCosts)
                );
                sethomeAppreciation(priceFormat(h.homeAppreciation));
                settotalPMICosts(h.totalPMICosts);
                setinterestEarnedOnInvestedFunds(h.interestEarnedOnInvestedFunds);
                settotalAssociationDues(h.totalAssociationDues);
                settaxSavings(h.taxSavings);
                setcashInvestmentOnRent(h.cashInvestmentOnRent);
                setcostOfSellingHome(h.costOfSellingHome);
                settotalCostsOfRenting(h.netCostOfRenting);
                settotalBenefitsOfRenting(h.totalBenefitsOfRenting);
                settotalCostsOfBuying(h.totalCostsOfBuying);
                settotalBenefitsOfBuying(h.totalBenefitsOfBuying);
                setnetCostOfRenting(h.netCostOfRenting);
                setnetCostOfBuying(h.firstYearBuyMaintenanceCosts);
                setbuyingBreakeven(h.buyingBreakeven);
                setfirstYearBuyDownPaymentopt(h.firstYearBuyDownPayment);
                setfirstYearBuyMortgagePayments(h.firstYearBuyMortgagePayments);
                setfirstYearBuyHomeownerInsurance(h.firstYearBuyHomeownerInsurance);
                setfirstYearBuyHOAfees(h.firstYearBuyHOAfees);
                setfirstYearBuyPropertyTaxes(h.firstYearBuyPropertyTaxes);
                setfirstYearBuyMaintenanceCosts(h.firstYearBuyMaintenanceCosts);
                setfirstYearBuyClosingCosts(h.firstYearBuyClosingCosts);
                setfirstYearRentPayments(h.firstYearRentPayments);
                setfirstYearRentInsuranceCosts(h.firstYearRentInsuranceCosts);
                setfirstYearRentDownPayment(h.firstYearRentDownPayment);
                setinvestmentSimulationHouse(h.investmentSimulation);
                setpaymentsOverTimeHouse(h.paymentsOverTime);
                setAmortizationSummary(true);
                setmonthlyAmortization(h.amortizationSchedule.monthlyAmortization);
                setstringSummary(h.summary);
            }
        };
        housebuy();
        dispatch(calculatorActions.buyHouseChangeState({
            closingCost: closingCostRate,
            rentAppreciationRate: rentAppreciation,
            city: propertyState,
            rateOfReturn: rateOfReturn,
            monthlyRentInsurance: monthlyRentInsurance,
            monthlyHOAFees: homeownersAssociationDues,
            downPayment: downPayment,
            monthlyRent: monthlyRent,
            mortgageInterestRate: mortgageInterestRate,
            homeownersInsuranceRate: homeownersInsuranceRate,
            incomeTaxRate: incomeTax,
            loanTerm: mortgageTerm,
            capitalGainsTaxRate: capitalGainsTaxRate,
            housePrice: housePrice,
            homeAppreciationRate: homeAppreciationRate,
            inflationRate: inflationRate,
            downPaymentRent: downPaymentRent,
            yearsToStayInHouse: yearsToStayInHouse,
            selectedOption: selectedOption,
            homeMaintenance: homeMonthlyMaintenance,
            propertyTax: taxByState,
        }));
    }, [
        /* #region Dependencies */
        dispatch,
        propertyState,
        taxByState,
        capitalGainsTaxRate, 
        closingCostRate, 
        downPayment,
        downPaymentRent, 
        homeAnnualTax, 
        homeAppreciationRate, 
        homeBuyDiscountPoints, 
        homeBuyRealtorCommissionRate, 
        homeMonthlyMaintenance, 
        homeownersAssociationDues, 
        homeownersInsuranceRate, 
        housePrice, 
        incomeTax, 
        inflationRate, 
        monthlyRent, 
        monthlyRentInsurance, 
        mortgageCosts, 
        mortgageInsuranceRate, 
        mortgageInterestRate,
        mortgageTerm, 
        rateOfReturn, 
        rentAppreciation, 
        yearsToStayInHouse,
        selectedOption,
        /* #endregion */
    ]);

    return {
        selectedOption, setSelectedOption,
        propertyState, setPropertyState,
        monthlyRent, setmonthlyRent,
        monthlyRentInsurance, setmonthlyRentInsurance,
        inflationRate, setinflationRate,
        housePrice, setshousePrice,
        downPayment, setdownPayment,
        downPaymentRent, setdownPaymentRent,
        mortgageTerm, setmortgageTerm,
        mortgageInterestRate, setmortgageInterestRate,
        homeBuyDiscountPoints, sethomeBuyDiscountPoints,
        closingCostRate, setclosingCostRate,
        mortgageCosts, setmortgageCosts,
        mortgageInsuranceRate, setmortgageInsuranceRate,
        homeownersInsuranceRate, sethomeownersInsuranceRate,
        homeownersAssociationDues, sethomeownersAssociationDues,
        homeMonthlyMaintenance, sethomeMonthlyMaintenance,
        homeAnnualTax, sethomeAnnualTax,
        incomeTax, setincomeTax,
        rateOfReturn, setrateOfReturn,
        homeAppreciationRate, sethomeAppreciationRate,
        yearsToStayInHouse, setyearsToStayInHouse,
        homeBuyRealtorCommissionRate, sethomeBuyRealtorCommissionRate,
        rentAppreciation, setrentAppreciation,
        capitalGainsTaxRate, setcapitalGainsTaxRate,
        yearsToStayInHouseopt, setyearsToStayInHouseopt,
        amortizationSchedule, setamortizationSchedule,
        totalEstimatedCostOfRenting, settotalEstimatedCostOfRenting,
        monthlyMortgagePayment, setmonthlyMortgagePayment,
        totalOfInterestPayments, settotalOfInterestPayments,
        totalClosingCosts, settotalClosingCosts,
        totalPropertyTaxCosts, settotalPropertyTaxCosts,
        totalMaintenanceCosts, settotalMaintenanceCosts,
        totalHomeownersInsuranceCosts, settotalHomeownersInsuranceCosts,
        homeAppreciation, sethomeAppreciation,
        totalPMICosts, settotalPMICosts,
        interestEarnedOnInvestedFunds, setinterestEarnedOnInvestedFunds,
        totalAssociationDues, settotalAssociationDues,
        taxSavings, settaxSavings,
        cashInvestmentOnRent, setcashInvestmentOnRent,
        costOfSellingHome, setcostOfSellingHome,
        totalCostsOfRenting, settotalCostsOfRenting,
        totalBenefitsOfRenting, settotalBenefitsOfRenting,
        totalCostsOfBuying, settotalCostsOfBuying,
        totalBenefitsOfBuying, settotalBenefitsOfBuying,
        netCostOfRenting, setnetCostOfRenting,
        netCostOfBuying, setnetCostOfBuying,
        buyingBreakeven, setbuyingBreakeven,
        firstYearBuyDownPaymentopt, setfirstYearBuyDownPaymentopt,
        firstYearBuyMortgagePayments, setfirstYearBuyMortgagePayments,
        firstYearBuyHomeownerInsurance, setfirstYearBuyHomeownerInsurance,
        firstYearBuyHOAfees, setfirstYearBuyHOAfees,
        firstYearBuyPropertyTaxes, setfirstYearBuyPropertyTaxes,
        firstYearBuyMaintenanceCosts, setfirstYearBuyMaintenanceCosts,
        firstYearBuyClosingCosts, setfirstYearBuyClosingCosts,
        firstYearRentPayments, setfirstYearRentPayments,
        firstYearRentInsuranceCosts, setfirstYearRentInsuranceCosts,
        firstYearRentDownPayment, setfirstYearRentDownPayment,
        investmentSimulationHouse, setinvestmentSimulationHouse,
        paymentsOverTimeHouse, setpaymentsOverTimeHouse,
        amortizationSummary, setAmortizationSummary,
        monthlyAmortization, setmonthlyAmortization,
        stringSummary, setstringSummary,
    };
};