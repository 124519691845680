import * as React from "react";
import {
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
  ITooltipProps,
  ITooltipStyles,
} from "office-ui-fabric-react/lib/Tooltip";
import classes from "./EFTooltip.module.css";
import EFLabel from "../EFLabel/EFLabel";

interface IProps {
  style?: any;
  title: string;
  text?: string;
}

export const EFTooltip: React.FC<IProps> = (props) => {
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => <EFLabel style={textStyle}>{props.text}</EFLabel>,
    style: { padding: "unset" },
  };

  return (
    <TooltipHost
      tooltipProps={tooltipProps}
      delay={TooltipDelay.zero}
      id={props.title}
      directionalHint={DirectionalHint.bottomCenter}
      styles={toolTipStyles}
    >
      <div
        style={props.style}
        className={classes.btn}
        aria-describedby={props.title}
      >
        ?
      </div>
    </TooltipHost>
  );
};

const toolTipStyles: Partial<ITooltipStyles> = {
  root: { display: "inline-block" },
  content: { background: "#293861" },
  subText: { background: "#293861" },
};

const textStyle = {
  padding: "0 0.5rem",
  fontSize: "1.3rem",
  fontWeight: "unset",
  maxWidth: "20rem",
  textAlign: "center",
  color: "#293861",
};

export default EFTooltip;
