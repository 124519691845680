import React from 'react';
import Backdrop from '../../Atomic/Backdrop/Backdrop';
import { Stack } from "office-ui-fabric-react";
import LifeEventsList from '../LifeEventsList/LifeEventsList';
import { useResponsive } from '../../../hooks/useResponsive';

interface Props {
    onBackdropClick: () => void;
    onExitClick: () => void;
    onLifeEventClick: (le: any) => void;
    openModal: boolean;
}

const LifeEventPicker: React.FC<Props> = props => {

    const { screenIsAtLeast } = useResponsive();

    return (
        <Backdrop
            customStyle={{ overflowY: "scroll" }}
            onClick={props.onBackdropClick}
        >
            <Stack
                horizontalAlign="center"
                style={
                    screenIsAtLeast("tabPort")
                        ? { width: "100%", margin: "10rem" }
                        : { width: "96%", margin: "2%" }
                }
            >
                <LifeEventsList
                    openModal={props.openModal}
                    onClickExit={props.onExitClick}
                    onLifeEventClick={props.onLifeEventClick}
                />
            </Stack>
        </Backdrop>
    );
};

export default LifeEventPicker;