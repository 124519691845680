import React, { useState }  from 'react';
import { Stack, TextField, FontIcon } from 'office-ui-fabric-react';
import Modal from 'react-modal';
import FabricCalendar from '../../Atomic/Calendar/FabricCalendar/FabricCalendar';
import CurrencyInputAlt from '../../Atomic/CurrencyInput/CurrencyInputAlt/CurrencyInputAlt';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import CustomModalCard from '../../UI/CustomModalCard/CustomModalCard';
import moment from 'moment';

import addLifeEvent from '../../../assets/images/lifeEvents/addLifeEvent.svg';
import classes from './Spikes.module.css';

interface IProps {
    title?: string;
    spikes?: any,
    isOpen?: boolean,
    onToggle?: () => void,
    icon?: string,
    onSaveSpikes?: (spikes: any) => void,
};

const Spikes: React.FC<IProps> = props => {
    const columnProps = {
        tokens: { childrenGap: '1.5rem' },
        styles: { root: { width: '23rem' } },
    };
    const [lump, setLump] = useState({description: '', amount: 0, startDate: null, endDate: null});
    const [errLumpMsg, setErrLumpMsg] = useState(false);
    const [txSettings, setTxSettings] = useState<any>({ lumpy: props.spikes });

    const onStart = (date: Date) => {
        const iso = moment(date).valueOf();
        const isStartBigger = iso > lump.endDate;
        if (isStartBigger) {
            setLump({ ...lump, startDate: iso, endDate: iso })
        } else {
            setLump({ ...lump, startDate: iso })
        }
    }
    const onEnd = (date: Date) => {
        const iso = moment(date).valueOf();
        setLump({ ...lump, endDate: iso })
    }
    const onStartDateSelect = (l: any, date: Date) => {
        const lumpy = txSettings.lumpy?.map((d) => {
            if (d === l) {
                const iso = moment(date).valueOf();
                const isStartBigger = iso > d.endDate;
                if (isStartBigger) {
                    return { ...d, startDate: iso, endDate: iso }
                }
                return { ...d, startDate: iso};
            }
            return d;
        });
        setTxSettings({
          ...txSettings,
          lumpy,
        });
      };
    const onEndDateSelect = (l: any, date: Date) => {
    const lumpy = txSettings.lumpy?.map((d) => {
        if (d === l) {
            return { ...d, endDate: moment(date).valueOf()};
            }
        return d;
    });
    setTxSettings({
        ...txSettings,
        lumpy,
    });
    };
    const addLumpy = () => {
    if (!lump.startDate || !lump.endDate || !lump.amount || !lump.description) {
        setErrLumpMsg(true);
    } else {
        setErrLumpMsg(false);
        setTxSettings({
            ...txSettings,
            lumpy: [lump, ...(txSettings.lumpy || [])],
        });
        setLump({ description: '', amount: 0, startDate: null, endDate: null });
    }
    };
    const onChangeLumpy = (
    l: any,
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
    ) => {
    const index = (ev.target as HTMLInputElement)["name"].replace("lumpy-", "");
    let lumpy = txSettings.lumpy || [];
    let newData: string | number = newValue;
    if (index === 'amount') {
        newData = handleStrToNum(ev.currentTarget.value);
    }
    lumpy = lumpy.map((d) => {
        if (d === l) {
            return { ...d, [index]: newData };
        }
        return d;
        });
    setTxSettings({
        ...txSettings,
        lumpy,
    });
    };
    const removeLumpy = (l: any) => {
    const lumpy = txSettings.lumpy?.filter((v) => v !== l);
    setTxSettings({
        ...txSettings,
        lumpy,
    });
    };

    const onSave = () => {
        let currSettings = txSettings;
        if (!lump.startDate || !lump.endDate || !lump.amount || !lump.description) {
            setErrLumpMsg(true);
        } else {
            setErrLumpMsg(false);
            currSettings = {
                ...txSettings,
                lumpy: [lump, ...(txSettings.lumpy || [])],
            }
            setTxSettings(currSettings);
            setLump({ description: '', amount: 0, startDate: null, endDate: null });
        }
        props.onSaveSpikes(currSettings);
        props.onToggle();
    }

    return (
        <Modal 
            isOpen={props.isOpen}
            onRequestClose={props.onToggle}
            style={modalCardStyle}
        >
            <CustomModalCard
                eventIcon={props.icon} 
                title={`Spikes - ${props.title}`} 
                onClickExit={props.onToggle}
                button
                buttonText='SAVE'
                onClick={onSave}
                stackClassName={classes.modalInner}
            >
                <Stack tokens={{ childrenGap: '2rem' }} styles={{root: { marginTop: '1rem' }}}>
                    <Stack horizontalAlign='center' tokens={{childrenGap: '1rem'}}>
                        <EFLabel style={{textAlign: 'center'}}>You can set up your cash flow planner with different amounts at different times</EFLabel>
                        <EFLabel style={{fontWeight: 'normal', textAlign: 'center'}}>For example: plan for an unexpected expense or saving goal</EFLabel>
                    </Stack>
                </Stack>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '25rem', flexDirection: 'column', overflow: 'auto'}}>
                    <Stack tokens={{ childrenGap: '1rem' }} styles={{root: { marginTop: '1rem', width: '90%' }}}>
                        <Stack tokens={{ childrenGap: '2rem' }} verticalAlign='center' horizontal>
                            <Stack {...columnProps}>
                                <FabricCalendar isDayPickerVisible={false} placeholder='Start Date' selectedDate={lump.startDate} onSelectDate={onStart} />
                            </Stack>
                            <Stack {...columnProps}>
                                <FabricCalendar isDayPickerVisible={false} placeholder='End Date' minDate={lump.startDate} selectedDate={lump.endDate} onSelectDate={onEnd} />
                            </Stack>
                            <Stack {...columnProps}>
                                <CurrencyInputAlt
                                    name="lumpy-amount" 
                                    placeholder='$ Amount' 
                                    value={lump.amount === 0 ? '' : lump.amount}
                                    onChange={(e: any) => setLump({ ...lump, amount: handleStrToNum(e.currentTarget.value) })}
                                />
                            </Stack>
                            <Stack {...columnProps}>
                                <TextField
                                    name="lumpy-description"
                                    placeholder='Description' 
                                    value={lump.description}
                                    onChange={(_, newValue: string) => setLump({ ...lump, description: newValue })}
                                />
                            </Stack>
                            <img 
                                onClick={addLumpy} 
                                style={{width: '2.1rem', height: '2.1rem', cursor: 'pointer'}} 
                                alt='addIcon' 
                                src={addLifeEvent} 
                            />
                        </Stack>
                        {errLumpMsg && <EFLabel style={{color: '#c4233e', textAlign: 'center'}}>Please fill out every input</EFLabel>} 
                    </Stack>
                    {(txSettings.lumpy || []).map((d) => {
                        return (
                            <Stack verticalAlign='center' horizontal tokens={{ childrenGap: '2rem' }} styles={{root: { marginTop: '1rem', width: '90%' }}}>
                                <Stack {...columnProps}>
                                    <FabricCalendar 
                                        isDayPickerVisible={false} 
                                        placeholder='Start Date' 
                                        selectedDate={d.startDate} 
                                        onSelectDate={onStartDateSelect.bind({}, d)} 
                                    />
                                </Stack>
                                <Stack {...columnProps}>
                                    <FabricCalendar 
                                        isDayPickerVisible={false} 
                                        placeholder='End Date' 
                                        minDate={d.startDate} 
                                        selectedDate={d.endDate} 
                                        onSelectDate={onEndDateSelect.bind({}, d)} 
                                    />
                                </Stack>
                                <Stack {...columnProps}>
                                    <CurrencyInputAlt
                                        name="lumpy-amount" 
                                        placeholder='$ Amount' 
                                        value={d.amount === 0 ? '' : d.amount}
                                        onChange={onChangeLumpy.bind({}, d)}
                                    />
                                </Stack>
                                <Stack {...columnProps}>
                                    <TextField
                                        name="lumpy-description"
                                        placeholder='Description' 
                                        value={d.description}
                                        onChange={onChangeLumpy.bind({}, d)}
                                        styles={{ prefix: { color: '#CACACA', fontFamily: 'brandon-grotesque', fontSize: '1.4rem' } }}
                                    />
                                </Stack>
                                <Stack style={{paddingLeft: '0.5rem'}}>
                                    <FontIcon 
                                        style={{cursor: 'pointer', marginRight: '.5rem'}} 
                                        iconName='GreyTrash' 
                                        onClick={removeLumpy.bind({}, d)}
                                    />
                                </Stack>
                            </Stack>
                        )
                    })}
                </div>
            </CustomModalCard>
        </Modal>
    );
};

const modalCardStyle = {
    content: {
        display: "flex",
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: 'fit-content',
        width: '100%',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
        margin: '2rem 0',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)'
    }
}

// must re work when using actual min and maxes
const handleStrToNum = (str: string) => {
    let curr_str = str;
    curr_str = curr_str.replace(/,/g, '');
    curr_str = curr_str.replace('$', '');
    let numVal = parseFloat(curr_str);
    if (isNaN(numVal) || numVal < 0) {
        numVal = 0;
    } else if (numVal > 100000) {
        numVal = 100000;
    };
    return numVal;
};

export default Spikes;
