import React from 'react';
import classes from './RetirementGrow.module.css';
import { Stack, Label } from 'office-ui-fabric-react';
import DoughnutChart from '../../../Charts/DoughnutChart';
import Category from '../../../Scoop/CategoryList/Category';
import { generateColorShadeSet, addAlpha } from '../../../../utils/colorGenerators';
import { useRetirementData } from '../../../../hooks/ScoopDashboards/useRetirementData';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../../utils/Masking';
import BlurAddMeContainer from '../../../UI/BlurAddMeContainer/BlurAddMeContainer';

interface IProps {
    accBalancesData: any,
    hasAccounts?: boolean,
    onClick?: () => void,
    onClickAddAccs?: () => void,
};

const numberMask = createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    allowNegative: true,
    allowLeadingZeroes: false,
  });

const RetirementGrow: React.FC<IProps> = props => {
    const toYearly = false;
    const {
        sortedData,
        monthlySums,
        getBalancesByMonth
    } = useRetirementData(props.accBalancesData, toYearly);

    const doughnutColors = generateColorShadeSet(sortedData && sortedData.length, '#036190');
    
    const getBaseCategories = () => {
        if (sortedData) {
            return sortedData.map((acc: any, i: any) => ({
                key: acc.account.accountName,
                text: acc.account.accountName,
                color: doughnutColors[i],
                icon: require('../../../../assets/images/scoop/piggy.svg'),
                focused: false,
                value: acc.historicalBalances[11] ? acc.historicalBalances[11].amount : 0,
            }));
        } else {
            return [];
        };
    };
    let categoryData = [
        ...getBaseCategories()
    ];

    const categories = categoryData.map(e => (
        <Stack key={e.key}>
            <Category
                id={e.key}
                title={e.text}
                color={addAlpha(e.color, .3)}
                icon={e.icon}
                iconColor={e.color}
                focused={e.focused}
                onClick={() => null}
                value={e.value} 
            />
        </Stack>
    ));
    
    return (
        <>
            {(props.hasAccounts && sortedData.length) ? (
                 <Stack onClick={props.onClick} className={classes.container} horizontalAlign='center' tokens={{childrenGap: 20}}>
                 <Stack horizontalAlign='center'>
                     <EFLabel style={{fontWeight: 'normal', fontSize: '1.8rem'}}>Total Retirement Savings</EFLabel>
                     <EFLabel style={{fontWeight: 'normal', fontSize: '1.8rem'}}>{maskValue(Math.round(monthlySums[11]), numberMask)}</EFLabel>
                     <Stack className={classes.doughnut_view} verticalAlign='center' horizontalAlign='center' tokens={{childrenGap: 10}}>
                         <DoughnutChart 
                             datasets={{
                             labels: sortedData ? sortedData.map((acc: any) => acc.account.accountName) : [],
                             datasets: [
                                 {
                                     label: 'Investments',
                                     data: getBalancesByMonth(11).map((e: any) => Math.round(e * 1e2) / 1e2),
                                     backgroundColor: doughnutColors,
                                     borderColor: 'transparent',
                                 },
                             ]
                             }}
                         />
                     </Stack>
                 </Stack>
                 <Stack horizontal horizontalAlign='center'>
                     <Stack horizontalAlign='center' tokens={{childrenGap: 6}}>
                         {categories.filter((_: any, i: any) => i < 3)}
                         {categories.length > 3 && <Label className={classes.show_all} onClick={() => null}>Show all</Label>}
                     </Stack>
                 </Stack>
             </Stack>
            ) : 
            <BlurAddMeContainer 
                onClick={props.onClickAddAccs} 
                backgroundImg={require('../../../../assets/images/grow_retire_preview.png')} 
                label={props.hasAccounts ? 'Please add more accounts' : 'Link your accounts'}
            />}
       </>
    )
};

export default RetirementGrow;
