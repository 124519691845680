import React from "react";
import { Stack, FontIcon } from "office-ui-fabric-react";
import EFLabel from "../../Atomic/EFLabel/EFLabel";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import maskValue from "../../../utils/Masking";

interface IProps {
  style?: object;
  titleStyle?: object;
  sectionGap?: number;
  title: string;
  value?: number;
  value2?: number;
  color?: string;
  prefix?: string;
  suffix?: string;
  includeThousandsSeparator?: boolean;
  thousandsSeparatorSymbol?: string;
  allowDecimal?: boolean;
  decimalSymbol?: string;
  decimalLimit?: number;
  allowNegative?: boolean;
  allowLeadingZeroes?: boolean;
  isBulleted?: boolean;
  valueFontSize?: number | string;
  includeIcon?: string;
  isHorizontal?: boolean;
  pairWidth?: string | number;
}

const MaskedLabelPair: React.FC<IProps> = (props) => {
  const numberMask = createNumberMask({
    ...MaskedLabelPair.defaultProps,
    ...props,
  });

  const titleStyle = {
    fontSize: '1.6rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: props.pairWidth,
    padding: '.5rem 0'
  }


  return (
    <Stack horizontal={props.isHorizontal} horizontalAlign={props.isHorizontal ? 'space-between' : 'center'}>
      {props.title ? (
        <Stack horizontal>
          {props.isBulleted ? (
            <FontIcon iconName="LocationDot" style={{ color: props.color }} />
          ) : null}
          <EFLabel style={{ ...titleStyle, ...props.titleStyle }}>{props.title}</EFLabel>
        </Stack>
      ) : null}
      <Stack horizontal verticalAlign='center' style={{ width: '49%'}} >
        <EFLabel
          style={{
            fontWeight: "normal" as "normal",
            fontSize: props.valueFontSize,
            textAlign: "left" as "left",
            color: props.color,
            width: '50%'
          }}
        >
          {props.value2 ? maskValue(Math.round(props.value2), numberMask) : ''}
        </EFLabel>
        <EFLabel
          style={{
            fontWeight: "normal" as "normal",
            fontSize: props.valueFontSize,
            textAlign: "left" as "left",
            color: props.color,
            width: '50%'
          }}
        >
          {props.value ? maskValue(Math.round(props.value), numberMask) : ''}
        </EFLabel>
      </Stack>
    </Stack>
  );
};



MaskedLabelPair.defaultProps = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: false,
  decimalSymbol: ".",
  decimalLimit: 2,
  allowNegative: true,
  allowLeadingZeroes: false,
  isBulleted: true,
  valueFontSize: '1.6rem',
  isHorizontal: true,
};

export default MaskedLabelPair;
