import React from 'react';
import EFButton from '../EFButton/EFButton';
import { useHistory } from "react-router-dom";

import classes from './Interstitial.module.css';

interface IProps {
    customHeader?: string;
    interstitialImg?: string;
    btnTxt?: string;
    path?: string;
};

const Interstitial: React.FC<IProps> = props => {

    let history = useHistory();

    const myPlansHeader = (
        <>
            <p className={classes.title}>Let's make a plan!</p>
            <p className={classes.subtitle}>Let's set up a plan. You can get started from one of</p>
            <p className={`${classes.subtitle} ${classes.sub2}`}>our profiles or set up your game plan.</p>
        </>
    );

    return (
        <div className={classes.container}>
            {props.customHeader ? <p className={classes.title}>{props.customHeader}</p> : myPlansHeader}
            <img className={classes.img} src={props.interstitialImg} alt="setup-preview" />
            <EFButton 
                stackClassName={classes.btn}
                text={props.btnTxt}
                onClick={() => history.push(props.path)} 
            />
            
            {/* --- TODO: CREATE BUTTON TO EXPLORE OTHER PROFILES FOR RELATIVE FT ---
             <EFButton 
                stackClassName={classes.btn}
                text={props.btnTxt}
                onClick={() => history.push(props.path)} 
            /> */}
        </div>
    );
}

export default Interstitial;