import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as calculatorActions from "../../store/actions/Calculator";
import { getCustomData } from "../../utils/LifeEventTools/customDataGenerator";
const {
  SAVING_MONTHLY_CONTRIBUTION,
  SAVING_GOAL_COMPARE,
} = require("ef-calculators");

export const useSavings = (uniqueEventID?: string) => {
  const dispatch = useDispatch();
  const loggedUserLifeEventData = useSelector((state: any) => {
    if (state.session.user) {
      return state.session.user.lifeEvents.find(
        (le: any) => le.id === uniqueEventID
      );
    } else {
      return null;
    }
  });

  const defaultData = getCustomData("Savings Goal");

  const [skip, setSkip] = useState(true);

  const [monthlySaving, setMonthlySaving] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.monthlySaving
      : 0
  );
  const [savingGoal, setSavingGoal] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.savingGoal
      : defaultData.savingGoal
  );
  const [annualReturnRate, setAnnualReturnRate] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.annualReturnRate
      : defaultData.annualReturnRate
  );
  const [currentSavings, setCurrentSavings] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.currentSavings
      : defaultData.currentSavings
  );
  const [monthsToGoal, setMonthsToGoal] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.monthsToGoal
      : defaultData.monthsToGoal
  );
  const [desiredMonthlySaving, setDesiredMonthlySaving] = useState(
    loggedUserLifeEventData &&
      Object.keys(loggedUserLifeEventData.customData).length > 0
      ? loggedUserLifeEventData.customData.desiredMonthlySaving
      : 0
  );
  const [desiredInterestRate, setDesiredInterestRate] = useState(
    annualReturnRate
  );

  const [savingProgress, setSavingProgress] = useState([]);

  const [summary, setSummary] = useState({
    savingGoal: savingGoal,
    desiredMonthlySaving: desiredMonthlySaving,
    monthlySaving: monthlySaving,
    monthsToGoal: monthsToGoal,
    desiredMonthsToGoal: monthsToGoal,
    annualReturnRate: annualReturnRate,
    desiredAnnualReturnRate: annualReturnRate,
    currentSavings: currentSavings,
    interestsEarned: 0,
    interestsSaved: 0,
    desiredTotalContribution: savingGoal,
    totalContribution: savingGoal
  });

  useEffect(() => {
    const savingMonthlyContributionCalculate = () => {
      const s = SAVING_MONTHLY_CONTRIBUTION(
        savingGoal,
        currentSavings,
        monthsToGoal,
        annualReturnRate
      );
      setMonthlySaving(Math.round(s));
      if (
        !loggedUserLifeEventData ||
        Object.keys(loggedUserLifeEventData.customData).length === 0
      ) {
        setDesiredMonthlySaving(Math.round(s));
      }
    };
    savingMonthlyContributionCalculate();
  }, []);

  useEffect(() => {
    const savingMonthlyContributionCalculate = () => {
      const s = SAVING_MONTHLY_CONTRIBUTION(
        savingGoal,
        currentSavings,
        monthsToGoal,
        annualReturnRate
      );
      setMonthlySaving(Math.round(s));
      setDesiredMonthlySaving(Math.round(s));
    };
    if (skip) {
      setSkip(false);
    } else {
      savingMonthlyContributionCalculate();
    }
  }, [savingGoal, monthsToGoal, annualReturnRate, currentSavings]);

  useEffect(() => {
    const savingGoalCompareCalculate = () => {
      const c = SAVING_GOAL_COMPARE(
        savingGoal,
        monthsToGoal,
        annualReturnRate,
        currentSavings,
        desiredMonthlySaving,
        desiredInterestRate
      );
      dispatch(
        calculatorActions.savingGoalChangeState(c.summary)
      );
      setSavingProgress(c.savingProgress);
      setSummary(c.summary);
    };
    savingGoalCompareCalculate();
  }, [
    savingGoal,
    monthsToGoal,
    annualReturnRate,
    currentSavings,
    desiredMonthlySaving,
    desiredInterestRate,
  ]);

  return {
    SAVING_MONTHLY_CONTRIBUTION,
    SAVING_GOAL_COMPARE,
    monthlySaving,
    setMonthlySaving,
    savingGoal,
    setSavingGoal,
    annualReturnRate,
    setAnnualReturnRate,
    currentSavings,
    setCurrentSavings,
    monthsToGoal,
    setMonthsToGoal,
    desiredMonthlySaving,
    setDesiredMonthlySaving,
    desiredInterestRate,
    setDesiredInterestRate,
    savingProgress,
    setSavingProgress,
    summary,
    setSummary,
  };
};
