export const ONBOARDING_CHANGE_STATE = "ONBOARDING_CHANGE_STATE";
export const CONNECT_CHANGE_STATE = "CONNECT_CHANGE_STATE";
export const CONNECT_SHOW_SCOOP = "CONNECT_SHOW_SCOOP";
export const CONNECT_SHOW_NUDGET = "CONNECT_SHOW_NUDGET";
export const CONNECT_SHOW_KAKEIBO = "CONNECT_SHOW_KAKEIBO";
export const CONNECT_SHOW_SUMMARY = "CONNECT_SHOW_SUMMARY";
export const LOG_IN_CREDENTIALS_STATUS = "LOG_IN_CREDENTIALS_STATUS";
export const LOG_IN_SHOW_AUTH_CODE = "LOG_IN_SHOW_AUTH_CODE";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
export const SIGN_UP_EMAIL_FROM_LOGIN = "SIGN_UP_EMAIL_FROM_LOGIN";
export const SIGN_UP_SHOW_AVATARS = "SIGN_UP_SHOW_AVATARS";
export const MAIN_REDIRECT_TO_DASHBOARD = "MAIN_REDIRECT_TO_DASHBOARD";
export const SIGN_UP_SHOW_CONFIRMATION_CODE = "SIGN_UP_SHOW_CONFIRMATION_CODE";
export const CONNECT_DISMISS_HINT = "CONNECT_DISMISS_HINT";
export const CONNECT_CHANGE_HINT = "CONNECT_CHANGE_HINT";
export const TRANSFER_MODAL_CHANGE_STATE = "TRANSFER_MODAL_CHANGE_STATE";

export const onboardingChangeState = (showCarousel: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: ONBOARDING_CHANGE_STATE,
        show: showCarousel,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const signUpError = (show: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: SIGN_UP_ERROR,
        error: show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const signUpShowConfirmationCode = () => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: SIGN_UP_SHOW_CONFIRMATION_CODE,
        show: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showSignUpAvatars = (show: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: SIGN_UP_SHOW_AVATARS,
        show: show
      });
    }
    catch (error) {
      console.error(error);
    }
  }
}

export const loginShowAuthCredentials = (show: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: LOG_IN_SHOW_AUTH_CODE,
        show: show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const loginCrendentialStatus = (invalid: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: LOG_IN_CREDENTIALS_STATUS,
        invalid: invalid,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const mainRedirectToDashboard = (redirect: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: MAIN_REDIRECT_TO_DASHBOARD,
        redirect: redirect,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const connectChangeState = (
  type: string,
  newView?: string,
  newKeys?: any
) => {
  return async (dispatch: any, getState: any) => {
    try {
      switch (type) {
        case "back": {
          const currentURL = window.location.href;
          let newCurrentKey = '';

          if (currentURL.includes('scoop')) {
            newCurrentKey = 'Scoop';
          }
          else {
            if (currentURL.includes('mylife')) {
              newCurrentKey = "My Life";
            }
            else {
              newCurrentKey = "My Plans";
            }
          }
          dispatch({
            type: CONNECT_CHANGE_STATE,
            connect_currentKey: newCurrentKey,
            connect_keys: ["Scoop", "My Life", "My Plans"],
          });
          break;
        }
        case "my_life": {
          dispatch({
            type: CONNECT_CHANGE_STATE,
            connect_currentKey: "My Life",
            connect_keys: ["Scoop", "My Life", "My Plans"],
          });
          break;
        }
        case "view": {
          dispatch({
            type: CONNECT_CHANGE_STATE,
            connect_currentKey: newView,
          });
          break;
        }
        case "navigation": {
          dispatch({
            type: CONNECT_CHANGE_STATE,
            connect_currentKey: newView,
            connect_keys: newKeys,
          });
          break;
        }
        case "nudget": {
          dispatch({
            type: CONNECT_CHANGE_STATE,
            connect_currentKey: newView,
            connect_keys: newKeys,
          });
          break;
        }
        case "kakeibo": {
          dispatch({
            type: CONNECT_CHANGE_STATE,
            connect_currentKey: newView,
            connect_keys: newKeys,
          });
          break;
        }
        case "scoop": {
          dispatch({
            type: CONNECT_CHANGE_STATE,
            connect_currentKey: "Scoop",
            connect_keys: ["Scoop", "My Life", "My Plans"],
          });
          break;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const connectShowScoop = (show: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CONNECT_SHOW_SCOOP,
        connect_showScoopDashboards: show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const connectShowNudget = (show: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CONNECT_SHOW_NUDGET,
        connect_showNudget: show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const connectShowKakeibo = (show: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CONNECT_SHOW_KAKEIBO,
        connect_showKakeibo: show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const connectShowSummary = (show: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CONNECT_SHOW_SUMMARY,
        connect_showSummary: show,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export const connectDismissHint = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CONNECT_DISMISS_HINT,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const changeHint = (newHint: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: CONNECT_CHANGE_HINT,
        hint: newHint,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showTransferConfirmedPrompt = (show: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: TRANSFER_MODAL_CHANGE_STATE,
        show: show,
      });
    } catch (error) {
      console.error(error);
    }
  }
};