import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import EFHeader from "../../components/UI/EFHeader/EFHeader";

import { quotes } from '../../assets/content/quotes';
import classes from "./MainMenuPage.module.scss";
import Footer from "../../components/Atomic/Footer/Footer";
import Backdrop from "../../components/Atomic/Backdrop/Backdrop";
import ProfileSwitcher from "../../components/UI/ProfileSwitcher/ProfileSwitcher";
import { eUserSettings } from "../../components/UserSettings/UserSettings";
import maleAvatar from "../../assets/images/maleAvatar.svg";
import davidAvatar from '../../assets/images/davidAvatar.svg';
import helenAvatar from '../../assets/images/helenAvatar.svg';
import owlyAvatar from '../../assets/images/owlyAvatar.svg';
import octoAvatar from '../../assets/images/octoAvatar.svg';
import TagManager from 'react-gtm-module'

const MainMenuPage: React.FC<{}> = (props) => {

  const avatarsR = [{
    id: "3Mxv3XhNNVaQupOzfYBWEN",
    image: davidAvatar,
    name: "David"
  }, {
    id: "4xYF66mqSnuVym8EDvHMRu",
    image: helenAvatar,
    name: "Helen"
  }, {
    id: "6F15h0gu6bK03QdpOaelFo",
    image: owlyAvatar,
    name: 'Owly'
  }, {
    id: "6KYAQizPddYd9XlDrrCmsE",
    image: octoAvatar,
    name: 'Octo'
  }];

  const [currentQuote, setCurrentQuote] = useState(Math.floor(Math.random() * (quotes.length)));
  const dispatch = useDispatch();
  let history = useHistory();

  const user = useSelector((state: any) => state.session.user);
  const realUser = useSelector((state: any) => state.data.realUser);
  const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);
  const [profileTab, setProfileTab] = useState(eUserSettings.PROFILE);

  // Google analytics set up for page
  const tagManagerArgs = {
    dataLayer: {
      userId: realUser?.trackingId,  
      page: 'Main Menu'
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  useEffect(() => {
    const newQuote = Math.floor(Math.random() * (quotes.length));
    if (newQuote !== currentQuote) {
      setCurrentQuote(newQuote);
    }
  }, [history])

  let content = null;

  if (Object.keys(user)?.length > 0) {

    content = (
      <div className={classes.container}>

        {showProfileSwitcher && (
          <Backdrop
            customStyle={{
              position: "fixed",
              overflow: "auto",
            }}
            onClick={() => {
              setProfileTab(eUserSettings.PROFILE);
              setShowProfileSwitcher(false);
            }}
          >
            <ProfileSwitcher
              hide={(hide: boolean) => { }}
              startingProfile={1}
              onSelectedProfile={() => { }}
              profileTab={profileTab}
              showCarousel={false}
              onClose={() => setShowProfileSwitcher(false)}
            />
          </Backdrop>
        )}

        <EFHeader onAvatarClick={() => {
          setShowProfileSwitcher(true)
        }} showSettings userAvatar={user.picture ? user.picture.image : maleAvatar } />
        <div className={classes.form}>
          <div className={classes.title}>
            Hello {user.name.split(" ")[0]}
          </div>
          <div className={classes.subtitle}>
            <div className={classes.message}>
              {quotes[currentQuote].quote}
            </div>
            <div className={classes.author}>{quotes[currentQuote].author}</div>
          </div>
          <div className={classes.cardList}>
            <div className={classes.cardContainer}>
              <div
                id="connectCard"
                onClick={() => {
                  history.push("/connect");
                }}
                className={classes.card}
                style={{ backgroundColor: "#FEB81E" }}
              >
                <div className={classes.titleCard}>CONNECT</div>
              </div>
              <div
                className={classes.cardMenu}
                style={{
                  background: "rgba(254, 219, 141, 0.3)",
                  border: "1px solid #FEB81E",
                  borderTop: "unset"
                }}
              >
                <div>
                  <div className={classes.content}>Get Organized</div>
                  <div className={classes.content}>Link your Accounts</div>
                  <div className={classes.content}>Planning Tools</div>
                </div>
              </div>
            </div>
            <div className={classes.cardContainer}>
              <div
                onClick={() => {
                  history.push("/protect/securevault");
                }}
                className={classes.card}
                style={{ backgroundColor: "#84CDED" }}
              >
                <div className={classes.titleCard}>PROTECT</div>
              </div>
              <div
                className={classes.cardMenu}
                style={{
                  background: "rgba(237, 248, 252, .60)",
                  border: "1px solid #84CDED",
                  borderTop: "unset"
                }}
              >
                <div>
                  <div className={classes.content}>Secure Vault</div>
                  <div className={classes.content}>Insurance Solutions</div>
                </div>
              </div>
            </div>
            <div className={classes.cardContainer}>
              <div
                onClick={() => {
                  history.push("/grow/overview");
                }}
                className={classes.card}
                style={{ backgroundColor: "#AAD17E" }}
              >
                <div className={classes.titleCard}>GROW</div>
              </div>
              <div
                className={classes.cardMenu}
                style={{
                  background: "rgba(240, 247, 231, 0.60)",
                  border: "1px solid #AAD17E",
                  borderTop: "unset"
                }}
              >
                <div>
                  <div className={classes.content}>Retirement Solutions</div>
                  <div className={classes.content}>Support</div>
                  <div className={classes.content}>Solutions for your life</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.rightColumn}>
        </div>
        <Footer />
      </div>
    );
  }

  return content;
};

export default MainMenuPage;
