import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import AddItem from '../AddItem/AddItem';
import * as actions from '../../../store/actions/Users';
import * as aggregationActions from '../../../store/actions/Aggregation';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { addAlpha } from '../../../utils/colorGenerators';

import classes from './EmptyTransactions.module.css';

interface IProps {
    icon: string,
    color: string,
    text: string,
    hideAddAccs?: boolean,
};

const EmptyTransactions: React.FC<IProps> = props => {
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector((state: any) => state.session.user);

    return (
        <Stack style={{ padding: '2rem' }} horizontalAlign='center'>
            {props.icon ? (
                <Stack horizontalAlign='center' verticalAlign='center' className={classes.imgContainer} style={{backgroundColor: addAlpha(props.color, .3)}}>
                    <img src={props.icon} alt='category' className={classes.img} />
                </Stack>
            ) : <></>}
            <EFLabel style={{fontWeight: 'normal', textAlign: 'center'}}>{props.text}</EFLabel>
            {(!props.hideAddAccs && !user.anonymized) ? (
                <>
                    <EFLabel style={{fontWeight: 'normal', margin: '.3rem 0 4rem 0'}}>
                        
                    </EFLabel>
                    <AddItem text='ADD ACCOUNTS' onClick={() => {
                        dispatch(aggregationActions.getFastlinkCredentials(history, false));
                        dispatch(actions.setRedirect(false));
                        history.push('/aggregate');
                    }}/>
                </>
            ) : <></>}
        </Stack>
    )
};

EmptyTransactions.defaultProps = {
    hideAddAccs: false,
}

export default EmptyTransactions;
