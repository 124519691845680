import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import EFToggle from '../../Atomic/EFToggle';
import EFStepperInput from '../EFStepperInput/EFStepperInput';
import { EFTooltip } from '../../Atomic/EFTooltip/EFTooltip';

interface IProps {
    isToggled?: boolean,
    onClickToggle?: () => void,
    label?: string,
    value: number,
    onChangeInput: (val: number, index?: number) => void;
    prefix?: string,
    suffix?: string,
    includeThousandsSeparator?: boolean,
    thousandsSeparatorSymbol?: string,
    allowDecimal?: boolean,
    decimalSymbol?: string,
    decimalLimit?: number,
    allowNegative?: boolean,
    allowLeadingZeroes?: boolean,
    maxVal: number,
    minVal: number,
    dynamicStepEnabled?: boolean,
    numStepper?: number,
    tooltip?: string,
};

const ToggleableEFStepperInput: React.FC<IProps> = props => {

    return (
        <Stack>
            <Stack horizontal horizontalAlign='space-between' style={{width: '27rem'}}>
                <Stack tokens={{ childrenGap: '.5rem' }} verticalAlign="center" horizontal >
                    <EFLabel style={{fontWeight: 'normal' as 'normal'}}>{props.label}</EFLabel>
                    {props.tooltip ? <EFTooltip text={props.tooltip} style={{ marginTop: '.2rem' }} title={props.label} /> : <></>}
                </Stack>
                <EFToggle 
                    isChecked={props.isToggled} 
                    onClick={props.onClickToggle} 
                />
            </Stack>
            {props.isToggled ? (
                <EFStepperInput
                    value={props.value}
                    onChange={props.onChangeInput}
                    prefix={props.prefix}
                    suffix={props.suffix}
                    includeThousandsSeparator={props.includeThousandsSeparator}
                    thousandsSeparatorSymbol={props.thousandsSeparatorSymbol}
                    allowDecimal={props.allowDecimal}
                    decimalSymbol={props.decimalSymbol}
                    decimalLimit={props.decimalLimit}
                    allowNegative={props.allowNegative}
                    allowLeadingZeroes={props.allowLeadingZeroes}
                    minVal={props.minVal} 
                    maxVal={props.maxVal}
                    dynamicStepEnabled={props.dynamicStepEnabled}
                    numStepper={props.numStepper}      
                /> ) : null
            }
        </Stack>
    )
};

ToggleableEFStepperInput.defaultProps = {
    prefix: '$',
};

export default ToggleableEFStepperInput;
