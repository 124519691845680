import React, { useEffect, useState } from 'react';
import classes from './Hint.module.css';
import { BASE_URL, SPACE_ID, API_KEY } from '../../contentful/contenful';

interface Props {
    asset: any;
    id: string;
    onDismissClick?: () => void;
    onActionClick?: () => void;
}

const Hint: React.FC<Props> = props => {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        fetch(`${BASE_URL}/spaces/${SPACE_ID}/entries/${props.id}?access_token=${API_KEY}`)
        .then(response => {
            response.json()
            .then(payload => {
                const fields = payload.fields;
                setTitle(fields.title);
                setDescription(fields.description);
            })
            .catch(error => console.error(error));
        })
        .catch(error => console.error(error));
    }, []);

    return (
        <div className={classes.container} onClick={props.onActionClick}>
            <div className={classes.assetContainer}>
                <img src={props.asset} alt="img"/>
            </div>
            <div className={classes.textContainer}>
                <p className={classes.subtitleText}>{description}</p>
                <p className={classes.titleText}>{title}</p>
            </div>
            <div className={classes.dismissContainer}>
                <p className={classes.dismissText} onClick={(event) => {
                    event.stopPropagation();
                    props.onDismissClick();
                }}>Dismiss</p>
            </div>
        </div>
    );
};

export default Hint;