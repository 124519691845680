import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import EFStepperInput from '../../UI/EFStepperInput/EFStepperInput';
import MaskedLabelPair from '../../UI/MaskedLabelPair';
import ResultReport from '../Common/ResultReport';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import StaticContainer from '../../UI/StaticContainer';
import ConfigButton from '../Common/ConfigButton';
import DividerContainer from '../../UI/Divider/DividerContainer';
import Divider from '../../UI/Divider/Divider';
import LineChart from '../../Charts/LineChart/LineChart';
import EFAccordion from '../../Atomic/EFAccordion/EFAccordion';

import { useStudentLoanBalance } from '../../../hooks/Calculators/useStudentLoanBalance';
import { useConfigButtons } from '../../../hooks/useConfigButtons';

import green_arrow from '../../../assets/icons/green_arrow.svg';
import red_arrow from '../../../assets/icons/red_arrow.svg';
import classes from './StudentDebt.module.css';
import StudentDebtOnboarding from './StudentDebtOnboarding';
import DebtInterstitial from '../../LifeEvents/Interstitials/DebtInterstitial/DebtInterstitial';
import SaveChangesModal from '../../LifeEvents/SaveChangesModal/SaveChangesModal';


interface Props {
    handleModal: { showModal: boolean, exitModal?: () => void },
    currentView: string,
    lifeEventDetails?: any,
}

const StudentDebt: React.FC<Props> = props => {
    const {
        //#region Inputs and Outputs
        // calculator
        LOAN_SIMPLE,
        // set up
        originalDebtAmount, setoriginalDebtAmount, 
        payOffTermInMonths, setpayOffTermInMonths,
        annualInterest, setannualInterest,
        // simu
        desiredMonthlyPayment, setdesiredMonthlyPayment,
        desiredInterestRate, setdesiredInterestRate,
        //output
        simpleMonthly,
        monthlyPaymentBase,
        monthlyPaymentSimulation,
        totalMonthsBase,
        totalMonthsSimulation,
        totalInterestsPaidSimulation,
        totalInterestsPaidBase,
        interestsSavedLoan,
        amortizationSchedule
        //#endregion
    } = useStudentLoanBalance(props.lifeEventDetails.id);

    // Handles debt balance with desired payments dependency on it
    const onChangeBalance = (val: number) => {
        const s = LOAN_SIMPLE(
            val,
            payOffTermInMonths / 12,
            annualInterest
        );
        setoriginalDebtAmount(val);
        setdesiredMonthlyPayment(s.monthlyPayment);
    };

    // Handles monthly term with desired payments dependency on it
    const onChangeTerm = (val: number) => {
        const s = LOAN_SIMPLE(
            originalDebtAmount,
            val / 12,
            annualInterest
        );
        setpayOffTermInMonths(val);
        setdesiredMonthlyPayment(s.monthlyPayment);
    };

    // Handles interest rate with desired payments + desired interest dependencies on it
    const onChangeInterest = (val: number) => {
        const s = LOAN_SIMPLE(
            originalDebtAmount,
            payOffTermInMonths / 12,
            val
        );
        setannualInterest(val);
        setdesiredInterestRate(val);
        setdesiredMonthlyPayment(s.monthlyPayment);
    };

    // Handles Configuration Buttons Logic
    const {setup, onSetup, simu, onSimu, lvl, onLvl} = useConfigButtons();

    // Chart data
    const paymentsMonth = amortizationSchedule.map((r,i) => amortizationSchedule[i][0]);
    const currentProjection = amortizationSchedule.map((r,i) => amortizationSchedule[i][1]);
    const reducedProjection = amortizationSchedule.map((r,i) => amortizationSchedule[i][2]);

    // Handles simulated chart's various states (none, green, red)
    const handleSimulatedData = () => {
        const simulatedData = monthlyPaymentBase === monthlyPaymentSimulation ? null : reducedProjection;
        let simulatedDataColor = 'rgba(247, 175, 2, 0.13)';
        if (monthlyPaymentBase > monthlyPaymentSimulation) {
            simulatedDataColor = 'rgba(243, 121, 141, 0.13)';
        } else if (monthlyPaymentBase < monthlyPaymentSimulation) {
            simulatedDataColor = 'rgba(57, 200, 16, 0.13)';
        }
        return { simulatedData, simulatedDataColor }
    };
     // Handles pay off time different font color states
     const handlePayOffTimeLabelColors = () => {
        let color = '#293861';
        let iconColor = undefined;
        if (totalMonthsSimulation < totalMonthsBase) {
            color = '#38C810';
            iconColor = green_arrow;
        } else if (totalMonthsSimulation > totalMonthsBase) {
            color = '#CF2435';
            iconColor = red_arrow;
        };
        return { color, iconColor };
    };
    // Handles interest payment different font color states
    const handleInterestPaymentLabelColors = () => {
        let color = '#293861';
        let iconColor = undefined;
        if (totalInterestsPaidSimulation < totalInterestsPaidBase) {
            color = '#38C810';
            iconColor = green_arrow;
        } else if (totalInterestsPaidSimulation > totalInterestsPaidBase) {
            color = '#CF2435';
            iconColor = red_arrow;
        };
        return { color, iconColor };
    };
    // Handles interest savings's different font color states
    const handleSavingsLabelColors = () => {
        let color = '#293861';
        let iconColor = undefined;
        if (interestsSavedLoan > 0) {
            color = '#38C810';
            iconColor = green_arrow;
        }
        return { color, iconColor };
    };

    // setup content
    const getYrs = (payOffTermInMonths / 12) | 0;
    const getMonths = payOffTermInMonths % 12;
    const setUpContent = (
        <>
            <EFStepperInput
                label='Student Debt Balance'
                onChange={onChangeBalance}
                value={originalDebtAmount}
                prefix='$'
                includeThousandsSeparator
                dynamicStepEnabled
                minVal={0}
                maxVal={250000}
            />
            <Stack tokens={{childrenGap: 5}}>
                <EFStepperInput
                    label='Loan Term (Months)'
                    onChange={onChangeTerm}
                    value={payOffTermInMonths}
                    minVal={1}
                    maxVal={1000}
                />
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    suffix={`${getMonths ? ' months' : ' years'} payoff time`}
                    prefix={`${getMonths ? getYrs + ' years ' : ''}`}
                    title=''
                    value={getMonths ? getMonths : getYrs}
                    color='#293861'
                    valueFontSize={10}
                />
            </Stack>
            <EFStepperInput
                label='Interest Rate'
                onChange={onChangeInterest}
                value={annualInterest}
                allowDecimal
                numStepper={.25}
                suffix='%'
                minVal={0}
                maxVal={18}
                // was defined as 15 but we have data coming from backend at 18 ?
                tooltip={'Can be up to 18%'}
            />
        </>
    );

    // simu content
    const simuContent = (
        <>
            <Stack tokens={{childrenGap: 5}}>
                <EFStepperInput
                    label='Desired Monthly Payments'
                    onChange={(val: number) => setdesiredMonthlyPayment(val)}
                    value={Math.round(desiredMonthlyPayment)}
                    prefix='$'
                    includeThousandsSeparator
                    dynamicStepEnabled
                    minVal={0}
                    maxVal={100000}
                />
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='Minimum payment: $'
                    title=''
                    value={Math.round(simpleMonthly)}
                    color='#293861'
                    valueFontSize={10}
                />
            </Stack>
            <EFStepperInput
                label='Desired Interest Rate'
                onChange={(val: number) => setdesiredInterestRate(val)}
                value={desiredInterestRate}
                allowDecimal
                numStepper={.25}
                suffix='%'
                minVal={0}
                maxVal={18}
                // was defined as 15 but we have data coming from backend at 18 ?
                tooltip={'Can be up to 18%'}
            />
        </>
    );

    return (
        <>
            <StudentDebtOnboarding 
                handleModal={props.handleModal}
                setDebtAmount={onChangeBalance}
                setPayOffTermInMonths={onChangeTerm}
                setInterest={onChangeInterest} 
            />
            <SaveChangesModal
                openLifeEventSaveModal={props.lifeEventDetails.openSaveModal}
                onClose={props.lifeEventDetails.onOpenSaveModal}
            >
                <DebtInterstitial 
                    lifeEventDetails={props.lifeEventDetails}
                    loanTerm={payOffTermInMonths}
                    onClick={props.lifeEventDetails.onSaveLifeEvent}
                    onCloseClick={props.lifeEventDetails.onOpenSaveModal}
                />
            </SaveChangesModal>
            {props.currentView === 'Connect the dots' && <Stack horizontalAlign='center' tokens={{childrenGap: 20}}>
                <Stack>
                    <Stack horizontal horizontalAlign='center' className={classes.dividercontainer} tokens={{childrenGap: 30}}>
                        <Divider
                            isOpen
                            title='Student Loan'
                            icon={require('../../../assets/icons/debt_icon.svg')}
                        />
                    </Stack>
                    <Stack horizontalAlign='center'>
                            <DividerContainer>
                                <Stack wrap horizontal horizontalAlign='center' tokens={{childrenGap: 30}}>
                                        <Stack className={classes.chart}>
                                            <LineChart
                                                maxXTicksLimit={10}
                                                datasets={{
                                                    labels: paymentsMonth,
                                                    datasets: [
                                                        {
                                                            label: 'Current',
                                                            data: currentProjection,
                                                            backgroundColor: 'rgba(247, 175, 2, 0.13)',
                                                            borderColor: 'rgba(247, 175, 2, 0.13)',
                                                            fill: true,
                                                        },
                                                        {
                                                            label: 'Desired',
                                                            data: handleSimulatedData().simulatedData,
                                                            backgroundColor: handleSimulatedData().simulatedDataColor,
                                                            borderColor: handleSimulatedData().simulatedDataColor,
                                                            fill: true,
                                                        },
                                                    ]
                                                }}
                                            />
                                        </Stack>
                                        <Stack horizontalAlign='center' tokens={{childrenGap: 20}} className={classes.report}>
                                            <ResultReport
                                                isGreen={monthlyPaymentBase < monthlyPaymentSimulation}
                                                onChange={() => null}  
                                                reportGreenLabel='Paying down your debt balance can help you save money'
                                                reportRedLabel='Paying down your debt balance can help you save money'
                                            />
                                            <Stack tokens={{childrenGap: 15}} style={{width: '30rem', marginTop: '5rem'}}>
                                                <MaskedLabelPair
                                                    isBulleted={false}
                                                    includeThousandsSeparator
                                                    title='Debt Balance'
                                                    value={originalDebtAmount}
                                                    color='#293861'
                                                />
                                                <MaskedLabelPair
                                                    isBulleted={false}
                                                    includeThousandsSeparator={false}
                                                    prefix=''
                                                    suffix=' Months'
                                                    title='Pay Off Time'
                                                    value={totalMonthsSimulation}
                                                    color={handlePayOffTimeLabelColors().color}
                                                    includeIcon={handlePayOffTimeLabelColors().iconColor}
                                                    notAmt
                                                />
                                                <MaskedLabelPair
                                                    isBulleted={false}
                                                    includeThousandsSeparator
                                                    prefix='$'
                                                    title='Interest Payment'
                                                    value={totalInterestsPaidSimulation}
                                                    color={handleInterestPaymentLabelColors().color}
                                                    includeIcon={handleInterestPaymentLabelColors().iconColor}
                                                />
                                                <MaskedLabelPair
                                                    isBulleted={false}
                                                    includeThousandsSeparator
                                                    prefix='$'
                                                    title='Interest Savings'
                                                    value={(interestsSavedLoan < 0) ? 0 : interestsSavedLoan}
                                                    color={handleSavingsLabelColors().color}
                                                    includeIcon={handleSavingsLabelColors().iconColor}
                                                />
                                            </Stack>
                                        </Stack>
                                </Stack>
                            </DividerContainer>
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign='center' tokens={{childrenGap: 40}}>
                    <ConfigButton type='setup' toggleFocus={setup} onClick={onSetup} />
                    <ConfigButton type='simu' toggleFocus={simu} onClick={onSimu} />
                    <ConfigButton type='levelup' toggleFocus={lvl} onClick={onLvl} />
                </Stack>
                {setup && (
                    <Stack horizontal horizontalAlign='center' tokens={{childrenGap: 10}}>
                        <EFAccordion title=''>
                            {setUpContent}
                        </EFAccordion>
                    </Stack>
                )}
                {simu && (
                    <Stack horizontal horizontalAlign='center' tokens={{childrenGap: 10}}>
                        <EFAccordion title=''>
                            {simuContent}
                        </EFAccordion>
                    </Stack>
                )}
                {lvl && (
                    <Stack horizontal horizontalAlign='center'>
                        <EFLabel style={{color: '#9EA4B3', margin: 40}}>No advanced options</EFLabel>
                    </Stack>
                )}
            </Stack>}
            {props.currentView === 'Low Down' && <StaticContainer url="https://content.equalfuture.com/blog/Student%20Debt/lowdown" />}
            {props.currentView === 'Nitty Gritty' && <StaticContainer url="https://content.equalfuture.com/blog/Student%20Debt/nittygritty" />}
        </>
    )
}

export default StudentDebt;
