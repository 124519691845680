import React, { useState } from 'react';
import EFButton from '../../../Atomic/EFButton/EFButton';

import classes from './GeneralInterstitial.module.css';

import close from '../../../../assets/images/close.svg';
import moment from 'moment';
import FabricCalendar from '../../../Atomic/Calendar/FabricCalendar/FabricCalendar';

interface Props {
    lifeEventDetails?: any;
    onClick?: (formData) => void;
    onCloseClick?: () => void;
};

const GeneralInterstitial: React.FC<Props> = props => {
    const initDate = moment().valueOf();
    const [formData, setFormData] = React.useState(
        { 
            nickName: props.lifeEventDetails.name, 
            startDate: props.lifeEventDetails?.startDate || initDate, 
            date: props.lifeEventDetails?.date || initDate 
        }
    );
    const [disable, setDisable] = useState(false);

    return (
        <div className={classes.container}>
            <div className={classes.closeContainer}>
                <img src={close} alt="close" style={{ width: '3%', cursor: 'pointer' }}
                    onClick={props.onCloseClick} />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <div className={classes.titleContainer}>
                    <img src={props.lifeEventDetails.icon[0].url} alt="le" style={{ width: '15%' }} />
                    <p className={classes.title}>{props.lifeEventDetails.name}</p>
                </div>
                <div className={classes.contentContainer}>
                    <p className={classes.content} style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
                        When do you plan on starting this event?
                    </p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-evenly', alignItems: 'center',
                        paddingTop: '5rem',
                        paddingBottom: '15rem',
                        width: '75%'
                    }}>
                        <div style={{
                            width: '35%',
                            display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                            border: '1px solid #D5D4CF',
                            padding: '0.4rem',
                            borderRadius: '7px'
                        }}>
                            <FabricCalendar
                                selectedDate={formData.startDate} 
                                strFormat="MM/DD/YYYY" isDayPickerVisible={false}
                                onSelectDate={d => 
                                    setFormData({ ...formData, "startDate": moment(d).valueOf(), "date": moment(d).valueOf() })
                                }
                                placeholder="Start Date" 
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <EFButton 
                        text="SAVE" 
                        isDisabled={disable} 
                        onClick={() => { props.onClick(formData); setDisable(true) } } 
                        style={{ width: '16rem' }} />
                </div>
            </div>
        </div >
    );
};

export default GeneralInterstitial;

// if (currentSlide === 1) {
//     content = (
//         <>
//             <p className={classes.content} style={{ paddingTop: '15rem' }}>
//                 Would you like to add this Life Event
//             </p>
//             <p className={classes.content} style={{ paddingBottom: '5rem' }}>
//                 to your Smart Cashflow now?
//             </p>
//         </>
//     );

//     buttons = (
//         <>
//             <EFButton text="YES" onClick={() => {
//                 setCurrentSlide(2);
//             }} style={{ width: '16rem' }} />
//             <EFButton text="NO" onClick={() => {

//             }} style={{ width: '16rem' }} />
//         </>
//     )
// }

// if (currentSlide === 2) {
//     //TODO: Insert Sudeep's inputs
//     content = (<>
//         <p className={classes.content} style={{ paddingTop: '5rem', paddingBottom: '15rem' }}>
//             The following expenses will be used
//         </p>
//         <p style={{
//             fontFamily: 'brandon-grotesque',
//             fontSize: '1.4rem', color: '#293861', paddingBottom: '2rem'
//         }}>You can link expenses manually anytime</p>
//     </>);

//     buttons = (
//         <EFButton text="NEXT" onClick={() => {
//         }} style={{ width: '16rem' }} />
//     )
// }