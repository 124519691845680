import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Label } from 'office-ui-fabric-react/lib/';

import * as actions from '../../../store/actions/Users';
import * as aggregationActions from '../../../store/actions/Aggregation';
import EFLabel from '../../Atomic/EFLabel/EFLabel';

import Accordion from '../SettingsAccordion/SettingsAccordion';
import Modal from 'react-modal';
import EFButton from '../../Atomic/EFButton/EFButton';
import { Stack } from 'office-ui-fabric-react';
import EFCheckbox from '../../Atomic/EFCheckbox/EFCheckbox';
import { useResponsive } from '../../../hooks/useResponsive';
import { useHistory } from "react-router-dom";
import { useMutation } from '@apollo/react-hooks';
import { ADD_ACC_SETTINGS } from '../../../hooks/Apollo/mutations';

import classes from './ConnectedAccounts.module.css';
import edit from '../../../assets/images/accEdit.svg';
import bank from '../../../assets/icons/bank.svg';
import exit from '../../../assets/icons/closeicon.svg';
import { GET_ACC_BALANCES, GET_TRANSACTIONS } from '../../../hooks/Apollo/queries';
import { elevenMonthsAgo, currMonth } from '../../../utils/dateTools';

interface AccountDetails {
    accountName: string;
    accountType: string;
    amount: string;
    accountStatus: string;
    id: string;
}
interface Props {
    hide?: (hide: boolean) => void;
}

const initDBCheckboxes = {
    cashflow: {
        id: 'cashflow',
        name: 'Netcash',
        userEnabled: false,
        isDisabled: false,
    },
    networth: {
        id: 'networth',
        name: 'Networth',
        userEnabled: false,
        isDisabled: false,
    },
    income: {
        id: 'income',
        name: 'Income',
        userEnabled: false,
        isDisabled: false,
    },
    saving: {
        id: 'saving',
        name: 'Savings',
        userEnabled: false,
        isDisabled: false,
    },
    retirement: {
        id: 'retirement',
        name: 'Retirement',
        userEnabled: false,
        isDisabled: false,
    },
    investment: {
        id: 'investment',
        name: 'Investment',
        userEnabled: false,
        isDisabled: false,
    },
    expense: {
        id: 'expense',
        name: 'Expenses',
        userEnabled: false,
        isDisabled: false,
    },
    debt: {
        id: 'debt',
        name: 'Debt',
        userEnabled: false,
        isDisabled: false,
    },
};

const dateYrAgo = elevenMonthsAgo.format("YYYY-MM-01");
const dateCurr = currMonth.format("YYYY-MM-DD");
const queryVariables = { fromDate: dateYrAgo, toDate: dateCurr };

const ConnectedAccounts: React.FC<Props> = props => {
    let history = useHistory();
    const dispatch = useDispatch();
    const loggedUser = useSelector((state: any) => state.session.user);
    const providers = [...new Set(loggedUser.connectedAccounts.map((acc: any) => acc.providerName))];
    const { screenIsAtMost, screenIsAtLeast } = useResponsive();

    const [openModal, setModal] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [checkboxes, setCheckboxes] = React.useState(initDBCheckboxes);

    const [addAccSettings] = useMutation(ADD_ACC_SETTINGS, {
        refetchQueries: [
            { query: GET_ACC_BALANCES, variables: queryVariables },
            { query: GET_TRANSACTIONS, variables: queryVariables },
        ]
    });

    const renderEmpty = () => {
        return (
            <div className={classes.container}>
                <img className={classes.bankImg} src={bank} alt="bank" />
                <div className={classes.noAccounts}>
                    You don’t have any connected account yet
                </div>
                <Label className={classes.font_outer} style={{ cursor: 'pointer' }} onClick={() => {
                    dispatch(aggregationActions.getFastlinkCredentials(history, false));
                    history.push('/aggregate');
                }}>Connect an account</Label>
            </div>
        )
    };

    const renderAccounts = () => {

        const hasCreditCards = accounts => {
            return accounts.some(acc => Boolean(acc.availableCredit))
        }
            
        return (
            <>
                <div className={classes.container}>
                    {providers.map((provider: string) => {
                        const accounts = loggedUser.connectedAccounts.filter(acc => provider === acc.providerName)
                        .sort((accA, accB) => {
                            const a = ( accA.availableCredit && accA.availableCredit.amount ) || 0;
                            const b = ( accB.availableCredit && accB.availableCredit.amount ) || 0;
                            return b-a;
                        });
                        return (
                            <div key={provider} className={classes.card}>
                                <EFLabel style={{ fontSize: '2rem' }}>{provider}</EFLabel>
                                <Stack horizontal horizontalAlign={screenIsAtMost('mobile') ? 'center' : 'end'} style={{ width: '81%' }}>
                                    {hasCreditCards(accounts) && <EFLabel style={{ fontWeight: 'normal', fontSize: '1.8rem', marginRight: '3rem' }}>Available Credit</EFLabel> }
                                    <EFLabel style={{ fontWeight: 'normal', fontSize: '1.8rem' }}>Balance</EFLabel>
                                </Stack>
                                {accounts.map(account => (
                                    <div key={account.id}>
                                        <Accordion
                                            isHorizontal={screenIsAtMost('mobile') ? false : true}
                                            title={account.displayedName}
                                            value={account.balance?.amount}
                                            value2={account.availableCredit ? account.availableCredit.amount : 0}
                                            id={account.id}>
                                            {renderAccountDetails(account)}
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        )
                    })}
                    <Label className={classes.font_outer} style={{ cursor: 'pointer' }} onClick={() => {
                        dispatch(aggregationActions.getFastlinkCredentials(history, false));
                        history.push('/aggregate');
                    }}>Connect more accounts</Label>
                </div>
                {dashboardModal()}
            </>
        )
    };

    const renderAccountDetails = (selectedAccount: any) => {
        const showModal = (selectedAccount: any) => {
            const userEnabled = selectedAccount && selectedAccount.dashboards;
            const disabled = selectedAccount && selectedAccount.dashboardSettings.disabled;
            let newCheckboxes = initDBCheckboxes;
            
            for (let i = 0; i < Object.keys(initDBCheckboxes).length; i++) {
                const currKey = Object.keys(initDBCheckboxes)[i];
                newCheckboxes[currKey].userEnabled = false;
                newCheckboxes[currKey].isDisabled = false;

                for (let e = 0; e < userEnabled.length; e++) {
                    if (currKey === userEnabled[e]) {
                        newCheckboxes[currKey].userEnabled = true;
                        newCheckboxes[currKey].isDisabled = false;
                    }
                }
                for (let d = 0; d < disabled.length; d++) {
                    if (currKey === disabled[d]) {
                        newCheckboxes[currKey].isDisabled = true;
                        newCheckboxes[currKey].userEnabled = false;
                    }
                }
            }
            setCheckboxes(newCheckboxes);
            setCurrentAccount(selectedAccount);
            setModal(true);
            props.hide(true);
        }
        
        return (
            <div className={classes.cardDetail}>
                <div>
                    <div className={classes.selectedAccount} key={selectedAccount.accountName}>
                        <div className={classes.selected}>
                            <div className={classes.selectedPanel}>
                                <div className={classes.selectedPanelLabel}>TYPE</div>
                                <div className={classes.selectedPanelValue}>{selectedAccount.accountTypeName}</div>
                            </div>
                            <div className={classes.selectedPanel} style={{ marginLeft: '2rem' }}>
                                <div className={classes.selectedPanelLabel}>STATUS</div>
                                <div className={classes.selectedPanelValue}>{selectedAccount.accountStatus}</div>
                            </div>
                            <img onClick={() => showModal(selectedAccount)} className={classes.editIcon} alt='editIcon' src={edit} />
                        </div>
                    </div>
                </div>
                <div onClick={(event) => {
                    event.stopPropagation()
                    dispatch(actions.deleteConnectedAccount(selectedAccount.id, history));
                }} className={classes.deleteBtn}>Delete Account</div>
            </div>
        )
    }

    const dashboardModal = () => {
        const providerName = currentAccount && currentAccount.providerName;
        const displayedName = currentAccount && currentAccount.displayedName;

        const onChange = (currBox: any, ev: React.FormEvent<HTMLElement>, checked: boolean): void => {
            const currBoxId = (ev.currentTarget as HTMLTextAreaElement).id;
            const newBox = { ...currBox, userEnabled: checked };
            setCheckboxes({ ...checkboxes, [currBoxId]: newBox });
        };
    
        const leaveModal = () => {
            setModal(false);
            props.hide(false);
        }

        const onClickDone = () => {
            const getUserEnabledDBs 
                = Object.keys(checkboxes)
                        .filter(key => checkboxes[key].userEnabled)
                         .map(type => checkboxes[type].id);
            addAccSettings({
                variables: {
                    accountContainer: currentAccount.CONTAINER,
                    accountType: currentAccount.accountType,
                    providerId: currentAccount.providerId,
                    dashboards: getUserEnabledDBs,
                }
            }).catch(error => console.error(error));
            dispatch(actions.getUpdatedConnectedAccounts(history));
            leaveModal();
        }

        return (
            <Modal
                isOpen={openModal}
                onRequestClose={leaveModal}
                style={modalCardStyle}>
                    <Stack className={`${classes.modalCard} ${!screenIsAtLeast('tabPort') && classes.modalCardMobile}`}>
                        <Stack onClick={leaveModal} style={{margin: '2rem 3rem 0 2rem'}} horizontalAlign='end'>
                            <img className={classes.exitIcon} alt='exit' src={exit} />
                        </Stack>
                        <Stack className={classes.modalTitle} horizontalAlign='center'>
                            <EFLabel style={{fontSize: '2.4rem', color: '#2695C7'}}>SHOW ACCOUNT</EFLabel>
                        </Stack>
                        <Stack className={screenIsAtLeast('tabPort') ? classes.innerModalContainer : classes.innerModalContainerMobile}>
                            <Stack className={classes.modalLabels} tokens={{childrenGap: '2.3rem'}} horizontalAlign='center'>
                                <EFLabel style={{fontSize: '2.4rem'}}>{providerName}</EFLabel>
                                <EFLabel style={{fontSize: '2rem'}}>{displayedName}</EFLabel>
                                <EFLabel style={{fontSize: '1.5rem', width: '22rem', height: '4.2rem', color: '#9EA4B3', textAlign: 'center'}}>
                                    Select the dashboards where you want the account to be implemented
                                </EFLabel>
                            </Stack>
                            <Stack>
                                {Object.keys(checkboxes).map((key, i) => (
                                    <div key={Math.random()}>
                                        <EFCheckbox
                                            id={checkboxes[key].id}
                                            stackClassName={classes.checkbox}
                                            label={checkboxes[key].name}
                                            checked={checkboxes[key].userEnabled} 
                                            disabled={checkboxes[key].isDisabled} 
                                            onChange={onChange.bind({}, checkboxes[key])} 
                                        />
                                        <div className={classes.checkboxBreaker} />
                                    </div>
                                ))}
                            </Stack>
                        </Stack>
                        <Stack horizontalAlign='center' tokens={{childrenGap: 30}} style={{marginTop: 'auto'}}>
                            <EFButton text='DONE' onClick={onClickDone} style={{borderColor: '#2695C7', backgroundColor: '#2695C7', width: '13.2rem', height: '3.5rem'}} />
                        </Stack>
                    </Stack>
            </Modal>
        )
    }

    return (loggedUser.connectedAccounts.length > 0 ? renderAccounts() : renderEmpty());
}

const modalCardStyle = {
    content: {
        justifyContent: 'center' as 'center',
        alignItems: 'middle' as 'middle',
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: 'fit-content',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
        marginTop: '2rem',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)'
    }
}

export default ConnectedAccounts;
