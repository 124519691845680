import React from 'react';
import { Label } from 'office-ui-fabric-react';
import { getLifeEventNameById, getTextColorAndBckgImgFromName } from '../../utils/LifeEventTools/Mappers';
import animations from '../../utils/animations.module.css';
import smallGreenCheckmark from '../../assets/images/smallGreenCheckmark.svg';
import notCompleted from '../../assets/images/notCompleted.svg';

interface Props {
    le: any,
    id: any,
    title: string,
    owner: string,
    avatar: any,
    statusString: string,
    isCompleted?: boolean,
    onCardClickHandler: (event: any, info: any) => void,
    customStyle?: Object,
    customBadgeStyle?: Object ,
    customContentStyle?: Object,
    customImgStyle?: Object,
    customLabelStyle?: Object
}

const PlanCard: React.FC<Props> = props => {

    const cardAssets = getTextColorAndBckgImgFromName(getLifeEventNameById(props.le.externalId));

    const cardStyle = {
        color: '#293861',
        fontFamily: 'brandon-grotesque' as 'brandon-grotesque',
        borderRadius: 15,
        backgroundColor: '#fff',
        backgroundImage: `url(${cardAssets.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.11)',
        width: '75%',
        minWidth: '30rem',
        maxWidth: '41.75rem',
        flex: 1,
        margin: '1.5rem',
        padding: '2.2rem 1.2rem 2.3rem 1.2rem',
        alignItems: 'center',
        overflow: 'hidden',
        display: 'flex',
        cursor: 'pointer'
    };

    return (
        <div className={animations.cardShadowExpand} key={props.id} style={{ ...cardStyle, ...props.customStyle}} onClick={event => {
            props.onCardClickHandler(event, {
                name: props.le.name,
                status: props.le.status,
                id: props.le.id,
                icon: props.le.icon,
                externalId: props.le.externalId
            });
        }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '95%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', ...props.customContentStyle }}>
                    <img style={{ height: '3.2rem', width: '3.2rem', ...props.customImgStyle }} alt='event' src={props.avatar} />
                    <div style={{ marginLeft: '1.2rem' }}>
                        <Label style={{ fontSize: '1.2rem', color: '#293861', fontFamily: 'brandon-grotesque', fontWeight: 'normal', ...props.customLabelStyle }}>{props.title}</Label>
                        <Label style={{ fontSize: '1.3rem', color: cardAssets.color, fontFamily: 'brandon-grotesque', fontWeight: 900,  }}>{props.statusString}</Label>
                    </div>
                </div>
                <div>
                    <img style={{ width: '5rem', ...props.customBadgeStyle }} src={props.isCompleted ? smallGreenCheckmark : notCompleted} alt="completed" />
                </div>
            </div>
        </div>
    );
};

export default PlanCard;