/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { sessionService } from "redux-react-session";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Stack } from "office-ui-fabric-react";
import ProfileSwitcher from "../../components/UI/ProfileSwitcher/ProfileSwitcher";
import Footer from "../../components/Atomic/Footer/Footer";
import NavigationView from "../../components/UI/NavigationView";
import DashboardContainer from "../../components/UI/DashboardContainer/DashboardContainer";
import Backdrop from "../../components/Atomic/Backdrop/Backdrop";
import EFCard from "../../components/Atomic/EFCard/EFCard";
import Greeting from "../../components/UI/Greeting";
import SummaryWidget from '../../components/Summary/SummaryWidget';
import { useResponsive } from "../../hooks/useResponsive";
import { useScroll } from "../../hooks/useScroll";
import interstitialImage from '../../assets/images/interscf3.svg';
import planForFutureMobile from '../../assets/images/planForFutureMobile.svg'
import { handleSideNavigation, isCanonical } from "../../utils/global";

import Hint from "../../components/Hint/Hint";

import maleAvatar from "../../assets/images/maleAvatar.svg";
import staceyAvatar from "../../assets/images/staceyAvatar.png";
import rosaAvatar from "../../assets/images/rosaAvatar.png";
import darylAvatar from "../../assets/images/darylAvatar.png";
import martinAvatar from "../../assets/images/martinAvatar.svg";
import eveAvatar from "../../assets/images/eveAvatar.svg";
import circleAdd from "../../assets/images/circleAdd.svg";
import addToPlaylistButtonWidget from '../../assets/images/planning/playlist/addToPlaylistWidgetButton.svg';

import classes from "./ConnectDashboardPage.module.css";
import animations from "../../utils/animations.module.css";
import * as actions from "../../store/actions/Users";
import * as careerActions from "../../store/actions/CareerPlanning";
import * as aggregationActions from "../../store/actions/Aggregation";
import { saveUser } from "../../store/actions/Data";
import AddAccountsModal from "../../components/UI/Modals/AddAccountsModal/AddAccountsModal";
import { eUserSettings } from "../../components/UserSettings/UserSettings";
import { hintsEntries } from "../../contentful/contenful";

import { connectDismissHint } from "../../store/actions/Screens";
import LifeEventPicker from "../../components/LifeEvents/LifeEventPicker/LifeEventPicker";
import PlaylistWidget from "../../components/Planning/Playlist/PlaylistWidget";
import ConnectTour from "../../components/TourGuides/Connect/ConnectTour";
import { duplicationAllowed } from "../../utils/LifeEventTools/Mappers";
import OkCancelDialog from "../../components/UI/OkCancelDialog/OkCancelDialog";
import { workEvents } from "../../components/LifeEvents/LifeEventsData";
import { usePersistentApolloState } from "../../hooks/Apollo/usePersistentApolloState";
import GetOrganizedCard from "../../components/GetOrganized/GetOrganizedCard";
import TagManager from 'react-gtm-module'

const ConnectDashboardPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [currentHint, setCurrentHint] = useState(-1); //useSelector((state: any) => state.screens.connect_currentHint);
  const { state: transactionsData } = usePersistentApolloState("transactionsState");
  const { state: transcationCategoriesData } = usePersistentApolloState("transactionsCategoriesState");
  const { state: balancesData } = usePersistentApolloState("balancesState");

  let history = useHistory();

  useEffect(() => {
    sessionService
      .loadUser()
      .then((response) => {
        const careerGoalLE = response.lifeEvents.find(
          (le: any) => le.externalId === "6ceIi2ikmezTJtxIDEuRsg" && le.status !== "NEW"
        );
        if (careerGoalLE) {
          const getOnetCode = careerGoalLE.customData.selectedCareerOnetCode;
          dispatch(careerActions.getJobInfo(getOnetCode));
        }
        dispatch(saveUser(response));
        sessionService
          .saveUser(response)
          .then((response) => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
    actions.getAccountsTransactions(history);
  }, [dispatch, history]);


  const user = useSelector((state: any) => state.session.user);
  const realUser = useSelector((state: any) => state.data.realUser);
  const selectedJob = useSelector((state: any) => state.career.jobInfo);
  const numConnectedAccounts =
    Object.keys(user) && Object.keys(user)?.length > 0
      ? user?.connectedAccounts?.length
      : 0;

  // Modals
  const [openLEModal, setLEModal] = useState(false);
  const [openAccsModal, setAccsModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [affordabilityRouteBlocker, setAffordabilityRouteBlocker] = useState(false);

  // Checks to see if user is allowed to enter affordability (user can't if they don't have career goal set up)
  const checkCareerGoalForAffordability = (externalId: string, job: any) => externalId === '15cY0o7S7hwiHdzTNLxNJL' && !job;

  // Closes modal and other apps on press of back button
  useEffect(() => {
    window.onpopstate = () => {
      setLEModal(false);
      setAccsModal(false);
      setModalIsOpen(false);
    }
  }, [window]);

  //import TagManager from 'react-gtm-module'
  // Google analytics set up for page
  const tagManagerArgs = {
    dataLayer: {
        userId: realUser?.trackingId,
        page: 'Connect'
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);
  const [hideOnAccSettings, setHideOnAccSettings] = useState(false);
  const [showCarousel, setShowCarousel] = useState(true);

  const [planId, setPlanId] = useState(""); //Couldn't find another way...

  const currentView = useSelector(
    (state: any) => state.screens.connect_currentKey
  );

  const { screenIsAtLeast, screenIsAtMost } = useResponsive();

  const [profileTab, setProfileTab] = useState(eUserSettings.PROFILE);

  const openConnectedAccounts = () => {
    if (user.anonymized) return;
    setProfileTab(eUserSettings.CONNECTED_ACCOUNTS);
    setShowProfileSwitcher(true);
    setShowCarousel(false);
  };

  //handles Hint display
  useEffect(() => {
    if (user.hints && Object.keys(user.hints).length > 0) {
      let newHint = -1;
      if (user.hints["connect.my-life"].enabled) {
        newHint = 0;
      } else {
        if (user.hints["connect.scoop"].enabled) {
          newHint = 1;
        } else {
          if (user.hints.profile.enabled) {
            newHint = 2;
          } else {
            if (user.hints["connect.nudget"].enabled) {
              newHint = 3;
            } else if (user.hints["connect.kakeibo"].enabled) {
              newHint = 4;
            }
          }
        }
      }
      if (newHint >= 0 && newHint > currentHint) {
        //dispatch(changeHint(newHint));
        setCurrentHint(newHint);
      }
    }
  }, [user.hints, currentHint]);

  useScroll(modalIsOpen);

  const handleLEModal = () => {
    setLEModal(!openLEModal);
    window.scrollTo(0, 0);
    if (planId !== "") {
      setPlanId("");
    }
  };

  const handleAccsModal = () => {
    setAccsModal(!openAccsModal);
    window.scrollTo(0, 0);
  };

  const cardWidgetClickHandler = (event: any) => {
    // Handles Affordability (when Career Goal has not been set up) case
    if (checkCareerGoalForAffordability(event.externalId, selectedJob)) {
      setAffordabilityRouteBlocker(true);
      return
    }

    history.push('/connect/lifeevent/' + event.id);
  }

  const lifeEventPickerClickHandler = (le: any) => {
    const isDuplicable = duplicationAllowed(le.id);
    const existingLE = user.lifeEvents?.find((e: any) => e.externalId === le.id);

    // Handles Affordability (when Career Goal has not been set up) case
    if (checkCareerGoalForAffordability(le.id, selectedJob)) {
      setAffordabilityRouteBlocker(true);
      return
    }

    if (!isDuplicable && existingLE) {
      history.push('/connect/lifeevent/' + existingLE.id);
    } else {
      const payload = {
        status: "NEW",
        name: le.title,
        icon: [{
          url: le.img
        }],
        id: "",
        externalId: le.id,
        relatedPlanId: planId
      }

      if (planId !== "") {
        setPlanId("");
      }

      history.push({
        pathname: '/connect/lifeevent',
        state: payload
      });
    }
  }

  const onProfileSwitch = (name: string) => {
    //we check if the profile that we are going to render has the Career Goal LE
    //we know that for CP, only Stacey has it
    if (name !== "" && name !== "STACEY") {
      dispatch(careerActions.removeCurrentJob());
    }
    else {
      if (name === "STACEY") {
        dispatch(careerActions.getJobInfo(15 - 1299.08));
      }
      else {
        const careerGoalLE = realUser.lifeEvents.find(
          (le: any) => le.externalId === "6ceIi2ikmezTJtxIDEuRsg" && le.status !== "NEW"
        );
        if (careerGoalLE) {
          const getOnetCode = careerGoalLE.customData.selectedCareerOnetCode;
          dispatch(careerActions.getJobInfo(getOnetCode));
        } else {
          dispatch(careerActions.removeCurrentJob());
        }
      }
    }

    setProfileTab(eUserSettings.PROFILE);
    setShowProfileSwitcher(false);
  };

  let profiles = [
    {
      name: "Stacey",
      img: staceyAvatar,
      label: "Planning for College",
      profileNumber: 2,
    },
    {
      name: "Rosa",
      img: rosaAvatar,
      label: "Begin Career",
      profileNumber: 3,
    },
    {
      name: "Daryl",
      img: darylAvatar,
      label: "Tools for Parents",
      profileNumber: 4,
    },
    {
      name: "Martin",
      img: martinAvatar,
      label: "Buy a House",
      profileNumber: 5,
    },
    {
      name: "Eve",
      img: eveAvatar,
      label: "Planning for Retirement",
      profileNumber: 6,
    },
  ];

  let content = null;

  if (Object.keys(user).length > 0 && !isLoading) {
    let selectCardContent = {
      name: user.name,
      img: !user.anonymized
        ? maleAvatar
        : user.name.length > 0
          ? profiles.filter(
            (profile) =>
              profile.name.toLowerCase() === user.name.toLowerCase()
          )[0].img
          : "",
      label: !user.anonymized
        ? ""
        : user.name.length > 0
          ? profiles.filter(
            (profile) =>
              profile.name.toLowerCase() === user.name.toLowerCase()
          )[0].label
          : "",
      profileNumber: !user.anonymized
        ? 2
        : profiles.filter(
          (profile) =>
            profile.name.toLowerCase() === user.name.toLowerCase()
        )[0].profileNumber - 1
    };
    

    content = (
      <div
        className={`${screenIsAtMost('tabLand') ? classes.containerMobile : [classes.container, classes.treeBackground].join(' ')}`}
      >

        <ConnectTour />

        {affordabilityRouteBlocker && (
          <OkCancelDialog
            isOpen={affordabilityRouteBlocker}
            onClose={() => setAffordabilityRouteBlocker(false)}
            onConfirmClick={() => lifeEventPickerClickHandler(workEvents[0])}
          />
        )}

        {openLEModal &&
          <LifeEventPicker
            onBackdropClick={handleLEModal} onExitClick={handleLEModal}
            onLifeEventClick={lifeEventPickerClickHandler} openModal={openLEModal} />
        }

        {openAccsModal && (
          <Backdrop
            customStyle={{ overflowY: "scroll" }}
            onClick={handleAccsModal}
          >
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              style={
                screenIsAtLeast("tabPort")
                  ? { width: "100%", margin: "10rem" }
                  : { width: "96%", margin: "2%" }
              }
            >
              <AddAccountsModal onClickExit={handleAccsModal} />
            </Stack>
          </Backdrop>
        )}

        {showProfileSwitcher && (
          <Backdrop
            customStyle={{
              position: hideOnAccSettings ? "unset" : "fixed",
              overflow: "auto",
            }}
            onClick={() => {
              setShowCarousel(true);
              setProfileTab(eUserSettings.PROFILE);
              setShowProfileSwitcher(false);
            }}
          >
            <ProfileSwitcher
              hide={(hide: boolean) => setHideOnAccSettings(hide)}
              startingProfile={selectCardContent.profileNumber}
              onSelectedProfile={onProfileSwitch}
              profileTab={profileTab}
              showCarousel={showCarousel}
              onClose={() => {
                setShowCarousel(true);
                setShowProfileSwitcher(false)}}
            />
          </Backdrop>
        )}

        <div
          style={{ display: hideOnAccSettings && "none", flexDirection: screenIsAtMost('tabLand') ? 'column' : 'row', alignItems: screenIsAtMost('tabLand') ? 'center' : '' }}
          className={classes.content}
        >
          {screenIsAtLeast("desktop") && (
            <NavigationView
              profile={user.anonymized}
              connectedAccounts={numConnectedAccounts}
              onConnectedLinkClick={(event, item) => {
                handleSideNavigation(item.name, history)
              }}
              connectedKey=""
              onConnectButtonClick={() => history.push("/connect")}
              onProtectButtonClick={() => history.push("/protect/securevault")}
              onGrowButtonClick={() => history.push("/grow/overview")}
              onConnectAccountClick={openConnectedAccounts}
              currentDashboard="CONNECT"
              selectCardContent={selectCardContent}
              onSelectBubleClickHandler={() => {
                setProfileTab(eUserSettings.PROFILE);
                setShowProfileSwitcher(true);
              }}
            />
          )}

          {screenIsAtMost("tabLand") &&
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '85%', paddingTop: '3rem' }}>

              <Greeting
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={numConnectedAccounts}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                showOnlySalutation
                onSelectBubleClickHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
              />

              <Greeting
                hideMenuLogo
                hideSalutation
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={numConnectedAccounts}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
              />
            </div>

          }

          <DashboardContainer childrenGap={30}>
            <div
              style={{
                width: "100%", display: 'flex',
                justifyContent: 'space-between', alignItems: 'center'
              }}
            >

              {!screenIsAtMost("tabLand") && <Greeting
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={numConnectedAccounts}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                showOnlySalutation
              />}

              {!screenIsAtMost("tabLand") && <Greeting
                hideSalutation
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={numConnectedAccounts}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
              />}
            </div>

            <Stack horizontalAlign="center">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <GetOrganizedCard 
                  transactionsData={transactionsData}
                  transactionCategoriesData={transcationCategoriesData}
                  balancesData={balancesData}
                />

                <EFCard style={{ cursor: 'pointer', overflowY: 'overlay', justifyContent: 'flex-start' }}
                  stackClassName={animations.cardShadowExpand}
                  title="MY PLAYLIST"
                  onClick={() => history.push('/connect/myplaylist')}>
                  <PlaylistWidget user={user} onCardClick={cardWidgetClickHandler} planToRender={user.plans[0]} customStyle={{ cursor: 'pointer', paddingLeft: '2rem', overflowY: 'overlay', justifyContent: 'flex-start' }} />
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent', width: '100%', margin: '0px' }}>
                    <img src={addToPlaylistButtonWidget} alt="add" style={{
                      paddingRight: '2rem',
                      width: '15%',
                      alignSelf: 'center',
                      paddingTop: '3rem',
                      cursor: 'pointer',
                      marginTop: '-1rem',
                      marginBottom: '1rem'
                    }} onClick={event => {
                      event.stopPropagation();
                      event.preventDefault();
                      setLEModal(true);
                      setPlanId(user.plans?.[0]?.planId);
                    }} />
                  </div>
                </EFCard>

                <SummaryWidget onClick={() => {
                  history.push('/connect/yourprogress');
                }} />

                {screenIsAtMost("tabLand") && <EFCard title="PLAN FOR FUTURE" stackClassName={`${screenIsAtMost("tabLand") ? animations.cardShadowExpand : [classes.cashflow, animations.cardShadowExpand].join(' ')}`} onClick={() => history.push('/connect/smartcashflow')}>
                  <img className={classes.futureImg} src={screenIsAtMost("tabLand") ? planForFutureMobile : interstitialImage} alt="scf" />
                </EFCard>}

              </div>

              {screenIsAtLeast("desktop") && <EFCard title="PLAN FOR FUTURE" stackClassName={`${screenIsAtMost("tabLand") ? animations.cardShadowExpand : [classes.cashflow, animations.cardShadowExpand].join(' ')}`} onClick={() => history.push('/connect/smartcashflow')}>
                <img className={classes.futureImg} src={screenIsAtMost("tabLand") ? planForFutureMobile : interstitialImage} alt="scf" />
              </EFCard>}
            </Stack>

          </DashboardContainer>
        </div>

        {!user.anonymized && (
          <>
            {currentHint === 0 && (
              <Hint
                asset={circleAdd}
                id={hintsEntries.myLife}
                onDismissClick={() => {
                  setCurrentHint(currentHint + 1);
                  dispatch(connectDismissHint());
                }}
                onActionClick={() => {
                  setLEModal(true);
                }}
              />
            )}
            {currentHint === 1 && (
              <Hint
                asset={circleAdd}
                id={hintsEntries.scoop}
                onDismissClick={() => {
                  setCurrentHint(currentHint + 1);
                  dispatch(connectDismissHint());
                }}
                onActionClick={() => {
                  dispatch(aggregationActions.getFastlinkCredentials(history, false));
                  dispatch(actions.setRedirect(false));
                  history.push("/aggregate");
                }}
              />
            )}
            {currentHint === 2 && (
              <Hint
                asset={circleAdd}
                id={hintsEntries.profile}
                onDismissClick={() => {
                  setCurrentHint(currentHint + 1);
                  dispatch(connectDismissHint());
                }}
                onActionClick={() => {
                  setShowProfileSwitcher(true);
                }}
              />
            )}
            {currentHint === 3 && (
              <Hint
                asset={circleAdd}
                id={hintsEntries.nudget}
                onActionClick={() => {
                  history.push('/connect/getorganized/nudget');
                }}
                onDismissClick={() => {
                  setCurrentHint(currentHint + 1);
                  dispatch(connectDismissHint());
                }}
              />
            )}
            {currentHint === 4 && (
              <Hint
                asset={circleAdd}
                id={hintsEntries.kakeibo}
                onActionClick={() => {
                  history.push('/connect/getorganized/kakeibo');
                }}
                onDismissClick={() => {
                  setCurrentHint(currentHint + 1);
                  dispatch(connectDismissHint());
                }}
              />
            )}
          </>
        )}
        <Footer />
      </div>
    );
  }

  return content;
};

export default ConnectDashboardPage;