import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../../Atomic/EFLabel/EFLabel';

interface IProps {
    title?: string,
    isGreen: boolean,
    isNeutral?: boolean;
    onChange: () => void,
    reportGreenLabel: string,
    reportRedLabel: string,
    reportNeutralLabel?: string;
    reportGreenImg?: string, //tmp
    reportRedImg?: string, //tmp
    greenAlt?: string,
    redAlt?: string,
    imgStyle?: object,
    reportStyle?: object,
    containerStyle?: object,
};

const ResultReport: React.FC<IProps> = props => {
    const reportLabel = props.isGreen ? props.reportGreenLabel : props.isNeutral ? props.reportNeutralLabel : props.reportRedLabel;
    const reportImg = props.isGreen ? props.reportGreenImg : props.reportRedImg;
    const reportStyle = {
        color: props.isGreen ? props.greenAlt ? props.greenAlt : '#2CCD79' : props.isNeutral ? '#293861' : props.redAlt ? props.redAlt : '#DC50A2',
        fontSize: '2.4rem', 
        width: '18rem', 
        height: '7.5rem', 
        textAlign: 'center' as 'center',
        ...props.reportStyle,
    };

    return (
        <Stack style={{marginBottom: '2rem', ...props.containerStyle}} horizontalAlign='center' onChange={props.onChange}>
            <Stack>
                <EFLabel style={{fontSize: '1.6rem', fontWeight: 'normal' as 'normal'}}>{props.title}</EFLabel>
                <img style={{width: '25.1rem', height: '17.7rem', ...props.imgStyle}} alt='result-img' src={reportImg} />
            </Stack>
            <EFLabel style={reportStyle}>{reportLabel}</EFLabel>
        </Stack>
    )
};

ResultReport.defaultProps = {
    title: 'Based on your calculations:',
    reportGreenImg: require('../../../assets/images/result_green.svg'),
    reportRedImg: require('../../../assets/images/result_red.svg'),
};

export default ResultReport;
