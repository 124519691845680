import React, { useState, useEffect } from 'react';
import classes from '../Dashboards.module.css';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';
import BarChart from '../../../Charts/BarChart/BarChart';
import DoughnutChart from '../../../Charts/DoughnutChart';
import MaskedLabelPair from '../../../UI/MaskedLabelPair';
import ScoopAccordion from '../../ScoopAccordion/ScoopAccordion';
import Category from '../../CategoryList/Category';
import EFDropdown from '../../../Atomic/EFDropdown/EFDropdown';
import EmergencyButton from './EmergencyButton/EmergencyButton';
import EmergencyModal from '../../EditorModal/EditorModal';
import { addAlpha, generateColorShadeSet } from '../../../../utils/colorGenerators';
import { chartOrganizedMonthNames } from '../../../../utils/dateTools';
import { useSavingsData } from '../../../../hooks/ScoopDashboards/useSavingsData';
import DashboardHeader from '../../DashboardHeader/DashboardHeader';
// import TimeButton from '../../TimeButton/TimeButton';
import EmptyTransactions from '../../EmptyTransactions/EmptyTransactions';
import { useResponsive } from '../../../../hooks/useResponsive';

interface IProps {
    accBalancesData: any;
    transactionsData: any;
};

const Savings: React.FC<IProps> = props => {
    const { screenIsAtMost } = useResponsive();
    const isMobile = screenIsAtMost('mobile');
    const [openModal, setOpenModal] = useState(false);
    const [toYearly] = useState<boolean>(false);
    const [monthByIdx, setMonthByIdx] = useState<number>(11);
    const {
        fullMonths,
        chartMonths,
        chartMonthsYrly,
        containerTypes,
        sortedData,
        test,
        setTest,
        getCategoryTotalsByMonth,
        percentFunds,
        monthlySums,
        getAccountBalancesByMonth,
        monthlySumsByCategory,
        yearToDateByCategory,
        totalYearToDate
    } = useSavingsData(props.accBalancesData, props.transactionsData, toYearly);

    useEffect(() => {
        toYearly ? setMonthByIdx(0) : setMonthByIdx(11);
        toggleCategory(allCategoryLabel);
    }, [toYearly]);

    const allCategoryLabel = 'All Categories';
    const allCategoryColor = '#2698E0';
    const baseCategories = ['Banking', 'Investment', 'Retirement'];
    const [category, toggleCategory] = useState<string>(allCategoryLabel);
    const months = toYearly ? chartOrganizedMonthNames : chartMonths;

    const [barColor, setBarColor] = useState<string>(allCategoryColor);
    const barOffSelectColor = addAlpha(barColor, .3);
    const barSelectColor = barColor;
    // Used to map the bar colors in barColors and updateChart
    const monthSet = toYearly ? chartMonthsYrly : months;
    const barColors = monthSet.map((_: any, i: number) => (i !== monthByIdx) ? barOffSelectColor : barSelectColor);
    const getDoughnutData =
        (category === allCategoryLabel) ? getCategoryTotalsByMonth(monthByIdx) : getAccountBalancesByMonth(monthByIdx);
    const doughnutColors = toYearly ? '#4083D7' : generateColorShadeSet(test.length, '#4083D7');

    const baseCategoryData = [
        { 
            key: baseCategories[0], 
            text: baseCategories[0], 
            color: '#4083D7', 
            icon: require('../../../../assets/images/scoop/all_bank_savings.svg'), 
            focused: category === baseCategories[0], 
            value: sortedData && monthlySumsByCategory[0][monthByIdx],
        },
        { 
            key: baseCategories[1], 
            text: baseCategories[1], 
            color: '#3865AD', 
            icon: require('../../../../assets/images/scoop/all_invest_savings.svg'), 
            focused: category === baseCategories[1], 
            value: sortedData && monthlySumsByCategory[1][monthByIdx],
        },
        { 
            key: baseCategories[2], 
            text: baseCategories[2], 
            color: '#529FC7', 
            icon: require('../../../../assets/images/scoop/all_retire_savings.svg'), 
            focused: category === baseCategories[2], 
            value: sortedData && monthlySumsByCategory[2][monthByIdx],
        },
    ];

    let categoryData = [
        { 
            key: allCategoryLabel, 
            text: allCategoryLabel,
             color: allCategoryColor, 
             icon: require('../../../../assets/images/scoop/all_retire.svg'), 
             focused: category === allCategoryLabel, 
             value: undefined
        },
        ...baseCategoryData
    ];

    const categories = categoryData.map(e => (
        <Stack key={e.key}>
            <Category
                id={e.key}
                title={e.text}
                color={addAlpha(e.color, .3)}
                icon={e.icon}
                focused={e.focused}
                onClick={() => handleCategoryChange(e.text, e.color)}
                value={e.value} 
            />
        </Stack>
    ));

    const titleMap = (title: string) => {
        if (title === 'Banking') return containerTypes[0];
        if (title === 'Investment') return containerTypes[1];
        if (title === 'Retirement') return containerTypes[2];
    };

    // Handles filtering data for particular category + switches category view
    const handleCategoryChange = (title: string, color: string) => {
        toggleCategory(title);
        setBarColor(color);
        let filtered = sortedData && sortedData.filter((acc: any) => {
            const type = titleMap(title);
            if (type !== "retirement") {
                return type === acc.account.CONTAINER
            } else {
                return acc.account.dashboards.find((db: any) => db === type);
            }
        });
        if (title === allCategoryLabel) {
            filtered = sortedData && sortedData;
        };
        setTest(filtered);
    };

    // Handles transaction list relative to category
    const transactionList = (title: string) => {
        const labelStyle = { fontSize: '2rem', fontWeight: 'normal', textAlign: 'center', margin: '1.5rem 0' };
        const getCurrentCategory = baseCategoryData.find((e: any) => title === e.text);
        const allCategoriesIsEmpty = baseCategoryData.every((category: any) => !category.value);

        return (
            <>
                {isMobile ? <></> : <EFLabel style={labelStyle}>{title}</EFLabel>}
                {(title === allCategoryLabel) && allCategoriesIsEmpty ? (
                    <EmptyTransactions
                        icon={categoryData[0].icon}
                        color={categoryData[0].color}
                        text={`We haven't found anything in ${fullMonths[monthByIdx]}.`}
                    /> 
                ) : getCurrentCategory && !getCurrentCategory.value ? (
                    <EmptyTransactions
                        icon={getCurrentCategory.icon}
                        color={getCurrentCategory.color}
                        text={`We haven't found any accounts in ${fullMonths[monthByIdx]}.`}
                    />
                ) : (
                    test && test.map((acc: any, i: any) => (
                        <Stack key={Math.random()}>
                            <ScoopAccordion
                                id={acc.id}
                                title={acc.account.accountName} 
                                color={doughnutColors[i]} 
                                value={acc.historicalBalances[monthByIdx] ? test && test[i].historicalBalances[monthByIdx].amount : 0}
                                isNotOpenable
                                style={{ border: test.length - 1 === i && 'unset' }}
                            />
                        </Stack>
                    ))
                )}
            </>
        )
    };

    const mobileCategories = (
        <Stack tokens={{childrenGap: '2rem'}} className={classes.modalCategories}>
            {categoryData.map((currCategory) => (
                <Stack
                    style={{ width: '68vw' }}
                    horizontalAlign="center"
                    verticalAlign="center"
                >
                <Category
                    id={currCategory.key}
                    title={currCategory.text}
                    color={addAlpha(currCategory.color, 0.3)}
                    icon={currCategory.icon}
                    focused={currCategory.focused}
                    onClick={() => handleCategoryChange(currCategory.text, currCategory.color)}
                    value={currCategory.value}
                    disableAnimation
                    style={{ width: '90%', height: '5.2rem', borderRadius: '2.9rem' }}
                    imgStyle={{ width: '3.5rem', height: '3.5rem', margin: '0 2rem' }}
                />
                {currCategory.text === category ? 
                    <Stack 
                        style={{
                            margin: "1.5rem 3rem",
                            border: "1px solid #F0F0F0",
                            borderRadius: "1.4rem",
                            width: '90%',
                        }}
                    >
                        {transactionList(category)}
                    </Stack> : <></>}
                </Stack>
            ))}
        </Stack>
    )

    function updateChart (this: any, e: any) {
        const element = this.getElementAtEvent(e);
                    
        if (element[0] && element.length) {
            const chartIdx = element[0]._index;
            const chartProps = element[0]._chart.config.data.datasets[0];
            setMonthByIdx(chartIdx);
            
            chartProps.data.forEach((_: any, i: number) => {
                element[0]._chart.config.data.datasets[0].backgroundColor[i] = barOffSelectColor;
                if (i === chartIdx) {
                    element[0]._chart.config.data.datasets[0].backgroundColor[i] = barSelectColor;
                }
            });
            this.update();
        };
    }
    
    return (
        <>
            {openModal && (
                <EmergencyModal
                    title='EMERGENCY FUND'
                    color='#2698E0'
                    openModal={openModal} 
                    onClickExit={() => setOpenModal(false)}
                >
                    <img alt='pig-icon' src={require('../../../../assets/images/scoop/emergency_pig.svg')} />
                    <EFLabel style={{fontSize: '2.8rem'}}>Total Savings</EFLabel>
                    <MaskedLabelPair
                        isBulleted={false}
                        includeThousandsSeparator
                        prefix='$'
                        suffix=''
                        title=''
                        value={monthlySums[11]}
                        color='#2698E0'
                        valueFontSize={24}
                    />
                    <Stack tokens={{childrenGap: 30}} horizontalAlign='center' verticalAlign='center' style={{marginTop: '2rem', borderRadius: '1.3rem', height: '30rem', width: '30rem', backgroundColor: '#FFF', boxShadow: '0 2px 11px rgba(0, 0, 0, 0.11)'}}>
                        <Stack>
                            <DoughnutChart
                                valueLabel={percentFunds}
                                labelFontSize='20'
                                prefix=''
                                suffix='%'
                                datasets={{
                                label: ['Funds', ''],
                                datasets: [
                                    {
                                        label: 'Funds',
                                        data: [percentFunds, 100 - percentFunds],
                                        backgroundColor: ['#3E93FD', '#EEEEEE'],
                                        borderColor: 'transparent',
                                    },
                                ]}}
                            />
                        </Stack>
                        <EFLabel>Goal: 3 months paycheck</EFLabel>
                    </Stack>
                    <EFLabel style={{width: '30rem', fontWeight: 'normal', fontSize: '1.4rem', textAlign: 'right', marginTop: '2rem'}}>Tracking with:</EFLabel>
                    {transactionList(category)}
                </EmergencyModal>
            )}
            <Stack horizontalAlign={isMobile ? "center" : "" as any} className={isMobile ? classes.outer_container_mobile : classes.outer_container}>
                <DashboardHeader 
                    title={toYearly ? 'Year To Date' : fullMonths[monthByIdx]}
                    value={toYearly ? totalYearToDate : monthlySums[monthByIdx]}
                    valueColor='#2698E0'
                    prevVal={toYearly ? 0 : monthlySums[monthByIdx - 1]}
                />
                <Stack className={classes.inner_container} tokens={{childrenGap: '2rem'}}>
                    <Stack horizontalAlign={isMobile ? "center" : "space-between"} wrap={isMobile} horizontal className={isMobile ? classes.charts_mobile : classes.charts} verticalAlign='center'>
                        <Stack className={isMobile ? classes.bar_view_mobile : classes.bar_view} tokens={{childrenGap: 10}}>
                            {/* <Stack horizontal horizontalAlign='space-evenly'>
                                <TimeButton text='YEAR TO DATE' isFocused={toYearly} onClick={() => { setToYearly(true); setMonthByIdx(11);}} />
                                <TimeButton text='MONTHLY' isFocused={!toYearly} onClick={() => { setToYearly(false); setMonthByIdx(11);}} />
                            </Stack> */}
                            <BarChart
                                fontSize={isMobile ? 8 : undefined}
                                barPercentage={isMobile ? 0.65 : 0.40}  
                                datasets={{
                                    labels: months,
                                    datasets: [
                                        {
                                            label: 'Savings',
                                            data: monthlySums.map((e: any) => Math.round(e * 1e2) / 1e2),
                                            backgroundColor: barColors,
                                            borderColor: 'transparent',
                                        },
                                    ]
                                    }}
                                    onClick={toYearly ? () => null : updateChart}
                            />
                        </Stack>
                        <Stack className={isMobile ? classes.doughnut_view_mobile : classes.doughnut_view} verticalAlign='center' horizontalAlign='center' tokens={{childrenGap: 10}}>
                            <EFDropdown 
                                defaultSelectedKey={category} 
                                style={{width: '17rem'}} 
                                options={categoryData} 
                                onChange={(e: any, item: any) => handleCategoryChange(item.text, item.color)} 
                            />
                            <DoughnutChart 
                                datasets={{
                                labels: (toYearly || category === allCategoryLabel) ? [baseCategories[0], baseCategories[1]] : test ? test.map((acc: any) => acc.account.accountName) : [],
                                datasets: [
                                    {
                                        label: 'Savings',
                                        data: toYearly ? yearToDateByCategory.map((e: any) => Math.round(e * 1e2) / 1e2) : getDoughnutData.map((e: any) => Math.round(e * 1e2) / 1e2),
                                        backgroundColor: doughnutColors.length === 1 ? '#4083D7' : doughnutColors,
                                        borderColor: 'transparent',
                                    },
                                ]}}
                            />
                            <EmergencyButton 
                                value={percentFunds ? percentFunds : 0}
                                onClick={() => {
                                    setOpenModal(true);
                                    handleCategoryChange(categoryData[1].text, categoryData[1].color)
                                }} 
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal horizontalAlign='center' tokens={{childrenGap: '6rem'}}>
                        {isMobile ? mobileCategories : (
                            <>
                                <Stack className={classes.categories} tokens={{childrenGap: '1.2rem'}}>
                                    {categories}
                                </Stack>
                                <Stack className={classes.breakdown_container}>
                                    {transactionList(category)}
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
};

export default Savings;
