import React from 'react';
import {
    TooltipHost,
    TooltipDelay,
    DirectionalHint,
    ITooltipProps,
    ITooltipHostStyles,
} from 'office-ui-fabric-react/lib/Tooltip';
import { 
    EmailShareButton, 
    EmailIcon, 
    FacebookShareButton, 
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    RedditShareButton,
    RedditIcon,
    LinkedinShareButton,
    LinkedinIcon
 } from 'react-share';
import { Stack } from 'office-ui-fabric-react';

interface IProps {
    shareUrl: string,
};

const EFTooltip: React.FC<IProps> = props => {
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
    const iconSize = 32;

    const tooltipProps: ITooltipProps = {
        onRenderContent: () => (
            <Stack horizontal>
                <TwitterShareButton url={props.shareUrl}><TwitterIcon size={iconSize} /></TwitterShareButton>
                <FacebookShareButton url={props.shareUrl}><FacebookIcon size={iconSize} /></FacebookShareButton>
                <LinkedinShareButton url={props.shareUrl}><LinkedinIcon size={iconSize} /></LinkedinShareButton>
                <RedditShareButton url={props.shareUrl}><RedditIcon size={iconSize} /></RedditShareButton>
                <EmailShareButton url={props.shareUrl}><EmailIcon size={iconSize} /></EmailShareButton>
            </Stack>
          ),
    };

    return (
        <TooltipHost
            tooltipProps={tooltipProps}
            delay={TooltipDelay.zero}
            directionalHint={DirectionalHint.bottomCenter}
            styles={hostStyles}
        >
        {props.children}
      </TooltipHost>
    )
};

export default EFTooltip;
