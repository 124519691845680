import React from "react";
import { Slider, Rail, Handles, } from "react-compound-slider";
import { SliderRail, Handle } from "../LibDependency/cashflowComponents";

import classes from "./CustomFeaturedSlider.module.css";
import edit from '../../../../assets/images/nudget/edit.svg';

interface IProps {
  onChange: (values: any) => void,
  value: number,
  fixedValue?: number,
  min: number,
  max: number,
  handleColor: string,
  style?: object,
  railStyle?: object,
  handleStyle?: object,
  handleInnerStyle?: object,
  tooltip?: boolean
};

const CustomFeaturedSlider: React.FC<IProps> = props => {

    return (
      <div style={props.style}>
        <Slider
          mode={2}
          step={1}
          domain={[props.min, props.max]}
          rootStyle={{position: 'relative', width: '100%'}}
          onChange={props.onChange}
          values={[props.value]}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail railStyle={props.railStyle} getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                  <Handle
                    key={handles[0].id}
                    handle={handles[0]}
                    domain={[props.min, props.max]}
                    getHandleProps={getHandleProps}
                    color={props.handleColor}
                    handleStyle={props.handleStyle}
                    handleInnerStyle={props.handleInnerStyle}
                    tooltip={props.tooltip}
                    toolTipChildren={
                      <>
                        <input 
                          className={classes.textToolTip} 
                          value={props.value} 
                          onChange={e => props.onChange(e.target.value ? parseInt(e.target.value) : 0)} 
                        />
                        <img alt='edit' src={edit} />
                      </>
                    }
                  />
                  {(props.fixedValue || props.fixedValue === 0) && 
                  <div
                    role="slider"
                    style={{
                      left: `${props.fixedValue/props.max * 100}%`,
                      position: "absolute",
                      transform: "translate(-50%, -50%)",
                      width: '2rem',
                      height: '2rem',
                      borderRadius: "50%",
                      backgroundColor: "#A3A3A3",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                      <div 
                        style={{
                            width: '.3rem',
                            height: '.3rem',
                            borderRadius: "50%",
                            backgroundColor: "white",
                        }}
                      />
                  </div>}
              </div>
            )}
          </Handles>
        </Slider>
      </div>
    );
}

export default CustomFeaturedSlider;
