import React, { useState, useEffect } from 'react';
import classes from '../Dashboards.module.css';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';
import BarChart from '../../../Charts/BarChart/BarChart';
import MaskedLabelPair from '../../../UI/MaskedLabelPair';
import ScoopAccordion from '../../ScoopAccordion/ScoopAccordion';
import Category from '../../CategoryList/Category';
import Backdrop from '../../../Atomic/Backdrop/Backdrop';
import AddItemModal from './AddItemModal/AddItemModal';
import { addAlpha } from '../../../../utils/colorGenerators';
import DashboardHeader from '../../DashboardHeader/DashboardHeader';
import { useNetWorthData } from '../../../../hooks/ScoopDashboards/useNetWorthData';
import EmptyTransactions from '../../EmptyTransactions/EmptyTransactions';
import { useResponsive } from '../../../../hooks/useResponsive';

interface IProps {
    accBalancesData: any;
};

const baseCategories = ['Assets', 'Liabilities'];
const baseColors = ['#04AB51', '#BDE20C'];
const baseIcons = [require('../../../../assets/images/scoop/all_asset.svg'), require('../../../../assets/images/scoop/all_liabilities.svg')];

const NetWorth: React.FC<IProps> = props => {
    const { screenIsAtMost } = useResponsive();
    const isMobile = screenIsAtMost('mobile');
    const [openModal, setOpenModal] = useState(false);
    const [toYearly] = useState<boolean>(false);
    const {
        months,
        monthSet,
        fullMonths,
        sortedData,
        monthlySumsByType,
        monthlyTotals,
        yearToDateByType,
        yearToDateTotal,
    } = useNetWorthData(props.accBalancesData, toYearly);

    const [monthByIdx, setMonthByIdx] = useState<number>(11);

    useEffect(() => {
        toYearly ? setMonthByIdx(0) : setMonthByIdx(11);
    }, [toYearly]);

    const [category, toggleCategory] = useState<string>(baseCategories[0]);

    const barColors1 = monthSet.map((_, i) => (i !== monthByIdx) ? addAlpha(baseColors[0], .3) : baseColors[0]);
    const barColors2 = monthSet.map((_, i) => (i !== monthByIdx) ? addAlpha(baseColors[1], .3) : baseColors[1]);

    let categoryData = [
        { 
            key: baseCategories[0], 
            text: baseCategories[0], 
            color: baseColors[0],
            icon: baseIcons[0], 
            focused: category === baseCategories[0], 
            value: monthlySumsByType[0][monthByIdx],
        },
        { 
            key: baseCategories[1], 
            text: baseCategories[1], 
            color: baseColors[1], 
            icon: baseIcons[1], 
            focused: category === baseCategories[1], 
            value: monthlySumsByType[1][monthByIdx],
        },
    ];

    let categoryDataYrly = [
        { 
            key: baseCategories[0], 
            text: baseCategories[0], 
            color: baseColors[0],
            icon: baseIcons[0], 
            focused: category === baseCategories[0], 
            value: yearToDateByType[0],
        },
        { 
            key: baseCategories[1], 
            text: baseCategories[1], 
            color: baseColors[1], 
            icon: baseIcons[1], 
            focused: category === baseCategories[1], 
            value: yearToDateByType[1],
        },
    ];

    const categories = (toYearly ? categoryDataYrly : categoryData).map(e => (
        <Stack key={e.key}>
            <Category
                id={e.key}
                title={e.text}
                color={addAlpha(e.color, .3)}
                icon={e.icon}
                focused={e.focused}
                onClick={() => handleCategoryChange(e.text, e.color)}
                value={e.value} 
            />
        </Stack>
    ));

    // Handles filtering data for particular category + switches category view
    const handleCategoryChange = (title: string, color: string) => {
        toggleCategory(title);
    };

    // Handles transaction list relative to category
    const transactionList = (title: string) => {
        const labelStyle = {fontSize: '2rem', fontWeight: 'normal', textAlign: 'center', margin: '1.5rem 0'};
        const getCurrentCategory = categoryData.find((e: any) => title === e.text);
        const accordionData = sortedData[baseCategories.indexOf(title)] && sortedData[baseCategories.indexOf(title)].filter((acc: any) => acc[monthByIdx] && acc[monthByIdx].amount);
        
        return (
            <>
                {isMobile ? <></> : <EFLabel style={labelStyle}>{title}</EFLabel>}
                {getCurrentCategory && !getCurrentCategory.value ? (
                    <EmptyTransactions
                        icon={getCurrentCategory.icon}
                        color={getCurrentCategory.color}
                        text={`We haven't found any ${title.toLocaleLowerCase()} in ${fullMonths[monthByIdx]}.`}
                    />
                ) : (
                    accordionData.map((acc: any, i: number) => (
                    <Stack key={Math.random()}>
                        <ScoopAccordion
                            id={Math.random()}
                            title={acc[monthByIdx].account.accountName} // dropped providerName to match mobile
                            color={categoryData[baseCategories.indexOf(title)].color}
                            value={acc[monthByIdx].amount}
                            isNotOpenable
                            style={{ border: accordionData.length - 1 === i && 'unset' }}
                        />
                    </Stack>
                )))}
            </>
        )
    };

    const mobileCategories = (
        <Stack tokens={{childrenGap: '2rem'}} className={classes.modalCategories}>
            {categoryData.map((currCategory) => (
                <Stack
                    style={{ width: '68vw' }}
                    horizontalAlign="center"
                    verticalAlign="center"
                >
                <Category
                    id={currCategory.key}
                    title={currCategory.text}
                    color={addAlpha(currCategory.color, 0.3)}
                    icon={currCategory.icon}
                    focused={currCategory.focused}
                    onClick={() => handleCategoryChange(currCategory.text, currCategory.color)}
                    value={currCategory.value}
                    disableAnimation
                    style={{ width: '90%', height: '5.2rem', borderRadius: '2.9rem' }}
                    imgStyle={{ width: '3.5rem', height: '3.5rem', margin: '0 2rem' }}
                />
                {currCategory.text === category ? 
                    <Stack 
                        style={{
                            margin: "1.5rem 3rem",
                            border: "1px solid #F0F0F0",
                            borderRadius: "1.4rem",
                            width: '90%',
                        }}
                    >
                        {transactionList(category)}
                    </Stack> : <></>}
                </Stack>
            ))}
        </Stack>
    )

    function updateChart (this: any, e: any) {
        const element = this.getElementAtEvent(e);
                    
        if (element[0] && element.length) {
            const chartIdx = element[0]._index;
            const chartProps = element[0]._chart.config.data.datasets[0];
            setMonthByIdx(chartIdx);
            
            chartProps.data.forEach((_: any, i: number) => {
                element[0]._chart.config.data.datasets[0].backgroundColor[i] = addAlpha(baseColors[0], .3);
                element[0]._chart.config.data.datasets[1].backgroundColor[i] = addAlpha(baseColors[1], .3);
                if (i === chartIdx) {
                    element[0]._chart.config.data.datasets[0].backgroundColor[i] = baseColors[0];
                    element[0]._chart.config.data.datasets[1].backgroundColor[i] = baseColors[1];
                }
            });
            this.update();
        };
    };
    
    return (
        <Stack horizontalAlign={isMobile ? "center" : "" as any} className={isMobile ? classes.outer_container_mobile : classes.outer_container}>
            {openModal && (<Backdrop onClick={() => setOpenModal(!openModal)} />)}
            {openModal && (<AddItemModal openModal={openModal} onClickExit={() => setOpenModal(false)} />)}
            <DashboardHeader 
                title='Total Net Worth'
                value={toYearly ? yearToDateTotal : monthlyTotals[monthByIdx]}
                valueColor='#42B05C'
                prevVal={toYearly ? 0 : monthlyTotals[monthByIdx - 1]}
            />
             <Stack className={classes.inner_container} tokens={{childrenGap: '2rem'}}>
                <Stack horizontalAlign={isMobile ? "center" : "space-between"} wrap={isMobile} horizontal className={isMobile ? classes.charts_mobile : classes.charts} verticalAlign='center'>
                    <Stack className={isMobile ? classes.bar_view_mobile : classes.bar_view} tokens={{childrenGap: 10}}>
                        {/* <Stack horizontal horizontalAlign='space-evenly'>
                            <TimeButton text='YEAR TO DATE' isFocused={toYearly} onClick={() => { setToYearly(true); setMonthByIdx(11);}} />
                            <TimeButton text='MONTHLY' isFocused={!toYearly} onClick={() => { setToYearly(false); setMonthByIdx(11);}} />
                        </Stack> */}
                        <BarChart
                            fontSize={isMobile ? 8 : undefined}
                            barPercentage={isMobile ? 0.65 : 0.40} 
                            datasets={{
                                labels: months,
                                datasets: [
                                    {
                                        label: baseCategories[0],
                                        data: monthlySumsByType[0].map((e: any) => Math.round(e * 1e2) / 1e2),
                                        backgroundColor: barColors1,
                                        borderColor: 'transparent',
                                    },
                                    {
                                        label: baseCategories[1],
                                        data: monthlySumsByType[1].map((e: any) => -1 * Math.round(e * 1e2) / 1e2),
                                        backgroundColor: barColors2,
                                        borderColor: 'transparent',
                                    },
                                    {
                                        label: 'Totals',
                                        type: 'line',
                                        fill: false,
                                        data: monthlyTotals.map((e: any) => Math.round(e * 1e2) / 1e2) || [],
                                        borderColor: '#09D2CB',
                                    },
                                ]
                                }}
                                onClick={updateChart}
                        />
                    </Stack>
                    <Stack style={{ marginTop: isMobile && '4rem' }} className={classes.alt_view} verticalAlign='center' horizontalAlign='center' tokens={{childrenGap: 10}}>
                        <Stack horizontal verticalAlign='center' style={{width: '25.8rem', height: '8.3rem', boxShadow: '0 2px 9px rgba(0, 0, 0, 0.11)', borderRadius: '1.2rem'}}>
                            <Stack style={{width: '50%'}}>
                                <MaskedLabelPair
                                    isHorizontal={false}
                                    isBulleted={false}
                                    includeThousandsSeparator
                                    prefix='$'
                                    suffix=''
                                    title={categoryData[0].text}
                                    titleStyle={{color: categoryData[0].color}}
                                    value={categoryData[0].value}
                                    color='#293861'
                                    valueFontSize={23}
                                />
                            </Stack>
                            <div style={{height: '100%', borderLeft: '0.5px solid rgba(0, 0, 0, 0.15)', borderRight: '0.5px solid rgba(0, 0, 0, 0.15)'}} />
                            <Stack style={{width: '50%'}}>
                                <MaskedLabelPair
                                    isHorizontal={false}
                                    isBulleted={false}
                                    includeThousandsSeparator
                                    prefix='$'
                                    suffix=''
                                    title={categoryData[1].text}
                                    titleStyle={{color: categoryData[1].color}}
                                    value={categoryData[1].value}
                                    color='#293861'
                                    valueFontSize={23}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign='center' tokens={{childrenGap: '6rem'}}>
                    {isMobile ? mobileCategories : (
                        <>
                            <Stack className={classes.categories} tokens={{childrenGap: '1.2rem'}}>
                                {categories}
                            </Stack>
                            <Stack className={classes.breakdown_container}>
                                {transactionList(category)}
                            </Stack>
                        </>
                    )}
                </Stack>
             </Stack>
        </Stack>
    )
};

export default NetWorth;
