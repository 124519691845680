import React from 'react';
import delImg from '../../../assets/images/nudget/del.svg';
import editImg from '../../../assets/images/nudget/editAlt.svg';

import classes from './EditDeleteTooltip.module.css';

interface Props {
    style?: object;
    onClickEdit?: () => void;
    onClickDelete?: () => void;
    show?: boolean
}

const EditDeleteTooltip: React.FC<Props> = props => {
    return (
        <div className={classes.container} style={{ display: props.show ? 'flex' : 'none', ...props.style }}>
            <img src={delImg} alt="delete" onClick={props.onClickDelete}
                className={classes.imgStyle} />
            <img src={editImg} alt="edit" onClick={props.onClickEdit}
                className={classes.imgStyle} />
        </div>
    )
};

export default EditDeleteTooltip