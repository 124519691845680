/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import classes from './CFAccordion.module.css';
import { Stack } from 'office-ui-fabric-react';
import SCFStepper from '../SCFStepper/SCFStepper';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import Spikes from '../../SmartCashflow2/Spikes/Spikes';
import { useBoolean } from '@uifabric/react-hooks';

import red from '../../../assets/images/red_toggle.svg';
import green from '../../../assets/images/green_toggle.svg';
import tuner from '../../../assets/images/tuner.svg';
import EFButton from '../../Atomic/EFButton/EFButton';
import Modal from 'react-modal';
import CustomModalCard from '../../UI/CustomModalCard/CustomModalCard';
import useDebounce from '../../../hooks/useDebounce';
interface IProps {
    name?: string,
    value?: number,
    fixedValue?: number;
    saveEntry?: any,
    onChange?: (v: any) => void,
    minVal?: number,
    maxVal?: number,
    asset?: string,
    focused?: boolean,
    onClickFocus?: (event: any) => void,
    spikes?: any,
    onSaveSpikes?: (spikes: any) => void,
    lifeEvents?: any,
    enabled?: boolean,
    onEnable?: () => void,
    onboarded?: boolean,
    onSaveOnboard?: () => void,
};

const CFAccordion: React.FC<IProps> = props => {
    const [hover, setHover] = useState(false);
    const [opened, setOpened] = useState<boolean>(false);
    const [showLumpy, { toggle: toggleLumpyModal }] = useBoolean(false);
    const [showOnboard, { toggle: toggleOnboard }] = useBoolean(false);

    const debouncedValue = useDebounce(props.value, 1000);

    useEffect(() => {
        if (!showOnboard && props.value !== props.fixedValue) {
            props.saveEntry && props.saveEntry();
        }
    }, [debouncedValue])
    
    return (
        <div 
            style={{ 
                margin: '2rem 0', 
                border: opened && props.focused && '1px solid #FEB81E',
                boxShadow: opened && (props.focused || hover) && '0px 0px 25px rgba(254, 184, 30, 0.2)',
            }} 
            className={`${classes.accordion} ${opened && classes.accordionopened}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Stack 
                style={{
                    border: !opened && props.focused && '1px solid #FEB81E',
                    boxShadow: !opened && (props.focused || hover) && '0px 0px 25px rgba(254, 184, 30, 0.2)',
                }} 
                className={`${classes.line} ${opened && classes.lineopened}`}
                onClick={props.onboarded ? props.onClickFocus : () => null}
            >
                <Stack style={{ width: '100%' }} horizontal horizontalAlign="space-between" verticalAlign="center" >
                    {props.onboarded ? (
                        <>
                            <img onClick={(e) => { toggleLumpyModal(); e.stopPropagation() }} className={classes.spike} src={tuner} alt="spikes" />
                            <img 
                                className={classes.toggle}
                                src={props.enabled ? green : red} 
                                alt="toggle-on-off" 
                                onClick={(e) => { props.onEnable(); e.stopPropagation() }} 
                            />
                        </>
                    ): <div style={{ height: '1.6rem' }} />}
                </Stack>
                <EFLabel style={{ fontSize: '2rem', marginBottom: '1.5rem' }}>{props.name}</EFLabel>
                <img src={props.asset} alt="group-icon" className={props.onboarded ? classes.onboardedCashImg : classes.cashImg} />
                {props.onboarded ? 
                    <SCFStepper dynamicStepEnabled value={props.value} onChange={props.onChange} minVal={props.minVal} maxVal={props.maxVal}  /> :
                    <EFButton style={{ width: '13.6rem', height: '2.98rem' }} text="SET UP" onClick={toggleOnboard} /> 
                }
            </Stack>
            {props.onboarded && (
                <>
                    <div
                        onClick={e => e.stopPropagation()}
                        className={`${classes.inner} ${opened && classes.inneropened}`}
                    >
                        <Stack
                            verticalAlign='center' 
                            tokens={{childrenGap: 20}} 
                            className={`${classes.content} ${opened && classes.contentopened}`}
                        >
                            {props.children}
                        </Stack>
                    </div>
                    <div onClick={e => e.stopPropagation()} className={classes.relative}>
                        <Stack 
                            className={`${classes.iconContainer} ${opened && classes.iconContainerOpened}`} 
                            onClick={() => setOpened(!opened)}
                        >
                            <span 
                                className={`${classes.icon} ${opened && classes.iconopened}`}
                            />
                        </Stack>
                    </div>
                </>
            )}
            {showOnboard && (
                <Modal 
                    isOpen={showOnboard}
                    onRequestClose={toggleOnboard}
                    style={modalCardStyle}
                >
                    <CustomModalCard
                        // style={{ height: '100%' }}
                        eventIcon={props.asset} 
                        title={props.name}
                        onClickExit={toggleOnboard}
                        button
                        buttonText='SAVE'
                        onClick={() => { props.saveEntry(); toggleOnboard(); }}
                        stackClassName={classes.modalInner}
                    >
                        <div className={classes.modalInnerContainer}>
                            {props.children}
                        </div>             
                    </CustomModalCard>
                </Modal>
            )}
            {showLumpy && 
                <Spikes 
                    title={props.name} 
                    isOpen={showLumpy} 
                    onToggle={toggleLumpyModal} 
                    icon={props.asset} 
                    spikes={props.spikes} 
                    onSaveSpikes={props.onSaveSpikes} 
                />
            }
        </div>
    )
};

const modalCardStyle = {
    content: {
        display: "flex",
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: 'fit-content',
        width: '100%',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
        margin: '2rem 0',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)'
    }
}

export default CFAccordion;
