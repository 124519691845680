import React, { useState, useEffect } from "react";
import classes from "../Dashboards.module.css";
import { Stack } from "office-ui-fabric-react";
import EFLabel from "../../../Atomic/EFLabel/EFLabel";
import BarChart from "../../../Charts/BarChart/BarChart";
import DoughnutChart from "../../../Charts/DoughnutChart";
import ScoopAccordion from "../../ScoopAccordion/ScoopAccordion";
import Category from "../../CategoryList/Category";
import EFDropdown from "../../../Atomic/EFDropdown/EFDropdown";
import TimeButton from "../../TimeButton/TimeButton";
import Backdrop from "../../../Atomic/Backdrop/Backdrop";
import AddIncomeModal from "./AddIncomeModal/AddIncomeModal";
import { addAlpha } from "../../../../utils/colorGenerators";
import { chartOrganizedMonthNames } from "../../../../utils/dateTools";
import { useIncomeData } from "../../../../hooks/ScoopDashboards/useIncomeData";
import DashboardHeader from "../../DashboardHeader/DashboardHeader";
import EmptyTransactions from "../../EmptyTransactions/EmptyTransactions";
import MaskedLabelPair from "../../../UI/MaskedLabelPair";
import moment from "moment";
import { useResponsive } from "../../../../hooks/useResponsive";
interface IProps {
  transactionsData: any;
  categories: any;
}

const Income: React.FC<IProps> = (props) => {
  const { screenIsAtMost } = useResponsive();
  const isMobile = screenIsAtMost("mobile");
  const [openModal, setOpenModal] = useState(false);
  const [toYearly, setToYearly] = useState<boolean>(false);
  const [monthByIdx, setMonthByIdx] = useState<number>(11);
  const {
    fullMonths,
    chartMonths,
    chartMonthsYrly,
    baseCategories,
    transactionData,
    amountsSummedByType,
    getMonthlyAmountsForIndex,
    amountsTotalsMonthly,
    yrToDateByType,
    totalYrToDate,
  } = useIncomeData(
    props.categories,
    props.transactionsData,
    toYearly,
    monthByIdx
  );

  const months = toYearly ? chartOrganizedMonthNames : chartMonths;
  const [category, toggleCategory] = useState<string>(baseCategories[0].title);
  // const [sort, setSort] = useState<string>('Amount');
  const barOffSelectColor = addAlpha("#09B2FB", 0.3);
  const barSelectColor = "#09B2FB";
  // Used to map the bar colors in barColors and updateChart
  const monthSet = toYearly ? chartMonthsYrly : months;
  const barColors = monthSet.map((_: any, i: number) =>
    i !== monthByIdx ? barOffSelectColor : barSelectColor
  );

  let categoryData = baseCategories.map(
    (e: { title: string; color: string; icon: string }) => {
      const idx = baseCategories.findIndex((x: any) => x.title === e.title);
      return {
        key: e.title,
        text: e.title,
        color: e.color,
        icon: e.icon,
        focused: category === e.title,
        value: amountsSummedByType[idx] && amountsSummedByType[idx][monthByIdx],
      };
    }
  );

  let categoryDataYrly = baseCategories.map(
    (e: { title: string; color: string; icon: string }) => {
      const idx = baseCategories.findIndex((x: any) => x.title === e.title);
      return {
        key: e.title,
        text: e.title,
        color: e.color,
        icon: e.icon,
        focused: category === e.title,
        value: yrToDateByType[idx] && yrToDateByType[idx],
      };
    }
  );

  useEffect(() => {
    toYearly ? setMonthByIdx(0) : setMonthByIdx(11);
  }, [toYearly]);

  // Handles filtering data for particular category + switches category view
  const handleCategoryChange = (title: string, color: string) => {
    toggleCategory(title);
  };

  // Handles bar chart's color highlighting click event
  function updateChart(this: any, e: any) {
    const element = this.getElementAtEvent(e);

    if (element[0] && element.length) {
      const chartIdx = element[0]._index;
      const chartProps = element[0]._chart.config.data.datasets[0];
      setMonthByIdx(chartIdx);

      chartProps.data.forEach((_: any, i: number) => {
        element[0]._chart.config.data.datasets[0].backgroundColor[i] =
          barOffSelectColor;
        if (i === chartIdx) {
          element[0]._chart.config.data.datasets[0].backgroundColor[i] =
            barSelectColor;
        }
      });
      this.update();
    }
  }

  // const alphaSort = (data: any) => data.sort((a: any, b: any) => a.text.localeCompare(b.text))
  // const valueSort = (data: any) => data.sort((a: any, b: any) => b.value - a.value);

  // const handleSorting = (_: any, item: any) => {
  //     setSort(item.key);
  //     if (item.text === 'Amount') {
  //         categoryData.sort((a: any, b: any) => b.value - a.value);
  //     } else {
  //         categoryData.sort((a: any, b: any) => a.text.localeCompare(b.text));
  //     };
  //     toggleCategory(categoryData[0].text);
  // };

  const categories = (toYearly ? categoryDataYrly : categoryData).map(
    (e: any) => (
      <Stack key={e.key}>
        <Category
          id={e.key}
          title={e.text}
          color={addAlpha(e.color, 0.3)}
          icon={e.icon}
          focused={e.focused}
          onClick={() => handleCategoryChange(e.text, e.color)}
          value={e.value}
        />
      </Stack>
    )
  );

  // Handles transaction list relative to category
  const transactionList = (name: string) => {
    const labelStyle = {
      fontSize: "2rem",
      fontWeight: "normal",
      textAlign: "center",
      margin: "1.5rem 0",
    };
    const idxType = props.categories?.findIndex((e: any) => e.name === name);
    const getCurrentCategory = baseCategories.find(
      (e: any) => name === e.title
    );

    return (
      <>
        {isMobile ? <></> : <EFLabel style={labelStyle}>{name}</EFLabel>}
        {amountsSummedByType[0] && amountsSummedByType[0][monthByIdx] === 0 ? (
          <EmptyTransactions
            icon={getCurrentCategory.icon}
            color={getCurrentCategory.color}
            text={`We haven't found income in ${fullMonths[monthByIdx]}.`}
          />
        ) : (
          getCurrentCategory &&
          transactionData[idxType] &&
          (transactionData[idxType][monthByIdx] || []).map(
            (category: any, i: number) => (
              <Stack key={`${category.category}_${i}`}>
                <ScoopAccordion
                  id={`${category.category}_${i}`}
                  title={category.category}
                  color={baseCategories[0].color}
                  // color={props.categories?.[idxType].color}
                  value={category.total}
                >
                  {category.data &&
                    category.data.map((e: any, j: number) => (
                      <Stack
                        className={
                          category.data.length - 1 !== j
                            ? classes.transaction
                            : ""
                        }
                        key={`${e.label}_${e.date}_${i}_${j}`}
                        horizontalAlign="center"
                      >
                        <Stack
                          tokens={{ childrenGap: "2rem" }}
                          className={classes.transactionInner}
                        >
                          <Stack
                            style={{ margin: "0 4rem 0 2.5rem" }}
                            horizontal
                            horizontalAlign="space-between"
                            verticalAlign="center"
                          >
                            <EFLabel
                              style={{
                                fontWeight: "normal",
                                fontSize: "1.6rem",
                                width: "30rem",
                              }}
                            >
                              {e.label}
                            </EFLabel>
                            <Stack
                              style={{ minWidth: isMobile ? "10rem" : "unset" }}
                              tokens={{ childrenGap: "2rem" }}
                              horizontal
                              horizontalAlign="space-between"
                            >
                              <EFLabel
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "1.6rem",
                                }}
                              >
                                {moment(e.date).format("MMM DD")}
                              </EFLabel>
                              <MaskedLabelPair
                                isBulleted={false}
                                includeThousandsSeparator
                                prefix="$"
                                suffix=""
                                title={""}
                                titleStyle={{ fontWeight: "normal" }}
                                value={e.amount}
                                color="#9EA4B3"
                                valueFontSize={"1.6rem"}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}
                </ScoopAccordion>
              </Stack>
            )
          )
        )}
      </>
    );
  };

  const mobileCategories = (
    <Stack tokens={{ childrenGap: "2rem" }} className={classes.modalCategories}>
      {categoryData.map((currCategory) => (
        <Stack
          style={{ width: "68vw" }}
          horizontalAlign="center"
          verticalAlign="center"
        >
          <Category
            id={currCategory.key}
            title={currCategory.text}
            color={addAlpha(currCategory.color, 0.3)}
            icon={currCategory.icon}
            focused={currCategory.focused}
            onClick={() =>
              handleCategoryChange(currCategory.text, currCategory.color)
            }
            value={currCategory.value}
            disableAnimation
            style={{ width: "90%", height: "5.2rem", borderRadius: "2.9rem" }}
            imgStyle={{ width: "3.5rem", height: "3.5rem", margin: "0 2rem" }}
          />
          {currCategory.text === category ? (
            <Stack
              style={{
                margin: "1.5rem 3rem",
                border: "1px solid #F0F0F0",
                borderRadius: "1.4rem",
                width: "90%",
              }}
            >
              {transactionList(category)}
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Stack
      horizontalAlign={isMobile ? "center" : ("" as any)}
      className={
        isMobile ? classes.outer_container_mobile : classes.outer_container
      }
    >
      {openModal && <Backdrop onClick={() => setOpenModal(!openModal)} />}
      {openModal && (
        <AddIncomeModal
          openModal={openModal}
          onClickExit={() => setOpenModal(false)}
        />
      )}
      <DashboardHeader
        title={toYearly ? "Year To Date" : fullMonths[monthByIdx]}
        value={
          toYearly
            ? totalYrToDate
            : amountsTotalsMonthly && amountsTotalsMonthly[monthByIdx]
        }
        valueColor="#7CD4FA"
        prevVal={toYearly ? 0 : amountsTotalsMonthly?.[monthByIdx - 1]}
      />
      <Stack
        className={classes.inner_container}
        tokens={{ childrenGap: "2rem" }}
      >
        <Stack
          horizontalAlign={isMobile ? "center" : "space-between"}
          wrap={isMobile}
          horizontal
          className={isMobile ? classes.charts_mobile : classes.charts}
          verticalAlign="center"
        >
          <Stack
            className={isMobile ? classes.bar_view_mobile : classes.bar_view}
            tokens={{ childrenGap: 10 }}
          >
            <Stack horizontal horizontalAlign="space-evenly">
              <TimeButton
                text="YEAR TO DATE"
                isFocused={toYearly}
                onClick={() => {
                  setToYearly(true);
                  setMonthByIdx(11);
                }}
              />
              <TimeButton
                text="MONTHLY"
                isFocused={!toYearly}
                onClick={() => {
                  setToYearly(false);
                  setMonthByIdx(11);
                }}
              />
            </Stack>
            <BarChart
              fontSize={isMobile ? 8 : undefined}
              barPercentage={isMobile ? 0.65 : 0.4}
              datasets={{
                labels: months,
                datasets: [
                  {
                    label: "Total Income",
                    data: amountsTotalsMonthly?.map(
                      (e: any) => Math.round(e * 1e2) / 1e2
                    ),
                    backgroundColor: barColors,
                    borderColor: "transparent",
                  },
                ],
              }}
              onClick={updateChart}
            />
          </Stack>
          <Stack
            className={
              isMobile ? classes.doughnut_view_mobile : classes.doughnut_view
            }
            verticalAlign="center"
            horizontalAlign="center"
            tokens={{ childrenGap: 10 }}
          >
            {/* <EFDropdown 
                            defaultSelectedKey={category} 
                            style={{width: '17rem'}} 
                            options={categoryData} 
                            onChange={(e: any, item: any) => handleCategoryChange(item.text, item.color)} 
                        /> */}
            <DoughnutChart
              datasets={{
                labels: getMonthlyAmountsForIndex(monthByIdx).labels.map((e) =>
                  capitalize(e)
                ),
                datasets: [
                  {
                    label: "Income For Month",
                    data: toYearly
                      ? yrToDateByType.map(
                          (e: any) => Math.round(e * 1e2) / 1e2
                        )
                      : getMonthlyAmountsForIndex(monthByIdx).values.map(
                          (e: any) => Math.round(e * 1e2) / 1e2
                        ),
                    backgroundColor:
                      getMonthlyAmountsForIndex(monthByIdx).colors,
                    borderColor: "transparent",
                  },
                ],
              }}
            />
          </Stack>
        </Stack>
        <Stack
          horizontal
          horizontalAlign="center"
          tokens={{ childrenGap: "6rem" }}
        >
          {isMobile ? (
            mobileCategories
          ) : (
            <>
              <Stack
                className={classes.categories}
                tokens={{ childrenGap: "1.2rem" }}
              >
                {categories}
              </Stack>
              <Stack className={classes.breakdown_container}>
                {transactionList(category)}
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const capitalize = (text: string) => {
  return text?.charAt(0).toUpperCase() + text?.slice(1);
};

export default Income;
