/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as calculatorActions from '../../store/actions/Calculator';
import { getCustomData } from '../../utils/LifeEventTools/customDataGenerator';
const { RETIREMENT_PROJECTION_TARGET, SS_BENEFIT_BY_AGE } = require('ef-calculators');

export const useRetirement = (uniqueEventID?: string) => {
    const dispatch = useDispatch();
    const loggedUserLifeEvent = useSelector((state: any) => {
            if (state.session.user) {
                return state.session.user.lifeEvents.find((le: any) => le.id === uniqueEventID)
            }
            else {
                return null;
            }
        }
    );

    const defaultData = getCustomData('Retire / Reboot');

    //retirement inputs
    const [age, setAge] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.startAge : defaultData.startAge);
    const [retirementAge, setretirementAge] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.retirementAge: defaultData.retirementAge);
    const [startOfDistributionsAge, setstartOfDistributionsAge] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.startingAgeDistribution: defaultData.startingAgeDistribution);
    const [lifeExpectancy, setlifeExpectancy] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.lifeExpectancy: defaultData.lifeExpectancy);
    const [startingBalance, setstartingBalance] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.retirementSavingsBalance : defaultData.retirementSavingsBalance);
    const [cashSavingsAndInvestments, setcashSavingsAndInvestments] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.additionalSavingsCashInvestment : defaultData.additionalSavingsCashInvestment);
    const [monthlySideHustle, setmonthlySideHustle] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.otherIncomes : defaultData.otherIncomes);
    const [annualSalary, setannualSalary] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.monthlySalary * 12 : defaultData.monthlySalary * 12);
    const [employerMatch, setemployerMatch] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.employerContributionMatch401k : defaultData.employerContributionMatch401k);
    const [employerUpToMatch, setemployerUpToMatch] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.employerUpToMatch401k : defaultData.employerUpToMatch401k);
    const [contribution401k, setcontribution401k] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.monthlyContribution401k : defaultData.monthlyContribution401k);
    const [contributionIRA, setcontributionIRA] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.annualContributionIRA : defaultData.annualContributionIRA);
    const [returnRate, setreturnRate] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.annualReturnRate : defaultData.annualReturnRate);
    const [investmentsFees, setinvestmentsFees] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.investmentFees : defaultData.investmentFees);
    const [taxRateInRetirement, settaxRateInRetirement] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.taxRateAtRetirement : defaultData.taxRateAtRetirement);
    // To Revisit:
    // console.log(defaultData.estimatedSocialSecurityBenefit)
    // console.log(loggedUserLifeEvent.customData.estimatedSocialSecurityBenefit)
    // console.log(loggedUserLifeEvent.customData)
    // console.log("USING", loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.estimatedSocialSecurityBenefit : defaultData.estimatedSocialSecurityBenefit)
    const [estimatedAnnualSSBenefit, setestimatedAnnualSSBenefit] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.estimatedSocialSecurityBenefit * 12 : defaultData.estimatedSocialSecurityBenefit * 12);
    const [incomeNeeded, setincomeNeeded] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.monthlyIncomeNeededAtRetire * 12 : defaultData.monthlyIncomeNeededAtRetire * 12);
    const [pctChangeInMonthlyDistributions, setpctChangeInMonthlyDistributions] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.percentageChangeMonthlyContribution : defaultData.percentageChangeMonthlyContribution);
    const [annualInflation, setannualInflation] = useState(loggedUserLifeEvent && Object.keys(loggedUserLifeEvent.customData).length > 0  ? loggedUserLifeEvent.customData.inflationRate : defaultData.inflationRate);

    //retirement outputs
    const [retirementOutput, setRetirementOutput] = useState(Array());

    useEffect(() => {
        setestimatedAnnualSSBenefit(SS_BENEFIT_BY_AGE(age) * 12);
    }, [age]);

    useEffect(() => {
        const maxRetirement = () => {
            const ssBenefit = typeof estimatedAnnualSSBenefit === 'undefined' ? SS_BENEFIT_BY_AGE(age) * 12 : estimatedAnnualSSBenefit;
            const m = RETIREMENT_PROJECTION_TARGET(
              age,
              retirementAge,
              startOfDistributionsAge,
              lifeExpectancy,
              startingBalance,
              cashSavingsAndInvestments,
              monthlySideHustle,
              annualSalary,
              employerMatch,
              employerUpToMatch,
              contribution401k,
              contributionIRA,
              returnRate,
              investmentsFees,
              taxRateInRetirement,
              ssBenefit,
              incomeNeeded,
              pctChangeInMonthlyDistributions,
              annualInflation
            );
            setRetirementOutput(m);
        };
        maxRetirement();
        dispatch(calculatorActions.retirementChangeState({
            annualContributionIRA: contributionIRA, 
            retirementSavingsBalance: startingBalance,
            monthlyIncomeNeededAtRetire: incomeNeeded / 12,
            startingAgeDistribution: startOfDistributionsAge,
            additionalSavingsCashInvestment: cashSavingsAndInvestments,
            estimatedSocialSecurityBenefit: estimatedAnnualSSBenefit / 12, // Not actually used in the calculation ...
            employerContributionMatch401k: employerMatch,
            monthlyContribution401k: contribution401k,
            retirementAge: retirementAge,
            employerUpToMatch401k: employerUpToMatch,
            monthlySalary: annualSalary / 12,
            startAge: age,
            annualReturnRate: returnRate,
            inflationRate: annualInflation,
            lifeExpectancy: lifeExpectancy,
            investmentFees: investmentsFees,
            percentageChangeMonthlyContribution: pctChangeInMonthlyDistributions,
            otherIncomes: monthlySideHustle,
            taxRateAtRetirement: taxRateInRetirement,
          }));
    }, [
        dispatch,
        estimatedAnnualSSBenefit,
        age, 
        annualInflation, 
        annualSalary, 
        cashSavingsAndInvestments, 
        contribution401k, 
        contributionIRA, 
        employerMatch, 
        employerUpToMatch, 
        incomeNeeded, 
        investmentsFees, 
        lifeExpectancy, 
        monthlySideHustle, 
        pctChangeInMonthlyDistributions, 
        retirementAge, 
        returnRate, 
        startOfDistributionsAge, 
        startingBalance, 
        taxRateInRetirement
    ]);

    return {
        age, setAge, 
        retirementAge, setretirementAge,
        startOfDistributionsAge, setstartOfDistributionsAge,
        lifeExpectancy, setlifeExpectancy,
        startingBalance, setstartingBalance,
        cashSavingsAndInvestments, setcashSavingsAndInvestments,
        monthlySideHustle, setmonthlySideHustle,
        annualSalary, setannualSalary,
        employerMatch, setemployerMatch,
        employerUpToMatch, setemployerUpToMatch,
        contribution401k, setcontribution401k,
        contributionIRA, setcontributionIRA,
        returnRate, setreturnRate,
        investmentsFees, setinvestmentsFees,
        taxRateInRetirement, settaxRateInRetirement,
        estimatedAnnualSSBenefit, setestimatedAnnualSSBenefit,
        incomeNeeded, setincomeNeeded,
        pctChangeInMonthlyDistributions, setpctChangeInMonthlyDistributions,
        annualInflation, setannualInflation,
        retirementOutput,
    };
};
