import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "@uifabric/react-cards";
import EFSpinner from "../../Atomic/EFSpinner/EFSpinner";

import EFButton from "../../Atomic/EFButton/EFButton";
import Bank from "../../../assets/images/Bank.svg";

import { getUpdatedConnectedAccounts } from "../../../store/actions/Users";
import { getFastlinkCredentials } from "../../../store/actions/Aggregation";

import classes from "./Fastlink.module.css";

const Fastlink = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [showSuccessPage, setShowSuccesPage] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  const frameListener = (e) => {
    if (e.data === "fastlinkEnd") {
      dispatch(getUpdatedConnectedAccounts());
      setShowSuccesPage(true);
    }
  };

  useEffect(() => {
    window.addEventListener("message", frameListener);

    return function cleanup() {
      window.removeEventListener("message", frameListener);
    };
  }, []);

  const url = useSelector((state) => state.aggregation.fastlinkURL);
  const token = useSelector((state) => state.aggregation.token);
  const user = useSelector((state) => state.session.user);

  if (url !== "" && token !== "") {
    localStorage.setItem("ef.fastlink.url", url);
    localStorage.setItem("ef.fastlink.token", token);
  }

  useEffect(() => {
    if (url === "" && token === "") {
      setShowSpinner(true);
      dispatch(getFastlinkCredentials(history, false));
    } else {
      setShowSpinner(false);
      localStorage.setItem("ef.fastlink.url", url);
      localStorage.setItem("ef.fastlink.token", token);
    }
  }, [url, token]);

  const path = process.env.REACT_APP_FASTLINK_FILE;

  let page = (
    <Card horizontal className={classes.card}>
      <Card.Section
        className={classes.contentBlur}
        styles={{ root: { height: "55rem", width: "68rem" } }}
      >
        <img src={Bank} alt="Sucess" className={classes.bankImage} />
        <p className={classes.title}>Congratulations!</p>
        <p className={classes.contentText}>Your Account has been</p>
        <p className={classes.contentText}>
          successfully linked to <strong>EqualFuture</strong>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EFButton
            id="bankAnother"
            text="ADD ANOTHER ACCOUNT"
            onClick={() => {
              localStorage.setItem("fastlinkEnd", "false");
              setShowSuccesPage(false);
            }}
            style={{ width: "25.5rem", marginTop: "3rem" }}
          />
          <EFButton
            id="bankDone"
            style={{
              backgroundColor: "transparent",
              color: "#feb81e",
              width: "13.2rem",
              marginTop: "2.7rem",
            }}
            text="I'M DONE"
            onClick={() => {
              localStorage.removeItem("ef.fastlink.url");
              localStorage.removeItem("ef.fastlink.token");
              localStorage.removeItem("fastlinkEnd");
              if (user.plans.length > 0) {
                history.push("/connect");
              }
              else {
                history.push("/myplansetup");
              }
            }}
          />
        </div>
      </Card.Section>
    </Card>
  );

  if (!showSuccessPage) {
    page = (
      <>
        {showSpinner && <EFSpinner />}
        {!showSpinner && <iframe
          src={path}
          style={{
            margin: "0px",
            minWidth: "100%",
            border: "none",
            overflow: "hidden",
            borderRadius: "15px",
            ...props.customStyle,
          }}
          width="100%"
          height="100%"
          title="fastlink"
          onLoad={(event) => {
            let frame = document.getElementsByTagName("iframe")[0]
              .contentWindow;
            frame.postMessage(url + "," + token, "*");
            setShowSpinner(false);
          }}
        />}
      </>
    );
  }

  return page;
};

export default Fastlink;
