import React from 'react';
import { Label } from 'office-ui-fabric-react/lib/';
import classes from './EFLabel.module.css'

interface IProps {
    style?: object;
    className?: string;
    onClick?: () => void;
};

const EFLabel: React.FC<IProps> = props => {

    return (
        <Label onClick={props.onClick} className={classes.label} style={{...props.style}}>{props.children}</Label>
    )
};

export default EFLabel;
