import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Backdrop from '../../components/Atomic/Backdrop/Backdrop';
import DashboardContainer from '../../components/UI/DashboardContainer/DashboardContainer';
import EFBreadcrumb from '../../components/UI/EFBreadcrumb';
import Greeting from '../../components/UI/Greeting';
import NavButton from '../../components/UI/NavButton';
import NavigationView from '../../components/UI/NavigationView';
import ProfileSwitcher from '../../components/UI/ProfileSwitcher/ProfileSwitcher';
import Modal from "react-modal";
import CareerAssesment from "../../components/CareerPlanning/InterestsCarousel";
import { useResponsive } from '../../hooks/useResponsive';
import * as careerActions from "../../store/actions/CareerPlanning";

import classes from './LifeEventPage.module.css';

import maleAvatar from "../../assets/images/maleAvatar.svg";
import staceyAvatar from "../../assets/images/staceyAvatar.png";
import rosaAvatar from "../../assets/images/rosaAvatar.png";
import darylAvatar from "../../assets/images/darylAvatar.png";
import martinAvatar from "../../assets/images/martinAvatar.svg";
import eveAvatar from "../../assets/images/eveAvatar.svg";

import checkMarkLifeEvent from "../../assets/images/lifeEvents/checkMarkLifeEvent.svg";
import saveChangesLifeEvent from "../../assets/images/lifeEvents/saveLifeEvent.svg";
import editLifeEvent from "../../assets/images/lifeEvents/editLifeEvent.svg";
import addLifeEventImg from "../../assets/images/lifeEvents/addLifeEvent.svg";

import { eUserSettings } from '../../components/UserSettings/UserSettings';
import { getCalculator } from '../../utils/LifeEventTools/CalculatorHub';
import { handleSideNavigation, isCanonical } from '../../utils/global';
import { getNavMenu } from '../../utils/LifeEventTools/Mappers';
import SignUpModal from '../../components/UI/Modals/SignUpModal/SignUpModal';

import { addLifeEventToPlaylist, deleteLifeEvent, updateLifeEvent } from '../../store/actions/Users';
import { getLifeEventNameById, getCurrentLifeEventCustomData } from "../../utils/LifeEventTools/Mappers";
import RouteLeaveWarningGuard from '../../components/UI/RouteLeaveWarningGuard/RouteLeaveWarningGuard';
import NavigateModal from '../../components/LifeEvents/Transfer/NavigateModal';
import { LifeEventTransfer } from '../../components/LifeEvents/Transfer/Transfer';
import DeleteLifeEventButton from '../../components/LifeEvents/DeleteLifeEventButton/DeleteLifeEventButton';
import modalCardStyle from '../../components/UI/Modals/CommonModalInlineStyle';
import TagManager from 'react-gtm-module'

const NEW_STATUS = "NEW"
const CONFIRMED_STATUS = "CONFIRMED"
const UPDATE_STATUS = "UPDATE"
const CONFIGURED_STATUS = "CONFIGURED"
const SAVE_STATUS = "SAVE"
const TRANSFER_STATUS = "WAITING_FOR_CHANGES"

const LifeEventPage: React.FC<{}> = () => {
    const dispatch = useDispatch();
    let history = useHistory();

    const { screenIsAtLeast, screenIsAtMost } = useResponsive();

    //const dateNow = moment().toDate().toISOString();
    const user = useSelector((state: any) => state.session.user);
    const realUser = useSelector((state: any) => state.data.realUser);
    const lifeEventCustomDataSets = useSelector((state: any) => state.calculator);

    const [navKeys, setNavKey] = useState([]);
    const [currentView, setCurrentView] = useState("");

    const [currentLifeEvent, setCurrentLifeEvent] = useState({} as any);

    const [showCarousel, setShowCarousel] = useState(true);

    const [profileTab, setProfileTab] = useState(eUserSettings.PROFILE);
    const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);

    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [openLifeEventSaveModal, setLifeEventSaveModal] = useState(false);
    const [isRouteBlocking, setIsRouteBlocking] = useState(false);
    const [navigateModal, setNavigateModal] = useState(false);
    const [showCareerAssesment, setShowCareerAssesment] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [transferLifeEvent, setTransferLifeEvent] = useState(false);
    const [dismissLifeEventTransfer, setDismissLifeEventTransfer] = useState(false);
    const [temporaryLifeEventStatus, setTemporaryLifeEventStatus] = useState("");
    const [jobList, setJobList] = useState(null);
    const [interests, setInterests] = useState(null);

    // Google analytics set up for page
    const tagManagerArgs = {
        dataLayer: {
            userId: realUser?.trackingId,
            page: `${currentLifeEvent.externalId ? getLifeEventNameById(currentLifeEvent.externalId) : ''} Life Event`
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    const numConnectedAccounts =
        Object.keys(user) && Object.keys(user)?.length > 0
            ? user?.connectedAccounts?.length
            : 0;

    useEffect(() => {
        const url = window.location.href;
        const lifeEventId = url.split('/').pop();

        if (Object.keys(user).length > 0) {

            const lifeEventArray = user.lifeEvents.filter(le => le.id === lifeEventId);

            if (lifeEventArray.length > 0) {
                const menu = getNavMenu(getLifeEventNameById(lifeEventArray[0].externalId));
                setCurrentLifeEvent(lifeEventArray[0]);
                setNavKey(menu);
                setCurrentView(menu[0]);
                if (lifeEventArray[0].status === CONFIGURED_STATUS) {
                    setTemporaryLifeEventStatus(UPDATE_STATUS);
                } else {
                    setTemporaryLifeEventStatus(lifeEventArray[0].status);
                }
            }
            else {
                const historyState = history.location.state as any;
                if (historyState !== undefined) {
                    const menu = getNavMenu(historyState.name);
                    setCurrentLifeEvent(historyState);
                    setNavKey(menu);
                    setCurrentView(menu[0]);
                    if (historyState.status === CONFIGURED_STATUS) {
                        setTemporaryLifeEventStatus(UPDATE_STATUS);
                    } else {
                        setTemporaryLifeEventStatus(historyState.status);
                    }

                    if (historyState.externalId === '6ceIi2ikmezTJtxIDEuRsg' && historyState.status === NEW_STATUS) {
                        if (historyState.status === NEW_STATUS) {
                            setShowCareerAssesment(true);
                            setModalIsOpen(true);
                        }
                        else {
                            const getOnetCode = historyState.customData.selectedCareerOnetCode;
                            dispatch(careerActions.getJobInfo(getOnetCode));
                        }
                    }

                }
                else {
                    if (!openLifeEventSaveModal) {
                        history.push('/connect');
                    }
                }
            }
        }

    }, [user]);

    useEffect(() => {
        if (temporaryLifeEventStatus === CONFIRMED_STATUS) {
            //setDisplayLifeEventStatus(UPDATE_STATUS)
            setTemporaryLifeEventStatus(UPDATE_STATUS);
        }
        if (temporaryLifeEventStatus === UPDATE_STATUS) {
            //changed realUser to just user // changing back but also handling 1771 
            const findRetirementLE = realUser?.lifeEvents?.filter((l) => l.externalId === "3s7O7PtEaKgjdAML8huqsO");
            const allowRetireAdd = currentLifeEvent.externalId === "3s7O7PtEaKgjdAML8huqsO" && findRetirementLE?.length;
            if (user.anonymized && realUser && !allowRetireAdd) {
                setTemporaryLifeEventStatus(TRANSFER_STATUS)
            } else {
                setTemporaryLifeEventStatus(SAVE_STATUS)
            }
            //setIsRouteBlocking(true)
        }
        if (user.anonymized && temporaryLifeEventStatus === TRANSFER_STATUS) {
            setTransferLifeEvent(true)
        }
    }, [lifeEventCustomDataSets]);

    const onProfileSwitch = (name: string) => {
        //we check if the profile that we are going to render has the Career Goal LE
        //we know that for CP, only Stacey has it
        if (name !== "" && name !== "STACEY") {
            dispatch(careerActions.removeCurrentJob());
        }
        else {
            if (name === "STACEY") {
                dispatch(careerActions.getJobInfo(15 - 1299.08));
            }
            else {
                const careerGoalLE = realUser.lifeEvents.find(
                    (le: any) => le.externalId === "6ceIi2ikmezTJtxIDEuRsg" && le.status !== NEW_STATUS
                );
                if (careerGoalLE) {
                    const getOnetCode = careerGoalLE.customData.selectedCareerOnetCode;
                    dispatch(careerActions.getJobInfo(getOnetCode));
                } else {
                    dispatch(careerActions.removeCurrentJob());
                }
            }
        }

        setProfileTab(eUserSettings.PROFILE);
        setShowProfileSwitcher(false);
    };

    function closeCareerAssessmentModal() {
        setModalIsOpen(false);
        setShowCareerAssesment(false);
        history.push('/connect');
    }

    const onCareerAssesmentEnd = (result) => {
        setJobList(result.occupations);
        setInterests(result.interests);
        setShowCareerAssesment(false);
        window.scrollTo(0, 0);
    };

    const handleNavigateModal = (open: boolean) => {
        if (open) window.scrollTo(0, 0);
        setNavigateModal(open);
    };

    const onLifeEventStatusChangeHandler = (status: String) => {
        if (status === NEW_STATUS) {
            if (isCanonical(user.name)) {
                setShowSignUpModal(true);
            } else {
                setLifeEventSaveModal(true);
            }
        } else if (
            status === UPDATE_STATUS ||
            status === SAVE_STATUS ||
            status === TRANSFER_STATUS
        ) {
            setLifeEventSaveModal(true);
            if (status === TRANSFER_STATUS) {
                setTemporaryLifeEventStatus(SAVE_STATUS)
            }
        }
    };

    const handleLifeEventStatusImg = (displayLifeEventStatus: string) => {
        switch (displayLifeEventStatus) {
            case NEW_STATUS:
                return (
                    <img alt="Status" src={addLifeEventImg} className={classes.badgeStatus} />
                );
            case UPDATE_STATUS:
                return (
                    <img alt="Status" src={editLifeEvent} className={classes.badgeStatus} />
                );
            case SAVE_STATUS:
            case TRANSFER_STATUS:
                return (
                    <img
                        alt="Status"
                        src={saveChangesLifeEvent}
                        style={{ position: "relative", left: "8rem", top: "-1.5rem" }}
                    />
                );
            case CONFIRMED_STATUS:
                return (
                    <img alt="Status" src={checkMarkLifeEvent} className={classes.badgeStatus} />
                );
            default:
                return <></>;
        }
    };

    const onAddLifeEvent = async ({ nickName, startDate, date }) => {
        if (currentLifeEvent.id !== "") { //I think...
            const lifeEventData = {
                startDate: startDate,
                date: date,
                name: nickName,
                customData: getCurrentLifeEventCustomData(
                    currentLifeEvent.externalId,
                    lifeEventCustomDataSets
                ),
                externalId: currentLifeEvent.externalId,
            };
            dispatch(
                updateLifeEvent(
                    currentLifeEvent.id,
                    lifeEventData,
                    history,
                    CONFIRMED_STATUS
                )
            );
        } else {
            // I'm not too sure why but some life events have a planId = null
            // for these weird cases I'm using this ternary for now...
            const planId = currentLifeEvent.relatedPlanId ? currentLifeEvent.relatedPlanId : realUser.plans[0].planId;
            dispatch(addLifeEventToPlaylist(planId, {
                externalId: currentLifeEvent.externalId,
                name: nickName,
                startDate: startDate,
                date: date,
                status: CONFIRMED_STATUS,
                customData: getCurrentLifeEventCustomData(
                    currentLifeEvent.externalId,
                    lifeEventCustomDataSets
                )
            }, history, true));
        }

        if (currentLifeEvent.name === "Career Goal") {
            const existingCareerGoal = user.lifeEvents.find(
                (le: any) => le.name === "Career Goal" && le.status !== NEW_STATUS
            );
            if (existingCareerGoal) {
                dispatch(deleteLifeEvent(existingCareerGoal.id, history));
            }
            dispatch(
                careerActions.getJobInfo(
                    getCurrentLifeEventCustomData(
                        currentLifeEvent.name,
                        lifeEventCustomDataSets
                    ).selectedCareerOnetCode
                )
            );
        }
    };

    const onUpdateLifeEvent = async ({ nickName, startDate, date }) => {
        await dispatch(
            updateLifeEvent(
                currentLifeEvent.id,
                {
                    name: nickName,
                    startDate: startDate,
                    date: date,
                    customData: getCurrentLifeEventCustomData(
                        currentLifeEvent.externalId,
                        lifeEventCustomDataSets
                    ),
                },
                history,
                CONFIRMED_STATUS
            )
        );
        setIsRouteBlocking(false);
    };

    /*const onUpdateLifeEventRouteBlocked = async (nickName = displayLifeEvent, date = dateNow) => {
        await dispatch(
          updateLifeEvent(
            currentLifeEvent.id,
            {
              name: nickName,
              date: date,
              customData: getCurrentLifeEventCustomData(
                getLifeEventNameById(currentLifeEvent.externalId),
                lifeEventCustomDataSets
              ),
            },
            history
          )
        );
        setIsRouteBlocking(false);
      }; */

    const onDeleteLifeEvent = async () => {
        //if the LE is not part of a plan, we delete it
        //otherwise, we need to update it to NEW an clean everything
        //we also restore LE's original name, in case user nicknamed it before
        setIsRouteBlocking(false);
        //let index = -1;
        //if (user.plans.length > 0) {
        //  index = user.plans[0].lifeEvents.findIndex((le) => le.id === currentLifeEvent.id);
        //}

        /* if (index !== -1) {
            const le = user.plans[0].lifeEvents[index];
            const realLEName = getLifeEventNameById(le.externalId);
            await dispatch(
                updateLifeEvent(
                    currentLifeEvent.id,
                    {
                        name: realLEName,
                        date: '',
                        customData: {},
                    },
                    history,
                    NEW_STATUS
                )
            );
        }  */

        if (currentLifeEvent.id === "") {
            //Edge case: if user adds an LE, and inmediately tries to delete it
            dispatch(deleteLifeEvent(user.lifeEvents[user.lifeEvents.length - 1].id, history));
        }
        else {
            dispatch(deleteLifeEvent(currentLifeEvent.id, history));
        }

        if (currentLifeEvent.name === "Career Goal") {
            dispatch(careerActions.removeCurrentJob());
        }

        history.push('/connect/myplaylist');
    };

    const copyLifeEvent = ({ nickName, startDate, date }) => {
        /*dispatch(
            addLifeEvent(
                {
                    originalName: currentLifeEvent.name,
                    name: nickName,
                    image: currentLifeEvent.icon[0].url,
                    date: date,
                    status: "CONFIRMED",
                    customData: getCurrentLifeEventCustomData(
                        currentLifeEvent.name,
                        lifeEventCustomDataSets
                    ),
                },
                history,
                true
            )
        ); */
        dispatch(addLifeEventToPlaylist(realUser.plans[0].planId, {
            externalId: currentLifeEvent.externalId,
            originalName: currentLifeEvent.name,
            name: nickName,
            startDate: startDate,
            date: date,
            status: CONFIRMED_STATUS,
            customData: getCurrentLifeEventCustomData(
                currentLifeEvent.externalId,
                lifeEventCustomDataSets
            )
        }, history, false));
        setTransferLifeEvent(false);
        setNavigateModal(true);
    };

    const onSaveLifeEvent = (formData: any) => {
        switch (temporaryLifeEventStatus) {
            case UPDATE_STATUS:
            case SAVE_STATUS: {
                onUpdateLifeEvent(formData);
                break;
            }
            case TRANSFER_STATUS: {
                copyLifeEvent(formData);
                break;
            }
            default: {
                onAddLifeEvent(formData);
                break;
            }
        }
        // Going back to previous page was requested by Ignacio
        // but this interferes with the transfer a le from persona 
        // flow as a user never gets to see the 
        // "stay on persona or go back to my profile"
        // history.goBack()
    }

    const openConnectedAccounts = () => {
        if (user.anonymized) return;
        setProfileTab(eUserSettings.CONNECTED_ACCOUNTS);
        setShowProfileSwitcher(true);
        setShowCarousel(false);
    };

    let content = null;

    if (Object.keys(user).length > 0 && Object.keys(currentLifeEvent).length > 0) {

        let profiles = [
            {
                name: "Stacey",
                img: staceyAvatar,
                label: "Planning for College",
                profileNumber: 2,
            },
            {
                name: "Rosa",
                img: rosaAvatar,
                label: "Begin Career",
                profileNumber: 3,
            },
            {
                name: "Daryl",
                img: darylAvatar,
                label: "Tools for Parents",
                profileNumber: 4,
            },
            {
                name: "Martin",
                img: martinAvatar,
                label: "Buy a House",
                profileNumber: 5,
            },
            {
                name: "Eve",
                img: eveAvatar,
                label: "Planning for Retirement",
                profileNumber: 6,
            },
        ];

        let selectCardContent = {
            name: user.name,
            img: !user.anonymized
                ? maleAvatar
                : user.name.length > 0
                    ? profiles.filter(
                        (profile) =>
                            profile.name.toLowerCase() === user.name.toLowerCase()
                    )[0].img
                    : "",
            label: !user.anonymized
                ? ""
                : user.name.length > 0
                    ? profiles.filter(
                        (profile) =>
                            profile.name.toLowerCase() === user.name.toLowerCase()
                    )[0].label
                    : "",
            profileNumber: !user.anonymized
                ? 2
                : profiles.filter(
                    (profile) =>
                        profile.name.toLowerCase() === user.name.toLowerCase()
                )[0].profileNumber - 1
        };

        content = (
            <div className={`${screenIsAtMost('tabLand') ? classes.containerMobile : [classes.container, classes.treeBackground].join(' ')}`}>

                <RouteLeaveWarningGuard
                    when={isRouteBlocking}
                    navigate={(path) => history.push(path)}
                    shouldBlockNavigation={(location) => {
                        const currPath = history.location.pathname;
                        const parentPath = currPath.substring(0, currPath.lastIndexOf('/'));
                        const nxtPath = location.pathname;
                        const nxtParentPath = nxtPath.substring(0, nxtPath.lastIndexOf('/'));
                        if (parentPath !== nxtParentPath) {
                            setIsRouteBlocking(false);
                            return true
                        }
                    }}
                    onConfirm={() => {
                        //onUpdateLifeEventRouteBlocked
                    }}
                    onCloseDiscard={() => {
                        //clearCurrentLifeEvent
                    }}
                />

                {showSignUpModal && (
                    <Backdrop onClick={() => setShowSignUpModal(false)}>
                        <SignUpModal
                            isLoggedUser={realUser && !realUser.anonymized}
                            onClickAsLoggedUser={() => history.push('/connect')}
                            onClickExit={() => setShowSignUpModal(false)}
                        />
                    </Backdrop>
                )}

                {showProfileSwitcher && (
                    <Backdrop
                        customStyle={{
                            position: "fixed",
                            overflow: "auto",
                        }}
                        onClick={() => {
                            setShowCarousel(true);
                            setProfileTab(eUserSettings.PROFILE);
                            setShowProfileSwitcher(false);
                        }}
                    >
                        <ProfileSwitcher
                            hide={(hide: boolean) => { }}
                            startingProfile={selectCardContent.profileNumber}
                            onSelectedProfile={onProfileSwitch}
                            profileTab={profileTab}
                            showCarousel={showCarousel}
                            onClose={() => {
                                setShowCarousel(true);
                                setShowProfileSwitcher(false)}}
                        />
                    </Backdrop>
                )}

                {showCareerAssesment && (
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeCareerAssessmentModal}
                        style={{
                            content: { ...modalCardStyle().content, width: "100%" },
                            overlay: modalCardStyle().overlay,
                        }}
                    >
                        <CareerAssesment
                            onEnd={onCareerAssesmentEnd}
                            closeModal={closeCareerAssessmentModal}
                        />
                    </Modal>
                )}

                <div
                    style={{ flexDirection: screenIsAtMost('tabLand') ? 'column' : 'row', alignItems: screenIsAtMost('tabLand') ? 'center' : '' }}
                    className={classes.content}
                >

                    {showProfileSwitcher && (
                        <Backdrop
                            customStyle={{
                                position: "fixed",
                                overflow: "auto",
                            }}
                            onClick={() => {
                                setShowCarousel(true);
                                setProfileTab(eUserSettings.PROFILE);
                                setShowProfileSwitcher(false);
                            }}
                        >
                            <ProfileSwitcher
                                hide={(hide: boolean) => { }}
                                startingProfile={selectCardContent.profileNumber}
                                onSelectedProfile={onProfileSwitch}
                                profileTab={profileTab}
                                showCarousel={showCarousel}
                                onClose={() => {
                                    setShowCarousel(true);
                                    setShowProfileSwitcher(false)}}
                            />
                        </Backdrop>
                    )}

                    {showCareerAssesment && (
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeCareerAssessmentModal}
                            style={{
                                content: { ...modalCardStyle().content, width: "100%" },
                                overlay: modalCardStyle().overlay,
                            }}
                        >
                            <CareerAssesment
                                onEnd={onCareerAssesmentEnd}
                                closeModal={closeCareerAssessmentModal}
                            />
                        </Modal>
                    )}


                    {screenIsAtLeast("desktop") && (
                        <NavigationView
                            profile={user.anonymized}
                            connectedAccounts={numConnectedAccounts}
                            connectedKey=""
                            onConnectedLinkClick={(event, item) => {
                                handleSideNavigation(item.name, history)
                            }}
                            onConnectButtonClick={() => history.push("/connect")}
                            onProtectButtonClick={() => history.push("/protect/securevault")}
                            onGrowButtonClick={() => history.push("/grow/overview")}
                            onConnectAccountClick={openConnectedAccounts}
                            currentDashboard="CONNECT"
                            selectCardContent={selectCardContent}
                            onSelectBubleClickHandler={() => {
                                setProfileTab(eUserSettings.PROFILE);
                                setShowProfileSwitcher(true);
                            }}
                        />
                    )}

                    {screenIsAtMost("tabLand") &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '85%', paddingTop: "3rem" }}>

                            <EFBreadcrumb
                                onParentClick={() => { history.push("/connect") }}
                                parentTitle="CONNECT"
                                profile={user.anonymized}
                                connectedAccounts={0}
                                connectedKey=""
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect/securevault")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                childrenTitles={
                                    ["LIFE EVENT"]
                                }
                                onConnectAccountClick={openConnectedAccounts}
                                style={{
                                    flexFlow: 'row',
                                    alignItems: 'center',
                                    width: '59%',
                                    justifyContent: 'space-between'
                                }}
                                onSelectBubleClickHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />

                            <Greeting
                                hideMenuLogo
                                hideSalutation
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={numConnectedAccounts}
                                connectedKey=""
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect/securevault")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                onConnectAccountClick={openConnectedAccounts}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />
                        </div>

                    }

                    <DashboardContainer childrenGap={30}>
                        <div className={classes.headerSection}>

                            {!screenIsAtMost("tabLand") &&
                                <EFBreadcrumb
                                    onParentClick={() => { history.push("/connect") }}
                                    parentTitle="CONNECT"
                                    profile={user.anonymized}
                                    connectedAccounts={0}
                                    connectedKey=""
                                    onConnectedLinkClick={
                                        () => { }
                                    }
                                    onConnectButtonClick={() => { }}
                                    onProtectButtonClick={() => { }}
                                    onGrowButtonClick={() => { }}
                                    childrenTitles={
                                        ["LIFE EVENT"]
                                    }
                                    onConnectAccountClick={() => { }}
                                />}

                            {!screenIsAtMost("tabLand") && <Greeting
                                hideSalutation
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={numConnectedAccounts}
                                connectedKey=""
                                onConnectedLinkClick={
                                    () => {

                                    }
                                }
                                onConnectButtonClick={() => {

                                }}
                                onProtectButtonClick={() => {

                                }}
                                onGrowButtonClick={() => {

                                }}
                                onConnectAccountClick={() => {

                                }}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />}

                        </div>

                        <div className={classes.lifeEventImgContainer}>
                            <div onClick={() => { onLifeEventStatusChangeHandler(temporaryLifeEventStatus) }} className={classes.lifeEventBadge}
                                style={{ backgroundImage: `url(${currentLifeEvent.icon[0].url})` }}>
                                {handleLifeEventStatusImg(temporaryLifeEventStatus)}
                            </div>
                            <input
                                className={classes.lifeEventTitle}
                                value={currentLifeEvent.name}
                                onChange={(e: any) => {
                                    setCurrentLifeEvent({ ...currentLifeEvent, name: e.target.value });
                                    if (temporaryLifeEventStatus !== NEW_STATUS) {
                                        setTemporaryLifeEventStatus(SAVE_STATUS);
                                    }
                                }}
                            />
                        </div>

                        <div className={classes.tabMenu}>
                            {navKeys.map((nav) => {
                                return (
                                    <NavButton
                                        id={`${nav
                                            .toLowerCase()
                                            .split(" ")
                                            .join("")}-button`}
                                        dataTourId={`${nav
                                            .toLowerCase()
                                            .split(" ")
                                            .join("")}-tab`}
                                        key={nav}
                                        text={nav}
                                        onClick={() => {
                                            setCurrentView(nav);
                                        }}
                                        isFocused={currentView === nav ? true : false}
                                    />
                                );
                            })}
                        </div>

                        <div className={classes.lifeEventSection}>

                            {!showCareerAssesment && getCalculator(
                                //Career Goal. Otherwise, we need to launch the assessment tool
                                // Passing an object consisting of life event values 
                                // and save le modal handlers
                                {
                                    ...currentLifeEvent,
                                    openSaveModal: openLifeEventSaveModal,
                                    onOpenSaveModal: () => setLifeEventSaveModal(false),
                                    onSaveLifeEvent: onSaveLifeEvent,
                                    onUpdateLifeEventStatus: () => setTemporaryLifeEventStatus(SAVE_STATUS),
                                },
                                isCanonical(user.name) || currentLifeEvent.status !== NEW_STATUS
                                    ? { showModal: false }
                                    : {
                                        showModal: true,
                                        exitModal: () => history.goBack(),
                                    },
                                currentView,
                                {
                                    jobList: jobList,
                                    interests: interests,
                                },
                            )}

                            <DeleteLifeEventButton
                                onClick={() => {
                                    onDeleteLifeEvent();
                                }}
                                showButton={
                                    !isCanonical(user.name) && currentLifeEvent.status !== NEW_STATUS
                                }
                            />

                        </div>

                        {navigateModal && (
                            <NavigateModal
                                isOpen={navigateModal}
                                handleModal={() => handleNavigateModal(false)}
                                navigateScoop={() => history.push('/connect')}
                            />
                        )}

                    </DashboardContainer>
                </div>

                {transferLifeEvent && !dismissLifeEventTransfer && (
                    <LifeEventTransfer
                        isLoggedUser={realUser && !realUser.anonymized}
                        lifeEventName={currentLifeEvent.name}
                        actingAs={user.name}
                        onDismiss={() => setDismissLifeEventTransfer(true)}
                        onConfirm={() => {
                            setLifeEventSaveModal(true);
                            setIsRouteBlocking(false);
                        }}
                    />
                )}

            </div>
        );
    }

    return content;
};

export default LifeEventPage;
