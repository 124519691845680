import React, { useState } from 'react';
import ScoopMaskedLabelPair from '../ScoopMaskedLabelPair/ScoopMaskedLabelPair';
import { Stack } from 'office-ui-fabric-react';

import check from '../../../assets/images/scoop/scoopcheck.svg';
import classes from './ScoopAccordion.module.css';
interface IProps {
    id?: any,
    title: string,
    value?: number,
    color?: string,
    isNotOpenable?: boolean,
    style?: object,
    containerStyle?: object,
    stackClassName?: any,
    onClick?: () => void,
    useCheckerIcon?: boolean,
};

const EFAccordion: React.FC<IProps> = props => {
    const [opened, setOpened] = useState<boolean>(true);

    const labelPairWithChecker = (
        <Stack horizontal horizontalAlign="space-between" style={{width: '100%'}}>
            <ScoopMaskedLabelPair
                isBulleted
                color={props.color}
                title={props.title}
                value={props.value}
            />
            {props.useCheckerIcon ? <img src={check} alt='check-mark' style={{ width: '2.3rem', height: '2.3rem' }} /> : <></>}
        </Stack>
    )

    const labelPairWithoutChecker = (
        <Stack style={{width: '100%'}}>
            <ScoopMaskedLabelPair
                isBulleted
                color={props.color}
                title={props.title}
                value={props.value}
            />
        </Stack>
    )
    
    return (
        <div style={props.containerStyle} onClick={props.onClick} key={props.id} className={`${classes.accordion} ${opened && classes.accordionopened}`}>
            <div 
                onClick={() => props.isNotOpenable ? null : setOpened(!opened)} 
                className={`${classes.line} ${opened && classes.lineopened} ${props.stackClassName}`}
                style={props.style}
            >
                {props.useCheckerIcon ? labelPairWithChecker : labelPairWithoutChecker}
                {props.isNotOpenable ? null : (
                    <span className={`${classes.icon} ${opened && classes.iconopened}`} />
                )}
            </div>
            <div className={`${classes.inner} ${opened && classes.inneropened}`}>
                <Stack 
                    verticalAlign='center' 
                    tokens={{childrenGap: 20}} 
                    className={`${classes.content} ${opened && classes.contentopened}`}
                >
                    {props.children}
                </Stack>
            </div>
        </div>
    )
};

export default EFAccordion;
