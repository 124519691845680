import React, { useState, useEffect } from 'react';
import Tour, { Navigation, Dot, Controls, Arrow, Close, Badge } from 'reactour';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import EFCheckbox from '../../Atomic/EFCheckbox/EFCheckbox';
import Step, { styles } from '../TourUI/Step/Step';
import { useResponsive } from '../../../hooks/useResponsive';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_TOUR_GUIDE } from '../../../hooks/Apollo/mutations';
import * as actions from '../../../store/actions/Users';

import '../TourUI/CustomHelper/TourGuide.css';

const OverviewTour: React.FC<{}> = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector((state: any) => state.session.user);
    const [updateTourGuide] = useMutation(UPDATE_TOUR_GUIDE);

    const [checkbox, setCheckbox] = useState(false);
    const [showTour, setShowTour] = useState(!user.tourGuide?.grow && user.actingAs === "ME");
    const [grow, setGrow] = useState({key: '', title: '', description: ''});

    useEffect(() => {
        const BASE_URL = 'https://cdn.contentful.com';
        const SPACE_ID = 'up7kjdcvffhd';
        const API_KEY =  'shmWB5u1iYWryX13wK2T97mzEoVcnH6F3Bp4lnBCLuw';

        const loadData = async () => {
            const resp = await fetch(`${BASE_URL}/spaces/${SPACE_ID}/entries?access_token=${API_KEY}&content_type=guideInfo`);
            const data = await resp.json();
            const entries = data.items.map(e => e.fields);
            setGrow(entries.find(e => e.key === "GROW"));
        }
        if (showTour) {
            loadData();   
        }
    }, []);

    const tourConfigConnect = [
        {
            id: 'GROW',
            selector: '[data-tour="grow"]',
            content: () => <Step title={grow?.title} desc={grow?.description} />,
            position: 'right',
            disableInteraction: true,
            style: { ...styles.tour, backgroundColor:  'rgba(170, 209, 126, 0.8)'},
        },
    ]

    const onRequestClose = async () => {
        setShowTour(false);
        if (checkbox) {
            await updateTourGuide({
                variables: {
                    tourGuide: {
                        ...user.tourGuide,
                        grow: true,
                    }
                }
            }).catch(error => console.error(error));
            dispatch(actions.getUpdatedUserFullProfile(history));
        }
    }

    function CustomHelper({ current, content, totalSteps, gotoStep, close }) {
        const { screenIsAtLeast } = useResponsive();
    
        return (
          <main className="CustomHelper__wrapper">
            <Badge className="CustomHelper_badge">{current + 1}</Badge>
            <div className="CustomHelper__content">
              {content}
              <Controls
                data-tour-elem="controls"
                className="CustomHelper__controls"
              >
                <div style={{display:'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: '1rem'}}>
                  <Arrow
                    onClick={() => gotoStep(current - 1)}
                    disabled={current === 0}
                    className="CustomHelper__navArrow"
                    style={{color: 'white', backgroundColor: 'red'}}
                  />
                  {screenIsAtLeast('desktop') && (
                      <Navigation data-tour-elem="navigation">
                          {Array.from(Array(totalSteps).keys()).map((li, i) => (
                              <Dot
                              key={li}
                              className="CustomHelper__dot"
                              onClick={() => current !== i && gotoStep(i)}
                              current={current}
                              index={i}
                              disabled={current === i}
                              showNumber={true}
                              data-tour-elem="dot"
                              />
                          ))}
                      </Navigation>
                  )}
                  <Arrow
                    onClick={() => gotoStep(current + 1)}
                    disabled={current === totalSteps - 1}
                    className="CustomHelper__navArrow"
                    inverted
                  />
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <EFCheckbox 
                        id="showtour" 
                        checked={checkbox} 
                        onChange={() => setCheckbox(!checkbox)}
                    />
                    <EFLabel style={{color: '#293861', fontSize: '1.3rem', marginLeft: '.5rem', fontWeight: '400'}}>
                        Do not show this again
                    </EFLabel>
                </div>
              </Controls>
            </div>
            <Close
                className="CustomHelper__exit"
                onClick={close}
            />
          </main>
        )
    }

    return (
        <Tour
            CustomHelper={CustomHelper}
            onRequestClose={onRequestClose}
            steps={tourConfigConnect}
            isOpen={showTour}
            rounded={5}
            accentColor="#293861"
            maskClassName="mask"
            disableInteraction
            onAfterOpen={disableBodyScroll}
            onBeforeClose={enableBodyScroll}
        />
    )
};

export default OverviewTour;