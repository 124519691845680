import React from 'react';
import classes from './Affordability.module.css';
import { Stack } from 'office-ui-fabric-react';
import EFStepperInput from '../../UI/EFStepperInput/EFStepperInput';
import ResultReport from '../Common/ResultReport';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import ConfigButton from '../Common/ConfigButton';
import DividerContainer from '../../UI/Divider/DividerContainer';
import Divider from '../../UI/Divider/Divider';
import { useConfigButtons } from '../../../hooks/useConfigButtons';
import EFAccordion from '../../Atomic/EFAccordion/EFAccordion';
import LimitButtons from '../Common/LimitButtons/LimitButtons';
import { useSelector } from 'react-redux';
import { useAffordability } from '../../../hooks/Calculators/useAffordability';
import SaveChangesModal from '../../LifeEvents/SaveChangesModal/SaveChangesModal';
import AffordabilityTemp from '../../LifeEvents/Interstitials/DebtInterstitial/AffordabilityTemp';
import AffordabilityOnboarding from './AffordabilityOnboarding';

interface Props {
    handleModal: { showModal: boolean, exitModal?: () => void };
    currentView: string;
    lifeEventDetails?: any,
}

const Affordability: React.FC<Props> = props => {
    const selectedJob = useSelector((state: any) => state.career.jobInfo);
    const {
        loanAmount,
        setLoanAmount,
        annualIncome,
        setAnnualIncome,
        interest,
        setInterest,
        termsInYears,
        setTermsInYears,
        affordabilityMinLimit,
        affordabilityMaxLimit,

        affordabilityTotalToPay,
        affordabilityMonthlyPayment,
        affordabilityZone,
    } = useAffordability(props.lifeEventDetails.id);

    // Handles Configuration Buttons Logic
    const {setup, onSetup, simu, onSimu, lvl, onLvl} = useConfigButtons();

    const maskAmount = (value: number) => {
        let x = Math.abs(value);
        if (x < 1000) {
            return '$' + Math.round(value);
        } else if (x < 1000000) {
            return '$' + Math.round(value / 1000) + 'k';
        } else {
            return '$' + Math.round(value / 1000000) + 'm';
        }
    };

    const careerTitle = selectedJob ? selectedJob.name.toUpperCase() : '';
    const midWage = selectedJob ? maskAmount(selectedJob.midWage) : '';
    const estTotalLoan = selectedJob ? maskAmount(affordabilityTotalToPay) : '';
    const estAnnualLoanPayment = selectedJob ? maskAmount(affordabilityMonthlyPayment * 12) : '';
    
    const careerReport = (
        <Stack className={classes.careerContainer} horizontalAlign='center' verticalAlign='center'>
            <Stack horizontal className={classes.careerTitle} horizontalAlign='center' verticalAlign='center' tokens={{childrenGap: '1.9rem'}}>
                <img alt='career-icon' src={require('../../../assets/images/career_icon.svg')} />
                <EFLabel style={{fontSize: '1.6rem', color: '#D679F3', height: '4rem', width: '30rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', marginTop: 23}}>{careerTitle}</EFLabel>
            </Stack>
            <Stack className={classes.careerDetails} tokens={{childrenGap: '1.7rem'}}>
                <Stack horizontal className={classes.careerItem} horizontalAlign='space-between'>
                    <EFLabel style={{fontWeight: 'normal', fontSize: '1.6rem'}}>Median First Year Earnings</EFLabel>
                    <EFLabel style={{fontSize: '1.6rem'}}>{midWage}</EFLabel>
                </Stack>
                <div className={classes.divider} />
                <Stack horizontal className={classes.careerItem}  horizontalAlign='space-between'>
                    <EFLabel style={{fontWeight: 'normal', fontSize: '1.6rem'}}>Estimated student loan balance</EFLabel>
                    <EFLabel style={{fontSize: '1.6rem'}}>{estTotalLoan}</EFLabel>
                </Stack>
                <div className={classes.divider} />
                <Stack horizontal className={classes.careerItem} horizontalAlign='space-between'>
                    <EFLabel style={{fontWeight: 'normal', fontSize: '1.6rem'}}>Estimated student loan annual payment</EFLabel>
                    <EFLabel style={{fontSize: '1.6rem'}}>{estAnnualLoanPayment}</EFLabel>
                </Stack>
            </Stack>
        </Stack>
    );

    // setup content
    const setUpContent = (
        <>
            <EFStepperInput
                label='Student Loan Amount'
                onChange={(val: number) => setLoanAmount(val)}
                value={loanAmount}
                prefix='$'
                includeThousandsSeparator
                dynamicStepEnabled
                minVal={0} // get min
                maxVal={250000} // get max
            />
            <EFStepperInput
                label='Student Loan Term (Months)'
                onChange={(val: number) => setTermsInYears(val / 12)}
                value={termsInYears * 12}
                minVal={0} // get min
                maxVal={480} // get max
            />
            <EFStepperInput
                label='Student Loan Interest Rate'
                onChange={(val: number) => setInterest(val)}
                value={interest}
                allowDecimal
                numStepper={.25}
                suffix='%'
                minVal={0} // get min
                maxVal={15} // get max
            />
        </>
    );

    // simu content
    const simuContent = (
        <>
            <EFStepperInput
                label='Monthly Future Salary'
                onChange={(val: number) => setAnnualIncome(val * 12)}
                value={annualIncome / 12}
                prefix='$'
                includeThousandsSeparator
                dynamicStepEnabled
                minVal={0}
                maxVal={100000}
            />
        </>
    );

    return (
        <>
            <AffordabilityOnboarding 
                handleModal={props.handleModal}
                setDebtAmount={(val: number) => setLoanAmount(val)}
                setPayOffTermInMonths={(val: number) => setTermsInYears(val / 12)}
                setInterest={(val: number) => setInterest(val)} 
            />
            <SaveChangesModal
                openLifeEventSaveModal={props.lifeEventDetails.openSaveModal}
                onClose={props.lifeEventDetails.onOpenSaveModal}
            >
                <AffordabilityTemp 
                    lifeEventDetails={props.lifeEventDetails}
                    onClick={props.lifeEventDetails.onSaveLifeEvent}
                    onCloseClick={props.lifeEventDetails.onOpenSaveModal}
                    loanTerm={termsInYears * 12}
                />
            </SaveChangesModal>
            <Stack horizontalAlign='center' tokens={{ childrenGap: 20 }}>
                {/* Not responsive */}
                <Stack style={{ minWidth: '85rem' }}>
                    <Stack horizontal horizontalAlign='center' className={classes.dividercontainer} tokens={{childrenGap: '3rem'}}>
                        <Divider
                            isOpen
                            title='Loan Affordability'
                            icon={require('../../../assets/icons/transparent.svg')}
                        />
                    </Stack>
                    <Stack horizontalAlign='center'>
                            <DividerContainer>
                                <Stack className={classes.container} horizontal horizontalAlign='space-between' tokens={{childrenGap: '10rem'}}>
                                    <Stack tokens={{childrenGap: '2rem'}}>
                                        <EFLabel style={{fontWeight: 'normal', fontSize: '2.2rem'}}>Based on your career selection: </EFLabel>
                                        <Stack className={classes.careerSection} horizontalAlign='center' tokens={{childrenGap: '3.3rem'}}>
                                            {careerReport}
                                            {/* {!user.anonymized && <EFButton text='Retake Quiz' onClick={() => props.onQuiz(true)} stackClassName={classes.quizBtn} />} */}
                                        </Stack>
                                    </Stack>
                                    <Stack verticalAlign='center' horizontalAlign='center' tokens={{childrenGap: '6rem'}} className={classes.report}>
                                        <ResultReport
                                            isGreen={affordabilityZone !== 2}
                                            onChange={() => null}
                                            reportGreenLabel='Loan payment is manageable'
                                            reportRedLabel='Loan payment is not manageable'
                                        />
                                        <LimitButtons 
                                            currLimit={affordabilityZone} 
                                            affordabilityMaxLimit={affordabilityMaxLimit} 
                                            affordabilityMinLimit={affordabilityMinLimit}
                                        />
                                    </Stack>
                                </Stack>
                            </DividerContainer>
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign='center' tokens={{childrenGap: '4rem'}}>
                    <ConfigButton type='setup' toggleFocus={setup} onClick={onSetup} />
                    <ConfigButton type='simu' toggleFocus={simu} onClick={onSimu} />
                    <ConfigButton type='levelup' toggleFocus={lvl} onClick={onLvl} />
                </Stack>
                {setup && (
                    <Stack horizontal horizontalAlign='center' tokens={{childrenGap: '1rem'}}>
                        <EFAccordion title='Student Loan'>
                            {setUpContent}
                        </EFAccordion>
                    </Stack>
                )}
                {simu && (
                    <Stack horizontal horizontalAlign='center' tokens={{childrenGap: '1rem'}}>
                        <EFAccordion title='Expected Income'>
                            {simuContent}
                        </EFAccordion>
                    </Stack>
                )}
                {lvl && (
                    <Stack horizontal horizontalAlign='center'>
                        <EFLabel style={{color: '#9EA4B3', margin: '4rem'}}>No advanced options</EFLabel>
                    </Stack>
                )}
            </Stack>
        </>
    )
}

export default Affordability;
