import React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/';
import classes from './EFSpinner.module.css';

interface IProps {
    style?: any,
};

const EFSpinner: React.FC<IProps> = props => {
    const styles = {
        circle: {
            width: '5rem',
            height: '5rem',
            borderColor: 'rgba(254, 184, 30, 1) rgba(254, 184, 30, 0.4) rgba(254, 184, 30, 0.4)',
            borderWidth: '.4rem',
        }
    }
    return <Spinner style={props.style} styles={styles} size={SpinnerSize.large} /> 
};

export default EFSpinner;
