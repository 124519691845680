import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import SettingsOptions from './SettingsOptions/SettingsOptions';
import PrivacyOptions from './SettingsOptionsValue/PrivacyOptions';
import NotificationsOptions from './SettingsOptionsValue/NotificationsOptions';
import LegalOptions from './SettingsOptionsValue/LegalOptions';
import SettingsModal from './SettingsOptionsValue/SettingsModal';

import { Card } from '@uifabric/react-cards';
import settingsLock from '../../../assets/images/settingsLock.svg';
import settingsLockGreen from '../../../assets/images/settingsLockGreen.svg';
import notificationBell from '../../../assets/images/notificationBell.svg';
import notificationBellGreen from '../../../assets/images/notificationBellGreen.svg';
import helpBadge from '../../../assets/images/helpBadge.svg';
import helpBadgeGreen from '../../../assets/images/helpBadgeGreen.svg';
import logout from '../../../assets/icons/logout.svg';
import logoutGreen from '../../../assets/icons/logoutGreen.svg';
import avatarGreen from '../../../assets/images/avatarGreen.svg';
import avatarWhite from '../../../assets/images/avatarWhite.svg';
import tourGuideWhite from '../../../assets/images/tourGuideWhite.svg';
import tourGuideGreen from '../../../assets/images/tourGuideGreen.svg';

import { entries, getEntity } from '../../../contentful/contenful';
import { MARKS } from '@contentful/rich-text-types';
import { logOut } from '../../../store/actions/Users';
import { onboardingChangeState } from '../../../store/actions/Screens';
import AvatarOptions from './SettingsOptionsValue/AvatarOptions';
import TourGuideOptions from './SettingsOptionsValue/TourGuideOptions';

export enum Options {
    avatar = "Avatar",
    tourGuide = "Tour Guide",
    privacy = "Privacy",
    notifications = "Notifications",
    help = "Help",
    legal = "Legal",
    logout = "Sign Out",
}

const options = {
    renderMark: {
        [MARKS.BOLD]: text => {
            const boldWords = ["info@equalfuture.com", "info@equalfuture.com."];
            return text && text.props || boldWords.includes(text) ? <span>{text}</span> : <b>{text}</b>
        }
    }
}

const Settings: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedOption, setSelectedOption] = useState(Options.avatar);
    const [openModal, setOpenModal] = useState(true);

    const [helpContent, setHelpContent] = useState(null);
    const [termsContent, setTermsContent] = useState(null);
    const [privacyContent, setPrivacyContent] = useState(null);

    const user = useSelector((state: any) => state.session.user);

    useEffect(() => {
        getEntity({ entryId: entries.help, callback: setHelpContent, options })
        getEntity({ entryId: entries.termsOfUse, callback: setTermsContent, options })
        getEntity({ entryId: entries.privacy, callback: setPrivacyContent, options })
    }, [])

    const handleModal = () => {
        setOpenModal(!openModal);
        window.scrollTo(0, 0);
    };

    const handleOption = (option: string) => {
        if (option === Options.logout) {
            dispatch(onboardingChangeState(false));
            dispatch(logOut(history))
        }
        else {
            setSelectedOption(option as Options);
        }
    }

    const renderModal = (Content: JSX.Element) => {
        if (openModal) {
            return (
                <SettingsModal
                    Content={helpContent}
                    handleModal={handleModal} />
            )
        }
        else {
            setSelectedOption(Options.privacy);
            handleModal();
            return <div></div>
        }
    }

    const renderOptionValue = () => {
        switch (selectedOption) {
            case Options.avatar:
                return <AvatarOptions avatar={user.picture}/>;
            case Options.tourGuide:
                return <TourGuideOptions />;
            case Options.privacy:
                return <PrivacyOptions />;
            case Options.notifications:
                return <NotificationsOptions />;
            case Options.help:
                return renderModal(<span>Content</span>)
            case Options.legal:
                return <LegalOptions
                    termsContent={termsContent}
                    privacyContent={privacyContent} />;
            case Options.logout:
                return <div></div>;
            default:
                return null;
        }
    }

    return (
        <Card.Section horizontal styles={{ root: { width: '100%', justifyContent: 'space-around', marginTop: '3rem' } }}>
            <Card.Section styles={{ root: { width: '25rem' } }}>
                <SettingsOptions
                    selected={selectedOption === Options.avatar}
                    title={Options.avatar}
                    handleOption={handleOption}
                    imgSelected={avatarWhite}
                    imgUnselected={avatarGreen}
                />
                <SettingsOptions
                    selected={selectedOption === Options.tourGuide}
                    title={Options.tourGuide}
                    handleOption={handleOption}
                    imgSelected={tourGuideWhite}
                    imgUnselected={tourGuideGreen}
                />
                <SettingsOptions
                    selected={selectedOption === Options.privacy}
                    title={Options.privacy}
                    handleOption={handleOption}
                    imgSelected={settingsLock}
                    imgUnselected={settingsLockGreen} />
                <SettingsOptions
                    selected={selectedOption === Options.notifications}
                    title={Options.notifications}
                    handleOption={handleOption}
                    imgSelected={notificationBell}
                    imgUnselected={notificationBellGreen} />
                <SettingsOptions
                    selected={selectedOption === Options.help}
                    title={Options.help}
                    handleOption={handleOption}
                    imgSelected={helpBadge}
                    imgUnselected={helpBadgeGreen} />
                <SettingsOptions
                    selected={selectedOption === Options.legal}
                    title={Options.legal}
                    handleOption={handleOption}
                    imgSelected={helpBadge}
                    imgUnselected={helpBadgeGreen} />
                <SettingsOptions
                    selected={selectedOption === Options.logout}
                    title={Options.logout}
                    handleOption={handleOption} //ADD Logout Logic here
                    imgSelected={logout}
                    imgUnselected={logoutGreen} />
            </Card.Section>
            <Card.Section styles={{ root: { width: '31rem' } }}>
                {renderOptionValue()}
            </Card.Section>
        </Card.Section>
    )
};

export default Settings;
