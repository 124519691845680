import User from "../../model/User";
import LifeEvent from "../../model/LifeEvent";
// import Dependent from "../../model/Dependent";
import { getId, getLifeEventNameById } from "../../utils/LifeEventTools/Mappers";
import { appendHeaders } from "../../utils/FetchHeaders";

import { getJobInfo, removeCurrentJob } from "./CareerPlanning";
import { setShowMonetization } from "./Aggregation";
import { saveUser, deleteUser } from "./Data";
import { addPlan } from "./Onboarding";
import { sessionService } from "redux-react-session";

import {
  LOG_IN_CREDENTIALS_STATUS,
  loginShowAuthCredentials,
  signUpError,
  mainRedirectToDashboard,
  signUpShowConfirmationCode,
  onboardingChangeState,
  SIGN_UP_SHOW_CONFIRMATION_CODE,
  SIGN_UP_EMAIL_FROM_LOGIN,
  showSignUpAvatars,
  showTransferConfirmedPrompt
} from "./Screens";
import { isCanonical } from "../../utils/global";

export const SIGN_UP = "SIGN_UP";
export const VALIDATE_EMAIL = "VALIDATE_EMAIL";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const VERIFY_AUTH_CODE = "VERIFY_AUTH_CODE";
export const GET_PROFILE = "GET_PROFILE";
export const ADD_LIFE_EVENT = "ADD_LIFE_EVENT";
export const UPDATE_LIFE_EVENT = "UPDATE_LIFE_EVENT";
export const DELETE_LIFE_EVENT = "DELETE_LIFE_EVENT";
export const UPDATE_SMART_CASHFLOW = "UPDATE_SMART_CASHFLOW";
export const UPDATE_CONNECTED_ACCOUNTS = "UPDATE_CONNECTED_ACCOUNTS";
export const REDIRECT_TO_MAIN_MENU = "REDIRECT_TO_MAIN_MENU";
export const CLEAR_CANONICAL_PROFILE = "CLEAR_CANONICAL_PROFILE";
export const SET_SHOW_FASTLINK = "SET_SHOW_FASTLINK";
export const SIGN_UP_ERROR = "USER_ALREADY_EXIST_ERROR";
export const SET_REDIRECT = "SET_REDIRECT";
export const INIT_RESET_PASSWORD = "INIT_RESET_PASSWORD";
export const VERIFY_RESET_PASSWORD_CODE = "VERIFY_RESET_PASSWORD_CODE";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO";
export const SAVE_USER = "SAVE_USER";
export const DELETE_CONNECTED_ACCOUNT = "DELETE_CONNECTED_ACCOUNT";
export const GET_NUDGET_SAVING_GOALS = "GET_NUDGET_SAVING_GOALS";
export const ADD_NUDGET = "ADD_NUDGET";
export const GET_PREVIOUS_NUDGETS = "GET_PREVIOUS_NUDGETS";
export const ADD_DEPENDENT = "ADD_DEPENDENT";
export const GET_AVATARS = "GET_AVATARS";

const SIGN_UP_ENDPOINT = process.env.REACT_APP_SIGN_UP_ENDPOINT;
const VALIDATE_EMAIL_ENDPOINT = process.env.REACT_APP_VALIDATE_EMAIL_ENDPOINT;
const LOG_IN_ENDPOINT = process.env.REACT_APP_LOG_IN_ENDPOINT;
const VERIFY_AUTH_CODE_ENDPOINT =
  process.env.REACT_APP_VERIFY_AUTH_CODE_ENDPOINT;
const SEND_VERIFICATION_CODE =
  process.env.REACT_APP_SEND_VERIFICATION_CODE_ENDPOINT;
const GET_NEW_2FA_ENDPOINT = process.env.REACT_APP_GET_NEW_2FA_ENDPOINT;
const GET_PROFILE_ENDPOINT = process.env.REACT_APP_GET_PROFILE_ENDPOINT;
const LIFE_EVENTS_ENDPOINT = process.env.REACT_APP_LIFE_EVENTS_ENDPOINT;
const GET_USER_FULL_PROFILE_ENDPOINT =
  process.env.REACT_APP_GET_USER_FULL_PROFILE_ENDPOINT;
const UPDATE_CONNECTED_ACCOUNTS_ENDPOINT =
  process.env.REACT_APP_UPDATE_CONNECTED_ACCOUNTS_ENDPOINT;
const INIT_RESET_PASSWORD_ENDPOINT =
  process.env.REACT_APP_INIT_RESET_PASSWORD_ENDPOINT;
const VERIFY_RESET_PASSWORD_CODE_ENDPOINT =
  process.env.REACT_APP_VERIFY_RESET_PASSWORD_CODE_ENDPOINT;
const SET_NEW_PASSWORD_ENDPOINT =
  process.env.REACT_APP_SET_NEW_PASSWORD_ENDPOINT;
const CLEAR_CACHE_ENDPOINT = process.env.REACT_APP_CLEAR_CACHE;
const GET_ACCOUNTS_TRANSCATIONS =
  process.env.REACT_APP_GET_ACCOUNTS_TRANSACTIONS;
const DELETE_CONNECTED_ACCOUNT_ENDPOINT =
  process.env.REACT_APP_DELETE_CONNECTED_ACCOUNT;
const NUDGET_SAVING_GOALS_ENDPOINT =
  process.env.REACT_APP_GET_NUDGET_SAVING_GOALS;
const DEPENDENT_ENDPOINT =
  process.env.REACT_APP_DEPENDENT;
const USER_PLANS_ENDPOINT =
  process.env.REACT_APP_USER_PLANS;
const AVATARS_ENDPOINT = process.env.REACT_APP_AVATARS_ENDPOINT;


export const signup = (
  name: string,
  email: string,
  phone: string,
  gender: string,
  password: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await fetch(SIGN_UP_ENDPOINT, {
        method: "POST",
        headers: appendHeaders(),
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          gender: gender,
          password: password,
        }),
      });

      if (response.ok) {
        //const u = new User(name, email, phone, gender, true, "");
        dispatch(signUpError(false));
        dispatch(signUpShowConfirmationCode());
      } else {
        dispatch(signUpError(true));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const validateEmail = (email: string, code: string, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await fetch(VALIDATE_EMAIL_ENDPOINT, {
        method: "POST",
        headers: appendHeaders(),
        body: JSON.stringify({
          username: email,
          code: code,
        }),
      });

      if (response.ok) {
        const body = await response.json();
        if (
          body.hasOwnProperty("data") &&
          body.data.hasOwnProperty("access_token") &&
          body.data.hasOwnProperty("refresh_token")
        ) {
          const user = await getUserFullProfile(
            body.data.access_token,
            body.data.refresh_token,
            dispatch,
            history
          );
          dispatch(getAvatars(body.data.access_token, history))
          sessionService
            .saveSession({
              access_token: body.data.access_token,
              refresh_token: body.data.refresh_token
            })
            .then((response) => {
              sessionService
                .saveUser(user)
                .then((response) => {
                  dispatch(saveUser(user));
                  dispatch({
                    type: VERIFY_AUTH_CODE,
                    showFastlink: false,
                  });
                  dispatch(signUpError(false));
                  dispatch(mainRedirectToDashboard(true));
                  //dispatch(showSignUpAvatars(true));
                  //dispatch(setShowMonetization(true));
                })
                .catch((error) => {
                  console.error(error);
                });
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          dispatch(loginShowAuthCredentials(true));
          history.push("/signin");
        }
      } else {
        dispatch(signUpError(true));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const login = (email: string, password: string, history: any) => {
  return async (dispatch: any, getState: any) => {
    const response = await fetch(LOG_IN_ENDPOINT, {
      method: "POST",
      headers: appendHeaders(),
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    });

    if (response.ok) {
      const body = await response.json();
      if (
        body.hasOwnProperty("data") &&
        body.data.hasOwnProperty("access_token") &&
        body.data.hasOwnProperty("refresh_token")
      ) {
        const user = await getUserFullProfile(
          body.data.access_token,
          body.data.refresh_token,
          dispatch,
          history
        );
        dispatch({
          type: VERIFY_AUTH_CODE,
          showFastlink: false,
        });
        dispatch(signUpError(false));
        updateConnectedAccounts(body.data.access_token, dispatch, history)
          .then((response) => {
            user.setConnectedAccounts(response);
          })
          .catch((error) => {
            console.error(error);
          });
        await sessionService.saveSession({
          access_token: body.data.access_token,
          refresh_token: body.data.refresh_token
        });
        await sessionService.saveUser(user);
        dispatch(saveUser(user));
        history.push("/");
      } else {
        dispatch(loginShowAuthCredentials(true));
      }
    } else {
      if (response.status === 403) {
        //Email has not been verified yet
        dispatch({
          type: SIGN_UP_EMAIL_FROM_LOGIN,
          email: email
        })
        dispatch({
          type: SIGN_UP_SHOW_CONFIRMATION_CODE,
          show: true
        });
        history.push('/signup');
      }
      else {
        dispatch({
          type: LOG_IN_CREDENTIALS_STATUS,
          invalid: true,
        });
      }

    }
  };
};

export const verifyAuthCode = (email: string, code: string, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await fetch(VERIFY_AUTH_CODE_ENDPOINT, {
        method: "POST",
        headers: appendHeaders(),
        body: JSON.stringify({
          username: email,
          code: code,
        }),
      });

      if (response.ok) {
        const payload = await response.json();
        const user = await getUserFullProfile(
          payload.data.access_token,
          payload.data.refresh_token,
          dispatch,
          history
        );
        sessionService
          .saveSession({
            access_token: payload.data.access_token,
            refresh_token: payload.data.refresh_token
          })
          .then((response) => {
            sessionService
              .saveUser(user)
              .then((response) => {
                dispatch(signUpError(false));
                dispatch(mainRedirectToDashboard(true));
                dispatch(saveUser(user));
                dispatch(loginShowAuthCredentials(false));
              })
              .catch((error) => console.error(error));
          })
          .catch((error) => console.error(error));
      } else {
        dispatch(signUpError(true));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const resendVerificationCode = (email: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await fetch(SEND_VERIFICATION_CODE, {
        method: 'POST',
        headers: { ...appendHeaders() },
        body: JSON.stringify({
          username: email
        })
      });

      if (response.ok) {
        return;
      }

    }
    catch (error) {
      console.error(error);
    }
  }
}

export const resend2FA = (email: string) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await fetch(GET_NEW_2FA_ENDPOINT, {
        method: 'POST',
        headers: { ...appendHeaders() },
        body: JSON.stringify({
          username: email
        })
      });

      if (response.ok) {
        return;
      }

    }
    catch (error) {
      console.error(error);
    }
  }
}

async function getUserFullProfile(
  token: string,
  refresh_token: string,
  dispatch: any,
  history: any
) {
  try {
    const response = await fetch(GET_USER_FULL_PROFILE_ENDPOINT, {
      headers: { ...appendHeaders(), Authorization: "Bearer " + token },
    });

    if (response.ok) {
      const payload = await response.json();
      const data = payload.data;
      const name = data.name;
      const isPersona = isCanonical(name);
      const lifeEvents = await getUserLifeEvents(token, dispatch, history);
      const plans = await getUserPlans(token, refresh_token, dispatch, history);
      const nudgetSavingGoals = await getNudgetSavingGoals(
        token,
        dispatch,
        history
      );
      const connectedAccounts = isPersona ? data.financialAccounts :
        await updateConnectedAccounts(
          token,
          dispatch,
          history
        );
      const user = new User(
        data.name,
        data.email,
        data?.trackingId,
        data.phone,
        data.gender,
        data.anonymized,
        isPersona ? data.actingAs : "ME",
        token,
        refresh_token,
        data.age,
        data.status,
        undefined,
        data.zipCode,
        data.grossAnnualSalaray,
        nudgetSavingGoals === undefined ? {} : nudgetSavingGoals,
        connectedAccounts,
        lifeEvents,
        isPersona ? data.plans : plans,
        data.dependents,
        data.financialProfile,
        data.masterSwitch === undefined ? {} : data.masterSwitch,
        data.cashflowSettings === undefined
          ? {}
          : data.cashflowSettings.masterSettings,
        isPersona ? {} : data.tourGuide,
        isPersona ? {} : data.hints,
        data.profileCompletionStatus,
        isPersona ? {} : data.scoop,
        isPersona ? [] : data.kakeibo ? data.kakeibo : [],
        isPersona ? "" : data.avatar,
        isPersona ? {} : data.picture,
        isPersona ? {} : data.protect ?  data.protect : {}
      );
      return user;
    } else {
      if (response.status === 401) {
        history.replace("/expired");
      }
    }
  } catch (error) {
    console.error(error);
  }
}

async function getUserPlans(token: string, refresh_token: string, dispatch: any, history: any) {
  try {
    const response = await fetch(USER_PLANS_ENDPOINT, {
      headers: { ...appendHeaders(), Authorization: "Bearer " + token }
    });

    if (response.ok) {
      const payload = await response.json();
      return payload.data;
    }
    else {
      if (response.status === 401) {
        history.replace("/expired");
      }
    }
  }
  catch (error) {
    console.error(error);
  }
}

export const getUpdatedUserFullProfile = (history: any, access?: string,
  refresh?: string, save?: boolean, fromCopy?: boolean, redirectToMyPlans?: boolean, lifeEventCopy?: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = access ? access : fromCopy || lifeEventCopy ? getState().data.realUser.access_token : getState().session.user.access_token;
      const refresh_token = refresh ? refresh : fromCopy || lifeEventCopy ? getState().data.realUser.access_token : getState().session.user.refresh_token;
      const user = await getUserFullProfile(
        token,
        refresh_token,
        dispatch,
        history
      );
      if (access !== undefined && refresh !== undefined) {
        sessionService
          .saveSession({
            access_token: token,
            refresh_token: refresh_token
          })
      }
      if (save === undefined && !fromCopy) {
        sessionService
          .saveUser(user)
          .then((response) => {
          })
          .catch((error) => console.error(error));
      }
      else {
        //we come from an AppNavigator flow, we need to check if we need to update
        //the session slice
        if (save) {
          sessionService
            .saveUser(user)
            .then((response) => {
            })
            .catch((error) => console.error(error));
        }
      }
      dispatch(saveUser(user));
      if (redirectToMyPlans) {
        history.push('/');
      }
      if (fromCopy) {
        dispatch(showTransferConfirmedPrompt(true));
      }
      return user;
    } catch (error) {
      console.error(error);
    }
  };
};

async function getUserLifeEvents(token: string, dispatch: any, history: any) {
  try {
    const response = await fetch(LIFE_EVENTS_ENDPOINT, {
      headers: { ...appendHeaders(), Authorization: "Bearer " + token }
    });

    if (response.ok) {
      const payload = await response.json();
      const lifeEvents = payload.data.map((d: any) => {
        if (d.name === "Career Goal" && d.status !== "NEW") {
          dispatch(getJobInfo(d.customData.selectedCareerOnetCode));
        }
        return new LifeEvent(
          d.id,
          d.date,
          d.eventIcon,
          d.name,
          d.status,
          d.startDate,
          d.customData,
          d.externalId,
          d.relatedPlanId,
          d.overview
        );
      });
      return lifeEvents;
    } else {
      if (response.status === 401) {
        history.replace("/expired");
      }
    }
  } catch (error) {
    console.error(error);
  }
}

// async function getDependents(token: string, dispatch: any, history: any) {
//   try {
//     const response = await fetch(DEPENDENT_ENDPOINT, {
//       method: "GET",
//       headers: { ...appendHeaders(), Authorization: "Bearer " + token },
//     });

//     if (response.ok) {
//       const payload = await response.json();
//       const dependents = payload.data.map((d: any) => {
//         return new Dependent(d._id, d.name, d.age, d.relationship);
//       });
//       return dependents;
//     } else {
//       if (response.status === 401) {
//         history.replace("/expired");
//       }
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }

export const getProfile = (name: string, history: any, redirect: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await fetch(GET_PROFILE_ENDPOINT, {
        method: "GET",
        headers: { ...appendHeaders(), "X-Persona": name },
      });

      if (response.ok) {
        const payload = await response.json();
        const data = payload.data;
        const persona = data.persona;
        const auth = data.auth;
        const lifeEvents = await getUserLifeEvents(
          auth.access_token,
          dispatch,
          history
        );
        const nudgetSavingGoals = await getNudgetSavingGoals(
          auth.access_token,
          dispatch,
          history
        );
        const p = new User(
          persona.name,
          persona.email,
          "",
          "",
          persona.gender,
          persona.anonymized,
          persona.actingAS,
          auth.access_token,
          auth.refresh_token,
          persona.age,
          "Single",
          persona.address,
          persona.zipCode,
          persona.grossAnnualSalary,
          nudgetSavingGoals,
          persona.financialAccounts,
          lifeEvents,
          persona.plans,
          persona.dependents,
          persona.financialProfile,
          persona.masterSwitch,
          persona.cashflowSettings.masterSwitch,
          {},
          {},
          0,
          [],
          [],
          "",
          {},
          {}
        );
        dispatch({
          type: GET_PROFILE,
        });
        //await sessionService.saveSession(auth);
        await sessionService.saveUser(p);
        dispatch(saveUser(p));
        if (redirect) {
          history.push("/connect");
        }
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const createPlan = (planName: string, lifeEventArray: any, history: any, fromCopy?: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().data.realUser.access_token;
      const newPlan = await addPlan(planName, lifeEventArray, history, token, fromCopy ? 'imported' : 'custom');
      if (Object.keys(newPlan).length > 0 && newPlan.planId) {
        if (fromCopy) {
          dispatch(getUpdatedUserFullProfile(history, undefined, undefined, false, true, false));
        }
        else {
          dispatch(getUpdatedUserFullProfile(history));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deletePlan = (planId: string, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().data.realUser.access_token;
      const response = await fetch(USER_PLANS_ENDPOINT + '/' + planId, {
        method: 'DELETE',
        headers: { ...appendHeaders(), Authorization: "Bearer " + token }
      });
      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history));
        history.push('/connect/myplaylist');
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    }
    catch (error) {
      console.error(error);
    }
  }
}

export const addLifeEventToPlaylist = (planId: string, lifeEventData: any, history: any, redirect: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      const object = {
        name: lifeEventData.name,
        customData: lifeEventData.customData,
        lifeEventId: getId(getLifeEventNameById(lifeEventData.externalId)),
        date: lifeEventData.date,
        startDate: lifeEventData.startDate,
        status: "CONFIRMED",
      };
      const token = getState().data.realUser.access_token;
      const response = await fetch(USER_PLANS_ENDPOINT + '/' + planId + '/life-events', {
        method: "POST",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify([object]),
      });

      if (response.ok) {
        const payload = await response.json();
        const data = payload.data;
        dispatch(getUpdatedUserFullProfile(history, token, undefined, undefined, undefined));
        if (redirect) {
          history.push('/connect/lifeevent/' + data.lifeEvents[0].id);
        }
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }

    }
    catch (error) {
      console.error(error);
    }
  }
}

export const addLifeEvent = (lifeEventData: any, history: any, fromCopy?: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      const object = {
        name: lifeEventData.name,
        customData: lifeEventData.customData,
        lifeEventId: getId(getLifeEventNameById(lifeEventData.externalId)),
        date: lifeEventData.date,
        startDate: lifeEventData.startDate,
        status: "CONFIRMED",
      };
      const token = getState().data.realUser.access_token;
      const response = await fetch(LIFE_EVENTS_ENDPOINT, {
        method: "POST",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          data: object,
        }),
      });

      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history, undefined, undefined, undefined, fromCopy));
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateLifeEvent = (
  lifeEventID: string,
  lifeEventData: any,
  history: any,
  status?: string
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let payload = {};
      if (status) {
        payload = {
          lifeEventId: lifeEventID,
          startDate: lifeEventData.startDate,
          date: lifeEventData.date,
          name: lifeEventData.name,
          customData: lifeEventData.customData,
          status: status,
        };
      } else {
        payload = {
          lifeEventId: lifeEventID,
          startDate: lifeEventData.startDate,
          date: lifeEventData.date,
          name: lifeEventData.name,
          customData: lifeEventData.customData,
        };
      }
      const token = getState().session.user.access_token;
      const response = await fetch(`${LIFE_EVENTS_ENDPOINT}/${lifeEventID}`, {
        method: "PATCH",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history));
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteLifeEvent = (lifeEventID: string, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const user = getState().session.user;
      const token = getState().session.user.access_token;
      const response = await fetch(`${LIFE_EVENTS_ENDPOINT}/${lifeEventID}`, {
        method: "DELETE",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
      });

      if (response.ok) {
        //We need to remove the LE from the playlist as well...
        let planId = "";
        user.plans.forEach(plan => {
          let searchedLE = plan.lifeEvents.filter(le => le.id === lifeEventID);
          if (searchedLE.length > 0) {
            planId = plan.planId;
          }
        });

        if (planId !== "") {
          const res = await fetch(USER_PLANS_ENDPOINT + '/' + planId + '/life-events/' + lifeEventID, {
            method: "DELETE",
            headers: { ...appendHeaders(), Authorization: "Bearer " + token }
          });
          if (res.ok) {
            dispatch(getUpdatedUserFullProfile(history));
          }
        }
        else {
          dispatch(getUpdatedUserFullProfile(history));
        }

      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateSmartCashflow = (masterSwitchData: any, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(GET_USER_FULL_PROFILE_ENDPOINT, {
        method: "PATCH",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          masterSwitch: masterSwitchData,
        }),
      });

      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history));
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const clearCanonicalProfile = (history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      sessionService
        .saveUser(getState().data.realUser)
        .then((response) => {
          dispatch(saveUser(getState().data.realUser));
          dispatch(removeCurrentJob());
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUpdatedConnectedAccounts = (history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      await clearCache(token, dispatch, history);
      const response = await fetch(UPDATE_CONNECTED_ACCOUNTS_ENDPOINT, {
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
      });

      if (response.ok) {
        const payload = await response.json();
        const data = payload.data;
        dispatch({
          type: UPDATE_CONNECTED_ACCOUNTS,
          connectedAccounts: data,
        });
        const updatedUser = { ...getState().session.user };
        updatedUser.connectedAccounts = data;
        sessionService
          .saveUser(updatedUser)
          .then((response) => {
            dispatch(saveUser(updatedUser));
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAccountsTransactions = (history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      await clearCache(token, dispatch, history);
      const response = await fetch(GET_ACCOUNTS_TRANSCATIONS, {
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
      });

      if (response.ok) {
        // const payload = await response.json();
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

async function clearCache(token: string, dispatch: any, history: any) {
  try {
    const response = await fetch(CLEAR_CACHE_ENDPOINT, {
      method: "POST",
      headers: { ...appendHeaders(), Authorization: "Bearer " + token },
    });

    if (response.ok) {
    } else {
      if (response.status === 401) {
        history.replace("/expired");
      }
    }
  } catch (error) {
    console.error(error);
  }
}

async function updateConnectedAccounts(
  token: string,
  dispatch: any,
  history: any
) {
  try {
    const response = await fetch(UPDATE_CONNECTED_ACCOUNTS_ENDPOINT, {
      headers: { ...appendHeaders(), Authorization: "Bearer " + token },
    });

    if (response.ok) {
      const payload = await response.json();
      const data = payload.data;
      dispatch({
        type: UPDATE_CONNECTED_ACCOUNTS,
        connectedAccounts: data,
      });

      return payload.data;
    } else {
      if (response.status === 401) {
        history.replace("/expired");
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export const redirectToMainMenu = () => {
  return async (dispatch: any) => {
    dispatch(mainRedirectToDashboard(true));
  };
};

export const setShowFastlink = () => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_SHOW_FASTLINK,
      showFastlink: true,
    });
    dispatch(setShowMonetization(false));
  };
};

export const setRedirect = (condition: boolean) => {
  return async (dispatch: any) => {
    dispatch(mainRedirectToDashboard(condition));
  };
};

export const logOut = (history: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: LOG_OUT,
    });
    sessionService
      .deleteSession()
      .then((response) => {
        sessionService
          .deleteUser()
          .then((response) => {
            dispatch(deleteUser());
            dispatch(onboardingChangeState(true));
            dispatch(mainRedirectToDashboard(false));
            history.replace("/");
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const resetPassword = (email: string, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(INIT_RESET_PASSWORD_ENDPOINT, {
        method: "POST",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          username: email,
        }),
      });

      if (response.ok) {
        dispatch({
          type: INIT_RESET_PASSWORD,
          showResetPasswordCode: true
        });
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const verifyResetPasswordCode = (
  email: string,
  code: string,
  history: any
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(VERIFY_RESET_PASSWORD_CODE_ENDPOINT, {
        method: "POST",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          username: email,
          verificationCode: code
        }),
      });

      if (response.ok) {
        const payload = await response.json();
        const token = payload.data.token;
        dispatch({
          type: VERIFY_RESET_PASSWORD_CODE,
          token: token,
        });
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        } else {
          dispatch(signUpError(true));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const setNewPassword = (
  email: string,
  passwordToken: string,
  newPassword: string,
  history: any
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(SET_NEW_PASSWORD_ENDPOINT, {
        method: "POST",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          username: email,
          token: passwordToken,
          password: newPassword,
        }),
      });

      if (response.ok) {
        dispatch({
          type: SET_NEW_PASSWORD,
        });
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateProfile = (
  payload: any,
  history: any
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(GET_USER_FULL_PROFILE_ENDPOINT, {
        method: "PATCH",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history));
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const addDependent = (
  name: string,
  age: string,
  relationship: string,
  history: any
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(DEPENDENT_ENDPOINT, {
        method: "POST",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          name: name,
          age: parseInt(age),
          relationship: relationship,
        }),
      });

      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history));
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateDependent = (
  id: string,
  name: string,
  age: string,
  relationship: string,
  history: any
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(`${DEPENDENT_ENDPOINT}\\${id}`, {
        method: "PATCH",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify({
          name: name,
          age: parseInt(age),
          relationship: relationship,
        }),
      });

      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history));
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteConnectedAccount = (id: number, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(
        DELETE_CONNECTED_ACCOUNT_ENDPOINT + "/" + id.toString(),
        {
          method: "DELETE",
          headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        }
      );

      if (response.ok) {
        const user = getState().session.user;
        const connectedAccounts = user.connectedAccounts;
        const newConnectedAccounts = connectedAccounts.filter(
          (c) => c.id !== id
        );
        let updatedUser = { ...user };
        updatedUser.connectedAccounts = newConnectedAccounts;

        dispatch({
          type: DELETE_CONNECTED_ACCOUNT,
        });

        sessionService
          .saveUser(updatedUser)
          .then((response) => {
            dispatch(saveUser(updatedUser));
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

async function getNudgetSavingGoals(
  token: string,
  dispatch: any,
  history: any
) {
  try {
    const response = await fetch(NUDGET_SAVING_GOALS_ENDPOINT, {
      method: "GET",
      headers: { ...appendHeaders(), Authorization: "Bearer " + token },
    });

    if (response.ok) {
      const payload = await response.json();
      return payload.data;
    } else {
      if (response.status === 401) {
        history.replace("/expired");
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export const getAllNudgetSavingGoals = (range: string, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(NUDGET_SAVING_GOALS_ENDPOINT + "/history", {
        method: "GET",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
      });

      if (response.ok) {
        const payload = await response.json();
        let newNudgetSavingGoals = payload.data.filter(
          (nudget) => nudget.month === range
        );
        if (newNudgetSavingGoals.length > 0) {
          newNudgetSavingGoals = newNudgetSavingGoals[0];
        } else {
          newNudgetSavingGoals = {};
        }

        const user = getState().session.user;
        let updatedUser = { ...user };
        updatedUser.nudgetSavingGoalsInfo = newNudgetSavingGoals;
        sessionService
          .saveUser(updatedUser)
          .then((response) => {
            dispatch(saveUser(updatedUser));
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const addNudgetSavingGoal = (nudgetInfo: any, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(NUDGET_SAVING_GOALS_ENDPOINT, {
        method: "POST",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify(nudgetInfo),
      });

      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateNudgetSavingGoal = (
  nudgetInfo: any,
  id: any,
  history: any
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(NUDGET_SAVING_GOALS_ENDPOINT + "/" + id, {
        method: "PUT",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
        body: JSON.stringify(nudgetInfo),
      });

      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history));
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteNudgetSavingGoal = (id: any, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(NUDGET_SAVING_GOALS_ENDPOINT + "/" + id, {
        method: "DELETE",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
      });

      if (response.ok) {
        dispatch(getUpdatedUserFullProfile(history));
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAvatars = (token: string, history: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await fetch(AVATARS_ENDPOINT, {
        method: "GET",
        headers: { ...appendHeaders(), Authorization: "Bearer " + token }
      });

      if (response.ok) {
        const payload = await response.json();
        const data = payload.data;
        dispatch({
          type: GET_AVATARS,
          avatars: data
        })
      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }

    }
    catch (error) {
      console.error(error);
    }
  }
} 
