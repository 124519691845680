import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import classes from './Divider.module.css';

interface IProps {
    style?: object;
};

const DividerContainer: React.FC<IProps> = props => {

    return (
        <Stack 
            wrap
            horizontalAlign='center' 
            verticalAlign='center' 
            tokens={{childrenGap: 60}}
            className={classes.container}
            style={props.style}
        >
            {props.children}
        </Stack>
    )
};

export default DividerContainer;
