import {
  ONBOARDING_CHANGE_STATE,
  CONNECT_CHANGE_STATE,
  CONNECT_SHOW_SCOOP,
  CONNECT_SHOW_NUDGET,
  CONNECT_SHOW_KAKEIBO,
  LOG_IN_CREDENTIALS_STATUS,
  LOG_IN_SHOW_AUTH_CODE,
  SIGN_UP_ERROR,
  MAIN_REDIRECT_TO_DASHBOARD,
  SIGN_UP_SHOW_CONFIRMATION_CODE,
  CONNECT_DISMISS_HINT,
  CONNECT_CHANGE_HINT,
  SIGN_UP_EMAIL_FROM_LOGIN,
  CONNECT_SHOW_SUMMARY,
  SIGN_UP_SHOW_AVATARS,
  TRANSFER_MODAL_CHANGE_STATE,
} from "../actions/Screens";

const initialState = {
  onboarding_showCarousel: true,
  signUp_signUpError: false,
  signUp_showConfirmationCode: false,
  signUp_emailFromLogin: '',
  signUp_showAvatars: false,
  login_invalidCredentials: false,
  login_showAuthCodePage: false,
  main_redirectToDashboard: false,
  connect_keys: ["Get Organized", "My Playlist", "Plan For Future"],
  connect_currentKey: "",
  connect_showNudget: false,
  connect_showKakeibo: false,
  connect_showScoopDashboards: false,
  connect_showSummary: false,
  connect_currentHint: 0,
  transferPromptShowConfirmed: false
};

const screensReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ONBOARDING_CHANGE_STATE: {
      return {
        ...state,
        onboarding_showCarousel: action.show,
      };
    }
    case SIGN_UP_ERROR: {
      return {
        ...state,
        signUp_signUpError: action.error,
      };
    }
    case SIGN_UP_SHOW_CONFIRMATION_CODE: {
      return {
        ...state,
        signUp_showConfirmationCode: action.show,
      };
    }
    case SIGN_UP_EMAIL_FROM_LOGIN: {
      return {
        ...state,
        signUp_emailFromLogin: action.email
      }
    }
    case SIGN_UP_SHOW_AVATARS: {
      return {
        ...state,
        signUp_showAvatars: action.show
      }
    }
    case MAIN_REDIRECT_TO_DASHBOARD: {
      return {
        ...state,
        main_redirectToDashboard: action.redirect,
      };
    }
    case CONNECT_CHANGE_STATE: {
      if (action.connect_keys) {
        return {
          ...state,
          connect_keys: action.connect_keys,
          connect_currentKey: action.connect_currentKey,
        };
      } else {
        return {
          ...state,
          connect_currentKey: action.connect_currentKey,
        };
      }
    }
    case CONNECT_SHOW_SCOOP: {
      return {
        ...state,
        connect_showScoopDashboards: action.connect_showScoopDashboards,
      };
    }
    case CONNECT_SHOW_NUDGET: {
      return {
        ...state,
        connect_showNudget: action.connect_showNudget,
      };
    }
    case CONNECT_SHOW_KAKEIBO: {
      return {
        ...state,
        connect_showKakeibo: action.connect_showKakeibo,
      };
    }
    case CONNECT_SHOW_SUMMARY: {
      return {
        ...state,
        connect_showSummary: action.connect_showSummary
      }
    }
    case CONNECT_DISMISS_HINT: {
      const newHint = state.connect_currentHint + 1;
      return {
        ...state,
        connect_currentHint: newHint,
      };
    }
    case CONNECT_CHANGE_HINT: {
      const newHint = action.hint;
      return {
        ...state,
        connect_currentHint: newHint,
      };
    }
    case LOG_IN_CREDENTIALS_STATUS: {
      return {
        ...state,
        login_invalidCredentials: action.invalid,
      };
    }
    case LOG_IN_SHOW_AUTH_CODE: {
      const showPage = action.show;
      return {
        ...state,
        login_showAuthCodePage: showPage,
      };
    }
    case TRANSFER_MODAL_CHANGE_STATE: {
      return {
        ...state,
        transferPromptShowConfirmed: action.show
      }
    }
    default:
      return state;
  }
};

export default screensReducer;

