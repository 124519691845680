import React from 'react';
import { Stack } from 'office-ui-fabric-react';
import LifeEvent from '../../LifeEvents/LifeEvent';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import EFButton from '../../Atomic/EFButton/EFButton';

import defaultIcon from '../../../assets/images/lifeEvents/bigstuff/college.svg';
import classes from './CustomModalCard.module.css';
import { useResponsive } from '../../../hooks/useResponsive';

interface IProps {
    style?: object,
    onClickExit: () => void,
    onClick?: () => void,
    button?: boolean,
    title?: string,
    eventIcon?: string,
    buttonText?: string,
    stackClassName?: string,
    imgStyle?: object,
    disabled?: boolean,
};

const CustomModalCard: React.FC<IProps> = props => {
    const { screenIsAtLeast } = useResponsive();

    return (
        <Stack style={props.style} className={`${classes.container} ${!screenIsAtLeast('tabPort') && classes.containerMobile}`}>
            <Stack onClick={props.onClickExit} style={{margin: '2rem 3rem 0 2rem'}} horizontalAlign='end'>
                <img className={classes.exitIcon} alt='exit' src={require('../../../assets/icons/closeicon.svg')} />
            </Stack>
            <Stack className={screenIsAtLeast('tabPort') ? `${classes.innerContainer} ${props.stackClassName}` : classes.innerContainerMobile}>
                <Stack horizontalAlign='center' tokens={{childrenGap: 10}}>
                    <img style={{height: '12rem', width: '12rem'}} alt="modal-icon" src={props.eventIcon || defaultIcon}/>
                    <EFLabel style={{fontSize: '2.6rem'}}>{props.title}</EFLabel>
                </Stack>
                <Stack className={classes.children} tokens={{childrenGap: 30}}>
                    {props.children}
                </Stack>
            </Stack>
            <Stack horizontalAlign='center' tokens={{childrenGap: 30}} style={{marginTop: 'auto'}}>
                {props.button ? (<EFButton style={{ minWidth: '13rem', maxHeight: '3rem' }} text={props.buttonText} onClick={props.onClick} isDisabled={props.disabled} />) : null}
            </Stack>
        </Stack>
    );
};

CustomModalCard.defaultProps = {
    title: 'COLLEGE PLANNING',
    buttonText: 'LET`S CHECK IT OUT',
};

export default CustomModalCard;
