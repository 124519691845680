import React from 'react';
import Backdrop from '../../../Atomic/Backdrop/Backdrop';
import classes from './SettingsModal.module.css';
import EFButton from '../../../Atomic/EFButton/EFButton';

interface Props {
    handleModal: () => void,
    Title?: JSX.Element,
    Content: JSX.Element,
    onClickAccept?: () => void,
}

const SettingsModal: React.FC<Props> = props => {
    return (
        <Backdrop onClick={props.handleModal}>
            <div onClick={(e) => e.stopPropagation()} className={classes.card}>
                <div className={classes.content}>
                    {props.Content}
                    {props.onClickAccept && 
                    <div className={classes.button}>
                        <EFButton 
                            stackClassName={classes.btnStyle} 
                            onClick={props.onClickAccept} 
                            text='ACCEPT' 
                        />
                    </div>}
                </div>
            </div>
        </Backdrop>
    )
}

export default SettingsModal;
