import React from 'react';
import { Bar } from 'react-chartjs-2';

require('./RoundedBars.js');

interface Props {
    datasets?: object;
    barRadius?: number;
    onClick?: (this: any, e: any) => void;
    maxTicksXLimit?: number;
    maxTicksYLimit?: number;
    barPercentage?: number;
    fontSize?: number;
};

const StackedBarChart: React.FC<Props> = props => {
  return (
    <Bar
      data={props.datasets}
      options={{
        plugins: {
            datalabels: {
                display: false,
            }
        },
        barRadius: props.barRadius,
        maintainAspectRatio: false,
        cornerRadius: props.barRadius,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [{
                barPercentage: props.barPercentage,
                stacked: true,
                gridLines: {
                    display: true,
                    drawOnChartArea: false,
                    drawTicks: false,
                    margin: 10,
                },
                ticks: {
                    maxTicksLimit: props.maxTicksXLimit,
                    beginAtZero: false,
                    padding: 10,
                    fontFamily: 'brandon-grotesque',
                    fontSize: props.fontSize,
                },
            }],
            yAxes: [{
                stacked: false,
                gridLines: {
                    display: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
                ticks: {
                    beginAtZero: false,
                    maxTicksLimit: props.maxTicksYLimit || 3, //maxing at 1 causes showing 2 ticks so use .5 for 1
                    padding: 10,
                    fontFamily: 'brandon-grotesque',
                    callback: (value: number) => {
                        return (value < 1000000) ? value/1000 + 'k' : value / 1000000 + 'm';
                    }
                }
            }]
        },
        elements: {
            point:{
                radius: 0,
            }
        },
        onClick: props.onClick,
      }}
    />
  )
}
  
StackedBarChart.defaultProps = {
    barPercentage: 0.4,
    barRadius: 20,
    datasets: {
        labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP'],
        datasets: [
            {
            label: 'SAVINGS',
            data: [9041, 9840, 10418, 11245, 12070, 12740, 15656, 10418, 12070],
            backgroundColor: '#FDDC8F',
            borderColor: '#FDDC8F',
            borderWidth: 1,
            barPercentage: 0.45,
            },
        ]
    }
}

export default StackedBarChart
