import React, { useState, useEffect } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import classes from './FavSelectorModal.module.css';
import EFButton from '../../Atomic/EFButton/EFButton';
import Modal from 'react-modal';
import ScoopDashboardWidget from '../ScoopDashboardWidget/ScoopDashboardWidget';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_USER } from '../../../hooks/Apollo/mutations';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useResponsive } from "../../../hooks/useResponsive";
import * as actions from '../../../store/actions/Users';

interface IProps {
    dashboardData: any;
    showEditFav: boolean;
    toggleEditFav: (e: any) => void;
};

const labelStyle = {
    fontSize: '2.2rem', 
    color: '#293861', 
    fontFamily: 'brandon-grotesque', 
    margin: '3.5rem 0 4.3rem 0',
    maxWidth: '50rem',
    textAlign: 'center',
};

const FavSelectorModal: React.FC<IProps> = props => {
    const dispatch = useDispatch();
    let history = useHistory();
    const { screenIsAtMost } = useResponsive();
    const [updateScoopPreviews] = useMutation(UPDATE_USER);
    const [dbs, setDbs] = useState(props.dashboardData);

    useEffect(() => {
        setDbs(props.dashboardData);
    }, [props.dashboardData])

    const onSetDbs = (db: any, i?: number) => {
        const numChecked = dbs.filter(e => e.checked).length;
        const isDbChecked = dbs[db].checked;
        if (numChecked < 4 || isDbChecked) {
            let newDbs = Array.from(dbs);
            const currDb = { ...dbs[db], checked: !isDbChecked };
            newDbs[db] = currDb;
            setDbs(newDbs)
        }
    }

    const onClickDone = async (e) => {
        const checkedDbIds = dbs.filter(e => e.checked).map(e => e.id);
        // What if a user does not want to display any db previews ? -> we need an image to place over
        // Tmp solution:
        const tmpDbs = ["networth", "saving", "cashflow", "expense"];
        await updateScoopPreviews({
            variables: {
                scoop: { hiddenDashboards: checkedDbIds.length ? checkedDbIds : tmpDbs },
            }
        })
        .then(() => dispatch(actions.getUpdatedUserFullProfile(history)))
        .catch(error => console.error(error));
        props.toggleEditFav(e);
    }

    return (
        <Modal isOpen={props.showEditFav} onRequestClose={props.toggleEditFav} style={modalCardStyle}>
            <Stack horizontalAlign='center' style={{ width: '100%', margin: '10rem' }}>
                <Stack className={`${classes.container} ${screenIsAtMost("mobile") && classes.containerMobile}`}>
                    <Stack onClick={props.toggleEditFav} style={{margin: 20}} horizontalAlign='end'>
                        <img className={classes.exit} alt='x' src={require('../../../assets/images/xLeft.png')} />
                        <img className={classes.exit} alt='x' src={require('../../../assets/images/xRight.png')} />
                    </Stack>
                    <Stack verticalAlign='center' horizontalAlign='center' tokens={{childrenGap: 40}}>
                        <EFLabel style={labelStyle}>
                            Pick up to four dashboards to preview on your Scoop
                        </EFLabel>
                        <Stack horizontalAlign='center' className={classes.cards} wrap tokens={{childrenGap: 15}}>
                            {dbs.map((db: any, i: number) => (
                                <Stack key={db.id}>
                                    <ScoopDashboardWidget
                                        isSelectView 
                                        dashboardData={db} 
                                        onClick={onSetDbs.bind(db, i)} 
                                    />
                                </Stack>
                            ))}
                        </Stack>
                        <EFButton 
                            style={{width: '17.5rem', height: '3.5rem', fontSize: '1.1rem'}} 
                            text='DONE' 
                            onClick={onClickDone} 
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Modal>
    )
};

const modalCardStyle = {
    content: {
        display: "flex" as "flex",
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: '70rem',
        width: 'fit-content',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)'
    }
}

export default FavSelectorModal;