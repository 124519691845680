import React from 'react';
import afford from '../../../assets/images/lifeEvents/money/affordability.svg';
import close from '../../../assets/images/close.svg';
import EFButton from '../../Atomic/EFButton/EFButton';
import Modal from "react-modal";
import classes from './OkCancelDialog.module.css';
import modalCardStyle from '../../../components/UI/Modals/CommonModalInlineStyle';

interface Props {
    isOpen?: boolean;
    onConfirmClick: () => void;
    onClose?: () => void;
}

const OkCancelDialog: React.FC<Props> = props => {
    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onClose}
            style={{
                content: modalCardStyle().content,
                overlay: modalCardStyle().overlay,
            }}
        >
            <div className={classes.container}>
                <div className={classes.closeContainer}>
                    <img onClick={props.onClose} src={close} alt="close" style={{ width: '2.5rem', cursor: 'pointer' }} />
                </div>
                <div className={classes.addPlanContainer}>
                    <img src={afford} alt="affordability-icon" className={classes.afford} />
                </div>
                <div className={classes.textContainer}>
                    <p className={classes.smallCardContent}>In order to properly use the Affordability event,</p>
                    <p className={classes.smallCardContent}>the Career Goal event needs to be configured first,</p>
                    <p className={classes.smallCardContent}>due to dependency. Would you like to switch</p>
                    <p className={classes.smallCardContent}>to the Career Goal event now?</p>
                </div>
                <div className={classes.buttonsContainer}>
                    <EFButton 
                        onClick={props.onClose} 
                        text={"NOT NOW"}
                        style={{ backgroundColor: '#FFF', color: '#FEB81E' }}
                    />
                    <EFButton 
                        onClick={(event) => {
                            event.stopPropagation();
                            props.onConfirmClick();
                        }} 
                        text={"GO FOR IT"} 
                    />
                </div>
            </div>
        </Modal>
    )
};

export default OkCancelDialog;