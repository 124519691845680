import React from 'react';
import EFTextField from '../EFTextField/EFTextField';

interface Props {
    options: any;
    selectedOptionHandler: (option: string) => void;
    value?: any
}

const Dropdown: React.FC<Props> = props => {
    return (
            <EFTextField
                value={props.value}
                width='100%'
                type='text'
                placeholder="Select one"
                placeholderColor='rgba(0, 0, 0, 0.31)'
                isDropdown
                dropdownOptions={props.options}
                onSelectedDropdownOption={props.selectedOptionHandler}
                autoComplete={false}
            />
    )
};

export default Dropdown;