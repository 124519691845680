export const quotes = [
  {
    author: "–Albert Einstein",
    quote: "Strive not to be a success, but rather to be of value.",
  },
  {
    author: " –Robert Frost",
    quote:
      "Two roads diverged in a wood, and I took the one less traveled by, and that has made all the difference.",
  },
  {
    author: "–Florence Nightingale",
    quote: "I attribute my success to this: I never gave or took any excuse.",
  },
  {
    author: "–Wayne Gretzky",
    quote: "You miss 100% of the shots you don't take.",
  },
  {
    author: "–Amelia Earhart",
    quote:
      "The most difficult thing is the decision to act, the rest is merely tenacity.",
  },
  {
    author: "–Babe Ruth",
    quote: "Every strike brings me closer to the next home run.",
  },
  {
    author: "–W. Clement Stone",
    quote: "Definiteness of purpose is the starting point of all achievement.",
  },
  {
    author: "–John Lennon",
    quote: "Life is what happens to you while you're busy making other plans.",
  },
  {
    author: "–Earl Nightingale",
    quote: "We become what we think about.",
  },
  {
    author: "–Charles Swindoll",
    quote: "Life is 10% what happens to me and 90% of how I react to it.",
  },
  {
    author: "–Alice Walker",
    quote:
      "The most common way people give up their power is by thinking they don't have any.",
  },
  {
    author: "–Buddha",
    quote: "The mind is everything. What you think you become.",
  },
  {
    author: "–Chinese Proverb",
    quote:
      "The best time to plant a tree was 20 years ago. The second best time is now.",
  },
  {
    author: "–Steve Jobs",
    quote:
      "Your time is limited, so don't waste it living someone else's life.",
  },
  {
    author: "–Stephen Covey",
    quote:
      "I am not a product of my circumstances. I am a product of my decisions.",
  },
  {
    author: "–Pablo Picasso",
    quote:
      "Every child is an artist. The problem is how to remain an artist once he grows up.",
  },
  {
    author: "–Christopher Columbus",
    quote:
      "You can never cross the ocean until you have the courage to lose sight of the shore.",
  },
  {
    author: "–Frank Sinatra",
    quote: "The best revenge is massive success.",
  },
  {
    author: "–Zig Ziglar",
    quote:
      "People often say that motivation doesn't last. Well, neither does bathing. That's why we recommend it daily.",
  },
  {
    author: "–Anais Nin",
    quote: "Life shrinks or expands in proportion to one's courage.",
  },
  {
    author: "–Vincent Van Gogh",
    quote:
      'If you hear a voice within you say "you cannot paint," then by all means paint and that voice will be silenced.',
  },
  {
    author: "–Aristotle",
    quote:
      "There is only one way to avoid criticism: do nothing, say nothing, and be nothing.",
  },
];
