import React, { useState, useEffect } from "react";
import { Stack } from "office-ui-fabric-react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import NavigationView from "../../components/UI/NavigationView";
import DashboardContainer from "../../components/UI/DashboardContainer/DashboardContainer";
import Backdrop from "../../components/Atomic/Backdrop/Backdrop";
import ProfileSwitcher from "../../components/UI/ProfileSwitcher/ProfileSwitcher";
import Hint from "../../components/Hint/Hint";
import { handleSideNavigation, isCanonical } from "../../utils/global";
import { hintsEntries } from "../../contentful/contenful";
import { useResponsive } from "../../hooks/useResponsive";

import Protect from "../../components/UI/Protect";
import FilesByCategory from "../../components/UI/FilesByCategory";
import classes from "./ProtectDashboardPage.module.css";

import maleAvatar from "../../assets/images/maleAvatar.svg";
import staceyAvatar from "../../assets/images/staceyAvatar.png";
import rosaAvatar from "../../assets/images/rosaAvatar.png";
import darylAvatar from "../../assets/images/darylAvatar.png";
import martinAvatar from "../../assets/images/martinAvatar.svg";
import eveAvatar from "../../assets/images/eveAvatar.svg";
import circleAdd from "../../assets/images/circleAdd.svg";

import VaultTour from "../../components/TourGuides/Protect/VaultTour";
import { eUserSettings } from "../../components/UserSettings/UserSettings";
import { getJobInfo, removeCurrentJob } from "../../store/actions/CareerPlanning";
import Greeting from "../../components/UI/Greeting";
import TagManager from 'react-gtm-module';

const ProtectDashboardPage: React.FC<{}> = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.session.user);
  const realUser = useSelector((state: any) => state.data.realUser);
  const [openModal, setOpenModal] = useState(false);
  const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [showVault, setShowVault] = useState(true);
  const [currentView, setCurrentView] = useState("SECURE VAULT");
  const [activeFolder, setActiveFolder] = useState({
    category: "",
    icon: "",
    files: [],
  });
  const [currentHint, setCurrentHint] = useState(-1);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [showCarousel, setShowCarousel] = useState(true);

  const [profileTab, setProfileTab] = useState(eUserSettings.PROFILE);

  // Google analytics set up for page
  const tagManagerArgs = {
    dataLayer: {
      userId: realUser?.trackingId,  
      page: 'Protect'
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  const openConnectedAccounts = () => {
    if (isCanonical(user.name)) return;

    setProfileTab(eUserSettings.CONNECTED_ACCOUNTS);
    setShowProfileSwitcher(true);
    setShowCarousel(false);
  };

  useEffect(() => {
    if (user.hints && Object.keys(user.hints).length > 0) {
      let newHint = -1;
      if (user.hints["profile.protect"].enabled) {
        newHint = 0;
      }
      if (newHint >= 0 && newHint > currentHint) {
        //dispatch(changeHint(newHint));
        setCurrentHint(newHint);
      }
    }
  }, [user.hints, currentHint]);

  const token = user.access_token;

  const { screenIsAtLeast, screenIsAtMost } = useResponsive();

  let profiles = [
    {
      name: "Stacey",
      img: staceyAvatar,
      label: "Planning for College",
      profileNumber: 2,
    },
    {
      name: "Rosa",
      img: rosaAvatar,
      label: "Begin Carrer",
      profileNumber: 3,
    },
    {
      name: "Daryl",
      img: darylAvatar,
      label: "Tools for Parents",
      profileNumber: 4,
    },
    {
      name: "Martin",
      img: martinAvatar,
      label: "Buy a House",
      profileNumber: 5,
    },
    {
      name: "Eve",
      img: eveAvatar,
      label: "Planning for Retirement",
      profileNumber: 6,
    },
  ];

  const handleModal = () => {
    setOpenModal(!openModal);
    window.scrollTo(0, 0);
  };

  const onSelectedFile = (folder) => {
    setShowVault(false);
    setActiveFolder(folder);
    setShowFiles(true);
    window.scrollTo(0, 0);
  };

  let content = null;

  if (Object.keys(user).length > 0) {
    let selectCardContent = {
      name: user.name,
      img: !user.anonymized
        ? maleAvatar
        : user.name.length > 0
          ? profiles.filter(
            (profile) =>
              profile.name.toLowerCase() === user.name.toLowerCase()
          )[0].img
          : "",
      label: !user.anonymized
        ? ""
        : user.name.length > 0
          ? profiles.filter(
            (profile) =>
              profile.name.toLowerCase() === user.name.toLowerCase()
          )[0].label
          : "",
      profileNumber: !user.anonymized
        ? 2
        : profiles.filter(
          (profile) =>
            profile.name.toLowerCase() === user.name.toLowerCase()
        )[0].profileNumber - 1
    };

    content = (
      <div
        className={`${screenIsAtMost('tabLand') ? classes.containerMobile : [classes.container, classes.treeBackground].join(' ')}`}
      >

        <VaultTour />

        {openModal && <Backdrop onClick={handleModal} />}

        {showProfileSwitcher && (
          <Backdrop
            customStyle={{
              overflow: "auto",
            }}
            onClick={() => { 
              setShowCarousel(true);
              setProfileTab(eUserSettings.PROFILE);
              setShowProfileSwitcher(false);
            }}
          >
            <ProfileSwitcher
              showCarousel={showCarousel}
              startingProfile={selectCardContent.profileNumber}
              onSelectedProfile={(name: string) => {
                if (name !== "" && name !== "STACEY") {
                  dispatch(removeCurrentJob());
                }
                else {
                  if (name === "STACEY") {
                    dispatch(getJobInfo(15 - 1299.08));
                  }
                  else {
                    const careerGoalLE = realUser.lifeEvents.find(
                      (le: any) => le.externalId === "6ceIi2ikmezTJtxIDEuRsg" && le.status !== "NEW"
                    );
                    if (careerGoalLE) {
                      const getOnetCode = careerGoalLE.customData.selectedCareerOnetCode;
                      dispatch(getJobInfo(getOnetCode));
                    } else {
                      dispatch(removeCurrentJob());
                    }
                  }
                }
                setProfileTab(eUserSettings.PROFILE);
                setShowProfileSwitcher(false);
              }}
              profileTab={profileTab}
              onClose={() => {
                setShowCarousel(true);
                setShowProfileSwitcher(false)}}
            />
          </Backdrop>
        )}

        <div
          style={{ flexDirection: screenIsAtMost('tabLand') ? 'column' : 'row', alignItems: screenIsAtMost('mobile') ? 'center' : '' }}
          className={classes.content}
        >

          {screenIsAtLeast("desktop") && (
            <NavigationView
              profile={isCanonical(user.name)}
              connectedAccounts={user.connectedAccounts.length}
              protectKey={"Secure Vault"}
              onProtectLinkClick={(event, item) => {
                handleSideNavigation(item.name, history)
              }}
              onConnectButtonClick={() => history.push("/connect")}
              onGrowButtonClick={() => history.push("/grow/overview")}
              onConnectAccountClick={openConnectedAccounts}
              onProtectButtonClick={() => history.push("/protect/securevault")}
              currentDashboard="PROTECT"
              onSelectBubleClickHandler={() => {
                setProfileTab(eUserSettings.PROFILE);
                setShowProfileSwitcher(true);
              }}
            />
          )}

          {screenIsAtMost("tabLand") &&
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '85%', paddingTop: '3rem' }}>

              <Greeting
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={user.connectedAccounts.length}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                showOnlySalutation
                onSelectBubleClickHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
              />

              <Greeting
                hideMenuLogo
                hideSalutation
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={user.connectedAccounts.length}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
              />
            </div>

          }


          <DashboardContainer childrenGap={30}>
            <div
              style={{
                width: "100%", display: 'flex',
                justifyContent: 'space-between', alignItems: 'center'
              }}
            >

              {!screenIsAtMost("tabLand") && <Greeting
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={user.connectedAccounts.length}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                showOnlySalutation
              />}

              {!screenIsAtMost("tabLand") && <Greeting
                hideSalutation
                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                backToProfileHandler={() => {

                }}
                chooseOtherProfileHandler={() => {
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
                profile={user.anonymized}
                connectedAccounts={user.connectedAccounts.length}
                connectedKey={currentView}
                onConnectedLinkClick={
                  () => { }
                }
                onConnectButtonClick={() => history.push("/connect")}
                onProtectButtonClick={() => history.push("/protect/securevault")}
                onGrowButtonClick={() => history.push("/grow/overview")}
                onConnectAccountClick={openConnectedAccounts}
                onUserAvatarClick={() => {
                  setShowCarousel(false);
                  setProfileTab(eUserSettings.PROFILE);
                  setShowProfileSwitcher(true);
                }}
              />}
            </div>

            {currentView === "SECURE VAULT" && showVault && !showFiles && (
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ width: "100%" }}
              >
                <Stack
                  horizontal
                  wrap
                  horizontalAlign="start"
                  style={{ width: "100%" }}
                >
                  <Protect
                    openUploadModal={openUploadModal}
                    showAdd={!isCanonical(user.name)}
                    token={token}
                    onEnd={onSelectedFile}
                    onCloseUploadModal={() => {
                      setOpenUploadModal(false);
                    }}
                  />
                </Stack>
              </Stack>
            )}
            {currentView === "SECURE VAULT" && !showVault && showFiles && (
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ width: "100%" }}
              >
                <Stack
                  horizontal
                  wrap
                  horizontalAlign="start"
                  style={{ width: "100%" }}
                >
                  <FilesByCategory
                    allowUpload={!isCanonical(user.name)}
                    token={token}
                    folder={activeFolder}
                    onClose={() => {
                      setShowFiles(false);
                      setShowVault(true);
                    }}
                  />
                </Stack>
              </Stack>
            )}
            {!isCanonical(user.name) && (
              <div
                style={{
                  width: "95%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {currentHint === 0 && (
                  <Hint
                    asset={circleAdd}
                    id={hintsEntries.protect}
                    onDismissClick={() => {
                      setCurrentHint(currentHint + 1);
                    }}
                    onActionClick={() => {
                      setOpenUploadModal(true);
                    }}
                  />
                )}
              </div>
            )}
          </DashboardContainer>
        </div>
      </div>
    );
  }

  return content;
};

export default ProtectDashboardPage;
