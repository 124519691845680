import React, { useState } from 'react';
import { MaskedTextField, TextField, IconButton } from 'office-ui-fabric-react/lib/';
import classes from '../EFTextField.module.css';

interface IProps {
    id?: string,
    style?: object,
    placeholder?: string,
    placeholderColor?: string,
    value?: string,
    onChange?: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onBlur?: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    type?: string,
    onGetErrorMessage?: () => JSX.Element,
    errorMessage?: string,
    mask?: string,
    width?: string,
    isDropdown?: boolean,
    dropdownOptions?: any,
    autoComplete?: boolean,
    onSelectedDropdownOption?: (option: string) => void,
    clear?: () => void;
    name?: string;
};

const EFTextField: React.FC<IProps> = props => {
    const mask = props.mask || (props.type === 'phone' ? '(999) 999 - 9999' : '');
    const [type, setType] = React.useState(props.type);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const activeBorderColor = (!props.errorMessage) ? 'rgba(0, 0, 0, 0.11)' : '#fcadba';

    const textFieldStyle = {
        root: {
            width: props.width || '80%',
        },
        wrapper: {
            padding: 0,
            borderColor: (!props.value && !props.errorMessage) ? 'rgba(0, 0, 0, 0.11)' : activeBorderColor,
            selectors: {
                '&:hover': {
                    borderColor: 'rgba(0, 0, 0, 0.11)',
                },
                '::after': {
                    borderColor: 'transparent',
                },
            },
        },
        field: {
            padding: 0,
            color: '#343f6e',
            fontFamily: 'brandon-grotesque',
            fontSize: '1.8rem',
            selectors: {
                '::placeholder': [{ color: props.placeholderColor ? props.placeholderColor : 'rgba(0, 0, 0, 0.11)', fontSize: 18, fontFamily: 'brandon-grotesque', }],
            },
            width: props.width || '80%'
        },
        suffix: {
            backgroundColor: 'transparent',
        },
        errorMessage: {
            color: '#fcadba',
            fontSize: '1.3rem',
            fontFamily: 'brandon-grotesque',
        },
    };

    const _onSuffixRenderer = (): JSX.Element => {
        return (
            props.type === 'password' ? (
                <IconButton
                    iconProps={{ iconName: 'PwdEye' }}
                    title='password'
                    onClick={() => setType(type === 'password' ? 'text' : 'password')}
                    styles={{
                        root: {
                            backgroundColor: 'transparent',
                        },
                        rootHovered: {
                            backgroundColor: 'transparent',
                        },
                        rootPressed: {
                            backgroundColor: 'transparent',
                        },
                    }}
                />
            ) : <></>
        );
    }

    const dropdownOnFocus = () => {
        setShowOptions(true);
    }

    const dropdownOnBlur = () => {
        setShowOptions(false);
    }

    const onSelectedOption = (option: string) => {
        setSelectedOption(option);
        setShowOptions(false);
        props.onSelectedDropdownOption(option);
    }

    return (
        !(props.mask) ? (
            <>
                <TextField
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    ariaLabel='text-input'
                    onChange={props.isDropdown ? null : props.onChange}
                    onBlur={props.isDropdown ? null : props.onBlur}
                    placeholder={props.placeholder}
                    styles={textFieldStyle}
                    underlined
                    type={type}
                    errorMessage={props.errorMessage}
                    onGetErrorMessage={props.onGetErrorMessage}
                    onRenderSuffix={_onSuffixRenderer}
                    onFocus={props.isDropdown ? dropdownOnFocus : null}
                    iconProps={props.isDropdown ? { iconName: 'ChevronDownMed' } : {}}
                    autoComplete={props.autoComplete ? 'on' : 'off'}
                />
                {props.isDropdown && showOptions &&
                    <div className={classes.container}>
                        {props.dropdownOptions.map((option, index) => {
                            return (
                                <div key={index} className={classes.optionContainer}
                                    style={{
                                        borderBottom: index + 1 === props.dropdownOptions.length ? '' : '1px solid rgba(0, 0, 0, 0.15)'
                                    }}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        onSelectedOption(option);
                                    }}>
                                    <p className={classes.dropdownOptionText}>{option}</p>
                                </div>
                            )
                        })}
                    </div>
                }
            </>
        ) : (
                <MaskedTextField
                    id={props.id}
                    name={props.name}
                    mask={mask}
                    value={props.value}
                    ariaLabel='text-input'
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    styles={textFieldStyle}
                    underlined
                    type={type}
                    errorMessage={props.errorMessage}
                    onGetErrorMessage={props.onGetErrorMessage}
                    onRenderSuffix={_onSuffixRenderer}
                />
            )
    )
};

EFTextField.defaultProps = {
    errorMessage: '',
    mask: '',
};

export default EFTextField;
