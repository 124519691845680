import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import classes from './EditorModal.module.css';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import Modal from 'react-modal';


interface IProps {
    openModal: boolean;
    title?: string;
    color?: string;
    onClickExit: () => void;
    noTitle?: boolean;
};

const EditorModal: React.FC<IProps> = props => {
    return (
        <Modal
            isOpen={props.openModal}
            onRequestClose={props.onClickExit}
            style={modalCardStyle}
        >
            <Stack onClick={props.onClickExit} className={classes.exit_container} horizontalAlign='end'>
                <img 
                    className={classes.exit} 
                    alt='x' 
                    src={require('../../../assets/images/scoop/exit_modal.svg')} 
                />
            </Stack>
            {props.noTitle ? <>{props.children}</> : (
                <>
                    <EFLabel style={{...labelStyle, color: props.color}}>{props.title}</EFLabel>
                    <Stack horizontalAlign='center' className={classes.container_list}>
                        {props.children}
                    </Stack>
                </>
            )}
        </Modal>
    );
};

const modalCardStyle = {
    content: {
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: '1rem',
        margin: 'auto',
        boxShadow: '0px 2px 11px rgba(0, 0, 0, 0.11)',
        width: '45rem',
        backgroundColor: '#FFF',
        borderStyle: 'none' as 'none',
        inset: 'unset' as 'unset',
        position: 'unset' as 'unset',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        borderRadius: '1.3rem',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)',
    }
};

const labelStyle = {
    fontSize: 22, 
    fontFamily: 'brandon-grotesque', 
    fontWeight: 600, 
    marginBottom: 23,
    paddingBottom: 10,
    borderBottom: '1px solid #EEEEEE',
    width: '100%',
    textAlign: 'center'
};

export default EditorModal;
