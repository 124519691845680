import React, { useEffect, useState } from 'react';
import EFButton from '../../../Atomic/EFButton/EFButton';
import FabricCalendar from '../../../Atomic/Calendar/FabricCalendar/FabricCalendar';
import classes from '../Interstitial.module.css';

import close from '../../../../assets/images/close.svg';
import pole from '../../../../assets/images/pole.svg';
import moment from 'moment';
import EFLabel from '../../../Atomic/EFLabel/EFLabel';
import { useMutation, useQuery } from 'react-apollo';
import { GET_CF_SETUP_2_5 } from '../../../../hooks/Apollo/queries';
import { Stack } from 'office-ui-fabric-react';
import maskValue, { numberMaskUS } from '../../../../utils/Masking';
import { SAVE_CASHFLOW_2_5 } from '../../../../hooks/Apollo/mutations';
import EFSpinner from '../../../Atomic/EFSpinner/EFSpinner';

import plus from '../../../../assets/images/scf/plus.svg';
import check from '../../../../assets/images/scf/check.svg';

interface Props {
    lifeEventDetails?: any;
    loanTerm?: any;
    onClick?: (formData) => void;
    onCloseClick?: () => void;
};

const DebtInterstitial: React.FC<Props> = props => {
    const initDate = moment().valueOf();
    const endDate = (d) => moment(d).add(props.loanTerm / 12, "years").valueOf();
    const [formData, setFormData] = React.useState(
        { 
            nickName: props.lifeEventDetails.name, 
            startDate: props.lifeEventDetails?.startDate || initDate, 
            date: props.lifeEventDetails?.date || initDate 
        }
    );
    const [currentSlide, setCurrentSlide] = useState(0);
    const [cashCards, setCashCards] = useState<any>([]);
    const [saveEntry2] = useMutation(SAVE_CASHFLOW_2_5, { context: { clientName: "cashFlow" }, onError(error) { console.error(error) } });
    const [skipCat, setSkip] = useState(false);

    useQuery(GET_CF_SETUP_2_5 , {
        onCompleted: data => {
            setCashCards(data?.userCashflowNextGenSetup);
            setSkip(true);
        },
        skip: skipCat,
        fetchPolicy: "no-cache",
    })
    const onChangeSubCategory = (cashCard, id, valueLEObj?) => {
        let subs = cashCard?.subCategories;
        let currValue = valueLEObj ? valueLEObj : { amount: 0, type: "USER_INPUT" }
        subs = subs?.map(d => {
            if (d.id === id) {
              return { 
                  ...d, 
                  amount: currValue?.amount, 
                  simuAmount: currValue?.amount,
                  values: [currValue],
                  source: currValue?.type,
            };
            }
            return d;
        });
        const totalAmt = subs?.reduce((a, b) => a + b.amount, 0);
        const updatedSet = cashCards?.map(d => {
            if (d === cashCard) {
                return { ...d, amount: totalAmt, subCategories: subs }
            }
            return d
        });
        setCashCards(updatedSet);
    }
    const onSave = async () => {
        const cashMapping = props.lifeEventDetails?.overview?.cashflowMapping;
        const cashCardIds = cashMapping?.map(e => e.groupId)?.filter((v, i, a) => a?.indexOf(v) === i);
        cashCardIds?.forEach(async id => {
            const cashCard = cashCards?.find(cc => cc.id.toString() === id);
            const mutate = {
                id: id,
                subCategories: cashCard.subCategories.map(s => {
                    const amt = cashMapping?.find(e => e?.subCategoryId === s.id)?.value?.amount;
                    return {
                        id: s.id,
                        amount: s.source === "LIFE_EVENT" ? amt || s.amount : s.amount,
                        simuAmount: s.source === "LIFE_EVENT" ? amt || s.amount : s.amount,
                        values: s.values.filter(v => v.type === "LIFE_EVENT").map(e => ({
                            amount: amt || s.amount,
                            source: e.source,
                            type: e.type,
                            name: e.name,
                            startDate: e.startDate,
                            endDate: e.endDate,
                        })),
                        source: s.source,
                    }
                }),
                onboarded: true,
            };
            await saveEntry2({variables: mutate});
        });
        props.onCloseClick();
    }
    
    const onClose = async () => {
        if (currentSlide === 1) {
            await onSave();
        } else {
            props.onCloseClick();
        }
    }
    const leHint = (cashflowMap) => {
        const cashCard = cashCards?.find(cc => cc.id.toString() === cashflowMap?.groupId);
        return (
            <Stack style={{ margin: '0 2rem' }} horizontalAlign="space-between" horizontal>
                <Stack horizontalAlign="start">
                    <EFLabel style={{ fontWeight: 'unset' }}>{cashflowMap?.name}</EFLabel>
                    <EFLabel>{maskValue(cashflowMap?.value?.amount, numberMaskUS)}</EFLabel>
                </Stack>
                {cashCard?.subCategories?.find(s => s.id === cashflowMap?.subCategoryId)?.source !== "LIFE_EVENT" ? (
                    <Stack
                        horizontal
                        horizontalAlign="space-evenly"
                        verticalAlign="center"
                        style={{ width: '8.2rem', height: '3.3rem', backgroundColor: '#979797', borderRadius: '1.5rem', borderColor: 'unset', cursor: 'pointer' }} 
                        onClick={() => onChangeSubCategory(cashCard, cashflowMap?.subCategoryId, cashflowMap?.value)}
                    >
                        <EFLabel style={{ fontSize: '1.4rem', color: '#FFFFFF' }}>Use It</EFLabel>
                        <img src={plus} style={{ width: '1.03rem', height: '1.03rem' }} alt="plus" />
                    </Stack>
                ) : (
                    <Stack
                        horizontal
                        horizontalAlign="space-evenly"
                        verticalAlign="center"
                        style={{ width: '8.2rem', height: '3.3rem', backgroundColor: '#00D250', borderRadius: '1.5rem', borderColor: 'unset', cursor: 'pointer' }} 
                        onClick={() => onChangeSubCategory(cashCard, cashflowMap?.subCategoryId)} 
                    >
                        <EFLabel style={{ fontSize: '1.4rem', color: '#FFFFFF' }}>Added</EFLabel>
                        <img src={check} style={{ width: '1.03rem', height: '1.03rem' }} alt="checked" />
                    </Stack>
                )}
            </Stack>
        )
    }

    let content = (
        <>
            <p className={classes.header}>
                Let's check your timeline
            </p>
            <div className={classes.labelSaveContent}>
                <p className={classes.content} style={{ fontSize: '1.8rem', width: '10rem', textAlign: 'center' }}>
                    Start loan payment in
                </p>
                <p className={classes.content} style={{ fontSize: '1.8rem', width: '10rem', textAlign: 'center'  }}>
                    Pay off time <b>{props.loanTerm}</b> month{props.loanTerm === 1 ? '' : 's'}
                </p>
            </div>
            <div className={classes.poleContent}>
                <img className={classes.pole} src={pole} alt="pole" />
            </div>
            <div className={classes.inputSaveContent}>
                <div className={classes.labelContainer}>
                    <FabricCalendar
                        selectedDate={formData.startDate} 
                        strFormat="MM/YYYY" 
                        isDayPickerVisible={false}
                        onSelectDate={d => 
                            setFormData({ ...formData, "startDate": moment(d).valueOf(), "date": moment(d).valueOf() })
                        }
                        labelStyle={{ color: '#293861', fontSize: '1.6rem' }}
                    />
                </div>
                <p className={classes.content} style={{ fontSize: '2rem', padding: '0 2rem' }}>to</p>
                <div className={classes.labelContainer}>
                    <EFLabel style={{ fontSize: '1.6rem'}}>
                        {moment(endDate(formData.startDate)).format("MM/YYYY")}
                    </EFLabel>
                </div>
            </div>
        </>
    )

    let button = (
        <EFButton 
            text="SAVE AND CONTINUE" 
            onClick={async () => {
                setCurrentSlide(1);
                props.onClick(formData);
            }}
            style={{ minWidth: '16rem' }} 
        />
    );

    if (currentSlide === 1) {
        content = (
            <>
                <p className={classes.content} style={{ textAlign: 'center', padding: '5rem 0', width: '43rem', fontWeight: 500 }}>
                    Would you like to add these expenses to your Smart Cashflow now?
                </p>
                {props.lifeEventDetails?.overview?.cashflowMapping?.length ? (
                    <Stack tokens={{ childrenGap: '2rem' }} className={classes.hintContainer}>
                        {props.lifeEventDetails?.overview?.cashflowMapping?.map((e, i) => (
                            <>
                                {leHint(e)}
                                {i !== props.lifeEventDetails?.overview?.cashflowMapping?.length - 1 ? <div className={classes.divider} /> : <></>}
                            </>
                        ))}
                    </Stack>
                ) : <EFSpinner />}
                <p className={classes.helperLabel} >You can link expenses manually at anytime</p>
            </>
        );

        button = (
            <EFButton 
                text="SAVE"
                onClick={onSave}
                style={{ width: '16rem', marginTop: '2rem' }}
            />
        )
    }

    return (
        <div className={classes.container}>
            <div className={classes.closeContainer}>
                <img 
                    src={close}
                    alt="close" 
                    onClick={onClose}
                    className={classes.close}
                />
            </div>
            <div className={classes.generalContainer}>
                <div className={classes.titleContainer}>
                    <img src={props.lifeEventDetails.icon[0].url} alt="le" style={{ width: '15%' }} />
                    <p className={classes.title}>{props.lifeEventDetails.name}</p>
                </div>
                <div className={classes.contentContainer}>
                    {content}
                </div>
                <div className={classes.buttonContainer}>
                    {button}
                </div>
            </div>
        </div >
    );
};

export default DebtInterstitial;
