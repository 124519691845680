import React from 'react';
import { Stack, IconButton } from 'office-ui-fabric-react/lib/';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import EFTooltip from '../../Atomic/EFTooltip/EFTooltip';

interface Props {
    value: number,
    onChange: (val: number, index?: number) => void;
    label?: string,
    prefix?: string,
    suffix?: string,
    includeThousandsSeparator?: boolean,
    thousandsSeparatorSymbol?: string,
    allowDecimal?: boolean,
    decimalSymbol?: string,
    decimalLimit?: number,
    allowNegative?: boolean,
    allowLeadingZeroes?: boolean,
    maxVal: number,
    minVal: number,
    dynamicStepEnabled?: boolean,
    numStepper?: number,
    displayErrStyle?: boolean,
    tooltip?: string,
};

const StepperInput: React.FC<Props> = props => {
    const iconButtonStyles = {
        root: {
            width: '4.5rem',
            height: '3.8rem',
            backgroundColor: !props.displayErrStyle ? '#f6f8f8' : '#f7f0f0',
            borderRadius: 0,
        },
    };
    
    const textFieldStyle = {
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderColor: !props.displayErrStyle ? 'rgba(0, 0, 0, 0.3)' : '#E0B4B5',
        borderLeft: 0,
        borderRight: 0,
        outline: 0,
        padding: 0,
        width: '18.7rem',
        height: '3.55rem',
        background: !props.displayErrStyle ? 'transparent' as 'transparent' : '#FEF6F6',
        color: !props.displayErrStyle ? '#293861' : '#c4233e',
        fontSize: '1.7rem',
        fontFamily: 'brandon-grotesque',
        fontWeight: 'normal' as 'normal',
        textAlign: 'center' as 'center',
    };

    const value = (typeof(props.value) !== 'number') ? 0 : props.value;
    let initVal = parseFloat(value.toFixed(props.decimalLimit));
    initVal = !props.allowDecimal ? ~~initVal : initVal;

    const decrementValue = () => {
        const newVal = (initVal <= props.minVal) ? props.minVal : initVal - getStep();
        props.onChange(newVal as number);
    }

    const incrementValue = () => {
        const newVal = (initVal >= props.maxVal) ? props.maxVal : initVal + getStep();
        props.onChange(newVal as number);
    }

    const handleStrToNum = (e: any) => {
        let curr_str = e.target.value;
        curr_str = curr_str.replace(/,/g, '');
        curr_str = curr_str.replace(props.prefix, '');
        curr_str = curr_str.replace(props.suffix, '');
        let numVal = parseFloat(curr_str);
        if (isNaN(numVal) || numVal < props.minVal) {
            numVal = props.minVal;
        } else if (numVal > props.maxVal) {
            numVal = props.maxVal;
        };
        props.onChange(numVal as number);
    };

    const getStep = () => {
        const checkNumRange = (min: number, max: number) => {
            return (initVal - min ^ initVal - max) < 0;
        };
        
        if (props.dynamicStepEnabled) {
            switch (!props.allowDecimal) {
                case checkNumRange(0, 50): return 5;
                case checkNumRange(50, 100): return 10;
                case checkNumRange(100, 1000): return 50;
                case checkNumRange(1000, 10000): return 100;
                case checkNumRange(10000, 25000): return 250;
                case checkNumRange(25000, 50000): return 500;
                case checkNumRange(50000, 100000): return 1000;
                case undefined: return 1;
                default: return 1;
            };
        } else {
            return (props.numStepper || 1);
        }
    };

    return (
        <Stack tokens={{childrenGap: 10}}>
            <Stack tokens={{ childrenGap: '.5rem' }} horizontal verticalAlign="center">
                <EFLabel style={{maxWidth: '25rem', fontWeight: 'normal' as 'normal', color: props.displayErrStyle && '#c4233e'}}>
                    {props.label}
                </EFLabel>
                {props.tooltip ? <EFTooltip text={props.tooltip} style={{ marginTop: '.2rem' }} title={props.label} /> : <></>}
            </Stack>
            <Stack horizontal style={{height: '4.1rem'}}>
                <IconButton 
                    iconProps={{ iconName: 'Minus' }} 
                    styles={iconButtonStyles} 
                    style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)', 
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4,
                        borderColor: !props.displayErrStyle ? 'rgba(0, 0, 0, 0.3)' : '#E0B4B5',
                    }}
                    onClick={decrementValue}
                />
                <MaskedInput 
                    mask={createNumberMask({
                        ...StepperInput.defaultProps,
                        ...props,
                    })} 
                    style={textFieldStyle}
                    value={initVal}
                    onChange={(e: any) => handleStrToNum(e)}
                />
                <IconButton 
                    iconProps={{ iconName: 'Plus' }} 
                    styles={iconButtonStyles} 
                    style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        borderColor: !props.displayErrStyle ? 'rgba(0, 0, 0, 0.3)' : '#E0B4B5',
                    }} 
                    onClick={incrementValue}
                />
            </Stack>
        </Stack>
    );
};

StepperInput.defaultProps = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: false,
    decimalSymbol: '.',
    allowLeadingZeroes: true,
    decimalLimit: 2,
    allowNegative: true,
    maxVal: 100,
    minVal: 0,
    numStepper: 1,
};

export default StepperInput;
