import React from 'react';
import classes from './CashflowCarousel.module.css';
import Carousel from '@brainhubeu/react-carousel';
import { Stack } from 'office-ui-fabric-react';

import leftArrow from '../../../assets/images/leftArrowSCF.svg';
import rightArrow from '../../../assets/images/rightArrowSCF.svg';
import './Carousel.scss'

interface IProps {
    style?: object,
    onChange?: () => void,
    slidesCount?: number,
};

const ScoopCarousel: React.FC<IProps> = props => {
    const arrowStyle = { cursor: 'pointer', width: '5.5rem', height: '5.5rem' }
    const arrowLeft = <img style={{marginRight: '1rem', ...arrowStyle }} alt='left-arrow' src={leftArrow} />
    const arrowRight = <img style={{marginLeft: '1rem', ...arrowStyle }} alt='right-arrow' src={rightArrow} />

    const arrowDLeft = <div style={{marginRight: '1rem', ...arrowStyle }} />
    const arrowDRight = <div style={{marginLeft: '1rem', ...arrowStyle }} />

    return (
        <Stack id="scf" className={classes.container}>
            <Carousel
                slidesPerPage={props.slidesCount}
                addArrowClickHandler
                arrowLeft={arrowLeft}
                arrowLeftDisabled={arrowDLeft}
                arrowRight={arrowRight}
                arrowRightDisabled={arrowDRight}
                clickToChange={false}
                onChange={props.onChange}
                draggable={false}
            >
                {props.children}
            </Carousel>
        </Stack>
    )
};

export default ScoopCarousel;
