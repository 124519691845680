import React from 'react';
import classes from './ScoopCarousel.module.css';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { Stack } from 'office-ui-fabric-react';
import { useResponsive } from '../../../hooks/useResponsive';

interface IProps {
    style?: object,
    onChange?: () => void,
};

const ScoopCarousel: React.FC<IProps> = props => {

    const { screenIsAtMost } = useResponsive();

    const slidesCount = () => {
        let count = 4.5;
        if (screenIsAtMost('tabLand'))
            count = 4;
        if (screenIsAtMost('tabPort'))
            count = 3;
        if (screenIsAtMost('mobile'))
            count = 1.35;

        return count;
    }

    return (
        <Stack verticalAlign='center' className={classes.container}>
            <Carousel
                centered
                slidesPerPage={slidesCount()}
                infinite
                addArrowClickHandler
                arrowLeft={<img style={{marginRight: '1rem', cursor: 'pointer'}} alt='chev-l' src={require('../../../assets/icons/chevron_left.svg')} />}
                arrowRight={<img style={{marginLeft: '1rem', cursor: 'pointer'}} alt='chev-l' src={require('../../../assets/icons/chevron_right.svg')} />}
                clickToChange
                onChange={props.onChange}
            >
                {props.children}
            </Carousel>
        </Stack>
    )
};

export default ScoopCarousel;
