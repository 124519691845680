import React, { useState } from 'react';
import { Stack } from 'office-ui-fabric-react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import EFCard from '../../components/Atomic/EFCard/EFCard';
import ScoopDashboardsView from '../../components/Scoop/ScoopDashboardsView/ScoopDashboardsView';
import DashboardContainer from '../../components/UI/DashboardContainer/DashboardContainer';
import EFBreadcrumb from '../../components/UI/EFBreadcrumb';
import Greeting from '../../components/UI/Greeting';
import NavigationView from '../../components/UI/NavigationView';
import { useResponsive } from '../../hooks/useResponsive';
import * as actions from "../../store/actions/Users";
import * as aggregationActions from "../../store/actions/Aggregation";
import * as careerActions from "../../store/actions/CareerPlanning";


import classes from './GetOrganizedPage.module.css';
import animations from "../../utils/animations.module.css";
import { usePersistentApolloState } from '../../hooks/Apollo/usePersistentApolloState';
import Nudget from '../../components/UI/Nudget/Nudget';
import EFLabel from '../../components/Atomic/EFLabel/EFLabel';
import { handleSideNavigation } from '../../utils/global';
import Backdrop from '../../components/Atomic/Backdrop/Backdrop';
import { eUserSettings } from '../../components/UserSettings/UserSettings';
import ProfileSwitcher from '../../components/UI/ProfileSwitcher/ProfileSwitcher';

import maleAvatar from "../../assets/images/maleAvatar.svg";
import staceyAvatar from "../../assets/images/staceyAvatar.png";
import rosaAvatar from "../../assets/images/rosaAvatar.png";
import darylAvatar from "../../assets/images/darylAvatar.png";
import martinAvatar from "../../assets/images/martinAvatar.svg";
import eveAvatar from "../../assets/images/eveAvatar.svg";
import ScoopTour from '../../components/TourGuides/Connect/ScoopTour';
import TagManager from 'react-gtm-module'

const GetOrganizedPage: React.FC<{}> = () => {

    const dispatch = useDispatch();
    let history = useHistory();

    const { screenIsAtLeast, screenIsAtMost } = useResponsive();

    const user = useSelector((state: any) => state.session.user);
    const realUser = useSelector((state: any) => state.data.realUser);

    const { state: transactionsData } = usePersistentApolloState("transactionsState");
    const { state: transcationCategoriesData } = usePersistentApolloState("transactionsCategoriesState");
    const { state: balancesData } = usePersistentApolloState("balancesState");

    const [showCarousel, setShowCarousel] = useState(true);
    const [profileTab, setProfileTab] = useState(eUserSettings.PROFILE);
    const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);

    // Google analytics set up for page
    const tagManagerArgs = {
        dataLayer: {
            userId: realUser?.trackingId,
            page: 'Get Organized'
        },
        dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs)

    const numConnectedAccounts =
        Object.keys(user) && Object.keys(user)?.length > 0
            ? user?.connectedAccounts?.length
            : 0;

    const onProfileSwitch = (name: string) => {
        //we check if the profile that we are going to render has the Career Goal LE
        //we know that for CP, only Stacey has it
        if (name !== "" && name !== "STACEY") {
            dispatch(careerActions.removeCurrentJob());
        }
        else {
            if (name === "STACEY") {
                dispatch(careerActions.getJobInfo(15 - 1299.08));
            }
            else {
                const careerGoalLE = realUser.lifeEvents.find(
                    (le: any) => le.externalId === "6ceIi2ikmezTJtxIDEuRsg" && le.status !== "NEW"
                );
                if (careerGoalLE) {
                    const getOnetCode = careerGoalLE.customData.selectedCareerOnetCode;
                    dispatch(careerActions.getJobInfo(getOnetCode));
                } else {
                    dispatch(careerActions.removeCurrentJob());
                }
            }
        }

        setProfileTab(eUserSettings.PROFILE);
        setShowProfileSwitcher(false);
    };

    const openConnectedAccounts = () => {
        if (user.anonymized) return;
        setProfileTab(eUserSettings.CONNECTED_ACCOUNTS);
        setShowProfileSwitcher(true);
        setShowCarousel(false);
    };

    let content = null;

    if (Object.keys(user).length > 0) {

        let profiles = [
            {
                name: "Stacey",
                img: staceyAvatar,
                label: "Planning for College",
                profileNumber: 2,
            },
            {
                name: "Rosa",
                img: rosaAvatar,
                label: "Begin Career",
                profileNumber: 3,
            },
            {
                name: "Daryl",
                img: darylAvatar,
                label: "Tools for Parents",
                profileNumber: 4,
            },
            {
                name: "Martin",
                img: martinAvatar,
                label: "Buy a House",
                profileNumber: 5,
            },
            {
                name: "Eve",
                img: eveAvatar,
                label: "Planning for Retirement",
                profileNumber: 6,
            },
        ];

        let selectCardContent = {
            name: user.name,
            img: !user.anonymized
                ? maleAvatar
                : user.name.length > 0
                    ? profiles.filter(
                        (profile) =>
                            profile.name.toLowerCase() === user.name.toLowerCase()
                    )[0].img
                    : "",
            label: !user.anonymized
                ? ""
                : user.name.length > 0
                    ? profiles.filter(
                        (profile) =>
                            profile.name.toLowerCase() === user.name.toLowerCase()
                    )[0].label
                    : "",
            profileNumber: !user.anonymized
                ? 2
                : profiles.filter(
                    (profile) =>
                        profile.name.toLowerCase() === user.name.toLowerCase()
                )[0].profileNumber - 1
        };

        content = (
            <div
                className={`${screenIsAtMost('tabLand') ? classes.containerMobile : [classes.container, classes.treeBackground].join(' ')}`}
            >

                {showProfileSwitcher && (
                    <Backdrop
                        customStyle={{
                            position: "fixed",
                            overflow: "auto",
                        }}
                        onClick={() => {
                            setShowCarousel(true);
                            setProfileTab(eUserSettings.PROFILE);
                            setShowProfileSwitcher(false);
                        }}
                    >
                        <ProfileSwitcher
                            hide={(hide: boolean) => { }}
                            startingProfile={selectCardContent.profileNumber}
                            onSelectedProfile={onProfileSwitch}
                            profileTab={profileTab}
                            showCarousel={showCarousel}
                            onClose={() => {
                                setShowCarousel(true);
                                setShowProfileSwitcher(false)}}
                        />
                    </Backdrop>
                )}

                <div
                    style={{ flexDirection: screenIsAtMost('tabLand') ? 'column' : 'row', alignItems: screenIsAtMost('tabLand') ? 'center' : '' }}
                    className={classes.content}
                >
                    <ScoopTour />

                    {screenIsAtLeast("desktop") && (
                        <NavigationView
                            profile={user.anonymized}
                            connectedAccounts={numConnectedAccounts}
                            connectedKey="Get Organized"
                            onConnectedLinkClick={(event, item) => {
                                handleSideNavigation(item.name, history)
                            }}
                            onConnectButtonClick={() => history.push("/connect")}
                            onProtectButtonClick={() => history.push("/protect/securevault")}
                            onGrowButtonClick={() => history.push("/grow/overview")}
                            onConnectAccountClick={openConnectedAccounts}
                            currentDashboard="CONNECT"
                            selectCardContent={selectCardContent}
                            onSelectBubleClickHandler={() => {
                                setProfileTab(eUserSettings.PROFILE);
                                setShowProfileSwitcher(true);
                            }}
                        />
                    )}

                    {screenIsAtMost("tabLand") &&
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '85%', paddingTop: '3rem' }}>

                            <EFBreadcrumb
                                onParentClick={() => { history.push("/connect") }}
                                parentTitle="CONNECT"
                                profile={user.anonymized}
                                connectedAccounts={0}
                                connectedKey="CONNECT"
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect/securevault")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                childrenTitles={
                                    ["GET ORGANIZED"]
                                }
                                onConnectAccountClick={openConnectedAccounts}
                                style={{
                                    flexFlow: 'row',
                                    alignItems: 'center',
                                    width: '65%',
                                    justifyContent: 'space-between'
                                }}
                                onSelectBubleClickHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />

                            <Greeting
                                hideMenuLogo
                                hideSalutation
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={numConnectedAccounts}
                                connectedKey=""
                                onConnectedLinkClick={
                                    () => { }
                                }
                                onConnectButtonClick={() => history.push("/connect")}
                                onProtectButtonClick={() => history.push("/protect/securevault")}
                                onGrowButtonClick={() => history.push("/grow/overview")}
                                onConnectAccountClick={openConnectedAccounts}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />
                        </div>

                    }


                    <DashboardContainer childrenGap={30}>
                        <div className={classes.headerSection}>

                            {!screenIsAtMost("tabLand") &&
                                <EFBreadcrumb
                                    dataTourId={"get-organized"}
                                    onParentClick={() => { history.push("/connect") }}
                                    parentTitle="CONNECT"
                                    profile={user.anonymized}
                                    connectedAccounts={0}
                                    connectedKey="CONNECT"
                                    onConnectedLinkClick={
                                        () => { }
                                    }
                                    onConnectButtonClick={() => { }}
                                    onProtectButtonClick={() => { }}
                                    onGrowButtonClick={() => { }}
                                    childrenTitles={
                                        ["GET ORGANIZED"]
                                    }
                                    onConnectAccountClick={() => { }}
                                />}

                            {!screenIsAtMost("tabLand") && <Greeting
                                hideSalutation
                                showLabelSubtext={realUser && Object.keys(realUser).length > 0}
                                backToProfileHandler={() => {

                                }}
                                chooseOtherProfileHandler={() => {
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                                profile={user.anonymized}
                                connectedAccounts={numConnectedAccounts}
                                connectedKey=""
                                onConnectedLinkClick={
                                    () => {

                                    }
                                }
                                onConnectButtonClick={() => {

                                }}
                                onProtectButtonClick={() => {

                                }}
                                onGrowButtonClick={() => {

                                }}
                                onConnectAccountClick={() => {

                                }}
                                onUserAvatarClick={() => {
                                    setShowCarousel(false);
                                    setProfileTab(eUserSettings.PROFILE);
                                    setShowProfileSwitcher(true);
                                }}
                            />}

                        </div>

                        <div className={classes.cardSection} style={{ flexDirection: screenIsAtMost("tabLand") ? 'column' : 'row' }}>

                            <EFCard
                                style={{ cursor: 'pointer' }}
                                dataTourId="scoop-dbs"
                                stackClassName={animations.cardShadowExpand}
                                title="SCOOP"
                                onClick={() => {
                                    history.push('/connect/getorganized/scoopdashboards');
                                }}
                            >
                                <ScoopDashboardsView
                                    isPreview
                                    transactionsData={transactionsData}
                                    accBalancesData={balancesData}
                                    onChange={(db: string) => history.push('/connect/getorganized/scoopdashboards', { selectedDashboard: db })}
                                    hasAccounts={numConnectedAccounts !== 0}
                                    onAddAccsClick={() => {
                                        dispatch(
                                            aggregationActions.getFastlinkCredentials(history, false)
                                        );
                                        dispatch(actions.setRedirect(false));
                                        history.push("/aggregate");
                                    }}
                                />
                            </EFCard>

                            <EFCard
                                dataTourId="nudget"
                                stackClassName={animations.cardShadowExpand}
                                title="NUDGET"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    history.push('/connect/getorganized/nudget')
                                }}
                            >
                                <div style={{ width: "100%", height: "100%" }}>
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <Nudget
                                            categoryData={transcationCategoriesData}
                                            currentView="Get Organized"
                                            flag={false}
                                        />
                                    </div>
                                </div>
                            </EFCard>

                            <EFCard
                                dataTourId="kakeibo"
                                stackClassName={animations.cardShadowExpand}
                                title="KAKEIBO"
                                onClick={() => {
                                    history.push('/connect/getorganized/kakeibo')
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <img
                                    alt="new-job"
                                    style={{ width: "17rem" }}
                                    src={require("../../assets/images/kakeibo_icon.png")}
                                />
                                <Stack tokens={{ childrenGap: 10 }}>
                                    <EFLabel style={{ fontSize: "1.6rem", textAlign: "center" }}>
                                        かけいぼ
                                    </EFLabel>
                                    <EFLabel style={{ fontSize: "2rem", textAlign: "center" }}>
                                        Conscious Spending
                                    </EFLabel>
                                    <EFLabel style={{ fontSize: "1.6rem", textAlign: "center" }}>
                                        Keep track of all your expenses
                                    </EFLabel>
                                </Stack>
                            </EFCard>

                        </div>

                    </DashboardContainer>
                </div>
            </div>
        );
    }

    return content;
};

export default GetOrganizedPage;