import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import maskValue, { numberMaskUS } from '../../../utils/Masking';
import EFLabel from '../../Atomic/EFLabel/EFLabel'
import classes from './LEInsights.module.css'
import { Stack } from 'office-ui-fabric-react';
import plus from '../../../assets/images/scf/plus.svg';
import check from '../../../assets/images/scf/check.svg';

interface IProps {
    style?: any,
    cashCard?: any,
    le: any,
    onClick?: (subId: string, value: number, valObj?: any) => void,
}

const LECard: React.FC<IProps> = props => {
    let history = useHistory();
    const [opened, setOpened] = useState<boolean>(false);
    const lifeEventClickHandler = (eventId: any) => {
        history.push('/connect/lifeevent/' + eventId);
    }

    const leHint = (cashflowMap) => {
        const subCategory = props.cashCard?.subCategories?.find(s => s.id === cashflowMap?.subCategoryId);
        const lifeEventValue = subCategory?.values?.find(e => e?.type === "LIFE_EVENT");

        return (
            <Stack horizontalAlign="space-between" horizontal>
                <Stack horizontalAlign="start">
                    <EFLabel style={{ fontWeight: 'unset' }}>{cashflowMap?.name}</EFLabel>
                    <EFLabel>{maskValue(cashflowMap?.value?.amount, numberMaskUS)}</EFLabel>
                </Stack>
                {subCategory?.source === "LIFE_EVENT" && lifeEventValue?.source === props.le?.id ? (
                    <Stack
                        horizontal
                        horizontalAlign="space-evenly"
                        verticalAlign="center"
                        style={{ width: '8.2rem', height: '3.3rem', backgroundColor: '#00D250', borderRadius: '1.5rem', borderColor: 'unset', cursor: 'pointer' }} 
                        onClick={() => props.onClick(cashflowMap?.subCategoryId, 0)} 
                    >
                        <EFLabel style={{ fontSize: '1.4rem', color: '#FFFFFF' }}>Added</EFLabel>
                        <img src={check} style={{ width: '1.03rem', height: '1.03rem' }} alt="checked" />
                    </Stack>
                ) : (
                    <Stack
                        horizontal
                        horizontalAlign="space-evenly"
                        verticalAlign="center"
                        style={{ width: '8.2rem', height: '3.3rem', backgroundColor: '#979797', borderRadius: '1.5rem', borderColor: 'unset', cursor: 'pointer' }}
                        onClick={() => props.onClick(cashflowMap?.subCategoryId, cashflowMap?.value?.amount, cashflowMap?.value)} 
                    >
                        <EFLabel style={{ fontSize: '1.4rem', color: '#FFFFFF' }}>Use It</EFLabel>
                        <img src={plus} style={{ width: '1.03rem', height: '1.03rem' }} alt="plus" />
                    </Stack>
                )}
            </Stack>
        )
    }

    return (
        <div 
            style={{ ...props.style, border: `1px solid ${props.le.iconColor}` }} 
            key={props.le.id} 
            className={`${classes.accordion} ${opened && classes.accordionopened}`}
        >
            <div 
                onClick={() => setOpened(!opened)} 
                className={`${classes.line} ${opened && classes.lineopened}`}
            >
                <div className={classes.le}>
                    <img 
                        onClick={() => lifeEventClickHandler(props.le.id)} 
                        className={classes.img} 
                        src={props.le.icon} 
                        alt={props.le.name}
                    />
                </div>
                <div className={classes.labels}>
                    <EFLabel style={{ fontSize: '1.6rem', marginLeft: '1rem' }}>
                        {props.le.name}
                    </EFLabel>
                </div>
                <span className={`${classes.icon} ${opened && classes.iconopened}`} />
            </div>
            <div className={`${classes.inner} ${opened && classes.inneropened}`}>
                <div className={`${classes.content} ${opened && classes.contentopened}`}>
                    <Stack tokens={{ childrenGap: '2rem' }} className={classes.hintContainer}>
                        {props.le.overview?.cashflowMapping?.map((e, i) => (
                            <>
                                {leHint(e)}
                                {i !== props.le?.overview?.cashflowMapping?.length - 1 ? 
                                    <div className={classes.divider} /> : 
                                    <></>
                                }
                            </>
                        ))}
                    </Stack>
                </div>
            </div>
        </div>
    )
}

export default LECard;
