import {
  SIGN_UP,
  VERIFY_AUTH_CODE,
  GET_PROFILE,
  ADD_LIFE_EVENT,
  UPDATE_LIFE_EVENT,
  DELETE_LIFE_EVENT,
  UPDATE_SMART_CASHFLOW,
  CLEAR_CANONICAL_PROFILE,
  UPDATE_CONNECTED_ACCOUNTS,
  REDIRECT_TO_MAIN_MENU,
  SET_SHOW_FASTLINK,
  SIGN_UP_ERROR,
  SET_REDIRECT,
  LOG_OUT,
  INIT_RESET_PASSWORD,
  VERIFY_RESET_PASSWORD_CODE,
  SET_NEW_PASSWORD,
  UPDATE_PERSONAL_INFO,
  SAVE_USER,
  DELETE_CONNECTED_ACCOUNT,
  ADD_NUDGET,
  GET_PREVIOUS_NUDGETS,
  ADD_DEPENDENT,
  GET_AVATARS
} from "../actions/Users";

const initialState = {
  showFastlink: false,
  showResetPasswordCode: false,
  resetPasswordToken: "",
  avatars: []
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PROFILE: {
      return {
        ...state,
      };
    }
    case SIGN_UP: {
      return {
        ...state,
      };
    }
    case VERIFY_AUTH_CODE: {
      const showFastlink = action.showFastlink;
      return {
        ...state,
        showFastlink: showFastlink,
      };
    }
    case CLEAR_CANONICAL_PROFILE: {
      return {
        ...state,
      };
    }
    case SET_SHOW_FASTLINK: {
      return {
        ...state,
        showFastlink: action.showFastlink,
      };
    }
    case INIT_RESET_PASSWORD: {
      return {
        ...state,
        showResetPasswordCode: action.showResetPasswordCode
      };
    }
    case VERIFY_RESET_PASSWORD_CODE: {
      return {
        ...state,
        resetPasswordToken: action.token,
        showResetPasswordCode: false
      };
    }
    case SET_NEW_PASSWORD: {
      return {
        ...state,
        resetPasswordToken: "",
      };
    }
    case UPDATE_PERSONAL_INFO: {
      return {
        ...state,
      };
    }
    case SAVE_USER: {
      return {
        ...state,
      };
    }
    case DELETE_CONNECTED_ACCOUNT: {
      return {
        ...state,
      };
    }
    case ADD_NUDGET: {
      return {
        ...state,
      };
    }
    case GET_PREVIOUS_NUDGETS: {
      return {
        ...state,
      };
    }
    case GET_AVATARS: {
      return {
        ...state,
        avatars: action.avatars
      }
    }
    case LOG_OUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default userReducer;
