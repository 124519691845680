import React from 'react';
import classes from './ChartCard.module.css';
import { Stack } from 'office-ui-fabric-react';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import MaskedLabelPair from '../../UI/MaskedLabelPair';
import animations from '../../../utils/animations.module.css';

interface IProps {
    title: string,
    subtitle: string,
    value: number,
    valueColor: string,
    img?: string,
    isClicked?: boolean,
    onClick?: () => void,
};

const ChartCard: React.FC<IProps> = props => {
    const title = {
        fontSize: props.isClicked ? 22 : 18,
        margin: '10px 0 5px 0',
        textAlign: 'center' as 'center',
    };
    const subtitle = {
        fontSize: props.isClicked ? 20 : 16,
        fontWeight: 'normal' as 'normal',
    };
    const highlight = {
        border: props.isClicked ? `0.78px solid ${props.valueColor}` : `none`
    };

    return (
        <Stack
            horizontalAlign='center' 
            className={`${classes.container} ${animations.carouselShadowExpand} ${props.isClicked && classes.container_clicked}`}
            style={highlight}
            onClick={props.onClick}
        >
            <EFLabel style={title}>{props.title}</EFLabel>
            <Stack className={classes.line} />
            <Stack horizontalAlign='center' tokens={{childrenGap: '0.50rem'}}>
                <EFLabel style={subtitle}>{props.subtitle}</EFLabel>
                <MaskedLabelPair
                    isBulleted={false}
                    includeThousandsSeparator
                    prefix='$'
                    suffix=''
                    title={''}
                    titleStyle={{color: props.valueColor, fontWeight: 'normal', padding: 'unset'}}
                    value={props.value}
                    color={props.valueColor}
                    valueFontSize={props.isClicked ? '2.2rem' : '2rem'}
                />
                <img 
                    className={`${classes.chart} ${props.isClicked && classes.chart_clicked}`} 
                    alt='chart' 
                    src={props.img || undefined} 
                />
            </Stack>
        </Stack>
    )
};

export default ChartCard;
