import { setShowFastlink, setRedirect } from "../actions/Users";
import { appendHeaders } from "../../utils/FetchHeaders";

export const GET_FASTLINK_CREDENTIALS = "GET_FASTLINK_CREDENTIALS";
export const SET_SHOW_MONETIZATION = "SET_SHOW_MONETIZATION";

const GET_FASTLINK_CREDENTIALS_ENDPOINT =
  process.env.REACT_APP_GET_FASTLINK_CREDENTIALS_ENDPOINT;

export const getFastlinkCredentials = (history: any, fromSignUp: boolean) => {
  return async (dispatch: any, getState: any) => {
    try {
      const token = getState().session.user.access_token;
      const response = await fetch(GET_FASTLINK_CREDENTIALS_ENDPOINT, {
        headers: { ...appendHeaders(), Authorization: "Bearer " + token },
      });

      if (response.ok) {
        const payload = await response.json();
        const data = payload.data;
        dispatch({
          type: GET_FASTLINK_CREDENTIALS,
          fastlinkURL: data.authUrl,
          token: data.token,
        });
        if (fromSignUp) {
          dispatch(setShowFastlink());
          dispatch(setRedirect(false));
          history.push("/signup");
        }

      } else {
        if (response.status === 401) {
          history.replace("/expired");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const setShowMonetization = (condition: boolean) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_SHOW_MONETIZATION,
      showMonetization: condition,
    });
  };
};

