import { useEffect } from 'react';

export const useScroll = (open: boolean = true) => {

    // Prevent components in the background to scroll.
    useEffect(() => {
        if (open) {
            document.body.style.overflowY = 'hidden';
        }
        return () => {document.body.style.overflowY = 'unset'}
    }, [open])
}
