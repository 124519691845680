import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import classes from './BlurAddMeContainer.module.css';
import EFLabel from '../../Atomic/EFLabel/EFLabel';

interface IProps {
    styleButton?: object,
    backgroundImg?: string,
    label?: string,
    onClick?: () => void,
};

const BlurAddMeContainer: React.FC<IProps> = props => {

    return (
        <>
            <Stack style={{backgroundImage: `url(${props.backgroundImg})`}} className={classes.backdrop} />
            <Stack 
                style={props.styleButton} 
                onClick={(e) => { e.stopPropagation(); props.onClick(); }} 
                className={classes.buttonContainer} 
                tokens={{childrenGap: 2}} 
                horizontalAlign='center'
            >
                <div style={{backgroundImage: `url(${require('../../../assets/images/new_job.svg')})`}} className={classes.button} />
                <EFLabel style={{color: '#9EA4B3', fontSize: '1.1rem', fontWeight: 'normal'}}>{props.label}</EFLabel>
            </Stack>
        </>
    )
};

export default BlurAddMeContainer;
