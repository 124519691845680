import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import close from '../../../assets/images/close.svg';
import EfButtonWhite from '../../CareerPlanning/EfButtonWhite';
import EFButton from '../../Atomic/EFButton/EFButton';
import addPlanOrange from '../../../assets/images/addPlanOrange.svg';
import bigCheckmark from '../../../assets/images/bigCheckmark.svg'
import classes from './PlanTransferPrompt.module.css';

interface Props {
    onConfirmClick: () => void;
    onRedirectToProfileClick: () => void;
    onClose?: () => void;
}

const PlanTransferPrompt: React.FC<Props> = props => {

    const confirmedPrompt = useSelector((state: any) => state.screens.transferPromptShowConfirmed);
    const user = useSelector((state: any) => state.session.user);

    return (
        <div className={classes.container}>
            <div className={classes.closeContainer}>
                <img onClick={props.onClose} src={close} alt="close" style={{ width: '2.5rem', cursor: 'pointer' }} />
            </div>
            <div className={classes.addPlanContainer}>
                <img src={confirmedPrompt ? bigCheckmark : addPlanOrange} alt="add" />
            </div>
            <div className={classes.textContainer}>
                {confirmedPrompt ?
                    <>
                        <p className={classes.smallCardContent}>THE TRANSFER HAS BEEN COMPLETED!</p>
                        <p className={classes.smallCardContent}>Do you wish to continue on {user.name}'s profile</p>
                        <p className={classes.smallCardContent}>or go to your Life Event?</p>
                    </> :
                    <>
                        <p className={classes.smallCardContent}>Are you sure you want to</p>
                        <p className={classes.smallCardContent}>transfer this data to your</p>
                        <p className={classes.smallCardContent}>Profile?</p>
                    </>}

            </div>
            <div className={classes.buttonsContainer}>
                <EfButtonWhite size='1.4rem' width='30%' text={confirmedPrompt ? "STAY HERE" : "CANCEL" } click={props.onClose} />
                <EFButton onClick={(event) => {
                    event.stopPropagation();
                    if (confirmedPrompt) {
                        props.onRedirectToProfileClick();
                    }
                    else {
                        props.onConfirmClick();
                    }
                }} text={confirmedPrompt ? "GO TO MY PROFILE" : "CONFIRM"} />
            </div>
        </div>
    )
};

export default PlanTransferPrompt;