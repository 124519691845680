import React from 'react';
import NavButton from '../../UI/NavButton';

interface IProps {
    text: string,
    isFocused: boolean,
    onClick: () => void,
};

const TimeButton: React.FC<IProps> = props => {
    return (
        <NavButton 
            text={props.text}
            style={style} 
            focusStyle={focusStyle} 
            offFocusStyle={offFocusStyle} 
            isFocused={props.isFocused} 
            onClick={props.onClick} 
        />

    );
};

const style = {
    fontSize: '1.2rem', 
    border: 0, 
    height: '2rem'
};
const focusStyle = { 
    backgroundColor: '#9EA4B3' 
};
const offFocusStyle = {
    backgroundColor: 'transparent', 
    color: '#9EA4B3'
};

export default TimeButton;
