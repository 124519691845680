import * as React from 'react';
import { FontIcon, Callout, DirectionalHint, Calendar, DayOfWeek, FocusTrapZone } from 'office-ui-fabric-react';
import { useBoolean } from '@uifabric/react-hooks';
import moment from 'moment';
import EFLabel from '../../EFLabel/EFLabel';

import './FabricCalendar.css'
import classes from './FabricCalendar.module.css';

const DayPickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  goToToday: 'Go to today',
  weekNumberFormatString: 'Week number {0}',
  prevMonthAriaLabel: 'Previous month',
  nextMonthAriaLabel: 'Next month',
  prevYearAriaLabel: 'Previous year',
  nextYearAriaLabel: 'Next year',
  prevYearRangeAriaLabel: 'Previous year range',
  nextYearRangeAriaLabel: 'Next year range',
  closeButtonAriaLabel: 'Close',
  monthPickerHeaderAriaLabel: '{0}, select to change the year',
  yearPickerHeaderAriaLabel: '{0}, select to change the month',
};

export interface ICalendarButtonExampleProps {
  selectedDate: Date | number;
  onSelectDate: (date: Date, selectedDateRangeArray?: Date[]) => void;
  minDate?: Date;
  maxDate?: Date;
  isDayPickerVisible?: boolean;
  isMonthPickerVisible?: boolean;
  highlightCurrentMonth?: boolean;
  highlightSelectedMonth?: boolean;
  buttonString?: string;
  showMonthPickerAsOverlay?: boolean;
  showGoToToday?: boolean;
  placeholder?: string;
  strFormat?: string;
  labelStyle?: object;
}

const FabricCalendar: React.FunctionComponent<ICalendarButtonExampleProps> = (props) => {
  const selectDate = moment(props.selectedDate).toDate();
  const minDate = props.minDate ? 
    moment(props.minDate).toDate() : moment().subtract(30, "years").toDate();
  const maxDate = props.maxDate ? moment(props.maxDate).toDate() : moment().add(30, "years").toDate();

  const [calendarButtonElement, setCalendarButton] = React.useState<HTMLDivElement>();
  const [showCalendar, { toggle: toggleShowCalendar }] = useBoolean(false);

  const {
    showMonthPickerAsOverlay = false,
    showGoToToday = true,
    highlightCurrentMonth,
    highlightSelectedMonth,
    isDayPickerVisible,
  } = props;
  const onSelectDate = (date: Date): void => {
    props.onSelectDate(date);
    toggleShowCalendar();
  };

  return (
    <div>
      <div 
        className={classes.label}
        ref={calendarBtn => setCalendarButton(calendarBtn!)}
        onClick={toggleShowCalendar}
      >
        <FontIcon className={classes.calendar} iconName='EFCalendar' />
        <EFLabel style={{ fontSize: '1.4rem', color: '#979797', fontWeight: 'none', ...props.labelStyle }}>
          {!props.selectedDate ? props.placeholder : 
            props.strFormat ? moment(selectDate).format(props.strFormat) : moment(selectDate).format("MMM YYYY")}
        </EFLabel>
      </div>
      {showCalendar && (
        <Callout
          styles={calloutStyle}
          isBeakVisible={false}
          className="ms-DatePicker-callout"
          gapSpace={0}
          doNotLayer={false}
          target={calendarButtonElement}
          directionalHint={DirectionalHint.bottomLeftEdge}
          onDismiss={toggleShowCalendar}
          setInitialFocus
        >
          <FocusTrapZone firstFocusableSelector="ms-DatePicker-day--today" isClickableOutsideFocusTrap>
            <Calendar
              onSelectDate={onSelectDate}
              minDate={minDate}
              maxDate={maxDate}
              onDismiss={toggleShowCalendar}
              isMonthPickerVisible={props.isMonthPickerVisible}
              value={selectDate!}
              firstDayOfWeek={DayOfWeek.Sunday}
              strings={DayPickerStrings}
              isDayPickerVisible={isDayPickerVisible}
              highlightCurrentMonth={highlightCurrentMonth}
              highlightSelectedMonth={highlightSelectedMonth}
              showMonthPickerAsOverlay={showMonthPickerAsOverlay}
              showGoToToday={showGoToToday}
            />
          </FocusTrapZone>
        </Callout>
      )}
    </div>
  );
};

const calloutStyle = { 
  root: { borderRadius: '2rem' }, 
  calloutMain: { 
    borderRadius: '2rem', 
    background: 'linear-gradient(to bottom, #FEB81E 17%, white 0%)' 
  } 
};

export default FabricCalendar;
