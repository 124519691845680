import React, { useState } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFStepperInput from '../../UI/EFStepperInput/EFStepperInput';
import CalculatorSetup from '../../UI/CustomModalCard/CustomModalCard';
import EFLabel from '../../Atomic/EFLabel/EFLabel';
import Modal from 'react-modal';
import Carousel from '@brainhubeu/react-carousel';
import EFButton from '../../Atomic/EFButton/EFButton';
import CompletedOnboardingCard from '../Common/CompletedOnboardingCard/CompletedOnboardingCard';
import { useResponsive } from '../../../hooks/useResponsive';

import '@brainhubeu/react-carousel/lib/style.css';
import managedebticon from '../../../assets/images/lifeEvents/money/manage_debt.svg';

interface IProps {
    handleModal?: any,
    setDebtAmount?: (val: number) => void,
    setPayOffTermInMonths?: (val: number) => void,
    setInterest?: (val: number) => void,
};

const ManageDebtOnboarding: React.FC<IProps> = props => {
    const { screenIsAtLeast } = useResponsive();
    const title = 'MANAGE DEBT'
    const defaultDebtAmount = 2231;
    const defaultPayOffTermInMonths = 19;
    const defaultInterest = 18;

    const [modalIsOpen, setModalIsOpen] = useState(props.handleModal.showModal);
    const [debtAmount, setDebtAmount] = useState(0);
    const [debtErr, setDebtErr] = useState(false);
    const [payOffTermInMonths, setPayOffTermInMonths] = useState(0);
    const [payOffErr, setPayOffErr] = useState(false);
    const [interest, setInterest] = useState(0);
    const [finished, setFinished] = useState(false);
    
    const onCompleteCard1 = () => {
        let isFinished = true;
        if (debtAmount <= 0) {
            setDebtErr(true);
            isFinished = false;
        }
        if (payOffTermInMonths <= 0) {
            setPayOffErr(true);
            isFinished = false;
        }
        setFinished(isFinished);
    }

    const onSkipCard1 = () => {
        setDebtAmount(defaultDebtAmount);
        setPayOffTermInMonths(defaultPayOffTermInMonths);
        setInterest(defaultInterest);
        setFinished(true);
    }

    const onCompleteSetup = () => {
        props.setDebtAmount(debtAmount);
        props.setPayOffTermInMonths(payOffTermInMonths);
        props.setInterest(interest);
        setModalIsOpen(false);
    }

    const ErrLabel = (msg: string) => (
        <EFLabel style={{fontSize: '1.3rem', color: '#c4233e', fontWeight: 'normal'}}>{msg}</EFLabel>
    ); 

    return (
        <Modal 
            isOpen={modalIsOpen}
            onRequestClose={props.handleModal.exitModal}
            style={modalCardStyle}
        >
            {!finished && (
                <Carousel 
                    draggable={false}
                    centered
                    slidesPerPage={screenIsAtLeast('tabPort') ? 1.8 : 1}
                >
                    <div>
                        <CalculatorSetup 
                            title={title} 
                            eventIcon={managedebticon} 
                            onClickExit={props.handleModal.exitModal}
                        >
                            <Stack horizontalAlign='center' tokens={{childrenGap: '2rem'}}>
                                <Stack tokens={{childrenGap: 5}}>
                                    <EFStepperInput
                                        label='Debt Balance'
                                        onChange={(val: number) => { setDebtAmount(val); setDebtErr(false); }}
                                        value={debtAmount}
                                        prefix='$'
                                        includeThousandsSeparator
                                        dynamicStepEnabled
                                        minVal={0}
                                        maxVal={100000}
                                        displayErrStyle={debtErr}
                                    />
                                    {debtErr && ErrLabel('Please enter an amount')}
                                </Stack>
                                <Stack tokens={{childrenGap: 5}}>
                                    <EFStepperInput
                                        label='Loan Term (Months)'
                                        onChange={(val: number) => { setPayOffTermInMonths(val); setPayOffErr(false); }}
                                        value={payOffTermInMonths}
                                        minVal={1}
                                        maxVal={1000}
                                        displayErrStyle={payOffErr}
                                    />
                                    {payOffErr && ErrLabel('Please enter an amount')}
                                </Stack>
                                <EFStepperInput
                                    label='Interest Rate'
                                    onChange={(val: number) => setInterest(val)}
                                    value={interest}
                                    allowDecimal
                                    numStepper={.25}
                                    suffix='%'
                                    minVal={0}
                                    maxVal={100}
                                />                                
                                <Stack style={{marginTop: '4rem'}} horizontalAlign='center' tokens={{childrenGap: '1.5rem'}}>
                                    <EFButton style={{width: '16rem'}} text={`DONE!`} onClick={onCompleteCard1} />
                                    <EFLabel onClick={onSkipCard1} style={{fontWeight: 'normal', textDecoration: 'underline', cursor: 'pointer'}}>
                                        Figure this out later
                                    </EFLabel>
                                </Stack>
                            </Stack>
                        </CalculatorSetup>
                    </div>
                </Carousel>
            )}
            {finished && <CompletedOnboardingCard title={title} eventIcon={managedebticon} onClick={onCompleteSetup} onClickExit={props.handleModal.exitModal} />}
        </Modal>
    )
};

const modalCardStyle = {
    content: {
        justifyContent: "center" as "center",
        alignItems: "middle" as "middle",
        borderRadius: 10,
        borderStyle: 'none' as 'none',
        overflow: 'visible',
        height: '70rem',
        width: '100%',
        background: 'none' as 'none',
        left: 'auto',
        right: 'auto',
        padding: 0,
        marginTop: '2rem',
    },
    overlay: {
        display: 'flex' as 'flex',
        justifyContent: 'center' as 'center',
        zIndex: 20,
        overflowY: 'auto' as 'auto',
        background: 'rgba(248, 250, 255, 0.50)',
        backdropFilter: 'blur(40px)',
        WebkitBackdropFilter: 'blur(40px)'
    }
}

export default ManageDebtOnboarding;
