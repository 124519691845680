/* eslint-disable @typescript-eslint/no-array-constructor */
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as calculatorActions from '../../store/actions/Calculator';
import { IChoiceGroupOption } from 'office-ui-fabric-react';
import { getCustomData } from '../../utils/LifeEventTools/customDataGenerator';
const { GET_COLLEGE_COST, COLLEGE_PLANNING } = require('ef-calculators');

interface collegeCostCalculatorResponse {
    collegeType: string,
    tuition: number,
    room: number,
    transportation: number,
    entertainment: number,
    medical: number,
    other: number,
};

interface collegePlanCalculatorResponse {
    totalCost: number; //total cost (all years, in future value)
    amountSaved: number; // the amount of $ saved before enrollment and during attendance
    pctCovered: number; // how near are we to reach the savings vs total cost goal
    amountCovered: number; // amount covered in $
    totalGrants: number; // total amount covered by grants and scholarships
    totalSideHustle: number; // total amount covered by side hustle or study work
    totalLoan: number; // amount covered by federal loan
    privateLoan: number; //total amount covered by private loan
    privateLoanBalance: number; // private loan balance at the end
    surplus: number; // amount of $ exceeding the goal
    shortfall: number; // if the goal is not met, how much $ is required
    schedule: {
      year: number; //i.e. 2054
      covered: boolean; //true | false
      needed: number; //future value cost for that year
      grants: number; //how much $ we covered with scholarships and grants
      sideHustle: number; //amount of $ used from the side hustle
      loan: number; //how much $ we covered with the federal loan
      spent: number; //how much money is consumed from our savings that year
      balance: number; //how much money is available in our savings
      unfunded: number; //if we ran out of funds, for how much $
      privateLoanBalance: number; //the balance of the private loan
      privateLoanSpent: number; //how much money is consumed from the private loan that year
    }[];
  };

export const useCollegeSavings = (uniqueEventID?: string) => {
    const dispatch = useDispatch();
    const loggedUserLifeEventData = useSelector((state: any) => {
        if (state.session.user) {
            return state.session.user.lifeEvents.find((le: any) => le.id === uniqueEventID)
        }
        else {
            return null;
        }
      }
    );

    const defaultData = getCustomData('College Plan');
    const collegeTypeInit = loggedUserLifeEventData ? loggedUserLifeEventData.customData.schoolType : defaultData.schoolType;

    /* #region State Inputs */
    //COLLEGE COST
    const [collegeType, setCollegeType] = useState(collegeTypeInit === '' ? 'PUBLIC_4' : collegeTypeInit);
    const [tuition, setTuition] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.tuitionCost : defaultData.tuitionCost);
    const [room, setRoom] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.roomAndBoardCost : defaultData.roomAndBoardCost);
    const [transportation, setTransportation] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.transportationCost : defaultData.transportationCost);
    const [entertainment, setEntertainment] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.entertainmentCost : defaultData.entertainmentCost);
    const [medical, setMedical] = useState(0); // Backend Missing !
    const [other, setOther] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.otherCost : defaultData.otherCost);
    //COLLEGE SAVINGS
    const [yearsToEnrollment, setyearsToEnrollment] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.yearsToEnrollment : defaultData.yearsToEnrollment);
    const [yearsAttending, setyearsAttending] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.yearsAttending : defaultData.yearsAttending);
    const [savings, setsavings] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.currentSavings : defaultData.currentSavings); // "College Savings so Far"
    const [otherCost, setotherCost] = useState(room + transportation + entertainment + medical + other);
    const [monthlyContribution, setmonthlyContribution] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.monthlyContribution : defaultData.monthlyContribution); // "Desired Monthly Contribution"
    const [sideHustle, setsideHustle] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  && loggedUserLifeEventData.customData.sideHustleWork ? loggedUserLifeEventData.customData.sideHustleWork : defaultData.sideHustleWork);
    const [grantScholarShipPct, setgrantScholarShipPct] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.grantsScholarship : defaultData.grantsScholarship); // "Grants & Scholarship Financial AID"
    const [federalLoanAmountLimit, setfederalLoanAmountLimit] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.studentLoanFunding : defaultData.studentLoanFunding);
    const [privateLoan, setPrivateLoan] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  && loggedUserLifeEventData.customData.privateStudentLoanFunding ? loggedUserLifeEventData.customData.privateStudentLoanFunding : defaultData.privateStudentLoanFunding);
    const [annualReturnRate, setannualReturnRate] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.annualReturnRate : defaultData.annualReturnRate); // "Annual Return Rate"
    const [inflationRateTuition, setinflationRateTuition] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.tuitionInflationRate : defaultData.tuitionInflationRate); // "Tuition Inflation Rate"
    const [inflationRateOther, setinflationRateOther] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.otherCostInflationRate : defaultData.otherCostInflationRate); // "Other Costs Inflation Rate"
    const [contributionsGrowWithInflation, setcontributionsGrowWithInflation] = useState(loggedUserLifeEventData && Object.keys(loggedUserLifeEventData.customData).length > 0  ? loggedUserLifeEventData.customData.contributionGrowWithInflation : defaultData.contributionGrowWithInflation);
    /* #endregion */
    /* #region State Outputs */
    //COLLEGE SAVINGS
    const [totalCost, settotalCost] = useState(0);
    const [amountSaved, setamountSaved] = useState(0);
    const [pctCovered, setpctCovered] = useState(0);
    const [amountCovered, setamountCovered] = useState(0);
    const [totalGrants, settotalGrants] = useState(0);
    const [totalSideHustle, settotalSideHustle] = useState(0);
    const [totalLoan, settotalLoan] = useState(0);
    const [privateLoanSpent, setPrivateLoandSpent] = useState(0);
    const [surplus, setsurplus] = useState(0);
    const [shortfall, setshortfall] = useState(0);
    const [schedule, setschedule] = useState(Array());
    /* #endregion */

    const handleCollegeType = useCallback((e: any, option?: IChoiceGroupOption) => {
        const collegeCost = () => {
            const s: 
            | collegeCostCalculatorResponse
            | string = GET_COLLEGE_COST(option?.key || '');
            if (typeof s != 'string') {
                setCollegeType(s.collegeType);
                setTuition(s.tuition);
                setRoom(s.room);
                setTransportation(s.transportation);
                setEntertainment(s.entertainment);
                setMedical(s.medical);
                setOther(s.other);
            }
        }
        collegeCost();
    }, []);

    useEffect(() => {
        /* #region Calc Responses */
            const studentLoan = () => {
                const s:
                | collegePlanCalculatorResponse
                | string = COLLEGE_PLANNING(
                    yearsToEnrollment,
                    yearsAttending,
                    savings,
                    tuition,
                    otherCost,
                    monthlyContribution,
                    sideHustle,
                    grantScholarShipPct,
                    federalLoanAmountLimit,
                    annualReturnRate,
                    inflationRateTuition,
                    inflationRateOther,
                    contributionsGrowWithInflation,
                    privateLoan
                );
                if (typeof s != 'string') {
                settotalCost(s.totalCost);
                setamountSaved(s.amountSaved);
                setpctCovered(s.pctCovered);
                setamountCovered(s.amountCovered);
                settotalGrants(s.totalGrants);
                settotalSideHustle(s.totalSideHustle);
                settotalLoan(s.totalLoan);
                setPrivateLoandSpent(s.privateLoan);
                setsurplus(s.surplus);
                setshortfall(s.shortfall);
                setschedule(s.schedule);
                }
            };
        /* #endregion */
        studentLoan();
        dispatch(calculatorActions.collegePlanChangeState({
            roomAndBoard: true,
            tuitionInflationRate: inflationRateTuition,
            roomAndBoardCost: room,
            yearsAttending: yearsAttending,
            otherCostInflationRate: inflationRateOther,
            currentSavings: savings,
            schoolType: collegeType,
            privateStudentLoanFunding: privateLoan,
            studentLoanFunding: federalLoanAmountLimit,
            otherCost: other,
            tuitionCost: tuition,
            entertainmentCost: entertainment,
            transportationCost: transportation,
            annualReturnRate: annualReturnRate,
            contributionGrowWithInflation: contributionsGrowWithInflation,
            yearsToEnrollment: yearsToEnrollment,
            monthlyContribution: monthlyContribution,
            grantsScholarship: grantScholarShipPct,
            sideHustleWork: sideHustle,
        }));
    }, [
        /* #region Dependencies */
        //COLLEGE COSTS
        collegeType,
        tuition,
        other,
        //COLLEGE SAVINGS
        annualReturnRate, 
        contributionsGrowWithInflation, 
        federalLoanAmountLimit, 
        grantScholarShipPct, 
        inflationRateOther, 
        inflationRateTuition, 
        monthlyContribution, 
        otherCost, 
        privateLoan, 
        savings, 
        sideHustle, 
        yearsAttending, 
        yearsToEnrollment,
        entertainment,
        room,
        transportation,
        dispatch
        /* #endregion */
    ]);

    return {
        collegeType, handleCollegeType,
        yearsToEnrollment, setyearsToEnrollment,
        yearsAttending, setyearsAttending,
        savings, setsavings, 
        otherCost, setotherCost,
        monthlyContribution, setmonthlyContribution,
        sideHustle, setsideHustle,
        grantScholarShipPct, setgrantScholarShipPct,
        federalLoanAmountLimit, setfederalLoanAmountLimit,
        privateLoan, setPrivateLoan,
        annualReturnRate, setannualReturnRate,
        inflationRateTuition, setinflationRateTuition,
        inflationRateOther, setinflationRateOther,
        contributionsGrowWithInflation, setcontributionsGrowWithInflation,
        tuition, setTuition,
        room, setRoom,
        transportation, setTransportation,
        entertainment, setEntertainment,
        medical, setMedical,
        other, setOther,
        totalCost, settotalCost,
        amountSaved, setamountSaved,
        pctCovered, setpctCovered,
        amountCovered, setamountCovered,
        totalGrants, settotalGrants,
        totalSideHustle, settotalSideHustle,
        totalLoan, settotalLoan,
        privateLoanSpent, setPrivateLoandSpent,
        surplus, setsurplus,
        shortfall, setshortfall,
        schedule, setschedule,
    };
};
