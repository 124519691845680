import React, { useState } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../Atomic/EFLabel/EFLabel';
import CollapsableMenu from './CollapsableMenu/CollapsableMenu';
import { useResponsive } from '../../hooks/useResponsive';
import { useSelector } from 'react-redux';

import maleAvatar from '../../assets/images/maleAvatar.svg';
import staceyAvatar from "../../assets/images/staceyAvatar.png";
import rosaAvatar from "../../assets/images/rosaAvatar.png";
import darylAvatar from "../../assets/images/darylAvatar.png";
import martinAvatar from "../../assets/images/martinAvatar.svg";
import eveAvatar from "../../assets/images/eveAvatar.svg";

import davidAvatar from '../../assets/images/davidAvatar.svg';
import helenAvatar from '../../assets/images/helenAvatar.svg';
import owlyAvatar from '../../assets/images/owlyAvatar.svg';
import octoAvatar from '../../assets/images/octoAvatar.svg';

import { isCanonical } from '../../utils/global';
import SwitchLabel from './SwitchLabel/SwitchLabel';


interface IProps {
    style?: object,
    dateStyle?: object,
    greeting?: string,
    emoji?: string,
    //CollapsableMenu Props
    connectedAccounts?: number,
    connectedKey?: string,
    onConnectedLinkClick?: (e: any, item: any) => void,
    onConnectButtonClick?: () => void,
    onProtectButtonClick?: () => void,
    onGrowButtonClick?: () => void,
    onConnectAccountClick: () => void,
    profile: boolean;
    showLabelSubtext: boolean;
    backToProfileHandler: () => void;
    chooseOtherProfileHandler: () => void;
    hideSalutation?: boolean;
    onUserAvatarClick?: () => void;
    showOnlySalutation?: boolean;
    hideName?: boolean;
    hideMenuLogo?: boolean;
    onSelectBubleClickHandler?: () => void;
};

const Greeting: React.FC<IProps> = props => {

    const user = useSelector((state: any) => state.session.user);
    const realUser = useSelector((state: any) => state.data.realUser);
    const { screenIsAtMost } = useResponsive();
    const d = new Date();

    const getAvatar = (name: string) => {
        let avatar;

        switch (name) {
            case 'Stacey': {
                avatar = staceyAvatar;
                break;
            }
            case 'Rosa': {
                avatar = rosaAvatar;
                break;
            }
            case 'Daryl': {
                avatar = darylAvatar;
                break;
            }
            case 'Martin': {
                avatar = martinAvatar;
                break;
            }
            case 'Eve': {
                avatar = eveAvatar;
                break;
            }
        }

        return avatar;
    }

    const avatarsR = [{
        id: "3Mxv3XhNNVaQupOzfYBWEN",
        image: davidAvatar,
        name: "David"
    }, {
        id: "4xYF66mqSnuVym8EDvHMRu",
        image: helenAvatar,
        name: "Helen"
    }, {
        id: "6F15h0gu6bK03QdpOaelFo",
        image: owlyAvatar,
        name: 'Owly'
    }, {
        id: "6KYAQizPddYd9XlDrrCmsE",
        image: octoAvatar,
        name: 'Octo'
    }];

    const greeting = (hours: number) => {
        if (hours < 12 && hours > 4) {
            return 'Good Morning ';
        } else if (hours >= 12 && hours < 18) {
            return 'Good Afternoon ';
        } else {
            return 'Good Evening ';
        }
    }
    const monthAsStr = (i: number) => {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][i];
    }
    const oSuffix = (n: number) => {
        const n0 = n % 10, n1 = n % 100;
        if (n0 === 1 && n1 !== 11) return (n + 'st');
        if (n0 === 2 && n1 !== 12) return (n + 'nd');
        if (n0 === 3 && n1 !== 13) return (n + 'rd');
        return (n + 'th');
    };
    const dateWelcome = `Today is ${monthAsStr(d.getMonth())} ${oSuffix(d.getDate())}, ${d.getFullYear()}`;

    let header = (
        <>
            {!props.showOnlySalutation && <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <img alt='avatar' src={realUser && realUser.picture ? realUser.picture.image : maleAvatar} style={{ width: '6rem', height: '6rem', cursor: 'pointer' }} onClick={props.onUserAvatarClick} />
                {!props.hideName && <p style={{
                    fontFamily: 'brandon-grotesque',
                    fontSize: '2rem',
                    color: '#293861',
                    fontWeight: 'bold',
                    paddingTop: '0.5rem'
                }}>{user.name.split(" ")[0]}</p>}
            </div>}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'left',
                flexDirection: 'column',
                paddingLeft: '1rem'
            }}>
                {!props.hideSalutation && <EFLabel style={{ fontSize: '3.5rem', marginBottom: '1rem', lineHeight: 1 }}>
                    {isCanonical(user.name) ? user.name + "'s Dashboard" : greeting(d.getHours()) + user.name.split(" ")[0]}
                </EFLabel>}
                {!props.hideSalutation && <Stack horizontal tokens={{ childrenGap: 5 }} style={{ marginLeft: '.5rem', ...props.dateStyle }}>
                    <EFLabel style={{ fontWeight: 'normal' as 'normal', fontSize: '2rem' }}>{dateWelcome}</EFLabel>
                </Stack>}
            </div>
        </>
    );

    if (isCanonical(user.name) && !props.showOnlySalutation) {
        header = (
            <SwitchLabel img={getAvatar(user.name)} name={user.name}
                showSubtext={!props.showLabelSubtext}
                goBackToProfile={props.backToProfileHandler}
                chooseOtherProfile={props.chooseOtherProfileHandler} />
        );
    }

    return (
        <div style={{display: screenIsAtMost("tabLand") ? 'flex' : '' }}>
            {screenIsAtMost("tabLand") &&
                <CollapsableMenu
                    hideLogo={props.hideMenuLogo}
                    connectedAccounts={props.connectedAccounts}
                    connectedKey={props.connectedKey}
                    onConnectedLinkClick={props.onConnectedLinkClick}
                    onConnectButtonClick={props.onConnectButtonClick}
                    onProtectButtonClick={props.onProtectButtonClick}
                    onGrowButtonClick={props.onGrowButtonClick}
                    profile={props.profile}
                    onConnectAccountClick={props.onConnectAccountClick}
                    onSelectBubleClickHandler={props.onSelectBubleClickHandler}
                />}
            <Stack style={{ ...props.style }} horizontal>
                {header}
            </Stack>
        </div>
    )
};

Greeting.defaultProps = {
    greeting: 'Good Morning!',
    emoji: '🤓',
};

export default Greeting;
