import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EFTextField from "../../components/Atomic/EFTextField/EFTextField";
import EFButton from "../../components/Atomic/EFButton/EFButton";
import EFHeader from "../../components/UI/EFHeader/EFHeader";
import EFValidationCheck from "../../components/Atomic/EFValidationCheck/EFValidationCheck";
import { Label } from "office-ui-fabric-react";
import { Card } from "@uifabric/react-cards";
import Lock from "../../assets/icons/lock.png";
import Fastlink from "../../components/UI/Fastlink/Fastlink";
import { useResponsive } from "../../hooks/useResponsive";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import SettingsModal from "../../components/UserSettings/Settings/SettingsOptionsValue/SettingsModal";
import { entries, getEntity } from "../../contentful/contenful";
import { MARKS } from "@contentful/rich-text-types";
import { useBoolean } from "@uifabric/react-hooks";
import "react-phone-number-input/style.css";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/Users";
import * as aggregationActions from "../../store/actions/Aggregation";
import * as screenActions from "../../store/actions/Screens";

import checkmark from "../../assets/images/scoop/checkmark.svg";
import classes from "./SignUpPage.module.css";
import "./PhoneNumber.css";

import davidAvatar from "../../assets/images/davidAvatar.svg";
import helenAvatar from "../../assets/images/helenAvatar.svg";
import owlyAvatar from "../../assets/images/owlyAvatar.svg";
import octoAvatar from "../../assets/images/octoAvatar.svg";
import { showSignUpAvatars } from "../../store/actions/Screens";
import VerificationInput from "../../components/UI/VerificationInput/VerificationInput";
import TagManager from 'react-gtm-module'

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => {
      const boldWords = ["info@equalfuture.com", "info@equalfuture.com."];
      return (text && text.props) || boldWords.includes(text) ? (
        <span>{text}</span>
      ) : (
        <b>{text}</b>
      );
    },
  },
};

const SignUpPage: React.FC<{}> = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  localStorage.setItem("fastlinkEnd", "false");
  const { screenIsAtMost } = useResponsive();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [termsContent, setTermsContent] = useState(null);
  const [showTerms, { toggle: toggleTerms }] = useBoolean(false);

  //beta-only
  const [contentNDA, setContentNDA] = useState(null);
  const [acceptedNDA, setAcceptedNDA] = useState(false);
  const [showNDA, setShowNDA] = useState(false);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmedPasswordError, setConfirmedPasswordError] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState({
    id: "",
    image: null,
    name: "",
  });
  const realUser = useSelector((state: any) => state.data.realUser);

  // Google analytics set up for page
  const tagManagerArgs = {
    dataLayer: {
      userId: realUser?.trackingId,  
      page: 'Sign Up'
    },
    dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs)

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const redirect = useSelector(
    (state: any) => state.screens.main_redirectToDashboard
  );
  const showFastlink = useSelector((state: any) => state.user.showFastlink);
  const showMonetization = useSelector(
    (state: any) => state.aggregation.showMonetization
  );
  const showAvatars = useSelector(
    (state: any) => state.screens.signUp_showAvatars
  );
  const signUpErrorState = useSelector(
    (state: any) => state.screens.signUp_signUpError
  );
  const showConfirmationCode = useSelector(
    (state: any) => state.screens.signUp_showConfirmationCode
  );
  const emailFromLogin = useSelector(
    (state: any) => state.screens.signUp_emailFromLogin
  );
  const avatars = useSelector((state: any) => state.user.avatars);

  const avatarsR = [
    {
      id: "3Mxv3XhNNVaQupOzfYBWEN",
      image: davidAvatar,
      name: "David",
    },
    {
      id: "4xYF66mqSnuVym8EDvHMRu",
      image: helenAvatar,
      name: "Helen",
    },
    {
      id: "6F15h0gu6bK03QdpOaelFo",
      image: owlyAvatar,
      name: "Owly",
    },
    {
      id: "6KYAQizPddYd9XlDrrCmsE",
      image: octoAvatar,
      name: "Octo",
    },
  ];

  const pickedAvatarStyle = {
    borderColor: "#feb81e",
    borderWidth: "3px",
    borderStyle: "solid",
    borderRadius: "60px",
    width: screenIsAtMost("mobile") ? "10rem" : "",
  };

  const validatePassword = (regex: string, password: string) => {
    return password.match(regex) ? true : false;
  };

  const validateFirstName = (event: any) => {
    setFirstNameError("");
    const firstName = (event.target as HTMLTextAreaElement).value;
    if (firstName === "") {
      setFirstNameError("Your first name cannot be empty");
    }
    setFirstName(firstName);
  };

  const validateLastName = (event: any) => {
    setLastNameError("");
    const lastName = (event.target as HTMLTextAreaElement).value;
    if (lastName === "") {
      setLastNameError("Your last name cannot be empty");
    }
    setLastName(lastName);
  };

  const validatePhone = () => {
    if (!phoneNumber) {
      setPhoneNumberError("Phone number cannot be empty");
    } else {
      if (isValidPhoneNumber(phoneNumber)) {
        setPhoneNumberError("");
      } else {
        setPhoneNumberError("Please enter a valid phone number");
      }
    }
  };

  const validateCompletePassword = (event: any) => {
    setPasswordError("");
    const password = (event.target as HTMLTextAreaElement).value;
    const isValid =
      validatePassword("[a-z]", password) &&
      validatePassword("[A-Z]", password) &&
      validatePassword('[!@#$%^&*(),.?":{}|<>-]', password) &&
      validatePassword("[0-9]", password);
    if (!isValid) {
      setPasswordError("Your password format is not valid");
    }
    setPassword(password);
  };

  const validateConfirmedPassword = (event: any) => {
    setConfirmedPasswordError("");
    const confirmed = (event.target as HTMLTextAreaElement).value;
    if (confirmed !== password) {
      setConfirmedPasswordError("The passwords don't match");
    }
    setConfirmedPassword(confirmed);
  };

  const validateEmail = (event: any) => {
    setEmailAddressError("");
    setEmailAddress((event.target as HTMLTextAreaElement).value);
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(
      (event.target as HTMLTextAreaElement).value.toLowerCase()
    );
    if (!isValid) {
      setEmailAddressError("Please enter a valid email");
    }
  };

  useEffect(() => {
    const enabled =
      firstNameError === "" &&
      lastNameError === "" &&
      phoneNumberError === "" &&
      emailAddressError === "" &&
      passwordError === "" &&
      confirmedPasswordError === "" &&
      firstName !== "" &&
      lastName !== "" &&
      phoneNumber !== "" &&
      emailAddress !== "" &&
      password !== "" &&
      confirmedPassword !== "" &&
      acceptedTerms;
    setIsButtonEnabled(enabled);
  }, [
    firstNameError,
    lastNameError,
    phoneNumberError,
    emailAddressError,
    passwordError,
    confirmedPasswordError,
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    password,
    confirmedPassword,
    signUpErrorState,
    acceptedTerms,
  ]);

  useEffect(() => {
    if (redirect) {
      history.push("/myplansetup");
    }
  }, [redirect, history]);

  useEffect(() => {
    getEntity({
      entryId: entries.termsOfUse,
      callback: setTermsContent,
      options,
    });
    getEntity({ entryId: entries.nda, callback: setContentNDA, options });
  }, []);

  return (
    <div
      className={`${classes.container} ${
        screenIsAtMost("tabPort") ? classes.containerMobile : null
      }`}
    >
      <EFHeader onNavToMain customStyle={{ display: "block" }} />
      <div className={classes.form}>
        {!showMonetization && (
          <>
            <p
              style={{
                color: "#293861",
                fontWeight: "bold",
                fontSize: "3.0rem",
                fontFamily: "brandon-grotesque",
                marginBottom: "1.8rem",
              }}
            >
              {showAvatars ? "Let's pick your Avatar" : "Create an Account"}
            </p>
            <p
              style={{
                color: "#293861",
                fontSize: "1.6rem",
                fontFamily: "brandon-grotesque",
                marginBottom: "4rem",
              }}
            >
              {showAvatars
                ? "Select the character you would like guiding you through your EqualFuture journey."
                : "Protected by bank-level security"}
            </p>
          </>
        )}
        {!showConfirmationCode && !showFastlink && !showMonetization && (
          <Card horizontal className={classes.card}>
            <Card.Section
              className={classes.formPadding}
              styles={{ root: { paddingTop: "2%", width: "100%" } }}
            >
              <Card.Section
                horizontal
                className={classes.mobileCardSectionLayout}
                styles={{
                  root: {
                    justifyContent: "space-around",
                    paddingLeft: "5%",
                    flexDirection: screenIsAtMost("mobile") ? "column" : "row",
                  },
                }}
              >
                <Card.Section
                  className={
                    screenIsAtMost("mobile")
                      ? classes.formInputWidthMobile
                      : classes.formInputWidth
                  }
                  styles={{ root: { alignContent: "center" } }}
                >
                  <Label className={classes.fieldLabel}>First Name</Label>
                  <EFTextField
                    id="firstnameSU"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => validateFirstName(e)}
                    errorMessage={firstNameError}
                    width={screenIsAtMost("mobile") ? "95%" : ""}
                  />
                </Card.Section>
                <Card.Section
                  className={
                    screenIsAtMost("mobile")
                      ? classes.formInputWidthMobile
                      : classes.formInputWidth
                  }
                  styles={{ root: { alignContent: "center" } }}
                >
                  <Label className={classes.fieldLabel}>Last Name</Label>
                  <EFTextField
                    id="lastnameSU"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => validateLastName(e)}
                    errorMessage={lastNameError}
                    width={screenIsAtMost("mobile") ? "95%" : ""}
                  />
                </Card.Section>
              </Card.Section>
              <Card.Section
                horizontal
                className={classes.mobileCardSectionLayout}
                styles={{
                  root: {
                    justifyContent: "space-around",
                    paddingLeft: "5%",
                    flexDirection: screenIsAtMost("mobile") ? "column" : "row",
                  },
                }}
              >
                <Card.Section
                  className={
                    screenIsAtMost("mobile")
                      ? classes.formInputWidthMobile
                      : classes.formInputWidth
                  }
                  styles={{ root: { alignContent: "center" } }}
                >
                  <Label className={classes.fieldLabel}>Phone Number</Label>
                  <PhoneInput
                    id="phoneNumberSU"
                    className={phoneNumberError ? "PhoneInputError" : null}
                    style={{ width: screenIsAtMost("mobile") ? "95%" : "80%" }}
                    placeholder="Phone Number"
                    defaultCountry="US"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e)}
                    onBlur={() => validatePhone()}
                  />
                  <div
                    style={{ display: phoneNumberError ? "block" : "none" }}
                    className={classes.errorPhoneNumber}
                  >
                    {phoneNumberError}
                  </div>
                </Card.Section>
                <Card.Section
                  className={
                    screenIsAtMost("mobile")
                      ? classes.formInputWidthMobile
                      : classes.formInputWidth
                  }
                  styles={{ root: { alignContent: "center" } }}
                >
                  <Label className={classes.fieldLabel}>Email Address</Label>
                  <EFTextField
                    id="emailSU"
                    type="text"
                    placeholder="Email Address"
                    value={emailAddress}
                    onChange={(e) => validateEmail(e)}
                    errorMessage={emailAddressError}
                    width={screenIsAtMost("mobile") ? "95%" : ""}
                  />
                </Card.Section>
              </Card.Section>
              <Card.Section
                horizontal
                className={classes.mobileCardSectionLayout}
                styles={{
                  root: {
                    justifyContent: "space-around",
                    paddingLeft: "5%",
                    marginBottom: "3rem",
                    flexDirection: screenIsAtMost("mobile") ? "column" : "row",
                  },
                }}
              >
                <Card.Section
                  className={
                    screenIsAtMost("mobile")
                      ? classes.formInputWidthMobile
                      : classes.formInputWidth
                  }
                  styles={{ root: { alignContent: "center" } }}
                >
                  <Label className={classes.fieldLabel}>Password</Label>
                  <EFTextField
                    id="passwordSU"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => validateCompletePassword(e)}
                    errorMessage={passwordError}
                    width={screenIsAtMost("mobile") ? "95%" : ""}
                  />
                </Card.Section>
                <Card.Section
                  className={
                    screenIsAtMost("mobile")
                      ? classes.formInputWidthMobile
                      : classes.formInputWidth
                  }
                  styles={{ root: { alignContent: "center" } }}
                >
                  <Label className={classes.fieldLabel}>Confirm Password</Label>
                  <EFTextField
                    id="passwordSU2"
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmedPassword}
                    onChange={(e) => validateConfirmedPassword(e)}
                    errorMessage={confirmedPasswordError}
                    width={screenIsAtMost("mobile") ? "95%" : ""}
                  />
                </Card.Section>
              </Card.Section>
              {signUpErrorState && (
                <p className={classes.errorMessage}>User already exits</p>
              )}
              <Card.Section
                styles={{ root: { paddingLeft: "5%", paddingBottom: "1rem" } }}
              >
                <EFValidationCheck
                  text="contains lowercase letter"
                  isValid={validatePassword("[a-z]", password)}
                />
                <EFValidationCheck
                  text="contains uppercase letter"
                  isValid={validatePassword("[A-Z]", password)}
                />
                <EFValidationCheck
                  text="contains a number"
                  isValid={validatePassword("[0-9]", password)}
                />
                <EFValidationCheck
                  text="contains special character"
                  isValid={validatePassword(
                    '[!@#$%^&*(),.?":{}|<>-]',
                    password
                  )}
                />
              </Card.Section>
              <Card.Section
                styles={{
                  root: {
                    margin: "3rem 0",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  },
                }}
              >
                <div
                  onClick={() => setAcceptedTerms(!acceptedTerms)}
                  className={`${classes.checkbox} ${
                    acceptedTerms && classes.checked
                  }`}
                >
                  <img
                    className={classes.checkmark}
                    src={checkmark}
                    alt="checkmark"
                  />
                </div>
                <p onClick={toggleTerms} className={classes.fieldLabel}>
                  Accept <b>Terms and Conditions</b>
                </p>
                {showTerms && (
                  <SettingsModal
                    Content={termsContent}
                    handleModal={toggleTerms}
                    onClickAccept={() => {
                      setAcceptedTerms(true);
                      toggleTerms();
                    }}
                  />
                )}
              </Card.Section>

              {false && (
                <Card.Section
                  styles={{
                    root: {
                      margin: "3rem 0",
                      alignContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                    },
                  }}
                >
                  <div
                    onClick={() => setAcceptedNDA(!acceptedNDA)}
                    className={`${classes.checkbox} ${
                      acceptedNDA && classes.checked
                    }`}
                  >
                    <img
                      className={classes.checkmark}
                      src={checkmark}
                      alt="checkmark"
                    />
                  </div>
                  <p
                    onClick={() => setShowNDA(true)}
                    className={classes.fieldLabel}
                  >
                    Accept <b>NDA</b>
                  </p>
                  {showNDA && (
                    <SettingsModal
                      Content={contentNDA}
                      handleModal={() => {
                        setShowNDA(false);
                      }}
                      onClickAccept={() => {
                        setAcceptedNDA(true);
                        setShowNDA(false);
                      }}
                    />
                  )}
                </Card.Section>
              )}
            </Card.Section>
          </Card>
        )}
        {showConfirmationCode &&
          !showFastlink &&
          !showMonetization &&
          !showAvatars && (
            <VerificationInput
              showVerificationInput
              showButton={false}
              validateEmailFlow
              forgotPasswordFlow
              validateEmail={actions.validateEmail}
              signUpErrorState={signUpErrorState}
              signUpError={screenActions.signUpError}
              email={emailAddress}
              loggedUserfromSignupEmail={emailFromLogin}
            />
          )}
        {showAvatars && (
          <div
            className={
              screenIsAtMost("mobile")
                ? classes.avatarContainerMobile
                : classes.avatarContainer
            }
          >
            {avatarsR.map((avatar) => {
              return (
                <div
                  key={avatar.id}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedAvatar(avatar);
                  }}
                >
                  <img
                    src={avatar.image}
                    alt={avatar.name}
                    style={
                      Object.keys(selectedAvatar).length > 0 &&
                      selectedAvatar.name === avatar.name
                        ? pickedAvatarStyle
                        : { width: screenIsAtMost("mobile") ? "10rem" : "" }
                    }
                  />
                  <p
                    style={{
                      fontFamily: "brandon-grotesque",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      lineHeight: "1.8rem",
                      textAlign: "center",
                      color: "#293861",
                      paddingTop: "1rem",
                    }}
                  >
                    {avatar.name}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {showAvatars && (
          <EFButton
            id="nextButtonSU"
            isDisabled={!(Object.keys(selectedAvatar).length > 0)}
            text="NEXT"
            onClick={() => {
              dispatch(
                actions.updateProfile({ avatar: selectedAvatar.id }, history)
              );
              dispatch(showSignUpAvatars(false));
              dispatch(aggregationActions.setShowMonetization(true));
            }}
          />
        )}
        {showFastlink && (
          <Fastlink
            customStyle={{
              paddingBottom: screenIsAtMost("mobile") ? "5rem" : "",
            }}
          />
        )}
        {showMonetization && !showFastlink && (
          <>
            <p className={classes.monetizationTitle}>Help us help you!</p>
            <Card horizontal className={classes.monetizationSignAndProfileCard}>
              <div className={classes.monetizationSignAndProfileCardBlur}>
                <p
                  className={classes.monetizationHeaderStyle}
                  style={{ marginTop: "3rem" }}
                >
                  Get Connected
                </p>
                <p className={classes.monetizationHeaderStyle}>Get Organized</p>
                <p className={classes.monetizationHeaderStyle}>Get Learning</p>
                <p
                  className={classes.monetizationHeaderStyle}
                  style={{
                    marginTop: "10rem",
                    fontWeight: "bold",
                    marginBottom: "4.6rem",
                  }}
                >
                  Let's connect your financial accounts
                </p>
                <div className={classes.monetizationCardContainers}>
                  <Card
                    horizontal
                    className={classes.monetizationSmallCard}
                    onClick={() => {
                      history.push("/signup");
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 0,
                        marginTop: "4.8rem",
                      }}
                    >
                      <Card
                        className={classes.yearlyCard}
                        onClick={() => {
                          dispatch(
                            aggregationActions.getFastlinkCredentials(
                              history,
                              true
                            )
                          );
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginTop: "1rem",
                            }}
                          >
                            <p
                              className={classes.billConditionsTitle}
                              style={{ color: "white" }}
                            >
                              Yearly
                            </p>
                            <p
                              className={classes.billConditionsTitle}
                              style={{ color: "white" }}
                            >
                              $5.99 / per month
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "2.5rem",
                              marginTop: "0.5rem",
                            }}
                          >
                            <p
                              className={classes.billConditionsText}
                              style={{ color: "white" }}
                            >
                              $60 if billed annually (2 months free)
                            </p>
                          </div>
                        </div>
                      </Card>
                      <Card
                        className={classes.trialCard}
                        onClick={() => {
                          dispatch(
                            aggregationActions.getFastlinkCredentials(
                              history,
                              true
                            )
                          );
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "0.8rem",
                            marginLeft: "2.5rem",
                          }}
                        >
                          <p className={classes.billConditionsTitle}>
                            30 days trial
                          </p>
                          <p
                            className={classes.billConditionsText}
                            style={{ marginTop: "0.5rem" }}
                          >
                            $5.99 / per month after 30 days trial
                          </p>
                        </div>
                      </Card>
                      <p
                        className={classes.monetizationSmallCardContent}
                        style={{
                          cursor: "pointer",
                          textAlign: "center",
                          marginBottom: "1.5rem",
                        }}
                        onClick={() => {
                          dispatch(actions.redirectToMainMenu());
                        }}
                      >
                        Skip for now
                      </p>
                    </div>
                  </Card>
                </div>
              </div>
            </Card>
          </>
        )}
        {!showConfirmationCode &&
          !showFastlink &&
          !showMonetization &&
          !showAvatars && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <EFButton
                id="nextButtonSU"
                isDisabled={isButtonEnabled ? false : true}
                text="NEXT"
                onClick={() => {
                  dispatch(
                    actions.signup(
                      firstName + " " + lastName,
                      emailAddress,
                      phoneNumber,
                      "M",
                      password
                    )
                  );
                }}
              />
              <p
                style={{
                  margin: "1.2rem 0",
                  fontSize: "1.6rem",
                  fontFamily: "brandon-grotesque",
                  textAlign: "center",
                }}
              >
                Already have an account?{" "}
                <strong
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/signin");
                  }}
                >
                  Sign In
                </strong>
              </p>
            </div>
          )}
        {showConfirmationCode &&
          !showFastlink &&
          !showMonetization &&
          !showAvatars && (
            <div style={{ margin: "2rem 0", fontSize: "1.6rem" }}>
              <p>
                Didn't get the code?{" "}
                <strong
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(
                      actions.resendVerificationCode(
                        emailFromLogin !== "" ? emailFromLogin : emailAddress
                      )
                    );
                  }}
                >
                  Resend code
                </strong>
              </p>
            </div>
          )}
        {!showAvatars && (
          <div style={{ fontSize: "1.6rem" }}>
            <img src={Lock} alt="" /> Your security. Our priority
            <p style={{ textAlign: "center" }}>Learn more</p>
          </div>
        )}
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
};

export default SignUpPage;
