import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// If we are gonna have different API_KEY and SPACE_ID based on environment move this to .env files.
export const BASE_URL = 'https://cdn.contentful.com';
export const SPACE_ID = 'bsyvugay2m7q';
export const API_KEY = '88f659a21d922557d320b5a4387613afe5937f26b43eefe1551d34c3773ffb78';

export const entries = {
  help: '3iYlSSlFaAhlCPPJ0lNaww',
  termsOfUse: '5UUdGIuI9ExD30zb1o9gds',
  nda: '5x6c7xQRKEexUSaAj38gwD',
  privacy: '3CS7LM0ySgwCHPRlSML7iY'
}

export const hintsEntries = {
  myLife: '7kFUzsPWuZGGU5tBvuo8wM',
  scoop: '1PCFGf6ULddnXrQXi1v9uL',
  profile: '71u0il2jNEuqywHBRXBo0P',
  nudget: '4SxejC7JCYHyn7ivqj292X',
  kakeibo: '1l35Jp3KkfbLTSQ0rfbR45',
  protect: '3dLh4uCdTlvWnK0KLVrec5'
}

export const canonicalProfileEntries = {
  stacey: '1aP4syY7nzjiPr12NOhi64',
  rosa: '2A4iZ3oS6KViANZcFSKXeN',
  daryl: '76qfeO7dGXLk14VhqhuHzZ',
  martin: '3eT9yNy2C81hyA7jB4UDzf',
  eve: '5TAVOFiZXGbsCaVdRVcm1z'
}

interface EntityParam {
  entryId: string;
  options?: any;
  callback: any;
  errorCallback?: any;
}

export const getEntity = ({ entryId, options, callback, errorCallback }: EntityParam) => {
  fetch(`${BASE_URL}/spaces/${SPACE_ID}/entries/${entryId}?access_token=${API_KEY}`)
    .then(res => res.json())
    .then(data => {
      if (options) {
        callback(documentToReactComponents(data.fields.content, options));
      } else {
        callback(documentToReactComponents(data.fields.content));
      }
    })
    .catch(errorCallback)
}

export const getProfileEntity = (entryId: string) => {
  fetch(`${BASE_URL}/spaces/${SPACE_ID}/entries/${entryId}?access_token=${API_KEY}`)
    .then(res => res.json())
    .then(data => {
    })
    .catch(error => console.error(error));
}