import React from 'react';
import { Stack } from 'office-ui-fabric-react/lib/';
import EFLabel from '../EFLabel/EFLabel';

import classes from './EFCard.module.css';

interface IProps {
    id?: string,
    stackClassName?: string,
    style?: object,
    title?: string,
    onClick?: () => void,
    dataTourId?: string,
    customStyle?: any
};

const EFCard: React.FC<IProps> = props => {

    return (
        <Stack id={props.id} data-tour={props.dataTourId} onClick={props.onClick} style={{margin: '2rem', ...props.customStyle}} tokens={{childrenGap: 20}}>
            <EFLabel style={{fontSize: '2.2rem', fontWeight: 'normal' as 'normal',}}>{props.title}</EFLabel>
            <Stack className={`${props.stackClassName} ${classes.card} ${classes.disableScrollbars}`} horizontalAlign='center' verticalAlign='center' style={{ ...props.style }} tokens={{childrenGap: 20}}>
                {props.children}
            </Stack>
        </Stack>
    )
};

export default EFCard;
