import React, { useState } from 'react';
import EditDeleteTooltip from '../UI/EditDeleteTooltip/EditDeleteTooltip';

import classes from './Kakeibo.module.css';

interface IProps {
    date: string;
    value: string;
    sourceName: string;
    deleteEntry?: () => void;
    editEntry?: () => void;
};

const TransactionStrip: React.FC<IProps> = props => {
    const [hover, setHover] = useState(false);

    return (
        <div onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            <div style={{position: 'relative'}} className={classes.centerAll}>
                <div style={{ width: '25%', marginRight: 25 }}>
                    <span className={classes.blueSmall}>{props.date}</span>
                </div>
                <div style={{ width: '20%', marginRight: 25 }}>
                    <span className={classes.blueSmall}>{props.sourceName}</span>
                </div>
                <div style={{ width: '20%' }}>
                    <span className={classes.blueStrong}>{props.value}</span>
                </div>
                <EditDeleteTooltip
                    style={{position: 'absolute', top: 'calc(100% + 1rem)', zIndex: 3}}
                    show={hover} 
                    onClickDelete={props.deleteEntry} 
                    onClickEdit={props.editEntry} 
                />
            </div>
            <hr className={classes.divider} />
        </div>
    );
};

export default TransactionStrip;
