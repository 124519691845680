import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import EFToggle from '../../../Atomic/EFToggle';
import * as actions from '../../../../store/actions/Users';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_TOUR_GUIDE } from '../../../../hooks/Apollo/mutations';

import classesParent from '../SettingsOptions/SettingsOptions.module.css';
import classes from './PrivacyOptions.module.css';

const TourGuideOptions: React.FC<{}> = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [updateTourGuide] = useMutation(UPDATE_TOUR_GUIDE);
    const tourGuide = useSelector((state: any) => state.session.user?.tourGuide);

    const [isChecked, setChecked] = useState({
        scoop: tourGuide?.scoop || false,
        protect: tourGuide?.protect || false,
        grow: tourGuide?.grow || false,
    });

    const onCheckAll = async () => {
        let updatedTour = {
            scoop: true,
            protect: true,
            grow: true,
        }
        if (!isChecked?.scoop && !isChecked?.protect && !isChecked?.grow) {
            setChecked(updatedTour);
        } else {
            updatedTour = {
                scoop: false,
                protect: false,
                grow: false,
            }
            setChecked(updatedTour);
        }
        await updateTourGuide({
            variables: {
                tourGuide: updatedTour
            }
        }).catch(error => console.error(error));
        dispatch(actions.getUpdatedUserFullProfile(history));
    }

    const onCheck = async (type: string) => {
        let updatedTour = {
            ...isChecked,
            [type]: !isChecked[type],
        }
        setChecked(updatedTour);
        await updateTourGuide({
            variables: {
                tourGuide: updatedTour
            }
        }).catch(error => console.error(error));
        dispatch(actions.getUpdatedUserFullProfile(history));
    }

    // Tour is considered active if backend either has the relative attribute as false or non existing
    return (
        <div>
            <div className={classes.card}>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>All Tours</div>
                    <EFToggle 
                        isChecked={!isChecked?.scoop && !isChecked?.protect && !isChecked?.grow} 
                        onClick={onCheckAll} 
                    />
                </div>
            </div>
            <div className={classes.card}>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>Connect</div>
                    <EFToggle isChecked={!(isChecked.scoop)} onClick={() => onCheck("scoop")} />
                </div>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>Protect</div>
                    <EFToggle isChecked={!(isChecked.protect)} onClick={() => onCheck("protect")} />
                </div>
                <div className={classes.switcher}>
                    <div className={classesParent.settingsTitleOption}>Grow</div>
                    <EFToggle isChecked={!(isChecked.grow)} onClick={() => onCheck("grow")} />
                </div>
            </div>

        </div>
    )
};

export default TourGuideOptions;
