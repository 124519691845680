/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-array-constructor */
import React, { useState, useEffect } from "react";
import { Stack } from 'office-ui-fabric-react';
import { useDispatch, useSelector } from "react-redux";
import * as calculatorActions from '../../../store/actions/Calculator';
import bright from '../../../assets/images/career/svg/bright.svg';
import ChartLabel from '../ChartLabel';
import { BarChart, Bar, XAxis } from 'recharts';
import Pagination from '../../Atomic/Pagination/Pagination';
import EfButtonOrange from '../EfButtonOrange';
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "./RadialSeparators";
import Modal from 'react-modal';
import classes from './Jobs.module.css';
import EFButton from "../../Atomic/EFButton/EFButton";
import { useResponsive } from '../../../hooks/useResponsive';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import maskValue from '../../../utils/Masking';
import SaveChangesModal from "../../LifeEvents/SaveChangesModal/SaveChangesModal";
import GeneralInterstitial from "../../LifeEvents/Interstitials/GeneralInterstitial/GeneralInterstitial";
import CareerAssesment from '../InterestsCarousel';

interface Props {
  jobList?: any;
  interests?: any;
  onJobSelection?: (isOpen: boolean) => void;
  onShowJobInfo?: (status: string) => void;
  onQuiz?: (isOpen: boolean) => void;
  lifeEventDetails?: any;
}

const thsdMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
});

const Jobs: React.FC<Props> = props => {
  const { screenIsAtMost } = useResponsive();
  const [interestsModal, setInterestsModal] = useState(false);
  const dispatch = useDispatch();
  const [jobListInfo, setJobListInfo] = useState(props.jobList);
  const [interests, setInterests] = useState(props.interests);
  const [showList, setShowList] = useState(props.jobList?.length);
  const user = useSelector((state: any) => state.session.user);
  const job = useSelector((state: any) => state.career.jobInfo);
  const selectedJob = job ? job.payload.OccupationDetail[0].OnetCode : props.lifeEventDetails.customData ? props.lifeEventDetails.customData.selectedCareerOnetCode : '';
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [anualProjected, setAnualProjected] = useState('');
  const [perCentChange, setPerCentChange] = useState('');
  const [wageMin, setWageMin] = useState(0);
  const [wageMid, setWageMid] = useState(0);
  const [wageMax, setWageMax] = useState(0);
  const [isBright, setIsBright] = useState(false);
  const [educationTitle, setEducationTitle] = useState('');
  const [experienceTitle, setExperienceTitle] = useState('');
  const [trainingTitle, setTrainingTitle] = useState('');
  const [trainingPrograms, setTrainingPrograms] = useState(Array());
  // const [cOSVideoURL, setCOSVideoURL] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [showCareerAssesment, setShowCareerAssesment] = useState(false);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const data = [
    {
      name: 'Low', Wages: wageMin
    },
    {
      name: 'Median', Wages: wageMid
    },
    {
      name: 'High', Wages: wageMax
    }
  ];

  const modalStyle = {
    content: {
      width: '75rem',
      maxWidth: '85vw',
      margin: '0 auto',
      position: 'relative' as 'relative',
      top: '15%',
      right: 'auto',
      left: 'auto',
      bottom: 'auto',
      boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.109512)',
      border: 'none',
      borderRadius: '20px'
    },
    overlay: {
      overflowY: "auto" as "auto",
      background: 'rgba(248, 250, 255, 0.50)',
      backdropFilter: 'blur(40px)',
      WebkitBackdropFilter: 'blur(40px)',
      zIndex: 10,
    },
  }
  const modalContentTitleMobile = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '1.6rem',
    textAlign: 'center' as 'center',
    fontWeight: 700,
    color: '#293861'
  };
  const programsPrepare = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '1.6rem',
    textAlign: 'center' as 'center',
    fontWeight: 700,
    color: '#293861',
  };
  const yellowText = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '1.6rem',
    textAlign: 'center' as 'center',
    fontWeight: 500,
    color: '#fab821'
  }
  const modalPercentMobile = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '3rem',
    fontWeight: 700,
    textAlign: 'center' as 'center',
    color: '#293861',
  };
  const modalSubTitleStyle = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '2rem',
    textAlign: 'center' as 'center',
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'uppercase' as 'uppercase',
    color: '#293861'
  };
  const modalGreenMobile = {
    fontFamily: 'brandon-grotesque, sans-serif',
    fontSize: '3rem',
    textAlign: 'center' as 'center',
    letterSpacing: '1px',
    fontWeight: 700,
    textTransform: 'uppercase' as 'uppercase',
    color: '#86C443',
  };
  const modalCardStyle = {
    content: {
      justifyContent: "center" as "center",
      alignItems: "middle" as "middle",
      borderRadius: 10,
      borderStyle: "none" as "none",
      overflow: "visible",
      height: "fit-content",
      background: "none" as "none",
      left: "auto",
      right: "auto",
      padding: 0,
      marginTop: "2rem",
    },
    overlay: {
      display: "flex" as "flex",
      justifyContent: "center" as "center",
      overflowY: "auto" as "auto",
      background: 'rgba(248, 250, 255, 0.50)',
      backdropFilter: 'blur(40px)',
      WebkitBackdropFilter: 'blur(40px)',
      zIndex: 10,
    },
  };

  function closeCareerAssessmentModal() {
    setModalIsOpen(false);
    setShowCareerAssesment(false);
  }

  const goBackToJobList = () => {
    setShowList(true);
    props.onShowJobInfo && props.onShowJobInfo('');
    setModalIsOpen(false);
  }

  const onCareerAssesmentEnd = (result) => {
    setInterestsModal(true);
    setJobListInfo(result.occupations);
    setInterests(result.interests);
    setShowCareerAssesment(false);
    window.scrollTo(0, 0);
    goBackToJobList();
  };

  async function openModal(onetCode) {
    setShowList(false);
    let url = '';

    if (process.env.NODE_ENV === 'development') {
      url = 'https://career-assessment-api-dev.equalfuture.com/occupations/' + onetCode + '/US/?training=true&videos=true&wages=true&projectedEmployment=true&skills=true&knowledge=true&trainingPrograms=true';
    }

    if (process.env.NODE_ENV === 'production') {
      url = 'https://career-assessment-api-dev.equalfuture.com/occupations/' + onetCode + '/US/?training=true&videos=true&wages=true&projectedEmployment=true&skills=true&knowledge=true&trainingPrograms=true';
    }

    fetch(url,
      {
        method: 'GET'
      }
    )
      .then(res => res.json())
      .then((data) => {
        setModalIsOpen(true);
        setModalTitle(data.OccupationDetail[0].OnetTitle);
        setPerCentChange(data.OccupationDetail[0].Projections.Projections[0].PerCentChange);
        setAnualProjected(data.OccupationDetail[0].Projections.Projections[0].ProjectedAnnualJobOpening);
        setEducationTitle(data.OccupationDetail[0].EducationTraining.EducationTitle);
        setExperienceTitle(data.OccupationDetail[0].EducationTraining.ExperienceTitle);
        setTrainingTitle(data.OccupationDetail[0].EducationTraining.TrainingTitle);
        setTrainingPrograms(data.OccupationDetail[0].TrainingPrograms);
        //  setCOSVideoURL(data.OccupationDetail[0].COSVideoURL);
        if (data.OccupationDetail[0].Wages.NationalWagesList[1].RateType === "Annual") {
          setWageMin(data.OccupationDetail[0].Wages.NationalWagesList[1].Pct10);
          setWageMid(data.OccupationDetail[0].Wages.NationalWagesList[1].Median);
          setWageMax(data.OccupationDetail[0].Wages.NationalWagesList[1].Pct90);
        } else {
          setWageMin(data.OccupationDetail[0].Wages.NationalWagesList[0].Pct10);
          setWageMid(data.OccupationDetail[0].Wages.NationalWagesList[0].Median);
          setWageMax(data.OccupationDetail[0].Wages.NationalWagesList[0].Pct90);
        }

        if (data.OccupationDetail[0].BrightOutlook === "Bright") {
          setIsBright(true)
        }
        dispatch(calculatorActions.careerGoalChangeState({
          selectedCareerTitle: data.OccupationDetail[0].OnetTitle,
          selectedCareerOnetCode: data.OccupationDetail[0].OnetCode,
        }));
      })
      .catch(error => console.error(error));

  }

  useEffect(() => {
    if (selectedJob) {
      setModalIsOpen(true);
      openModal(selectedJob);
    }
  }, []);

  const myparam = jobListInfo || [];
  const currentPosts = myparam ? myparam.slice(indexOfFirstPost, indexOfLastPost) : [];

  const jobDescription = () => {
    return (
      <div>
        {jobListInfo?.length ? <div style={{ marginBottom: '3rem', cursor: 'pointer', fontSize: '1.3rem' }} onClick={goBackToJobList}>Go Back to my Career Options</div> : <></>}
        <p className={`${classes.mainTitle} ${screenIsAtMost('mobile') && classes.mainTitleMobile}`}>
          {modalTitle}
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, marginTop: 20 }}>
          <p style={modalSubTitleStyle}>EDUCATION AND EXPERIENCE TO GET STARTED:</p>
        </div>
        <div className={`${classes.eduAndExp} ${screenIsAtMost('mobile') && classes.eduAndExpMobile}`}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <p style={modalContentTitleMobile}>People starting in this career usually have:</p>
            </div>
            <div>
              <ul className={classes.list}>
                <li className={classes.li}><label>{educationTitle}</label></li>
                <li className={classes.li}><label>{experienceTitle}</label></li>
                <li className={classes.li}><label>{trainingTitle}</label></li>
              </ul>
            </div>
          </div>
          {trainingPrograms != null && trainingPrograms.length && (
            <div>
              <div className={classes.subheader}>
                <p style={programsPrepare}>Programs that can prepare you:</p>
              </div>
              <div className={classes.programs}>
                <ul style={{ columns: '2 auto' }} className={classes.list}>
                  {trainingPrograms.map((row, i) => (
                    <li key={i}><label style={yellowText}>{row}</label></li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <p style={{ margin: '3rem 0', ...modalSubTitleStyle }}>PROJECTED EMPLOYMENT:</p>
        <div className={classes.projection}>
          <div className={classes.h3Container}>
            <label style={modalPercentMobile}>{perCentChange}%</label>
            <div className={classes.h3}>Percent change</div>
          </div>
          <div className={classes.h3Container}>
            <label style={modalGreenMobile}>{maskValue(parseInt(anualProjected), thsdMask)}</label>
            <div className={classes.h3}>Annual projected job openings</div>
          </div>
        </div>
        {isBright &&
          <>
            <div style={{ marginTop: '6rem', width: '100%', display: 'block' }}>
              <p style={modalSubTitleStyle}>OUTLOOK: WILL THERE BE JOBS?</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, marginTop: 20 }}>
              <div style={{ float: 'left' as 'left', width: 90 }}>
                <img alt='your-future-is-bright' src={bright} style={{ marginLeft: -20 }} />
              </div>
              <div style={{ float: 'right' as 'right', marginBottom: 30, marginTop: 20 }}>
                <label className={classes.h3}>New job opportunities are very likely in the future.</label>
                <p className={classes.h3}>This occupation is expected to grow much faster than average.</p>
              </div>
            </div>
          </>
        }
        <div style={{ marginTop: 90, display: 'block', margin: '0' }}>
          <p style={modalSubTitleStyle}>TYPICAL WAGES:</p>
          <p className={classes.h3} style={{ marginTop: '3rem' }}>
            Annual Wages for {modalTitle} in the United States
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginLeft: 'auto', marginRight: 'auto', width: '100%', marginBottom: 20, marginTop: 20 }}>
            <BarChart height={173} width={screenIsAtMost('mobile') ? 200 : 400} data={data} margin={{ top: 60, bottom: 20 }}>
              <XAxis
                tick={{ fontSize: '1.4rem', color: '#293861' }}
                axisLine={false}
                tickLine={false}
                dataKey="name"
              />
              <Bar
                isAnimationActive={false}
                maxBarSize={300}
                dataKey="Wages"
                fill="#32C5FF"
                label={ChartLabel}
                radius={[10, 10, 10, 10]}
              />
            </BarChart>
          </div>
        </div>
        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <EfButtonOrange click={() => { props.onJobSelection(true) }} text={"ADD TO MY LIFE"} width={'18rem'} size={'1.4rem'} />
        </div> */}
      </div>
    )
  }

  const JobInterests = () => {
    let circles: JSX.Element[] = [];
    const colors = {
      "Realistic": "#FEB81E",
      "Investigative": "#2695C7",
      "Artistic": "#A339FF",
      "Social": "#2CCD79",
      "Enterprising": "#F3798D",
      "Conventional": "#02C4EA"
    }

    for (const interest in interests) {
      circles.push(
        <div key={interest} className={classes.circle}>
          <CircularProgressbarWithChildren
            value={interests[interest].score}
            strokeWidth={6}
            styles={buildStyles({
              strokeLinecap: "square",
              pathColor: colors[interest]
            })}
          >
            <RadialSeparators
              count={24}
              style={{
                background: "#fff",
                width: "12px",
                borderRadious: "100px",
                height: `${7}%`
              }}
            />
            <div style={{ color: colors[interest] }} className={classes.circleText}>{interest}</div>
          </CircularProgressbarWithChildren>
        </div>
      )
    }

    return (
      <Modal isOpen={interestsModal} onRequestClose={() => { setInterestsModal(false) }} style={modalStyle}>
        <div>
          <div className={classes.modalTitle}>Based on your answers here are your interests:</div>
          <div className={classes.modalContainer}>
            {circles}
          </div>
          <div className={classes.modalBtn}>
            <EfButtonOrange click={() => { setInterestsModal(false) }} text={"EXPLORE MY CAREER OPTIONS"} width={'26rem'} size={'1.4rem'} />
          </div>
        </div>
      </Modal>
    )
  }

  const retakeQuiz = () => {
    return (
      <EFButton
        text='Retake Assessment'
        onClick={() => { 
          // Not sure the difference of these two below
          // but it is seems modalIsOpen handles reopening of career quiz
          setShowCareerAssesment(true);
          setModalIsOpen(true);
         }}
        style={{ border: '2px solid #2695C7', color: '#2695C7', backgroundColor: '#FFF' }}
      />
    )
  }

  const showResults = () => {
    return !user.anonymized && (
      <EFButton
        text='Show Results'
        onClick={() => { setInterestsModal(true) }}
        style={{ border: '2px solid #2695C7', color: '#2695C7', backgroundColor: '#FFF' }}
      />
    )
  }

  const jobList = () => {
    return (
      <>
        <div>
          <p className={classes.title}>JOBS BASED ON MY INTERESTS</p>
          <p className={classes.sub}>Click to explore</p>
          <label className={classes.greyText}>Careers</label>
          <div style={{ float: 'right' }}>
            <label className={classes.greyText}>Match</label>
          </div>
          <hr className={classes.divider} />

        </div>
        {currentPosts.map((row: any, i: number) => (
          <div
            style={{
              color: "#5A5A5A",
              padding: 0
            }}
            key={i}
          >
            <div onClick={async () => { await openModal(row.OnetCode) }} style={{ padding: '1.5rem 0' }}><label className={classes.subtitleLabel}>{row.Name}</label> <div style={{ float: 'right' }}><label className={classes.subtitleLabel}>{row.Rank}</label></div></div>

            <hr className={classes.divider} />
          </div>
        ))}
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={myparam.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </>
    )
  }

  return (
    <>
      <SaveChangesModal
        openLifeEventSaveModal={props.lifeEventDetails.openSaveModal}
        onClose={props.lifeEventDetails.onOpenSaveModal}
      >
        <GeneralInterstitial
          lifeEventDetails={props.lifeEventDetails}
          onClick={(formData: any) => {
            props.lifeEventDetails.onSaveLifeEvent(formData);
            props.lifeEventDetails.onOpenSaveModal();
          }}
          onCloseClick={props.lifeEventDetails.onOpenSaveModal}
        />
      </SaveChangesModal>

      {showCareerAssesment && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeCareerAssessmentModal}
          style={{
            content: { ...modalCardStyle.content, width: "100%" },
            overlay: modalCardStyle.overlay,
          }}
        >
          <CareerAssesment
            onEnd={onCareerAssesmentEnd}
            closeModal={closeCareerAssessmentModal}
          />
        </Modal>
      )}

      <Stack horizontalAlign='center' style={{ width: '100%' }}>
        <Stack tokens={{ childrenGap: '2.8rem' }} style={{ width: '100%' }}>
          <JobInterests />
          <div className={classes.buttonsContainer}>
            <div>
              {interests && showResults()}
            </div>
            {retakeQuiz()}
          </div>
          <div className={classes.container} id='container'>
            {showList ? jobList() : jobDescription()}
          </div>
        </Stack>
      </Stack>
    </>
  )
}

export default Jobs;
