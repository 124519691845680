import React, { useState } from "react";
import { Stack, IDropdownOption } from "office-ui-fabric-react";
import EFSlider from "../../UI/EFSlider/EFSlider";
import EFStepperInput from "../../UI/EFStepperInput/EFStepperInput";
import BreakdownList from "../Common/BreakdownList";
import ResultReport from "../Common/ResultReport";
import StackedChart from "../../Charts/StackedChart";
import EFLabel from "../../Atomic/EFLabel/EFLabel";
import StaticContainer from "../../UI/StaticContainer";
import ConfigButton from "../Common/ConfigButton";
import DividerContainer from "../../UI/Divider/DividerContainer";
import Divider from "../../UI/Divider/Divider";
import LineChart from "../../Charts/LineChart/LineChart";
import { useHouseBuyRent } from "../../../hooks/Calculators/useHouseBuyRent";
import { useConfigButtons } from "../../../hooks/useConfigButtons";
import EFDropdown from "../../Atomic/EFDropdown/EFDropdown";
import EFScaledStepper from "../../UI/EFScaledStepper/EFScaledStepper";
import MaskedLabelPair from "../../UI/MaskedLabelPair";
import EFAccordion from "../../Atomic/EFAccordion/EFAccordion";
import HouseOnboarding from "./HouseOnboarding";

import { stateList } from "../../../assets/content/StateList";
import classes from "./HouseBuyRent.module.css";
import BuyRentInterstitial from "../../LifeEvents/Interstitials/BuyRentInterstitial/BuyRentInterstitial";
import SaveChangesModal from "../../LifeEvents/SaveChangesModal/SaveChangesModal";
import EFTooltip from "../../Atomic/EFTooltip/EFTooltip";
import { houseTT } from "../../../assets/content/TempCalcToolTips";
import maskValue, { numberMaskUS } from "../../../utils/Masking";

interface Props {
  handleModal: { showModal: boolean; exitModal?: () => void };
  currentView: string;
  lifeEventDetails?: any;
}

const HouseBuyRent: React.FC<Props> = (props) => {
  const {
    /* #region inputs and outputs */
    //INPUTS
    selectedOption,
    setSelectedOption,
    //setup
    propertyState,
    setPropertyState,
    yearsToStayInHouse,
    setyearsToStayInHouse,
    //simu
    housePrice,
    setshousePrice,
    downPayment,
    setdownPayment,
    mortgageTerm,
    setmortgageTerm,
    mortgageInterestRate,
    setmortgageInterestRate,
    monthlyRent,
    setmonthlyRent,
    downPaymentRent,
    setdownPaymentRent,
    //lvlup
    closingCostRate,
    setclosingCostRate,
    homeAnnualTax,
    sethomeAnnualTax,
    incomeTax,
    setincomeTax,
    capitalGainsTaxRate,
    setcapitalGainsTaxRate,
    homeownersInsuranceRate,
    sethomeownersInsuranceRate,
    monthlyRentInsurance,
    setmonthlyRentInsurance,
    homeMonthlyMaintenance,
    sethomeMonthlyMaintenance,
    homeownersAssociationDues,
    sethomeownersAssociationDues,
    homeAppreciationRate,
    sethomeAppreciationRate,
    rentAppreciation,
    setrentAppreciation,
    inflationRate,
    setinflationRate,
    rateOfReturn,
    setrateOfReturn,
    //OUTPUTS
    paymentsOverTimeHouse,
    investmentSimulationHouse,
    firstYearBuyDownPaymentopt,
    firstYearBuyMortgagePayments,
    firstYearBuyHomeownerInsurance,
    firstYearBuyPropertyTaxes,
    firstYearBuyMaintenanceCosts,
    firstYearBuyClosingCosts,
    /* #endregion */
  } = useHouseBuyRent(props.lifeEventDetails.id);
  /* #region Define Divider States: TO BE REFACTORED INTO CUSTOM HOOK */
  const [expensesDivider, setExpensesDivider] = useState<boolean>(true);
  const [totalCostDivider, setTotalCostDivider] = useState<boolean>(false);
  const [breakdownDivider, setBreakdownDivider] = useState<boolean>(false);
  const handleDividers = () => {
    setExpensesDivider(false);
    setTotalCostDivider(false);
    setBreakdownDivider(false);
  };
  /* #endregion */

  const { setup, onSetup, simu, onSimu, lvl, onLvl } = useConfigButtons();
  const [currMonth, setCurrMonth] = useState<number>(0);
  const [onRentDepositInput, setOnRentDepositInput] = useState<boolean>(false);
  const [rentDepositKey, setRentDepositKey] = useState<string>("2");

  const [lengthYearsInput, setLengthYearsInput] =
    useState<number>(yearsToStayInHouse);
  const [lengthYearsObservable, setLengthYearsObservable] =
    useState<number>(yearsToStayInHouse);

  //setup input that manipulates input recieved by calc, the self input and the observable input from cost over time
  const onChangeYearsToLive = (val: number) => {
    setyearsToStayInHouse(val);
    setLengthYearsInput(val);
    setLengthYearsObservable(val);
  };
  //cost over time input that changes self input and input recieved by calc
  const onChangeYearsToLiveObservable = (values: readonly number[]) => {
    setLengthYearsObservable(values[0]);
    setyearsToStayInHouse(values[0]);
  };

  const paymentsMonth = paymentsOverTimeHouse.map(
    (r, i) => paymentsOverTimeHouse[i][0]
  );
  const paymentsBuyPayment = paymentsOverTimeHouse.map(
    (r, i) => paymentsOverTimeHouse[i][1]
  );
  const paymentsRentPayment = paymentsOverTimeHouse.map(
    (r, i) => paymentsOverTimeHouse[i][2]
  );
  const paymentsMonthlyBuyCost = paymentsOverTimeHouse.map(
    (r, i) => paymentsOverTimeHouse[i][3]
  );
  const paymentsMonthlyRentCost = paymentsOverTimeHouse.map(
    (r, i) => paymentsOverTimeHouse[i][4]
  );

  const rentTotalDataSet = investmentSimulationHouse.map(
    (r, i) => investmentSimulationHouse[i][1]
  );
  const buyTotalDataSet = investmentSimulationHouse.map(
    (r, i) => investmentSimulationHouse[i][2]
  );

  // Results shown below the chart
  const rentResult = rentTotalDataSet[yearsToStayInHouse - 1];
  const buyResult = buyTotalDataSet[yearsToStayInHouse - 1];
  // Toggler for best option (BUY OR RENT)
  const bestOption =
    rentResult <= buyResult
      ? { text: "BUY", color: "#BAA0FF" }
      : { text: "RENT", color: "#FF9255" };

  const rateToPrice = (rate: number) => {
    return (rate * housePrice) / 100;
  };

  const priceToRate = (price: number) => {
    return (price * 100) / housePrice;
  };

  const onChangeRentDepositDropdown = (e: any, item?: IDropdownOption) => {
    const key = item?.key.toString() || "";
    setRentDepositKey(key);
    switch (key !== "") {
      case key === "1":
        setOnRentDepositInput(false);
        setdownPaymentRent(1 * monthlyRent);
        break;
      case key === "2":
        setOnRentDepositInput(false);
        setdownPaymentRent(2 * monthlyRent);
        break;
      case key === "3":
        setOnRentDepositInput(false);
        setdownPaymentRent(3 * monthlyRent);
        break;
      case key === "O":
        setOnRentDepositInput(true);
        break;
    }
  };

  // setup content
  const setupContent = (
    <>
      <Stack>
        <Stack
          tokens={{ childrenGap: ".5rem" }}
          verticalAlign="center"
          horizontal
        >
          <EFLabel style={{ fontWeight: "normal" as "normal" }}>
            Where do you plan to live?
          </EFLabel>
          <EFTooltip
            text={houseTT.planToLive}
            title={"Where do you plan to live?"}
          />
        </Stack>
        <EFDropdown
          selectedKey={stateList.find((s: any) => s.text === propertyState).key}
          options={stateList}
          onChange={(e: any, item?: IDropdownOption) => {
            const x = item?.key.toString() || "";
            const state = stateList.find((s: any) => s.key === x).text;
            setPropertyState(state);
          }}
        />
      </Stack>
      <EFSlider
        title="How long you plan to live there? (Years)"
        onChange={onChangeYearsToLive}
        value={lengthYearsInput}
        min={1}
        max={30}
      />
    </>
  );

  // simu content
  const simuContent1 = (
    <>
      <EFStepperInput
        label="House Purchase Price"
        onChange={(val: number) => setshousePrice(val)}
        value={housePrice}
        prefix="$"
        minVal={0}
        maxVal={1000000000}
        numStepper={50000}
        includeThousandsSeparator
        tooltip={houseTT.housePurchasePrice}
      />
    </>
  );
  const simuContent2 = (
    <>
      <EFSlider
        title="Down Payment"
        onChange={(val: number) => setdownPayment(val)}
        value={downPayment}
        prefix="$"
        min={0}
        max={housePrice}
        includeThousandsSeparator
      />
      <EFSlider
        title="Loan Term (Years)"
        onChange={(val: number) => setmortgageTerm(val)}
        value={mortgageTerm}
        min={0}
        max={45}
        tooltip={houseTT.loanTerm}
      />
      <EFStepperInput
        label="Mortage interest rate"
        onChange={(val: number) => setmortgageInterestRate(val)}
        value={mortgageInterestRate}
        suffix="%"
        minVal={0.0}
        maxVal={10.0}
        numStepper={0.25}
        allowDecimal
        tooltip={houseTT.mortgageRate}
      />
    </>
  );
  const simuContent3 = (
    <>
      <Stack horizontalAlign="center">
        <EFStepperInput
          label="Monthly Rent"
          onChange={(val: number) => setmonthlyRent(val)}
          value={monthlyRent}
          prefix="$"
          minVal={0}
          maxVal={500000}
          dynamicStepEnabled
          includeThousandsSeparator
          tooltip={houseTT.monthlyRent}
        />
      </Stack>
      <Stack>
        <Stack
          tokens={{ childrenGap: ".5rem" }}
          verticalAlign="center"
          horizontal
        >
          <EFLabel style={{ fontWeight: "normal" as "normal" }}>
            Rent Security Deposit
          </EFLabel>
          <EFTooltip
            text={houseTT.rentSecurityDeposit}
            title={"Rent Security Deposit"}
          />
        </Stack>
        <EFDropdown
          style={{ maxWidth: "25rem" }}
          options={[
            { key: "1", text: "One Month" },
            { key: "2", text: "Two Months" },
            { key: "3", text: "Three Months" },
            { key: "O", text: "Other" },
          ]}
          defaultSelectedKey={rentDepositKey}
          onChange={onChangeRentDepositDropdown}
        />
      </Stack>
      <Stack horizontalAlign="center">
        {onRentDepositInput && (
          <EFStepperInput
            label="Enter the amount"
            onChange={(val: number) => setdownPaymentRent(val)}
            value={downPaymentRent}
            prefix="$"
            minVal={0}
            maxVal={500000}
            dynamicStepEnabled
            includeThousandsSeparator
          />
        )}
      </Stack>
    </>
  );

  // lvlup content
  const lvlContent1 = (
    <>
      <Stack tokens={{ childrenGap: 5 }}>
        <EFStepperInput
          label="Closing Costs"
          onChange={(val: number) => setclosingCostRate(priceToRate(val))}
          value={rateToPrice(closingCostRate)}
          prefix="$"
          minVal={0}
          maxVal={50000}
          dynamicStepEnabled
          includeThousandsSeparator
        />
        <MaskedLabelPair
          isBulleted={false}
          prefix=""
          suffix="%"
          title=""
          value={closingCostRate}
          color="#293861"
          valueFontSize={10}
          allowDecimal
        />
      </Stack>
    </>
  );
  const lvlContent2 = (
    <>
      <EFStepperInput
        label="Property Taxes"
        onChange={(val: number) => sethomeAnnualTax(val)}
        value={homeAnnualTax}
        prefix="$"
        minVal={0}
        maxVal={50000}
        dynamicStepEnabled
        includeThousandsSeparator
        allowDecimal
        tooltip={houseTT.propertyTaxes}
      />
      <EFStepperInput
        label="Income Tax Rate"
        onChange={(val: number) => setincomeTax(val)}
        value={incomeTax}
        suffix="%"
        minVal={0}
        maxVal={100}
        allowDecimal
        tooltip={houseTT.incomeTaxRate}
      />
      <EFStepperInput
        label="Capital Gains Tax Rate"
        onChange={(val: number) => setcapitalGainsTaxRate(val)}
        value={capitalGainsTaxRate}
        suffix="%"
        minVal={0}
        maxVal={100}
        allowDecimal
        tooltip={houseTT.capitalGainsTaxRate}
      />
    </>
  );
  const lvlContent3 = (
    <>
      <Stack tokens={{ childrenGap: 5 }}>
        <EFStepperInput
          label={`Homeowner's Insurance (Monthly)`}
          onChange={(val: number) =>
            sethomeownersInsuranceRate(priceToRate(val))
          }
          value={rateToPrice(homeownersInsuranceRate)}
          prefix="$"
          minVal={0}
          maxVal={10000}
          dynamicStepEnabled
          includeThousandsSeparator
          tooltip={houseTT.homeownerInsurance}
        />
        <MaskedLabelPair
          isBulleted={false}
          prefix=""
          suffix="%"
          title=""
          value={homeownersInsuranceRate}
          color="#293861"
          valueFontSize={10}
          allowDecimal
        />
      </Stack>
      <Stack tokens={{ childrenGap: 5 }}>
        <EFStepperInput
          label={`Renter's Insurance (Monthly)`}
          onChange={(val: number) => setmonthlyRentInsurance(val)}
          value={monthlyRentInsurance}
          prefix="$"
          minVal={0}
          maxVal={1000000}
          dynamicStepEnabled
          includeThousandsSeparator
          tooltip={houseTT.rentersInsurance}
        />
        <MaskedLabelPair
          isBulleted={false}
          prefix=""
          suffix="%"
          title=""
          value={monthlyRentInsurance}
          color="#293861"
          valueFontSize={10}
          allowDecimal
        />
      </Stack>
    </>
  );
  const lvlContent4 = (
    <>
      <EFStepperInput
        label="Home Repairs (Monthly)"
        onChange={(val: number) => sethomeMonthlyMaintenance(val)}
        value={homeMonthlyMaintenance}
        prefix="$"
        minVal={0}
        maxVal={10000}
        dynamicStepEnabled
        includeThousandsSeparator
        tooltip={houseTT.homeRepairs}
      />
      <EFStepperInput
        label="Monthly HOA Fees"
        onChange={(val: number) => sethomeownersAssociationDues(val)}
        value={homeownersAssociationDues}
        prefix="$"
        minVal={0}
        maxVal={1000000}
        dynamicStepEnabled
        includeThousandsSeparator
      />
    </>
  );
  const lvlContent5 = (
    <>
      <EFStepperInput
        label="Home Value Appreciation"
        onChange={(val: number) => sethomeAppreciationRate(val)}
        value={homeAppreciationRate}
        suffix="%"
        minVal={0}
        maxVal={100}
        allowDecimal
        tooltip={houseTT.homeValueAppreciation}
      />
      <EFStepperInput
        label="Rent Appreciation"
        onChange={(val: number) => setrentAppreciation(val)}
        value={rentAppreciation}
        suffix="%"
        minVal={0}
        maxVal={10}
        allowDecimal
        tooltip={houseTT.rentAppreciation}
      />
      <EFStepperInput
        label="General Inflation"
        onChange={(val: number) => setinflationRate(val)}
        value={inflationRate}
        suffix="%"
        minVal={0}
        maxVal={10}
        allowDecimal
        tooltip={houseTT.generalInflation}
      />
    </>
  );
  const lvlContent6 = (
    <>
      <EFStepperInput
        label="Rate of Return"
        onChange={(val: number) => setrateOfReturn(val)}
        value={rateOfReturn}
        suffix="%"
        minVal={0}
        maxVal={100}
        numStepper={0.25}
        allowDecimal
        tooltip={houseTT.rateOfReturn}
      />
    </>
  );

  return (
    <>
      <HouseOnboarding
        handleModal={props.handleModal}
        setPropertyState={setPropertyState}
        onChangeYearsToLive={onChangeYearsToLive}
      />
      <SaveChangesModal
        openLifeEventSaveModal={props.lifeEventDetails.openSaveModal}
        onClose={props.lifeEventDetails.onOpenSaveModal}
      >
        <BuyRentInterstitial
          lifeEventDetails={props.lifeEventDetails}
          onClick={props.lifeEventDetails.onSaveLifeEvent}
          onCloseClick={props.lifeEventDetails.onOpenSaveModal}
          lengthYears={lengthYearsInput}
          currentOption={selectedOption}
          onSelectOption={(option) => setSelectedOption(option)}
        />
      </SaveChangesModal>
      {props.currentView === "Connect the dots" && (
        <Stack horizontalAlign="center" tokens={{ childrenGap: 20 }}>
          <Stack>
            <Stack
              horizontal
              horizontalAlign="center"
              className={classes.dividercontainer}
              tokens={{ childrenGap: 30 }}
            >
              <Divider
                title="Rent vs Buy:"
                subtitle="Expenses over time"
                icon={require("../../../assets/icons/houseannual.svg")}
                isOpen={expensesDivider}
                onClick={() => {
                  handleDividers();
                  setExpensesDivider(true);
                }}
              />
              <Divider
                title="Rent vs Buy:"
                subtitle="Investment Impact"
                icon={require("../../../assets/icons/housetotal.svg")}
                isOpen={totalCostDivider}
                onClick={() => {
                  handleDividers();
                  setTotalCostDivider(true);
                }}
              />
              <Divider
                title="Rent vs Buy:"
                subtitle="Breakdown of first year cost"
                icon={require("../../../assets/icons/housebd.svg")}
                isOpen={breakdownDivider}
                onClick={() => {
                  handleDividers();
                  setBreakdownDivider(true);
                }}
              />
            </Stack>
            <Stack horizontalAlign="center">
              <DividerContainer>
                {expensesDivider && (
                  <Stack
                    wrap
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 30 }}
                  >
                    <Stack className={classes.chart}>
                      <LineChart
                        enableBreakEven
                        breakEvenImg={require("../../../assets/images/bep_house.svg")}
                        bepTextMargin={"12px 0"}
                        maxXTicksLimit={8}
                        onDrag={(event: any) =>
                          setCurrMonth(event.subject.config.value)
                        }
                        datasets={{
                          labels: paymentsMonth,
                          datasets: [
                            {
                              label: "RENT",
                              data: paymentsRentPayment,
                              backgroundColor: "#FF9255",
                              borderColor: "#FF9255",
                              fill: false,
                            },
                            {
                              label: "BUY",
                              data: paymentsBuyPayment,
                              backgroundColor: "#BAA0FF",
                              borderColor: "#BAA0FF",
                              fill: false,
                            },
                          ],
                        }}
                      />
                    </Stack>
                    <Stack
                      horizontalAlign="center"
                      tokens={{ childrenGap: 20 }}
                      className={classes.report}
                    >
                      <ResultReport
                        isGreen={rentResult <= buyResult}
                        onChange={() => null}
                        reportGreenLabel="Based on your inputs, buying is your best option"
                        greenAlt="#BAA0FF"
                        reportRedLabel="Based on your inputs, renting is your best option"
                        redAlt="#FF9255"
                      />
                      <Stack
                        horizontal
                        style={{ marginLeft: 34 }}
                        tokens={{ childrenGap: 0 }}
                      >
                        <EFLabel>Monthly</EFLabel>
                        <EFLabel style={{ marginLeft: 30 }}>Total</EFLabel>
                      </Stack>
                      <Stack horizontal>
                        <BreakdownList
                          style={{ width: "15rem" }}
                          data={[
                            {
                              key: 1,
                              title: "Rent",
                              value: paymentsMonthlyRentCost[currMonth],
                              color: "#FF9255",
                            },
                            {
                              key: 3,
                              title: "Buy",
                              value: paymentsMonthlyBuyCost[currMonth],
                              color: "#BAA0FF",
                            },
                          ]}
                        />
                        <BreakdownList
                          style={{ width: "10rem", marginLeft: "4rem" }}
                          data={[
                            {
                              key: 0,
                              title: "",
                              value: paymentsRentPayment[currMonth],
                              color: "",
                            },
                            {
                              key: 1,
                              title: "",
                              value: paymentsBuyPayment[currMonth],
                              color: "",
                            },
                          ]}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )}
                {totalCostDivider && (
                  <Stack
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 30 }}
                  >
                    <Stack horizontalAlign="center">
                      <Stack
                        horizontalAlign="center"
                        className={classes.observable}
                        tokens={{ childrenGap: 30 }}
                      >
                        <Stack
                          horizontal
                          verticalAlign="center"
                          tokens={{ childrenGap: 25 }}
                        >
                          {/* <img alt='house' src={require('../../../assets/images/house_orange.svg')}/> */}
                          <p
                            className={classes.observabletext}
                            style={{ color: bestOption.color }}
                          >
                            {`Staying in the house for `}
                            <strong>{lengthYearsObservable}</strong>
                            {` years, the best option is `}
                            <strong>{bestOption.text}</strong>
                          </p>
                        </Stack>
                        <EFScaledStepper
                          onChange={onChangeYearsToLiveObservable}
                          value={lengthYearsObservable}
                          min={1}
                          max={lengthYearsInput}
                        />
                      </Stack>
                      <Stack
                        horizontalAlign="center"
                        className={classes.chartalt}
                      >
                        <StackedChart
                          datasets={{
                            labels: ["", ""],
                            datasets: [
                              {
                                type: "bar",
                                label: "Rent",
                                data: [rentResult, 0],
                                backgroundColor: "#FF9255",
                                borderColor: "#FF9255",
                                datalabels: {
                                  color: "#343F6E",
                                  formatter: function (value, context) {
                                    if (value === 0) {
                                      return "";
                                    }
                                    const sum =
                                      context.chart.data?.datasets.reduce(
                                        (a, b) => {
                                          return a + b.data[context.dataIndex];
                                        },
                                        0
                                      );
                                    return maskValue(
                                      Math.round(sum),
                                      numberMaskUS
                                    );
                                  },
                                  align: "end",
                                  anchor: "end",
                                  offset: 5,
                                  padding: -2,
                                  clip: true,
                                  font: {
                                    size: "16",
                                    weight: "bold",
                                  },
                                },
                              },
                              {
                                type: "bar",
                                label: "Buy",
                                data: [0, buyResult],
                                backgroundColor: "#BAA0FF",
                                borderColor: "#BAA0FF",
                                datalabels: {
                                  color: "#343F6E",
                                  formatter: function (value, context) {
                                    if (value === 0) {
                                      return "";
                                    }
                                    const sum =
                                      context.chart.data?.datasets.reduce(
                                        (a, b) => {
                                          return a + b.data[context.dataIndex];
                                        },
                                        0
                                      );
                                    return maskValue(
                                      Math.round(sum),
                                      numberMaskUS
                                    );
                                  },
                                  align: "end",
                                  anchor: "end",
                                  offset: 5,
                                  padding: -2,
                                  clip: true,
                                  font: {
                                    size: "16",
                                    weight: "bold",
                                  },
                                },
                              },
                            ],
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      horizontalAlign="center"
                      tokens={{ childrenGap: 20 }}
                      className={classes.report}
                    >
                      <ResultReport
                        isGreen={rentResult <= buyResult}
                        onChange={() => null}
                        reportGreenLabel="Based on your inputs, buying is your best option"
                        greenAlt="#BAA0FF"
                        reportRedLabel="Based on your inputs, renting is your best option"
                        redAlt="#FF9255"
                      />
                      <BreakdownList
                        style={{ width: "30rem", marginTop: "5rem" }}
                        data={[
                          {
                            key: 0,
                            title: "Rent",
                            value: rentTotalDataSet[yearsToStayInHouse - 1],
                            color: "#FF9255",
                          },
                          {
                            key: 1,
                            title: "Buy",
                            value: buyTotalDataSet[yearsToStayInHouse - 1],
                            color: "#BAA0FF",
                          },
                        ]}
                      />
                    </Stack>
                  </Stack>
                )}
                {breakdownDivider && (
                  <Stack
                    wrap
                    horizontal
                    horizontalAlign="center"
                    tokens={{ childrenGap: 30 }}
                  >
                    <Stack className={classes.chart}>
                      <StackedChart
                        barPercentage={0.6}
                        datasets={{
                          labels: ["BUYING"],
                          datasets: [
                            {
                              type: "bar",
                              label: "Closing Costs",
                              data: [firstYearBuyClosingCosts],
                              backgroundColor: "#DB53C3",
                              borderColor: "#DB53C3",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Maintenance",
                              data: [firstYearBuyMaintenanceCosts],
                              backgroundColor: "#55D6BE",
                              borderColor: "#55D6BE",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Property Taxes",
                              data: [firstYearBuyPropertyTaxes],
                              backgroundColor: "#A5EB97",
                              borderColor: "#A5EB97",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Homeowner Insurance",
                              data: [firstYearBuyHomeownerInsurance],
                              backgroundColor: "#7D5BA6",
                              borderColor: "#7D5BA6",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Mortage Payment",
                              data: [firstYearBuyMortgagePayments],
                              backgroundColor: "#F3798D",
                              borderColor: "#F3798D",
                              datalabels: {
                                display: false,
                              },
                            },
                            {
                              type: "bar",
                              label: "Down Payment",
                              data: [firstYearBuyDownPaymentopt],
                              backgroundColor: "#BAA0FF",
                              borderColor: "#BAA0FF",
                              datalabels: {
                                display: false,
                              },
                            },
                          ],
                        }}
                      />
                    </Stack>
                    <Stack
                      horizontalAlign="center"
                      tokens={{ childrenGap: 20 }}
                      className={classes.report}
                    >
                      <ResultReport
                        isGreen={rentResult <= buyResult}
                        onChange={() => null}
                        reportGreenLabel="Based on your inputs, buying is your best option"
                        greenAlt="#BAA0FF"
                        reportRedLabel="Based on your inputs, renting is your best option"
                        redAlt="#FF9255"
                      />
                      <BreakdownList
                        style={{ width: "30rem", marginTop: 0 }}
                        data={[
                          {
                            key: 0,
                            title: "Down Payment",
                            value: firstYearBuyDownPaymentopt,
                            color: "#BAA0FF",
                          },
                          {
                            key: 1,
                            title: "Mortage Payment",
                            value: firstYearBuyMortgagePayments,
                            color: "#F3798D",
                          },
                          {
                            key: 2,
                            title: "Homeowner Insurance",
                            value: firstYearBuyHomeownerInsurance,
                            color: "#7D5BA6",
                          },
                          {
                            key: 3,
                            title: "Property Taxes",
                            value: firstYearBuyPropertyTaxes,
                            color: "#A5EB97",
                          },
                          {
                            key: 4,
                            title: "Maintenance",
                            value: firstYearBuyMaintenanceCosts,
                            color: "#55D6BE",
                          },
                          {
                            key: 5,
                            title: "Closing Costs",
                            value: firstYearBuyClosingCosts,
                            color: "#DB53C3",
                          },
                        ]}
                      />
                    </Stack>
                  </Stack>
                )}
              </DividerContainer>
            </Stack>
          </Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 40 }}
          >
            <ConfigButton type="setup" toggleFocus={setup} onClick={onSetup} />
            <ConfigButton type="simu" toggleFocus={simu} onClick={onSimu} />
            <ConfigButton type="levelup" toggleFocus={lvl} onClick={onLvl} />
          </Stack>
          {setup && (
            <Stack
              wrap
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: 10 }}
            >
              <EFAccordion title="">{setupContent}</EFAccordion>
            </Stack>
          )}
          {simu && (
            <Stack
              wrap
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: 10 }}
            >
              <EFAccordion title="">{simuContent1}</EFAccordion>
              <EFAccordion title="">{simuContent2}</EFAccordion>
              <EFAccordion title="">{simuContent3}</EFAccordion>
            </Stack>
          )}
          {lvl && (
            <Stack
              horizontal
              wrap
              horizontalAlign="center"
              tokens={{ childrenGap: 10 }}
            >
              <EFAccordion title="Closing Costs">{lvlContent1}</EFAccordion>
              <EFAccordion title="Taxes">{lvlContent2}</EFAccordion>
              <EFAccordion title="Insurance">{lvlContent3}</EFAccordion>
              <EFAccordion title="Maintenance">{lvlContent4}</EFAccordion>
              <EFAccordion title="Home Value">{lvlContent5}</EFAccordion>
              <EFAccordion title="Return Rate">{lvlContent6}</EFAccordion>
            </Stack>
          )}
        </Stack>
      )}
      {props.currentView === "Low Down" && (
        <StaticContainer url="https://content.equalfuture.com/blog/House/lowdown" />
      )}
      {props.currentView === "Nitty Gritty" && (
        <StaticContainer url="https://content.equalfuture.com/blog/House/nittygritty" />
      )}
    </>
  );
};

export default HouseBuyRent;
